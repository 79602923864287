import { ShaclValidationResultReportResponse } from "data/Mason";

type Error = string | ShaclValidationResultReportResponse;

export class UIStatus {
  indeterminate: boolean;
  error?: Error;
  empty?: boolean;
  successful?: boolean;

  constructor(defaultLoading?: boolean) {
    this.indeterminate = !!defaultLoading;
  }

  static from(statuses: UIStatus[]) {
    const s = new UIStatus();
    s.indeterminate = statuses.find((s) => s.indeterminate) !== undefined;

    const errors = statuses.filter((s) => s.error);
    if (errors.length) {
      s.error = errors.reduce((a, c) => a + c.error, "");
    } else {
      s.error = undefined;
    }

    s.empty = statuses.find((s) => s.empty) !== undefined;
    s.successful = statuses.find((s) => s.successful) !== undefined;
    return s;
  }

  setIndeterminate(loading: boolean) {
    const s = new UIStatus();
    s.indeterminate = loading;
    return s;
  }

  setEmpty(e: boolean): UIStatus {
    const s = new UIStatus();
    s.empty = e;
    return s;
  }

  setError(e: Error): UIStatus {
    const s = new UIStatus();
    s.error = e;
    return s;
  }

  setSuccessful(successful: boolean): UIStatus {
    const s = new UIStatus();
    s.successful = successful;
    return s;
  }

  isTruthy(): boolean {
    return !this.error && !this.indeterminate;
  }
}
