/** @jsxImportSource @emotion/react */

import { useNavigate } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import tw from "twin.macro";
import {
  availableApplicationViewPath,
  installedApplicationViewPath,
  manageApplicationViewPath,
} from "Routes";
import {
  AppCategories,
  AppListView,
  AppSitesAndBuildings,
  AppStats,
  ApplicationsMode,
  CommonAppProps,
  getIconByCategory,
} from ".";
import { DATE_TIME_FORMAT_WITH_AMPM, formatDate } from "components/shared";

export const ApplicationCard = ({ app, mode }: CommonAppProps) => {
  const navigate = useNavigate();
  const iconSvg = getIconByCategory(app.categories ?? []);

  const handleNavigateOnClick = () => {
    switch (mode) {
      case ApplicationsMode.available:
        navigate(availableApplicationViewPath(app.applicationUid), {
          state: { backTo: "Available Applications" },
        });
        break;
      case ApplicationsMode.installed:
        navigate(installedApplicationViewPath(app.applicationUid), {
          state: { backTo: "Installed Applications" },
        });
        break;
      case ApplicationsMode.managed:
        navigate(manageApplicationViewPath(app.applicationUid), {
          state: { backTo: "Manage Applications" },
        });
        break;
    }
  };

  return (
    <div className="flex-width-card" css={tw`p-2`}>
      <div
        data-test-id="applicationCard"
        css={tw`rounded-lg bg-core-grey-dark p-4 flex gap-3 flex-col h-full hover:bg-primary hover:cursor-pointer`}
        onClick={() => {
          handleNavigateOnClick();
        }}
      >
        <div css={tw`flex-1 flex gap-4 justify-between`}>
          <h3 css={tw`m-0 break-words flex-1`}>
            {mode === ApplicationsMode.installed
              ? app.instanceName
              : app.applicationName}
          </h3>
          <div>
            <img src={iconSvg} alt="application icon" width="65" height="65" />
          </div>
        </div>
        {mode === ApplicationsMode.installed ? (
          <InstalledApplicationContent app={app} mode={mode} />
        ) : (
          <MarketplaceCardContent app={app} mode={mode} />
        )}
      </div>
    </div>
  );
};

const InstalledApplicationContent = ({ app, mode }: CommonAppProps) => {
  return (
    <>
      <AppStats app={app} mode={mode} appListView={AppListView.Grid} />
      <AppSitesAndBuildings app={app} appListView={AppListView.Grid} />
      <AppCategories app={app} />
      {app.lastKnownRun && (
        <span>
          <Icon name="refresh" />
          {formatDate(app.lastKnownRun, DATE_TIME_FORMAT_WITH_AMPM)}
        </span>
      )}
    </>
  );
};

const MarketplaceCardContent = ({ app, mode }: CommonAppProps) => {
  return (
    <>
      <div>{`by ${app.vendorId}`}</div>
      <div>{app.description}</div>
      <AppCategories app={app} />
      <AppStats app={app} mode={mode} appListView={AppListView.Grid} />
    </>
  );
};
