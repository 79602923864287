/* @jsxImportSource @emotion/react */
import React, { Dispatch, SetStateAction } from "react";
import tw from "twin.macro";
import {
  CheckboxInput,
  EMPTY_DESCRIPTION,
  ProcessingStatusLabel,
} from "components/shared";
import {
  AcceptAgreementRequest,
  AgreementId,
  enodiaApi,
  InvitationId,
  InvitationResponse,
  OrgId,
  RequiredAgreement,
  RolesAndGroupsDescription,
} from "data/Enodia";
import { Link } from "react-router-dom";
import { Button, Icon, SemanticWIDTHS, Table } from "semantic-ui-react";

/**
 * DEFINITIONS
 */
export const InvitationTableWidths = {
  rowHeader: 3 as SemanticWIDTHS,
  rowContent: 13 as SemanticWIDTHS,
  name: 6 as SemanticWIDTHS,
  description: 10 as SemanticWIDTHS,
};

export enum InvitationState {
  AcceptedAll = "Accepted All",
  RejectedAll = "Rejected All",
  Accepted = "Accepted",
  Rejected = "Rejected",
}

export enum DescriptionType {
  RoleSet = "roleset",
  UserGroup = "user group",
}
export interface GroupedInvitations {
  [name: string]: InvitationResponse[];
}

export interface InvitationStateById {
  [id: string]: InvitationState | undefined;
}

/** HELPERS */
export const groupInvitationsByOrg = (invitations: InvitationResponse[]) => {
  const invitationsByOrg: GroupedInvitations = {};
  for (const invitation of invitations) {
    const orgName = invitation.organisation.name;

    if (!invitationsByOrg[orgName]) {
      invitationsByOrg[orgName] = [];
    }

    invitationsByOrg[orgName].push(invitation);
  }

  return invitationsByOrg;
};

export const filterOutRejectedInvitationIds = (
  invitations: string[],
  exclusionList: string[]
) => {
  const exclusionSet = new Set(exclusionList);

  const result = invitations.filter(
    (invitationId) => !exclusionSet.has(invitationId)
  );
  return result;
};

/**
 * PROMISES
 */
export const acceptAgreementPromise = (
  invitationId: string,
  agreementId: AgreementId,
  orgId: OrgId
) => {
  return new Promise((resolve, reject) => {
    enodiaApi
      .postAcceptAgreements([
        {
          agreementId: agreementId as AgreementId,
          forOrganisation: orgId as OrgId,
        },
      ])
      .then(
        () => resolve(invitationId),
        () => reject(invitationId)
      );
  });
};

export const acceptInvitePromise = (invId: string) => {
  return new Promise((resolve, reject) => {
    enodiaApi.acceptInvitation(invId).then(
      () => resolve(invId),
      () => reject(invId)
    );
  });
};

/** COMPONENTS */
export const AcceptRejectButtons = ({
  invitationState,
  onReject,
  onAccept,
  isSelectAll,
  isBothDisabled,
  isAcceptDisabled,
}: {
  invitationState: InvitationState | undefined;
  onReject: () => void;
  onAccept: () => void;
  isSelectAll?: boolean;
  isBothDisabled?: boolean;
  isAcceptDisabled?: boolean;
}) => {
  return invitationState ? (
    <ProcessingStatusLabel status={invitationState} />
  ) : (
    <>
      <div>
        <Button
          disabled={isBothDisabled}
          basic
          inverted
          size="tiny"
          content={`Reject${isSelectAll ? " All" : ""}`}
          onClick={onReject}
        />
        <Button
          disabled={isBothDisabled || isAcceptDisabled}
          basic
          inverted
          size="tiny"
          content={`Accept${isSelectAll ? " All" : ""}`}
          onClick={onAccept}
        />
      </div>
    </>
  );
};

export const RequiredAgreements = ({
  authorityName,
  agreements,
  invitationId,
  setAgreementsAccepted,
  termsAgreed,
  setTermsAgreed,
  orgId,
}: {
  authorityName: string;
  agreements: RequiredAgreement[];
  invitationId: InvitationId;
  setAgreementsAccepted: Dispatch<SetStateAction<number>>;
  termsAgreed: Map<string, AcceptAgreementRequest>;
  setTermsAgreed: Dispatch<SetStateAction<Map<string, AcceptAgreementRequest>>>;
  orgId: OrgId;
}) => {
  const handleOnChange = (id: AgreementId, orgId: OrgId) => {
    const updatedCheckedState = new Map(termsAgreed);
    // key will be appended with its child, such that entry is unique
    const key = `${id}_${orgId}`;
    if (updatedCheckedState.get(key)) {
      updatedCheckedState.delete(key);
      setAgreementsAccepted((p) => p - 1);
    } else {
      updatedCheckedState.set(key, {
        agreementId: id,
        forOrganisation: orgId,
      });
      setAgreementsAccepted((p) => p + 1);
    }
    setTermsAgreed(updatedCheckedState);
  };

  return (
    <>
      <div css={tw`flex pt-2`} key={`invitation-agreements-${invitationId}`}>
        <div css={tw`pt-2`}>
          <Icon className="purpleLight" size="large" name="exclamation" />
        </div>
        <div>
          You have been invited to this organisation as a {authorityName}. Your
          use of the DCH Portal is subject to your compliance with the DCH
          Connection and Access License Agreement and our Privacy Collection
          Notice.
          {agreements.map((agreement) => (
            <CheckboxInput
              dataTestId="required-checkbox"
              customCss={tw`pt-2`}
              key={`agreement_checkbox`}
              checkboxLabel={
                <Link to={agreement.href} target="_blank">
                  {agreement.title}
                </Link>
              }
              value={!!termsAgreed.get(`${agreement.agreementId}_${orgId}`)}
              onChange={() => handleOnChange(agreement.agreementId, orgId)}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export const InvitationDescriptionRow = ({
  descriptions,
  type,
  disabled,
}: {
  descriptions: RolesAndGroupsDescription[];
  type: DescriptionType;
  disabled: boolean;
}) => {
  const disabledStyle = tw`text-core-grey`;
  return (
    <Table.Row css={disabled && disabledStyle}>
      <Table.Cell width={InvitationTableWidths.rowHeader}>
        <b>
          {type === DescriptionType.RoleSet ? "Roleset(s)" : "User Group(s)"}
        </b>
      </Table.Cell>
      <Table.Cell width={InvitationTableWidths.rowContent}>
        <Table basic inverted compact="very">
          <Table.Body>
            {descriptions.length === 0 ? (
              <Table.Row>
                <Table.Cell>
                  <em css={disabled && disabledStyle}>No {type} invited</em>
                </Table.Cell>
              </Table.Row>
            ) : (
              descriptions.map((roleOrGroup, index) => (
                <Table.Row
                  key={`roleset-${roleOrGroup.id}-${index}`}
                  css={disabled && disabledStyle}
                >
                  <Table.Cell
                    css={tw`break-words`}
                    width={InvitationTableWidths.name}
                  >
                    {roleOrGroup.name}
                  </Table.Cell>
                  <Table.Cell width={InvitationTableWidths.description}>
                    {roleOrGroup.description || <i>{EMPTY_DESCRIPTION}</i>}
                  </Table.Cell>
                </Table.Row>
              ))
            )}
          </Table.Body>
        </Table>
      </Table.Cell>
    </Table.Row>
  );
};
