import * as O from "fp-ts/lib/Option";
import { httpDelete, httpGet, httpPostFile, httpPut } from "data/httpUtil";
import { ClientEnv } from "data/utils";
import { metisEnv } from "reducers/env";
import {
  ApplicationId,
  ApplicationRecipeDetailResponse,
  ApplicationVersionPutRequest,
  ApplicationVersionFullResponse,
  AvailableApplicationResponse,
  GetApplicationsSearchParams,
  ApplicationUid,
  CommonApplicationRecipeData,
  ApplicationRecipeUploadResponse,
} from "./metisTypes";
import { VendorUid } from "./Vendor/vendorTypes";

export type ManageApplicationsApi = {
  getManageApplicationRecipes: (
    searchParams: GetApplicationsSearchParams
  ) => Promise<Array<AvailableApplicationResponse>>;
  getManageApplicationRecipe: (
    applicationId: string
  ) => Promise<ApplicationRecipeDetailResponse>;
  getManageApplicationRecipeVersions: (
    applicationId: ApplicationId
  ) => Promise<Array<ApplicationVersionFullResponse>>;
  putManageApplicationRecipe: (
    applicationId: ApplicationId,
    applicationData: CommonApplicationRecipeData
  ) => Promise<void>;
  putManageApplicationRecipeVersion: (
    applicationUid: ApplicationUid,
    versionUid: string,
    applicationVersion: ApplicationVersionPutRequest
  ) => Promise<void>;
  deleteApplicationRecipe: (applicationId: ApplicationId) => Promise<void>;
  publishApplication: (applicationId: string) => Promise<void>;
  syncOperatorDeployment: (operatorId: string) => Promise<void>;
  publishApplicationVersion: (
    applicationId: string,
    applicationVersionId: string
  ) => Promise<void>;
  uploadApplicationRecipe: (
    vendorUid: VendorUid
  ) => (archive: File) => Promise<ApplicationRecipeUploadResponse>;
};

const mkManageApplicationsApi = (env: ClientEnv): ManageApplicationsApi => ({
  publishApplication: (applicationId: string) =>
    httpPut(null)(O.none)(env)(null)(
      `/manage/application/recipes/${applicationId}/publish`
    ).then((_) => undefined),
  syncOperatorDeployment: (operatorId: string) =>
    httpPut(null)(O.none)(env)(null)(
      `/manage/operators/${operatorId}/sync-deployment`
    ).then((_) => undefined),
  publishApplicationVersion: (
    applicationId: string,
    applicationVersionId: string
  ) =>
    httpPut(null)(O.none)(env)(null)(
      `/manage/application/recipes/${applicationId}/versions/${applicationVersionId}/publish`
    ).then((_) => undefined),
  getManageApplicationRecipes: (queryParams: GetApplicationsSearchParams) =>
    httpGet(O.none)(env)(queryParams)("/manage/application/recipes").then(
      (r) => r as Array<AvailableApplicationResponse>
    ),
  getManageApplicationRecipe: (applicationId: string) =>
    httpGet(O.none)(env)(null)(
      `/manage/application/recipes/${applicationId}`
    ).then((r) => r as ApplicationRecipeDetailResponse),
  getManageApplicationRecipeVersions: (applicationId: ApplicationId) =>
    httpGet(O.none)(env)(null)(
      `/manage/application/recipes/${applicationId}/versions`
    ).then((r) => r as Array<ApplicationVersionFullResponse>),
  putManageApplicationRecipe: (
    applicationId: ApplicationId,
    applicationData: CommonApplicationRecipeData
  ) =>
    httpPut(applicationData)(O.none)(env)(null)(
      `/manage/application/recipes/${applicationId}`
    ).then((_) => undefined),
  putManageApplicationRecipeVersion: (
    applicationUid: ApplicationUid,
    versionUid: string,
    applicationVersion: ApplicationVersionPutRequest
  ) =>
    httpPut(applicationVersion)(O.none)(env)(null)(
      `/manage/application/recipes/${applicationUid}/versions/${versionUid}`
    ).then((_) => undefined),
  deleteApplicationRecipe: (applicationId: ApplicationId) =>
    httpDelete(O.none)(env)(null)(
      `/manage/application/recipes/${applicationId}`
    ).then((_) => undefined),
  uploadApplicationRecipe: (vendorUid: VendorUid) => (archive: File) => {
    const formData = new FormData();
    formData.append("recipeArchive", archive);
    //to upload as multipart/form-data, must clear default headers (per https://muffinman.io/blog/uploading-files-using-fetch-multipart-form-data/)
    return httpPostFile(formData)(O.none)({ ...env, headers: {} })({})(
      `/manage/application/recipes/${vendorUid}`
    ).then((r) => r as ApplicationRecipeUploadResponse);
  },
});

export const manageApplicationsApi = mkManageApplicationsApi(metisEnv);
