import {
  Classifier,
  ClassifierFilterOptions,
  ClassifierId,
  ClassifierRuleOptions,
} from "data/Aletheia";
import React, {
  Dispatch,
  FC,
  Fragment,
  SetStateAction,
  useEffect,
} from "react";
import { Icon, Table } from "semantic-ui-react";
import { flexRender, Table as TanstackTable } from "@tanstack/react-table";
import {
  ClassifierSetMetadata,
  createNewClassifier,
} from "./ClassifierSetMetadataUtils";
import { FieldValues, useFormContext } from "react-hook-form";
import EntityPropertiesClassifierAccordion from "../EntityProperties/EntityPropertiesClassifierAccordion";
import { UIStatus } from "components/shared";
import { Unit } from "data/Mason";
import Form from "components/shared/Forms/ReactHookForm";

export interface ClassifierSetFormFields extends FieldValues {
  classifiers: ClassifierFormValue[];
}

export type ClassifierFormValue = {
  executeRules: ClassifierRuleOptions;
  filterBy: ClassifierFilterOptions;
  enabled: boolean;
  id: ClassifierId;
};

type FormTableProps = {
  classifiers: Classifier[];
  table: TanstackTable<Classifier>;
  setClassifierSet: Dispatch<SetStateAction<ClassifierSetMetadata>>;
  classifierHighlightIndex?: number;
  setCanSave: Dispatch<SetStateAction<boolean>>;
  setUIStatus: React.Dispatch<React.SetStateAction<UIStatus>>;
  unitsList: Array<Unit>;
};

const classifiersToClassiferFormValues = (classifiers: Array<Classifier>) => {
  return {
    classifiers: classifiers.map((c) => ({
      id: c.id,
      enabled: c.enabled,
      executeRules: { ...c.executeRules },
      filterBy: { ...c.filterBy },
    })),
  };
};

const ClassifierSetFormTable: FC<FormTableProps> = ({
  classifiers,
  table,
  setClassifierSet,
  classifierHighlightIndex,
  setCanSave,
  setUIStatus,
  unitsList,
}) => {
  const { reset, getValues } = useFormContext<ClassifierSetFormFields>();

  useEffect(() => {
    if (classifiers) {
      reset(classifiersToClassiferFormValues(classifiers));
    }
  }, [classifiers, reset]);

  const addNewClassifier = () => {
    // TODO: aletheiaApi.newClassifier(): success, grab the id to create new Classifier
    // TODO: put this in the .then and replace v4 with the returned ID
    const newClassifier = createNewClassifier();

    /** @deprecated - replaced with field array appending below - however this is implemented to keep tanstack table happy (not reading undefined values for new rows) */
    setClassifierSet((p) => ({
      ...p,
      classifiers: [...getValues("classifiers"), newClassifier],
    }));
  };

  const deleteClassifier = (id: ClassifierId, index: number) => {
    setClassifierSet((p) => ({
      ...p,
      classifiers: getValues("classifiers").filter((x) => x.id !== id),
    }));
  };

  return (
    <Table inverted compact="very">
      <Table.Header>
        {table.getHeaderGroups().map((headerGroup) => (
          <Table.Row className="entity-properties-headers" key={headerGroup.id}>
            {headerGroup.headers.map((header, index) => (
              <Table.HeaderCell key={header.id} colSpan={header.colSpan}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                <div />
              </Table.HeaderCell>
            ))}
            <Table.HeaderCell textAlign="right">
              {headerGroup.depth === 0 && (
                <Form.Button
                  submit={false}
                  onClick={addNewClassifier}
                  label="+"
                />
              )}
            </Table.HeaderCell>
          </Table.Row>
        ))}
      </Table.Header>

      <Table.Body>
        {table.getRowModel().rows.map((row, index) => {
          if (!row) {
            console.error(`Row at index ${index} is undefined`);
            return null;
          }

          return (
            <Fragment key={row.id}>
              <Table.Row
                key={row.id}
                active={index === classifierHighlightIndex}
              >
                {row.getVisibleCells().map((cell) => (
                  <Table.Cell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.Cell>
                ))}
                <Table.Cell textAlign="right">
                  <Icon
                    color="red"
                    name="times"
                    onClick={() => {
                      setCanSave(true);
                      deleteClassifier(row.original.id, index);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              {row.getIsExpanded() && (
                <Table.Row>
                  <Table.Cell colSpan={row.getVisibleCells().length}>
                    <EntityPropertiesClassifierAccordion
                      row={row}
                      formFieldName={""}
                      setCanSave={setCanSave}
                      setUIStatus={setUIStatus}
                      unitsList={unitsList}
                    />
                  </Table.Cell>
                </Table.Row>
              )}
            </Fragment>
          );
        })}
      </Table.Body>
    </Table>
  );
};

export default ClassifierSetFormTable;
