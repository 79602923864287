import { UIStatus } from "components/shared";
import {
  DataPoolUid,
  PointGetParams,
  PointListItem,
  aletheiaApi,
} from "data/Aletheia";
import { OrgId } from "data/Enodia";
import { getPaginatedFromURL } from "data/httpUtil";
import {
  DEFAULT_PAGINATION_DATA_LIMIT,
  PaginatedResponse,
} from "data/paginationHelper";
import { Dispatch, SetStateAction, useState } from "react";
import { aletheiaEnv } from "reducers/env";

export type PageState = {
  orgId?: OrgId;
  points: PointListItem[];
  links: Record<string, string> | undefined;
  pointsStatus: UIStatus;
  pointSearchParams: PointGetParams;
};

export type PointTablePageState = {
  setOrgId: (orgId?: OrgId) => void;
  setDataPoolId: (dataPoolId?: DataPoolUid) => void;
  setPoints: (points: PointListItem[]) => void;
  setPointsStatus: Dispatch<SetStateAction<UIStatus>>;
  setLinks: (links: Record<string, string> | undefined) => void;
  setPointSearchParams: (params: PointGetParams) => void;
  getPoints: () => void;
  state: PageState;
};

export const usePointTablePageState = () => {
  const [pointsStatus, setPointsStatus] = useState(new UIStatus());

  const [state, setPageState] = useState<PageState>({
    orgId: undefined,
    points: [],
    links: undefined,
    pointsStatus: pointsStatus,
    pointSearchParams: {
      limit: DEFAULT_PAGINATION_DATA_LIMIT,
    },
  });

  const setOrgId = (orgId?: OrgId) => {
    setPageState((currentState: PageState) => ({
      ...currentState,
      orgId: orgId,
    }));
    pageState.setPointSearchParams({
      organisationid: orgId,
    });
  };

  const setDataPoolId = (dataPoolId?: string) => {
    setPageState((currentState: PageState) => ({
      ...currentState,
      pointSearchParams: {
        ...currentState.pointSearchParams,
        datapoolid: dataPoolId,
      },
    }));
  };

  const setPoints = (points: PointListItem[]) => {
    setPageState((currentState: PageState) => ({
      ...currentState,
      points: points,
    }));
  };

  const getPoints = () => {
    fetchPoints(pageState);
  };

  const setLinks = (links: Record<string, string> | undefined) => {
    setPageState((currentState: PageState) => ({
      ...currentState,
      links: links,
    }));
  };

  const setPointSearchParams = (params: PointGetParams) => {
    setPageState((currentState: PageState) => ({
      ...currentState,
      pointSearchParams: { ...currentState.pointSearchParams, ...params },
    }));
  };

  const pageState = {
    state,
    setOrgId,
    setPoints,
    setDataPoolId,
    setPointsStatus,
    setLinks,
    setPointSearchParams,
    getPoints,
  };

  return pageState;
};

function processFetchPoints(
  promise: Promise<PaginatedResponse<PointListItem[]>>,
  pageState: PointTablePageState
) {
  promise
    .then((response) => {
      pageState.setPoints(response.data);
      pageState.setLinks(response.links);
      pageState.setPointsStatus((prevState) =>
        prevState.setIndeterminate(false)
      );
    })
    .catch((e) => {
      pageState.setPointsStatus((prevState) =>
        prevState.setError(
          e.message ??
            `Error fetching points: No points found for the given parameters ${pageState.state.pointSearchParams}`
        )
      );
    });
}

export function fetchPointsByLink(
  link: string,
  pageState: PointTablePageState
) {
  pageState.setPointsStatus((prevState) => prevState.setIndeterminate(true));
  processFetchPoints(
    getPaginatedFromURL(link)(aletheiaEnv).then(
      (response) => response as PaginatedResponse<PointListItem[]>
    ),
    pageState
  );
}

//exclude undefined properties from queryParams
function excludeUndefinedProps(obj: PointGetParams) {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => value !== undefined)
  );
}

export function fetchPoints(pageState: PointTablePageState) {
  pageState.setPointsStatus((prevState) => prevState.setIndeterminate(true));
  processFetchPoints(
    aletheiaApi.getPoints(
      excludeUndefinedProps(pageState.state.pointSearchParams)
    ),
    pageState
  );
}
