/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import { isBefore, parseISO } from "date-fns";
import { Button, Icon, Radio, Table } from "semantic-ui-react";
import tw from "twin.macro";
import { ApiKey, ApiKeyId, ApiKeyResponse, enodiaApi } from "data/Enodia";
import {
  formatDateAsString,
  Page,
  PageTitle,
  UIStatus,
  UIStatusWrapper,
} from "components/shared";
import ApiKeyModal, { ModalMode } from "./ApiKeyModal";

const convertToIso = (expiry: string) => {
  //currently the API is returning expiry in ISO but without the trailing 'Z' - add it to convert correctly
  const expiryString = !expiry.endsWith("Z") ? (expiry += "Z") : expiry;
  return formatDateAsString(new Date(expiryString));
};

const isKeyExpired = (expiry: string | undefined) => {
  if (!expiry) return false;
  return isBefore(parseISO(expiry), new Date());
};

const renderExpiry = (expiry: string, isExpired: boolean) => {
  return isExpired ? (
    <div>
      <span css={tw`line-through`}>{expiry}</span>
      <p css={tw`text-sm italic text-red-error`}>expired</p>
    </div>
  ) : (
    expiry
  );
};

const renderNoData = () => {
  return (
    <Table.Row>
      <Table.Cell>No Keys Found</Table.Cell>
    </Table.Row>
  );
};

const renderApiKey = (
  key: ApiKey,
  setModalMode: (_?: ModalMode) => void,
  setModalKey: React.Dispatch<React.SetStateAction<ApiKey>>,
  toggleEnabled: () => void
) => {
  const isExpired: boolean = isKeyExpired(key?.expires);
  return (
    <Table.Row key={`apikey_${key.name}_${key.keyTail}`}>
      <Table.Cell>{key.name}</Table.Cell>
      <Table.Cell>{key.keyTail && `******${key.keyTail}`}</Table.Cell>
      <Table.Cell>
        {key.expires ? renderExpiry(key.expires, isExpired) : null}
      </Table.Cell>
      <Table.Cell>
        <Radio
          toggle
          checked={key.enabled}
          disabled={isExpired}
          onChange={(_) => toggleEnabled()}
        />
      </Table.Cell>
      <Table.Cell textAlign="right">
        <Button
          primary
          size="small"
          onClick={() => {
            setModalMode(ModalMode.edit);
            setModalKey(key);
          }}
        >
          Edit
        </Button>
        <Button
          basic
          inverted
          size="small"
          onClick={() => {
            setModalMode(ModalMode.delete);
            setModalKey(key);
          }}
        >
          Delete
        </Button>
      </Table.Cell>
    </Table.Row>
  );
};

const newApiKey = {
  name: "",
  enabled: true,
  id: "" as ApiKeyId,
  keyHead: "",
  keyTail: "",
  created: "",
  key: "",
};
const ApiKeys = () => {
  const [apiKeys, setApiKeys] = useState<ApiKey[]>();
  const [status, setStatus] = useState(new UIStatus());
  const [modalMode, setModalMode] = useState<ModalMode>();
  const [modalKey, setModalKey] = useState<ApiKey>(newApiKey);

  const getKeys = () => {
    setStatus((prev) => prev.setIndeterminate(true));
    enodiaApi.getApiKeys().then(
      (keys) => {
        setApiKeys(
          keys.map((k) => {
            return {
              ...k,
              expires: k.expires ? convertToIso(k.expires) : undefined,
              enabled:
                k.enabled &&
                (!k.expires || !isKeyExpired(convertToIso(k.expires))),
              key: "",
            };
          })
        );
        setStatus((prev) => prev.setIndeterminate(false));
      },
      (e) => {
        setStatus((prevState) =>
          prevState.setError(`Error fetching API keys.`)
        );
      }
    );
  };

  useEffect(() => {
    getKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalMode]);

  const toggleEnabled = (key: ApiKeyResponse) => {
    if (key.id)
      enodiaApi
        .patchApiKey(key.id)({ name: key.name, enabled: !key.enabled })
        .then((_) => getKeys());
  };

  return (
    <Page>
      <ApiKeyModal
        open={modalMode !== undefined}
        onClose={() => setModalMode(undefined)}
        apiKey={modalKey}
        modalMode={modalMode}
        setApiKey={setModalKey}
        setModalMode={setModalMode}
      />
      <PageTitle
        primaryHeader={
          <span>
            <Icon name="key" color="purple" inverted />
            API Keys
          </span>
        }
        subHeader="Create and manage your API keys"
      />

      <Button
        primary
        onClick={() => {
          setModalKey(newApiKey);
          setModalMode(ModalMode.create);
        }}
      >
        <Icon name="plus" />
        Create a new key
      </Button>
      <UIStatusWrapper status={status} fitted>
        <Table inverted striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Key</Table.HeaderCell>
              <Table.HeaderCell>Expires</Table.HeaderCell>
              <Table.HeaderCell>Enabled</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {apiKeys?.length === 0 && status.isTruthy()
              ? renderNoData()
              : apiKeys?.map((key) =>
                  renderApiKey(key, setModalMode, setModalKey, () =>
                    toggleEnabled(key)
                  )
                )}
          </Table.Body>
        </Table>
      </UIStatusWrapper>
    </Page>
  );
};

export default ApiKeys;
