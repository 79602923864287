/** @jsxImportSource @emotion/react */

import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { Accordion, Header, Icon } from "semantic-ui-react";
import tw from "twin.macro";
import { EnodiaOrgContext } from "App";
import { Path } from "Routes";
import OrgContextModal from "context/OrgContextModal";
import {
  DchModal,
  PageMode,
  UIStatus,
  changeOrgNoResetMessage,
  redirectMessage,
  resetMessage,
} from "components/shared";
import { SiteOrBuildingParams } from "../Common";
import {
  SiteOrBuildingMetadata,
  SiteOrBuildingMetadataProps,
} from "./Metadata";
import { ApiError } from "data/http";

export type SiteOrBuildingMetadataAccordionProps = {
  hasUnsavedChanges: boolean;
  setPageError: Dispatch<SetStateAction<ApiError | undefined>>;
  setPageStatus: Dispatch<SetStateAction<UIStatus>>;
} & SiteOrBuildingMetadataProps;

const UnsavedChangesModal = ({
  setAccordionOpen,
  showResetModal,
  setShowResetModal,
  reset,
  formRef,
}: SiteOrBuildingMetadataAccordionProps & {
  setAccordionOpen: React.Dispatch<React.SetStateAction<boolean>>;
  showResetModal: boolean;
  setShowResetModal: (_: boolean) => void;
  reset?: any;
}) => {
  const handleClose = () => {
    setShowResetModal(false);
    setAccordionOpen((prev) => !prev);
  };
  return (
    <DchModal
      header={`Unsaved changes`}
      content={<p>Do you wish to discard or save changes?</p>}
      open={showResetModal}
      onConfirm={() => {
        formRef?.current?.dispatchEvent(
          new Event("submit", { cancelable: true, bubbles: true }),
        );
        handleClose();
      }}
      onCancel={() => {
        reset?.();
        handleClose();
      }}
      onClose={() => handleClose()}
      confirmText="Save"
      cancelText="Discard"
    />
  );
};

const OrgContextChangeModal = ({
  showModal,
  setShowModal,
  isBuilding,
  pageMode,
  resetField,
  formSiteValue,
}: {
  showModal: boolean;
  setShowModal: (_: boolean) => void;
  isBuilding: boolean;
  pageMode: PageMode;
  formSiteValue?: string;
  resetField?: any;
}) => {
  const params = useParams<SiteOrBuildingParams>();
  const { orgId } = useContext(EnodiaOrgContext);
  const navigate = useNavigate();
  const isCreateMode = pageMode === PageMode.create && _.isEmpty(params); //Add New Site / Add New Building where SiteId not populated
  const modalMessage = isCreateMode
    ? isBuilding
      ? resetMessage
      : changeOrgNoResetMessage
    : redirectMessage;

  // only want to reset fields dependent on the orgId -> site ID
  const onResetFormConfirm = () => {
    if (isBuilding) {
      resetField("siteId");
    }
  };
  const onRedirectPageConfirm = () => {
    navigate(Path.Home);
  };
  return (
    <OrgContextModal
      open={showModal}
      openCondition={
        (isBuilding && pageMode === PageMode.create && !!formSiteValue) ||
        !!(params.orgId && params.orgId !== orgId)
      }
      onConfirm={() => {
        _.isEmpty(params) ? onResetFormConfirm() : onRedirectPageConfirm();
      }}
      modalMessage={modalMessage}
      setModalState={setShowModal}
    />
  );
};

export const SiteOrBuildingMetadataAccordion = (
  props: SiteOrBuildingMetadataAccordionProps,
) => {
  const { pageMode, hasUnsavedChanges, modelType, isBuilding } = props;

  const [accordionOpen, setAccordionOpen] = useState(true);
  const [showResetModal, setShowResetModal] = useState(false);
  const [showOrgContextChangeModal, setShowOrgContextChangeModal] =
    useState(false);
  const [resetMethod, setResetMethod] = useState<() => () => void>();
  const [resetFieldMethod, setResetFieldMethod] =
    useState<() => (name: string) => void>();
  const [formSiteValue, setFormSiteValue] = useState<string>();
  const formRef = useRef<HTMLFormElement>(null);

  // Hack to ensure that the map is loaded correctly. Open the accordion to begin with and close once the meta has been loaded
  const [hasLoadedMeta, setHasLoadedMeta] = useState<boolean>(false);
  useEffect(() => {
    if (!hasLoadedMeta) {
      setAccordionOpen(false);
    }
  }, [hasLoadedMeta, setAccordionOpen]);

  return (
    <>
      <UnsavedChangesModal
        {...props}
        setAccordionOpen={setAccordionOpen}
        showResetModal={showResetModal}
        setShowResetModal={setShowResetModal}
        reset={resetMethod}
        formRef={formRef}
      />
      <OrgContextChangeModal
        showModal={showOrgContextChangeModal}
        setShowModal={setShowOrgContextChangeModal}
        isBuilding={isBuilding}
        pageMode={pageMode}
        resetField={resetFieldMethod}
        formSiteValue={formSiteValue}
      />
      <Accordion>
        <Accordion.Title
          active={accordionOpen || pageMode === PageMode.create}
          onClick={() => {
            if (pageMode === PageMode.create) {
              return;
            }
            if (hasUnsavedChanges && accordionOpen) {
              setShowResetModal(true);
            } else {
              setAccordionOpen(!accordionOpen);
            }
          }}
        >
          <Header>
            <span css={tw`cursor-pointer flex items-center`}>
              <Icon
                name="dropdown"
                rotated={
                  accordionOpen || pageMode === PageMode.create
                    ? "clockwise"
                    : undefined
                }
              />
              {modelType} Information
            </span>
          </Header>
        </Accordion.Title>
        <Accordion.Content
          active={accordionOpen || pageMode === PageMode.create}
        >
          <SiteOrBuildingMetadata
            {...props}
            setHasLoadedMeta={setHasLoadedMeta}
            setReset={(resetMethod: () => void) =>
              setResetMethod(() => resetMethod)
            }
            setResetField={(resetFieldMethod: (name: string) => void) =>
              setResetFieldMethod(
                () => (name: string) => resetFieldMethod(name),
              )
            }
            setFormSiteValue={(value: string) => setFormSiteValue(value)}
            formRef={formRef}
          />
        </Accordion.Content>
      </Accordion>
    </>
  );
};
