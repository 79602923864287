import React from "react";
import {
  Controller,
  FieldValues,
  RegisterOptions,
  useFormContext,
} from "react-hook-form";
import {
  CheckboxInput,
  CheckboxInputProps,
} from "components/shared/InputFields/Inputs/CheckboxInput";
import { FormInputProps } from "../ReactHookForm";

export type FormCheckboxInputProps<TFieldValues extends FieldValues> =
  FormInputProps<TFieldValues> & CheckboxInputProps;

export const FormCheckboxInput = <TFieldValues extends FieldValues>(
  props: FormCheckboxInputProps<TFieldValues>
) => {
  let rules = props.rules as Omit<
    RegisterOptions<FieldValues>,
    "setValueAs" | "disabled" | "valueAsNumber" | "valueAsDate"
  >;
  const { control } = useFormContext();

  return (
    <Controller
      name={props.name}
      control={control}
      rules={rules}
      defaultValue={!!props.defaultValue as TFieldValues[keyof TFieldValues]}
      render={({ field: { value, onChange }, fieldState }) => {
        const inputProps = {
          value: value !== undefined && value,
          onChange: (_: any, d: any) => onChange(d.checked),
        };
        return <CheckboxInput {...props} {...inputProps} />;
      }}
    />
  );
};
