import { pipe } from "fp-ts/lib/function";
import { BrickProperty } from "data/Models/Brick/brickRelationships";
import { MODEL_NODE_NAME } from "data/QueryApi/queryApiUtils";
import {
  MatchType,
  Match,
  OrderHint,
  Query,
  QueryMode,
  QueryVarType,
  VarFields,
} from "data/QueryApi/queryTypes";

export enum LocationsAndChildrenVars {
  Point = "P",
  Location = "L",
  LocationParent1 = "L1",
  LocationParent2 = "L2",
  Equipment = "E",
  EquipmentParent1 = "E1",
  EquipmentParent2 = "E2",
  Node1 = "node1",
  Parent1 = "parent1",
  Parent2 = "parent2",
}

const filterKeywords = (keywords: string) => (q: Query) =>
  keywords
    ? {
        ...q,
        variables: q.variables.map((v) => ({
          ...v,
          filterOn: ["id", "label"],
          filterString: keywords,
        })),
      }
    : q;

const mapClassTypes = (
  subclasses: string[],
  includeSubclasses: boolean
): MatchType[] => {
  const match = includeSubclasses ? Match.isa : Match.equalsOrEquivalent;
  return subclasses.length > 0
    ? subclasses.map((subclass) => ({ match, type: subclass } as MatchType))
    : [];
};

const fetchPoints = (
  pointTypes: string[],
  includeSubclasses: boolean
): MatchType[] =>
  pointTypes.map(
    (point) =>
      ({
        match: includeSubclasses ? Match.isa : Match.equalsOrEquivalent,
        type: point,
      } as MatchType)
  );

export const locationsOrEquipmentAndChildren = (
  types: string[],
  pointTypes: string[],
  includePointsSubclasses: boolean,
  includeSubclasses: boolean
): Query => {
  let brickTypes = mapClassTypes(types, includeSubclasses);
  if (brickTypes.length === 0) {
    brickTypes = [
      {
        match: Match.isa,
        type: "Location",
      },
      {
        match: Match.isa,
        type: "Equipment",
      },
    ];
  }
  return {
    comment:
      "Select locations by type, their points, and the points of their children. Get parents for breadcrumb. Filter by point type.",
    mode: QueryMode.select,
    variables: [
      {
        comment: "A point",
        varType: QueryVarType.node,
        name: LocationsAndChildrenVars.Node1,
        output: true,
        fetch: [
          VarFields.id,
          VarFields.type,
          VarFields.hypernym,
          VarFields.pointInfo,
        ],
        pointsOptional: false,
        brickTypes: brickTypes,
        fetchPoints: fetchPoints(pointTypes, includePointsSubclasses),
        // if the user specifies any point types (other than "Point"), add "orderHint":["fetchPoints"].
        // if the user doesn't specify any point types, do not add this line.
        // This hint will improve performance and reduce temporary memory usage during execution.
        orderHint:
          pointTypes.length === 0 ||
          (pointTypes.length === 1 && pointTypes[0] === "Point")
            ? undefined
            : [OrderHint.fetchPoints],
      },

      // Note that one optional variable may be defined with a nested optional variable 'inside' it.
      // In this case, parent2 will never be non-null if parent1 is null.
      {
        varType: QueryVarType.node,
        comment: "The node's immediate parent",
        name: LocationsAndChildrenVars.Parent1,
        output: true,
        fetch: [VarFields.id, VarFields.type],
        nullable: true,
        constraints: {
          paths: [
            {
              fromRef: LocationsAndChildrenVars.Parent1,
              properties: [
                {
                  property: BrickProperty.hasPart,
                },
              ],
              toRef: LocationsAndChildrenVars.Node1,
            },
          ],
        },

        // Note that one optional variable may be defined with a nested optional variable 'inside' it.
        // In this case, parent2 will never be non-null if parent1 is null.
        nested: [
          {
            varType: QueryVarType.node,
            comment: "The node's next level parent",
            name: LocationsAndChildrenVars.Parent2,
            output: true,
            fetch: [VarFields.id, VarFields.type],
            nullable: true,
            constraints: {
              paths: [
                {
                  fromRef: LocationsAndChildrenVars.Parent2,
                  properties: [{ property: BrickProperty.hasPart }],
                  toRef: LocationsAndChildrenVars.Parent1,
                },
              ],
            },
          },
        ],
      },
    ],
  };
};

export const locationsAndEquipmentAndChildren = (
  locations: string[],
  equipment: string[],
  points: string[],
  includeLocationsSubclasses: boolean,
  includeEquipmentSubclasses: boolean,
  includePointSubclasses: boolean
): Query => ({
  comment: "",
  mode: QueryMode.select,
  variables: [
    {
      comment: "A point",
      varType: QueryVarType.node,
      name: LocationsAndChildrenVars.Point,
      output: true,
      fetch: [
        VarFields.id,
        VarFields.type,
        VarFields.hypernym,
        VarFields.streams,
      ],
      brickTypes: mapClassTypes(points, includePointSubclasses),

      // These two paths go here, not in the main query block, so that only points belonging to both a Location and
      // Equipment will be considered.
      constraints: {
        paths: [
          {
            fromRef: LocationsAndChildrenVars.Location,
            properties: [{ property: BrickProperty.hasPoint }],
            toRef: LocationsAndChildrenVars.Point,
          },
          {
            fromRef: LocationsAndChildrenVars.Equipment,
            properties: [{ property: BrickProperty.hasPoint }],
            toRef: LocationsAndChildrenVars.Point,
          },
        ],
      },
    },
    {
      comment: "A location which must have the point P as a point",
      varType: QueryVarType.node,
      name: LocationsAndChildrenVars.Location,
      output: true,
      fetch: [VarFields.id, VarFields.type, VarFields.hypernym],
      brickTypes: mapClassTypes(locations, includeLocationsSubclasses),
    },
    {
      comment: "An equipment which must have the point P as a point",
      varType: QueryVarType.node,
      name: LocationsAndChildrenVars.Equipment,
      output: true,
      fetch: [VarFields.id, VarFields.type, VarFields.hypernym],
      brickTypes: mapClassTypes(equipment, includeEquipmentSubclasses),
    },
    {
      varType: QueryVarType.node,
      comment: "Optional parent of equipment E.",
      name: LocationsAndChildrenVars.EquipmentParent1,
      output: true,
      fetch: [VarFields.id, VarFields.type],
      brickTypes: [{ match: Match.hypernym, type: "Equipment" }],
      nullable: true,
      constraints: {
        paths: [
          {
            fromRef: LocationsAndChildrenVars.EquipmentParent1,
            properties: [{ property: BrickProperty.hasPart }],
            toRef: LocationsAndChildrenVars.Equipment,
          },
        ],
      },
      nested: [
        {
          varType: QueryVarType.node,
          comment: "Optional 2nd level parent of equipment E.",
          name: LocationsAndChildrenVars.EquipmentParent2,
          output: true,
          fetch: [VarFields.id, VarFields.type],
          brickTypes: [{ match: Match.hypernym, type: "Equipment" }],
          nullable: true,
          constraints: {
            paths: [
              {
                fromRef: LocationsAndChildrenVars.EquipmentParent2,
                properties: [{ property: BrickProperty.hasPart }],
                toRef: LocationsAndChildrenVars.EquipmentParent1,
              },
            ],
          },
        },
      ],
    },
    {
      varType: QueryVarType.node,
      comment: "Optional parent of location L.",
      name: LocationsAndChildrenVars.LocationParent1,
      output: true,
      fetch: [VarFields.id, VarFields.type],
      brickTypes: [{ match: Match.hypernym, type: "Location" }],
      nullable: true,
      constraints: {
        paths: [
          {
            fromRef: LocationsAndChildrenVars.LocationParent1,
            properties: [{ property: BrickProperty.hasPart }],
            toRef: LocationsAndChildrenVars.Location,
          },
        ],
      },
      nested: [
        {
          varType: QueryVarType.node,
          comment: "Optional 2nd level parent of location L.",
          name: LocationsAndChildrenVars.LocationParent2,
          output: true,
          fetch: [VarFields.id, VarFields.type],
          brickTypes: [{ match: Match.hypernym, type: "Location" }],
          nullable: true,
          constraints: {
            paths: [
              {
                fromRef: LocationsAndChildrenVars.LocationParent2,
                properties: [{ property: BrickProperty.hasPart }],
                toRef: LocationsAndChildrenVars.LocationParent1,
              },
            ],
          },
        },
      ],
    },
  ],
});

const DEFAULT_BRICK_TYPES: MatchType[] = [
  {
    match: Match.isa,
    type: "Location",
  },
  {
    match: Match.isa,
    type: "Equipment",
  },
  {
    match: Match.isa,
    type: "Collection",
  },
  {
    match: Match.isa,
    type: "Point",
  },
];

export const createSearchQueryDef = (searchTerm: string): Query => {
  return pipe(
    {
      comment:
        "Find all objects with ID or label containing a given filter string.",
      mode: QueryMode.select,
      variables: [
        {
          output: true,
          name: MODEL_NODE_NAME,
          varType: QueryVarType.node,
          fetch: [
            VarFields.id,
            VarFields.type,
            VarFields.label,
            VarFields.hypernym,
          ],
          // DCH-6154: Overcome bug where not all results are returned if brick types not explicitly specified
          brickTypes: DEFAULT_BRICK_TYPES,
        },
      ],
    },
    filterKeywords(searchTerm)
  );
};
