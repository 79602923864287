import React, { useCallback, useEffect, useState } from "react";
import { ApiError } from "data/http";
import { ApplicationId, metisApi } from "data/Metis";
import { ImageViewer, UIStatus, UIStatusWrapper } from "components/shared";

export const ApplicationImages = ({
  appId,
  screenshotCount,
  isReadOnly,
}: {
  appId: ApplicationId;
  screenshotCount: number;
  isReadOnly: boolean;
}) => {
  const [appScreenshots, setAppScreenshots] = useState<string[]>([]);
  const [uiStatus, setUiStatus] = useState(new UIStatus());
  const [imageCount, setImageCount] = useState(screenshotCount);

  const fetchScreenshots = useCallback(() => {
    setUiStatus((prev) => prev.setIndeterminate(true));
    const promises: Promise<any>[] = [];
    for (
      let screenshotIndex = 0;
      screenshotIndex < imageCount;
      screenshotIndex++
    ) {
      promises.push(
        metisApi.getApplicationRecipeScreenshot(appId, screenshotIndex)
      );
    }
    Promise.all(promises).then((res) => {
      setAppScreenshots(res.map((r) => URL.createObjectURL(r)));
      setUiStatus((prev) => prev.setIndeterminate(false));
    });
  }, [appId, imageCount]);

  useEffect(() => {
    if (imageCount > 0) fetchScreenshots();
  }, [fetchScreenshots, imageCount]);

  const handleSubmit = (imageToUpload: File) => {
    const formData = new FormData();
    formData.append("screenshot", imageToUpload);
    return metisApi.postApplicationRecipeScreenshot(appId, formData);
  };

  const onSuccess = () => {
    setImageCount((prev) => prev + 1);
  };

  const handleDelete = (imageIndex: number) => {
    setUiStatus((prev) => prev.setIndeterminate(true));
    metisApi.deleteApplicationRecipeScreenshot(appId, imageIndex).then(
      () => {
        setUiStatus((prev) => prev.setIndeterminate(false));
        setImageCount((prev) => prev - 1);
      },
      (error: ApiError) => {
        setUiStatus((prev) =>
          prev.setError(
            error.message ?? "An error occured while deleting this screenshot."
          )
        );
      }
    );
  };

  return (
    <UIStatusWrapper status={uiStatus}>
      <ImageViewer
        loading={uiStatus.indeterminate}
        imageSources={appScreenshots}
        isReadOnly={isReadOnly}
        handleSubmit={(imageToUpload: File) => handleSubmit(imageToUpload)}
        handleDelete={(indexToDelete: number) => handleDelete(indexToDelete)}
        maxImages={5}
        onSuccess={onSuccess}
      />
    </UIStatusWrapper>
  );
};
