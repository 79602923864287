/** @jsxImportSource @emotion/react */

import React from "react";
import * as O from "fp-ts/lib/Option";
import { useFormContext } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Accordion, AccordionPanelProps, List } from "semantic-ui-react";
import tw from "twin.macro";
import { DCHModel, ModelRootType } from "data/brick";
import { labelOrId } from "components/shared/Common";
import { PageMode, capitaliseFirstCharacter } from "components/shared";
import { SITE_ICON_NAME } from "components/SitesAndBuildings/SiteOrBuildingIcon";
import Form from "components/shared/Forms/ReactHookForm";
import { SubLevelNodeName } from "components/SitesAndBuildings/Common";
import { extractModelId, SelectedElement } from "../ModelUtils";
import { FormFieldArrayType, ModelFormFields } from "./SiteOrBuildingModelForm";

export type ModelTreeProps = {
  model?: DCHModel;
  modelType: ModelRootType;
  selectedElement: SelectedElement;
  setSelectedElement: (_: SelectedElement) => void;
  pageMode: PageMode;
  addNewNode: (nodeType: SubLevelNodeName) => void;
};

const TreeList = ({
  nodeName,
  fieldArrayName,
  selectedElement,
  setSelectedElement,
  pageMode,
  addNewNode,
}: {
  nodeName: SubLevelNodeName;
  fieldArrayName: keyof ModelFormFields;
} & ModelTreeProps) => {
  const { getValues } = useFormContext<ModelFormFields>();
  const entries: FormFieldArrayType[] | undefined = getValues(
    fieldArrayName as string
  );

  return (
    <div css={tw`p-4`}>
      <List css={tw`max-h-40 overflow-auto`} className="dch-scrollbar">
        {pageMode !== PageMode.view &&
          nodeName !== "point" && ( //made temporarily readonly under DCH-4598 (points not yet supported on MASON)
            <List.Item>
              <Form.Button
                submit={false}
                size="tiny"
                label={`Add ${capitaliseFirstCharacter(nodeName)}`}
                onClick={() => {
                  addNewNode(nodeName);
                }}
              />
            </List.Item>
          )}
        {entries && entries?.length > 0 ? (
          entries.map((entry: FormFieldArrayType) => {
            return (
              <List.Item
                key={`${nodeName}_${entry.fieldId}`}
                onClick={() => {
                  selectedElement.id !== entry.fieldId &&
                    setSelectedElement({
                      type: nodeName,
                      id: entry.fieldId,
                    });
                }}
              >
                <Link
                  css={selectedElement.id === entry.fieldId && tw`text-light`}
                  to={`?id=${entry.fieldId}`}
                  relative="path"
                >
                  {labelOrId(entry.fieldId, O.fromNullable(entry.name))}
                </Link>
              </List.Item>
            );
          })
        ) : (
          <List.Item>
            <em>No data found</em>
          </List.Item>
        )}
      </List>
    </div>
  );
};

export const ModelFormTree: React.FunctionComponent<ModelTreeProps> = (
  props
) => {
  const { model, modelType, selectedElement, setSelectedElement } = props;

  const location = useLocation();
  const navigate = useNavigate();

  const rootPanel: Array<AccordionPanelProps> =
    modelType === ModelRootType.Site
      ? [
          {
            key: "site",
            title: {
              content: "Site Details",
              icon: SITE_ICON_NAME,
              onClick: () => {
                model?.id &&
                  setSelectedElement({
                    type: "model",
                    id: extractModelId(model?.id),
                  });
                navigate(location.pathname);
              },
              className:
                selectedElement.type === "model" &&
                selectedElement.id === (model?.id && extractModelId(model?.id))
                  ? "selected"
                  : undefined,
            },
            content: <></>,
          },
        ]
      : [
          {
            key: "building",
            title: {
              content: "Building Details",
              icon: "building",
              onClick: () => {
                model?.id &&
                  props.setSelectedElement({
                    type: "model",
                    id: extractModelId(model.id),
                  });
                navigate(location.pathname);
              },
              className:
                selectedElement.type === "model" &&
                selectedElement.id === (model?.id && extractModelId(model.id))
                  ? "selected"
                  : undefined,
            },
            content: <></>,
          },
        ];

  const panels = rootPanel.concat([
    {
      key: "wing",
      title: "Wing",
      content: {
        content: <TreeList {...props} nodeName="wing" fieldArrayName="wings" />,
      },
    },
    {
      key: "floor",
      title: "Floor",
      content: {
        content: (
          <TreeList {...props} nodeName="floor" fieldArrayName="floors" />
        ),
      },
    },
    {
      key: "room",
      title: "Room",
      content: {
        content: <TreeList {...props} nodeName="room" fieldArrayName="rooms" />,
      },
    },
    {
      key: "zone",
      title: "Zone",
      content: {
        content: <TreeList {...props} nodeName="zone" fieldArrayName="zones" />,
      },
    },
    {
      key: "equipment",
      title: "Equipment",
      content: {
        content: (
          <TreeList
            {...props}
            nodeName="equipment"
            fieldArrayName="equipment"
          />
        ),
      },
    },
    {
      key: "point",
      title: "Point",
      content: {
        content: (
          <TreeList {...props} nodeName="point" fieldArrayName="points" />
        ),
      },
    },
  ]);

  const [activeIndexes, setActiveIndexes] = React.useState<number[]>([
    panels.findIndex((panel) => panel.key === selectedElement.type),
  ]);

  const onAccordionClick = (index: number) =>
    activeIndexes.includes(index)
      ? setActiveIndexes((p) => p.filter((x) => x !== index))
      : setActiveIndexes((p) => [...p, index]);

  const indexTrackedPanels = panels.map((p, index) => {
    return {
      ...p,
      active: activeIndexes.includes(index),
      onTitleClick: () => onAccordionClick(index),
    };
  });

  // on selected element change, set the active accordion panel and its scroll position of current entity
  React.useEffect(() => {
    // add active accordion index if it doesnt already exist
    const index = panels.findIndex(
      (panel) => panel.key === selectedElement.type
    );
    !activeIndexes.includes(index) && setActiveIndexes((p) => [...p, index]);

    // scroll the selected element to the top of container
    const element = document.getElementById(selectedElement.id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    // activeIndexes will cause infinite dependency loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panels, selectedElement]);

  return <Accordion inverted panels={indexTrackedPanels} exclusive={false} />;
};
