/** @jsxImportSource @emotion/react */
import { useCallback, useMemo, useState } from "react";
import { DropdownItemProps, Image } from "semantic-ui-react";
import tw from "twin.macro";
import { FetchStatus } from "data/constants";
import {
  metisApi,
  useApplicationCategoriesContext,
  CategoryResponse,
} from "data/Metis";
import { orderAlphabetically } from "components/shared";
import { getIconByCategory } from "components/Applications/shared";

let categoriesPromise: Promise<CategoryResponse[]> | null = null;

export const useApplicationCategories = () => {
  const { categories, setCategories } = useApplicationCategoriesContext();
  const [fetchingStatus, setFetchingStatus] = useState(FetchStatus.Pending);

  const fetchCategories = useCallback(async () => {
    setFetchingStatus(FetchStatus.Fetching);
    categoriesPromise = metisApi.getCategories();
    await categoriesPromise.then(
      (res) => {
        const categoryMap = new Map<string, CategoryResponse>();
        res.map((category) => categoryMap.set(category.uid, category));
        setCategories(categoryMap);
        setFetchingStatus(FetchStatus.Complete);
        categoriesPromise = null;
      },
      () => setFetchingStatus(FetchStatus.Error)
    );
  }, [setCategories]);

  //if we don't have categories yet, go fetch them and store them in context
  useMemo(() => {
    if (
      !categoriesPromise &&
      fetchingStatus === FetchStatus.Pending &&
      (!categories || categories.size === 0)
    ) {
      fetchCategories();
    }
  }, [categories, fetchCategories, fetchingStatus]);

  const getCategoriesByUid = (categoryUids: string[]) =>
    categoryUids.flatMap(
      (categoryUid) => categories.get(categoryUid)?.name ?? ""
    );

  const categorySelectOptions: DropdownItemProps[] = [...categories.entries()]
    .map(([categoryUid, category]) => {
      return {
        value: categoryUid,
        text: category.name,
        content: (
          <div css={tw`flex gap-4`}>
            <Image
              src={getIconByCategory([category.name])}
              alt="application icon"
              width="16"
              height="16"
            />
            <span>{category.name}</span>
          </div>
        ),
      } as DropdownItemProps;
    })
    .sort(orderAlphabetically);

  return {
    categories,
    getCategoriesByUid,
    fetchingStatus,
    categorySelectOptions,
  };
};
