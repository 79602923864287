import * as O from "fp-ts/lib/Option";
import { masonEnv } from "reducers/env";
import { httpGet } from "data/httpUtil";
import { ClientEnv } from "data/utils";

/** @deprecated - replaced with AletheiaTypes entityProperties type */
export type EntityPropertyDto = {
  schema?: string;
  name: string;
  intval?: number;
  numval?: number;
  strval?: string;
  urival?: string;
  boolval?: boolean;
  unit?: string;
  nested?: Array<EntityPropertyDto>;
};

export type EntityPropertyFromQueryDto = {
  property: string;
  key?: string;
  value?: any;
  unit?: string;
  nested?: Array<EntityPropertyFromQueryDto>;
  schema?: string;
};

export type EntityPropertySummary = {
  id: string;
  name: string;
  schema: string;
  definition?: string;
  domains: Array<string>;
  quantity_kinds?: Array<string>;
};

export type EntityPropertiesResponse = {
  properties: Array<EntityPropertySummary>;
  count: number;
  totalCount: number;
};

export type EntityPropertyAttribute = {
  type?: string | Array<string>;
  description?: string;
  const?: any;
  minimum?: number;
  exclusiveMinimum?: number;
  maximum?: number;
  exclusiveMaximum?: number;
  enum?: Array<any>;
  properties?: { [key: string]: EntityPropertyAttribute };
};

type EntityPropertyJsonSchemaDetails = {
  oneOf?: Array<EntityPropertyJsonSchemaDetails>;
  type?: string | Array<string>;
  description?: string;
  required?: Array<string>;
  properties?: { [key: string]: EntityPropertyAttribute };
  additionalProperties?: boolean;
};
export type EntityPropertyJsonSchema = {
  id: string;
  schema: string;
} & EntityPropertyJsonSchemaDetails;

export type EntityPropertyDefinition = EntityPropertySummary & {
  json_schema: EntityPropertyJsonSchema;
};

export type EntityPropertiesApi = {
  getEntityProperties: (name?: string) => Promise<EntityPropertiesResponse>;
  getEntityProperty: (id: string) => Promise<EntityPropertyDefinition>;
};

const ENTITY_PROPERTY_URL = "/schema/entityproperties";
const mkEPApi = (env: ClientEnv): EntityPropertiesApi => ({
  getEntityProperties: (name?: string) => {
    const params = name ? { name: `*${name}*` } : null;
    return httpGet(O.none)(env)(params)(`${ENTITY_PROPERTY_URL}`).then(
      (r) => r as EntityPropertiesResponse
    );
  },
  getEntityProperty: (id) =>
    httpGet(O.none)(env)(null)(`${ENTITY_PROPERTY_URL}/${id}`).then(
      (r) => r as EntityPropertyDefinition
    ),
});

export const entityPropertiesApi = mkEPApi(masonEnv);
