import {
  addDays,
  addHours,
  addMonths,
  addSeconds,
  addYears,
  addMinutes,
  addWeeks,
  startOfSecond,
  startOfMinute,
  startOfHour,
  startOfDay,
  startOfWeek,
  startOfMonth,
  startOfYear,
} from "date-fns";

const addPeriods = (frequency: string): Function => {
  switch (frequency) {
    case "s":
      return addSeconds;
    case "m":
      return addMinutes;
    case "h":
      return addHours;
    case "d":
      return addDays;
    case "w":
      return addWeeks;
    case "M":
      return addMonths;
    case "Y":
      return addYears;
    default:
      throw new Error(
        `Invalid frequency found: ${frequency} must be one of [smhdwMY]`
      );
  }
};

const floorFrequency = (frequency: string): Function => {
  switch (frequency) {
    case "s":
      return startOfSecond;
    case "m":
      return startOfMinute;
    case "h":
      return startOfHour;
    case "d":
      return startOfDay;
    case "w":
      return startOfWeek;
    case "M":
      return startOfMonth;
    case "Y":
      return startOfYear;
    default:
      throw new Error(
        `Invalid frequency found: ${frequency} must be one of [smhdwMY]`
      );
  }
};

export const convertTimeString = (timeString: string, timeNow?: Date) => {
  let dt = timeNow ?? new Date();

  const pattern =
    /now(?<offsetDirection>[-+]?)(?<offsetPeriods>[1-9]*)(?<offsetFrequency>[smhdwMY])?\/?(?<floorFrequency>[smhdwMY])?/g;
  const res = pattern.exec(timeString)?.groups;

  if (res) {
    if (res["offsetDirection"] && res["offsetFrequency"]) {
      const offsetDirection = res["offsetDirection"] === "-" ? -1 : 1;
      const offsetPeriods =
        offsetDirection *
        (res["offsetPeriods"] ? parseInt(res["offsetPeriods"]) : 1);
      const offsetFrequency = res["offsetFrequency"];

      dt = addPeriods(offsetFrequency)(dt, offsetPeriods);
    }

    if (res["floorFrequency"]) {
      dt = floorFrequency(res["floorFrequency"])(dt);
    }

    return dt;
  }

  return new Date(parseInt(timeString));
};

export const extractDateRangeFromURL = (url: string) => {
  const pattern = /from=([^&]+)&to=([^&]+)/g;
  const results = pattern.exec(url);
  return results ? [results?.[1], results?.[2]] : [];
};
