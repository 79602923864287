/** @jsxImportSource @emotion/react */
import { useEffect, useMemo, useState } from "react";
import { Container, Grid, Header, Menu } from "semantic-ui-react";
import tw from "twin.macro";
import { FULFILLED_PROMISE, REJECTED_PROMISE } from "data/constants";
import {
  InstalledApplicationMetadataResponse,
  MetadataConfig,
  metisApi,
} from "data/Metis";
import { UIStatus, UIStatusWrapper } from "components/shared";
import ApplicationConfigurationDocument from "./ApplicationConfigurationDocument";
import DocumentConfigMenu from "./ApplicationConfigurationDocumentMenu";
import {
  ConfigType,
  JSON_STRING_SPACING,
  Status,
  mkConfigType,
} from "./ApplicationConfigurationConstants";

const ApplicationConfiguration = (props: {
  applicationID: string;
  applicationMetadata: InstalledApplicationMetadataResponse | undefined;
}) => {
  const [isSaved, setIsSaved] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [activeDocIndex, setActiveDocIndex] = useState(0);

  const [documentStatuses, setDocumentStatuses] = useState<
    Record<string, Status>
  >({});
  const [activeDocumentStatus, setActiveDocumentStatus] = useState<Status>(
    Status.Synced
  );
  const [configList, setConfigList] = useState<ConfigType[]>([]);
  const [status, setStatus] = useState(new UIStatus());

  const activeDoc = configList[activeDocIndex];

  const getDocumentString = (r: any): string =>
    typeof r === "object" ? JSON.stringify(r, null, JSON_STRING_SPACING) : r;

  const configApi = (metaData: MetadataConfig) => {
    const docId = metaData.documentId;
    let tempConfig = mkConfigType(metaData);
    setStatus((p) => p.setIndeterminate(true));
    return metisApi
      .getApplicationDocument(props.applicationID)(docId)
      .then((r) => {
        tempConfig = { ...tempConfig, plaintext: getDocumentString(r) };
        return tempConfig;
      })
      .then(async (r) => {
        const schema = await metisApi
          .getApplicationDocumentSchema(props.applicationID)(docId)
          .then(
            (res) => {
              return res;
            },
            (e) => console.error(e)
          );
        return { ...r, schema: schema ?? undefined };
      })
      .then((configRes) => {
        return configRes;
      })
      .catch((e) =>
        setStatus((p) =>
          p.setError(
            `Unable to gather data for ${props.applicationID} - ${docId} (${e})`
          )
        )
      );
  };

  useEffect(() => {
    if (activeDoc !== undefined) {
      if (
        documentStatuses[activeDoc.metadata.documentId] !== activeDocumentStatus
      ) {
        setDocumentStatuses({
          ...documentStatuses,
          [activeDoc.metadata.documentId]: activeDocumentStatus,
        });
      }
    }
  }, [activeDoc, activeDocumentStatus, documentStatuses]);

  useEffect(() => {
    if (activeDoc) {
      if (documentStatuses[activeDoc.metadata.documentId] === Status.Modified) {
        setIsModified(true);
      } else if (
        documentStatuses[activeDoc.metadata.documentId] === Status.Synced
      ) {
        setIsSaved(true);
      } else {
        setIsSaved(false);
        setIsModified(false);
      }
    }
  }, [activeDoc, documentStatuses]);

  const loadConfigFromApi = async () => {
    if (
      props.applicationMetadata?.configurationDocuments &&
      props.applicationMetadata.configurationDocuments.length > 0
    ) {
      setActiveDocumentStatus(Status.Synced);

      // initialise the data structure here
      Promise.allSettled(
        props.applicationMetadata.configurationDocuments
          .filter(
            (document) =>
              props.applicationMetadata?.readablePorts.includes(
                document.documentId
              ) ||
              props.applicationMetadata?.writablePorts.includes(
                document.documentId
              )
          )
          .map((meta: MetadataConfig) => configApi(meta))
      )
        .then((results) => {
          setStatus((p) => p.setIndeterminate(false));
          const configRes = results
            .filter((r) => r.status === FULFILLED_PROMISE)
            .flatMap(
              (r) => Object.assign([], r as PromiseFulfilledResult<any>).value
            );
          const rejected = results.filter((r) => r.status === REJECTED_PROMISE);
          if (rejected.length > 0) {
            return Promise.reject(
              rejected
                .map((r) => (r as PromiseRejectedResult).reason.error)
                .reduce((c, a) => a + "\n" + c, "")
                .trim()
            );
          } else {
            return Promise.resolve(configRes);
          }
        })
        .then((r) => {
          setStatus((p) => p.setIndeterminate(false));
          setConfigList(r);
        })
        .catch((e) => {
          setStatus((p) => p.setError(e));
        });
    }
  };

  useEffect(() => {
    loadConfigFromApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // given the current index, update the text
  const updateConfigList = (plaintext?: string) => {
    const tmpConfigList = configList.map((c, index) =>
      index === activeDocIndex ? { ...c, plaintext } : c
    );
    setConfigList(tmpConfigList);
  };

  /**
   * Memoised function to generate the document menu, including document status and type, triggered by
   * changes to documentStatus, activeDoc, and/or any modifications/saved documents.
   *
   * @requires activeDoc - Current document being edited, uses documentid to modify the record entry within loadedDocumentValue
   * @requires props.applicationMetadata - Metadata file as loaded from BSL
   * @requires documentStatus - Current status of each document loaded for the application
   *
   */
  const generateMenu = useMemo(() => {
    if (activeDoc && configList) {
      return (
        <DocumentConfigMenu
          activeConfig={activeDoc}
          setActiveConfig={setActiveDocIndex}
          isModified={isModified}
          isSaved={isSaved}
          configList={configList}
          documentStatus={documentStatuses}
        />
      );
    } else {
      return <Menu className="basic" vertical inverted fluid />;
    }
  }, [activeDoc, configList, documentStatuses, isModified, isSaved]);

  return (
    <div data-test-id="applicationConfiguration" css={tw`p-2`}>
      <UIStatusWrapper status={status}>
        <Grid divided inverted stackable>
          <Grid.Column width={3}>
            <Container data-test-id="application-configuration-left-menu">
              {generateMenu}
            </Container>
          </Grid.Column>
          <Grid.Column width={13}>
            <Container fluid data-test-id="application-configuration-form">
              <Header>
                {activeDoc?.metadata.label ||
                  activeDoc?.metadata.documentId ||
                  ""}
              </Header>
              <p>{activeDoc?.metadata.description}</p>
              {activeDoc && (
                <ApplicationConfigurationDocument
                  applicationId={props.applicationID}
                  activeDoc={activeDoc}
                  setDocumentStatus={setActiveDocumentStatus}
                  updateConfigList={updateConfigList}
                />
              )}
            </Container>
          </Grid.Column>
        </Grid>
      </UIStatusWrapper>
    </div>
  );
};

export default ApplicationConfiguration;
