//TODO - once we have all locations/equipment/points configured on the visualiser, decide which of the potential layouts produces the best result
const gForceLayout = {
  //https://g6.antv.vision/en/docs/api/graphLayout/gforce
  type: "gForce",
  preventOverlap: true,
  nodeSpacing: 20, //minimum distance between nodes to prevent node overlappings
  //center: [0, 0], //The center of the layout
  linkDistance: 100, //The edge length
  nodeSize: 80,
  nodeStrength: 2000, //The strength of node force. Positive value means repulsive force, negative value means attractive force
  edgeStrength: 100, //The strength of edge force. Calculated according to the degree of nodes by default
  onTick: () => {
    console.log("ticking");
  },
  onLayoutEnd: () => {
    console.log("force layout done");
  },
  animate: true,
  //workerEnabled: true, // Whether to activate web-worker
  //gpuEnabled: true, // Whether to enable the GPU
};

// const customLayout = {
//   //https://g6.antv.vision/en/examples/net/forceDirected#forceDirectedFunctionalParams
//   type: "force",
//   preventOverlap: true,
//   linkDistance: 100,
//   nodeStrength: (d: any) => {
//     console.log("d", d); // if (d.isLeaf) {
//     //   return -50;
//     // }
//     return -10;
//   },
//   edgeStrength: 0.1,
// };

// const radialLayout = {
//   //https://g6.antv.vision/en/docs/api/graphLayout/radial
//   type: "radial",
//   //center: [1381 / 2, 701 / 2], // The center of the graph by default
//   linkDistance: 150, // The edge length
//   //maxIteration: 1000,
//   //focusNode: "TestBuilding", //"0b4f75b0_9757_42a2_9312_577e80866462",
//   unitRadius: 150,
//   preventOverlap: true, // nodeSize or size in data is required for preventOverlap: true
//   //nodeSize: 30,
//   strictRadial: false,
//   //workerEnabled: true, // Whether to activate web-worker
// };

// const concentricLayout = {
//   //https://g6.antv.vision/en/docs/api/graphLayout/concentric
//   type: "concentric",
//   //center: [200, 200], // The center of the graph by default
//   linkDistance: 50, // The edge length
//   preventOverlap: true, // nodeSize or size in data is required for preventOverlap: true
//   //nodeSize: 30,
//   sweep: 10,
//   equidistant: false,
//   startAngle: 0,
//   clockwise: false,
//   maxLevelDiff: 10,
//   sortBy: "degree",
//   //workerEnabled: true, // Whether to activate web-worker
// };

// const fructermanLayout = {
//   //https://g6.antv.vision/en/docs/api/graphLayout/fruchterman
//   type: "fruchterman",
//   //center: [200, 200], // The center of the graph by default
//   gravity: 20,
//   speed: 2,
//   preventOverlap: true,
//   //clustering: true,
//   //clusterGravity: 30,
//   //maxIteration: 2000,
//   workerEnabled: true, // Whether to activate web-worker
//   gpuEnabled: true, // Whether to enable the GPU parallel computing, supported by G6 4.0
// };

// const fa2Layout = {
//   type: "forceAtlas2",
//   kr: 200, //Repulsive parameter, smaller the kr, more compact the graph
//   kg: 50, //The parameter for the gravity. Larger kg, the graph will be more compact to the center
//   mode: "linlog", // 'normal' | 'linlog';  Under 'linlog' mode, the cluster will be more compact
//   preventOverlap: true,
// };

// //TODO - investigate combos further
// //also check out combo combined - https://g6.antv.vision/en/docs/api/graphLayout/comboCombined
// const comboForceLayout = {
//   type: "comboForce",
//   //center: [ 200, 200 ],     // The center of the graph by default
//   linkDistance: 50, // Edge length
//   nodeStrength: 30,
//   edgeStrength: 0.5,
//   preventOverlap: true,
//   //preventNodeOverlap
//   //preventComboOverlap
//   //collideStrength
//   //nodeCollideStrength
//   //comboCollideStrength
//   //nodeSpacing: 2,
//   //comboSpacing
//   //comboPadding
//   onTick: () => {
//     console.log("ticking");
//   },
//   onLayoutEnd: () => {
//     console.log("combo force layout done");
//   },
// };

export const layout = gForceLayout;
