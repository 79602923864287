/** @jsxImportSource @emotion/react */

import { useMemo, useState } from "react";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import { Dimmer, Icon, List } from "semantic-ui-react";
import tw from "twin.macro";
import { OrgId } from "data/Enodia";
import { Path } from "Routes";
import { SITE_ICON_NAME } from "components/SitesAndBuildings/SiteOrBuildingIcon";
import { UIStatusWrapper, UIStatus, FilterInput } from "components/shared";
import { BuildingModel, SiteModel, sortModelByName } from "./Common";

type Props = {
  sitesUiStatus: UIStatus;
  orgId?: OrgId;
  sitesAndBuildingsList?: SiteModel[];
};

// helper for sorting array by search term, displaying them as priority at top
const sortByTerm = function (
  data: SiteModel[] | BuildingModel[],
  term: string
) {
  const lower = term.toLowerCase();
  return data.sort(function (
    a: SiteModel | BuildingModel,
    b: SiteModel | BuildingModel
  ) {
    const nameA = a.name.toLowerCase().indexOf(lower);
    const nameB = b.name.toLowerCase().indexOf(lower);
    return nameA > nameB ? -1 : nameA < nameB ? 1 : 0;
  });
};

const sortName = function (data: SiteModel[] | BuildingModel[]) {
  return data.sort(sortModelByName);
};

//helper to highlight and parse string
const highlight = (name: string, searchTerm: string) => {
  if (name && searchTerm) {
    return parse(
      name.replace(
        new RegExp(searchTerm, "gi"),
        (match) => `<mark className="highlighted"}>${match}</mark>`
      )
    );
  }
  return name;
};

const containsSearchTerm = (name: string, searchTerm: string) =>
  name.toLowerCase().includes(searchTerm);

const SitesAndBuildingsList = (props: Props) => {
  const { sitesUiStatus, orgId, sitesAndBuildingsList } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const [activeSites, setActiveSites] = useState<string[]>([]);

  const handleSearch = (term: string) =>
    setSearchTerm(term.trim().toLowerCase());

  useMemo(() => {
    if (searchTerm && sitesAndBuildingsList) {
      const sitesWithBuildingsContainingSearchTerm =
        sitesAndBuildingsList.filter((site) =>
          site.buildings.some((b) => containsSearchTerm(b.name, searchTerm))
        );
      setActiveSites(
        sitesWithBuildingsContainingSearchTerm.map((site) => site.id)
      );
    } else if (!searchTerm) setActiveSites([]);
  }, [searchTerm, sitesAndBuildingsList]);

  const toggleSiteId = (siteId: string) => {
    if (activeSites.includes(siteId))
      setActiveSites((prev) =>
        prev.filter((activeSiteId) => activeSiteId !== siteId)
      );
    else setActiveSites((prev) => [...prev, siteId]);
  };

  const renderBuildings = (buildings: BuildingModel[]) => {
    if (buildings.length > 0) {
      sortName(buildings);
      if (searchTerm) sortByTerm(buildings, searchTerm);
    }
    return (
      <List.List>
        {buildings.length > 0 ? (
          buildings.map((building) => {
            return (
              <List.Item css={tw`mb-2`} key={building.id}>
                <span css={tw`pl-4`}>
                  <Icon name="building" inverted color="purple" />
                  <Link
                    css={tw`pl-2 break-words no-underline`}
                    to={building.path || Path.Home}
                  >
                    {containsSearchTerm(building.name, searchTerm) ? (
                      <span>
                        <strong>{highlight(building.name, searchTerm)}</strong>
                      </span>
                    ) : (
                      <span css={tw`text-core-grey`}>
                        {highlight(building.name, searchTerm)}
                      </span>
                    )}
                  </Link>
                </span>
              </List.Item>
            );
          })
        ) : (
          <List.Item>
            <span css={tw`text-sm italic pl-4`}>No buildings</span>
          </List.Item>
        )}
      </List.List>
    );
  };

  const renderSite = (site: SiteModel) => {
    const siteActive = activeSites.includes(site.id);
    const caretIcon = siteActive ? "caret down" : "caret right";
    return (
      <List.Item key={site.id} css={tw`mb-2`}>
        <span css={tw`cursor-pointer`}>
          <span onClick={() => toggleSiteId(site.id)}>
            <Icon name={caretIcon} />
            <Icon name={SITE_ICON_NAME} color="purple" />
          </span>
          <Link css={tw`break-words no-underline`} to={site.path || Path.Index}>
            {siteActive || containsSearchTerm(site.name, searchTerm) ? (
              <span>
                <strong>{highlight(site.name, searchTerm)}</strong>
                {` (${site.buildings.length} building${
                  site.buildings.length === 1 ? "" : "s"
                })`}
              </span>
            ) : (
              <span css={tw`text-core-grey`}>
                {highlight(site.name, searchTerm)}
                {` (${site.buildings.length} building${
                  site.buildings.length === 1 ? "" : "s"
                })`}
              </span>
            )}
          </Link>
        </span>
        {siteActive && renderBuildings(site.buildings)}
      </List.Item>
    );
  };

  const renderSitesList = () => {
    if (!sitesAndBuildingsList)
      return <div css={tw`pt-4`}>No matching results found</div>;
    if (sitesAndBuildingsList.length > 0) {
      sortName(sitesAndBuildingsList);
      if (searchTerm) sortByTerm(sitesAndBuildingsList, searchTerm);
    }
    return (
      <UIStatusWrapper
        status={sitesUiStatus}
        loadingDataMsg="Loading Data"
        clearable
        fitted
      >
        {sitesAndBuildingsList.length > 0 ? (
          <List inverted style={{ paddingRight: ".5rem" }}>
            {sitesAndBuildingsList.map((site) => renderSite(site))}
          </List>
        ) : (
          <div css={tw`pt-4`}>No matching results found</div>
        )}
      </UIStatusWrapper>
    );
  };

  return (
    <Dimmer.Dimmable
      blurring
      dimmed={!orgId}
      className={"fitted"}
      css={tw`flex-col-fit z-0`}
    >
      <Dimmer active={!orgId}>
        <div>Select an organisation to view sites and building list</div>
      </Dimmer>
      <div css={tw`flex flex-col h-full`}>
        <div css={tw`flex`}>
          <FilterInput handleSearch={handleSearch} />
        </div>
        <div css={tw`overflow-y-auto h-full my-2`}>{renderSitesList()}</div>
      </div>
    </Dimmer.Dimmable>
  );
};

export default SitesAndBuildingsList;
