/** @jsxImportSource @emotion/react */

import tw from "twin.macro";
import React, { useContext, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { Icon, Dimmer, Grid, Table, Label, Button } from "semantic-ui-react";
import { AuthorityContext, EnodiaOrgContext } from "App";
import { Path } from "Routes";
import {
  RoleSetId,
  RoleSetResponse,
  UserGroupId,
  UserGroupResponse,
} from "data/Enodia";
import {
  FilterInput,
  PageSection,
  SelectInput,
  UIStatus,
  UIStatusWrapper,
} from "components/shared";
import { groupsAndSetsToOptions } from "../FilterByOptions";
import { UserPermissions } from "../Permissions";

const renderGroups = (groups: Array<string>) => {
  return <React.Fragment>{groups.join(", ")}</React.Fragment>;
};

const renderRoleSets = (roleSets: Array<RoleSetResponse>) => {
  const numberOfRoleSets = roleSets.length;
  return numberOfRoleSets > 0 ? (
    <Label circular basic className="inverted">{`${roleSets[0].name} ${
      numberOfRoleSets > 1
        ? `+ ${numberOfRoleSets - 1} other${numberOfRoleSets > 2 ? "s" : ""}`
        : ""
    }`}</Label>
  ) : (
    "Not specified"
  );
};

const renderUser = (
  user: UserPermissions,
  onClick: (user: UserPermissions) => void
) => {
  return (
    <Table.Row key={`user-${user.id}`}>
      <Table.Cell width={4}>{user.id}</Table.Cell>
      <Table.Cell width={5}>
        {user.userGroups && renderGroups(user.userGroups.map((g) => g.name))}
      </Table.Cell>
      <Table.Cell width={5}>{renderRoleSets(user.roleSets)}</Table.Cell>
      <Table.Cell width={2} textAlign="center">
        <Button basic inverted onClick={() => onClick(user)}>
          View
        </Button>
      </Table.Cell>
    </Table.Row>
  );
};

const renderNoResults = () => {
  return (
    <Table.Row>
      <Table.Cell>No Users Found</Table.Cell>
    </Table.Row>
  );
};

export const UserTable: React.FC<{
  userList: Array<UserPermissions>;
  onUserClick: (user: UserPermissions) => void;
}> = ({ userList, onUserClick }) => {
  return (
    <Table fixed singleLine inverted striped className="collapsed">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={4}>User ID</Table.HeaderCell>
          <Table.HeaderCell width={5}>Groups</Table.HeaderCell>
          <Table.HeaderCell width={5}>RoleSets</Table.HeaderCell>
          <Table.HeaderCell width={2}></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {userList.length > 0
          ? userList.map((user) => renderUser(user, onUserClick))
          : renderNoResults()}
      </Table.Body>
    </Table>
  );
};

type Props = {
  userList: Array<UserPermissions>;
  uiStatus: UIStatus;
  roleSets: Array<RoleSetResponse>;
  userGroups: Array<UserGroupResponse>;
};

export const Users: React.FunctionComponent<Props> = ({
  userList,
  uiStatus,
  roleSets,
  userGroups,
}) => {
  const navigate = useNavigate();

  const { orgId } = useContext(EnodiaOrgContext);
  const { canAssignAuthoritiesList } = useContext(AuthorityContext);

  const [idSearchTerm, setIdSearchTerm] = useState("");
  const [groupSearchTerm, setGroupSearchTerm] = useState("");
  const [roleSetSearchTerm, setRoleSetSearchTerm] = useState("");

  const filteredUsers = userList.filter((u) => {
    return (
      (!idSearchTerm || u.id.toLowerCase().includes(idSearchTerm)) &&
      (!roleSetSearchTerm ||
        u.roleSets
          .map((rs) => rs.id)
          .includes(roleSetSearchTerm as RoleSetId)) &&
      (!groupSearchTerm ||
        u.userGroups.map((g) => g.id).includes(groupSearchTerm as UserGroupId))
    );
  });

  return (
    <PageSection
      header={
        <div>
          <Icon name="user" color="purple" inverted />
          <span> Users</span>
        </div>
      }
    >
      {canAssignAuthoritiesList.length > 0 && (
        <div
          css={tw`absolute bg-black px-2 right-6`}
          style={{
            top: "calc((1em + .25rem) * -1)", //position according to half the button's own height
          }}
        >
          <Button
            id="InviteUser"
            size="small"
            primary
            onClick={() => {
              navigate(Path.InviteNewUser);
            }}
            disabled={!orgId}
          >
            <Icon name="plus" />
            Invite New User
          </Button>
        </div>
      )}
      <UIStatusWrapper status={uiStatus} customCss={tw`z-0`}>
        <Dimmer.Dimmable blurring dimmed={!orgId} css={tw`z-0`}>
          <Dimmer active={!orgId}>
            <div>Select an organisation to view users</div>
          </Dimmer>
          <Grid>
            <Grid.Row columns="equal">
              <Grid.Column>
                <FilterInput
                  fieldLabel="User Search"
                  placeholder="Enter user ID..."
                  handleSearch={(term: string) =>
                    setIdSearchTerm(term.toLowerCase())
                  }
                />
              </Grid.Column>
              <Grid.Column>
                <SelectInput
                  label="Filter by Group"
                  placeholder="Search for Group..."
                  value={groupSearchTerm}
                  options={userGroups.map(groupsAndSetsToOptions)}
                  onChange={(_, { value }) => {
                    setGroupSearchTerm(value as string);
                  }}
                />
              </Grid.Column>

              <Grid.Column>
                <SelectInput
                  label="Filter by RoleSet"
                  placeholder="Search for RoleSet..."
                  value={roleSetSearchTerm}
                  options={roleSets.map(groupsAndSetsToOptions)}
                  onChange={(_, { value }) => {
                    setRoleSetSearchTerm(value as string);
                  }}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <div
                css={tw` w-full mx-4 pb-2 max-h-80 overflow-y-auto`}
                className="dch-scrollbar"
              >
                <UserTable
                  userList={filteredUsers}
                  onUserClick={(user) => {
                    navigate(
                      generatePath(Path.ViewUser, {
                        user_id: user.id,
                      }),
                      { state: { user: user } }
                    );
                  }}
                />
              </div>
            </Grid.Row>
          </Grid>
        </Dimmer.Dimmable>
      </UIStatusWrapper>
    </PageSection>
  );
};
