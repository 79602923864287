/** @jsxImportSource @emotion/react */
import React, { Dispatch, SetStateAction } from "react";
import { Icon, Table } from "semantic-ui-react";
import tw from "twin.macro";
import { OrgId } from "data/Enodia";
import { toOptionTypeBase } from "components/shared/Common";
import { RoleRequest, ScopeType, enodiaApi } from "data/Enodia";
import { SelectInput, UIStatus } from "components/shared";
import { BuildingPermissions } from "./ScopeTypePermissions/BuildingScopePermissions";
import { SitePermissions } from "./ScopeTypePermissions/SiteScopePermissions";
import { OrganisationPermissions } from "./ScopeTypePermissions/OrganisationScopePermissions";
import { ScopeTypePermissionsPropsType } from "./ScopeTypePermissions/Common";

type RoleRowProps = {
  isReadOnly: boolean;
  role: RoleRequest;
  index: number;
  updateRole: (_: RoleRequest) => void;

  onDelete: () => void;
  verbOptions: Map<string, string[]>;
  setVerbOptions: Dispatch<SetStateAction<Map<string, string[]>>>;
  orgId?: OrgId;
};

export const RoleRow: React.FunctionComponent<RoleRowProps> = ({
  isReadOnly,
  role,
  index,
  updateRole,
  onDelete,
  verbOptions,
  setVerbOptions,
  orgId,
}) => {
  const [optionsStatus, setOptionsStatus] = React.useState(new UIStatus());
  // add the new verb options to the map if it doesnt already exist
  React.useEffect(() => {
    if (!!role.scopeType && !verbOptions.get(role.scopeType)) {
      setOptionsStatus((p) => p.setIndeterminate(true));
      enodiaApi
        .getPermissionsByScopeType(role.scopeType)
        .then((list) => {
          setVerbOptions((prev) => prev.set(role.scopeType, list));
        })
        .then(() => {
          setOptionsStatus((p) => p.setIndeterminate(false));
        })
        .catch((e) => {
          setOptionsStatus((p) => p.setError(e.message));
        });
    }
  }, [orgId, role, setVerbOptions, updateRole, verbOptions]);

  const ScopeTypePermissionComponent = getScopeTypePermissionComponent(
    role.scopeType
  );
  return (
    <Table.Row key={`role-${index}`} css={tw`h-24`}>
      <Table.Cell collapsing>
        {!isReadOnly && <Icon link name="close" onClick={onDelete} />}
      </Table.Cell>
      <Table.Cell width={3}>
        <ScopeTypeSelector
          role={role}
          orgId={orgId}
          onChange={updateRole}
          isReadOnly={isReadOnly}
        />
      </Table.Cell>
      {ScopeTypePermissionComponent && (
        <ScopeTypePermissionComponent
          orgId={orgId}
          role={role}
          onChange={updateRole}
          isReadOnly={isReadOnly}
          verbOptions={verbOptions}
          optionsStatus={optionsStatus}
        />
      )}
    </Table.Row>
  );
};

type ScopeTypeSelectorProps = {
  role: RoleRequest;
  orgId?: OrgId;
  onChange: (_: RoleRequest) => void;
  isReadOnly: boolean;
};
const ScopeTypeSelector: React.FunctionComponent<ScopeTypeSelectorProps> = ({
  role,
  orgId,
  onChange,
  isReadOnly,
}) => {
  return (
    <SelectInput
      testId={"ScopeTypeSelector"}
      placeholder="Select ..."
      onChange={(_, { value }) => {
        const newRole = {
          ...role,
          scopeType: value as ScopeType,
          scopeId: undefined, // clears existing scope id on type change
        };
        switch (value) {
          case ScopeType.Organisation:
            onChange({ ...newRole, scopeId: orgId as string });
            break;
          case ScopeType.Building:
            onChange(newRole);
            break;
          case ScopeType.Site:
            onChange(newRole);
            break;
          default:
            onChange(newRole);
        }
      }}
      isReadOnly={isReadOnly}
      value={role.scopeType}
      options={Object.values(ScopeType).map((v) => toOptionTypeBase(v))}
    />
  );
};

function getScopeTypePermissionComponent(
  type: ScopeType
): React.FunctionComponent<ScopeTypePermissionsPropsType> | undefined {
  if (type === ScopeType.Building) {
    return BuildingPermissions;
  }
  if (type === ScopeType.Site) {
    return SitePermissions;
  }
  if (type === ScopeType.Organisation) {
    return OrganisationPermissions;
  }

  return;
}
