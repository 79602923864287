import React, { useState } from "react";
import { Accordion, Divider, Icon } from "semantic-ui-react";
import { FieldValues } from "react-hook-form";
import { CheckboxInput } from "components/shared/InputFields/Inputs/CheckboxInput";
import {
  CheckboxInputs,
  DateInputs,
  FileInputs,
  SelectInputs,
  TextInputs,
} from "./";

export interface FormFields extends FieldValues {
  checked?: boolean;
  date?: Date;
  text?: string;
  select?: string;
}

export type InputsProps = {
  showBoundaries: boolean;
};

const accordionEntries = [
  {
    name: "Text Inputs",
    component: (showBoundaries: boolean) => (
      <TextInputs showBoundaries={showBoundaries} />
    ),
  },
  {
    name: "Select Inputs",
    component: (showBoundaries: boolean) => (
      <SelectInputs showBoundaries={showBoundaries} />
    ),
  },
  {
    name: "DateTime Inputs",
    component: (showBoundaries: boolean) => (
      <DateInputs showBoundaries={showBoundaries} />
    ),
  },
  {
    name: "Checkbox Inputs",
    component: (showBoundaries: boolean) => (
      <CheckboxInputs showBoundaries={showBoundaries} />
    ),
  },
  {
    name: "File Inputs",
    component: (showBoundaries: boolean) => (
      <FileInputs showBoundaries={showBoundaries} />
    ),
  },
];

export const Inputs = () => {
  const [showBoundaries, setShowBoundaries] = useState(false);
  const [activeIndices, setActiveIndices] = useState<number[]>([]);

  const handleClick = (index: number) => {
    if (activeIndices.includes(index))
      setActiveIndices((prev) => [...prev.filter((i) => i !== index)]);
    else setActiveIndices((prev) => [...prev, index]);
  };

  const getAccordionEntry = (
    index: number,
    title: string,
    component: JSX.Element
  ) => (
    <div key={`accordion_${index}`}>
      <Accordion.Title
        index={index}
        active={activeIndices.includes(index)}
        onClick={() => handleClick(index)}
      >
        <Icon name="dropdown" />
        {title}
      </Accordion.Title>
      <Accordion.Content active={activeIndices.includes(index)}>
        <div style={{ padding: "0.1rem 1rem" }}>{component}</div>
      </Accordion.Content>
    </div>
  );

  return (
    <>
      <CheckboxInput
        value={showBoundaries}
        onChange={() => setShowBoundaries((prev) => !prev)}
        checkboxLabel="Show element boundaries"
      />
      <Divider />
      <Accordion inverted>
        {accordionEntries.map((a, index) =>
          getAccordionEntry(index, a.name, a.component(showBoundaries))
        )}
      </Accordion>
    </>
  );
};
