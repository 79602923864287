/** @jsxImportSource @emotion/react */
import React from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownItemProps, DropdownProps } from "semantic-ui-react";
import tw from "twin.macro";
import {
  EMPTY_LIST_MSG,
  LabelledInput,
  InputProps,
  FormProps,
  getLabelProps,
  getCommonInputProps,
  getControlledInputProps,
  getLabelledInputLayoutProps,
  toOptionTypeBase,
} from "components/shared";
import { InputValidation } from "../InputValidation";

const LOADING_TEXT = "Loading ...";
const NO_OPTIONS_TEXT = "No options found";

export type SelectDataType = string | string[] | number;

const ReadOnlyInput = (props: SelectInputProps) => {
  const value = props.formProps?.value ?? props.value;
  const hasMultiValue: boolean = props.isMulti && value && value.length !== 0;
  const readOnlyOptions = props.options
    .concat(toOptionTypeBase(EMPTY_LIST_MSG))
    .concat(
      props.readOnlyEmptyText ? toOptionTypeBase(props.readOnlyEmptyText) : [],
    );
  const hasValue = hasMultiValue || (!props.isMulti && value && value !== "");
  const readOnlyValue = hasValue
    ? value
    : (props.readOnlyEmptyText ?? EMPTY_LIST_MSG);

  const matchedOption = props.options.find(
    (option) => option.value === value,
  )?.text;

  if (props.link && hasValue)
    return (
      <Link css={tw`cursor-pointer underline`} to={props.link(readOnlyValue)}>
        {matchedOption ?? readOnlyValue}
      </Link>
    );
  else if (props.bypassReadOnlyValue && props.isReadOnly) {
    return <span>{props.bypassReadOnlyValue}</span>;
  } else
    return (
      <Dropdown
        fluid
        selection
        className={`readonly ${!hasValue ? "italic" : (props.className ?? "")}`}
        tabIndex={-1}
        options={readOnlyOptions}
        multiple={hasMultiValue}
        renderLabel={props.renderLabel}
        value={readOnlyValue}
      />
    );
};

export type SelectInputProps = {
  options: DropdownItemProps[];
  isMulti?: boolean;
  isLoading?: boolean;
  noOptionsMessage?: string;
  testId?: string;
  isClearable?: boolean;
  link?: (readOnlyValue?: string) => string;
  bypassReadOnlyValue?: string;
  onClear?: () => void;
} & Pick<
  DropdownProps,
  "search" | "renderLabel" | "onSearchChange" | "noResultsMessage"
> &
  InputProps<SelectDataType>;

export const SelectInput = (props: SelectInputProps) => {
  const { inputValidation, isClearable = true, ...inputProps } = props;
  const labelProps = getLabelProps(inputProps);
  const labelledInputProps = getLabelledInputLayoutProps(props);
  const { readOnlyEmptyText, ...commonProps } = getCommonInputProps(inputProps);
  const controlledInputProps = getControlledInputProps(inputProps);
  const formProps = (inputProps as FormProps).formProps;
  if (
    (controlledInputProps.value || controlledInputProps.onChange) &&
    formProps
  )
    console.error(
      "Cannot supply props for both controlled and uncontrolled input to SelectInput. Remove either [formProps] or [value,onChange]",
    );

  const getPlaceholderText = () =>
    props.options.length > 0 || (inputProps.search && inputProps.onSearchChange)
      ? (inputProps.placeholder ?? "Select...")
      : inputProps.isLoading
        ? LOADING_TEXT
        : (props.noOptionsMessage ?? NO_OPTIONS_TEXT);

  const input = props.isReadOnly ? (
    <ReadOnlyInput {...props} />
  ) : (
    <>
      <Dropdown
        data-test-id={props.testId}
        fluid
        selection
        clearable={isClearable}
        selectOnBlur={false} // to avoid auto selecting the first option
        multiple={inputProps.isMulti}
        options={inputProps.options}
        search={inputProps.search}
        loading={inputProps.isLoading}
        renderLabel={inputProps.renderLabel}
        onSearchChange={inputProps.onSearchChange}
        noResultsMessage={inputProps.noResultsMessage}
        className={inputProps.className}
        {...controlledInputProps}
        {...commonProps}
        {...formProps}
        placeholder={getPlaceholderText()}
        error={commonProps.error ?? inputValidation?.invalid}
      />
      {inputValidation && <InputValidation {...inputValidation} />}
    </>
  );

  return props.label ? (
    <LabelledInput {...labelProps} {...labelledInputProps} input={input} />
  ) : (
    input
  );
};
