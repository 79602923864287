import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { EquipmentId, FloorId, PointId, RoomType } from "data/brick";
import { ClassHypernym } from "data/Mason";
import { NodeFormFields } from "data/Mason/ModelDraft/ModelDraftUtils";
import { EntityProperty } from "../../EntityProperties/EntityProperty";
import { EntityProperties } from "../../EntityProperties/EntityProperties";
import { ModelEntityCollectionProps } from "../ModelFormUtils";
import {
  useIsFedByWatcher,
  useLocationOfWatcher,
  usePointsWatcher,
} from "../RelationshipWatchers";
import { handleFieldToArrayChanges } from "../RelationshipWatchers/RelationshipWatcherUtils";
import { useFieldArrayNodes } from "../useFieldArrayNodes";
import {
  Comment,
  CommonInputProps,
  DeleteButton,
  Floor,
  Id,
  IsFedBy,
  LocationOf,
  Name,
  Points,
  TypeInput,
} from "./CommonInputs";
import { FloorFields } from "./Floor";

export type RoomFields = {
  type?: RoomType;
  floor?: FloorId;
  locationOf?: EquipmentId[];
  isFedBy?: EquipmentId[];
  points?: PointId[];
  properties?: EntityProperty[];
} & NodeFormFields;

export const Room = ({
  isReadOnly,
  index,
  deleteNode,
}: ModelEntityCollectionProps) => {
  const inputProps: CommonInputProps = {
    isReadOnly,
    formFieldName: "rooms",
    objectName: "room",
    index,
  };

  useRoomRelationshipWatcher(inputProps);

  return (
    <>
      <Id {...inputProps} />
      <Name {...inputProps} />
      <TypeInput
        {...inputProps}
        hierarchyParams={{ hypernym: ClassHypernym.Location, parent: "Room" }}
      />
      <Floor {...inputProps} />
      <LocationOf {...inputProps} />
      <IsFedBy {...inputProps} />
      <Points {...inputProps} />
      <Comment {...inputProps} />
      <EntityProperties
        {...inputProps}
        hypernym={ClassHypernym.Location}
        parentClass="Room"
      />
      {!isReadOnly && (
        <DeleteButton onDelete={() => deleteNode(index)} objectName="room" />
      )}
    </>
  );
};

const useRoomRelationshipWatcher = (inputProps: CommonInputProps) => {
  const { isReadOnly, formFieldName, index } = inputProps;
  const { getValues, watch, setValue } = useFormContext();
  const { floorFields } = useFieldArrayNodes();

  const entityIdentifier = `${formFieldName}.${index}`;
  const entityFieldId = getValues(`${entityIdentifier}.fieldId`);

  const watcherProps = { isReadOnly, entityFieldId, entityIdentifier };
  useLocationOfWatcher(watcherProps);
  useIsFedByWatcher(watcherProps);
  usePointsWatcher(watcherProps);

  //FLOOR
  const watchFloor = watch(`${entityIdentifier}.floor`);
  useEffect(() => {
    if (!isReadOnly) {
      handleFieldToArrayChanges({
        entityFieldId,
        getValues,
        setValue,
        watchedField: watchFloor,
        existingTargetFields: floorFields.fields,
        targetNodeType: "floor",
        targetProperty: "rooms" as keyof FloorFields,
      });
    }
  }, [
    entityFieldId,
    floorFields.fields,
    getValues,
    isReadOnly,
    setValue,
    watchFloor,
  ]);
};
