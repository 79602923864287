import siteSvg from "assets/icons/site.svg";
import buildingSvg from "assets/icons/building.svg";
import wingSvg from "assets/icons/wing.svg";
import floorSvg from "assets/icons/floor.svg";
import spaceSvg from "assets/icons/room.svg";
import pointSvg from "assets/icons/point.svg";
import equipmentSvg from "assets/icons/equipment-generic.svg";
import electricalSvg from "assets/icons/equipment-electric.svg";
import ahuSvg from "assets/icons/equipment-hvac-ahu.svg";
import coolerSvg from "assets/icons/equipment-hvac-cooler.svg";
import damperSvg from "assets/icons/equipment-hvac-damper.svg";
import fanSvg from "assets/icons/equipment-hvac-fan.svg";
import filterSvg from "assets/icons/equipment-hvac-filter.svg";
import hvacSvg from "assets/icons/equipment-hvac-generic.svg";
import heatingSvg from "assets/icons/equipment-hvac-heating.svg";
import pumpSvg from "assets/icons/equipment-hvac-pump.svg";
import valveSvg from "assets/icons/equipment-hvac-valve.svg";
import lightingSvg from "assets/icons/equipment-lighting.svg";
import meterSvg from "assets/icons/equipment-meter.svg";
import pvPanelSvg from "assets/icons/equipment-pv.svg";
import vfdSvg from "assets/icons/equipment-vfd.svg";
import weatherSvg from "assets/icons/equipment-weather.svg";
import collectionSvg from "assets/icons/collection.svg";
import questionSvg from "assets/icons/circle-question.svg";
import selectSvg from "assets/icons/material-select.svg";
import { DchClass } from "./EntityClassesContext";
import { ClassHypernym, DCHJsonClassResponse } from "data/Mason";

//this is what we get back when we call getDchHierarchy({ hypernym: "Location", parent: "Location", depth: 1 })
export enum LocationParentClass {
  Location = "Location",
  Region = "Region",
  Site = "Site",
  Building = "Building",
  Wing = "Wing",
  Floor = "Floor",
  Storey = "Storey",
  Space = "Space",
  Outside = "Outside",
  Outdoor_Area = "Outdoor_Area",
}

//these are our custom choices for "parent" level equipment
export enum EquipmentParentClass {
  Electrical_Equipment = "Electrical_Equipment",
  Meter = "Meter",
  Lighting_Equipment = "Lighting_Equipment",
  PV_Panel = "PV_Panel",
  Weather_Station = "Weather_Station",
  VFD = "Variable_Frequency_Drive",
  //HVAC sub-classes
  AHU = "AHU",
  Fan = "Fan",
  Filter = "Filter",
  Chiller = "Chiller",
  Cooling_Tower = "Cooling_Tower",
  Water_Heater = "Water_Heater",
  Heat_Exchanger = "Heat_Exchanger",
  Pump = "Pump",
  Damper = "Damper",
  Valve = "Valve",
  //generic catch-alls
  HVAC_Equipment = "HVAC_Equipment", //keep this below HVAC sub-classes as we want to match lower-level category first if possible

  /* COLLECTIONS
  this is a horrible hack, currently needed because Collections can be implemented as Equipment in DCH but not in BRICK (DCH-5551)
  */
  Collection = "Collection",

  Equipment = "Equipment", //keep this at the bottom as the fallback equipment parent class
}

export enum PointParentClass {
  Point = "Point",
}

enum ZoneParentClass {
  Zone = "Zone",
}

enum CollectionParentClass {
  Collection = "Collection",
}

export enum UnknownParentClass {
  Unknown = "Unknown",
}

export type ParentClass =
  | LocationParentClass
  | EquipmentParentClass
  | PointParentClass
  | ZoneParentClass
  | CollectionParentClass
  | UnknownParentClass;

export const classIcons = new Map<ParentClass, string>([
  [LocationParentClass.Location, siteSvg],
  [LocationParentClass.Site, siteSvg],
  [LocationParentClass.Building, buildingSvg],
  [LocationParentClass.Wing, wingSvg],
  [LocationParentClass.Floor, floorSvg],
  [LocationParentClass.Space, spaceSvg],
  [EquipmentParentClass.Equipment, equipmentSvg],
  [EquipmentParentClass.Electrical_Equipment, electricalSvg],
  [EquipmentParentClass.Meter, meterSvg],
  [EquipmentParentClass.Lighting_Equipment, lightingSvg],
  [EquipmentParentClass.PV_Panel, pvPanelSvg],
  [EquipmentParentClass.Weather_Station, weatherSvg],
  [EquipmentParentClass.VFD, vfdSvg],
  [EquipmentParentClass.HVAC_Equipment, hvacSvg],
  [EquipmentParentClass.AHU, ahuSvg],
  [EquipmentParentClass.Fan, fanSvg],
  [EquipmentParentClass.Filter, filterSvg],
  [EquipmentParentClass.Chiller, coolerSvg],
  [EquipmentParentClass.Cooling_Tower, coolerSvg],
  [EquipmentParentClass.Water_Heater, heatingSvg],
  [EquipmentParentClass.Heat_Exchanger, heatingSvg],
  [EquipmentParentClass.Pump, pumpSvg],
  [EquipmentParentClass.Damper, damperSvg],
  [EquipmentParentClass.Valve, valveSvg],
  [PointParentClass.Point, pointSvg],
  [CollectionParentClass.Collection, collectionSvg],
  [ZoneParentClass.Zone, selectSvg],
  [UnknownParentClass.Unknown, questionSvg],
]);

export const getClassesWithParent = (
  hypernym: ClassHypernym,
  dchClasses: DCHJsonClassResponse[]
) => {
  let myClasses = new Map<string, DchClass>();

  const enumValues = Object.values(
    hypernym === ClassHypernym.Equipment
      ? EquipmentParentClass
      : LocationParentClass
  );

  const getDefaultParentByHypernym = (hypernym: ClassHypernym) => {
    switch (hypernym) {
      case ClassHypernym.Location:
        return LocationParentClass.Location;
      case ClassHypernym.Equipment:
        return EquipmentParentClass.Equipment;
      case ClassHypernym.Point:
        return PointParentClass.Point;
      case ClassHypernym.Zone:
        return ZoneParentClass.Zone;
      case ClassHypernym.Collection:
        return CollectionParentClass.Collection;
      default:
        return UnknownParentClass.Unknown;
    }
  };

  const findParentClass = (parent: string[]): ParentClass => {
    if (parent.length > 1) {
      //if we have multiple parents, look at their parents and see if we have a parent class match
      //take the first matching parent in the enum order
      const firstMatchingParent = enumValues.find((p) => parent.includes(p));
      return firstMatchingParent
        ? (firstMatchingParent as ParentClass)
        : findParentClass(
            dchClasses
              .filter((d) => parent.includes(d.type))
              .flatMap((d) => d.parents)
          );
    } else {
      //find the class details
      const parentClass = dchClasses.filter((d) => d.type === parent[0])?.[0];
      if (parentClass) {
        return enumValues.includes(parentClass.type as ParentClass)
          ? (parentClass.type as ParentClass)
          : findParentClass(parentClass.parents);
      }
    }
    //no parent found - shouldn't wind up here, but if we do just make it the default top-level class
    return getDefaultParentByHypernym(hypernym);
  };

  dchClasses.forEach((c) => {
    const parentClassName = enumValues.includes(c.type as ParentClass)
      ? (c.type as ParentClass)
      : findParentClass(c.parents);
    myClasses.set(c.type, {
      ...c,
      parentClass: parentClassName,
      hypernym,
    });
  });
  return myClasses;
};
