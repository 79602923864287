import {
  IconShapeStyle,
  NodeBadgeShapeStyle,
  NodeBadgeStateStyle,
} from "./Types";
import {
  classIcons,
  LocationParentClass,
  ParentClass,
  UnknownParentClass,
} from "data/EntityClasses/ParentClassHelper";
import { ClassHypernym } from "data/Mason";

//colours
const colorSite = "#DC73FF";
const colorBuilding = "#DC73FF";
const colorWing = "#00B5AD";
const colorFloor = "#FF82C8";
const colorSpace = "#FFC09F";
const colorEquipment = "#FD6C3B";
const colorPoint = "#73B9F4";
const colorGrey = "#979797";
const colorGreyDark = "#252525";
const colorBackground = "#000000";

export const getClassColor = (
  parentClass: ParentClass,
  hypernym?: ClassHypernym
) => {
  switch (hypernym) {
    case ClassHypernym.Location:
      switch (parentClass) {
        case LocationParentClass.Site:
          return colorSite;
        case LocationParentClass.Building:
          return colorBuilding;
        case LocationParentClass.Wing:
          return colorWing;
        case LocationParentClass.Floor:
          return colorFloor;
        case LocationParentClass.Space:
          return colorSpace;
        default:
          return colorGrey;
      }
    case ClassHypernym.Equipment:
    case ClassHypernym.Collection:
      return colorEquipment;
    case ClassHypernym.Point:
      return colorPoint;
  }
  return colorGrey;
};

const getClassIcon = (
  parentClass: ParentClass,
  hypernym?: ClassHypernym
): IconShapeStyle => {
  switch (hypernym) {
    case ClassHypernym.Location:
      return { img: classIcons.get(parentClass) };
    case ClassHypernym.Equipment:
    case ClassHypernym.Point:
      return { img: classIcons.get(parentClass), width: 32, height: 32 };
    default:
      return {
        img: classIcons.get(parentClass ?? UnknownParentClass.Unknown),
        width: 32,
        height: 32,
      };
  }
};

const getHaloStrokeColor = (color: string) =>
  `r(0.5,0.5,1) 0:${color} 0.5:${color} 0.8:#000000 1:#000000`;

export const getClassNodeStyle = (
  parentClass: ParentClass,
  hypernym?: ClassHypernym
): NodeBadgeShapeStyle => {
  const color = getClassColor(parentClass, hypernym);
  return {
    icon: getClassIcon(parentClass, hypernym),
    label: { fill: color },
    halo: { stroke: getHaloStrokeColor(color) },
  };
};

export const defaultNodeStyle = {
  type: "node-badge",
  style: {
    keyShape: {
      size: 60,
      fill: colorBackground,
      lineWidth: 1,
      stroke: colorGrey,
    },
    label: {
      textAlign: "center",
      textBaseline: "top",
      fontSize: 12,
      opacity: 0,
    },
    icon: {
      width: 28,
      height: 32,
      opacity: 1,
    },
    badge: {
      stroke: colorGrey,
      size: 18,
      opacity: 1,
      textAlign: "center",
      textBaseline: "middle",
      fill: colorBackground,
    },
    halo: {
      size: 100,
      strokeOpacity: 0,
    },
  },
};

export const defaultEdgeStyle = {
  style: {
    stroke: colorGrey,
    opacity: 0.8,
    lineWidth: 1,
  },
};

export const mixStyles = (
  style1: NodeBadgeShapeStyle,
  style2: NodeBadgeShapeStyle,
  style3?: NodeBadgeShapeStyle
): NodeBadgeShapeStyle => {
  let myStyle: NodeBadgeShapeStyle = {
    keyShape: { ...style1?.keyShape, ...style2?.keyShape, ...style3?.keyShape },
    icon: { ...style1?.icon, ...style2?.icon, ...style3?.icon },
    label: { ...style1?.label, ...style2?.label, ...style3?.label },
    badge: { ...style1?.badge, ...style2?.badge, ...style3?.badge },
    halo: { ...style1?.halo, ...style2?.halo, ...style3?.halo },
  };
  return myStyle;
};

export const defaultNodeStateStyle: NodeBadgeStateStyle = {
  status: {
    hover: {
      halo: { strokeOpacity: 0.4 },
      label: {
        opacity: 1,
        fontSize: 16,
      },
    },
    click: {
      halo: { strokeOpacity: 0.4 },
      label: {
        opacity: 1,
        fontSize: 16,
      },
    },
    active: {
      label: { opacity: 1 },
    },
    inactive: {
      keyShape: { stroke: colorGreyDark },
      icon: { opacity: 0.2 },
      badge: {
        visible: false,
      },
      label: { opacity: 0 },
    },
  },
};

export const defaultEdgeStateStyle = {
  active: { stroke: colorGrey },
  inactive: {
    stroke: colorGreyDark,
    opacity: 0.6,
  },
};
