import React, { useEffect, useState } from "react";
import { InfoHelper } from "components/shared";
import { IncludeSubclassesModal } from "./IncludeSubclassesModal";
import { LabelValue } from "./Common";
import { DCHJsonClassResponse } from "data/Mason";
import { Form } from "components/shared/Forms/ReactHookForm";
import { useFormContext } from "react-hook-form";

const SUBCLASSES_HELP =
  "'Subclasses' are more narrowly specialised versions of other classes.";

export const IncludeSubclassesCheckbox = ({
  fieldName,
  isLoading,
  currentlySelectedClasses,
  options,
  updateValues,
}: {
  fieldName: string;
  isLoading: boolean;
  currentlySelectedClasses: string[];
  options: LabelValue<DCHJsonClassResponse>[];
  updateValues: (_: string[]) => void;
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { watch } = useFormContext();
  const watchIncludeSubclasses: boolean = watch(fieldName);

  useEffect(() => {
    if (!watchIncludeSubclasses) {
      if (currentlySelectedClasses?.length > 0) {
        setModalOpen(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchIncludeSubclasses]);

  return (
    <div key={fieldName}>
      <Form.CheckboxInput
        name={fieldName}
        disabled={!!isLoading}
        checkboxLabel={
          <span>
            include subclasses{" "}
            <InfoHelper
              body={SUBCLASSES_HELP}
              disabled={true}
              position="left"
            />
          </span>
        }
        defaultValue={true}
      />
      <IncludeSubclassesModal
        open={modalOpen}
        setOpen={setModalOpen}
        options={options}
        selectedOptions={currentlySelectedClasses}
        updateSelectedValues={updateValues}
      />
    </div>
  );
};
