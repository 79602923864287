import { FULFILLED_PROMISE, REJECTED_PROMISE } from "data/constants";
import { sensorApi, Stream, StreamId } from "../senaps";
import { max, min } from "date-fns";

export type StreamMinMaxDate = { min?: Date; max?: Date };

/**
 * Get the min and max date of a collection of streams
 * @param streamIds
 */
export const getStreamsMinMaxUsingIds =
  (streamIds: StreamId[]) =>
  (
    onSuccess: (minMax: StreamMinMaxDate) => void,
    onError: (error: string) => void
  ) => {
    Promise.allSettled(
      streamIds.map((id) =>
        sensorApi
          .getStreamId(id)({})
          .then((s) => s)
      )
    ).then((results) => {
      const streams = results
        .filter((r) => r.status === FULFILLED_PROMISE)
        .map((r) => (r as PromiseFulfilledResult<any>).value);

      const rejected = results.filter((r) => r.status === REJECTED_PROMISE);
      if (rejected.length > 0) {
        onError(
          rejected
            .map((r) => (r as PromiseRejectedResult).reason.error)
            .reduce((c, a) => a + "\n" + c, "")
            .trim()
        );
      } else {
        onSuccess(getStreamsMinMax(streams));
      }
    });
  };

/**
 * Get the min and max date of a collection of streams
 * @param streams
 */
export const getStreamsMinMax = (streams: Stream[]): StreamMinMaxDate => {
  if (streams.length > 0) {
    const minDates = streams
      .filter(
        (s) =>
          s.resultsSummary && s.resultsSummary.first && s.resultsSummary.first.t
      )
      .map((s) => new Date(s.resultsSummary?.first?.t as string));

    const maxDates = streams
      .filter(
        (s) =>
          s.resultsSummary && s.resultsSummary.last && s.resultsSummary.last.t
      )
      .map((s) => new Date(s.resultsSummary?.last?.t as string));

    return {
      min: minDates.length > 0 ? min(minDates) : undefined,
      max: maxDates.length > 0 ? max(maxDates) : undefined,
    };
  } else {
    return {};
  }
};
