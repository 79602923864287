import { Eq } from "fp-ts/lib/Eq";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as S from "fp-ts/lib/Set";
import {
  SiteId,
  BuildingId,
  LocationId,
  PointId,
  eqLocationId,
  eqPointId,
  LocationType,
  DCHModel,
  PointType,
} from "data/brick";
import { OrgId } from "data/Enodia";
import {
  Solution,
  SolutionNodes,
  QueryResponse,
} from "data/QueryApi/queryApiTypes";
import {
  BuildingListMap,
  NodeValue,
  PointInfo,
  ResponseValue,
} from "data/QueryApi/queryTypes";
import { StreamId } from "data/senaps";
import { LocationsAndChildrenVars } from "./Queries";

type BreadcrumbIds = {
  orgId: OrgId;
  siteId: SiteId;
  buildingId?: BuildingId;
  hash: string;
};

type Building = {
  orgId: OrgId;
  siteId: SiteId;
  buildingId?: BuildingId;
  label?: string;
};

export const extract = (s: string): O.Option<BreadcrumbIds> => {
  const buildingRE = /^dch:org\/(.*)\/site\/(.*)\/building\/(.*)#(.*)$/;
  const siteRE = /^dch:org\/(.*)\/site\/(.*)#(.*)$/;

  const bFound = s.match(buildingRE);
  const sFound = s.match(siteRE);

  return bFound != null &&
    bFound[1] != null &&
    bFound[2] != null &&
    bFound[3] != null &&
    bFound[4] != null
    ? O.some({
        orgId: bFound[1],
        siteId: bFound[2],
        buildingId: bFound[3],
        hash: bFound[4],
      } as BreadcrumbIds)
    : sFound != null &&
      sFound[1] != null &&
      sFound[2] != null &&
      sFound[3] != null
    ? O.some({
        orgId: sFound[1],
        siteId: sFound[2],
        hash: sFound[3],
      } as BreadcrumbIds)
    : O.none;
};

export type ResultLocation = {
  location: LocationId;
  type?: LocationType | PointType;
  label?: string;
};

type ResultPoint = {
  id: PointId;
  label?: string;
  type?: PointType;
};

// This is everything we need to render a result.
export type Result = {
  building: Building;
  location: ResultLocation;
  point: ResultPoint;
  parent1?: ResultLocation;
  parent2?: ResultLocation;
};

// We don't care about results for the same location with different parents, so our notion of
// equality isn't the default "compare everything" you might expect.
export const eqResult: Eq<Result> = {
  equals: (a, b) =>
    eqLocationId.equals(a.location.location, b.location.location) &&
    eqPointId.equals(a.point.id, b.point.id),
};

export const unionResults = S.union(eqResult);

type Node = { fullId: string; nodeValue: NodeValue };

type NodesForSolution = {
  point?: NodeValue;
  location: NodeValue;
  p1: O.Option<NodeValue>;
  p2: O.Option<NodeValue>;
};

export type ResultExtractor = (
  buildingData: BuildingListMap<DCHModel>,
  solutionNodes: SolutionNodes,
  solution: Solution
) => Set<Result>;

const mkLocation =
  (buildingModel: DCHModel | undefined) =>
  (nv: NodeValue): O.Option<ResultLocation> => {
    const f =
      (location: LocationId) =>
      (type: LocationType): ResultLocation => {
        const label =
          type === LocationType.Floor
            ? buildingModel?.floor.get(location)?.label
            : type === LocationType.Room
            ? buildingModel?.room.get(location)?.label
            : type === LocationType.Wing
            ? buildingModel?.wing.get(location)?.label
            : buildingModel?.equipment.get(location)?.label;

        return {
          location,
          type,
          label,
        };
      };

    return pipe(
      O.some(f),
      O.ap(O.fromNullable(nv.id) as O.Option<LocationId>),
      O.ap(O.fromNullable(nv.type) as O.Option<LocationType>)
    );
  };

const mkPoint =
  (buildingModel: DCHModel | undefined) =>
  (id: PointId): ResultPoint => ({
    id: id,
    label: buildingModel?.point.get(id)?.label,
    type: buildingModel?.point.get(id)?.point_type,
  });

const resultsFromNode = (
  buildingData: BuildingListMap<DCHModel>,
  building: Building,
  nodes: NodesForSolution
): Set<Result> => {
  // TODO: PointInfo.point should be something like FullId<Point>
  const pointId = (p: PointInfo) =>
    O.getOrElse(() => p.point)(
      O.map((bc: BreadcrumbIds) => bc.hash as PointId)(extract(p.point))
    );

  const explodePointInfo = (
    p: PointInfo
  ): Array<[PointId, StreamId | undefined]> => {
    const pId = pointId(p) as PointId;
    if (p.streams.length === 0) {
      return [[pId, undefined]];
    } else {
      return p.streams.map((s: StreamId) => [pointId(p), s]);
    }
  };

  const streams: O.Option<[PointId, StreamId | undefined][]> = nodes.point
    ? O.fromNullable(
        nodes?.point?.streams?.length === 0
          ? [[nodes.point?.id as PointId, undefined]]
          : nodes.point.streams?.map((s: StreamId) => [
              nodes.point?.id as PointId,
              s,
            ])
      )
    : O.fromNullable(nodes.location?.pointInfo?.flatMap(explodePointInfo));

  const buildingModel = buildingData
    .get(building.orgId)
    ?.get(building.siteId)
    ?.find(
      (m) => O.toUndefined(extract(m.id))?.buildingId === building.buildingId
    );

  building.label = buildingModel?.label;

  const p1 = O.chain(mkLocation(buildingModel))(nodes.p1);
  const p2 = O.chain(mkLocation(buildingModel))(nodes.p2);
  const location = mkLocation(buildingModel)(nodes.location);

  const mkResult = (
    point: ResultPoint,
    streamId: StreamId | undefined,
    parent1: ResultLocation | undefined,
    parent2: ResultLocation | undefined,
    location: ResultLocation
  ): Result => ({
    building,
    point: point,
    location,
    parent1,
    parent2,
  });

  const assembleResults =
    (ss: Array<[PointId, StreamId | undefined]>) =>
    (loc: ResultLocation): Array<Result> =>
      ss.map(([pid, sid]) =>
        mkResult(
          mkPoint(buildingModel)(pid),
          sid,
          O.toUndefined(p1),
          O.toUndefined(p2),
          loc
        )
      );

  const oResults = pipe(O.some(assembleResults), O.ap(streams), O.ap(location));
  const results = O.getOrElse(() => [] as Array<Result>)(oResults);
  return S.fromArray(eqResult)(results);
};

const toSolutionNode =
  (solutionNodes: SolutionNodes, solution: Solution) =>
  (variableName: LocationsAndChildrenVars): O.Option<Node> =>
    O.chain((tableVar: ResponseValue) => {
      if (tableVar.varType === "reference" && tableVar.fullId) {
        const variableSolution = solutionNodes[variableName]?.filter(
          (node) => node.fullId === tableVar.fullId
        )[0];
        if (variableSolution)
          return O.some({
            fullId: tableVar.fullId,
            nodeValue: {
              ...variableSolution,
              id: variableSolution.id || tableVar.fullId,
            },
          });
      }
      return O.none;
    })(O.fromNullable(solution[variableName]));

const getNodeValue = (on: O.Option<Node>) =>
  O.map((n: Node) => n.nodeValue)(on);

const toSolutionNodeValue =
  (solutionNodes: SolutionNodes, solution: Solution) =>
  (v: LocationsAndChildrenVars) =>
    getNodeValue(toSolutionNode(solutionNodes, solution)(v));

/**
 * Explode out the points and their streams into a flat table of results from query: {@link locationsOrEquipmentAndChildren}
 *
 * @param buildingData
 * @param solutionNodes
 * @param solution
 */
export const resultsFromLocationsOrEquipmentAndChildrenQuery: ResultExtractor =
  (buildingData, solutionNodes, solution) => {
    const oLocationNode = toSolutionNode(
      solutionNodes,
      solution
    )(LocationsAndChildrenVars.Node1);

    const parent1 = toSolutionNodeValue(
      solutionNodes,
      solution
    )(LocationsAndChildrenVars.Parent1);

    const parent2 = toSolutionNodeValue(
      solutionNodes,
      solution
    )(LocationsAndChildrenVars.Parent2);

    const oBuilding = O.chain(
      ({ fullId }: Node) => extract(fullId) as O.Option<Building>
    )(oLocationNode);

    const getResults = (
      ol: O.Option<NodeValue>,
      p1: O.Option<NodeValue>,
      p2: O.Option<NodeValue>
    ): Set<Result> => {
      const f =
        (b: Building) =>
        (location: NodeValue): Set<Result> =>
          resultsFromNode(buildingData, b, { location, p1, p2 });

      return pipe(
        O.some(f),
        O.ap(oBuilding),
        O.ap(ol),
        O.getOrElse(() => S.empty as Set<Result>)
      );
    };

    const oLocation = getNodeValue(oLocationNode);
    const locationResults = getResults(oLocation, parent1, parent2);
    return locationResults;
  };

/**
 * Explode out the points and their streams into a flat table of results from query: {@link locationsAndEquipmentAndChildren}
 *
 * @param buildingData
 * @param solutionNodes
 * @param solution
 */
export const resultsFromLocationsAndEquipmentAndChildrenQuery: ResultExtractor =
  (buildingData, solutionNodes, solution) => {
    const pointNode = toSolutionNode(
      solutionNodes,
      solution
    )(LocationsAndChildrenVars.Point);

    const locationNode = toSolutionNodeValue(
      solutionNodes,
      solution
    )(LocationsAndChildrenVars.Location);

    const locationParent1 = toSolutionNodeValue(
      solutionNodes,
      solution
    )(LocationsAndChildrenVars.LocationParent1);

    const locationParent2 = toSolutionNodeValue(
      solutionNodes,
      solution
    )(LocationsAndChildrenVars.LocationParent2);

    const building = O.chain(
      ({ fullId }: Node) => extract(fullId) as O.Option<Building>
    )(pointNode);

    const getResults = (
      point: O.Option<NodeValue>,
      l: O.Option<NodeValue>,
      p1: O.Option<NodeValue>,
      p2: O.Option<NodeValue>
    ): Set<Result> => {
      const f =
        (b: Building) =>
        (point: NodeValue) =>
        (location: NodeValue): Set<Result> =>
          resultsFromNode(buildingData, b, { point, location, p1, p2 });

      return pipe(
        O.some(f),
        O.ap(building),
        O.ap(point),
        O.ap(l),
        O.getOrElse(() => S.empty as Set<Result>)
      );
    };

    const point = getNodeValue(pointNode);
    const locationResults = getResults(
      point,
      locationNode,
      locationParent1,
      locationParent2
    );

    const equipmentNode = toSolutionNodeValue(
      solutionNodes,
      solution
    )(LocationsAndChildrenVars.Equipment);

    const equipmentParent1 = toSolutionNodeValue(
      solutionNodes,
      solution
    )(LocationsAndChildrenVars.EquipmentParent1);

    const equipmentParent2 = toSolutionNodeValue(
      solutionNodes,
      solution
    )(LocationsAndChildrenVars.EquipmentParent2);

    const equipmentResults = getResults(
      point,
      equipmentNode,
      equipmentParent1,
      equipmentParent2
    );

    return unionResults(locationResults, equipmentResults);
  };

export const resultsFromQueryResponse =
  (buildingData: BuildingListMap<DCHModel>, response: QueryResponse) =>
  (resultExtractor: ResultExtractor): Set<Result> => {
    return (
      response.solutionTable?.reduce(
        (s: Set<Result>, solution: Solution) =>
          unionResults(
            s,
            resultExtractor(buildingData, response.solutionNodes, solution)
          ),
        S.empty
      ) || S.empty
    );
  };
