import { EntityProperty } from "components/SitesAndBuildings/Model/EntityProperties/EntityProperty";
import {
  EntityPropertyAttribute,
  EntityPropertyDto,
  EntityPropertyFromQueryDto,
  EntityPropertyJsonSchema,
} from ".";

export const DEFAULT_SCHEMA_NAME = "brick";
export const KEYVALUE_ID = "keyValue";
export const BASIC_EP_ATTRIBUTES = ["key", "value", "hasUnit"];
const EP_TYPE_ATTRIBUTE = "ep_type";

export const getPropertyName = (propertyName: string) => {
  return propertyName?.substring(propertyName.lastIndexOf("#") + 1);
};

export const getUnitName = (unit: string) => {
  //units are sometimes defined following the last "/", ":", or "#"
  const slash = unit.substring(unit.lastIndexOf("/") + 1);
  const hashSubstring = slash.substring(slash.lastIndexOf("#") + 1);
  const colonSubstring = hashSubstring.substring(
    hashSubstring.lastIndexOf(":") + 1
  );
  return `unit:${colonSubstring}`;
};

export const getEntityPropertyDefinition = (
  id: string,
  schema: string = DEFAULT_SCHEMA_NAME
) => `${schema}:${id}`;

export const getPropertyNameFromDefinition = (
  propertyDefinition: string,
  schema: string = DEFAULT_SCHEMA_NAME
) => {
  return propertyDefinition.replace(`${schema}:`, "");
};

export const getAttributeValue = (property: EntityProperty, id: string) => {
  if (id === "key" && property.key !== undefined) return property.key;
  if (id === "value" && property.value !== undefined) return property.value;
  if (id === "hasUnit" && property.unit !== undefined) return property.unit;
  //search for the prop in the nested properties
  if (property.nested) {
    if (property.nested.map((n) => n.entityPropertyId).includes(id)) {
      return property.nested.filter((n) => n.entityPropertyId === id)?.[0]
        .value;
    }
  }
  return undefined;
};

const EP_SORT_ARRAY = [
  "aggregationFunction",
  "aggregationInterval",
  "key",
  "value",
  "hasUnit",
  "latitude",
  "longitude",
  "scale",
  "offset",
  "ambientTemperatureOfMeasurementDegC",
];

export const sortPropertiesByName = (a: string, b: string) =>
  getPropertyName(a) > getPropertyName(b)
    ? 1
    : getPropertyName(a) < getPropertyName(b)
    ? -1
    : 0;

export const sortPropertiesByArray = (a: string, b: string) => {
  const indexA = EP_SORT_ARRAY.indexOf(a);
  const indexB = EP_SORT_ARRAY.indexOf(b);
  return indexA === -1
    ? //a isn't in the array - if b is then order them alphabetically, otherwise put a at the end
      indexB === -1
      ? sortPropertiesByName(a, b)
      : 1
    : //index A is in the array - if b isn't then put a ahead of b, otherwise order by array index
    indexB === -1
    ? -1
    : indexA > indexB
    ? 1
    : indexA < indexB
    ? -1
    : 0;
};

export type EntityPropertyAttributeSchema = {
  id: string;
  schemaDefinition?: string;
  type?: Array<string>;
  enumValues?: Array<string>;
  required?: boolean;
  minimum?: number;
  maximum?: number;
  nestedIndex?: number;
};

const getAttributeSchema = (
  id: string,
  prop: EntityPropertyAttribute,
  schema?: string
): EntityPropertyAttributeSchema => {
  const isBasicEP = BASIC_EP_ATTRIBUTES.includes(id);
  return {
    id: id,
    schemaDefinition: isBasicEP
      ? undefined
      : `${schema ?? DEFAULT_SCHEMA_NAME}:${id}`, //we don't want to assign a schema definition for key,value,or hasUnit
    type: prop.type
      ? Array.isArray(prop.type)
        ? (prop.type as string[])
        : [prop.type]
      : undefined,
    enumValues: prop.enum,
    minimum: prop.minimum,
    maximum: prop.maximum,
  };
};
export const getEntityPropertyAttributes = (
  jsonSchema: EntityPropertyJsonSchema,
  schema?: string
): EntityPropertyAttributeSchema[] => {
  let attributes: Array<EntityPropertyAttributeSchema> = [];
  if (jsonSchema.oneOf && jsonSchema.oneOf.length > 0) {
    const requiredAttributes: Array<Array<string>> = [];
    jsonSchema.oneOf.forEach((option) => {
      requiredAttributes.push(option.required ?? []);
      if (option.properties) {
        Object.entries(option.properties)
          .filter(([id, _]) => id !== EP_TYPE_ATTRIBUTE)
          .forEach(([id, prop]) => {
            //is this attribute already defined?
            const existingProperty = attributes.filter((p) => p.id === id)?.[0];
            if (existingProperty) {
              //already defined - update the type array if necessary
              if (
                prop.type &&
                JSON.stringify(existingProperty.type) !==
                  JSON.stringify(prop.type)
              ) {
                if (Array.isArray(prop.type)) {
                  prop.type.forEach((type) => {
                    if (!existingProperty.type?.includes(type as string))
                      existingProperty.type?.push(prop.type as string);
                  });
                } else if (
                  !existingProperty.type?.includes(prop.type as string)
                )
                  existingProperty.type?.push(prop.type as string);
              }
            }
            //hasn't already been defined, add to attributes
            else attributes.push(getAttributeSchema(id, prop, schema));
          });
      }
    });
    //add required flag for those attributes required in all cases
    attributes.forEach((attr) => {
      if (requiredAttributes.filter((r) => !r.includes(attr.id)).length === 0) {
        attr.required = true;
      }
    });
  } else if (!jsonSchema.properties) attributes = [];
  else {
    let nestedAttributes: string[] = [];
    Object.entries(jsonSchema.properties).forEach(([key, _]) => {
      if (!BASIC_EP_ATTRIBUTES.concat(EP_TYPE_ATTRIBUTE).includes(key))
        nestedAttributes.push(key);
    });
    attributes = Object.entries(jsonSchema.properties)
      .map(([id, prop]) => ({
        ...getAttributeSchema(id, prop, schema),
        required: jsonSchema.required?.includes(id),
        nestedIndex:
          nestedAttributes &&
          nestedAttributes.length > 0 &&
          nestedAttributes.includes(id)
            ? nestedAttributes.indexOf(id)
            : undefined,
      }))
      .filter((prop) => prop.id !== EP_TYPE_ATTRIBUTE);
  }

  return attributes.sort((a, b) => sortPropertiesByArray(a.id, b.id));
};

/* METHODS FOR CONVERTING BETWEEN THE API'S TWO DIFFERENT STRUCTURES FOR ENTITY PROPERTIES
    AND THE PROPS STRUCTURE REQUIRED BY THE REACT COMPONENT */

const convertPropertyDtoToEntityProperty = (
  property: EntityPropertyDto
): EntityProperty => {
  const isKeyValue = property.schema === undefined || property.schema === null;
  const id = isKeyValue ? KEYVALUE_ID : property.name;
  return {
    schemaDefinition: `${property.schema ?? DEFAULT_SCHEMA_NAME}:${id}`,
    entityPropertyId: id,
    key: isKeyValue ? getPropertyName(property.name) : undefined,
    value:
      property.intval ??
      property.numval ??
      property.strval ??
      property.urival ??
      property.boolval?.toString() ??
      undefined,
    unit: property.unit ? getUnitName(property.unit) : undefined,
    schema: property.schema ?? DEFAULT_SCHEMA_NAME,
    nested: property.nested
      ? convertDtosToEntityProperties(property.nested)
      : undefined,
  };
};

export const convertDtosToEntityProperties = (
  properties?: EntityPropertyDto[]
): EntityProperty[] => {
  if (!properties) return [];
  return properties.map((p) => convertPropertyDtoToEntityProperty(p));
};

enum ValueType {
  Number,
  String,
}

const convertEntityPropertyToPropertyDto = (
  property: EntityProperty
): EntityPropertyDto => {
  const isKeyValue =
    property.schema === undefined ||
    property.schema === null ||
    property.entityPropertyId === KEYVALUE_ID;

  const hasValue = !!property.value && property.value !== "";
  const valueType =
    hasValue &&
    typeof property.value === "number" &&
    !isNaN(Number(property.value))
      ? ValueType.Number
      : ValueType.String;

  return {
    name: isKeyValue && property.key ? property.key : property.entityPropertyId,
    numval:
      valueType === ValueType.Number && hasValue
        ? Number(property.value)
        : undefined,
    strval:
      valueType === ValueType.String && hasValue
        ? property.value?.toString()
        : undefined,
    unit: property.unit,
    schema: isKeyValue ? undefined : property.schema ?? DEFAULT_SCHEMA_NAME,
    nested: property.nested
      ? convertEntityPropertiesToDtos(property.nested)
      : undefined,
  };
};

export const convertEntityPropertiesToDtos = (
  properties?: EntityProperty[]
): EntityPropertyDto[] => {
  if (!properties) return [];
  return properties.map((p) => convertEntityPropertyToPropertyDto(p));
};

const convertPropertyFromQueryDtoToEntityProperty = (
  property: EntityPropertyFromQueryDto
): EntityProperty => {
  const propertyName = getPropertyName(property.property);
  return {
    schemaDefinition: getEntityPropertyDefinition(
      propertyName,
      property.schema
    ),
    entityPropertyId: propertyName,
    key: property.key,
    value: property.value,
    unit: property.unit ? getUnitName(property.unit) : undefined,
    schema: property.schema ?? DEFAULT_SCHEMA_NAME,
    nested: property.nested
      ? convertQueryDtosToEntityProperties(property.nested)
      : undefined,
  };
};

export const convertQueryDtosToEntityProperties = (
  properties?: EntityPropertyFromQueryDto[]
): EntityProperty[] => {
  if (!properties) return [];
  return properties.map((p) => convertPropertyFromQueryDtoToEntityProperty(p));
};
