/** @jsxImportSource @emotion/react */

import React, { useContext, useState } from "react";
import { Dimmer, Divider } from "semantic-ui-react";
import tw from "twin.macro";
import { EnodiaOrgContext } from "App";
import { Page, PageTitle, UIStatus, UIStatusWrapper } from "components/shared";
import { SearchResults } from "./Results";
import { Result } from "./result-utils";
import { SearchForm } from "./SearchParams/SearchForm";

const Search = () => {
  const { orgId } = useContext(EnodiaOrgContext);
  const [queryStatus, setQueryStatus] = useState(new UIStatus());

  // location enabled and non-empty
  const [queryResults, setQueryResults] = useState(new Set<Result>([]));
  const [showResults, setShowResults] = useState(false);

  return (
    <Page>
      <PageTitle primaryHeader="Point Search" subHeader="Find a Point" />

      <Dimmer.Dimmable blurring dimmed={!orgId} css={tw`z-5`}>
        <Dimmer active={!orgId}>
          <div> Please select an organisation to search</div>
        </Dimmer>
        <SearchForm
          orgId={orgId}
          setQueryStatus={setQueryStatus}
          setShowResults={setShowResults}
          setQueryResults={setQueryResults}
        />
      </Dimmer.Dimmable>
      <Divider />
      <UIStatusWrapper status={queryStatus} clearable={true}>
        {showResults ? (
          <SearchResults results={queryResults} />
        ) : (
          <p css={tw`italic`}>Search results will display here.</p>
        )}
      </UIStatusWrapper>
    </Page>
  );
};

export default Search;
