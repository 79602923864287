/** @jsxImportSource @emotion/react */

import * as React from "react";
import { LatLng, LatLngExpression } from "leaflet";
import { MapContainer, useMap } from "react-leaflet";
import { useNavigate } from "react-router-dom";
import { Button } from "semantic-ui-react";
import tw from "twin.macro";
import { OrgId } from "data/Enodia";
import { editSitePath } from "Routes";
import { UIStatus, UIStatusWrapper } from "components/shared";
import { getSiteDisplayLabel } from "components/SitesAndBuildings/SitesAndBuildings";
import { SiteSearcher } from "components/Search/SiteSearcher";
import { SvgBuildings } from "components/SitesAndBuildings/svg";
import {
  CommonTileLayer,
  DEFAULT_LAT_LONG,
  DEFAULT_ZOOM,
  LOCATION_ZOOM,
  DraggableMarker,
} from ".";
import { SitesAndBuildingsResponse } from "data/Mason/Site/SiteTypes";

interface MapProps {
  orgId?: OrgId;
  sites: SitesAndBuildingsResponse[];
  loading: boolean;
}

export const SitesMap: React.FunctionComponent<MapProps> = ({
  orgId,
  sites,
  loading,
}) => {
  const navigate = useNavigate();
  const [selectedSite, setSelectedSite] =
    React.useState<SitesAndBuildingsResponse>();
  const [selectedSiteHasNoGeojson, setSelectedSiteHasNoGeojson] =
    React.useState<boolean>();
  const [latLng, setLatLng] = React.useState<LatLngExpression>();

  const editSiteButton = (selectedSite: SitesAndBuildingsResponse) => (
    <Button
      primary
      onClick={() => orgId && navigate(editSitePath(orgId, selectedSite.id))}
    >
      Edit site
    </Button>
  );

  return (
    <div>
      <div
        css={[{ top: 10, right: 30, zIndex: 450, width: 350 }, tw`absolute`]}
      >
        <SiteSearcher
          orgId={orgId}
          loadingSites={loading}
          saveSelectedSites={(selectedSites: SitesAndBuildingsResponse[]) =>
            setSelectedSite(selectedSites[0])
          }
          sites={sites}
          multiple={false}
          dropdownProps={{
            placeholder: loading ? "Loading sites ..." : "Jump to site",
            noResultsMessage: "No sites found.",
          }}
        />
      </div>

      <UIStatusWrapper
        status={new UIStatus().setEmpty(
          (selectedSite && selectedSiteHasNoGeojson) || false
        )}
        emptyDataMsg={
          selectedSite && (
            <span>
              {`No location data has been supplied for the ${getSiteDisplayLabel(
                selectedSite
              )}.`}{" "}
              <p css={tw`pt-2`}>{editSiteButton(selectedSite)}</p>
            </span>
          )
        }
      >
        <MapContainer
          center={latLng || DEFAULT_LAT_LONG}
          zoom={DEFAULT_ZOOM}
          scrollWheelZoom={false}
          css={{ height: "55vh", width: "100%" }}
        >
          <MapSetup
            selectedSite={selectedSite}
            setLatLng={setLatLng}
            setSelectedSiteHasNoGeojson={setSelectedSiteHasNoGeojson}
          />
          <CommonTileLayer />
          {sites &&
            sites.map((siteMeta, index) => {
              const displayLabel = getSiteDisplayLabel(siteMeta);
              return (
                <DraggableMarker
                  key={`marker-${index}`}
                  title={displayLabel}
                  pos={latLng}
                  draggable={false}
                  popupText={
                    <div>
                      <div css={tw`flex justify-center`}>
                        <SvgBuildings />
                      </div>

                      <p>
                        <strong>Organisation:</strong> {siteMeta.id}
                      </p>
                      <p>
                        <strong>Site:</strong> {displayLabel}
                      </p>

                      <div css={tw`flex justify-center`}>
                        {editSiteButton(siteMeta)}
                      </div>
                    </div>
                  }
                />
              );
            })}
        </MapContainer>
      </UIStatusWrapper>
    </div>
  );
};

const MapSetup = ({
  selectedSite,
  setLatLng,
  setSelectedSiteHasNoGeojson,
}: {
  selectedSite: SitesAndBuildingsResponse | undefined;
  setLatLng: React.Dispatch<React.SetStateAction<LatLngExpression | undefined>>;
  setSelectedSiteHasNoGeojson: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >;
}) => {
  const map = useMap();

  React.useEffect(() => {
    if (selectedSite?.location?.latitude && selectedSite?.location?.longitude) {
      const latLng = new LatLng(
        selectedSite.location.latitude,
        selectedSite.location.longitude
      );
      if (latLng) {
        setLatLng(latLng);
        setSelectedSiteHasNoGeojson(false);
        map?.setView(latLng, LOCATION_ZOOM);
      } else {
        setSelectedSiteHasNoGeojson(true);
      }
    }
  }, [selectedSite, map, setLatLng, setSelectedSiteHasNoGeojson]);

  return null;
};
