import React from "react";
import { useFormContext } from "react-hook-form";
import { EquipmentId, LocationId, PointType } from "data/brick";
import { ClassHypernym } from "data/Mason";
import { NodeFormFields } from "data/Mason/ModelDraft/ModelDraftUtils";
import { StreamId } from "data/senaps";
import Form from "components/shared/Forms/ReactHookForm";
import { EntityProperty } from "../../EntityProperties/EntityProperty";
import { EntityProperties } from "../../EntityProperties/EntityProperties";
import {
  ModelEntityCollectionProps,
  renderEntityListLabel,
} from "../ModelFormUtils";
import { usePointOfWatcher } from "../RelationshipWatchers";
import { useFormValues } from "../useFormValues";
import { Comment, CommonInputProps, Id, Name, TypeInput } from "./CommonInputs";
import { getCompositePointIdFromRdfId } from "data/Aletheia";
import { PointDataModal } from "components/Search/PointDataModal";
import { Button, Icon } from "semantic-ui-react";

export type PointFields = {
  type?: PointType;
  unit?: string | null;
  pointOf?: (LocationId | EquipmentId)[];
  streamIds?: StreamId[];
  properties?: EntityProperty[];
} & NodeFormFields;

export const Point = ({
  index,
  modelMeta,
  entityId,
}: ModelEntityCollectionProps & { entityId: string }) => {
  const isReadOnly = true; //made temporarily readonly under DCH-4598 (points not yet supported on MASON)
  const inputProps: CommonInputProps = {
    isReadOnly,
    formFieldName: "points",
    objectName: "point",
    index,
  };

  usePointRelationshipWatcher(inputProps);
  const { pointOfOptions } = useFormValues(modelMeta);

  return (
    <>
      <Id {...inputProps} />
      <Name {...inputProps} />
      <TypeInput
        {...inputProps}
        hierarchyParams={{ hypernym: ClassHypernym.Point, parent: "Point" }}
      />
      <Form.TextInput name={`points.${index}.unit`} label="Unit" isReadOnly />
      <Form.SelectInput
        name={`points.${index}.pointOf`}
        label="Point Of"
        description={
          isReadOnly
            ? null
            : "Location(s) or equipment with which the point is associated"
        }
        isMulti
        options={pointOfOptions}
        isReadOnly={isReadOnly}
        renderLabel={renderEntityListLabel(!!isReadOnly)}
      />
      <Comment {...inputProps} />
      <EntityProperties
        {...inputProps}
        hypernym={ClassHypernym.Point}
        parentClass="Point"
      />
      <PointDataModal
        id={getCompositePointIdFromRdfId(entityId)}
        buttonLabel={
          <Button
            type="button"
            primary={false}
            basic
            inverted
            style={{ marginRight: "1rem" }}
          >
            <Icon name={"chart bar"} />
            Show Point Data
          </Button>
        }
        isLink={false}
      />
    </>
  );
};

const usePointRelationshipWatcher = (inputProps: CommonInputProps) => {
  const { isReadOnly, formFieldName, index } = inputProps;
  const { getValues } = useFormContext();

  const entityIdentifier = `${formFieldName}.${index}`;
  const entityFieldId = getValues(`${entityIdentifier}.fieldId`);

  usePointOfWatcher({ isReadOnly, entityFieldId, entityIdentifier });
};
