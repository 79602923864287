import { BuildingId, SiteId } from "data/brick";
import { OrgId } from "data/Enodia";
import {
  BuildingMetaResponse,
  masonApi,
  BuildingPutRequest,
  BuildingGetRequest,
  AddressMetaResponse,
} from "data/Mason";
import {
  AddressFormFields,
  SiteOrBuildingMetadataFormFields,
} from "components/SitesAndBuildings/Metdata/Metadata";
import { ModelProps } from "components/SitesAndBuildings/Model/ModelUtils";
import {
  SiteGetRequest,
  SiteMetaResponse,
  SitePutRequest,
} from "data/Mason/Site/SiteTypes";

/* CRUD METADATA */

export const fetchMetadata = ({ orgId, siteId, buildingId }: ModelProps) => {
  const params = {
    orgId: orgId,
    siteId: siteId,
    buildingId: buildingId,
  };
  return buildingId
    ? masonApi.getBuilding(params as BuildingGetRequest)
    : masonApi.getSite(params as SiteGetRequest);
};

export const publishMetadata = (
  publishProps: BuildingPutRequest | SitePutRequest
) => {
  const params = {
    orgId: publishProps.orgId,
    siteId: publishProps.siteId,
    buildingId: (publishProps as BuildingPutRequest).buildingId,
  };
  return params.buildingId
    ? masonApi.putBuilding(params as BuildingGetRequest)(
        publishProps as BuildingPutRequest
      )
    : masonApi.putSite(params as SiteGetRequest)(
        publishProps as SitePutRequest
      );
};

export const deleteMetadata = (
  orgId: OrgId,
  siteId: SiteId,
  buildingId?: BuildingId
) => {
  return buildingId
    ? masonApi.deleteBuilding({ orgId, siteId, buildingId })
    : masonApi.deleteSite({ orgId, siteId });
};

/* METADATA TRANSFORMS */

type MetaToFormMapper = { metaField: string; formField: string };

/* ADDRESS META */

const ADDRESS_META_TO_FORM_MAPPER: MetaToFormMapper[] = [
  { metaField: "subAddress", formField: "unit" },
  { metaField: "streetNumber", formField: "streetNumber" },
  { metaField: "streetName", formField: "streetName" },
  { metaField: "locality", formField: "city" },
  { metaField: "administrativeArea", formField: "state" },
  { metaField: "countryCode", formField: "country" },
  { metaField: "postcode", formField: "postCode" },
  { metaField: "propertyId", formField: "propertyId" },
  { metaField: "cadastralId", formField: "cadastralId" },
  { metaField: "titleId", formField: "titleId" },
];

export const transformAddressMetaToForm = (
  addressMeta?: AddressMetaResponse
) => {
  let address: AddressFormFields = {};
  if (addressMeta) {
    const keys = Object.keys(addressMeta);
    keys.forEach((key) => {
      const formField = ADDRESS_META_TO_FORM_MAPPER.find(
        (map) => map.metaField === key
      )?.formField;
      const value = addressMeta?.[key as keyof AddressMetaResponse];
      if (formField)
        address[formField as keyof AddressFormFields] = value ?? undefined;
    });
  }
  return address;
};

export const transformAddressFormToMeta = (address: AddressFormFields) => {
  let addressMeta: AddressMetaResponse = {};
  if (address) {
    const addressEntries = Object.entries(address).filter(
      ([_, value]) => !!value
    );
    addressEntries.forEach(([key, value]) => {
      const metaField = ADDRESS_META_TO_FORM_MAPPER.find(
        (map) => map.formField === key
      )?.metaField;
      if (metaField && value)
        addressMeta[metaField as keyof AddressMetaResponse] = value;
    });
  }
  return addressMeta;
};

/* SITE OR BUILDING METADATA */

export const transformMetadataFormToBuildingMeta = (
  orgId: OrgId,
  formValues: SiteOrBuildingMetadataFormFields
) => {
  let metadata: BuildingMetaResponse = {
    orgId: orgId,
    siteId: formValues.siteId,
    buildingId: formValues.buildingId as BuildingId,
    label: formValues.name ?? undefined,
    description: formValues.description ?? undefined,
  };

  return metadata;
};

export const transformMetadataFormToSiteMeta = (
  orgId: OrgId,
  formValues: SiteOrBuildingMetadataFormFields
) => {
  let metadata: SitePutRequest = {
    orgId: orgId,
    siteId: formValues.siteId,
    label: formValues.name ?? undefined,
    description: formValues.description ?? undefined,
    addressMeta: formValues.address
      ? transformAddressFormToMeta(formValues.address)
      : undefined,
    geolocation2d:
      formValues.location?.latitude && formValues.location?.longitude
        ? {
            latitude: formValues.location.latitude,
            longitude: formValues.location.longitude,
          }
        : undefined,
  };
  return metadata;
};

export const transformMetaToFormFields = (
  meta: SiteMetaResponse,
  isBuilding: boolean
) => {
  let formValues: SiteOrBuildingMetadataFormFields = {
    siteId: meta.siteId,
    name: meta.label,
    description: meta.description,
  };
  return isBuilding
    ? { ...formValues, buildingId: (meta as BuildingMetaResponse).buildingId }
    : {
        ...formValues,
        address: transformAddressMetaToForm(meta.addressMeta),
        location: { ...meta.geolocation2d },
      };
};
