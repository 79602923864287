import React, { useContext, useEffect, useState } from "react";
import { Button, Icon } from "semantic-ui-react";
import { generatePath, useParams, useNavigate } from "react-router-dom";
import { EnodiaOrgContext } from "App";
import { Path } from "Routes";
import OrgContextModal from "context/OrgContextModal";
import { OrgId, RoleSetId, UserGroupId } from "data/Enodia";
import { ApiError } from "data/http";
import {
  PermissionParams,
  RoleSetResponse,
  UserGroupResponse,
  enodiaApi,
} from "data/Enodia";
import {
  DchModal,
  Page,
  PageMode,
  PageSection,
  PageTitle,
  redirectMessage,
  resetMessage,
  UIStatus,
  UIStatusWrapper,
} from "components/shared";
import Form, {
  FormMethods,
  FormStateValues,
} from "components/shared/Forms/ReactHookForm";
import UserGroupFormFields from "./UserGroupFormFields";

const errorMessage = (e: ApiError, pageMode: PageMode) => {
  return (
    e.message ||
    `Unable to ${pageMode === PageMode.create ? "create" : "edit"} User Group.`
  );
};

type Props = {
  pageMode: PageMode;
};

type Params = {
  orgId?: OrgId;
  userGroupId?: string;
};

export class UserGroup {
  userGroupId?: string;
  name = "";
  description = "";
  roleSets: RoleSetId[] = [];
  userGroups: UserGroupId[] = [];
}
export const EditOrCreateUserGroup: React.FC<Props> = ({ pageMode }) => {
  const navigate = useNavigate();
  const params = useParams<keyof Params>() as Params;

  const [formState, setFormState] = useState<FormStateValues<UserGroup>>();
  const [formMethods, setFormMethods] = useState<FormMethods<UserGroup>>();

  const { orgId } = useContext(EnodiaOrgContext);

  const [status, setStatus] = useState(new UIStatus());
  const [deleteStatus, setDeleteStatus] = useState(new UIStatus());
  const [showDeleteModal, updateShowDeleteModal] = useState(false);

  const userGroupId = params.userGroupId || "";

  const [modalOpen, setModalOpen] = useState(false);

  const [roleSets, setRoleSets] = useState<Array<RoleSetResponse>>([]);
  const [userGroups, setUserGroups] = useState<Array<UserGroupResponse>>([]);

  const isReadOnly = pageMode === PageMode.view;

  const modalMessage =
    pageMode === PageMode.create ? resetMessage : redirectMessage;

  const handleSubmit = (data: UserGroup) => {
    if (orgId) {
      userGroupId
        ? enodiaApi
            .patchUserGroup(userGroupId)({
              organisationId: orgId,
              name: data.name,
              description: data.description,
              addRoleSetIds: data.roleSets,
              deleteRoleSetIds: [],
              deleteAllRoleSets: true,
              addUserGroupIds: data.userGroups,
              deleteAllUserGroups: true,
              deleteUserGroupIds: [],
            })
            .then(() => {
              navigate(Path.Permissions);
            })
            .catch((e) =>
              setStatus((p) => p.setError(errorMessage(e, pageMode))),
            )
        : enodiaApi
            .postUserGroup({
              organisationId: orgId,
              name: data.name,
              description: data.description,
              roleSetIds: data.roleSets,
              userGroupIds: data.userGroups,
            })
            .then(() => {
              navigate(Path.Permissions);
            })
            .catch((e) =>
              setStatus((p) => p.setError(errorMessage(e, pageMode))),
            );
    }
  };

  const deleteUserGroup = () => {
    setDeleteStatus((prev) => prev.setIndeterminate(true));
    if (userGroupId) {
      enodiaApi
        .deleteUserGroup(userGroupId)
        .then(() => {
          setDeleteStatus((prevState) => prevState.setIndeterminate(false));
          // go back to permissions page instead
          navigate(Path.Permissions);
        })
        .catch((err: ApiError) =>
          setDeleteStatus((prevState) =>
            prevState.setError(
              err.message || "An error occured when deleting the User Group",
            ),
          ),
        );
    }
  };

  // on first load or on org change, get the selection list for usergroups and rolesets
  useEffect(() => {
    const apiParams: PermissionParams = {
      organisation: orgId,
    };
    enodiaApi
      .getRoleSets(apiParams)
      .then((rolesets) => setRoleSets(rolesets))
      .catch((e) => {
        setStatus((prev) => prev.setError(errorMessage(e, pageMode)));
      });
    enodiaApi
      .getUserGroups(apiParams)
      .then((userGroups) => {
        // find and omit the current pages' id
        setUserGroups(userGroups.filter((x) => x.id !== params.userGroupId));
      })
      .catch((e) => {
        setStatus((prev) => prev.setError(errorMessage(e, pageMode)));
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  // calling params vs useState value because only want dependency on param change for edit/view render
  useEffect(() => {
    if (params.userGroupId && formMethods) {
      enodiaApi
        .getUserGroup(params.userGroupId)
        .then((ug) => {
          const userGroup: UserGroup = {
            userGroupId: ug.id,
            name: ug.name,
            description: ug.description ?? "",
            userGroups: ug.inGroupIDs ?? [],
            roleSets: ug.roleSetIDs ?? [],
          };
          formMethods?.reset(userGroup);
        })
        .catch((e) =>
          setStatus((prev) => prev.setError(errorMessage(e, pageMode))),
        );
    }
  }, [formMethods, pageMode, params.userGroupId]);

  const onResetFormConfirm = () => {
    formMethods?.reset();
    setModalOpen(false);
  };
  const onRedirectPageConfirm = () => {
    navigate(Path.Permissions);
  };

  return (
    <Page>
      <OrgContextModal
        open={modalOpen}
        openCondition={!!(params.orgId && params.orgId !== orgId)}
        onConfirm={() =>
          pageMode === PageMode.create
            ? onResetFormConfirm()
            : onRedirectPageConfirm()
        }
        modalMessage={modalMessage}
        setModalState={setModalOpen}
      />
      {userGroupId && (
        <DchModal
          header={`Delete User Group: ${formMethods?.getValues("name")}`}
          content={
            <UIStatusWrapper status={deleteStatus}>
              Are you sure you want to delete this user group? RoleSets under
              <b>{` ${formMethods?.getValues(
                "name",
              )} (id: ${userGroupId}) `}</b>
              will be deleted.
            </UIStatusWrapper>
          }
          open={showDeleteModal}
          onConfirm={deleteUserGroup}
          onClose={() => {
            setDeleteStatus(new UIStatus());
            updateShowDeleteModal(false);
          }}
          confirmText="Delete"
        />
      )}
      <PageTitle
        primaryHeader={`Access Permissions | ${pageMode} User Group`}
      />
      <UIStatusWrapper status={status}>
        <Form<UserGroup>
          onSubmit={(data: UserGroup) => handleSubmit(data)}
          setFormState={(state: FormStateValues<UserGroup>) =>
            setFormState(state)
          }
          setFormMethods={(methods: FormMethods<UserGroup>) =>
            setFormMethods(methods)
          }
        >
          <PageSection>
            {pageMode !== PageMode.create && (
              <Form.TextInput
                name="userGroupId"
                label="User Group ID"
                isReadOnly
              />
            )}
            <UserGroupFormFields
              orgId={orgId}
              isReadOnly={isReadOnly}
              userGroups={userGroups}
              roleSets={roleSets}
            />
          </PageSection>

          {pageMode === PageMode.view ? (
            <Button
              type="button"
              primary
              onClick={(e) => {
                e.preventDefault();
                navigate(
                  generatePath(Path.EditUserGroup, {
                    orgId: orgId as string,
                    userGroupId: userGroupId as string,
                  }),
                );
              }}
            >
              <Icon name="pencil" />
              Edit User Group
            </Button>
          ) : (
            <Button
              type="submit"
              loading={status.indeterminate}
              primary
              disabled={!formState?.isValid || !orgId}
            >
              {pageMode === PageMode.create ? "Create" : "Save"}
            </Button>
          )}
          {pageMode !== PageMode.create && (
            <Button
              type="button"
              basic
              inverted
              onClick={() => updateShowDeleteModal(true)}
            >
              <Icon name="trash" />
              Delete User Group
            </Button>
          )}

          <Button type="button" basic inverted onClick={() => navigate(-1)}>
            Cancel
          </Button>
        </Form>
      </UIStatusWrapper>
    </Page>
  );
};
