/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import { useCallback, useEffect, useMemo, useState } from "react";

import { Checkbox, Dropdown, Icon, Popup } from "semantic-ui-react";
import {
  Column,
  ColumnDef,
  createColumnHelper,
  Row,
  Table,
} from "@tanstack/react-table";
import { Classifier, ClassifierMode } from "data/Aletheia";
import { getKeyFromId } from "../PointClassifierUtils";
import { generateClassifierTableHeaderId } from "./ClassifierSetTableUtils";
import { matchSorter } from "match-sorter";
import Form from "components/shared/Forms/ReactHookForm";
import { useFormContext } from "react-hook-form";

/** These classifierOptions are to match the key of ClassifierFilterOption amd ClassifierRuleOption
 */
export enum ClassifierOptionsKeys {
  id = "id",
  timeseriesId = "timeseriesId",
  class = "class",
  entityProperties = "entityProperties",
  label = "label",
  unit = "unit",
}

export const renderHeaderSelectionCheckbox = (
  leafColumns: Column<Classifier>[],
  table: Table<Classifier>,
) => (
  <Dropdown item simple text="Select Fields">
    <Dropdown.Menu>
      <Dropdown.Item key={`select-all-headers}`}>
        <Checkbox
          label={"All"}
          checked={
            leafColumns.filter((c) => c.getIsVisible() === true).length ===
            leafColumns.length
          }
          onChange={(e, { checked }) => {
            if (checked) {
              leafColumns.forEach((col) => {
                col.toggleVisibility(true);
              });
            } else {
              leafColumns.forEach((col) => {
                col.toggleVisibility(false);
              });
            }
          }}
        />
      </Dropdown.Item>
      <Dropdown.Divider css={tw`bg-white`} />
      {leafColumns.map((col) => {
        return (
          <Dropdown.Item key={col.id}>
            <Checkbox
              label={col.columnDef.header ?? col.id}
              checked={col.getIsVisible()}
              onChange={col.getToggleVisibilityHandler()}
            />
          </Dropdown.Item>
        );
      })}
    </Dropdown.Menu>
  </Dropdown>
);

const SelectAllClassifiersCheckbox = ({
  table,
}: {
  table: Table<Classifier>;
}) => {
  const { setValue, getValues } = useFormContext();
  const classifierLength = getValues().classifiers.length;

  return (
    <Checkbox
      onChange={(_, { checked }) => {
        if (checked) {
          table.toggleAllRowsSelected(true);
          for (let index = 0; index < classifierLength; index++) {
            setValue(`classifiers.${index}.enabled`, true);
          }
        } else {
          table.toggleAllRowsSelected(false);
          for (let index = 0; index < classifierLength; index++) {
            setValue(`classifiers.${index}.enabled`, false);
          }
        }
      }}
      {...{
        checked: table.getIsAllRowsSelected(),
        indeterminate: table.getIsSomeRowsSelected(),
      }}
    />
  );
};

const ClassifierCheckbox = ({ row }: { row: Row<Classifier> }) => {
  const { watch } = useFormContext();
  const watchEnabled = watch(`classifiers.${row.index}.enabled`);

  // update the tanstack table library implementation of enabled behaviour
  useMemo(() => {
    row.toggleSelected(watchEnabled);
  }, [row, watchEnabled]);

  return (
    <div className="px-1">
      <Form.CheckboxInput name={`classifiers.${row.index}.enabled`} />
    </div>
  );
};
/** CLASSIFIER TABLE COLUMN DEFINITION */
const columnHelper = createColumnHelper<Classifier>();
export const defaultColumns = [
  columnHelper.accessor((row: Classifier) => row.enabled, {
    id: "select",
    header: ({ table }) => <SelectAllClassifiersCheckbox table={table} />,
    cell: ({ row }) => <ClassifierCheckbox row={row} />,
    enableHiding: false,
  }),
  columnHelper.group({
    header: "Filter By",
    columns: [
      columnHelper.accessor((row: Classifier) => row.filterBy.id.value, {
        header: "ID",
        id: generateClassifierTableHeaderId(
          ClassifierMode.filter,
          ClassifierOptionsKeys.id,
        ),
      }),
      columnHelper.accessor((row: Classifier) => row.filterBy.label.value, {
        header: "Label",
        id: generateClassifierTableHeaderId(
          ClassifierMode.filter,
          ClassifierOptionsKeys.label,
        ),
      }),
      columnHelper.accessor((row: Classifier) => row.filterBy.class?.value, {
        id: generateClassifierTableHeaderId(
          ClassifierMode.filter,
          ClassifierOptionsKeys.class,
        ),
        header: "Class",
      }),
      columnHelper.accessor((row: Classifier) => row.filterBy.unit.value, {
        id: generateClassifierTableHeaderId(
          ClassifierMode.filter,
          ClassifierOptionsKeys.unit,
        ),
        header: "UoM",
      }),
      columnHelper.accessor(() => null, {
        header: (it) => {
          // filter out the column that contains the component render header
          const leftTable = it.table
            .getLeftLeafColumns()
            .filter((c) => typeof c.columnDef.header === "string");
          return renderHeaderSelectionCheckbox(leftTable, it.table);
        },
        id: `filter-by-column-selection`,
        cell: () => null,
        enableHiding: false,
      }),
    ],
  }),
  columnHelper.group({
    header: "Apply Rules",
    columns: [
      columnHelper.accessor((row: Classifier) => row.executeRules.label.value, {
        header: "Label",
        id: generateClassifierTableHeaderId(
          ClassifierMode.execute,
          ClassifierOptionsKeys.label,
        ),
      }),
      columnHelper.accessor(
        (row: Classifier) => row.executeRules.class?.value,
        {
          header: "Class",
          id: generateClassifierTableHeaderId(
            ClassifierMode.execute,
            ClassifierOptionsKeys.class,
          ),
        },
      ),
      columnHelper.accessor((row: Classifier) => row.executeRules.unit.value, {
        header: "UoM",
        id: generateClassifierTableHeaderId(
          ClassifierMode.execute,
          ClassifierOptionsKeys.unit,
        ),
      }),
      columnHelper.accessor(() => null, {
        header: (it) => {
          // filter out the column that contains the component render header
          const rightTable = it.table
            .getRightLeafColumns()
            .filter((c) => typeof c.columnDef.header === "string");
          return renderHeaderSelectionCheckbox(rightTable, it.table);
        },
        id: `apply-rules-${ClassifierOptionsKeys.entityProperties}`,
        enableHiding: false,
      }),
    ],
  }),
];
// Render of cells logic
export const defaultColumn: Partial<ColumnDef<Classifier>> = {
  cell: function Cell({
    row: { original, index, getCanExpand, getIsExpanded, toggleExpanded },
    column: { id },
    table,
  }) {
    const mode = id.includes("filter-by")
      ? ClassifierMode.filter
      : ClassifierMode.execute;

    // We need to keep and update the state of the cell normally
    const [classSearchQuery, setClassSearchQuery] = useState("");
    const [unitSearchQuery, setUnitSearchQuery] = useState("");

    const key = getKeyFromId(id);

    const formInputName = `classifiers.${index}.${mode}.${key}.value`;
    const formInputEnabledName = `classifiers.${index}.${mode}.${key}.enabled`;

    const { watch, setValue, getValues } = useFormContext();
    const formInput = watch(formInputName);

    // toggle enable via controlled RHF
    const toggleEnabled = useCallback(() => {
      if (key === ClassifierOptionsKeys.entityProperties) {
        if (formInput && formInput.length > 0)
          setValue(formInputEnabledName, true);
        else setValue(formInputEnabledName, false);
      } else {
        if (formInput !== undefined && formInput !== null && formInput !== "") {
          setValue(formInputEnabledName, true);
        } else {
          setValue(formInputEnabledName, false);
        }
      }
    }, [formInput, formInputEnabledName, key, setValue]);

    useMemo(() => {
      toggleEnabled();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formInput, toggleEnabled]);

    // we want exact match to the id since we want only the RHS to be editable
    const input = () => {
      switch (key) {
        case "entityProperties":
          return getCanExpand() ? (
            <Form.Button
              submit={false}
              css={tw`cursor-pointer p-0`}
              onClick={() => toggleExpanded()}
              label="Properties"
              icon={getIsExpanded() ? "chevron down" : "chevron right"}
              iconPosition="right"
            />
          ) : null;
        case "id":
        case "label":
          return <Form.TextInput name={formInputName} />;
        case "class":
          return (
            <Form.SelectInput
              name={formInputName}
              search
              placeholder="Class"
              options={
                classSearchQuery
                  ? matchSorter(
                      table.options.meta?.classOptions,
                      classSearchQuery,
                      { keys: ["text"] },
                    )
                  : (table.options.meta?.classOptions ?? [])
              }
              onSearchChange={(_, { searchQuery }) => {
                setClassSearchQuery(searchQuery);
              }}
              onClear={() => {
                setClassSearchQuery("");
              }}
            />
          );

        case "unit":
          return (
            <Form.SelectInput
              name={formInputName}
              search
              placeholder="UoM"
              options={
                unitSearchQuery
                  ? matchSorter(
                      table.options.meta?.unitsOptions,
                      unitSearchQuery,
                      { keys: ["text"] },
                    )
                  : (table.options.meta?.unitsOptions ?? [])
              }
              onSearchChange={(_, { searchQuery }) =>
                setUnitSearchQuery(searchQuery)
              }
            />
          );
        default:
          return <Form.TextInput name={formInputName} />;
      }
    };

    /**
     * set the state for the tooltip condition to show up
     */
    const watchEPList =
      key === ClassifierOptionsKeys.entityProperties && watch(formInputName);
    const [isEPListEmpty, setIsEPListEmpty] = useState(true);
    useEffect(() => {
      setIsEPListEmpty(watchEPList && watchEPList.length === 0);
    }, [watchEPList]);

    const renderRadio = () => {
      if (
        original &&
        typeof original === "object" &&
        mode in original &&
        original[mode]
      ) {
        // Check if the key exists in original[mode]
        if (key in original[mode]) {
          const isChecked = getValues(formInputEnabledName);

          return (
            <span css={tw`flex items-center`}>
              <Form.CheckboxInput
                toggle
                disabled={key === ClassifierOptionsKeys.class && !formInput}
                name={formInputEnabledName}
              />
              {key === "entityProperties" && isChecked && isEPListEmpty && (
                <Popup
                  popper={<div style={{ filter: "none" }}></div>}
                  inverted
                  position="top center"
                  on={["hover", "click"]}
                  trigger={
                    <Icon circular name="exclamation circle" color="yellow" />
                  }
                  content="Enabling this field without any declared entity
                    properties will delete ALL existing entity properties from
                    points this classifier applies to."
                  size="small"
                />
              )}
            </span>
          );
        }
      }
      return <></>;
    };

    return (
      <div css={tw`flex flex-col`}>
        {renderRadio()}
        {input()}
      </div>
    );
  },
};
