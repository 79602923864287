import { HttpScheme, SenapsClientEnv } from "../data/utils";
import { some, none } from "fp-ts/lib/Option";

export const senapsEnv: SenapsClientEnv = {
  scheme:
    process.env.REACT_APP_SENAPS_USE_HTTPS === "true"
      ? HttpScheme.HTTPS
      : HttpScheme.HTTP,
  host: process.env.REACT_APP_SENAPS_HOST
    ? some(process.env.REACT_APP_SENAPS_HOST)
    : none,
  port: process.env.REACT_APP_SENAPS_PORT
    ? some(Number(process.env.REACT_APP_SENAPS_PORT))
    : none,
  headers: { "Content-Type": "application/json", Accept: "application/json" },
};
