/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { Message, MessageProps, Icon } from "semantic-ui-react";

interface DefaultMessageProps extends MessageProps {
  dismissable?: boolean;
  customDismiss?: () => void;
  dataTestId?: string;
}

const DefaultMessage: React.FC<DefaultMessageProps> = ({
  children,
  className = "",
  color,
  customDismiss,
  dismissable,
  error,
  icon,
  info,
  positive,
  warning,
  dataTestId,
  header,
  attached,
}) => {
  const [visible, setVisible] = useState(true);

  const handleDismiss = () => {
    setVisible(false);
    if (customDismiss) customDismiss();
  };

  return visible ? (
    <div role="alert" className={className}>
      <Message
        attached={attached}
        data-test-id={dataTestId}
        onDismiss={dismissable ? handleDismiss : undefined}
        info={info}
        error={error}
        positive={positive}
        icon={icon}
        warning={warning}
        color={color}
        header={header}
        className={`${dismissable ? "dismissable" : ""} ${
          header ? "message-header" : ""
        }`}
      >
        {children}
      </Message>
    </div>
  ) : null;
};

export const InfoMessage: React.FC<DefaultMessageProps> = (props) => {
  return (
    <DefaultMessage {...props} info>
      <Icon name="info" /> {props.children}
    </DefaultMessage>
  );
};

export const ErrorMessage: React.FC<DefaultMessageProps> = (props) => {
  return (
    <DefaultMessage {...props} dataTestId={"errorMessageModal"} error>
      {props.children ||
        "Could not connect to the server. Please try again later."}
    </DefaultMessage>
  );
};

interface SuccessMessageProps extends DefaultMessageProps {
  header?: string;
}

export const SuccessMessage: React.FC<SuccessMessageProps> = (props) => (
  <DefaultMessage {...props} positive icon>
    <Icon name="check" />
    <Message.Content>
      <Message.Header>{props.header}</Message.Header>
      <p>{props.content}</p>
    </Message.Content>
  </DefaultMessage>
);

export const WarningMessage: React.FC<DefaultMessageProps> = (props) => (
  <DefaultMessage {...props} warning color="yellow">
    <Icon name="warning" /> {props.content}
  </DefaultMessage>
);

export const ErrorHeaderWithContent: React.FC<DefaultMessageProps> = (
  props
) => (
  <div>
    <DefaultMessage
      {...props}
      header={() => <b>Error</b>}
      error
      attached="top"
      color="red"
    />
    {props.content}
  </div>
);
