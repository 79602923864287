/** @jsxImportSource @emotion/react */

import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import tw from "twin.macro";
import { BuildingId, ModelRootType, SiteId } from "data/brick";
import { OrgId } from "data/Enodia";
import { EnodiaOrgContext } from "App";
import { SiteOrBuildingParams } from "components/SitesAndBuildings/Common";
import { SiteOrBuildingIcon } from "components/SitesAndBuildings/SiteOrBuildingIcon";
import { Page, PageTitle } from "components/shared";
import { ModelVisualisation } from "./Visualisation";

export const ModelVisualiser: React.FunctionComponent = () => {
  const params = useParams<SiteOrBuildingParams>();
  const { orgId } = useContext(EnodiaOrgContext);
  const siteId = params.siteId as SiteId;
  const buildingId = params.buildingId as BuildingId;
  const isBuilding = buildingId !== undefined;

  const modelProps = {
    orgId: orgId as OrgId,
    siteId: siteId,
    buildingId: buildingId,
    modelType: buildingId ? ModelRootType.Building : ModelRootType.Site,
  };

  return (
    <Page>
      <div css={tw`flex flex-col h-full`}>
        <PageTitle
          primaryHeader={
            <span>
              <SiteOrBuildingIcon isBuilding={isBuilding} />
              {`View ${isBuilding ? buildingId : siteId} (BETA)`}
            </span>
          }
        />
        <div css={tw`flex-auto flex`}>
          <div
            css={tw`border border-solid border-core-grey rounded flex-1 flex flex-col overflow-hidden`}
          >
            {orgId && <ModelVisualisation {...modelProps} />}
          </div>
        </div>
      </div>
    </Page>
  );
};

export default ModelVisualiser;
