/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import CronParser from "cron-parser";
import { Checkbox, Dropdown, Grid, Input } from "semantic-ui-react";
import tw from "twin.macro";
import { DEFAULT_INPUT_WIDTHS, UIStatus } from "components/shared";
import { FrequencyTypes } from "./ApplicationSchedule";

const BASIC_PERIOD_OPTIONS = ["minute(s)", "hour(s)", "day(s)"].map((x) => ({
  key: x,
  text: x,
  value: x,
}));

const ApplicationScheduleBasicFrequency: React.FC<{
  cronString: string;
  setCronString: Function;
  disabled: boolean;
  selectedFrequency: string;
  setSelectedFrequency: Function;
  setStatus: Function;
  isReadOnly: boolean;
}> = ({
  cronString,
  setCronString,
  disabled,
  selectedFrequency,
  setStatus,
  isReadOnly,
  setSelectedFrequency,
}) => {
  const [fieldString, setFieldString] = useState<number>(5);
  const [basicPeriod, setBasicPeriod] = useState<string>("minute(s)");
  const [cronStatus, setCronStatus] = useState<UIStatus>(new UIStatus());

  useEffect(
    () => {
      if (selectedFrequency !== FrequencyTypes.basic) {
        return;
      }

      let newCronString = "";
      if (basicPeriod === "minute(s)") {
        if (fieldString < 5) {
          setCronStatus((p) =>
            p.setError("Frequency cannot be less than 5 minutes")
          );
          setStatus((p: UIStatus) =>
            p.setError("Frequency cannot be less than 5 minutes")
          );
          return;
        }
        newCronString = `0 */${fieldString} * * * *`;
      } else if (basicPeriod === "hour(s)") {
        newCronString = `0 0 */${fieldString} * * *`;
      } else if (basicPeriod === "day(s)") {
        newCronString = `0 0 */${fieldString} * *`;
      }

      if (newCronString && cronString !== newCronString) {
        try {
          CronParser.parseExpression(newCronString);
          setCronString(newCronString);
          setCronStatus((p) => p.setEmpty(true));
          setStatus((p: UIStatus) => p.setEmpty(true));
        } catch (err) {
          setCronStatus((p) => p.setError("Invalid cron expression"));
          setStatus((p: UIStatus) => p.setError("Invalid cron expression"));
        }
      }
    },
    // eslint-disable-next-line
    [fieldString, basicPeriod, selectedFrequency, cronString]
  );

  return (
    <Grid>
      <Grid.Column {...DEFAULT_INPUT_WIDTHS.label} verticalAlign="middle">
        <Checkbox
          css={tw`ml-10`}
          radio
          value={FrequencyTypes.basic}
          readOnly={isReadOnly}
          checked={selectedFrequency === FrequencyTypes.basic}
          onChange={(e, d) => {
            setSelectedFrequency(FrequencyTypes.basic);
          }}
          label="Basic"
        />
      </Grid.Column>
      <Grid.Column {...DEFAULT_INPUT_WIDTHS.input}>
        <Input
          fluid
          disabled={disabled}
          error={cronStatus.error !== undefined}
          label={
            <Dropdown
              options={BASIC_PERIOD_OPTIONS}
              value={basicPeriod}
              disabled={disabled}
              onChange={(e, d) => {
                setBasicPeriod(d.value as string);
              }}
            />
          }
          labelPosition="right"
          value={fieldString}
          onChange={(e, d) => {
            let n = Number(d.value);
            if (n !== undefined) {
              n = n > 0 ? n : 1;
              setFieldString(n);
            }
          }}
          type="number"
        />
      </Grid.Column>
    </Grid>
  );
};

export default ApplicationScheduleBasicFrequency;
