import { DecodedResponse } from "./http";
import { httpGet, httpPost, httpGetToBlob, httpPostRaw } from "data/httpUtil";
import { HttpScheme, ClientEnv } from "./utils";
import * as O from "fp-ts/lib/Option";
import { none, some } from "fp-ts/lib/Option";
import { env } from "reducers/env";

export enum ModelState {
  Pending = "PENDING",
  Started = "STARTED",
  Success = "SUCCESS",
  Failure = "FAILURE",
  Revoked = "REVOKED",
  Retry = "RETRY",
  Ignored = "IGNORED",
}

export type JobStatusDTO = {
  id: string;
  state: ModelState;
  submitted_at: Date;
  submitted_by: string;
};

type RootWebDTO = {
  version: string;
};

const modelGenerationEnv: ClientEnv = {
  ...env,
  scheme:
    process.env.REACT_APP_BSL_USE_HTTPS === "true"
      ? HttpScheme.HTTPS
      : HttpScheme.HTTP,
  host: process.env.REACT_APP_MODEL_CREATION_HOST
    ? some(process.env.REACT_APP_MODEL_CREATION_HOST)
    : none,
  port: process.env.REACT_APP_MODEL_CREATION_PORT
    ? some(Number(process.env.REACT_APP_MODEL_CREATION_PORT))
    : none,
  baseUrl: process.env.REACT_APP_MODEL_CREATION_BASE_URL ?? "",
};

// Omit the accept-type and let the browser automatially assign the type, else the boundary will not
// set for the uploaded file
const uploadHeader: ClientEnv = {
  ...modelGenerationEnv,
  headers: { Accept: "application/json" },
};

type ModelGenerationApi = {
  getRootAPI: () => Promise<RootWebDTO>;
  postJob: (formData: FormData) => Promise<JobStatusDTO>;
  getJobStatus: (jobStatusId: string) => Promise<JobStatusDTO>;
  getModelGenPdfBlob: (jobStatusId: string) => Promise<DecodedResponse<Blob>>;
  cancelJob: (jobStatusId: string) => Promise<void>;
};

const mkModelCreationApi = (env: ClientEnv): ModelGenerationApi => ({
  // GET /https://dataclearinghouse.org/api/modelcreation/v1
  getRootAPI: () =>
    httpGet(O.none)(env)(null)("/").then((r) => r as RootWebDTO),

  // POST /jobs - submitting the zip file
  postJob: (formData) =>
    httpPostRaw(formData)(O.none)(uploadHeader)(formData)(`/jobs`).then(
      (r) => r as JobStatusDTO
    ),

  // GET /jobs/{id} - get model creation blob
  getJobStatus: (jobStatusId) =>
    httpGet(O.none)(env)(null)(`/jobs/${jobStatusId}`).then(
      (r) => r as JobStatusDTO
    ),

  // GET /jobs/{id}/file - return generated zip file
  getModelGenPdfBlob: (jobStatusId) =>
    httpGetToBlob(O.none)(env)(null)(`/jobs/${jobStatusId}/file`).then(
      (r) => r
    ),

  // POST /jobs/{id}/cancel
  cancelJob: (jobStatusId) =>
    httpPost(null)(O.none)(env)(null)(`/jobs/${jobStatusId}/cancel`).then(
      (_) => undefined
    ),
});

export const modelGenerationApi = mkModelCreationApi(modelGenerationEnv);
