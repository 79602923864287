import React from "react";
import {
  Controller,
  FieldValues,
  RegisterOptions,
  useFormContext,
} from "react-hook-form";
import { FileInput, FileInputProps } from "components/shared";
import { FormInputProps } from "../ReactHookForm";

export type FormFileInputProps<TFieldValues extends FieldValues> =
  FormInputProps<TFieldValues> & FileInputProps;

export const FormFileInput = <TFieldValues extends FieldValues>(
  props: FormFileInputProps<TFieldValues>
) => {
  let rules = props.rules as Omit<
    RegisterOptions<FieldValues>,
    "setValueAs" | "disabled" | "valueAsNumber" | "valueAsDate"
  >;
  if (props.required) rules = { ...rules, required: "Required" };

  const { control } = useFormContext();
  return (
    <Controller
      name={props.name}
      control={control}
      rules={rules}
      render={({ field: { value, onChange } }) => {
        return <FileInput value={value} onChange={onChange} {...props} />;
      }}
    />
  );
};
