import { createContext, useContext } from "react";
import {
  EntityPropertyDefinition,
  EntityPropertySummary,
} from "./EntityPropertiesApi";

export type EntityPropertiesContextType = {
  entityPropertyDefinitions: Map<string, EntityPropertyDefinition>;
  setEntityPropertyDefinitions: (
    _: Map<string, EntityPropertyDefinition>
  ) => void;
  entityPropertiesList: Array<EntityPropertySummary>;
  setEntityPropertiesList: (_: Array<EntityPropertySummary>) => void;
};

export const EntityPropertiesContext =
  createContext<EntityPropertiesContextType>({
    entityPropertyDefinitions: new Map(),
    setEntityPropertyDefinitions: (
      _: Map<string, EntityPropertyDefinition>
    ) => {},
    entityPropertiesList: [],
    setEntityPropertiesList: (_: Array<EntityPropertySummary>) => {},
  });

export const useEntityPropertiesContext = () =>
  useContext(EntityPropertiesContext);
