import React, { useCallback, useEffect, useState } from "react";
import { FieldValues } from "react-hook-form";
import { OrgId } from "data/Enodia";
import { SiteBuildingReference } from "data/QueryApi/queryTypes";
import { UIStatus, UIStatusWrapper } from "components/shared";
import Form, {
  FormMethods,
  FormStateValues,
} from "components/shared/Forms/ReactHookForm";
import { searchPoints } from "../BuildQuery";
import { Result } from "../result-utils";
import { LocationsSelectInput } from "./LocationsSelectInput";
import { EquipmentSelectInput } from "./EquipmentSelectInput";
import { PointsSelectInput } from "./PointsSelectInput";
import { ModelsSelectInput } from "../../shared/InputFields/Fields/Domain/ModelsSelectInput";

export interface SearchParamsValues extends FieldValues {
  models: string[];
  locations: string[];
  equipment: string[];
  points: string[];
  includeAllSites: boolean;
  includeAllBuildings: boolean;
  includeLocationsSubclasses: boolean;
  includeEquipmentSubclasses: boolean;
  includePointsSubclasses: boolean;
}

type Props = {
  orgId?: OrgId;
  setQueryStatus: React.Dispatch<React.SetStateAction<UIStatus>>;
  setShowResults: (_: boolean) => void;
  setQueryResults: (_: Set<Result>) => void;
};

export const SearchForm = (props: Props) => {
  const { orgId, setQueryStatus, setShowResults, setQueryResults } = props;
  const [uiStatus, setUiStatus] = useState(new UIStatus());
  const [formState, setFormState] =
    useState<FormStateValues<SearchParamsValues>>();
  const [formMethods, setFormMethods] =
    useState<FormMethods<SearchParamsValues>>();
  const [modelsList, setModelsList] = useState<SiteBuildingReference[]>([]);

  const handleSubmit = (formValues: SearchParamsValues): Promise<void> => {
    setQueryStatus((prevState) => prevState.setIndeterminate(true));

    const resultsPromise: Promise<Set<Result>> = searchPoints(
      formValues,
      modelsList
    );

    return resultsPromise.then(
      (r) => {
        setQueryStatus((prevState) => prevState.setIndeterminate(false));
        setShowResults(true);
        setQueryResults(r);
      },
      (e) =>
        setQueryStatus((prevState) =>
          prevState.setError(
            e.message || "An error occurred when running the search"
          )
        )
    );
  };

  const clearSearch = useCallback(() => {
    formMethods?.reset();
    setQueryResults(new Set([]));
    setShowResults(false);
  }, [formMethods, setQueryResults, setShowResults]);

  useEffect(() => {
    clearSearch();
  }, [clearSearch, orgId]);

  const setError = (error: string) => {
    setUiStatus((prev) => prev.setError(error));
  };

  return (
    <UIStatusWrapper status={uiStatus}>
      <Form
        onSubmit={(data: SearchParamsValues) => handleSubmit(data)}
        setFormState={(state) => setFormState(state)}
        setFormMethods={(methods) => setFormMethods(methods)}
      >
        <ModelsSelectInput
          orgId={orgId}
          setModelsList={setModelsList}
          setError={setError}
        />
        <LocationsSelectInput setError={setError} />
        <EquipmentSelectInput setError={setError} />
        <PointsSelectInput setError={setError} />
        <div style={{ marginTop: "2rem" }}>
          <Form.Button
            submit
            label="Search DCH"
            disabled={!formState?.isDirty || !formState?.isValid}
          />
          <Form.Button
            submit={false}
            label="Clear Search"
            disabled={!formState?.isDirty}
            onClick={() => clearSearch()}
          />
        </div>
      </Form>
    </UIStatusWrapper>
  );
};
