import React, { useCallback, useEffect, useMemo, useState } from "react";
import { RJSFSchema } from "@rjsf/utils";
import { Divider } from "semantic-ui-react";
import { ApplicationConfigurationActionBar } from "./ApplicationConfigurationActionBar";
import { ConfigType, Status } from "./ApplicationConfigurationConstants";
import { ApplicationConfigurationJsonDocument } from "./ApplicationConfigurationJsonDocument";
import { ApplicationConfigurationPlaintextDocument } from "./ApplicationConfigurationPlaintextDocument";
import {
  validateConfiguration,
  validateJSON,
} from "./ApplicationConfigurationValidation";

export const ApplicationConfigurationDocument: React.FC<{
  activeDoc: ConfigType;
  applicationId: string;
  setDocumentStatus: React.Dispatch<React.SetStateAction<Status>>;
  updateConfigList: (_?: string) => void;
}> = ({ applicationId, setDocumentStatus, activeDoc, updateConfigList }) => {
  const documentId = activeDoc.metadata.documentId;
  const [showRaw, setShowRaw] = useState<boolean>(false);
  const [validInput, setValidInput] = useState<boolean>(true);
  const [initialTextStates, setInitialTextStates] = useState<
    Record<string, string | undefined>
  >({});

  /** Helpers to update setstate actions */
  const updatePlainText = useCallback(
    (newText?: string) => {
      updateConfigList(newText);
    },
    [updateConfigList]
  );

  const updateInitialState = (documentId: string, plaintext?: string) => {
    setInitialTextStates((prev) => ({
      ...prev,
      [documentId]: plaintext,
    }));
  };

  /**
   * On first load,save the initial state of the form info
   */
  useEffect(() => {
    if (!initialTextStates[documentId]) {
      updateInitialState(documentId, activeDoc.plaintext);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId]);

  useEffect(() => {
    if (activeDoc.metadata.type) {
      setValidInput(
        validateConfiguration(
          activeDoc.plaintext,
          activeDoc.metadata.type.toLowerCase()
        )
      );
      // condition that there is a valid schema detected, but the metadata.type is not declared
      // we can assume that it is a JSON, hence, we validate the json
    } else if (activeDoc.schema && activeDoc.plaintext) {
      setValidInput(validateJSON(activeDoc.plaintext));

      // bypass the validation, assuming that the plaintext type is unknown/ invalidateable
    } else {
      setValidInput(true);
    }
  }, [activeDoc.metadata.type, activeDoc.plaintext, activeDoc.schema]);

  useEffect(() => {
    initialTextStates[documentId] === activeDoc.plaintext
      ? setDocumentStatus(Status.Synced)
      : setDocumentStatus(Status.Modified);
  }, [activeDoc.plaintext, documentId, initialTextStates, setDocumentStatus]);

  const renderPlaintextDocument = useMemo(
    () => (
      <ApplicationConfigurationPlaintextDocument
        plaintext={activeDoc.plaintext}
        setPlaintext={(value: string | undefined) => updatePlainText(value)}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeDoc.plaintext]
  );

  const renderDocument = () => {
    if (!showRaw && activeDoc.schema !== undefined) {
      return (
        <ApplicationConfigurationJsonDocument
          schema={activeDoc.schema as RJSFSchema}
          plaintext={activeDoc.plaintext}
          setPlaintext={(value: string | undefined) => updatePlainText(value)}
          setValidInput={setValidInput}
          isClean={initialTextStates[documentId] === activeDoc.plaintext}
          applicationId={applicationId}
          updateInitialState={updateInitialState}
          documentId={documentId}
          setDocumentStatus={setDocumentStatus}
        />
      );
    }
    return renderPlaintextDocument;
  };

  return (
    <React.Fragment>
      <ApplicationConfigurationActionBar
        initialTextState={initialTextStates[documentId]}
        validInput={validInput}
        activeDoc={activeDoc}
        updatePlainText={updatePlainText}
        showRaw={showRaw}
        setShowRaw={setShowRaw}
      />
      <Divider />
      {renderDocument()}
      <Divider />
    </React.Fragment>
  );
};

export default ApplicationConfigurationDocument;
