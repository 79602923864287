/** @jsxImportSource @emotion/react */

import React from "react";
import tw from "twin.macro";
import { Grid, SemanticVERTICALALIGNMENTS } from "semantic-ui-react";
import {
  DEFAULT_INPUT_WIDTHS,
  FULL_SCREEN_WIDTHS,
  InputWidths,
  LabelProps,
  InputLabel,
} from "components/shared";

export class LabelledInputLayoutProps {
  inputWidths?: InputWidths;
  verticalLayout?: boolean;
  verticalAlign?: SemanticVERTICALALIGNMENTS;
}

export type LabelledInputProps = {
  input: React.ReactNode;
} & LabelledInputLayoutProps &
  LabelProps;

export const LabelledInput = (props: LabelledInputProps) => {
  const labelColumnWidths = props.verticalLayout
    ? { ...FULL_SCREEN_WIDTHS.label }
    : props?.inputWidths
    ? { ...props.inputWidths.label }
    : { ...DEFAULT_INPUT_WIDTHS.label };

  const inputColumnWidths = props?.inputWidths
    ? { ...props.inputWidths.input }
    : { ...DEFAULT_INPUT_WIDTHS.input };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column
          {...labelColumnWidths}
          verticalAlign={props.verticalAlign || "top"}
          textAlign="left"
        >
          <div css={props.verticalLayout ? null : tw`mt-2`}>
            <InputLabel {...props} />
          </div>
          {props.verticalLayout && (
            <div
              css={props.verticalLayout && !props.isReadOnly ? tw`mt-2` : null}
            >
              {props.input}
            </div>
          )}
        </Grid.Column>
        {!props.verticalLayout && (
          <Grid.Column
            {...inputColumnWidths}
            textAlign="left"
            verticalAlign="middle"
          >
            {props.input}
          </Grid.Column>
        )}
      </Grid.Row>
    </Grid>
  );
};
