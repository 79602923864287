/** @jsxImportSource @emotion/react */

import React from "react";
import tw from "twin.macro";
import { Link } from "react-router-dom";
import { DropdownItemProps } from "semantic-ui-react";
import { FieldArrayWithId } from "react-hook-form";
import { StreamId } from "data/senaps";
import { PointDataModal } from "components/Search/PointDataModal";
import {
  AnyNodeName,
  SubLevelNodeName,
} from "components/SitesAndBuildings/Common";
import { ModelProps } from "../ModelUtils";
import { ModelFormFields } from "./SiteOrBuildingModelForm";

export type ModelEntityProps = {
  isReadOnly?: boolean;
} & ModelProps;

export type ModelEntityCollectionProps = {
  index: number;
  isReadOnly?: boolean;
  deleteNode: (index: number) => void;
} & ModelEntityProps;

export const getCollectionName = (name: AnyNodeName) => {
  switch (name) {
    case "wing":
    case "floor":
    case "room":
    case "zone":
    case "point":
      return `${name}s`;
    case "equipment":
      return name;
    case "model":
      return "modelDetails";
  }
};

export const renderEntityListLabel = (isReadOnly: boolean) => {
  return isReadOnly
    ? (item: DropdownItemProps) => ({
        content: (
          <Link
            css={tw`cursor-pointer underline pointer-events-auto block`}
            to={`?id=${item.value}`}
            relative="path"
          >
            {item.text}
          </Link>
        ),
        as: "div",
      })
    : undefined;
};

export const renderStreamListLabel = (isReadOnly: boolean) => {
  return isReadOnly
    ? (item: DropdownItemProps, index: number) => ({
        content: (
          <PointDataModal
            id={item.value as StreamId}
            buttonLabel={item.value as string}
            index={index}
            isLink
          />
        ),
        as: "div",
      })
    : undefined;
};

export const getFieldArray = (
  fieldArrays: Omit<ModelFormFields, "modelDetails">,
  fieldName: SubLevelNodeName
) => {
  switch (fieldName) {
    case "wing":
      return fieldArrays.wings as FieldArrayWithId<
        ModelFormFields,
        "wings",
        "id"
      >[];
    case "floor":
      return fieldArrays.floors as FieldArrayWithId<
        ModelFormFields,
        "floors",
        "id"
      >[];
    case "room":
      return fieldArrays.rooms as FieldArrayWithId<
        ModelFormFields,
        "rooms",
        "id"
      >[];
    case "zone":
      return fieldArrays.zones as FieldArrayWithId<
        ModelFormFields,
        "zones",
        "id"
      >[];
    case "equipment":
      return fieldArrays.equipment as FieldArrayWithId<
        ModelFormFields,
        "equipment",
        "id"
      >[];
    case "point":
      return fieldArrays.points as FieldArrayWithId<
        ModelFormFields,
        "points",
        "id"
      >[];
  }
};
