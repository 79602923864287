/** @jsxImportSource @emotion/react */
import React, { useContext, useState } from "react";
import { Button, Icon, SemanticCOLORS } from "semantic-ui-react";
import tw from "twin.macro";
import { AuthContext, EnodiaOrgContext } from "App";
import OrgContextHeader from "context/OrgContextHeader";

const UserEmail: React.FC<{ userEmail: string | undefined }> = (props) => (
  <div data-type="light" css={tw`truncate`}>
    {props.userEmail}
  </div>
);

export const PageHeader = () => {
  const { setOrgList } = useContext(EnodiaOrgContext);
  const authContext = useContext(AuthContext);

  const handleLogoutClicked = (e: any) => {
    e.preventDefault();
    setOrgList(undefined);
    authContext.logout();
  };

  const [exitColour, setExitColour] = useState<SemanticCOLORS>("grey");

  const renderExit = () => {
    return (
      <Button
        css={tw`min-w-max`}
        secondary
        id="logout"
        onClick={handleLogoutClicked}
        onMouseOver={() => setExitColour("red")}
        onMouseLeave={() => setExitColour("grey")}
      >
        <Icon name="log out" flipped="horizontally" color={exitColour} />
        Sign Out
      </Button>
    );
  };

  return (
    <div css={tw`bg-black p-4 flex items-center gap-4`}>
      <div style={{ width: "max(50%,500px)" }}>
        <OrgContextHeader />
      </div>
      <div
        css={tw`flex gap-4 flex-wrap justify-end ml-auto`}
        style={{ width: "max(50%,500px)" }}
      >
        <div css={tw`text-right`}>
          You are logged in as{" "}
          <UserEmail userEmail={authContext.state?.userInfo?.email} />
        </div>
        <div>{renderExit()}</div>
      </div>
    </div>
  );
};
