import { RJSFSchema } from "@rjsf/utils";
import { SemanticCOLORS } from "semantic-ui-react";
import { MetadataConfig } from "data/Metis";
import { StateColours } from "components/shared";

export enum Status {
  Synced = "SYNCED",
  Modified = "UNSAVED",
  Error = "ERROR",
}

export const StatusColor: Record<Status, SemanticCOLORS> = {
  SYNCED: StateColours.Success,
  UNSAVED: StateColours.Waiting,
  ERROR: StateColours.Failure,
};

export const JSON_STRING_SPACING = 4;

export const mkConfigType = (metadata: MetadataConfig) => {
  return {
    metadata,
    documentStatus: Status.Synced,
    plaintext: "",
    schema: undefined,
  };
};

export type ConfigType = {
  metadata: MetadataConfig;
  documentStatus: Status;
  plaintext: string | undefined;
  schema?: RJSFSchema;
};
