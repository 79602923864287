import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BuildingId, ModelRootType, SiteId } from "data/brick";
import { OrgId } from "data/Enodia";
import { ApiError } from "data/http";
import { masonApi, ValidationStage } from "data/Mason";
import { DchModal, UIStatus, UIStatusWrapper } from "components/shared";
import Form, {
  FormMethods,
  FormStateValues,
} from "components/shared/Forms/ReactHookForm";
import { UploadModelForm } from "./UploadModelForm";
import { ValidationReportProps } from "./ValidationReportModal";

const UPLOAD_MODEL_FORM_ID = "UploadModelForm";

type Props = {
  orgId: OrgId;
  siteId: SiteId;
  buildingId?: BuildingId;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  modelType: ModelRootType;
  existingModel?: boolean;
  refetchModel?: () => void;
  setValidationReportProps: (_: ValidationReportProps) => void;
};

type ModelUpload = {
  orgId: OrgId;
  siteId: SiteId;
  buildingId?: BuildingId;
  file: File;
};

export const ModelUploadModal: React.FunctionComponent<Props> = (props) => {
  const {
    orgId,
    siteId,
    buildingId,
    showModal,
    setShowModal,
    modelType,
    existingModel = false,
    refetchModel,
    setValidationReportProps,
  } = props;
  const [uploadStatus, setUploadStatus] = useState(new UIStatus());
  const navigate = useNavigate();
  const [formState, setFormState] = useState<FormStateValues<ModelUpload>>();
  const [formMethods, setFormMethods] = useState<FormMethods<ModelUpload>>();

  const handleError = (
    validationStage: ValidationStage,
    errorMessage?: string,
  ) => {
    setUploadStatus((p) =>
      p.setError(
        `An error occurred while ${validationStage}ing the model: ${
          errorMessage ?? "Unknown Error"
        }`,
      ),
    );
  };

  const upload = (model: string) => {
    const siteParam = {
      orgId: orgId,
      siteId: siteId,
    };
    const buildingParam = {
      ...siteParam,
      buildingId: buildingId as BuildingId,
    };

    (buildingId
      ? masonApi.putDraftBuildingRDF(buildingParam)(model)
      : masonApi.putDraftSiteRDF(siteParam)(model)
    )
      .then(
        (putDraftResponse) => {
          if (putDraftResponse.isSuccessful) {
            masonApi
              .publishSite(siteParam)
              .then(
                (publishSiteResponse) => {
                  if (
                    publishSiteResponse.isSuccessful &&
                    !publishSiteResponse.validationReport
                  )
                    if (putDraftResponse.validationReport) {
                      //show the validation report modal as successful but with warnings
                      setValidationReportProps({
                        isError: false,
                        validationReport: putDraftResponse.validationReport,
                        validationStage: "upload",
                        modelId: buildingId ?? siteId,
                      });
                      setUploadStatus((prev) => prev.setIndeterminate(false));
                      setShowModal(false);
                    } else navigate(0);
                  //refresh the page if it succeeded with no validation reports to show
                  else if (publishSiteResponse.validationReport) {
                    //want to show the validation report modal, and pass through isSuccessful as an error or not
                    setValidationReportProps({
                      isError: !publishSiteResponse.isSuccessful,
                      validationReport: publishSiteResponse.validationReport,
                      validationStage: "publish",
                      modelId: buildingId ?? siteId,
                    });
                    setUploadStatus((prev) => prev.setIndeterminate(false));
                    setShowModal(false);
                  } else
                    handleError("publish", publishSiteResponse.errorMessage);
                },
                (error) => handleError("publish", error.message),
              )
              .catch((error) => handleError("publish", error.message));
          } else if (putDraftResponse.validationReport) {
            setValidationReportProps({
              isError: !putDraftResponse.isSuccessful,
              validationReport: putDraftResponse.validationReport,
              validationStage: "publish",
              modelId: buildingId ?? siteId,
            });
            setUploadStatus((prev) => prev.setIndeterminate(false));
            setShowModal(false);
          } else {
            handleError("upload", putDraftResponse.errorMessage);
          }
        },
        (error) => handleError("upload", error.message),
      )
      .catch((error: ApiError) => handleError("upload", error.message));
  };

  const handleSubmit = (values: ModelUpload) => {
    if (values.file) {
      values.file.text().then(
        (fileStr: string) => {
          setUploadStatus((prev) => prev.setIndeterminate(true));
          upload(fileStr);
        },
        () => setUploadStatus((p) => p.setError("Unable to read the file.")),
      );
    } else setUploadStatus((p) => p.setError("Please upload a file."));
  };

  return (
    <DchModal
      header={
        existingModel
          ? `Are you sure you want to overwrite the current ${modelType} model?`
          : `Upload ${modelType} Model`
      }
      content={
        <Form<ModelUpload>
          formId={UPLOAD_MODEL_FORM_ID}
          onSubmit={(data: ModelUpload) => handleSubmit(data)}
          defaultValues={{ orgId, siteId, buildingId }}
          setFormState={(state) => setFormState(state)}
          setFormMethods={(methods) => setFormMethods(methods)}
        >
          <UIStatusWrapper status={uploadStatus} onErrorDismiss={refetchModel}>
            <UploadModelForm modelType={modelType} />{" "}
          </UIStatusWrapper>
        </Form>
      }
      open={showModal}
      submitFormId={UPLOAD_MODEL_FORM_ID}
      onClose={() => {
        formMethods?.reset();
        if (!uploadStatus.indeterminate) {
          setUploadStatus((p) => p.setIndeterminate(false));
          setShowModal(false);
        }
      }}
      disableConfirm={!formState?.isValid}
      confirmLoading={uploadStatus.indeterminate}
      confirmText={existingModel ? "Upload & Overwrite" : "Upload"}
    />
  );
};
