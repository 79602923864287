/** @jsxImportSource @emotion/react */

import * as React from "react";
import { FieldValues } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Grid } from "semantic-ui-react";
import {
  DataSourceCompositeId,
  DataSourceId,
  DataSourceResponse,
  DataSourceUid,
  DataSourceUpdateRequest,
  aletheiaApi,
} from "data/Aletheia";
import { ApiError } from "data/http";
import { genStrongPassword } from "data/password";
import { isValidName } from "data/validation";
import { EnodiaOrgContext } from "App";
import { Path } from "Routes";
import OrgContextModal from "context/OrgContextModal";
import {
  Page,
  PageSection,
  PageTitle,
  UIStatus,
  UIStatusWrapper,
  redirectMessage,
} from "components/shared";
import Form, {
  FormMethods,
  FormStateValues,
} from "components/shared/Forms/ReactHookForm";
import { createCompositeId } from "./DataSourceUtils";

export interface UpdateDataSourceFormValues
  extends DataSourceUpdateRequest,
    FieldValues {
  id: string;
  name?: string;
  description?: string;
  enabled: boolean;
  gatewayUsername: string;
  gatewayPassword?: string;
  gatewayConfirmPassword?: string;
}

type Params = { orgId: string; id: DataSourceId };

const EditDataSource: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { orgId } = React.useContext(EnodiaOrgContext);
  const params = useParams<Params>();

  const [dataSource, setDataSource] = React.useState<
    DataSourceResponse | undefined
  >();
  const [confirmationModal, setConfirmationModal] = React.useState(false);
  const [uiStatus, setUIStatus] = React.useState(new UIStatus());

  const [formState, setFormState] =
    React.useState<FormStateValues<UpdateDataSourceFormValues>>();
  const [formMethods, setFormMethods] =
    React.useState<FormMethods<UpdateDataSourceFormValues>>();

  const updateDataSource = (formData: UpdateDataSourceFormValues) => {
    if (params.id && dataSource) {
      const updateRequest = {
        name: formData.name ?? undefined,
        description: formData.description ?? undefined,
        enabled: formData.enabled,
        transportConfig: dataSource?.transportConfig,
        decoderConfig: dataSource?.decoderConfig,
      };

      setUIStatus((prevState) => prevState.setIndeterminate(true));
      aletheiaApi
        .putDatasource(dataSource.uid as DataSourceUid)(updateRequest)
        .then(() => {
          setUIStatus((prevState) => prevState.setIndeterminate(false));
          navigate(`/datasource/${dataSource.organisationId}/${params.id}`);
        })
        .catch((e: ApiError) => {
          setUIStatus((prevState) => prevState.setError(e.message));
        });
    }
  };

  React.useEffect(() => {
    if (state) setDataSource(state as DataSourceResponse);
    else if (params.id && orgId && !state) {
      setUIStatus((prevState) => prevState.setIndeterminate(true));
      aletheiaApi
        .getDatasource(
          createCompositeId(orgId, params.id) as DataSourceCompositeId
        )
        .then((ds) => {
          setDataSource(ds);
          setUIStatus((prevState) => prevState.setIndeterminate(false));
        })
        .catch((e: ApiError) => {
          setUIStatus((prevState) =>
            prevState.setError(
              e.message ??
                "Error fetching data source: No data source with requested ID found"
            )
          );
        });
    }
  }, [params.id, orgId, state]);

  function handleGeneratePassword() {
    const newPassword = genStrongPassword(10, 10); // Replace 10 with your desired password length
    // Set the values of both fields
    formMethods?.setValue("gatewayPassword", newPassword, {
      shouldDirty: true,
    });
    formMethods?.setValue("gatewayConfirmPassword", newPassword, {
      shouldDirty: true,
    });
    formMethods?.trigger();
  }

  return (
    <Page>
      <PageTitle primaryHeader="Edit a Data Source" />
      <OrgContextModal
        open={confirmationModal}
        openCondition={params.orgId !== orgId}
        onConfirm={() => {
          navigate(Path.DataSources);
        }}
        modalMessage={redirectMessage}
        setModalState={setConfirmationModal}
      />
      <UIStatusWrapper status={uiStatus}>
        {dataSource && (
          <Form
            onSubmit={(ds: UpdateDataSourceFormValues) => updateDataSource(ds)}
            formId="CreateDataSource"
            setFormState={(formState) => setFormState(formState)}
            setFormMethods={(fm) => setFormMethods(fm)}
            defaultValues={{
              id: dataSource.id,
              name: dataSource.name,
              description: dataSource.description,
              enabled: dataSource.enabled,
              gatewayUsername: dataSource.transportConfig.userName,
              gatewayPassword: "",
              gatewayConfirmPassword: "",
            }}
          >
            <PageSection header="Data source details">
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <Form.TextInput
                      name="id"
                      label="ID"
                      value={dataSource.id}
                      isReadOnly
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Form.TextInput
                      name="name"
                      label="Name"
                      required
                      rules={{ validate: isValidName }}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Form.TextInput name="description" label="Description" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Form.CheckboxInput
                      name="enabled"
                      label="Enabled?"
                      toggle
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </PageSection>
            <PageSection header="Gateway authentication">
              <Grid>
                <Grid.Row>
                  <Grid.Column width={13}>
                    <Form.TextInput
                      name="gatewayUsername"
                      label="Username"
                      value={`${formMethods?.getValues(
                        "gatewayUsername"
                      )}@dataclearinghouse.org`}
                      isReadOnly
                    />
                  </Grid.Column>
                  <Grid.Column width={3} />
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={13}>
                    <Form.TextInput
                      name="gatewayPassword"
                      type="password"
                      autoComplete="off"
                      label="Password"
                    />
                  </Grid.Column>

                  <Grid.Column width={3}>
                    <Button
                      type="button"
                      primary
                      onClick={handleGeneratePassword}
                    >
                      Generate password
                    </Button>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={13}>
                    <Form.TextInput
                      name="gatewayConfirmPassword"
                      type="password"
                      autoComplete="off"
                      label="Confirm Password"
                      rules={{
                        validate: (s: string) => {
                          return s !== formMethods?.getValues("gatewayPassword")
                            ? `Your password and confirmation password do not match.`
                            : undefined;
                        },
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </PageSection>
            <Button
              primary
              type="submit"
              loading={uiStatus.indeterminate}
              disabled={
                uiStatus.indeterminate ||
                !formState?.isValid ||
                (formState?.isValid && !formState?.isDirty)
              }
            >
              Update Data Source
            </Button>
            <Button
              inverted
              basic
              type="button"
              onClick={() => navigate(`/datasource/${orgId}/${params.id}`)}
            >
              Cancel
            </Button>
          </Form>
        )}
      </UIStatusWrapper>
    </Page>
  );
};

export default EditDataSource;
