/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Icon, Input } from "semantic-ui-react";
import { enodiaApi } from "data/Enodia/enodiaApi";
import { InvitationId, accountApi } from "data/senaps";
import {
  validatePasswordStrength,
  validatePasswordsMatch,
} from "data/validation";
import {
  LabelledInput,
  TERMS_CONDITIONS_URL,
  UIStatus,
  UIStatusWrapper,
} from "../../shared";
import Form, {
  FormMethods,
  FormStateValues,
} from "../../shared/Forms/ReactHookForm";
import { AuthContext } from "App";

type SignUpParams = {
  invitation_id: InvitationId;
};

class SignUpFormValues {
  email = "";
  password = "";
  confirmPassword = "";
  acceptedTerms = false;
}

export const SignUpForm = ({
  handleInvalidToken,
}: {
  handleInvalidToken: () => void;
}) => {
  const authContext = useContext(AuthContext);
  const [status, setStatus] = useState(new UIStatus());
  const [enteredCaptcha, setEnteredCaptcha] = useState("");

  const params = useParams<SignUpParams>();
  // const navigate = useNavigate();

  const [formState, setFormState] =
    useState<FormStateValues<SignUpFormValues>>();
  const [formMethods, setFormMethods] =
    useState<FormMethods<SignUpFormValues>>();

  const handleSubmit = (formValues: SignUpFormValues) => {
    const { email, password } = formValues;
    setStatus((prevState) => prevState.setIndeterminate(true));
    accountApi.postSignup({ userid: email, password: password }).then(
      (_) => {
        authContext.login();
      },
      (err) =>
        setStatus((prevState) =>
          prevState.setError(
            err.message === "Username already taken."
              ? "This username already exists, please sign in instead."
              : "An error occured during sign up. Please try again.",
          ),
        ),
    );
  };

  useEffect(() => {
    if (params.invitation_id) {
      if (formMethods) {
        setStatus((prev) => prev.setIndeterminate(true));
        enodiaApi
          .getInvitation(params.invitation_id)
          .then((r) => {
            formMethods?.reset({ email: r.recipient });
            setStatus((prev) => prev.setIndeterminate(false));
          })
          .catch((e) => {
            handleInvalidToken();
          });
      }
    } else {
      handleInvalidToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formMethods, params.invitation_id]);

  const passwordFieldValidation = (s: string) => {
    const strengthValidation = validatePasswordStrength(s);
    if (strengthValidation) return strengthValidation;
    const passwordMatchResult = validatePasswordsMatch(
      s,
      formMethods?.getValues("confirmPassword"),
    );
    if (passwordMatchResult) {
      if (!formState?.errors["confirmPassword"])
        formMethods?.setError("confirmPassword", {
          message: passwordMatchResult,
        });
    } else formMethods?.clearErrors("confirmPassword");
    return undefined;
  };

  return (
    <UIStatusWrapper status={status}>
      <Form<SignUpFormValues>
        onSubmit={(data) => handleSubmit(data)}
        setFormState={(state) => setFormState(state)}
        setFormMethods={(methods) => setFormMethods(methods)}
      >
        <div css={tw`mb-6`}>
          <Form.TextInput
            name="email"
            label="Email"
            verticalLayout
            isReadOnly
          />
          <Form.TextInput
            name="password"
            label="Password"
            placeholder="Enter password"
            rules={{
              required: "Password is required",
              validate: passwordFieldValidation,
            }}
            type="password"
            autoComplete="current-password"
            verticalLayout
          />
          <Form.TextInput
            name="confirmPassword"
            label="Confirm Password"
            placeholder="Re-enter password"
            rules={{
              required: "Password is required",
              validate: (s: string) =>
                validatePasswordsMatch(s, formMethods?.getValues("password")),
            }}
            type="password"
            verticalLayout
          />
          <LabelledInput
            label="Complete captcha"
            verticalLayout
            input={
              <div css={tw`text-center`}>
                <Input
                  css={tw`w-full`}
                  onChange={(e) => setEnteredCaptcha(e.currentTarget.value)}
                  placeholder="Enter captcha"
                />
              </div>
            }
          />
        </div>
        <Form.CheckboxInput
          name="acceptedTerms"
          checkboxLabel={
            <span>
              By requesting access to Data Clearing House you agree to adhere
              with the{" "}
              <Link to={TERMS_CONDITIONS_URL} target="_blank">
                terms of service
              </Link>
              <span css={tw`text-red-error whitespace-nowrap`}> *</span>
            </span>
          }
          rules={{
            validate: (s?: boolean) =>
              s
                ? undefined
                : "You must accept the Terms and Conditions to sign up",
          }}
        />
        <Button
          type="submit"
          primary
          style={{
            padding: "1em",
            marginBlock: "1.5rem",
          }}
          disabled={!formState?.isValid || !enteredCaptcha}
        >
          <div css={tw`flex justify-between`}>
            <span> Register for DCH</span>
            <Icon name="long arrow alternate right" />
          </div>
        </Button>
      </Form>
    </UIStatusWrapper>
  );
};
