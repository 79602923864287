/** @jsxImportSource @emotion/react */
import React from "react";
import { Icon } from "semantic-ui-react";
import tw from "twin.macro";
import { ApiKeyCreateResponse } from "data/Enodia";
import { FileDisplay, WarningMessage } from "components/shared";
import { ModalComponent } from "../ApiKeyModal";

export const getCreateSuccessModal = (
  apiKey: ApiKeyCreateResponse,
  onSuccess: () => void,
  onError: (_: string) => void,
  onConfirm: () => void,
  copied?: boolean
): ModalComponent => ({
  header: "Create a New API Key",
  content: (
    <div css={tw`flex flex-col gap-4`}>
      <div css={tw`flex`}>
        <Icon name="check" color="green" size="large" />
        <div css={tw`ml-4`}>
          API key <strong>{apiKey.name}</strong> has been successfully created.
          Please copy the below key and store in your required application.
        </div>
      </div>
      <WarningMessage
        content="WARNING: This is the only time you will be able to copy this API key, so
                make sure to store it in a safe place."
      />

      <FileDisplay data={apiKey.key as string} />
    </div>
  ),
  onConfirm: () => {
    onConfirm();
    apiKey.key &&
      navigator.clipboard.writeText(apiKey.key).then(
        () => onSuccess(),
        () => onError("Error copying API key")
      );
  },
  customConfirmText: copied ? (
    <>
      <Icon name="check" />
      Copied
    </>
  ) : (
    "Copy"
  ),
});
