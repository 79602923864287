/** @jsxImportSource @emotion/react */

import React, { useContext, useState } from "react";
import { FieldValues, useFormContext } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { Button, Dimmer, Grid } from "semantic-ui-react";
import tw from "twin.macro";
import { v4 } from "uuid";
import {
  DataSourceCreateRequest,
  DataSourceResponse,
  aletheiaApi,
} from "data/Aletheia";
import { OrgId } from "data/Enodia";
import { ApiError } from "data/http";
import { genStrongPassword } from "data/password";
import { isValidId, isValidName } from "data/validation";
import { EnodiaOrgContext } from "App";
import { Path } from "Routes";
import { GatewayConfigModal } from "components/Data/DataSource/GatewayConfig";
import {
  HelpText,
  Page,
  PageSection,
  PageTitle,
  UIStatus,
  UIStatusWrapper,
} from "components/shared";
import Form from "components/shared/Forms/ReactHookForm";
import {
  DEFAULT_DECODER_ID,
  DEFAULT_TRANSPORT_CONFIG_TYPE,
} from "./DataSourceUtils";

export interface CreateDataSourceFormValues
  extends DataSourceCreateRequest,
    FieldValues {
  id: string;
  name?: string;
  description?: string;
  enabled: boolean;
  organisationId: OrgId;
  gatewayUsername: string;
  gatewayPassword: string;
  gatewayConfirmPassword: string;
}

const GatewayDetails = () => {
  const { setValue, getValues, trigger } = useFormContext();

  function handleGeneratePassword() {
    const newPassword = genStrongPassword(10, 10); // Replace 10 with your desired password length
    // Set the values of both fields
    setValue("gatewayPassword", newPassword);
    setValue("gatewayConfirmPassword", newPassword);
    trigger();
  }

  return (
    <PageSection header="Gateway authentication">
      <Grid>
        <Grid.Row>
          <Grid.Column width={16} verticalAlign="middle">
            <HelpText>
              <p>
                Define a new set of credentials to let your gateway or other
                device/service authenticate with DCH.
              </p>
              <p>
                Username must be email format, but does not need to be the
                address of a real email account. Importantly, the username must
                be unique -
                <strong>&nbsp; do not use your own username here</strong>.
              </p>
              <p>
                Once the data source is created, install these credentials in
                your equipment or software service.
              </p>
            </HelpText>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={12}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={13}>
                  <Form.TextInput
                    name="gatewayUsername"
                    label="Username"
                    isReadOnly
                  />
                </Grid.Column>
                <Grid.Column width={3} />
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={13}>
                  <Form.TextInput
                    name="gatewayPassword"
                    type="password"
                    autoComplete="new-password"
                    label="Password"
                    required
                  />
                </Grid.Column>
                <Grid.Column width={3}>
                  <Button
                    type="button"
                    primary
                    onClick={handleGeneratePassword}
                  >
                    Generate password
                  </Button>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={13}>
                  <Form.TextInput
                    name="gatewayConfirmPassword"
                    type="password"
                    autoComplete="new-password"
                    label="Confirm Password"
                    required
                    rules={{
                      validate: (s: string) => {
                        return s !== getValues("gatewayPassword")
                          ? `Your password and confirmation password do not match.`
                          : undefined;
                      },
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </PageSection>
  );
};

export const NewDataSource: React.FC = () => {
  const generatedUUID = v4();
  const navigate = useNavigate();
  const { orgId } = useContext(EnodiaOrgContext);

  const [uiStatus, setUIStatus] = useState(new UIStatus());
  const [copied, setCopied] = React.useState(false);
  const [createSuccessData, setCreateSuccessData] = React.useState<
    DataSourceResponse | undefined
  >();
  const [isFormValid, setFormValid] = React.useState(false);

  const mkDataSourceRequest = (
    form: CreateDataSourceFormValues
  ): DataSourceCreateRequest => ({
    id: form.id,
    name: form.name,
    // We need to send the description field, so set it to null if undefined.
    description: form.description ?? undefined,
    enabled: form.enabled,
    organisationId: orgId as OrgId,
    transportConfig: {
      userName: form.gatewayUsername,
      password: form.gatewayPassword,
      type: DEFAULT_TRANSPORT_CONFIG_TYPE, //only one supported type atm, hardcoded
    },
    // decoder ID is hardcoded for now since we only support one decoder id at the moment
    decoderConfig: { decoderId: DEFAULT_DECODER_ID },
  });

  const submitDataSource = async (formValues: CreateDataSourceFormValues) => {
    setUIStatus(uiStatus.setIndeterminate(true));
    const ds = mkDataSourceRequest(formValues);
    aletheiaApi.postDatasource(ds).then(
      (datasource) => {
        setUIStatus(uiStatus.setIndeterminate(false));
        setCreateSuccessData(datasource);
      },
      (e: ApiError) => {
        setUIStatus(uiStatus.setError(e.message));
      }
    );
  };

  return (
    <Page>
      {createSuccessData && (
        <GatewayConfigModal
          datasource={createSuccessData}
          open={createSuccessData !== undefined}
          onClose={() => {
            navigate(Path.DataSources);
            setCopied(false);
          }}
          copied={copied}
          onConfirm={() => setCopied(true)}
          isCreate
        />
      )}

      <PageTitle
        primaryHeader="Add a Data Source"
        heroText={
          <span>
            Complete the following to create a new data source in DCH. Once a
            data source has been created*, its streams can be connected to the
            points in a building model that correspond to the sensors or other
            devices in that building.
            <span css={tw`text-core-grey block`}>
              * currently this web interface only supports adding{" "}
              <Link
                to={
                  "https://bitbucket.csiro.au/projects/sbdch/repos/bms-json/browse"
                }
                target={"_blank"}
                rel={"noopener noreferrer"}
              >
                DCH bms-json
              </Link>{" "}
              compatible devices or services via an MQTT client connection
            </span>
          </span>
        }
      />

      <HelpText>
        A <strong>data source</strong> represents a collection of data streams.
        It may be a gateway device that streams data from devices in your
        building, a web service that provides data such as weather forecasts, or
        any other source of data that helps you manage your building.
      </HelpText>

      <UIStatusWrapper status={uiStatus} clearable={true}>
        <Dimmer.Dimmable dimmed={!orgId} css={tw`z-0`}>
          <Dimmer active={!orgId}>
            <div>Select an organisation to view sites and building list</div>
          </Dimmer>
          <Form<CreateDataSourceFormValues>
            onSubmit={(ds: CreateDataSourceFormValues) => submitDataSource(ds)}
            formId="CreateDataSource"
            setFormState={({ isValid }) => setFormValid(isValid)}
            defaultValues={{
              gatewayUsername: `${generatedUUID}@dataclearinghouse.org`,
            }}
          >
            <React.Fragment>
              <PageSection header="Data source details">
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <Form.TextInput
                        name="id"
                        label="ID"
                        required
                        rules={{ validate: isValidId }}
                        description="Give your data source an ID."
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Form.TextInput
                        name="name"
                        label="Name"
                        required
                        rules={{ validate: isValidName }}
                        description="Give your data source a name."
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Form.TextInput
                        name="description"
                        label="Description"
                        description="Describe what this data source is."
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Form.CheckboxInput
                        name="enabled"
                        label="Enabled?"
                        toggle
                        defaultValue={true}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </PageSection>
              <GatewayDetails />
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <Button
                      primary
                      type="submit"
                      loading={uiStatus.indeterminate}
                      disabled={uiStatus.indeterminate || !isFormValid}
                    >
                      Add data source
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </React.Fragment>
          </Form>
        </Dimmer.Dimmable>
      </UIStatusWrapper>
    </Page>
  );
};
