import { sensorApi, Stream, StreamId } from "../senaps";

const GET_STREAMS_LIMIT = 3000;

/**
 * Get streams without limit
 *
 * @param streamId
 * @param results
 */
export const getStreamsWithoutLimit = (
  streamId: StreamId,
  results: Array<Stream> = []
): Promise<Array<Stream>> => {
  return sensorApi
    .getStreams({
      id: streamId,
      expand: true,
      limit: GET_STREAMS_LIMIT,
      skip: results.length,
    })
    .then(
      (r) => {
        const count = (r && r.count) || 0;
        const newResults = results.concat(r._embedded?.streams || []);

        if (count < GET_STREAMS_LIMIT || count === 0) {
          // no more results to load
          return Promise.resolve(newResults);
        } else {
          return getStreamsWithoutLimit(streamId, newResults);
        }
      },
      (e) => {
        return Promise.reject(e.error || "Could not load stream data");
      }
    );
};
