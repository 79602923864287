/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Grid, Button, Divider } from "semantic-ui-react";
import tw from "twin.macro";

import {
  DataSourceCompositeId,
  DataSourceId,
  DataSourceResponse,
  aletheiaApi,
} from "data/Aletheia";
import { PointTable } from "../PointTable/PointTable";
import { Path } from "Routes";
import OrgContextModal from "context/OrgContextModal";
import {
  EMPTY_DESCRIPTION,
  UIStatus,
  UIStatusWrapper,
  redirectMessage,
  Page,
  PageTitle,
} from "components/shared";
import { renderEnabled } from "./DataSources";
import { createCompositeId, displayType } from "./DataSourceUtils";
import { GatewayConfigModal } from "./GatewayConfig";
import RemoveDataSource from "./RemoveDataSource";
import { usePointTablePageState } from "../PointTable/usePointTablePageState";

type Params = { orgId: string; id: DataSourceId };

const Row: React.FunctionComponent<{
  label1: string;
  value1: React.ReactChild;
  label2?: string;
  value2?: React.ReactChild;
}> = (props) => (
  <Grid.Row>
    <Grid.Column width={2}>
      <strong>{props.label1}</strong>
    </Grid.Column>
    <Grid.Column width={6}>{props.value1}</Grid.Column>
    <Grid.Column width={2}>
      <strong>{props.label2}</strong>
    </Grid.Column>
    <Grid.Column width={6}>{props.value2}</Grid.Column>
  </Grid.Row>
);

const DataSource: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const pageState = usePointTablePageState();

  const { orgId, id } = useParams<Params>();

  const [dataSourceStatus, setDataSourceStatus] = useState(new UIStatus());
  const [dataSource, setDataSource] = useState<DataSourceResponse>();
  const [isGatewayConfigOpen, setIsGatewayConfigOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [confirmationModal, setConfirmationModal] = React.useState(false);

  const fetchDataSource = React.useCallback(() => {
    if (id && orgId) {
      setDataSourceStatus((prevState) => prevState.setIndeterminate(true));
      aletheiaApi
        .getDatasource(createCompositeId(orgId, id) as DataSourceCompositeId)
        .then((ds) => {
          setDataSource(ds);
          setDataSourceStatus((prevState) => prevState.setIndeterminate(false));
          pageState.setDataPoolId(
            createCompositeId(orgId, ds.dataPoolRef.dataPoolId)
          );
        })
        .catch((e) => {
          setDataSourceStatus((prevState) =>
            prevState.setError(
              e.message ??
                "Error fetching data source: No data source with requested ID found"
            )
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, orgId]);

  useEffect(() => {
    // DCH-5510: Wait until the datapool ID has settled to fetch points
    if (pageState.state.pointSearchParams.datapoolid !== undefined) {
      pageState.getPoints();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageState.state.pointSearchParams.datapoolid,
    pageState.state.pointSearchParams.id,
    pageState.state.pointSearchParams.unit,
  ]);

  useEffect(() => {
    fetchDataSource();
  }, [fetchDataSource, id, orgId]);

  return (
    <Page>
      <PageTitle
        primaryHeader={`View Data Source: ${dataSource?.name ?? ""}`}
      />
      <OrgContextModal
        open={confirmationModal}
        openCondition={orgId !== pageState.state.orgId}
        onConfirm={() => {
          navigate(Path.DataSources);
        }}
        modalMessage={redirectMessage}
        setModalState={setConfirmationModal}
      />
      <UIStatusWrapper
        status={dataSourceStatus}
        loadingDataMsg="Loading data source"
      >
        {dataSource && (
          <div>
            <div css={tw`flex space-x-4`}>
              <GatewayConfigModal
                datasource={dataSource}
                open={isGatewayConfigOpen}
                onClose={() => {
                  setIsGatewayConfigOpen(false);
                  setCopied(false);
                }}
                copied={copied}
                onConfirm={() => setCopied(true)}
              />
              <Button
                primary
                onClick={() =>
                  navigate(`/datasource/${orgId}/${id}/edit`, {
                    state: { ...dataSource },
                  })
                }
              >
                Edit
              </Button>
              <RemoveDataSource
                asButton={true}
                dataSource={dataSource}
                onRemoved={() => navigate(-1)}
              />
              <Link
                css={tw`m-auto`}
                to="/#"
                onClick={(e) => {
                  e.preventDefault();
                  setIsGatewayConfigOpen(true);
                }}
              >
                How do I configure my gateway?
              </Link>
            </div>
            <Divider />
            <Grid>
              <Row
                label1="Name"
                value1={dataSource.name as string}
                label2="Enabled"
                value2={renderEnabled(dataSource.enabled)}
              />
              <Row
                label1="ID"
                value1={dataSource.id}
                label2="Type"
                value2={displayType(dataSource.transportConfig.type)}
              />
              <Row
                label1="UID"
                value1={dataSource.uid as string}
                label2="Description"
                value2={dataSource.description || EMPTY_DESCRIPTION}
              />
              <Row
                label1="Data Pool ID"
                value1={dataSource.dataPoolRef.dataPoolId}
              />
            </Grid>
            <Divider />
          </div>
        )}
      </UIStatusWrapper>
      <PointTable pageState={pageState} />
    </Page>
  );
};

export default DataSource;
