import React, { useState } from "react";
import { ApiKey } from "data/Enodia";
import { DchModal, ErrorMessage, UIStatus } from "components/shared";
import {
  getCreateModal,
  getCreateSuccessModal,
  getDeleteModal,
  getEditModal,
} from "./Modals";

export enum ModalMode {
  create,
  createSuccess,
  edit,
  delete,
}

export type ModalComponent = {
  header: string | React.ReactNode;
  content: React.ReactNode;
  onConfirm?: () => void;
  customConfirmText?: string | React.ReactNode;
  submitFormId?: string;
};

type ApiKeyModalProps = {
  open: boolean;
  onClose: () => void;
  apiKey: ApiKey;
  modalMode?: ModalMode;
  setApiKey: (_: ApiKey) => void;
  setModalMode: (_?: ModalMode) => void;
};

const ApiKeyModal = (props: ApiKeyModalProps) => {
  const { open, modalMode, apiKey, onClose, setApiKey, setModalMode } = props;

  const [disableConfirm, setDisableConfirm] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState(new UIStatus());
  const [copied, setCopied] = useState(false);

  const onConfirm = () =>
    setConfirmStatus((prev) => prev.setIndeterminate(true));
  const onSuccess = (handleSuccess: () => void) => {
    setConfirmStatus((prev) => prev.setIndeterminate(false));
    handleSuccess();
  };
  const closeOnSuccess = () => onSuccess(() => props.onClose());
  const onError = (errorMessage: string) =>
    setConfirmStatus((prev) => prev.setError(errorMessage));

  const getModal = (): ModalComponent => {
    switch (modalMode) {
      case ModalMode.create:
        const onCreateActionSuccess = (key: ApiKey) => {
          onSuccess(() => {
            setApiKey(key);
            setModalMode(ModalMode.createSuccess);
          });
        };
        return getCreateModal(
          setDisableConfirm,
          onCreateActionSuccess,
          onError,
          onConfirm
        );
      case ModalMode.createSuccess:
        const onCopySuccess = () => onSuccess(() => setCopied(true));
        return getCreateSuccessModal(
          apiKey,
          onCopySuccess,
          onError,
          onConfirm,
          copied
        );
      case ModalMode.edit:
        return getEditModal(
          apiKey,
          setDisableConfirm,
          closeOnSuccess,
          onError,
          onConfirm
        );
      case ModalMode.delete:
        return getDeleteModal(apiKey, closeOnSuccess, onError, onConfirm);
    }
    return { header: "", content: <></> };
  };

  const modal = getModal();

  return (
    <DchModal
      open={open}
      header={modal.header}
      content={
        confirmStatus.error ? (
          <ErrorMessage>{`${confirmStatus.error}. Please try again later.`}</ErrorMessage>
        ) : (
          modal.content
        )
      }
      onClose={() => {
        setConfirmStatus((prev) => prev.setIndeterminate(false));
        setCopied(false);
        onClose();
      }}
      onConfirm={modal.onConfirm}
      submitFormId={modal.submitFormId}
      id="ApiKeyModal"
      disableConfirm={
        disableConfirm ||
        (confirmStatus?.error !== undefined && confirmStatus.error !== "")
      }
      cancelText={modalMode === ModalMode.createSuccess ? "Close" : "Cancel"}
      confirmText={modal.customConfirmText}
      confirmLoading={confirmStatus.indeterminate}
    />
  );
};

export default ApiKeyModal;
