import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { EnodiaOrgContext } from "App";
import { ApiError } from "data/http";
import {
  AuthorityId,
  AuthoritySummary,
  UpdatedUserAssignmentResponse,
  UserId,
  enodiaApi,
} from "data/Enodia";
import {
  PageTitle,
  PageMode,
  UIStatus,
  UIStatusWrapper,
  Page,
} from "components/shared";
import { InviteUserForm } from "./InviteUserForm";
import { UserPermissions } from "../Permissions";
import _ from "lodash";

type Props = {
  pageMode: PageMode;
};

type Params = {
  user_id: string;
};

const emptyUser: UserPermissions = {
  id: "" as UserId,
  userGroups: [],
  roleSets: [],
  authority: {
    id: "" as AuthorityId,
    title: "",
    description: "",
  },
};
export class UserInviteFormFields {
  emails = "";
  userGroups: Array<string> = [];
  roleSets: Array<string> = [];
  authority: string = "";
}

export const EditOrInviteUser: React.FC<Props> = ({ pageMode }) => {
  const { orgId } = useContext(EnodiaOrgContext);

  const location = useLocation();
  const params = useParams<keyof Params>() as Params;

  const [requestedUser, setRequestedUser] =
    useState<UserPermissions>(emptyUser);
  const [userInvite, setUserInvite] = useState<UserInviteFormFields>(
    new UserInviteFormFields(),
  );
  const [status, setStatus] = useState(new UIStatus());

  useEffect(() => {
    const setUserError = (e: ApiError) =>
      setStatus((prev) =>
        prev.setError(e.message || "An error occurred retrieving user details"),
      );

    if (location.state && location.state.user) {
      // Clear the state in the browser history
      window.history.replaceState({}, "");
      const currUser: UserPermissions = location.state.user;
      setRequestedUser({
        id: currUser.id as UserId,
        userGroups: currUser.userGroups,
        roleSets: currUser.roleSets,
        authority: currUser.authority as AuthoritySummary,
      });
      setUserInvite({
        emails: currUser.id,
        roleSets: currUser.roleSets.map((x) => x.id),
        userGroups: currUser.userGroups.map((x) => x.id),
        authority: currUser.authority?.id ?? "",
      });
    } else if (!_.isEmpty(params) && orgId) {
      // call the api if no user info found from location.state
      setStatus((prev) => prev.setIndeterminate(true));
      enodiaApi
        .getUserAssignmentByOrg(orgId)(params.user_id)
        .then((userAssignment: UpdatedUserAssignmentResponse) => {
          const user: UserInviteFormFields = {
            emails: params.user_id,
            roleSets: userAssignment.assignedRoleSets?.map((x) => x.id) ?? [],
            userGroups: userAssignment.inGroups?.map((x) => x.id) ?? [],
            authority: (userAssignment.assignedAuthority?.id as string) ?? "",
          };

          setUserInvite(user);

          setRequestedUser(
            user
              ? {
                  id: params.user_id as UserId,
                  roleSets: userAssignment.assignedRoleSets,
                  userGroups: userAssignment.inGroups,
                  authority:
                    userAssignment.assignedAuthority as AuthoritySummary,
                }
              : emptyUser,
          );
          setStatus((prev) => prev.setIndeterminate(false));
        })
        .catch((e) => {
          setUserError(e);
        });
    }
    // no params in dependency - causes excessive GET calls
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state, orgId]);

  React.useEffect(() => {
    if (pageMode === PageMode.create) {
      // Otherwise, use the empty user to start creating an invite
      setUserInvite((prev) => ({
        ...prev,
        orgId: orgId,
      }));
    }
  }, [pageMode, orgId]);

  const displayTitle = (mode: PageMode) => {
    switch (mode) {
      case PageMode.view:
        return "View";
      case PageMode.create:
        return "Invite New";
      case PageMode.edit:
        return "Edit";
    }
  };

  return (
    <Page>
      <PageTitle
        primaryHeader={`Access Permissions | ${displayTitle(pageMode)} User`}
      />
      <UIStatusWrapper status={status}>
        <InviteUserForm
          userInvite={userInvite}
          pageMode={pageMode}
          requestedUser={requestedUser}
          setStatus={setStatus}
          setUserInvite={setUserInvite}
        />
      </UIStatusWrapper>
    </Page>
  );
};
