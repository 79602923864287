import React from "react";
import {
  EMPTY_DESCRIPTION,
  EMPTY_NAME,
  EMPTY_ROLESETS,
  EMPTY_USERGROUPS,
} from "components/shared";
import { groupsAndSetsToOptions } from "../FilterByOptions";
import Form from "components/shared/Forms/ReactHookForm";
import { UserGroup } from "./EditOrCreateUserGroup";
import { OrgId, RoleSetResponse, UserGroupResponse } from "data/Enodia";
import { checkNameIsUnique } from "./Validation";
import { useFormContext } from "react-hook-form";
import { isValidName } from "data/validation";
import {
  useAsyncValidate,
  hasAsyncError,
} from "components/shared/Forms/useAsyncValidate";

type UserGroupFormFieldsProps = {
  isReadOnly: boolean;
  orgId?: OrgId;
  userGroups: UserGroupResponse[];
  roleSets: RoleSetResponse[];
};

const viewInfo = {
  roleSetLabel: "RoleSet(s)",
  roleSetDescription: "",
};

const editInfo = {
  roleSetLabel: "Add RoleSet(s) to User Group",
  roleSetDescription:
    "You can add a RoleSet to a UserGroup by selecting it here",
};

export default function UserGroupFormFields({
  isReadOnly,
  orgId,
  userGroups,
  roleSets,
}: UserGroupFormFieldsProps) {
  const formInfo = isReadOnly ? viewInfo : editInfo;
  const { formState, getFieldState } = useFormContext<UserGroup>();

  const validateUniqueName = (name: string): Promise<string | undefined> =>
    new Promise((resolve) => {
      if (orgId && name !== "" && formState.dirtyFields.name) {
        checkNameIsUnique(orgId, name, resolve);
      }
    });

  useAsyncValidate<UserGroup>("name", validateUniqueName);

  return (
    <>
      <Form.TextInput
        name="name"
        label="Name"
        placeholder="Enter name here..."
        isReadOnly={isReadOnly}
        rules={{
          validate: {
            isValidName: (name: string | undefined) =>
              name && isValidName(name),
            hasAsyncError: () => hasAsyncError("name", getFieldState),
          },
        }}
        readOnlyEmptyText={EMPTY_NAME}
        required
      />
      <Form.TextInput<UserGroup>
        isReadOnly={isReadOnly}
        name="description"
        label="Description"
        placeholder="Create a description for this role..."
        readOnlyEmptyText={EMPTY_DESCRIPTION}
      />
      <Form.SelectInput<UserGroup>
        name="userGroups"
        label={"Parent User Groups"}
        description={
          !isReadOnly &&
          "You can associate this user to another parent group by adding them here"
        }
        placeholder={
          !orgId
            ? "Select an organisation to view groups"
            : "Add parent groups that this group falls under"
        }
        options={userGroups.map(groupsAndSetsToOptions)}
        isMulti
        isReadOnly={isReadOnly}
        readOnlyEmptyText={EMPTY_USERGROUPS}
      />
      <Form.SelectInput<UserGroup>
        name="roleSets"
        label={formInfo.roleSetLabel}
        description={formInfo.roleSetDescription}
        placeholder={
          !orgId
            ? "Select an organisation to view rolesets"
            : "Add role set to user group"
        }
        options={roleSets.map(groupsAndSetsToOptions)}
        isMulti
        isReadOnly={isReadOnly}
        readOnlyEmptyText={EMPTY_ROLESETS}
      />
    </>
  );
}
