import { metisWSEnv } from "reducers/env";
import { ClientEnv } from "data/utils";
import * as O from "fp-ts/lib/Option";
import { IMessageEvent, w3cwebsocket } from "websocket";
import { getIdpBearerToken } from "data/auth";
import { mkUrl } from "data/http";
import { LoggerFactory } from "../../logger";
import { ApplicationJobstatusResponse } from "./metisTypes";

/**
 * Application system Websocket API ENDPOINTS
 * AKA. Metis Websockets
 * @link https://develop.dataclearinghouse.org/api/metis/v1/swagger#/
 */
type MetisWSApi = {
  getApplicationStatus: (
    applicationId: string,
    onMessage: (message: ApplicationJobstatusResponse) => void,
    pollingBackupFunction: () => Promise<boolean>
  ) => MetisWSConnection;
};

type MetisWSConnection = {
  websocketClient: w3cwebsocket;
  stopFallback: () => void;
};

const wsConnect = (
  env: ClientEnv,
  path: string,
  onMessage: (message: ApplicationJobstatusResponse) => void,
  pollingFallback: () => Promise<boolean>
): MetisWSConnection => {
  // Get bearer token and set as cookie
  const bearer: string = O.getOrElse(() => "")(getIdpBearerToken());
  document.cookie = `Authorization=Bearer ${bearer};path=/;domain=${env.host}`;
  const wsUrl = mkUrl(env, path, null);
  const websocketClient = new w3cwebsocket(wsUrl);
  const logger = LoggerFactory.create("ApplicationStatusUpdateWS");
  websocketClient.onmessage = function (event: IMessageEvent) {
    if (typeof event.data === "string") {
      const message: ApplicationJobstatusResponse = JSON.parse(event.data);
      onMessage(message);
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let wsIsConnected = false;
  const onBlur = pollFunction(pollingFallback, wsIsConnected);
  websocketClient.onopen = function () {
    wsIsConnected = true;
    logger.debug(`Successfully opened websocket`);
  };
  websocketClient.onerror = function (error) {
    wsIsConnected = false;
    logger.error(`Error in websocket - ${error}`);
    document.cookie = `Authorization=;path=/;domain=${env.host}`;
  };
  websocketClient.onclose = function (event) {
    document.cookie = `Authorization=;path=/;domain=${env.host}`;
    if (event.reason === "Unauthorised") wsIsConnected = false;
  };
  return { websocketClient, stopFallback: onBlur };
};

const mkMetisWSApi = (env: ClientEnv): MetisWSApi => ({
  getApplicationStatus: (
    applicationId: string,
    onMessage: (message: ApplicationJobstatusResponse) => void,
    pollingFallback: () => Promise<boolean>
  ) => {
    const path = `/applications/jobstatuses/${applicationId}`;
    return wsConnect(env, path, onMessage, pollingFallback);
  },
});

export const metisWSApi = mkMetisWSApi(metisWSEnv);

function poll(
  pollingFallback: () => Promise<boolean>,
  wsIsConnected: boolean,
  interval: number,
  timeout: number
): () => void {
  const startTime = Date.now();
  const intervalId = setInterval(async () => {
    const timeNow = Date.now();
    const elapsedTime = timeNow - startTime;
    if (!wsIsConnected && (await pollingFallback())) {
    } else if (elapsedTime >= timeout) {
      clearInterval(intervalId);
    }
  }, interval);
  const stopInterval = () => clearInterval(intervalId);
  return stopInterval;
}

function pollFunction(
  polling: () => Promise<boolean>,
  wsIsConnected: boolean
): () => void {
  const stopPolling = poll(
    async () => {
      return polling();
    },
    wsIsConnected,
    60 * 1000,
    3 * 60 * 1000
  );
  return stopPolling;
}
