/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import React, { useContext } from "react";
import {
  EMPTY_LIST_MSG,
  FilterInput,
  PageTitle,
  Pagination,
  UIStatusWrapper,
} from "components/shared";
import { Accordion, Button, Grid, Icon } from "semantic-ui-react";
import { PointListItem, aletheiaApi } from "data/Aletheia";
import { DEFAULT_PAGINATION_DATA_LIMIT } from "data/paginationHelper";
import { downloadCsv } from "data/utils";
import PointData from "../PointData/PointData";
import { ColumnName, timeSeriesLayout } from "./Common";
import { Tooltip } from "react-tooltip";
import {
  PointTablePageState,
  fetchPoints,
  fetchPointsByLink,
} from "./usePointTablePageState";
import { EnodiaOrgContext } from "App";
import { debounce } from "lodash";

export const PointTable: React.FunctionComponent<{
  pageState: PointTablePageState;
}> = ({ pageState }) => {
  const [activeIndices, setActiveIndices] = React.useState<number[]>([]);
  // DCH-4586: Use context must be used within this React.FunctionComponent rather than the usePointTablePageState:
  // Otherwise, will be faced with: Invalid hook call. Hooks can only be called inside of the body of a function component.
  const { orgId } = useContext(EnodiaOrgContext);

  React.useEffect(() => {
    pageState.setOrgId(orgId);
    setActiveIndices([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  const renderRowTitle = (column: string, point: PointListItem) => {
    switch (column) {
      case ColumnName.Id:
        return (
          <span css={tw`flex`}>
            <Icon name="dropdown" />
            <span
              data-tooltip-id={`point-id-tooltip`}
              data-tooltip-content={point.id}
              data-tooltip-float={true}
              data-tooltip-variant="light"
              css={tw`truncate z-3`}
            >
              {point.id}
            </span>
          </span>
        );
      case ColumnName.UnitOfMeasure:
        return <span>{point.unit}</span>;
      default:
        return;
    }
  };

  const renderPanel = (point: PointListItem, index: number) => ({
    key: `panel-${index}`,
    active: activeIndices.includes(index),
    title: {
      children: (
        <Grid className="truncate">
          <Grid.Row
            // DCH-4585: Add debounce to protect Amcharts from reaching "EventDispatcher is disposed" error
            onClick={debounce(() => toggleIndex(index), 100)}
            className="items-center"
          >
            {timeSeriesLayout.map((column) => (
              <Grid.Column
                width={column.columnWidth}
                textAlign={column.contentAlign}
                key={`${column.columnName}-title-${index}`}
              >
                {renderRowTitle(column.columnName, point)}
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
      ),
    },
    content: {
      children: (
        <div css={tw`m-4`}>
          {activeIndices.includes(index) ? (
            <PointData point={point} />
          ) : (
            <div />
          )}
        </div>
      ),
    },
  });

  const renderHeaderColumn = (column: string) => {
    switch (column) {
      case ColumnName.Id:
        return (
          <div css={tw`flex flex-col`}>
            <span>Point ID</span>
            <FilterInput
              handleSearch={(term) =>
                pageState.setPointSearchParams({
                  id: term !== "" ? `*${term}*` : undefined,
                })
              }
              placeholder="Search by ID..."
            />
          </div>
        );
      case ColumnName.UnitOfMeasure:
        return (
          <div css={tw`flex flex-col`}>
            <span>Unit of Measure</span>
            <FilterInput
              handleSearch={(term) => {
                pageState.setPointSearchParams({
                  unit: term !== "" ? `*${term}*` : undefined,
                });
              }}
              placeholder="Search by Unit of Measure..."
            />
          </div>
        );
      default:
        return;
    }
  };

  const renderHeader = () => (
    <Grid.Row className="items-center">
      {timeSeriesLayout.map((column) => (
        <Grid.Column
          width={column.columnWidth}
          textAlign={column.headerAlign || "left"}
          key={`${column.columnName}-header`}
        >
          {renderHeaderColumn(column.columnName)}
        </Grid.Column>
      ))}
    </Grid.Row>
  );

  const indexIsActive = (index: number) => activeIndices.includes(index);
  const toggleIndex = (index: number) => {
    setActiveIndices((state) => {
      return indexIsActive(index)
        ? [...state.filter((i) => i !== index)]
        : [...state, index];
    });
  };

  const downloadPointsList = () => {
    const { datapoolid } = pageState.state.pointSearchParams;
    if (datapoolid)
      aletheiaApi
        .getPointListCsv(datapoolid, {
          include_header: true,
          all_fields: true,
        })
        .then(
          (res) => downloadCsv(res, datapoolid),
          () =>
            pageState.setPointsStatus((prevState) =>
              prevState.setError(
                "There was an error downloading the points list. Please try again later.",
              ),
            ),
        );
  };

  return (
    <UIStatusWrapper status={pageState.state.pointsStatus}>
      <PageTitle
        secondaryHeader={
          <div css={tw`flex`}>
            <span css={tw`mr-4`}>Points</span>
            <Button
              basic
              inverted
              onClick={() => downloadPointsList()}
              disabled={!pageState.state.pointSearchParams.datapoolid}
            >
              <Icon name="download" />
              Download Points List
            </Button>
          </div>
        }
      />
      <Tooltip id={`point-id-tooltip`} css={tw`z-5`} />
      <div css={tw`m-4 mt-8`}>
        <Grid css={tw`border-core-grey border border-solid`}>
          {renderHeader()}
          {pageState.state.points.length > 0 ? (
            <Accordion
              inverted
              panels={pageState.state.points.map((point, index) =>
                renderPanel(point, index),
              )}
              className="striped"
            />
          ) : (
            <i> {EMPTY_LIST_MSG} </i>
          )}
          {pageState.state.links && (
            <Pagination
              links={pageState.state.links}
              onPaginationChange={(link) => {
                fetchPointsByLink(link, pageState);
              }}
              setLimit={(limit) => {
                pageState.setPointSearchParams({
                  limit: limit,
                });
              }}
              limit={
                pageState.state.pointSearchParams.limit ||
                DEFAULT_PAGINATION_DATA_LIMIT
              }
              dataLength={pageState.state.points.length}
              refetch={() => fetchPoints(pageState)}
            />
          )}
        </Grid>
      </div>
    </UIStatusWrapper>
  );
};
