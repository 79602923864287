import React from "react";

export const GridSvgIcon = (props: {
  color: string;
  width?: number;
  height?: number;
  gap?: number;
}) => {
  const gap = props.gap ?? 3;
  const width = props.width ?? 43;
  const height = props.height ?? 27;
  const lineHeight = (height - gap) / 2;
  const lineWidth = (width - gap) / 2;
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={lineWidth} height={lineHeight} rx={gap} fill={props.color} />
      <rect
        x={lineWidth + gap}
        width={lineWidth}
        height={lineHeight}
        rx={gap}
        fill={props.color}
      />
      <rect
        y={lineHeight + gap}
        width={lineWidth}
        height={lineHeight}
        rx={gap}
        fill={props.color}
      />
      <rect
        x={lineWidth + gap}
        y={lineHeight + gap}
        width={lineWidth}
        height={lineHeight}
        rx={gap}
        fill={props.color}
      />
    </svg>
  );
};
