/** @jsxImportSource @emotion/react */

import { useState } from "react";
import { getCookie, removeCookie } from "components/shared/utils/cookie-utils";
import { Auth0Context } from "./useAuth0Context";

const GRAFANA_PATH = ((("https://" +
  process.env.REACT_APP_GRAFANA_HOST!!) as string) +
  process.env.REACT_APP_GRAFANA_BASE_URL!!) as string;

export const GRAFANA_SESSION_KEY = "grafana_session";
export const GRAFANA_SESSION_EXPIRY_KEY = "grafana_session_expiry";

type GrafanaSession = {
  token: string;
  expiry: number;
};

export type GrafanaContext = {
  login: () => void;
  logout: () => void;
  checkSession: () => void;
  deferRender: (
    component: React.ReactElement<any>
  ) => React.ReactElement<any> | null;
  session?: GrafanaSession;
};

export const useGrafanaContext = (
  auth0Context: Auth0Context
): GrafanaContext => {
  const [hasLoggedIn, setHasLoggedIn] = useState(false);
  const [session, setSession] = useState<GrafanaSession>();

  const hasValidSession = (grafanaSessionExpiry: string | null) =>
    grafanaSessionExpiry
      ? new Date(parseFloat(grafanaSessionExpiry)) < new Date()
      : false;

  const getSessionFromCookies = () => {
    const grafanaSession = getCookie(GRAFANA_SESSION_KEY);
    const grafanaSessionExpiry = getCookie(GRAFANA_SESSION_EXPIRY_KEY);

    const expired = !hasValidSession(grafanaSessionExpiry);

    grafanaSession &&
      grafanaSessionExpiry &&
      setSession({
        token: grafanaSession,
        expiry: parseFloat(grafanaSessionExpiry),
      });

    !expired && grafanaSession && setHasLoggedIn(true);

    return !expired && grafanaSession;
  };

  const checkSession = () => {
    getSessionFromCookies() || login();
  };

  const deferRender = (component: React.ReactElement<any>) =>
    hasLoggedIn ? component : null;

  const login = () => {
    fetch(`${GRAFANA_PATH}/login`, {
      method: "GET",
      credentials: "include",
      redirect: "follow",
      headers: {
        Authorization: `Bearer ${auth0Context.state?.authResult
          ?.accessToken!!}`,
      },
    }).then(
      (response) => {
        response.ok && setHasLoggedIn(true);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const logout = () => {
    removeCookie(GRAFANA_SESSION_KEY);
    removeCookie(GRAFANA_SESSION_EXPIRY_KEY);
  };

  const pageState = {
    login,
    logout,
    checkSession,
    deferRender,
    session,
  };

  return pageState;
};
