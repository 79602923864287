import React from "react";
import { ApiKey, ApiKeyCreateResponse, enodiaApi } from "data/Enodia";
import { ModalComponent } from "../ApiKeyModal";
import { API_KEY_DETAILS_FORM_ID, KeyDetailsForm } from "../KeyDetailsForm";

export const getCreateModal = (
  setDisableConfirm: (_: boolean) => void,
  onSuccess: (res: ApiKey) => void,
  onError: (_: string) => void,
  onConfirm: () => void
): ModalComponent => {
  return {
    header: "Create a New API Key",
    content: (
      <KeyDetailsForm
        onSubmit={(key) => {
          onConfirm();
          enodiaApi
            .postApiKey({
              ...key,
              expires: key.expires
                ? new Date(key.expires).toISOString()
                : undefined,
            })
            .then(
              (res: ApiKeyCreateResponse) =>
                onSuccess({ ...res, keyHead: "", keyTail: "" }),
              () => onError("Error creating API key")
            );
        }}
        setFormState={({ isValid }) => setDisableConfirm(!isValid)}
      />
    ),
    submitFormId: API_KEY_DETAILS_FORM_ID,
  };
};
