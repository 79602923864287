import { ClientEnv } from "data/utils";
import { masonEnv } from "reducers/env";
import * as O from "fp-ts/lib/Option";
import {
  httpGet,
  httpPut,
  httpDelete,
  httpPutRawWithShaclReport,
  httpPostRawWithShaclReport,
} from "data/httpUtil";
import {
  OrganisationGetRequest,
  ModelPublishStatusResponse,
  ShaclValidationResultReportResponse,
  MASON_JSON_DRAFT_ROUTE,
  MASON_ORGANISATION_ROUTE,
  MASON_RDF_DRAFT_ROUTE,
  MASON_SITES_ROUTE,
  MASON_SITE_ROUTE,
  MASON_SITES_AND_BUILDINGS_ROUTE,
  ModelShaclReportResponse,
} from "..";
import {
  SiteGetRequest,
  SiteMetaResponse,
  SitesAndBuildingsResponse,
} from "./SiteTypes";
import {
  DCHModel,
  SerialisedDCHModel,
  deserialiseDCHModel,
  serialiseDCHModel,
} from "data/brick";

export type SiteApi = {
  getSites: (
    params: OrganisationGetRequest,
  ) => Promise<Array<SiteMetaResponse>>;
  getSitesAndBuildings: (
    params: OrganisationGetRequest,
  ) => Promise<Array<SitesAndBuildingsResponse>>;
  getSite: (params: SiteGetRequest) => Promise<SiteMetaResponse>;
  deleteSite: (params: SiteGetRequest) => Promise<void>;
  putSite: (
    params: SiteGetRequest,
  ) => (siteMeta: SiteMetaResponse) => Promise<void>;
  getDraftSiteRDF: (params: SiteGetRequest) => Promise<string>;
  putDraftSiteRDF: (
    params: SiteGetRequest,
  ) => (rdf: string) => Promise<ModelShaclReportResponse>;
  getDraftSiteJSON: (params: SiteGetRequest) => Promise<DCHModel>;
  putDraftSiteJSON: (
    params: SiteGetRequest,
    model: DCHModel,
  ) => Promise<ShaclValidationResultReportResponse>;
  publishSite: (params: SiteGetRequest) => Promise<ModelShaclReportResponse>;
  validateSite: (params: SiteGetRequest) => Promise<ModelShaclReportResponse>;
  getPublishStatus: (
    params: SiteGetRequest,
  ) => Promise<ModelPublishStatusResponse>;
};

const getSiteBaseUrl = (params: SiteGetRequest) =>
  `/${MASON_ORGANISATION_ROUTE}/${params.orgId}/${MASON_SITE_ROUTE}/${params.siteId}`;

const mkSiteApi = (env: ClientEnv): SiteApi => ({
  getSites: (params) =>
    httpGet(O.none)(env)(params)(
      `/${MASON_ORGANISATION_ROUTE}/${params.orgId}/${MASON_SITES_ROUTE}`,
    ).then((r) => r as SiteMetaResponse[]),
  getSitesAndBuildings: (params) =>
    httpGet(O.none)(env)(params)(
      `/${MASON_ORGANISATION_ROUTE}/${params.orgId}/${MASON_SITES_AND_BUILDINGS_ROUTE}`,
    ).then((r) => r as SitesAndBuildingsResponse[]),
  getSite: (params) =>
    (true
      ? httpGet(O.none)(env)(null)(getSiteBaseUrl(params))
      : Promise.resolve(stubSiteMeta)
    ).then((r) => r as SiteMetaResponse),
  deleteSite: (params) =>
    httpDelete(O.none)(env)(null)(getSiteBaseUrl(params)).then(
      (_) => undefined,
    ),
  putSite: (params) => (siteMeta) =>
    httpPut(siteMeta)(O.none)(env)({})(getSiteBaseUrl(params)).then(
      () => undefined,
    ),
  getDraftSiteRDF: (params) =>
    httpGet(O.none)(env)({})(
      `${getSiteBaseUrl(params)}/${MASON_RDF_DRAFT_ROUTE}`,
    ).then((r) => r as string),
  putDraftSiteRDF: (params) => (rdf) =>
    httpPutRawWithShaclReport(rdf)(O.none)(env)({})(
      `${getSiteBaseUrl(params)}/${MASON_RDF_DRAFT_ROUTE}`,
    ).then((r) => r as ModelShaclReportResponse),
  getDraftSiteJSON: (params) =>
    httpGet(O.none)(env)({})(
      `${getSiteBaseUrl(params)}/${MASON_JSON_DRAFT_ROUTE}`,
    ).then((r) => deserialiseDCHModel(r as SerialisedDCHModel)),
  putDraftSiteJSON: (params, model) =>
    httpPut(serialiseDCHModel(model))(O.none)(env)({})(
      `${getSiteBaseUrl(params)}/${MASON_JSON_DRAFT_ROUTE}`,
    ).then((r) => r as ShaclValidationResultReportResponse),
  publishSite: (params) =>
    httpPostRawWithShaclReport({})(O.none)(env)({})(
      `${getSiteBaseUrl(params)}/publish`,
    ).then((r) => r as ModelShaclReportResponse),
  validateSite: (params) =>
    httpPutRawWithShaclReport({})(O.none)(env)({})(
      `${getSiteBaseUrl(params)}/validate`,
    ).then((r) => r as ModelShaclReportResponse),
  getPublishStatus: (params) =>
    httpGet(O.none)(env)({})(`${getSiteBaseUrl(params)}/publish`).then(
      (r) => r as ModelPublishStatusResponse,
    ),
});

export const siteApi = mkSiteApi(masonEnv);

const stubSiteMeta = {
  orgId: "OrgId",
  siteId: "SiteId",
  buildingIds: ["BuildingId"],
  label: "Site-label",
  description: "description",
};
