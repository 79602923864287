/** @jsxImportSource @emotion/react */

import React, { ReactNode } from "react";
import tw from "twin.macro";

type Props = {
  header?: string | ReactNode;
  children?: ReactNode;
};

export const PageSection = (props: Props) => {
  return (
    <div css={tw`mt-6`}>
      <div
        css={tw`border-solid border-2 border-core-grey m-0 mb-4 p-6 pt-8 relative`}
      >
        {props.header && (
          <h3
            css={tw`absolute bg-black px-2 mt-0 text-core-grey`}
            style={{ top: "-1rem" }}
          >
            {props.header}
          </h3>
        )}
        {props.children}
      </div>
    </div>
  );
};
