/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { Auth0Error, Auth0Result, Auth0UserProfile, WebAuth } from "auth0-js";
import { addMilliseconds } from "date-fns";
import { now } from "moment";
import { setEnodiaToken } from "data/auth";
import { OauthTokenResponse } from "data/Enodia";
import { decodeBase64, encodeBase64 } from "components/shared";

const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN!! as string;
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID!! as string;

const IDP_AUTH_RESULT_KEY = "idpAuthResult";
const IDP_USER_INFO_KEY = "idpUserInfo";
const IDP_BEARER_TOKEN_EXPIRY = "idpBearerTokenExp";

type UserInfo = Pick<Auth0UserProfile, "user_id" | "username" | "email">;

type Auth0State = {
  webAuth: WebAuth;
  authResult?: Auth0Result;
  userInfo?: UserInfo;
  authenticated?: boolean;
  error?: string;
};

const getRedirectUri = () => window.location.origin + process.env.PUBLIC_URL;

export type Auth0Context = {
  login: () => void;
  logout: () => void;
  checkSession: (renewalTimeframeMilliseconds: number) => void;
  resetError: () => void;
  resetSessionStorage: () => void;
  deferRender: (
    component: React.ReactElement<any>
  ) => React.ReactElement<any> | null;
  state?: Auth0State;
  signup: () => void;
};

export const useAuth0Context = (): Auth0Context => {
  const webAuthFactory = () =>
    new WebAuth({
      domain: AUTH0_DOMAIN,
      clientID: AUTH0_CLIENT_ID,
      redirectUri: getRedirectUri(),
      responseType: "token id_token",
      scope: "openid profile email offline_access",
    });

  const [state, setState] = useState<Auth0State>({
    webAuth: webAuthFactory(),
  });

  const handleError = (
    error: Auth0Error | null,
    defaultErrorResponse: string = "Error communicating with Auth0"
  ) => {
    if (error != null) {
      const errorMessage =
        error.description ||
        error.errorDescription ||
        error.statusText ||
        defaultErrorResponse;
      console.error(errorMessage);
      setState((currentState: Auth0State) => ({
        ...currentState,
        error: errorMessage,
      }));
    }
  };

  const removeAuth0Cookies = () => {
    // DCH-5598: Removes auth0 cookies, avoiding a 431 error (HTTP request too long) when the user tries to log in
    document.cookie.split(";").forEach((c) => {
      const cookieKey = c.substring(0, c.indexOf("="));
      if (cookieKey.includes("auth0")) {
        document.cookie = `${cookieKey}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      }
    });
  };

  const setUserInfo = (authResult: Auth0Result, userInfo: UserInfo) => {
    sessionStorage.setItem(
      IDP_USER_INFO_KEY,
      encodeBase64(JSON.stringify(userInfo))
    );
    setState((currentState: Auth0State) => ({
      ...currentState,
      authResult: authResult,
      userInfo: userInfo,
      authenticated: true,
    }));
  };

  const getSessionFromAuth0 = () => {
    state.webAuth.checkSession(
      {},
      (err: Auth0Error | null, newAuthResult: any) => {
        if (err) {
          setState((currentState: Auth0State) => ({
            ...currentState,
            authResult: undefined,
            userInfo: undefined,
            authenticated: false,
          }));

          if (err.code !== "login_required") {
            handleError(err, "Error gathering bearer token from Auth0");
          }
        }

        const authResult = newAuthResult === null ? undefined : newAuthResult;

        if (authResult) {
          sessionStorage.setItem(
            IDP_AUTH_RESULT_KEY,
            encodeBase64(JSON.stringify(newAuthResult))
          );

          if (authResult?.accessToken) {
            setEnodiaToken({
              ...(authResult as OauthTokenResponse),
              accessToken: authResult.accessToken,
            });

            authResult.expiresIn &&
              sessionStorage.setItem(
                IDP_BEARER_TOKEN_EXPIRY,
                (now() + authResult.expiresIn * 1000).toString()
              );

            const authResultPayload = authResult?.["idTokenPayload"];
            const userInfo: UserInfo | undefined =
              authResultPayload && authResultPayload?.["email"]
                ? {
                    user_id: authResultPayload?.["user_id"],
                    username: authResultPayload?.["username"],
                    email: authResultPayload?.["email"],
                  }
                : undefined;

            if (userInfo) setUserInfo(authResult, userInfo);
            else
              state.webAuth.client.userInfo(
                authResult.accessToken,
                (err, auth0UserProfile) => {
                  handleError(err, "Error gathering user info from Auth0");
                  setUserInfo(authResult, auth0UserProfile);
                }
              );
          }
        }
      }
    );
  };

  const getSessionFromSessionStorage = (
    renewalTimeframeMilliseconds: number
  ) => {
    const storedAuthResult = sessionStorage.getItem(IDP_AUTH_RESULT_KEY);
    const storedUserInfo = sessionStorage.getItem(IDP_USER_INFO_KEY);
    const storedExpiry = sessionStorage.getItem(IDP_BEARER_TOKEN_EXPIRY);

    const authResult = storedAuthResult
      ? JSON.parse(decodeBase64(storedAuthResult))
      : undefined;
    const userInfo = storedUserInfo
      ? JSON.parse(decodeBase64(storedUserInfo))
      : undefined;
    const expiredOrExpiresWithinRenewalTimeframe = storedExpiry
      ? new Date(parseFloat(storedExpiry)) <
        addMilliseconds(new Date(), renewalTimeframeMilliseconds)
      : false;

    const loadedFromStorage =
      authResult !== undefined && userInfo !== undefined;
    setState((currentState: Auth0State) => ({
      ...currentState,
      authResult: authResult,
      userInfo: userInfo,
      authenticated: loadedFromStorage ? true : undefined,
    }));

    return !expiredOrExpiresWithinRenewalTimeframe && loadedFromStorage;
  };

  const checkSession = (renewalTimeframeMinutes: number) => {
    getSessionFromSessionStorage(renewalTimeframeMinutes) ||
      getSessionFromAuth0();
  };

  const deferRender = (component: React.ReactElement<any>) =>
    state.authenticated !== undefined ? component : null;

  const resetSessionStorage = () => {
    removeAuth0Cookies();
    sessionStorage.removeItem(IDP_AUTH_RESULT_KEY);
    sessionStorage.removeItem(IDP_USER_INFO_KEY);
    sessionStorage.removeItem(IDP_BEARER_TOKEN_EXPIRY);
  };

  const resetError = () => {
    setState((currentState: Auth0State) => ({
      ...currentState,
      webAuth: webAuthFactory(),
      error: undefined,
    }));
    state.webAuth.logout({
      returnTo: getRedirectUri(),
      clientID: AUTH0_CLIENT_ID,
    });
  };

  const resetPageState = () => {
    setState((currentState: Auth0State) => ({
      ...currentState,
      authResult: undefined,
      userInfo: undefined,
      authenticated: undefined,
    }));
  };

  const resetSession = () => {
    resetSessionStorage();
    resetPageState();
  };

  const login = () => {
    const prompt = state.error ? "login" : undefined;
    if (state.error) {
      resetSession();
    }
    state.webAuth.authorize({
      redirectUri: getRedirectUri(),
      prompt: prompt,
    });
  };

  const logout = () => {
    // DCH-5546: Remove session storage if logging out
    resetSession();
    state.webAuth.logout({
      returnTo: getRedirectUri(),
      clientID: AUTH0_CLIENT_ID,
    });
  };

  const signup = () => {
    if (state.error) {
      resetSession();
    }
    state.webAuth.authorize({
      redirectUri: getRedirectUri(),
      prompt: "login",
      screen_hint: "signup",
    });
  };

  const pageState = {
    state,
    login,
    logout,
    checkSession,
    resetError,
    resetSessionStorage,
    deferRender,
    signup,
  };

  return pageState;
};
