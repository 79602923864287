import React from "react";
import { generatePath } from "react-router-dom";
import { Path } from "Routes";
import { LocationId, ModelRootType, PointId } from "data/brick";
import { ClassHypernym } from "data/Mason";
import {
  ModelMeta,
  NodeFormFields,
} from "data/Mason/ModelDraft/ModelDraftUtils";
import { TextInput } from "components/shared";
import Form from "components/shared/Forms/ReactHookForm";
import { EntityProperties } from "../../EntityProperties/EntityProperties";
import { EntityProperty } from "../../EntityProperties/EntityProperty";
import { ModelEntityProps } from "../ModelFormUtils";
import {
  CommonInputProps,
  Comment,
  Points,
  LocationOf,
  Name,
} from "./CommonInputs";
import {
  useLocationOfWatcher,
  usePointsWatcher,
} from "../RelationshipWatchers";

export type ModelDetailsFields = NodeFormFields & {
  type?: ModelRootType;
  comment?: string;
  locationOf?: LocationId[];
  points?: PointId[];
  properties?: EntityProperty[];
};

export const ModelDetails = ({
  isReadOnly,
  modelType,
  orgId,
  siteId,
  modelMeta,
}: ModelEntityProps & { modelMeta: ModelMeta }) => {
  const inputProps: CommonInputProps = {
    isReadOnly,
    objectName: modelType,
    formFieldName: "modelDetails",
  };

  useModelDetailsRelationshipWatcher(inputProps, modelMeta);

  return (
    <>
      <Form.TextInput name="modelDetails.fieldId" label="ID" isReadOnly />
      <Name {...inputProps} />
      <TextInput label="Type" value={modelType} isReadOnly />
      {modelType === ModelRootType.Building && (
        <TextInput
          label="Site"
          value={siteId}
          isReadOnly
          link={generatePath(Path.ViewSite, {
            orgId: orgId,
            siteId: siteId!!,
          })}
        />
      )}
      <LocationOf {...inputProps} />
      <Points {...inputProps} />
      <Comment {...inputProps} />
      <EntityProperties
        {...inputProps}
        hypernym={ClassHypernym.Location}
        parentClass={modelType}
      />
    </>
  );
};

const useModelDetailsRelationshipWatcher = (
  inputProps: CommonInputProps,
  modelMeta: ModelMeta
) => {
  const { isReadOnly, formFieldName } = inputProps;

  const entityFieldId = modelMeta.modelId;

  const watcherProps = {
    isReadOnly,
    entityFieldId,
    entityIdentifier: formFieldName as string,
  };
  useLocationOfWatcher(watcherProps);
  usePointsWatcher(watcherProps);
};
