import React from "react";
import { useFormContext } from "react-hook-form";
import { EquipmentId, LocationId, PointId, ZoneType } from "data/brick";
import { ClassHypernym } from "data/Mason";
import { NodeFormFields } from "data/Mason/ModelDraft/ModelDraftUtils";
import { ModelEntityCollectionProps } from "../ModelFormUtils";
import { EntityProperty } from "../../EntityProperties/EntityProperty";
import { EntityProperties } from "../../EntityProperties/EntityProperties";
import { useIsFedByWatcher } from "../RelationshipWatchers";
import {
  Comment,
  CommonInputProps,
  DeleteButton,
  Id,
  IsFedBy,
  Locations,
  Name,
  Points,
  TypeInput,
} from "./CommonInputs";

export type ZoneFields = {
  type?: ZoneType;
  locations?: LocationId[];
  isFedBy?: EquipmentId[];
  points?: PointId[];
  properties?: EntityProperty[];
} & NodeFormFields;

export const Zone = ({
  isReadOnly,
  index,
  modelMeta,
  deleteNode,
}: ModelEntityCollectionProps) => {
  const inputProps: CommonInputProps = {
    isReadOnly,
    formFieldName: "zones",
    objectName: "zone",
    index,
  };

  useZoneRelationshipWatcher(inputProps);

  return (
    <>
      <Id {...inputProps} />
      <Name {...inputProps} />
      <TypeInput
        {...inputProps}
        hierarchyParams={{ hypernym: ClassHypernym.Zone, parent: "Zone" }}
      />
      <Locations {...inputProps} {...modelMeta} />
      <IsFedBy {...inputProps} />
      <Points {...inputProps} />
      <Comment {...inputProps} />
      <EntityProperties
        {...inputProps}
        hypernym={ClassHypernym.Zone}
        parentClass="Zone"
      />
      {!isReadOnly && (
        <DeleteButton onDelete={() => deleteNode(index)} objectName="zone" />
      )}
    </>
  );
};

const useZoneRelationshipWatcher = (inputProps: CommonInputProps) => {
  const { isReadOnly, formFieldName, index } = inputProps;
  const { getValues } = useFormContext();

  const entityIdentifier = `${formFieldName}.${index}`;
  const entityFieldId = getValues(`${entityIdentifier}.fieldId`);

  useIsFedByWatcher({ isReadOnly, entityFieldId, entityIdentifier });
};
