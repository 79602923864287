/** @jsxImportSource @emotion/react */
import React from "react";
import { Link } from "react-router-dom";
import { Input } from "semantic-ui-react";
import tw from "twin.macro";
import {
  InputProps,
  FormProps,
  getLabelProps,
  getCommonInputProps,
  getControlledInputProps,
  getLabelledInputLayoutProps,
  LabelledInput,
  UNSPECIFIED_STRING_MSG,
} from "components/shared";
import { InputValidation } from "../InputValidation";

const ReadOnlyInput = (props: {
  value?: string;
  readOnlyEmptyText?: string;
  verticalLayout?: boolean;
  link?: string;
}) => {
  const readOnlyValue =
    props.value && props.value !== ""
      ? props.value
      : props.readOnlyEmptyText ?? UNSPECIFIED_STRING_MSG;
  return props.link ? (
    <Link css={tw`cursor-pointer underline`} to={props.link}>
      {readOnlyValue}
    </Link>
  ) : (
    <Input
      fluid
      className={`readonly ${!props.value ? "italic" : ""} ${
        props.verticalLayout ? "vertical" : ""
      }`}
      tabIndex={-1}
      value={readOnlyValue}
      children={<div css={tw`truncate`}>{readOnlyValue}</div>}
    />
  );
};

export type InputType = "password" | "email" | "string" | "number";
export type TextInputProps = {
  type?: InputType;
  autoComplete?: string;
  link?: string;
  testId?: string;
} & InputProps<string>;

export const TextInput = (props: TextInputProps) => {
  const { inputValidation, ...inputProps } = props;
  const labelProps = getLabelProps(inputProps);
  const labelledInputProps = getLabelledInputLayoutProps(props);
  const { readOnlyEmptyText, ...commonProps } = getCommonInputProps(inputProps);
  const controlledInputProps = getControlledInputProps(inputProps);
  const formProps = (inputProps as FormProps).formProps;

  if (
    (controlledInputProps.value || controlledInputProps.onChange) &&
    formProps &&
    !props.isReadOnly
  )
    console.error(
      "Cannot supply props for both controlled and uncontrolled input to TextInput. Remove either [formProps] or [value,onChange]"
    );
  const passwordRevealProps = usePasswordReveal(props.type);
  const input = inputProps.isReadOnly ? (
    <ReadOnlyInput
      value={formProps?.value ?? props.value}
      readOnlyEmptyText={readOnlyEmptyText}
      link={props.link}
      verticalLayout={labelledInputProps.verticalLayout}
    />
  ) : (
    <>
      <Input
        fluid
        {...commonProps}
        {...controlledInputProps}
        {...formProps}
        {...passwordRevealProps}
        error={commonProps.error ?? inputValidation?.invalid}
        data-test-id={props.testId}
      />
      {inputValidation && (
        <InputValidation {...{ ...inputValidation, testId: props.testId }} />
      )}
    </>
  );

  return props.label ? (
    <LabelledInput {...labelProps} {...labelledInputProps} input={input} />
  ) : (
    input
  );
};

const usePasswordReveal = (initialType?: InputType) => {
  const [type, setType] = React.useState(initialType);
  const [icon, setIcon] = React.useState(
    initialType === "password" ? "eye" : undefined
  );

  const toggleIcon = React.useCallback(() => {
    if (type === "password") {
      setType("string");
      setIcon("eye slash");
    } else {
      setType("password");
      setIcon("eye");
    }
  }, [type, setType, setIcon]);

  return type
    ? {
        type: type,
        icon: icon
          ? {
              name: icon,
              circular: true,
              link: true,
              onClick: toggleIcon,
            }
          : undefined,
      }
    : {};
};
