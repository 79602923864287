/** @jsxImportSource @emotion/react */
import { Dispatch, SetStateAction, useState } from "react";
import { Button, Icon } from "semantic-ui-react";
import tw from "twin.macro";
import {
  ApplicationVersionFullResponse,
  ApplicationVersionPutRequest,
  metisApi,
} from "data/Metis";
import { MarkdownInput, PageMode, UIStatus } from "components/shared";

export const ApplicationConfiguration = ({
  appVersion,
  setUiStatus,
  setAppVersion,
}: {
  appVersion?: ApplicationVersionFullResponse;
  setAppVersion: (_: ApplicationVersionFullResponse) => void;
  setUiStatus: Dispatch<SetStateAction<UIStatus>>;
}) => {
  const [configInstructions, setConfigInstructions] = useState<
    string | undefined
  >(appVersion?.configurationInstructions);
  const [mode, setMode] = useState(PageMode.view);
  const [isTouched, setIsTouched] = useState(false);

  const handleSave = () => {
    if (appVersion) {
      setUiStatus((prev) => prev.setIndeterminate(true));
      metisApi
        .putManageApplicationRecipeVersion(
          appVersion.applicationUid,
          appVersion.uid,
          ((v) => ({
            configurationInstructions: configInstructions,
            autoRunOnInstall: v.autoRunOnInstall,
            canRun: v.canRun,
            runLabel: v.runLabel,
            resourceRequirement: v.resourceRequirement,
            configurationDocuments: v.configurationDocuments,
            readablePorts: v.readablePorts,
            writablePorts: v.writablePorts,
            dashboardDateLocation: v.dashboardDateLocation,
            changeLog: v.changeLog,
            requirements: v.requirements,
            deprecated: v.deprecated,
          }))(appVersion) as ApplicationVersionPutRequest
        )
        .then(
          () => {
            setAppVersion({
              ...appVersion,
              configurationInstructions: configInstructions,
            });
            setUiStatus((prev) => prev.setIndeterminate(false));
          },
          (error) =>
            setUiStatus((prev) =>
              prev.setError(
                error.message ??
                  "An error occured saving the application configuration. Please try again later."
              )
            )
        );
    }
    setMode(PageMode.view);
  };

  return (
    <div css={tw`flex flex-col items-end mx-4`}>
      <div css={tw`w-full`}>
        {appVersion ? (
          <MarkdownInput
            value={configInstructions}
            onChange={(newMarkdown) => {
              setConfigInstructions(newMarkdown);
              if (!isTouched) setIsTouched(true);
            }}
            isReadOnly={mode === PageMode.view}
          />
        ) : (
          <>No version available for configuration.</>
        )}
      </div>
      <div css={tw`mt-4`}>
        {mode === PageMode.view && (
          <Button
            primary
            onClick={() => {
              setMode(PageMode.edit);
            }}
          >
            <Icon name="pencil" />
            Edit Configuration
          </Button>
        )}
        {mode === PageMode.edit && (
          <div css={tw`flex gap-2`}>
            <Button
              basic
              inverted
              onClick={() => {
                setConfigInstructions(appVersion?.configurationInstructions);
                setMode(PageMode.view);
              }}
            >
              Cancel
            </Button>
            <Button primary onClick={handleSave} disabled={!isTouched}>
              <Icon name="save" />
              Save Configuration
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
