import React, { FocusEvent, SyntheticEvent } from "react";
import {
  ariaDescribedByIds,
  enumOptionsIndexForValue,
  enumOptionsValueForIndex,
  labelValue,
  EnumOptionsType,
  FormContextType,
  RJSFSchema,
  StrictRJSFSchema,
  WidgetProps,
  UIOptionsType,
  getUiOptions,
  UiSchema,
  GenericObjectType,
} from "@rjsf/utils";
import { Form, DropdownProps } from "semantic-ui-react";

type SemanticPropsTypes<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
> = {
  formContext?: F;
  uiSchema?: UiSchema<T, S, F>;
  options?: UIOptionsType<T, S, F>;
  defaultSchemaProps?: GenericObjectType;
  defaultContextProps?: GenericObjectType;
};

/**
 * Extract props meant for semantic UI components from props that are
 * passed to Widgets, Templates and Fields.
 * @param {Object} params
 * @param {Object?} params.formContext
 * @param {Object?} params.uiSchema
 * @param {Object?} params.options
 * @param {Object?} params.defaultSchemaProps
 * @param {Object?} params.defaultContextProps
 * @returns {any}
 */
function getSemanticProps<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>({
  formContext = {} as F,
  uiSchema = {},
  options = {},
  defaultSchemaProps = { fluid: true, inverted: false },
  defaultContextProps = {},
}: SemanticPropsTypes<T, S, F>) {
  const formContextProps = formContext.semantic;
  const schemaProps = getUiOptions<T, S, F>(uiSchema).semantic;
  const optionProps = options.semantic;
  // formContext props should overide other props
  return Object.assign(
    {},
    { ...defaultSchemaProps },
    { ...defaultContextProps },
    schemaProps,
    optionProps,
    formContextProps
  );
}
/**
 * Returns and creates an array format required for semantic drop down
 * @param {array} enumOptions- array of items for the dropdown
 * @param {array} enumDisabled - array of enum option values to disable
 * @returns {*}
 */
function createDefaultValueOptionsForDropDown<
  S extends StrictRJSFSchema = RJSFSchema
>(
  enumOptions?: EnumOptionsType<S>[],
  enumDisabled?: UIOptionsType["enumDisabled"]
) {
  const disabledOptions = enumDisabled || [];
  const options =
    enumOptions &&
    enumOptions.map(({ label, value }, index) => ({
      disabled: disabledOptions.indexOf(value) !== -1,
      key: label,
      text: label,
      value: String(index),
    }));
  return options;
}

/** The `SelectWidget` is a widget for rendering dropdowns.
 * This custom SelectWidget was built upon this default one https://github.com/rjsf-team/react-jsonschema-form/blob/a87dee178743d286d797276466246db25ab47e37/packages/semantic-ui/src/SelectWidget/SelectWidget.tsx
 * The default one threw an error at SelectWidget.onFocus(...) handler
 * @param props - The `WidgetProps` for this component
 */
export function CustomSelectWidget<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>(props: WidgetProps<T, S, F>) {
  const {
    uiSchema,
    formContext,
    id,
    options,
    label,
    hideLabel,
    required,
    disabled,
    readonly,
    value,
    multiple,
    placeholder,
    autofocus,
    onChange,
    onBlur,
    onFocus,
    rawErrors = [],
  } = props;
  const semanticProps = getSemanticProps<T, S, F>({
    uiSchema,
    formContext,
    options,
    defaultSchemaProps: {
      inverted: "false",
      selection: true,
      fluid: true,
      scrolling: true,
      upward: false,
    },
  });
  const { enumDisabled, enumOptions, emptyValue: optEmptyVal } = options;
  const emptyValue = multiple ? [] : "";
  const dropdownOptions = createDefaultValueOptionsForDropDown<S>(
    enumOptions,
    enumDisabled
  );
  const _onChange = (
    _: SyntheticEvent<HTMLElement>,
    { value }: DropdownProps
  ) =>
    onChange(
      enumOptionsValueForIndex<S>(value as string[], enumOptions, optEmptyVal)
    );

  const _onBlur = (_: FocusEvent<HTMLElement>, { target }: DropdownProps) =>
    onBlur(
      id,
      enumOptionsValueForIndex<S>(
        // NOTE: The default SelectWidget threw an error here due to undefined value of "target"
        target && target.value,
        enumOptions,
        optEmptyVal
      )
    );

  const _onFocus = (_: FocusEvent<HTMLElement>, { target }: DropdownProps) =>
    onFocus(
      id,
      enumOptionsValueForIndex<S>(
        // NOTE: The default SelectWidget threw an error here due to undefined value of "target"
        target && target.value,
        enumOptions,
        optEmptyVal
      )
    );

  const selectedIndexes = enumOptionsIndexForValue<S>(
    value,
    enumOptions,
    multiple
  );

  return (
    <Form.Dropdown
      key={id}
      id={id}
      name={id}
      label={labelValue(label || undefined, hideLabel, false)}
      multiple={typeof multiple === "undefined" ? false : multiple}
      value={typeof value === "undefined" ? emptyValue : selectedIndexes}
      error={rawErrors.length > 0}
      disabled={disabled}
      placeholder={placeholder}
      {...semanticProps}
      required={required}
      autoFocus={autofocus}
      readOnly={readonly}
      options={dropdownOptions}
      onChange={_onChange}
      onBlur={_onBlur}
      onFocus={_onFocus}
      aria-describedby={ariaDescribedByIds<T>(id)}
    />
  );
}
