import React, { Dispatch, FC, SetStateAction, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Path } from "Routes";
import { ApiError } from "data/http";
import { InstalledApplicationResponse, metisApi } from "data/Metis";
import {
  DchModal,
  UIStatus,
  UIStatusWrapper,
  WarningMessage,
} from "components/shared";

type UninstallModalProps = {
  app: InstalledApplicationResponse;
  deleteModalOpen: boolean;
  setDeleteModalOpen: Dispatch<SetStateAction<boolean>>;
};

const ApplicationUninstallModal: FC<UninstallModalProps> = ({
  app,
  deleteModalOpen,
  setDeleteModalOpen,
}) => {
  const navigate = useNavigate();
  const [deleteStatus, setDeleteStatus] = useState(new UIStatus());

  const deleteApp = (): void => {
    setDeleteStatus((prevState) => prevState.setIndeterminate(true));

    metisApi
      .deleteApplication(app.id)
      .then((_) => {
        setDeleteStatus((prevState) => prevState.setIndeterminate(false));
        setDeleteModalOpen(false);
      })
      .then(() => navigate(Path.Applications))
      .catch((e: ApiError) =>
        setDeleteStatus((prevState) =>
          prevState.setError(
            e.message || "An error occurred when deleting the application"
          )
        )
      );
  };

  return (
    <DchModal
      header="Confirm Uninstall"
      content={
        <UIStatusWrapper status={deleteStatus} clearable={true}>
          <p>
            Are you sure you wish to delete <b>{app.name}</b>?
          </p>
          <WarningMessage content="This application cannot be recovered once deleted. It can be re-installed from the Application Marketplace if required." />
        </UIStatusWrapper>
      }
      open={deleteModalOpen}
      onClose={() => setDeleteModalOpen(false)}
      onConfirm={() => deleteApp()}
      confirmText="Uninstall"
      disableConfirm={deleteStatus.indeterminate}
      confirmLoading={deleteStatus.indeterminate}
    />
  );
};

export default ApplicationUninstallModal;
