/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import tw, { TwComponent } from "twin.macro";
import { CheckboxInput, PageSection } from "components/shared";
import { FormCheckboxInputProps } from "components/shared/Forms/Inputs";
import Form from "components/shared/Forms/ReactHookForm";
import { Grid3Col } from "../ComponentLibrary";
import { FormFields, InputsProps } from "./Inputs";

const FormCheckboxInputWrapper = ({
  formId,
  checkboxInputProps,
  InputBox,
  defaultValues,
}: {
  formId: string;
  checkboxInputProps: Omit<FormCheckboxInputProps<FormFields>, "name">;
  InputBox: TwComponent<"div">;
  defaultValues?: FormFields;
}) => {
  return (
    <div>
      <InputBox>
        <Form
          formId={formId}
          onSubmit={(data: FormFields) => console.log(data)}
          defaultValues={defaultValues}
        >
          <Form.CheckboxInput name="checked" {...checkboxInputProps} />
        </Form>
      </InputBox>
      <Button
        size="mini"
        inverted
        basic
        type="submit"
        form={formId}
        style={{ marginTop: ".5rem" }}
      >
        Submit
      </Button>
    </div>
  );
};

export const CheckboxInputs = ({ showBoundaries }: InputsProps) => {
  const InputBox = showBoundaries
    ? tw.div`border border-dashed border-core-grey`
    : tw.div`border border-solid border-transparent`;

  const [checked, setChecked] = useState(false);

  const getFormCheckboxInput = (
    formId: string,
    checkboxInputProps: Omit<FormCheckboxInputProps<FormFields>, "name">,
    defaultValues?: FormFields
  ) => {
    return (
      <FormCheckboxInputWrapper
        InputBox={InputBox}
        formId={formId}
        checkboxInputProps={checkboxInputProps}
        defaultValues={defaultValues}
      />
    );
  };

  return (
    <>
      <PageSection header="Basic">
        <Grid3Col>
          <div>
            <InputBox>
              <CheckboxInput
                value={checked}
                onChange={(_, { checked }) => setChecked(checked)}
              />
            </InputBox>
          </div>
          <div>
            <InputBox>
              <CheckboxInput
                checkboxLabel="With checkbox label"
                value={checked}
                onChange={(_, { checked }) => setChecked(checked)}
              />
            </InputBox>
          </div>
          <div>
            <InputBox>
              <CheckboxInput
                toggle={true}
                value={checked}
                onChange={(_, { checked }) => setChecked(checked)}
              />
            </InputBox>
          </div>
          <div>
            <InputBox>
              <CheckboxInput
                label="My Label"
                value={checked}
                onChange={(_, { checked }) => setChecked(checked)}
              />
            </InputBox>
          </div>
          <div>
            <InputBox>
              <CheckboxInput
                label="My Label"
                toggle
                value={checked}
                onChange={(_, { checked }) => setChecked(checked)}
              />
            </InputBox>
          </div>
          <div></div>
        </Grid3Col>
      </PageSection>
      <PageSection header="In a React Hook Form">
        <Grid3Col>
          <div>
            Basic
            {getFormCheckboxInput("FormCheckboxInput_1", {})}
          </div>
          <div>
            With default value = true (set at form level)
            {getFormCheckboxInput("FormCheckboxInput_2", {}, { checked: true })}
          </div>
          <div>
            With default value = true (set at field level)
            {getFormCheckboxInput("FormCheckboxInput_3", {
              defaultValue: true,
            })}
          </div>
          {getFormCheckboxInput("FormCheckboxInput_4", {
            label: "My Form Label",
          })}
          <div>
            As toggle
            {getFormCheckboxInput("FormCheckboxInput_5", {
              label: "My Form Label",
              toggle: true,
            })}
          </div>
        </Grid3Col>
      </PageSection>
    </>
  );
};
