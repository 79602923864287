import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { PointFields } from "../EntityForms";
import { useFieldArrayNodes } from "../useFieldArrayNodes";
import {
  WatcherProps,
  handleArrayToArrayChanges,
} from "./RelationshipWatcherUtils";

export const usePointsWatcher = (props: WatcherProps) => {
  const { isReadOnly, entityFieldId, entityIdentifier } = props;
  const { getValues, watch, setValue } = useFormContext();
  const { pointFields } = useFieldArrayNodes();
  const watchPoints = watch(`${entityIdentifier}.points`);

  useEffect(() => {
    if (!isReadOnly) {
      handleArrayToArrayChanges({
        entityFieldId,
        getValues,
        setValue,
        watchedField: watchPoints,
        existingTargetFields: pointFields.fields,
        targetNodeType: "point",
        targetProperty: "pointOf" as keyof PointFields,
      });
    }
  }, [
    entityFieldId,
    getValues,
    isReadOnly,
    pointFields.fields,
    setValue,
    watchPoints,
  ]);
};
