import {
  ClassificationDirectiveId,
  ClassificationDirectiveResponse,
  ClassificationPlanId,
  DesiredPointStates,
  DirectiveState,
  EntityPropertyKeyValue,
  EntityPropertyPutMutator,
  EntityPutMutator,
  MatchedPointState,
  ProposedDirectivePutRequest,
  StringPutMutator,
} from "data/Aletheia";
import { DirectiveOptionsKeys } from "./ClassificationPlanTableDefs";
import { getKeyFromId } from "../PointClassifierUtils";
import { DropdownItemProps } from "semantic-ui-react";

//hardcoded these values because theres only three options
export const directiveViewOptions: DropdownItemProps[] = [
  { value: DirectiveState.ALL, text: "All Points" },
  {
    value: DirectiveState.CHANGES_PENDING,
    text: "Points with Pending Changes",
  },
  {
    value: DirectiveState.MATCHES_NO_CHANGE,
    text: "Matching Points, no Pending Changes",
  },
];

/**
 * HELPERS
 */
export const convertDirectiveResponseToPutRequest = (
  planId: ClassificationPlanId,
  directiveId: ClassificationDirectiveId,
  directive: ClassificationDirectiveResponse
): ProposedDirectivePutRequest => {
  return {
    planId,
    directiveId,
    action: directive.action,
    proposedPointState: createPutMutators(directive),
  };
};

const createPutMutators = (directive: ClassificationDirectiveResponse) => {
  const createPutMutator = (
    value: string | string[] | EntityPropertyKeyValue[] | undefined
  ) =>
    value
      ? {
          value,
        }
      : undefined;

  return {
    class: createPutMutator(
      directive.proposedPointState.class?.userValue
    ) as StringPutMutator,
    entity: createPutMutator(
      directive.proposedPointState.entity?.userValue
    ) as EntityPutMutator, // not used current, will be when there is support for entities
    label: createPutMutator(
      directive.proposedPointState.label?.userValue
    ) as StringPutMutator,
    entityProperties: createPutMutator(
      directive.proposedPointState.entityProperties?.userValue
    ) as EntityPropertyPutMutator,
    unit: createPutMutator(
      directive.proposedPointState.unit?.userValue
    ) as StringPutMutator,
  };
};

export const handleDirectiveChange = (
  directive: ClassificationDirectiveResponse,
  key: DirectiveOptionsKeys,
  value: any
) => {
  const proposedDirectiveChange = {
    ...directive,
    proposedPointState: {
      ...directive.proposedPointState,
      [key]: {
        ...directive.proposedPointState[key as keyof DesiredPointStates],
        userValue: value,
        overridenByUser: true,
      },
    },
  };
  return proposedDirectiveChange;
};

export const displayRedCondition = (
  row: ClassificationDirectiveResponse,
  cellId: string
) => {
  const key = getKeyFromId(cellId);

  if (key === DirectiveOptionsKeys.entityProperties) return false;
  return !!(
    row.currentPointState[key as keyof MatchedPointState]?.currentValue &&
    row.proposedPointState[key as keyof DesiredPointStates]?.computedValue
  );
};

export const displayGreenCondition = (
  row: ClassificationDirectiveResponse,
  cellId: string
) => {
  const key = getKeyFromId(cellId);

  return !!(
    (row.currentPointState[key as keyof MatchedPointState]?.currentValue &&
      row.proposedPointState[key as keyof DesiredPointStates]?.computedValue) ||
    row.proposedPointState[key as keyof DesiredPointStates]?.userValue
  );
};

export const mkClassificationPlanEPFormId = (index: number | undefined) =>
  `classification-plan-EP-form-${index}`;
