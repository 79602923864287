/** @jsxImportSource @emotion/react */
import React from "react";

import tw from "twin.macro";
import { Select, Button, Icon } from "semantic-ui-react";
import { Table } from "@tanstack/react-table";
import { ClassificationDirectiveResponse, Classifier } from "data/Aletheia";
import { resultsPerPageOptions } from "components/shared";

/** 
 * Default page size: 10 (from the library)
 * To set default page size, declare the following when you create your useReactTable:
 *  initialState: {
        pagination: {
            pageSize: x,
        },
    }
 */
type PaginationProps = React.PropsWithChildren<{
  table: Table<Classifier> | Table<ClassificationDirectiveResponse>;
}>;

export const PointClassifierPagination: React.FC<PaginationProps> = ({
  table,
}) => {
  return (
    <div css={tw`flex w-full my-4 px-4`}>
      <span css={tw`flex items-center gap-1 float-left`}>
        <div>Page</div>
        <strong>
          {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
        </strong>
      </span>
      <div css={tw`text-center flex-1`}>
        <Button.Group basic inverted>
          <Button
            onClick={() => {
              table.setPageIndex(0);
            }}
          >
            <Icon name="angle double left" inverted />
          </Button>
          <Button
            disabled={!table.getCanPreviousPage()}
            onClick={() => {
              table.previousPage();
            }}
          >
            Prev
          </Button>
          <Button
            disabled={!table.getCanNextPage()}
            onClick={() => {
              table.nextPage();
            }}
          >
            Next
          </Button>
          <Button onClick={() => table.setPageIndex(table.getPageCount() - 1)}>
            <Icon name="angle double right" inverted />
          </Button>
        </Button.Group>
      </div>
      <Select
        css={tw`float-right`}
        style={{ wordWrap: "none !important" }}
        compact
        onChange={(e, data) => table.setPageSize(Number(data.value))}
        options={resultsPerPageOptions.map((option) => {
          return { text: option, value: option };
        })}
        value={table.getState().pagination.pageSize}
      />
    </div>
  );
};
