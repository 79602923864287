import { NodeConfig, EdgeConfig } from "@antv/g6";
import { ParentClass } from "data/EntityClasses/ParentClassHelper";
import { ClassHypernym } from "data/Mason";
import { BrickProperty } from "data/Models/Brick/brickRelationships";
import { ModelReference } from "data/QueryApi/queryTypes";
import { FullNodeIdentifier, VizModel } from "../VizModelUtil";
import { getClassNodeStyle, mixStyles } from "./NodeBadgeShape/StyleHelper";
type VizNode = {
  label: string;
  hypernym?: ClassHypernym;
  parentClass: ParentClass;
  relationships: number;
  subClass: string;
  modelReference: ModelReference;
} & NodeConfig;

export const getVisualisationModelFromDchModel = (vizModel: VizModel) => {
  //take the model that was returned from the QueryAPI, and convert it into the VizModel we need for the UI

  //take all locations, equipment and points and convert into nodes that define the class, parent class, and relationships
  let vizNodes: VizNode[] = [];
  vizNodes = [
    ...vizNodes,
    ...[...vizModel.nodes.entries()].map(
      ([id, node]) =>
        ({
          id,
          label: node.graphLabel,
          hypernym: node.class?.hypernym,
          parentClass: node.class?.parentClass,
          modelReference: node.modelReference,
          relationships:
            [...node.relationships.entries()].flatMap(
              ([_, r]: [BrickProperty, FullNodeIdentifier[]]) => r
            ).length ?? 0,
          subClass: node.class?.label,
        } as VizNode)
    ),
  ];
  //define all the relationships between nodes
  let edges: EdgeConfig[] = [];
  [...vizModel.nodes.entries()].forEach(([nodeId, node]) => {
    const relatedNodes = [...node.relationships.entries()].flatMap(
      ([_, nodeIdentifiers]) => nodeIdentifiers
    );
    edges = [
      ...edges,
      ...relatedNodes.map((relatedNode) => ({
        id: `${nodeId}_${relatedNode.nodeUri}_edge`,
        source: nodeId,
        target: relatedNode.nodeUri,
      })),
    ];
  });

  //for each node, add the custom styling for that class of node
  vizNodes = vizNodes.map((node) => {
    return {
      ...node,
      style: mixStyles(getClassNodeStyle(node.parentClass, node.hypernym), {
        label: { text: node.label },
        badge: {
          text: `${node.relationships}`,
        },
      }),
      modelReference: node.modelReference,
    };
  });

  return { nodes: vizNodes, edges };
};
