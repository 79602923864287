import { ClientEnv, HttpScheme } from "../data/utils";
import { none } from "fp-ts/lib/Option";
import { some } from "fp-ts/Option";

export const env: ClientEnv = {
  scheme:
    process.env.REACT_APP_BSL_USE_HTTPS === "true"
      ? HttpScheme.HTTPS
      : HttpScheme.HTTP,

  host: process.env.REACT_APP_BSL_HOST
    ? some(process.env.REACT_APP_BSL_HOST)
    : none,

  port: process.env.REACT_APP_BSL_PORT
    ? some(Number(process.env.REACT_APP_BSL_PORT))
    : none,

  baseUrl: process.env.REACT_APP_BSL_BASE_URL || "",
  headers: { "Content-Type": "application/json", Accept: "application/json" },
};

export const iamEnodiaEnv: ClientEnv = {
  scheme: HttpScheme.HTTPS,
  host: process.env.REACT_APP_IAM_ENODIA_HOST
    ? some(process.env.REACT_APP_IAM_ENODIA_HOST)
    : none,
  port: none,
  baseUrl: process.env.REACT_APP_IAM_ENODIA_BASE_URL || "",
  headers: { "Content-Type": "application/json", Accept: "application/json" },
  nullablePort: true,
};

export const aletheiaEnv: ClientEnv = {
  ...iamEnodiaEnv,
  baseUrl: process.env.REACT_APP_ALETHEIA_BASE_URL ?? "",
};

export const metisEnv: ClientEnv = {
  ...iamEnodiaEnv,
  baseUrl: process.env.REACT_APP_METIS_BASE_URL ?? "",
};

export const metisWSEnv: ClientEnv = {
  ...metisEnv,
  scheme:
    process.env.REACT_APP_METIS_USE_WSS === "true"
      ? HttpScheme.WSS
      : metisEnv.scheme,
  baseUrl:
    process.env.REACT_APP_METIS_WS_BASE_URL ??
    process.env.REACT_APP_METIS_BASE_URL + "/ws",
};

export const masonEnv: ClientEnv = {
  scheme: HttpScheme.HTTPS,
  host: process.env.REACT_APP_MASON_HOST
    ? some(process.env.REACT_APP_MASON_HOST)
    : none,
  port: process.env.REACT_APP_MASON_PORT
    ? some(Number(process.env.REACT_APP_MASON_PORT))
    : none,
  baseUrl: process.env.REACT_APP_MASON_BASE_URL ?? "",
  headers: { "Content-Type": "application/json", Accept: "application/json" },
  nullablePort: true,
};

export const chronosEnv: ClientEnv = {
  ...iamEnodiaEnv,
  baseUrl: process.env.REACT_APP_CHRONOS_BASE_URL ?? "",
};
