/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { DropdownItemProps, Grid, Icon, Popup } from "semantic-ui-react";
import tw from "twin.macro";
import { DchClass } from "data/EntityClasses/EntityClassesContext";
import { ClassHypernym } from "data/Mason";
import {
  EntityPropertyAttributeSchema,
  EntityPropertyDefinition,
  getAttributeValue,
  getEntityPropertyAttributes,
  getEntityPropertyDefinition,
  getPropertyNameFromDefinition,
  useEntityProperties,
} from "data/Mason/EntityProperties";
import { convertCamelCaseToTitleCase } from "components/shared";
import Form from "components/shared/Forms/ReactHookForm";
import { getFilteredOptionsList } from "components/SitesAndBuildings/Model/ModelUtils";
import { EntityPropertiesProps } from "./EntityProperties";
import { EntityPropertyAttribute } from "./EntityPropertyAttribute";

export type EntityProperty = {
  entityPropertyId: string; //this is the identifier without the schema, e.g. "netArea"
  schemaDefinition: string; //this is the format [schema]:[name], e.g. "brick:netArea"
  key?: string;
  value?: string | number;
  unit?: string;
  nested?: EntityProperty[];
  schema?: string;
};

export type EntityPropertyInputProps = {
  entityClasses: Map<ClassHypernym, Map<string, DchClass>>;
  propertyIndex: number;
  handleDelete: (_: number) => void;
} & EntityPropertiesProps;

/**
 * @deprecated - replaced by AletheiaEntityProperty.tsx
 * - View/edit models page still uses this type implementation.
 */
export const EntityPropertyInput = (props: EntityPropertyInputProps) => {
  const { formFieldName, index, propertyIndex, isReadOnly, verticalLayout } =
    props;
  const indexIdentifier = index !== undefined ? `.${index}` : "";
  const entityPropertyName = `${formFieldName}${indexIdentifier}.properties.${propertyIndex}`;

  const { entityPropertyDefinitions } = useEntityProperties();
  const { watch } = useFormContext();
  const [schema, setSchema] = useState<EntityPropertyDefinition>();
  const [attributes, setAttributes] = useState<EntityPropertyAttributeSchema[]>(
    [],
  );
  const watchSchemaDefinition = watch(`${entityPropertyName}.schemaDefinition`);
  const schemaIdRef = useRef("");

  //if we update the entity property type, or once the definitions are fetched, set the id, schema and attributes for the EP
  useEffect(() => {
    if (
      schemaIdRef.current !== watchSchemaDefinition &&
      entityPropertyDefinitions
    ) {
      const schemaDefinition = entityPropertyDefinitions.get(
        watchSchemaDefinition,
      );
      if (schemaDefinition) {
        schemaIdRef.current = watchSchemaDefinition;
        setSchema(schemaDefinition);
      }
      const entityPropertyAttributes = schemaDefinition
        ? getEntityPropertyAttributes(
            schemaDefinition.json_schema,
            schemaDefinition.schema,
          )
        : [];
      setAttributes(entityPropertyAttributes);
    }
  }, [watchSchemaDefinition, entityPropertyDefinitions]);

  return isReadOnly ? (
    <ReadOnlyEntityProperty
      entityPropertyName={entityPropertyName}
      attributes={attributes}
      schemaName={schema?.name}
      verticalLayout={verticalLayout}
      attributePrefix={entityPropertyName}
    />
  ) : (
    <EditableEntityProperty
      {...props}
      entityPropertyName={entityPropertyName}
      attributes={attributes}
      schema={schema}
      schemaIdRef={schemaIdRef.current}
    />
  );
};

const EditableEntityProperty = ({
  formFieldName,
  index,
  entityPropertyName,
  attributes,
  hypernym,
  parentClass,
  handleDelete,
  entityClasses,
  propertyIndex,
  schema,
  schemaIdRef,
}: EntityPropertyInputProps & {
  entityPropertyName: string;
  attributes: EntityPropertyAttributeSchema[];
  schema?: EntityPropertyDefinition;
  schemaIdRef: string;
}) => {
  const [options, setOptions] = useState<DropdownItemProps[]>([]);
  const { watch, setValue, getValues } = useFormContext();

  const { entityPropertiesList } = useEntityProperties();
  const entityProperty: EntityProperty = getValues(entityPropertyName);

  const indexIdentifier = index !== undefined ? `.${index}` : "";
  const watchType = watch(`${formFieldName}${indexIdentifier}.type`);

  // on first load and on type change, load the options accordingly
  useEffect(() => {
    if (entityPropertiesList.length > 0 && entityClasses.size > 0) {
      const type = watchType ?? parentClass;
      const filteredOptions =
        type && hypernym && hypernym !== ClassHypernym.Zone //Zone not currently supported
          ? getFilteredOptionsList(
              type,
              hypernym,
              entityPropertiesList,
              entityClasses,
            )
          : entityPropertiesList.map((ep) => ({ value: ep.id, text: ep.name }));
      setOptions(filteredOptions);
    }
  }, [entityClasses, entityPropertiesList, hypernym, parentClass, watchType]);

  const attributeSchemaDefinitionIdfRef = useRef(schemaIdRef);
  const watchSchemaDefinition = watch(`${entityPropertyName}.schemaDefinition`);
  useEffect(() => {
    if (
      schemaIdRef &&
      watchSchemaDefinition === schemaIdRef &&
      entityProperty.entityPropertyId ===
        getPropertyNameFromDefinition(schemaIdRef) &&
      !attributeSchemaDefinitionIdfRef.current
    ) {
      attributeSchemaDefinitionIdfRef.current = schemaIdRef;
    } else if (
      schemaIdRef &&
      watchSchemaDefinition === schemaIdRef &&
      ((attributeSchemaDefinitionIdfRef.current &&
        attributeSchemaDefinitionIdfRef.current !== schemaIdRef) ||
        (!entityProperty.entityPropertyId &&
          !attributeSchemaDefinitionIdfRef.current))
    ) {
      //reset attributes if watch, schemaref and schema id all match but attr ref does NOT (i.e. we've selected a new property type)
      const id = getPropertyNameFromDefinition(schemaIdRef);
      const nestedAttributes = attributes?.filter(
        (attr) => attr.nestedIndex !== undefined,
      );
      const newProperty: EntityProperty | undefined = schema
        ? {
            entityPropertyId: id,
            schemaDefinition: schema.id,
            schema: schema.schema,
            value: "",
            unit: "",
            key: "",
            nested: nestedAttributes.map((n) => ({
              entityPropertyId: n.id,
              schemaDefinition:
                n.schemaDefinition ??
                getEntityPropertyDefinition(n.id, schema.schema),
              schema: schema.schema,
              value: "",
            })),
          }
        : undefined;
      setValue(entityPropertyName, newProperty);
      attributeSchemaDefinitionIdfRef.current = schemaIdRef;
    }
  }, [
    attributes,
    entityProperty.entityPropertyId,
    entityPropertyName,
    schema,
    schemaIdRef,
    setValue,
    watchSchemaDefinition,
  ]);

  return (
    <Grid>
      <Grid.Row css={{ paddingBottom: "0 !important" }}>
        <Grid.Column width={14}>
          <Form.SelectInput
            name={`${entityPropertyName}.schemaDefinition`}
            label="Type"
            options={options}
            search
            verticalLayout
            required
            isClearable={false}
          />
        </Grid.Column>
        <Grid.Column textAlign="right" width={2}>
          <Popup
            inverted
            hoverable
            trigger={
              <Icon
                name="trash"
                onClick={() => handleDelete(propertyIndex)}
                style={{ cursor: "pointer" }}
              />
            }
            content="Delete this entity property"
            position="top left"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row verticalAlign="bottom" css={{ paddingTop: "0 !important" }}>
        {attributes.map((attribute) => (
          <Grid.Column
            key={`${entityPropertyName}_attribute_${attribute.id}`}
            width={5}
          >
            <EntityPropertyAttribute
              attribute={attribute}
              entityPropertyFieldName={entityPropertyName}
            />
          </Grid.Column>
        ))}
      </Grid.Row>
    </Grid>
  );
};

const ReadOnlyEntityProperty = ({
  entityPropertyName,
  attributes,
  schemaName,
  verticalLayout,
  attributePrefix,
}: {
  entityPropertyName: string;
  attributes: EntityPropertyAttributeSchema[];
  attributePrefix: string;
  schemaName?: string;
  verticalLayout?: boolean;
}) => {
  const propCss = verticalLayout ? tw`flex flex-col` : tw`flex flex-wrap`;
  const { getValues } = useFormContext();
  const entityProperty: EntityProperty = getValues(entityPropertyName);
  return (
    <div>
      <div css={tw`font-bold break-words pb-1`}>
        {schemaName ??
          convertCamelCaseToTitleCase(entityProperty.entityPropertyId)}
      </div>
      <div css={propCss}>
        {attributes.map((attr) => {
          const value = getAttributeValue(entityProperty, attr.id);
          const keyName = `${attributePrefix}_attribute_${attr.id}`;
          return value !== undefined ? (
            <div key={keyName} css={tw`max-w-full mr-4`}>
              <span css={tw`pr-2`}>{`${convertCamelCaseToTitleCase(
                attr.id,
              )}:`}</span>
              <span css={tw`min-w-0`}>{value}</span>
            </div>
          ) : (
            <React.Fragment key={keyName} />
          );
        })}
      </div>
    </div>
  );
};
