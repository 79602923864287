import G6, { Graph } from "@antv/g6";
import { addEventListeners } from "./Model/EventHelper";
import { layout } from "./Model/LayoutHelper";
import { getVisualisationModelFromDchModel } from "./Model/ModelHelper";
import { registerNodeShape } from "./Model/NodeBadgeShape/ShapeHelper";
import {
  defaultNodeStyle,
  defaultEdgeStyle,
  defaultNodeStateStyle,
  defaultEdgeStateStyle,
} from "./Model/NodeBadgeShape/StyleHelper";
import { VizModel } from "./VizModelUtil";
import { SelectedNode } from "./Visualisation";

export class G6GraphUtil {
  private _graph: Graph | undefined;

  get graph() {
    return this._graph;
  }

  set graph(graph: Graph | undefined) {
    this._graph = graph;
  }

  initialiseGraph = (
    container: HTMLDivElement,
    setSelectedNode: (_?: SelectedNode) => void
  ) => {
    registerNodeShape();
    this.graph = new G6.Graph({
      container: container,
      modes: {
        default: [
          "drag-canvas",
          "zoom-canvas",
          "drag-node",
          "activate-relations",
        ],
      },
      layout: layout,
      fitView: false,
      defaultNode: defaultNodeStyle,
      defaultEdge: defaultEdgeStyle,
      nodeStateStyles: defaultNodeStateStyle,
      edgeStateStyles: defaultEdgeStateStyle,
    });

    addEventListeners(this.graph, (selectedNode?: SelectedNode) => {
      setSelectedNode(selectedNode);
    });
  };

  setClickState = (selectedNodeId?: string) => {
    if (this.graph)
      if (selectedNodeId) {
        // set the selected node to click state true, and all others to false
        const graphNode = this.graph.findById(selectedNodeId);
        graphNode?.setState("click", true);
        this.graph
          .findAllByState("node", "click")
          .filter((n) => n.getID() !== selectedNodeId)
          .forEach((n) => {
            n.setState("click", false);
          });
      } else {
        const allNodes = this.graph.getNodes();
        allNodes.forEach((n) => n.setState("click", false));
      }
  };

  updateGraphData = (
    vizModel: VizModel | undefined,
    setSelectedPanelModel: (_: VizModel) => void,
    rerender: boolean = false
  ) => {
    if (this.graph && vizModel) {
      setSelectedPanelModel({ ...vizModel });
      const data = getVisualisationModelFromDchModel(vizModel);
      if (rerender) {
        this.graph.data(data);
        this.graph.render();
      } else {
        this.graph.changeData(data);
      }
    }
  };
}
