/** @jsxImportSource @emotion/react */

import React from "react";
import { Breadcrumb } from "semantic-ui-react";
import tw from "twin.macro";
import { SelectedElement } from "./ModelUtils";

const MAX_TO_DISPLAY = 5;

export type Breadcrumb = {
  nodeLabel: string;
  element: SelectedElement;
  deleted?: boolean;
};

type Props = {
  breadcrumbs: Array<Breadcrumb>;
  onClick: (index: number) => void;
};
export const Breadcrumbs: React.FunctionComponent<Props> = ({
  breadcrumbs,
  onClick,
}) => {
  const breadcrumbSections = breadcrumbs
    .map((crumb: Breadcrumb, index) => {
      const isActive = index === breadcrumbs.length - 1;
      return {
        key: `${crumb.element.type}_${crumb.element.id}_${index}`,
        content: (
          <div css={tw`flex flex-col items-center text-sm px-4`}>
            <span
              css={
                crumb.deleted ? tw`text-core-grey pb-2` : tw`text-light pb-2`
              }
            >
              {`${crumb.deleted ? "Deleted " : ""}${crumb.element.type}`}
            </span>
            <span css={tw`text-core-grey`} className="name">
              {crumb.nodeLabel}
            </span>
          </div>
        ),
        onClick: () => {
          if (!isActive && !crumb.deleted) onClick(index);
        },
        active: isActive,
      };
    })
    .slice(
      Math.max(breadcrumbs.length - MAX_TO_DISPLAY, 0),
      breadcrumbs.length
    );

  return (
    <React.Fragment>
      {breadcrumbs.length > MAX_TO_DISPLAY && (
        <span css={tw`text-core-grey`}>. . .</span>
      )}
      <Breadcrumb icon="angle right" sections={breadcrumbSections} />
    </React.Fragment>
  );
};
