import { useState } from "react";
import { debounce } from "throttle-debounce";
import { masonApi } from "data/Mason";
import { EntityPropertyDefinition, useEntityPropertiesContext } from ".";

export enum FetchStatus {
  Pending,
  Fetching,
  Complete,
  Error,
}

export const useEntityProperties = () => {
  const {
    entityPropertyDefinitions,
    setEntityPropertyDefinitions,
    entityPropertiesList,
    setEntityPropertiesList,
  } = useEntityPropertiesContext();

  const [fetchingListStatus, setFetchingListStatus] = useState<FetchStatus>(
    FetchStatus.Pending
  );
  const [fetchingEPDefinitions, setFetchingEPDefinitions] = useState<
    Array<string>
  >([]);
  const [errorEPs, setErrorEPs] = useState<Array<string>>([]);

  const fetchEntityPropertiesList = () => {
    const debouncedFetch = debounce(500, () => {
      masonApi
        .getEntityProperties()
        .then((res) => {
          setEntityPropertiesList(res.properties);
          setFetchingListStatus(FetchStatus.Complete);
        })
        .catch(() => {
          setFetchingListStatus(FetchStatus.Error);
        });
    });

    if (
      fetchingListStatus === FetchStatus.Pending &&
      entityPropertiesList.length === 0
    ) {
      setFetchingListStatus(FetchStatus.Fetching);
      debouncedFetch();
    }
  };

  const fetchEntityPropertyDefinitions = (
    propertyDefinitions: Array<string>
  ) => {
    const propertyDefinitionsToFetch = [
      ...new Set(
        propertyDefinitions.filter(
          (def) =>
            !fetchingEPDefinitions.includes(def) && !errorEPs.includes(def)
        )
      ),
    ];
    if (propertyDefinitionsToFetch.length > 0) {
      const fetchedEPs: Array<EntityPropertyDefinition> = [];
      setFetchingEPDefinitions((prev) => [
        ...prev,
        ...propertyDefinitionsToFetch,
      ]);
      //fetch the necessary ones one by one and add to this array
      const entityPropertyPromises = propertyDefinitionsToFetch.map((def) =>
        masonApi.getEntityProperty(def).then(
          (res) => fetchedEPs.push(res),
          () => {
            console.error("error fetching entity property definition for", def);
            setErrorEPs((prev) => [...new Set([...prev, def])]);
          }
        )
      );
      Promise.all(entityPropertyPromises)
        .then(() => {
          const newMap: Map<string, EntityPropertyDefinition> = new Map(
            fetchedEPs.map((ep) => [ep.id, ep])
          );
          setEntityPropertyDefinitions(
            new Map([...entityPropertyDefinitions, ...newMap])
          );
        })
        .then(() =>
          setFetchingEPDefinitions((prev) =>
            prev.filter((def) => !propertyDefinitionsToFetch.includes(def))
          )
        );
    }
  };

  return {
    fetchEntityPropertiesList,
    entityPropertiesList,
    fetchingListStatus,
    entityPropertyDefinitions,
    fetchEntityPropertyDefinitions,
    fetchingEPDefinitions,
    errorEPs,
  };
};
