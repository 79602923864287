/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  OnChangeFn,
  RowSelectionState,
  Updater,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Classifier, ClassifierMode } from "data/Aletheia";
import { defaultColumn, defaultColumns } from "./ClassifierSetTableDefs";
import {
  ClassifierSetMetadata,
  handleClassifierChange,
  toOptionsDCHJsonClasses,
  toOptionsUnits,
  toggleAllColumnsVisible,
} from ".";
import { useSkipper } from "../PointClassifierUtils";
import { DCHJsonClassResponse, Unit } from "data/Mason";
import { PointClassifierPagination } from "../PointClassifierPagination";
import Form, {
  FormMethods,
  FormStateValues,
} from "components/shared/Forms/ReactHookForm";
import { UIStatus } from "components/shared";
import ClassifierSetFormTable, {
  ClassifierSetFormFields,
} from "./ClassifierSetFormTable";

export const CLASSIFIER_TABLE_FORM_ID = "classifier-table-form";

type ClassifierSetTableProps = {
  currentClassifierSet: ClassifierSetMetadata;
  setClassifierSet: Dispatch<SetStateAction<ClassifierSetMetadata>>;
  classifierHighlightIndex?: number;
  setCanSave: Dispatch<SetStateAction<boolean>>;
  schemaClassesList: DCHJsonClassResponse[];
  unitsList: Unit[];
  setUIStatus: React.Dispatch<React.SetStateAction<UIStatus>>;
  setFormMethods: React.Dispatch<
    React.SetStateAction<FormMethods<ClassifierSetFormFields> | undefined>
  >;
  setFormState: React.Dispatch<
    React.SetStateAction<FormStateValues<ClassifierSetFormFields> | undefined>
  >;
};

export const ClassifierSetTable: React.FunctionComponent<
  ClassifierSetTableProps
> = ({
  currentClassifierSet,
  setClassifierSet,
  classifierHighlightIndex,
  setCanSave,
  schemaClassesList,
  unitsList,
  setUIStatus,
  setFormMethods,
  setFormState,
}) => {
  const [columns] = useState<typeof defaultColumns>(defaultColumns);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnPinning, setColumnPinning] = useState({});
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();

  useEffect(() => {
    currentClassifierSet.classifiers.forEach((classifier, index) => {
      setRowSelection((original) => {
        original[index] = classifier.enabled;
        return original;
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClassifierSet.classifiers]);

  const updateData = useCallback(
    (mode: ClassifierMode, rowIndex: number, columnKey: string, value: any) => {
      // Skip page index reset until after next rerender
      skipAutoResetPageIndex();
      setCanSave(true);
      setClassifierSet((p) => ({
        ...p,
        classifiers: p.classifiers.map((c, index) => {
          if (index === rowIndex) {
            return handleClassifierChange(c, columnKey, value, mode);
          }
          return c;
        }),
      }));
    },
    [setCanSave, setClassifierSet, skipAutoResetPageIndex]
  );

  const onRowSelectionChange: OnChangeFn<RowSelectionState> = (
    update: Updater<RowSelectionState>
  ) => {
    let newValue = typeof update === "function" ? update(rowSelection) : update;
    setRowSelection(newValue);
    const rowsToUpdateAction = getRowsToUpdate(rowSelection, newValue);
    skipAutoResetPageIndex();
    setClassifierSet((previousState) => {
      rowsToUpdateAction.forEach(
        ([index, enabled]) =>
          (previousState.classifiers[Number(index)].enabled = enabled)
      );
      return previousState;
    });
    setCanSave(true);
  };

  const table = useReactTable<Classifier>({
    data: currentClassifierSet.classifiers as Classifier[],
    columns,
    state: {
      columnVisibility,
      columnPinning,
      rowSelection,
    },
    enableRowSelection: true,
    onRowSelectionChange: onRowSelectionChange,
    onColumnVisibilityChange: setColumnVisibility,
    onColumnPinningChange: setColumnPinning,
    defaultColumn,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getRowCanExpand: () => true,
    getExpandedRowModel: getExpandedRowModel(),
    enablePinning: true,
    autoResetPageIndex,
    meta: {
      updateProposedChange: () => {},
      updateData: updateData,
      classOptions: schemaClassesList.map(toOptionsDCHJsonClasses),
      setActivePosition: () => {},
      unitsOptions: unitsList.map(toOptionsUnits),
    },
  });

  // initialise the grouping for the table on load
  useEffect(() => {
    table.getAllLeafColumns().forEach((col) => {
      col.id.includes("apply") ? col.pin("right") : col.pin("left");
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // on first load, toggle all visibility
  useEffect(() => {
    toggleAllColumnsVisible(table);
  }, [table]);

  // TODO: think of a more optimal way of setting this
  useEffect(() => {
    table.getRowModel().rows.forEach((row) => {
      // in theory, there is only one cell that matches "select", so we use .find()
      const idCell = row
        .getVisibleCells()
        .find((cell) => cell.id.includes("select"));
      if (idCell) row.toggleSelected((idCell.getValue() as boolean) ?? false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div css={tw`my-4`}>
      <Form<ClassifierSetFormFields>
        formId={CLASSIFIER_TABLE_FORM_ID}
        resetOnSubmit
        defaultValues={{
          classifiers: currentClassifierSet.classifiers.map((c) => ({
            enabled: c.enabled,
            executeRules: { ...c.executeRules },
            filterBy: { ...c.filterBy },
          })),
        }}
        setFormMethods={(methods) => setFormMethods(methods)}
        setFormState={(state) => setFormState(state)}
        onSubmit={() => {}}
      >
        <ClassifierSetFormTable
          classifiers={currentClassifierSet.classifiers as Classifier[]}
          table={table}
          setClassifierSet={setClassifierSet}
          classifierHighlightIndex={classifierHighlightIndex}
          setCanSave={setCanSave}
          unitsList={unitsList}
          setUIStatus={setUIStatus}
        />
        <PointClassifierPagination table={table} />
      </Form>
    </div>
  );
};

function getRowsToUpdate(
  oldSelection: RowSelectionState,
  newSelection: RowSelectionState
): [string, boolean][] {
  const removedFromNewSelection = Object.entries(oldSelection)
    .filter(([index]) => newSelection[index] === undefined)
    .map(([key, _]): [string, boolean] => {
      return [key, false];
    });
  const existsButNotSameValue = Object.entries(newSelection).filter(
    ([index, value]) => oldSelection[index] !== value
  );
  return existsButNotSameValue.concat(removedFromNewSelection);
}
