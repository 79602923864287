import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FieldValues } from "react-hook-form";
import { generatePath, useNavigate } from "react-router-dom";
import { BuildingId, ModelRootType, SiteId } from "data/brick";
import { ApiError, ApiErrorType } from "data/http";
import {
  masonApi,
  BuildingMetaResponse,
  BaseMeta,
  BuildingPutRequest,
  BuildingGetRequest,
  SiteGetRequest,
  SiteMetaResponse,
  SitePutRequest,
  Coordinates,
} from "data/Mason";
import {
  fetchMetadata,
  transformMetaToFormFields,
  transformMetadataFormToBuildingMeta,
  transformMetadataFormToSiteMeta,
} from "data/Models/Metadata/ModelMetadataUtils";
import { Path } from "Routes";
import {
  DchModal,
  PageMode,
  PageSection,
  UIStatus,
  UIStatusWrapper,
} from "components/shared";
import Form, {
  FormMethods,
  FormStateValues,
} from "components/shared/Forms/ReactHookForm";
import { ModelProps } from "../Model/ModelUtils";
import { AddressDetails, LandParcel, SiteOrBuildingDetails } from ".";

export const SITE_OR_BUILDING_METADATA_FORM = "SiteOrBuildingMetadataForm";

export interface AddressFormFields {
  unit?: string;
  streetNumber?: string;
  streetName?: string;
  city?: string;
  state?: string;
  country?: string;
  postCode?: string;
  propertyId?: string;
  cadastralId?: string;
  titleId?: string;
}

export interface SiteOrBuildingMetadataFormFields extends FieldValues {
  siteId: SiteId;
  buildingId?: BuildingId;
  name?: string;
  description?: string;
  address?: AddressFormFields;
  location?: Coordinates;
}

const CancelButton = ({
  modelType,
  showModal,
  setShowModal,
  onSubmit,
}: {
  modelType: ModelRootType;
  showModal?: boolean;
  setShowModal?: (_: boolean) => void;
  onSubmit?: (e?: any) => void;
}) => (
  <>
    <DchModal
      header={`Abort ${modelType} creation`}
      content={<p>Are you sure you wish to stop {modelType} creation?</p>}
      open={!!showModal}
      onConfirm={onSubmit}
      onClose={() => setShowModal?.(false)}
      confirmText="Confirm"
    />
    <Form.Button
      submit={false}
      label="Cancel"
      icon="cancel"
      onClick={() => setShowModal?.(true)}
    />
  </>
);

export type SiteOrBuildingMetadataProps = ModelProps & {
  pageMode: PageMode;
  isBuilding: boolean;
  setHasUnsavedChanges: (_: boolean) => void;
  setReset?: (_: () => void) => void;
  setResetField?: (_: (name: string) => void) => void;
  setFormSiteValue?: (_: string) => void;
  setHasLoadedMeta?: (_: boolean) => void;
  formRef?: React.RefObject<HTMLFormElement>;
  setPageError: Dispatch<SetStateAction<ApiError | undefined>>;
  setPageStatus: Dispatch<SetStateAction<UIStatus>>;
};

export const SiteOrBuildingMetadata: React.FunctionComponent<
  SiteOrBuildingMetadataProps
> = (props) => {
  const {
    orgId,
    siteId,
    buildingId,
    modelType,
    isBuilding,
    pageMode,
    setHasUnsavedChanges,
    setReset,
    setResetField,
    setFormSiteValue,
    setHasLoadedMeta,
    formRef,
    setPageError,
    setPageStatus,
  } = props;

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [navigationPath, setNavigationPath] = useState<string>();
  const [uiStatus, setUiStatus] = useState(new UIStatus());
  const [buildingIds, setBuildingIds] = useState<BuildingId[]>([]);

  const [formState, setFormState] =
    useState<FormStateValues<SiteOrBuildingMetadataFormFields>>();
  const [formMethods, setFormMethods] =
    useState<FormMethods<SiteOrBuildingMetadataFormFields>>();

  const navigate = useNavigate();

  const watchSiteValue = formMethods?.watch("siteId");
  useEffect(
    () => setFormSiteValue?.((watchSiteValue as string) ?? undefined),
    [setFormSiteValue, watchSiteValue],
  );

  // DCH-4593: Adds navigation path state to allow for waiting for the form state to resolve before navigating
  useEffect(
    () => {
      navigationPath && navigate(navigationPath);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigationPath],
  );

  // Set data fetched from the api
  useEffect(() => {
    if (pageMode !== PageMode.create && orgId && siteId && formMethods) {
      setUiStatus((prevState) => prevState.setIndeterminate(true));
      setPageStatus((prevState) => prevState.setIndeterminate(true));
      (
        fetchMetadata({ orgId, siteId, buildingId, modelType }) as Promise<
          BuildingMetaResponse | SiteMetaResponse
        >
      ).then(
        (metadata: BuildingMetaResponse | SiteMetaResponse) => {
          setHasLoadedMeta?.(true);
          setUiStatus((prevState) => prevState.setIndeterminate(false));
          setPageStatus((prevState) => prevState.setIndeterminate(false));
          formMethods?.reset(
            transformMetaToFormFields(metadata as BaseMeta, isBuilding),
          );
          isBuilding ||
            masonApi
              .getBuildings({
                orgId: orgId,
                siteId: siteId,
              } as SiteGetRequest)
              .then((res) => {
                setBuildingIds(res.map((it) => it.buildingId) ?? []);
              });
        },
        (err: ApiError) => {
          setPageStatus((prevState) => prevState.setIndeterminate(false));
          if (
            err.type === ApiErrorType.ResourceNotFound ||
            err.type === ApiErrorType.Unauthorized
          ) {
            setPageError(err);
          }
          setUiStatus((prevState) =>
            prevState.setError(
              err.message ||
                "An error occurred while gathering metadata for the building",
            ),
          );
        },
      );
    }
  }, [
    pageMode,
    orgId,
    siteId,
    formMethods,
    isBuilding,
    buildingId,
    modelType,
    setHasLoadedMeta,
    setPageError,
    setPageStatus,
  ]);

  const handleSubmit = (formValues: SiteOrBuildingMetadataFormFields) => {
    setUiStatus((prevState: UIStatus) => prevState.setIndeterminate(true));

    const params = {
      orgId: orgId,
      siteId: formValues.siteId,
      buildingId: formValues.buildingId,
    };

    const meta = isBuilding
      ? transformMetadataFormToBuildingMeta(orgId, formValues)
      : transformMetadataFormToSiteMeta(orgId, formValues);

    const putFunction = isBuilding
      ? () =>
          masonApi.putBuilding(params as BuildingGetRequest)(
            meta as BuildingPutRequest,
          )
      : () =>
          masonApi.putSite(params as SiteGetRequest)(meta as SitePutRequest);

    putFunction().then(
      () => {
        setUiStatus((prevState: UIStatus) => prevState.setIndeterminate(false));
        formMethods?.reset(formValues);
        setHasUnsavedChanges(false);
        setNavigationPath(
          isBuilding && formValues.buildingId
            ? generatePath(Path.ViewBuilding, {
                orgId: orgId,
                siteId: formValues.siteId,
                buildingId: formValues.buildingId,
              })
            : generatePath(Path.ViewSite, {
                orgId: orgId,
                siteId: formValues.siteId,
              }),
        );
      },
      (err: ApiError) => {
        setUiStatus((prevState: UIStatus) => prevState.setError(err.message));
      },
    );
  };

  return (
    <Form<SiteOrBuildingMetadataFormFields>
      formId={SITE_OR_BUILDING_METADATA_FORM}
      formRef={formRef}
      onSubmit={(data: SiteOrBuildingMetadataFormFields) => handleSubmit(data)}
      setFormState={(state) => {
        setFormState(state);
        setHasUnsavedChanges(state.isDirty);
      }}
      setFormMethods={(methods) => {
        setFormMethods(methods);
        setReset?.(() => methods.reset());
        setResetField?.((name: string) => methods.resetField(name));
      }}
    >
      <UIStatusWrapper status={uiStatus}>
        {pageMode === PageMode.create && (
          <CancelButton
            modelType={modelType}
            showModal={showCancelModal}
            setShowModal={setShowCancelModal}
            onSubmit={() => {
              setShowCancelModal(false);
              navigate(-1);
            }}
          />
        )}
        <Form.Button
          submit
          disabled={!formState?.isValid || !formState.isDirty}
          loading={uiStatus.indeterminate}
          icon="save"
          label={`${
            pageMode === PageMode.create ? "Create" : "Save"
          } ${modelType} Information`}
        />
        <SiteOrBuildingDetails
          pageMode={pageMode}
          modelType={modelType}
          isBuildingDetails={isBuilding}
          buildingIds={buildingIds}
        />
        {!isBuilding && (
          <>
            <AddressDetails
              orgId={orgId}
              isBuilding={isBuilding}
              isReadOnly={pageMode === PageMode.view}
            />

            <PageSection header="Land Parcel Details">
              <LandParcel isReadOnly={pageMode === PageMode.view} />
            </PageSection>
          </>
        )}
      </UIStatusWrapper>
    </Form>
  );
};
