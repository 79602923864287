import { Icon } from "semantic-ui-react";
import * as React from "react";

export const SITE_ICON_NAME = "map marker alternate";
export const BUILDING_ICON_NAME = "building";

export const getSiteOrBuildingIconName = (isBuilding: boolean) =>
  isBuilding ? BUILDING_ICON_NAME : SITE_ICON_NAME;

export const SiteOrBuildingIcon: React.FC<{ isBuilding: boolean }> = (
  props
) => (
  <Icon
    name={props.isBuilding ? BUILDING_ICON_NAME : SITE_ICON_NAME}
    color="purple"
    inverted
  />
);
