import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { WingFields, FloorFields, RoomFields } from "../EntityForms";
import { useFieldArrayNodes } from "../useFieldArrayNodes";
import {
  WatcherProps,
  handleArrayToArrayChanges,
} from "./RelationshipWatcherUtils";

export const useLocationsWatcher = (props: WatcherProps) => {
  const { isReadOnly, entityFieldId, entityIdentifier } = props;
  const { getValues, watch, setValue } = useFormContext();
  const { wingFields, floorFields, roomFields } = useFieldArrayNodes();
  const watchLocations = watch(`${entityIdentifier}.locations`);
  useEffect(() => {
    if (!isReadOnly) {
      handleArrayToArrayChanges({
        entityFieldId,
        getValues,
        setValue,
        watchedField: watchLocations,
        existingTargetFields: wingFields.fields,
        targetNodeType: "wing",
        targetProperty: "locationOf" as keyof WingFields,
      });
      handleArrayToArrayChanges({
        entityFieldId,
        getValues,
        setValue,
        watchedField: watchLocations,
        existingTargetFields: floorFields.fields,
        targetNodeType: "floor",
        targetProperty: "locationOf" as keyof FloorFields,
      });
      handleArrayToArrayChanges({
        entityFieldId,
        getValues,
        setValue,
        watchedField: watchLocations,
        existingTargetFields: roomFields.fields,
        targetNodeType: "room",
        targetProperty: "locationOf" as keyof RoomFields,
      });
    }
  }, [
    entityFieldId,
    floorFields.fields,
    getValues,
    isReadOnly,
    roomFields.fields,
    setValue,
    watchLocations,
    wingFields.fields,
  ]);
};
