import * as O from "fp-ts/lib/Option";
import { httpDelete, httpPost, httpPut } from "data/httpUtil";
import { ClientEnv } from "data/utils";
import { VendorDetailsResponse, VendorUid } from "./vendorTypes";
import { metisEnv } from "reducers/env";
import { PostVendorRequest, PutVendorRequest } from "./manageVendorTypes";

export type ManageVendorApi = {
  postVendor: (
    postVendorRequest: PostVendorRequest
  ) => Promise<VendorDetailsResponse>;
  deleteVendor: (vendorUid: VendorUid) => Promise<void>;
  putVendor: (
    vendorUid: VendorUid
  ) => (putVendorRequest: PutVendorRequest) => Promise<void>;
};

const mkManageVendorApi = (env: ClientEnv): ManageVendorApi => ({
  postVendor: (postVendorRequest: PostVendorRequest) =>
    httpPost(postVendorRequest)(O.none)(env)(null)(`/manage/vendors`).then(
      (r) => r as VendorDetailsResponse
    ),
  deleteVendor: (vendorUid: VendorUid) =>
    httpDelete(O.none)(env)(null)(`/manage/vendors/${vendorUid}`).then(
      (_) => undefined
    ),
  putVendor: (vendorUid: VendorUid) => (putVendorRequest: PutVendorRequest) =>
    httpPut(putVendorRequest)(O.none)(env)(null)(
      `/manage/vendors/${vendorUid}`
    ).then((_) => undefined),
});

export const manageVendorApi = mkManageVendorApi(metisEnv);
