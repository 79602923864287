/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Grid } from "semantic-ui-react";
import {
  DchModal,
  FormMethods,
  FormStateValues,
  UIStatus,
} from "components/shared";
import { ActionButton } from "components/Tools/PointClassifier/ActionButton";
import { EnodiaOrgContext } from "App";
import {
  ClassificationPlanCreateRequest,
  ClassifierSetCreateRequest,
  ClassifierSetId,
  ClassifierSetListItem,
  DataPoolCompositeId,
  pointClassifierApi,
} from "data/Aletheia";
import {
  ClassifierSearchMode,
  ClassifierSetSearchState,
} from "./ClassifierSetSearchInput";
import {
  ClassifierSetMetadata,
  clearedClassifierSet,
  removeClassifierEmptyFields,
} from "./ClassifierSetMetadataUtils";
import { ClassifierSetFormFields } from "./ClassifierSetFormTable";
import { PointClassifierPageState } from "../usePointClassifierPageState";
import { CLASSIFIER_TABLE_FORM_ID } from "./ClassifierSetTable";
import { OrgId } from "data/Enodia";

type ActionButtonProps = {
  classifierSetMetadata: ClassifierSetMetadata;
  setClassifierSetMetadata: React.Dispatch<
    React.SetStateAction<ClassifierSetMetadata>
  >;
  selectedDataPool: DataPoolCompositeId | undefined;
  setShowResults: React.Dispatch<React.SetStateAction<boolean>>;
  classifierSetSearch: ClassifierSetSearchState;
  setUIStatus: React.Dispatch<React.SetStateAction<UIStatus>>;
  canSave: boolean;
  setCanSave: React.Dispatch<React.SetStateAction<boolean>>;
  setClassifierSetSearch: React.Dispatch<
    React.SetStateAction<ClassifierSetSearchState>
  >;
  getClassifierSets: (orgId?: OrgId) => Promise<void | ClassifierSetListItem[]>;
  setCanPurgeClassifiers: Dispatch<SetStateAction<boolean>>;
  formMethods: FormMethods<ClassifierSetFormFields> | undefined;
  formState: FormStateValues<ClassifierSetFormFields> | undefined;
  pageState: PointClassifierPageState;
};

enum ActionMode {
  Reset,
  ClearAll,
}

export const ClassifierSetActionButtons: React.FunctionComponent<
  ActionButtonProps
> = ({
  classifierSetMetadata,
  setClassifierSetMetadata,
  selectedDataPool,
  setShowResults,
  classifierSetSearch,
  setUIStatus,
  canSave,
  setCanSave,
  setClassifierSetSearch,
  getClassifierSets,
  setCanPurgeClassifiers,
  pageState,
  formMethods,
  formState,
}) => {
  const { orgId } = useContext(EnodiaOrgContext);
  const [actionMode, setActionMode] = useState<ActionMode>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setCanSave(!!(formState?.isDirty && formState?.isValid));
  }, [formState?.isDirty, formState?.isValid, setCanSave]);

  /**
   * HELPERS
   */
  const getClassifierCreatePayload = () => {
    return (
      formMethods
        ?.getValues()
        .classifiers.map((formClassifier) =>
          removeClassifierEmptyFields(formClassifier),
        ) ?? []
    );
  };

  /**
   * ON-CLICK BUTTON ACTIONS
   */
  const onReset = () => {
    setModalOpen(false);
    setShowResults(false);
  };

  const onConfirmPurge = () => {
    // api to create a new classifier
    // then use that info to initalise new classifier set
    if (classifierSetSearch.id && orgId) {
      setUIStatus((p) => p.setIndeterminate(true));
      pointClassifierApi
        .purgeAllClassifiers(classifierSetSearch.id)
        .then(() => {
          setUIStatus((p) => p.setIndeterminate(false));
          setCanPurgeClassifiers(false);
          setClassifierSetMetadata(
            clearedClassifierSet(
              orgId,
              classifierSetSearch.id,
              classifierSetSearch.name,
            ),
          );
        })
        .then(() => setModalOpen(false))
        .then(() => setShowResults(false))
        .catch((e) => setUIStatus((p) => p.setError(e.message)));
    }
  };

  const onSaveProgress = () => {
    setUIStatus((p) => p.setIndeterminate(true));

    if (classifierSetSearch.id) {
      putClassifierSetPromise();
    } else if (
      classifierSetSearch.mode === ClassifierSearchMode.create &&
      classifierSetSearch.name
    ) {
      setUIStatus((p) => p.setIndeterminate(true));
      pointClassifierApi
        .postClassifierSet({
          name: classifierSetSearch.name,
          classifiers: getClassifierCreatePayload(),
          organisationId: orgId,
        } as ClassifierSetCreateRequest)
        .then((r) => {
          setClassifierSetSearch((p) => ({
            ...p,
            id: r.id,
            mode: ClassifierSearchMode.search,
          }));
          setClassifierSetMetadata(r);
        })
        .then(() => {
          setUIStatus((p) => p.setIndeterminate(false));
          getClassifierSets();
        })
        .catch((e) => {
          setUIStatus((p) => p.setError(e.message));
        });
    }
  };

  const onGenerateRules = () => {
    if (pageState.state.datapoolId && orgId && classifierSetSearch.id) {
      setIsLoading(true);
      // automatically save and process the rules if user didnt save the classifier set preemtively
      if (canSave) {
        putClassifierSetPromise()
          ?.then(deletePreviousPlan)
          .then(generateRulesPromise)
          .catch((e) => setUIStatus((p) => p.setError(e.message ?? e)));
      } else {
        deletePreviousPlan().then(() => generateRulesPromise());
      }
    }
  };

  /**
   * PROMISES & API CALLS
   */
  const putClassifierSetPromise = () => {
    return (
      classifierSetSearch.id &&
      pointClassifierApi
        .putClassifierSet(classifierSetSearch.id)({
          name: classifierSetMetadata.name ?? classifierSetSearch.name,
          classifiers: getClassifierCreatePayload(),
        })
        .then(() => {
          setUIStatus((p) => p.setIndeterminate(false));
        })
        .catch((e) => {
          setUIStatus((p) => p.setError(e.message));
        })
    );
  };

  // only delete if there is a cached planid of previously generated plan
  // otherwise, pass through this promise
  const generateRulesPromise = () => {
    if (pageState.state.datapoolId && orgId && classifierSetSearch.id) {
      const request: ClassificationPlanCreateRequest = {
        planScope: [pageState.state.datapoolId], // only support for one datapool right now, so its hardwrapped in an array for now
        organisationId: orgId,
        classifierSetId: classifierSetSearch.id as ClassifierSetId,
      };
      return pointClassifierApi
        .postClassificationPlan(request)
        .then((res) => {
          pageState.setPlanId(res.id);
          setShowResults(true);
          setIsLoading(false);
        })
        .catch((e) => {
          setUIStatus((p) => p.setError(e.message));
        });
    }
    return Promise.reject(
      "No datapool ID, orgID or Classifier Set ID was found ",
    );
  };

  const deletePreviousPlan = () => {
    if (pageState.state.classificationPlanId)
      return pointClassifierApi.deleteClassificationPlan(
        pageState.state.classificationPlanId,
      );

    console.warn(
      "no classificationPlanId discovered, hence previous plan was not deleted",
    );
    // we put resolve because it is not crucial the previous plan is deleted
    return Promise.resolve();
  };

  return (
    <React.Fragment>
      <Grid.Row css={[tw` flex justify-between`]}>
        <Grid.Column>
          {/* <ActionButton
            label="Reset"
            icon="refresh"
            onClick={() => {
              setActionMode(ActionMode.Reset);
              setModalOpen(true);
            }}
            basic
          /> */}
          {classifierSetSearch.id && (
            <ActionButton
              label="Delete All"
              icon="trash"
              onClick={() => {
                setActionMode(ActionMode.ClearAll);
                setModalOpen(true);
              }}
              basic
            />
          )}
        </Grid.Column>
        <Grid.Column>
          <ActionButton
            disabled={
              !!(
                !classifierSetSearch.name &&
                classifierSetSearch.mode === ClassifierSearchMode.create
              ) ||
              !!(
                !classifierSetSearch.id &&
                classifierSetSearch.mode === ClassifierSearchMode.search
              ) ||
              !canSave
            }
            label="Save Progress"
            icon="save"
            onClick={onSaveProgress}
          />
          <ActionButton
            type="submit"
            form={CLASSIFIER_TABLE_FORM_ID}
            testId="generate_rules_button"
            disabled={
              !(
                selectedDataPool &&
                orgId &&
                classifierSetSearch.id &&
                classifierSetMetadata.classifiers.length > 0
              ) || !formState?.isValid
            }
            label={canSave ? "Save & Generate Rules" : "Generate Rules"}
            onClick={onGenerateRules}
            isLoading={isLoading}
            noStyle
          />
        </Grid.Column>
      </Grid.Row>
      <DchModal
        header="Confirmation"
        content={
          <p>
            Are you sure you wish to{" "}
            {actionMode === ActionMode.Reset ? "reset" : "delete all"} your
            classifiers?
            {actionMode === ActionMode.ClearAll &&
              " Deleting all classifier is irreversible."}
          </p>
        }
        onConfirm={() =>
          actionMode === ActionMode.Reset ? onReset() : onConfirmPurge()
        }
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        confirmText="Confirm"
      />
    </React.Fragment>
  );
};
