/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import { LatLng } from "leaflet";
import { useFormContext } from "react-hook-form";
import { MapContainer, useMap } from "react-leaflet";
import { Grid } from "semantic-ui-react";
import {
  LOCATION_ZOOM,
  DEFAULT_LAT_LONG,
  DEFAULT_ZOOM,
  CommonTileLayer,
  DraggableMarker,
} from ".";

export const LocationMap = ({ markerTitle }: { markerTitle?: string }) => {
  const [manualZoom, setManualZoom] = useState(false);

  const { setValue, watch } = useFormContext();
  const watchLat = watch("location.latitude");
  const watchLng = watch("location.longitude");

  const [latLng, setLatLng] = useState(
    watchLat && watchLng ? new LatLng(watchLat, watchLng) : undefined
  );

  useEffect(() => {
    setLatLng(
      watchLat && watchLng ? new LatLng(watchLat, watchLng) : undefined
    );
  }, [watchLat, watchLng]);

  return (
    <Grid.Row>
      <Grid.Column>
        <MapContainer
          center={latLng ?? DEFAULT_LAT_LONG}
          zoom={latLng ? LOCATION_ZOOM : DEFAULT_ZOOM}
          scrollWheelZoom={false}
          css={{ height: "55vh", width: "100%" }}
        >
          <MapSetup
            latLng={latLng}
            manualZoom={manualZoom}
            setManualZoom={setManualZoom}
          />
          <CommonTileLayer />
          <DraggableMarker
            title={markerTitle}
            pos={latLng}
            draggable={true}
            onCoordinatesChange={(l) => {
              setValue("location.latitude", l.lat, { shouldDirty: true });
              setValue("location.longitude", l.lng, { shouldDirty: true });
              setManualZoom(true);
            }}
          />
        </MapContainer>
      </Grid.Column>
    </Grid.Row>
  );
};

const MapSetup = ({
  latLng,
  manualZoom,
  setManualZoom,
}: {
  latLng: LatLng | undefined;
  manualZoom: boolean;
  setManualZoom: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const map = useMap();

  useEffect(() => {
    if (latLng) {
      const zoom = manualZoom ? map.getZoom() : LOCATION_ZOOM;
      if (manualZoom) setManualZoom(false);
      map.setView(latLng, zoom);
    } else {
      map.setView(DEFAULT_LAT_LONG, DEFAULT_ZOOM);
    }
  }, [latLng, map, manualZoom, setManualZoom]);

  return null;
};
