/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { FieldValues } from "react-hook-form";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import tw from "twin.macro";
import { Path } from "Routes";
import {
  manageVendorApi,
  PutVendorRequest,
  vendorApi,
  VendorDetailsResponse,
  VendorUid,
} from "data/Metis";
import { isEmail, isValidUrl } from "data/validation";
import {
  Form,
  FormStateValues,
  Page,
  PageTitle,
  UIStatus,
  UIStatusWrapper,
} from "components/shared";

type Params = { vendorUid: string };

export interface EditVendorFormFields extends FieldValues {
  vendorName: string;
  description: string;
  contactEmail: string;
  website: string;
  overview: string;
}

export const EditVendorDetails: React.FC<{}> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams<keyof Params>() as Params;
  const [vendor, setVendor] = useState<VendorDetailsResponse>();
  const [status, setStatus] = useState<UIStatus>(new UIStatus());

  const [formState, setFormState] =
    useState<FormStateValues<EditVendorFormFields>>();

  // setVendor based on whether its passed from previous router state
  useEffect(() => {
    if (location.state && location.state.vendor) {
      setVendor(location.state.vendor);
    } else {
      setStatus((prev) => prev.setIndeterminate(true));
      vendorApi
        .getVendor(params.vendorUid as VendorUid)
        .then((res) => {
          setVendor(res);
          setStatus((prev) => prev.setIndeterminate(false));
          return res.uid;
        })
        .then(() => setStatus((p) => p.setIndeterminate(false)))
        .catch((e) => setStatus((prev) => prev.setError(e.message)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (formData: EditVendorFormFields) => {
    if (vendor) {
      const updateRequest: PutVendorRequest = {
        organisationId: vendor?.organisationId,
        organisationUid: vendor?.organisationUid,
        name: formData.vendorName,
        description: formData.description,
        overview: formData.overview,
        email: formData.contactEmail,
        website: formData.website,
      };
      manageVendorApi
        .putVendor(vendor.uid)(updateRequest)
        .then(() => {
          navigate(
            generatePath(Path.ViewVendorDetails, { vendorUid: vendor.uid })
          );
        })
        .catch((e) => setStatus((p) => p.setError(e.message)));
    } else {
      setStatus((p) =>
        p.setError(
          "Cannot submit updated vendor information. No vendor metadata was found."
        )
      );
    }
  };

  return (
    <Page hasGoBack backTo={location.state?.["backTo"]}>
      <PageTitle
        primaryHeader="Applications Marketplace"
        secondaryHeader={`Edit Vendor: ${vendor?.name}`}
      />

      <UIStatusWrapper status={status}>
        {vendor && (
          <div css={tw`flex flex-col gap-4 bg-core-grey-dark rounded p-4`}>
            <Form<EditVendorFormFields>
              setFormState={(state) => setFormState(state)}
              defaultValues={{
                vendorName: vendor.name,
                description: vendor.description,
                contactEmail: vendor.email,
                website: vendor.website,
                overview: vendor.overview,
              }}
              onSubmit={onSubmit}
            >
              <Form.TextInput
                required
                verticalLayout
                name="vendorName"
                label="Vendor Name"
              />
              <Form.TextInput
                verticalLayout
                name="description"
                label="Description"
              />
              <Form.TextInput
                required
                verticalLayout
                name="contactEmail"
                label="Contact Email"
                rules={{
                  required: "Email is required",
                  validate: isEmail,
                }}
              />
              <Form.TextInput
                required
                verticalLayout
                name="website"
                label="Website"
                rules={{
                  validate: isValidUrl,
                }}
              />
              <Form.MarkdownInput
                verticalLayout
                name="overview"
                label="Overview"
                defaultView="edit"
              />

              <div css={tw`flex flex-row justify-end mt-4`}>
                <Form.Button
                  submit={false}
                  label="Cancel"
                  icon="close"
                  onClick={() => {
                    navigate(-1);
                  }}
                />
                <Form.Button
                  submit
                  label="Save Vendor Information"
                  icon="save"
                  disabled={!formState?.isDirty}
                />
              </div>
            </Form>
          </div>
        )}
      </UIStatusWrapper>
    </Page>
  );
};
