/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { Button } from "semantic-ui-react";
import tw from "twin.macro";
import { Markdown } from "components/shared/Components/Markdown";
import { PageMode } from "components/shared";
import defaultMarkdown from "./Markdown.md";

export const MarkdownComponent = () => {
  const [markdown, setMarkdown] = useState<string | undefined>("");
  const [pageMode, setPageMode] = useState(PageMode.view);

  useEffect(() => {
    fetch(defaultMarkdown)
      .then((res) => res.text())
      .then((text) => setMarkdown(text));
  }, []);

  const saveMarkdown = () => {
    console.info("markdown changed to", markdown);
    setPageMode(PageMode.view);
  };

  return (
    <div css={tw`flex flex-col items-end`}>
      <div
        className="markdown-container"
        data-color-mode="dark"
        css={tw`my-4 w-full`}
      >
        <Markdown
          markdown={markdown}
          setMarkdown={setMarkdown}
          isReadOnly={pageMode === PageMode.view}
        />
      </div>
      {pageMode === PageMode.edit ? (
        <div>
          <Button basic inverted onClick={() => setPageMode(PageMode.view)}>
            Cancel
          </Button>
          <Button primary onClick={saveMarkdown}>
            Save Markdown
          </Button>
        </div>
      ) : (
        <Button primary onClick={() => setPageMode(PageMode.edit)}>
          Edit Markdown
        </Button>
      )}
    </div>
  );
};
