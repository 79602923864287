import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { ApiError } from "data/http";
import { ClassHypernym, DCHJsonClassResponse, masonApi } from "data/Mason";
import Form from "components/shared/Forms/ReactHookForm";
import { LabelValue, SEARCH_OPTION_INPUT_WIDTHS } from "./Common";
import { SearchParamsValues } from "./SearchForm";
import { SearchOption } from "./SearchOption";
import { IncludeSubclassesCheckbox } from "./IncludeSubclassesCheckbox";

const POINT_FIELD_NAME: keyof SearchParamsValues = "points";
const SUBCLASSES_FIELD_NAME: keyof SearchParamsValues =
  "includePointsSubclasses";

export const PointsSelectInput = ({
  setError,
}: {
  setError: (_: string) => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [pointsOptions, setPointsOptions] = useState<
    Array<LabelValue<DCHJsonClassResponse>>
  >([]);

  const { setValue, watch } = useFormContext();
  const currentlySelectedPoints: string[] = watch(POINT_FIELD_NAME);

  //on load, fetch point types
  useEffect(() => {
    const fetchPointTypes = async () => {
      setIsLoading(true);
      masonApi
        .getSchemaTypes({ hypernym: ClassHypernym.Point })
        .then(
          (res) => {
            setPointsOptions(
              res
                .map((a: DCHJsonClassResponse) => ({
                  value: a,
                  label: a.label,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
            );
          },
          (e: ApiError) =>
            setError(
              e.message || `Could not fetch ${ClassHypernym.Point} types`,
            ),
        )
        .finally(() => setIsLoading(false));
    };
    fetchPointTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const input = (
    <Form.SelectInput
      name={POINT_FIELD_NAME}
      label="Restrict to these types of points"
      placeholder="Select point types ..."
      noOptionsMessage="No point types found."
      options={pointsOptions.map((o) => ({
        value: o.value.type,
        text: o.label,
      }))}
      isMulti
      isLoading={isLoading}
      search
      inputWidths={SEARCH_OPTION_INPUT_WIDTHS}
    />
  );

  return (
    <SearchOption
      input={input}
      rightCheckboxes={[
        <IncludeSubclassesCheckbox
          key={SUBCLASSES_FIELD_NAME}
          fieldName={SUBCLASSES_FIELD_NAME}
          isLoading={isLoading}
          currentlySelectedClasses={currentlySelectedPoints}
          options={pointsOptions}
          updateValues={(subclasses: string[]) => {
            setValue(
              POINT_FIELD_NAME,
              Array.from(new Set(currentlySelectedPoints.concat(subclasses))),
            );
          }}
        />,
      ]}
    />
  );
};
