import { Option } from "fp-ts/lib/Option";
import * as O from "fp-ts/lib/Option";
import { ClientEnv, Headers, SenapsClientEnv } from "./utils";
import {
  Json,
  validateStructureValidationReport,
  ValidationReport,
} from "data/Models/Brick/BrickValidationUtils";
import * as E from "fp-ts/lib/Either";

export enum ApiErrorType {
  BadRequest = "BadRequest",
  ValidationReport = "ValidationReport",
  AuthenticationRequired = "AuthenticationRequired",
  Unauthorized = "Unauthorized",
  ServerError = "ServerError",
  Unknown = "Unknown",
  Conflict = "Conflict",
  ResourceNotFound = "ResourceNotFound",
}
export const errorMsg = (o: any) => o?.title || o?.message;

type ApiErrorT<t extends ApiErrorType, e> = {
  type: t;

  // This provides a common field that the UI components can use to display error messages
  message: string;

  error: e;
};
const mkApiError =
  <T extends ApiErrorType, e>(t: T) =>
  (e: e, message: string): ApiErrorT<T, e> => ({
    type: t,
    message,
    error: e,
  });

type BadRequestError = ApiErrorT<ApiErrorType.BadRequest, string>;

const mkBadRequestError = (e: string) =>
  mkApiError(ApiErrorType.BadRequest)(e, e);

type ValidationReportError = ApiErrorT<
  ApiErrorType.ValidationReport,
  ValidationReport
>;
const mkValidationReportError = (e: ValidationReport) =>
  mkApiError(ApiErrorType.ValidationReport)(
    e,
    e.results.map((r) => r.message).join("\n"),
  );

type AuthenticationRequiredError = ApiErrorT<
  ApiErrorType.AuthenticationRequired,
  string
>;

const mkAuthenticationRequiredError = (e: string) =>
  mkApiError(ApiErrorType.AuthenticationRequired)(e, e);

type UnauthorizedError = ApiErrorT<ApiErrorType.Unauthorized, string>;

const mkUnauthorizedError = (e: string) =>
  mkApiError(ApiErrorType.Unauthorized)(e, e);

type UnknownError = ApiErrorT<ApiErrorType.Unknown, string>;
const mkUnknownError = (e: string) => mkApiError(ApiErrorType.Unknown)(e, e);

const mkServerError = (e: string) => mkApiError(ApiErrorType.ServerError)(e, e);

type ResourceNotFoundError = ApiErrorT<ApiErrorType.ResourceNotFound, string>;
const mkResourceNotFoundError = (e: string) =>
  mkApiError(ApiErrorType.ResourceNotFound)(e, e);

type ConflictError = ApiErrorT<ApiErrorType.Conflict, string>;
const mkConflictError = (e: string) => mkApiError(ApiErrorType.Conflict)(e, e);

export type ApiError =
  | BadRequestError
  | ValidationReportError
  | AuthenticationRequiredError
  | UnauthorizedError
  | UnknownError
  | ResourceNotFoundError
  | ConflictError;

const decodeError =
  (r: Response) => (onText: string) => (other: string) => () =>
    r.text().then(
      (a) => Promise.reject(mkUnknownError(`${onText}: ${a}`)), // the error is some sort of text
      (_) => Promise.reject(mkUnknownError(other)), // no idea what the hell this is.
    );

const unknownJson =
  (r: Response) =>
  <a>(j: JSON): Promise<a> =>
    Promise.reject(
      mkUnknownError(
        `Unknown JSON returned with HTTP ${r.status}:\n${JSON.stringify(j)}`,
      ),
    );

export const defaultTryDecode = (r: Response) => {
  let ret: Promise<string>;
  const rc = r.clone();
  try {
    ret = r.json().then(
      (v) => v,
      () => (ret = rc.text()),
    );
  } catch {
    console.error("Could not decode JSON, returning string...");
    ret = r.text();
  }
  return ret;
};

export const decodeResponse = <T>(
  response: Response,
  passRawRes: boolean,
  decodeFunc?: (input: Response) => Promise<T>,
): Promise<DecodedResponse<T>> => {
  const decode = decodeFunc || defaultTryDecode;
  if (passRawRes) return decode(response);
  // statuses from https://tools.ietf.org/html/rfc7231#section-6.1
  switch (response.status) {
    case 200: // OK
      return decode(response);
    case 201: // Created
      return decode(response);
    case 202: // Accepted
      return decode(response);
    case 203: // Non-Authorative
      return decode(response);
    case 204: // No Content
      return Promise.resolve(null);
    case 205: // Reset Content
      return Promise.resolve(null);
    case 206: // Partial Content
      return decode(response);
    case 400:
      return interpretErrorResponse(
        response,
        (json) =>
          errorMsg(json)
            ? Promise.reject(mkBadRequestError(errorMsg(json)))
            : unknownJson(response)(json),
        decodeError(response)("Unexpected error")(
          "Unable to decode JSON from 400 response",
        ),
      );
    case 401:
      return interpretErrorResponse(
        response,
        (json) =>
          errorMsg(json)
            ? Promise.reject(mkAuthenticationRequiredError(errorMsg(json)))
            : unknownJson(response)(json),
        decodeError(response)("Unexpected error")(
          "Unable to decode JSON from 401 response",
        ),
      );
    case 403:
      return interpretErrorResponse(
        response,
        (json) =>
          errorMsg(json)
            ? Promise.reject(mkUnauthorizedError(errorMsg(json)))
            : unknownJson(response)(json),
        decodeError(response)("Unexpected error")(
          "Unable to decode JSON from 403 response",
        ),
      );
    case 404:
      return interpretErrorResponse(
        response,
        (json) => {
          return Promise.reject(mkResourceNotFoundError(errorMsg(json)));
        },
        (r) =>
          Promise.reject(
            mkResourceNotFoundError(
              `Unable to decode JSON from the ${r.status} response`,
            ),
          ),
      );
    case 409:
      return interpretErrorResponse(
        response,
        (json) => {
          return Promise.reject(mkConflictError(errorMsg(json)));
        },
        decodeError(response)(ApiErrorType.Conflict)(
          "Unable to decode JSON from 409 response",
        ),
      );
    case 422:
      return interpretErrorResponse(
        response,
        (a: Json) =>
          E.fold(
            (_) =>
              Promise.reject(mkUnknownError(`Unknown error report:\n${a}`)), // unknown json
            (a: ValidationReport) => Promise.reject(mkValidationReportError(a)), // our error response is well formed
          )(validateStructureValidationReport(a)),
        decodeError(response)("Unexpected error")("Unknown error"),
      );
  }

  if (response.status >= 500 && response.status < 600) {
    return interpretErrorResponse(
      response,
      (json) => {
        return errorMsg(json)
          ? Promise.reject(mkServerError(errorMsg(json)))
          : unknownJson(response)(json);
      },
      (r) =>
        Promise.reject(
          mkServerError(`Unable to decode JSON from the ${r.status} response`),
        ),
    );
  }

  return interpretErrorResponse(
    response,
    (json) => {
      if (errorMsg(json)) {
        return Promise.reject(mkUnknownError(errorMsg(json)));
      } else {
        return unknownJson(response)(json);
      }
    },
    decodeError(response)(`Unhandled HTTP return code ${response.status}`)(
      `Unhandled HTTP return code ${response.status}`,
    ),
  );
};

export const mkUrl = <a extends object>(
  env: ClientEnv,
  url: string,
  params: a | null,
): string => {
  const name = O.getOrElse(() => window.location.hostname)(env.host);
  const port = O.fold(
    () =>
      env.nullablePort
        ? ""
        : window.location.port === ""
          ? ""
          : `:${window.location.port}`,
    (p) => `:${p}`,
  )(env.port);
  const host = `${env.scheme}://${name}${port}`;
  // don't put a ? when there are no params
  const mkParams = (p: a): string => {
    const l = Object.entries(p)
      .filter(([_, v]) => v != null)
      .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
      .join("&");
    return l.length === 0 ? "" : `?${l}`;
  };
  return `${host}${env.baseUrl}${url}${params == null ? "" : mkParams(params)}`;
};

export const mkBearer =
  (t: string) =>
  (e: number): O.Option<string> =>
    e < new Date().getTime() ? O.none : O.some(`Bearer ${t}`);

export const mkBasic = (e: string) => (p: string) =>
  `Basic ${btoa(`${e}:${p}`)}`;

const authHeaders = (auth: Option<UserPass>): Headers => {
  const basic: Headers = O.fold(
    () => ({}),
    (a: UserPass) => ({ Authorization: mkBasic(a.user)(a.pass) }),
  )(auth);
  return { ...basic };
};

const mkHeaders = (env: SenapsClientEnv, auth: Option<UserPass>) => ({
  ...env.headers,
  ...authHeaders(auth),
});

export type UserPass = { user: string; pass: string };
const rawHttp =
  (method: string) =>
  (init: object) =>
  <p extends object | null>(auth: Option<UserPass>) =>
  (env: ClientEnv) =>
  (params: p) =>
  (url: string): Promise<Response> =>
    window.fetch(mkUrl(env, url, params), {
      method,
      credentials: "same-origin",
      headers: mkHeaders(env, auth),
      redirect: "follow",
      ...init,
    });

export type DecodedResponse<T> = T | ApiError | string | null;

/** @deprecated- use httpUtil.ts file as successor */
const http =
  <T>(decodeResponseFunc?: (input: Response) => Promise<T>) =>
  (rawError?: boolean) =>
  (method: string) =>
  (init: object) =>
  <p extends object | null>(auth: Option<UserPass>) =>
  (env: ClientEnv) =>
  (params: p) =>
  (url: string): Promise<DecodedResponse<T>> =>
    rawHttp(method)(init)(auth)(env)(params)(url).then((r) =>
      decodeResponse<T>(r, rawError || false, decodeResponseFunc),
    );

/** @deprecated - please refer to httpUtil.ts file for the updated version*/
export const httpGet = http()()("GET")({});
/** @deprecated */
export const httpPost = <a>(data: a) =>
  http()()("POST")({ body: JSON.stringify(data) });

/**
 * @param parseDefaultExpected expected json format response we get from error responses
 * @param onParseError callback to handle text/plain responses, or however the dev wants to handle unhandled, non json cases
 */
function interpretErrorResponse(
  response: Response,
  parseDefaultExpected: (json: any) => Promise<DecodedErrorResponseType>,
  onParseError: (r?: any) => Promise<never>,
): Promise<DecodedResponse<DecodedErrorResponseType>> {
  const contentType = response.headers.get("content-type");
  if (contentType && contentType.indexOf("application/json") !== -1) {
    return response.json().then((json) => {
      return parseDefaultExpected(json);
    });
  }
  // if text plain, have it handled by decode error
  return onParseError(response);
}

export type DecodedErrorResponseType =
  | string
  | BadRequestError
  | ValidationReportError
  | AuthenticationRequiredError
  | UnauthorizedError
  | UnknownError
  | ResourceNotFoundError
  | ConflictError
  | null;
