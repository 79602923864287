/** @jsxImportSource @emotion/react */ import tw from "twin.macro";
import { ApplicationVersionBriefResponse } from "data/Metis";
import { MarkdownInput, formatDate } from "components/shared";

export const ApplicationReleaseNotes = ({
  appVersions,
}: {
  appVersions: ApplicationVersionBriefResponse[];
}) => {
  return (
    <>
      {appVersions.map((version) => {
        return (
          <div key={version.id} css={tw`mb-4`} data-test-id="release-notes">
            <h4 css={tw`m-0`}>{`v ${version.version}`}</h4>
            <p css={tw`m-0`}>
              <em>{formatDate(version.uploadTime, "do MMM yyyy, HH:mm")}</em>
            </p>
            <MarkdownInput
              value={
                version.changeLog && version.changeLog !== ""
                  ? version.changeLog
                  : "No release notes provided."
              }
              isReadOnly
            />
          </div>
        );
      })}
    </>
  );
};
