import * as O from "fp-ts/lib/Option";
import { httpGet } from "data/httpUtil";
import { ClientEnv } from "data/utils";
import {
  GetVendorsSearchParams,
  VendorDetailsResponse,
  VendorUid,
  generateVendorSearchParamsQueryString,
} from "./vendorTypes";
import { metisEnv } from "reducers/env";

export type VendorApi = {
  getVendor: (vendorUid: VendorUid) => Promise<VendorDetailsResponse>;
  getVendors: (
    getVendorsSearchParams: GetVendorsSearchParams
  ) => Promise<VendorDetailsResponse[]>;
};

const mkVendorApi = (env: ClientEnv): VendorApi => ({
  getVendor: (vendorUid: VendorUid) =>
    httpGet(O.none)(env)(null)(`/vendors/${vendorUid}`).then(
      (r) => r as VendorDetailsResponse
    ),
  getVendors: (getVendorsSearchParams: GetVendorsSearchParams) =>
    httpGet(O.none)(env)(null)(
      `/vendors?${generateVendorSearchParamsQueryString(
        getVendorsSearchParams
      )}`
    ).then((r) => r as VendorDetailsResponse[]),
});

export const vendorApi = mkVendorApi(metisEnv);
