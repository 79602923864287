/** @jsxImportSource @emotion/react */

import * as React from "react";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import { Link } from "react-router-dom";
import { DropdownItemProps, Icon, List, Button } from "semantic-ui-react";
import tw, { TwStyle } from "twin.macro";
import { Path } from "Routes";
import { compareString } from "./utils/string-utils";

export const toOptionTypeBase = function (
  a: boolean | number | string
): DropdownItemProps {
  return { value: a, text: a };
};
// sorting logic for dropdown selection lists
export const orderAlphabetically = (
  a: DropdownItemProps,
  b: DropdownItemProps
) => compareString(a.text as string, b.text as string);

export const labelOrId = (id: string, label: O.Option<string>): string =>
  O.getOrElse(() => id)(
    O.chain((string: string) =>
      string.trim() === "" ? O.none : O.some(string.trim())
    )(label)
  );

export const getVocabularyNameFromUri = (uri: string) => {
  const splitUri = uri.split("/");
  const uriName = splitUri[splitUri.length - 1];
  return uriName.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/[-_]/g, " ");
};

export const quickMenuItem = (path: Path, label: string) => {
  return (
    <List.Item css={tw`mx-4`}>
      <Button primary as={Link} to={path} css={tw`w-full`}>
        <Icon name="add" />
        {label}
      </Button>
    </List.Item>
  );
};

export const externalLinkTo = (
  externalLink: string,
  label: string,
  style?: TwStyle
) => {
  return (
    <Button
      href={externalLink}
      target="_blank"
      rel="noopener noreferrer"
      primary
      css={style}
    >
      <Icon name="external" />
      {label}
    </Button>
  );
};

// gather all resolved promises, drop ones with errors.
export function reducePromises<a>(l: Array<Promise<a>>): Promise<Array<a>> {
  const f = (z: Promise<Array<a>>, a: Promise<a>): Promise<Array<a>> =>
    a.then(
      (r) => z.then((rr) => rr.concat([r])),
      (_) => z
    );
  return A.reduce(Promise.resolve<Array<a>>([]), f)(l);
}
