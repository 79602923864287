import { OrgId } from "data/Enodia";
import { SiteId } from "data/brick";

export * from "./Site/SiteTypes";
export * from "./Building/BuildingTypes";

/**
 * DATA POOL
 */

/**
 * GEOLOCATION
 */

/**
 * SCHEMA
 */
export enum ClassHypernym {
  Location = "Location",
  Equipment = "Equipment",
  Point = "Point",
  Zone = "Zone",
  Collection = "Collection",
}

export type SchemaTypeRequestParams = {
  hypernym?: ClassHypernym;
  parent?: string; // default "all"
  depth?: number; // default 0
};

export type DCHJsonClassResponse = {
  type: string;
  parents: string[];
  label: string;
};

export type SchemaUnitRequestParams = {
  id?: string;
  name?: string;
  skip?: string;
  limit?: number;
};

export type SchemaUnitsResponse = {
  units: Array<Unit>;
  count: number;
  totalCount: number;
  prevPage: string;
  nextPage: string;
};

export type Unit = {
  id: string;
  iri: string;
  name?: string;
  quantityKinds: string[];
};
/**
 * SEARCH
 */

/**
 * ORGANISATION
 */
export type OrganisationGetRequest = {
  orgId: OrgId;
};

/**
 * COMMON METADATA
 */
export type OrgIdentifierParam = {
  orgId: OrgId;
};

export type BaseMeta = {
  orgId: OrgId;
  siteId: SiteId;
  label?: string;
  description?: string;
};

export type AddressMetaResponse = {
  subAddress?: string;
  streetNumber?: string;
  streetName?: string;
  subLocality?: string;
  locality?: string;
  subAdministrativeArea?: string;
  administrativeArea?: string;
  countryCode?: string;
  postcode?: string;
  propertyId?: string;
  cadastralId?: string;
  titleId?: string;
};

export type ModelStats = {
  point_count?: number;
};

export type Coordinates = {
  longitude: number;
  latitude: number;
};
