/** @jsxImportSource @emotion/react */

import * as React from "react";
import { Button, Container, Icon, Table } from "semantic-ui-react";
import tw from "twin.macro";
import { StreamId } from "data/senaps";
import { toOptionTypeBase } from "components/shared/Common";
import { SelectInput } from "components/shared";
import { PointDataModal } from "components/Search/PointDataModal";
import { toFixed } from "./Export";
import { dataHealthHelper } from "./InfoBoxes";

export const OutageTable: React.FC<{
  namespace: string;
  outageStreams: Array<string>;
  outageDataHealth: Record<string, number>;
  addToInspector?: Function;
}> = (props) => {
  const [displayedStreams, setDisplayedStreams] = React.useState<Array<string>>(
    []
  );

  const [searchTerm, setSearchTerm] = React.useState<string>();

  React.useEffect(() => {
    setDisplayedStreams(() => props.outageStreams);
  }, [props.outageStreams]);

  React.useEffect(() => {
    if (searchTerm === undefined) {
      setDisplayedStreams(props.outageStreams);
    } else {
      setDisplayedStreams(
        props.outageStreams.filter((x) =>
          x.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [searchTerm, props.outageStreams]);

  const generateTable = React.useMemo(
    () =>
      props.outageStreams.length === 0 ? (
        <i>No outage streams found</i>
      ) : (
        <Table inverted celled basic verticalAlign="top">
          <Table.Header css={tw`mt-0 pt-0`}>
            <Table.Row css={tw`sticky top-0 z-10 bg-purple-700`}>
              <Table.HeaderCell>
                <SelectInput
                  label="ID"
                  placeholder="Search streams..."
                  value={searchTerm}
                  options={props.outageStreams.map(toOptionTypeBase)}
                  onChange={(value) => {
                    setSearchTerm(value as string);
                  }}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                Most Recent Data Health {dataHealthHelper()}
              </Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {displayedStreams.length === 0 ? (
              <Table.Row key="empty">
                <Table.Cell>
                  <i>No outage streams found</i>
                </Table.Cell>
              </Table.Row>
            ) : (
              displayedStreams
                .sort(
                  (a, b) =>
                    props.outageDataHealth[
                      props.namespace + "." + a + ".stream.metrics"
                    ] -
                    props.outageDataHealth[
                      props.namespace + "." + b + ".stream.metrics"
                    ]
                )
                .map((x, ii) => (
                  <Table.Row key={ii}>
                    <Table.Cell>
                      <PointDataModal
                        id={x as StreamId}
                        buttonLabel={x as StreamId}
                      />
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      {toFixed(
                        props.outageDataHealth[
                          props.namespace + "." + x + ".stream.metrics"
                        ],
                        0,
                        "-"
                      )}
                      %
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        primary
                        fluid
                        icon
                        labelPosition="right"
                        onClick={() => {
                          if (props.addToInspector) {
                            props.addToInspector(x);
                          }
                        }}
                      >
                        Add stream to inspector <Icon name="plus" />
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                ))
            )}
          </Table.Body>
        </Table>
      ),
    [props, displayedStreams, searchTerm]
  );

  return (
    <Container fluid>
      <div
        className="dch-scrollbar"
        css={tw`overflow-y-scroll`}
        style={{ maxHeight: "40vh" }}
      >
        {generateTable}
      </div>
    </Container>
  );
};
