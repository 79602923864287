import G6, {
  ShapeStyle,
  IGroup,
  IShape,
  ModelConfig,
  NodeConfig,
} from "@antv/g6";
import { mixStyles } from "./StyleHelper";
import {
  NodeBadgeShapeStyle,
  KeyShapeStyle,
  HaloShapeStyle,
  IconShapeStyle,
  LabelShapeStyle,
  BadgeShapeStyle,
} from "./Types";

export const SHAPE_NAME = "node-badge";

export function removeUndefinedAttrs(attrs: any) {
  Object.keys(attrs).forEach((key) => {
    if (attrs[key] === undefined) delete attrs[key];
  });
  return attrs;
}

const parseKeyShape = (shapeStyle?: ShapeStyle) => {
  const keyShapeStyle: KeyShapeStyle = shapeStyle?.keyShape;
  const keyShapeRadius = keyShapeStyle?.size
    ? keyShapeStyle?.size / 2
    : undefined;

  return {
    name: "keyShape",
    attrs: removeUndefinedAttrs({
      r: keyShapeRadius,
      cursor: "pointer",
      ...keyShapeStyle,
    }),
  };
};

const parseHaloShape = (shapeStyle: ShapeStyle) => {
  const haloStyle: HaloShapeStyle = shapeStyle?.halo;
  const keyShapeRadius = shapeStyle?.keyShape?.size
    ? shapeStyle?.keyShape?.size / 2
    : undefined;
  return {
    name: "halo",
    attrs: removeUndefinedAttrs({
      r: keyShapeRadius ? keyShapeRadius + 10 : undefined,
      lineWidth: 10,
      ...haloStyle,
    }),
  };
};

const parseIconShape = (
  shapeStyle: ShapeStyle,
  excludeImg: boolean = false
) => {
  const iconStyle: IconShapeStyle = shapeStyle?.icon;
  const getImgStyles = () =>
    excludeImg ? { ...styleExcludingImg } : { ...iconStyle };
  const { img, ...styleExcludingImg } = iconStyle;
  return {
    name: "icon",
    attrs: removeUndefinedAttrs({
      x: iconStyle.width ? -iconStyle.width / 2 : undefined,
      y: iconStyle.height ? -iconStyle.height / 2 : undefined,
      cursor: "pointer",
      ...getImgStyles(),
    }),
  };
};

const parseLabelShape = (shapeStyle: ShapeStyle) => {
  const labelStyle: LabelShapeStyle = shapeStyle?.label;
  return {
    name: "label",
    attrs: removeUndefinedAttrs({
      x: 0,
      y: shapeStyle?.keyShape?.size
        ? shapeStyle?.keyShape?.size / 2 + 15
        : undefined,
      cursor: "pointer",
      ...labelStyle,
    }),
  };
};

const getBadgeX = (size: number) =>
  size ? (size / 2) * Math.cos((Math.PI * 1) / 4) : undefined;
const getBadgeY = (size: number) =>
  size ? -(size / 2) * Math.sin((Math.PI * 1) / 4) : undefined;

const parseBadgeCircleShape = (shapeStyle: ShapeStyle) => {
  const badgeStyle: BadgeShapeStyle = shapeStyle?.badge;
  const badgeX = getBadgeX(shapeStyle?.keyShape?.size);
  const badgeY = getBadgeY(shapeStyle?.keyShape?.size);
  const badgeRadius = badgeStyle?.size ? badgeStyle.size / 2 : undefined;

  return {
    name: "badge-circle",
    attrs: removeUndefinedAttrs({
      x: badgeX,
      y: badgeY,
      r: badgeRadius,
      ...badgeStyle,
    }),
  };
};
const parseBadgeTextShape = (shapeStyle: ShapeStyle) => {
  const badgeStyle: BadgeShapeStyle = shapeStyle?.badge;
  const badgeX = getBadgeX(shapeStyle?.keyShape?.size);
  const badgeY = getBadgeY(shapeStyle?.keyShape?.size);

  return {
    name: "badge-text",
    attrs: removeUndefinedAttrs({
      x: badgeX,
      y: badgeY,
      textAlign: badgeStyle?.textAlign,
      textBaseline: badgeStyle?.textBaseline,
      fill: badgeStyle?.stroke,
      text: badgeStyle?.text,
    }),
  };
};

const parseAttr = (style: ShapeStyle, shapeName: string) => {
  if (shapeName === "keyShape") return parseKeyShape(style).attrs;
  if (shapeName === "halo") return parseHaloShape(style).attrs;
  if (shapeName === "icon") return parseIconShape(style, true).attrs;
  if (shapeName === "label") return parseLabelShape(style).attrs;
  if (shapeName === "badge-circle") return parseBadgeCircleShape(style).attrs;
  if (shapeName === "badge-text") return parseBadgeTextShape(style).attrs;
  return style?.[shapeName] || {};
};

const setShapesStyle = (shapes: any, shapesStyle: any) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    shapes.forEach((shapeItem: any) => {
      const itemShapeName: string = shapeItem.cfg.name;
      const style =
        shapesStyle?.[itemShapeName] ??
        shapesStyle?.[itemShapeName.split("-")[0]];

      if (style) {
        shapeItem.attr(parseAttr(shapesStyle, itemShapeName));
      }
    });
  } catch (error) {
    console.error(error);
  }
};

export const registerNodeShape = () => {
  //G6.registerNode(typeName: string, nodeDefinition: object, extendedTypeName?: string)
  //draw, update, and setState will extend from extendedTypeName unless they are rewritten in nodeDefinition
  //if update is not defined, the update function of the extended node will be executed; if no extended node, draw will be called instead

  //draw is required if the custome node doesn't extend any other shape
  //afterDraw and afterUpdate - used for extending the existing nodes in general. e.g. adding extra image on rect node, adding animation on a circle node
  //setState should be overridden when you want to response the state changes by animation
  //getAnchorPoints: it is only required when you want to constrain the link points for nodes and their related edges.

  G6.registerNode(SHAPE_NAME, {
    draw(cfg?: ModelConfig, group?: IGroup) {
      if (cfg?.style) cfg._initialStyle = { ...cfg?.style };

      //define the basic circle shape
      const keyShape = group?.addShape("circle", parseKeyShape(cfg?.style));

      //add the halo
      if (cfg?.style?.halo)
        group?.addShape("circle", parseHaloShape(cfg?.style));

      //add the label to the bottom
      if (cfg?.style?.label)
        group?.addShape("text", parseLabelShape(cfg?.style));

      //add the badge to the top right
      if (cfg?.style?.badge) {
        group?.addShape("circle", parseBadgeCircleShape(cfg?.style));
        group?.addShape("text", parseBadgeTextShape(cfg?.style));
      }

      return keyShape as IShape;
    },
    afterDraw(cfg?: ModelConfig, group?: IGroup) {
      //add the icon in the centre
      if (cfg?.style?.icon)
        group?.addShape("image", parseIconShape(cfg?.style));
    },
    //the below function is required by G6 in order for new nodes added to the graph to have the same shape and style as the other nodes,
    //and also to ensure when a value on a node changes (e.g. number of relationships) it is reflected in the UI
    update(cfg, item) {
      if (cfg?.style && cfg?._initialStyle) {
        const shapes = item.getContainer().get("children");
        setShapesStyle(shapes, cfg.style);
        cfg._initialStyle = mixStyles(
          cfg._initialStyle as NodeBadgeShapeStyle,
          cfg.style as NodeBadgeShapeStyle
        );
      }
    },
    setState(name, value, item) {
      if (!name || !item) return;

      const model = item.getModel() as NodeConfig;
      const shapes = item.getContainer().get("children");
      const stateStyles = model?.style?.status;
      const status = item._cfg?.states || [];

      if (stateStyles)
        try {
          Object.keys(stateStyles).forEach((statusKey) => {
            if (name === statusKey) {
              if (value) {
                setShapesStyle(shapes, stateStyles[statusKey]);
              } else {
                setShapesStyle(shapes, model._initialStyle);
                status.forEach((key) => {
                  setShapesStyle(shapes, stateStyles[key]);
                });
              }
            }
          });
        } catch (error) {
          console.error(error);
        }
    },
  });
};
