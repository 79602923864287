import { DCHModel } from "data/brick";
import { OrgId } from "data/Enodia";
import { masonApi } from "data/Mason";
import { QueryInvocation } from "data/QueryApi/queryApiTypes";
import {
  BuildingListMap,
  SiteBuildingReference,
} from "data/QueryApi/queryTypes";
import { reducePromises } from "components/shared/Common";
import { SearchParamsValues } from "./SearchParams/SearchForm";
import { modelRefToSelectVal } from "../shared/InputFields/Fields/Domain/ModelsSelectInput";
import {
  locationsAndEquipmentAndChildren,
  locationsOrEquipmentAndChildren,
} from "./Queries";
import {
  Result,
  ResultExtractor,
  resultsFromLocationsAndEquipmentAndChildrenQuery,
  resultsFromLocationsOrEquipmentAndChildrenQuery,
  resultsFromQueryResponse,
} from "./result-utils";

export const buildQuery = (
  searchParams: SearchParamsValues,
  modelsList: SiteBuildingReference[]
): QueryInvocation => {
  const models =
    searchParams.models.length > 0
      ? modelsList.filter((modelRef) =>
          searchParams.models.includes(modelRefToSelectVal(modelRef))
        )
      : [];
  let types: string[] = [];
  let includeSubclasses = false;

  if (searchParams.locations.length > 0 && searchParams.equipment.length > 0) {
    // both location and equipment types are selected
    return {
      models,
      queryDef: locationsAndEquipmentAndChildren(
        searchParams.locations,
        searchParams.equipment,
        searchParams.points,
        searchParams.includeLocationsSubclasses,
        searchParams.includeEquipmentSubclasses,
        searchParams.includePointsSubclasses
      ),
    };
  } else {
    if (searchParams.locations.length > 0) {
      types = searchParams.locations;
      includeSubclasses = searchParams.includeLocationsSubclasses;
    } else if (searchParams.equipment.length > 0) {
      types = searchParams.equipment;
      includeSubclasses = searchParams.includeEquipmentSubclasses;
    }

    return {
      models,
      queryDef: locationsOrEquipmentAndChildren(
        types,
        searchParams.points,
        searchParams.includePointsSubclasses,
        includeSubclasses
      ),
    };
  }
};

export const searchPoints = (
  searchParams: SearchParamsValues,
  modelsList: SiteBuildingReference[]
): Promise<Set<Result>> => {
  const query: QueryInvocation = buildQuery(searchParams, modelsList);
  if (searchParams.locations.length > 0 && searchParams.equipment.length > 0) {
    return resultsFromQuery(query)(
      resultsFromLocationsAndEquipmentAndChildrenQuery
    );
  } else {
    return resultsFromQuery(query)(
      resultsFromLocationsOrEquipmentAndChildrenQuery
    );
  }
};

const resultsFromQuery =
  (selectQuery: QueryInvocation) => (resultExtractor: ResultExtractor) => {
    return masonApi.postSelectQuery(selectQuery).then((r) => {
      return reducePromises(
        (selectQuery.models || []).map(({ orgId, siteId, buildingId }) => {
          return buildingId !== undefined
            ? masonApi
                .getDraftBuildingJSON({
                  orgId: orgId as OrgId,
                  siteId: siteId,
                  buildingId: buildingId,
                })
                .then((m) => [orgId, siteId, m])
            : masonApi
                .getDraftSiteJSON({
                  orgId: orgId as OrgId,
                  siteId: siteId,
                })
                .then((m) => [orgId, siteId, m]);
        })
      ).then((data) => {
        const buildingData: BuildingListMap<DCHModel> = data.reduce(
          (a, [orgId, siteId, buildingModel]) => {
            const siteMap = a.get(orgId);
            if (siteMap) {
              if (siteMap.get(siteId)) {
                siteMap.set(siteId, siteMap.get(siteId).concat(buildingModel));
              } else {
                siteMap.set(siteId, [buildingModel]);
              }
            } else {
              a.set(orgId, new Map([[siteId, [buildingModel]]]));
            }

            return a;
          },
          new Map()
        );

        return resultsFromQueryResponse(buildingData, r)(resultExtractor);
      });
    });
  };
