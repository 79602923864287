/** @jsxImportSource @emotion/react */
import { Container } from "semantic-ui-react";
import tw from "twin.macro";
import { FileDisplay } from "components/shared";

export const ApplicationConfigurationPlaintextDocument: React.FC<{
  plaintext?: string;
  setPlaintext?: (value: string | undefined) => void;
}> = ({ plaintext, setPlaintext }) => {
  return (
    <Container fluid css={tw`w-full`}>
      <FileDisplay
        data={plaintext || "No data found"}
        updateCallback={setPlaintext}
        setExpanded
        css={tw`w-full`}
      />
    </Container>
  );
};
