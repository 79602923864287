import React from "react";
import { Button, Icon, SemanticICONS } from "semantic-ui-react";
/**
 * Buttons in an action cluster
 */
type ButtonProps = {
  label: string;
  onClick?: () => void;
  icon?: SemanticICONS;
  basic?: boolean;
  disabled?: boolean;
  noStyle?: boolean;
  secondary?: boolean;
  isLoading?: boolean;
  testId?: string;
  type?: "submit" | "reset" | "button" | undefined;
  form?: string;
};
export const ActionButton: React.FunctionComponent<ButtonProps> = ({
  label,
  onClick,
  icon,
  basic,
  disabled,
  noStyle,
  secondary,
  isLoading,
  testId,
  type,
  form,
}) => (
  <Button
    type={type ?? "button"}
    data-test-id={testId}
    secondary={secondary}
    primary={!basic}
    onClick={onClick}
    basic={basic}
    inverted={basic}
    style={{ marginRight: !noStyle && "1rem" }}
    disabled={disabled}
    loading={isLoading}
    form={form}
  >
    {icon && <Icon name={icon} />}
    {label}
  </Button>
);
