import { FULFILLED_PROMISE, REJECTED_PROMISE } from "data/constants";
import { Observation, sensorApi, StreamId } from "../senaps";

const GET_OBSERVATIONS_LIMIT = 3000;

/**
 * Get observations without limit.
 *
 * There are two limits in a single request of GET /observations
 * - The limit on the number of results, returned for every stream in the request
 * - The limit on the query string, affected by the number of streams you can add in a
 * request.
 *
 * @param streamIds
 * @param start url encoded iso8601 format
 * @param end url encoded iso8601 format
 * @param resultsLimit override the default behavior by specifying a limit
 */
export const getObservationsCustom = (
  streamIds: StreamId[],
  start?: string,
  end?: string,
  resultsLimit?: number
): Promise<Array<Observation>> => {
  return Promise.allSettled(
    streamIds.map((id) =>
      resultsLimit === undefined
        ? getObservationsNoLimitSingleStream(id, start, end)
        : sensorApi
            .getObservations({
              streamid: id,
              start: start,
              end: end,
              limit: resultsLimit,
            })
            .then((r) => r.results)
    )
  ).then((results) => {
    const obs = results
      .filter((r) => r.status === FULFILLED_PROMISE)
      .flatMap((r) => (r as PromiseFulfilledResult<any>).value);

    const rejected = results.filter((r) => r.status === REJECTED_PROMISE);
    if (rejected.length > 0) {
      return Promise.reject(
        rejected
          .map((r) => (r as PromiseRejectedResult).reason.error)
          .reduce((c, a) => a + "\n" + c, "")
          .trim()
      );
    } else {
      return Promise.resolve(obs);
    }
  });
};

/**
 * Get observations without the limit on the number of results from a single stream.
 *
 * @param streamId If there are lots of streams, consider using {@link getObservationsCustom}
 * @param start url encoded iso8601 format
 * @param end url encoded iso8601 format
 * @param results
 */
export const getObservationsNoLimitSingleStream = (
  streamId: StreamId,
  start?: string,
  end?: string,
  results: Array<Observation> = []
): Promise<Array<Observation>> => {
  return sensorApi
    .getObservations({
      streamid: streamId,
      start: start,
      end: end,
      limit: GET_OBSERVATIONS_LIMIT,
    })
    .then(
      (r) => {
        const count = (r && r.count) || 0;
        const newResults = results.concat(r.results || []);

        if (count < GET_OBSERVATIONS_LIMIT || count === 0) {
          // no more results to load
          return Promise.resolve(newResults);
        } else {
          const lastResult = r.results[count - 1];
          const time = lastResult.t || Object.keys(lastResult)[0];

          return getObservationsNoLimitSingleStream(
            streamId,
            time as string,
            end,
            newResults
          );
        }
      },
      (e) => {
        return Promise.reject(e.error || "Could not load observation data");
      }
    );
};
