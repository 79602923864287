/** @jsxImportSource @emotion/react */

import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import { generatePath, useNavigate } from "react-router-dom";
import tw from "twin.macro";
import { useEntityClasses } from "data/EntityClasses/useEntityClasses";
import { ApiErrorType } from "data/http";
import { DescribeResponse } from "data/QueryApi/queryApiTypes";
import {
  ModelResult,
  describeQuery,
  selectQuery,
} from "data/QueryApi/queryApiUtils";
import { ModelReference, NodeUriType } from "data/QueryApi/queryTypes";

import { Path } from "Routes";
import { DchModal, UIStatus, UIStatusWrapper } from "components/shared";
import { useWindowSize } from "components/SitesAndBuildings/Model/Visualiser/use-window-size";
import {
  convertDchModelToVisualiserModel,
  convertDescribetoVisualiserModel,
} from "./Model/ModelConverter";
import { ModelProps } from "../ModelUtils";
import { SelectedNodePanel } from "./SelectedNode/SelectedNodePanel";
import { G6GraphUtil } from "./G6GraphUtil";
import { VisualisationSearchPanel } from "./VisualisationSearchPanel";
import {
  VizModel,
  generateNewUuid,
  updateExistingVizModel,
} from "./VizModelUtil";

export type SelectedNode = {
  id: NodeUriType;
  modelReference: ModelReference;
  action: "update" | "replace";
};

export const ModelVisualisation: React.FunctionComponent<ModelProps> = ({
  orgId,
  siteId,
  buildingId,
  modelType,
}) => {
  const [model, setModel] = useState<ModelResult>();
  const [status, setStatus] = useState(new UIStatus());
  const [showModelUnavailableModal, setShowModelUnavailableModal] =
    useState(false);
  const [selectedNode, setSelectedNode] = useState<SelectedNode>();
  const [selectedNodeStateUuid, setSelectedNodeStateUuid] = useState("");
  const [searchClicked, setSearchClicked] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [selectedSearchResult, setSelectedSearchResult] =
    useState<NodeUriType>();
  const vizModel = useRef<VizModel>();
  const [selectedPanelModel, setSelectedPanelModel] = useState<VizModel>();
  const [g6Graph, setG6Graph] = useState<G6GraphUtil>();
  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const windowSize = useWindowSize();

  const { entityClasses } = useEntityClasses();

  const fetchDefaultModel = useCallback(() => {
    setStatus((prev) => prev.setIndeterminate(true));
    selectQuery(modelType, orgId, siteId!!, buildingId).then(
      (res: ModelResult) => {
        //if we have found nothing that can be rendered, show modal indicating no published model available
        if (!res.rootModelNode) {
          setShowModelUnavailableModal(true);
        } else {
          //otherwise, set the model and proceed to render the graph
          setModel(res);
        }
        setStatus((prev) => prev.setIndeterminate(false));
      },
      (error: any) => {
        if (error?.type === ApiErrorType.Conflict)
          setShowModelUnavailableModal(true);
        else
          setStatus((prevState) => prevState.setError("Could not load model"));
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, siteId, buildingId, modelType]);

  const changeGraphData = useCallback(
    (rerender: boolean = false) => {
      g6Graph?.updateGraphData(
        vizModel.current,
        setSelectedPanelModel,
        rerender
      );
    },
    [g6Graph]
  );

  const initialiseOrResetGraph = useCallback(() => {
    // take the model data, convert it to the UI model, and set it as vizModel.current
    // also convert into the nodes and edges required for rendering by the G6 library and set it as the graph's data
    if (model && g6Graph) {
      vizModel.current = convertDchModelToVisualiserModel(model, entityClasses);
      changeGraphData(true);
      setSelectedNode(undefined);
      setSearchClicked(false);
    }
  }, [changeGraphData, entityClasses, g6Graph, model]);

  const fetchDataByNodeId = useCallback(
    (
      modelReference: ModelReference,
      nodeUri: NodeUriType,
      onSuccess: (res: DescribeResponse) => void
    ) => {
      //fetch the data for a singular selected node
      return describeQuery(modelReference, nodeUri)
        .then((res) => onSuccess(res))
        .catch((e) => setStatus((prevState) => prevState.setError(e.message)));
    },
    []
  );

  const replaceOrUpdateVizModel = useCallback(
    (respsone: DescribeResponse, selectedNode: SelectedNode) => {
      switch (selectedNode.action) {
        case "replace":
          //replace the existing viz with a new graph centered on the selected node
          vizModel.current = convertDescribetoVisualiserModel(
            respsone,
            entityClasses
          );
          changeGraphData();
          break;
        case "update":
          if (
            updateExistingVizModel(
              respsone,
              selectedNode,
              entityClasses,
              vizModel.current
            )
          ) {
            changeGraphData();
          }

          setSelectedNodeStateUuid(generateNewUuid());
          break;
      }
    },
    [changeGraphData, entityClasses]
  );

  const handleSelectedNodeChange = useCallback(() => {
    if (entityClasses && g6Graph) {
      let promises: Promise<any>[] = [];
      if (selectedNode) {
        //if we have a search result selected and we have changed the selected node, clear the search result if it is no longer the selected node
        if (selectedSearchResult && selectedSearchResult !== selectedNode.id)
          setSelectedSearchResult(undefined);
        // go fetch the data for this specific node
        promises = [
          ...promises,
          fetchDataByNodeId(
            selectedNode.modelReference,
            selectedNode.id,
            (res) => replaceOrUpdateVizModel(res, selectedNode)
          ),
        ];
      }
      Promise.all(promises).then((_) =>
        g6Graph.setClickState(selectedNode?.id)
      );
    }
  }, [
    entityClasses,
    fetchDataByNodeId,
    g6Graph,
    replaceOrUpdateVizModel,
    selectedNode,
    selectedSearchResult,
  ]);

  //on initial load, instantiate graphUtils
  useEffect(() => setG6Graph(new G6GraphUtil()), []);

  //once graphUtils is instantiated, initialise the graph once only
  useMemo(() => {
    if (!g6Graph?.graph && ref.current)
      g6Graph?.initialiseGraph(ref.current, setSelectedNode);
  }, [g6Graph]);

  // on first load, go and fetch the initial model data from the query API
  useEffect(() => {
    fetchDefaultModel();
  }, [fetchDefaultModel]);

  // once the model is returned from the API, convert it to the model structure we need for the UI
  useMemo(() => {
    initialiseOrResetGraph();
  }, [initialiseOrResetGraph]);

  // when the selectedNode is changed, fetch the data for the selected node and update the graph's data with any new nodes and relationships that have been found
  useMemo(() => {
    handleSelectedNodeChange();
  }, [handleSelectedNodeChange]);

  useEffect(() => {
    if (g6Graph && g6Graph?.graph && ref.current) {
      // using getBoundingClientRect() instead of clientWidth/clientHeight to get the fractional pixels
      const { width, height } = ref.current.getBoundingClientRect();
      g6Graph?.graph?.changeSize(width, height);
    }
  }, [windowSize, ref, g6Graph]);

  const redirectToViewModel = () => {
    setShowModelUnavailableModal(false);
    navigate(
      buildingId !== undefined
        ? generatePath(Path.ViewBuilding, {
            orgId: orgId,
            siteId: siteId!!,
            buildingId: buildingId,
          })
        : generatePath(Path.ViewSite, {
            orgId: orgId,
            siteId: siteId!!,
          })
    );
  };

  return (
    <div style={{ flex: 1 }}>
      <UIStatusWrapper status={status} customCss={tw`h-full relative`}>
        <DchModal
          content={
            <p>
              There are no published nodes within this model to display. Please
              update and publish the model, then try again.
            </p>
          }
          header="Published Model Unavailable"
          open={showModelUnavailableModal}
          confirmText="Return to Model"
          hideCancel
          onConfirm={() => redirectToViewModel()}
          onClose={() => redirectToViewModel()}
        />
        <div ref={ref} css={tw`h-full`}></div>
        <VisualisationSearchPanel
          setSearchOpen={setSearchOpen}
          searchOpen={searchOpen}
          setSearchClicked={setSearchClicked}
          searchClicked={searchClicked}
          onClear={initialiseOrResetGraph}
          setSelectedNode={setSelectedNode}
          selectedSearchResult={selectedSearchResult}
          setSelectedSearchResult={setSelectedSearchResult}
        />
        {selectedNode && (
          <div css={tw`h-full p-4 absolute top-0 right-0 w-1/4`}>
            <SelectedNodePanel
              siteId={siteId!!}
              vizModel={selectedPanelModel}
              selectedNode={selectedNode}
              setSelectedNode={(selectedNode?: SelectedNode) =>
                setSelectedNode(selectedNode)
              }
              selectedNodeStateUuid={selectedNodeStateUuid}
            />
          </div>
        )}
      </UIStatusWrapper>
    </div>
  );
};
