/** @jsxImportSource @emotion/react */

import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { Divider, Grid } from "semantic-ui-react";
import tw from "twin.macro";
import { OrgId } from "data/Enodia";
import { ApiError } from "data/http";
import { SiteMetaResponse, masonApi } from "data/Mason";
import { transformAddressMetaToForm } from "data/Models/Metadata/ModelMetadataUtils";
import { isValidCountryCode, isValidStreetNumber } from "data/validation";
import Form from "components/shared/Forms/ReactHookForm";
import { DchModal, UIStatus, PageSection } from "components/shared";
import { Location } from "./Location";
import { LocationMap } from "./Map/Map";

type AddressDetailsProps = {
  orgId: OrgId;
  isReadOnly?: boolean;
  isBuilding?: boolean;
};

const AddressHeader = ({ orgId, isBuilding }: AddressDetailsProps) => {
  const [showCopyAddressModal, setShowCopyAddressModal] = useState(false);
  const [getSiteLocationStatus, setGetSiteLocationStatus] = React.useState(
    new UIStatus()
  );

  const { setValue, watch } = useFormContext();
  const watchSiteId = watch("siteId");

  const copyAddressFromSite = () => {
    if (watchSiteId) {
      setGetSiteLocationStatus((prevState) => prevState.setIndeterminate(true));
      masonApi.getSite({ orgId, siteId: watchSiteId }).then(
        (siteMeta: SiteMetaResponse) => {
          if (siteMeta.addressMeta)
            setValue(
              "address",
              transformAddressMetaToForm(siteMeta.addressMeta),
              {
                shouldDirty: true,
              }
            );
          if (siteMeta.geolocation2d)
            setValue("location", siteMeta.geolocation2d, {
              shouldDirty: true,
            });
          setGetSiteLocationStatus((prevState) =>
            prevState.setIndeterminate(false)
          );
        },
        (err: ApiError) => {
          setGetSiteLocationStatus((prevState) =>
            prevState.setError(err.message)
          );
        }
      );
    }
  };

  return (
    <span>
      <span css={tw`pr-4`}>Address Details</span>
      {isBuilding && (
        <DchModal
          header={"Overwrite building address details"}
          content={
            <p>
              Are you sure you wish to overwrite existing address and
              geographical information for the building?
            </p>
          }
          open={showCopyAddressModal}
          onConfirm={() => {
            copyAddressFromSite();
            setShowCopyAddressModal(false);
          }}
          onClose={() => setShowCopyAddressModal(false)}
          confirmText="Confirm"
          trigger={
            <Form.Button
              submit={false}
              label="Copy from site"
              icon="copy"
              size="tiny"
              onClick={() => setShowCopyAddressModal(true)}
              disabled={watchSiteId === undefined}
              loading={getSiteLocationStatus.indeterminate}
            />
          }
        />
      )}
    </span>
  );
};

export const AddressDetails = (props: AddressDetailsProps) => {
  const { isReadOnly } = props;
  const { watch } = useFormContext();
  const watchSiteId = watch("siteId");
  const watchBuildingId = watch("buildingId");
  const watchName = watch("name");
  return (
    <PageSection header={<AddressHeader {...props} />}>
      <Grid>
        <Location />
        <Divider />
        <Grid.Row columns="equal">
          <Grid.Column>
            <Form.TextInput
              name="address.unit"
              label="Unit No"
              description="e.g. Apartment #"
              isReadOnly={isReadOnly}
            />
          </Grid.Column>
          <Grid.Column>
            <Form.TextInput
              name="address.streetNumber"
              label="Street Number"
              isReadOnly={isReadOnly}
              rules={{ validate: isValidStreetNumber }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns="equal">
          <Grid.Column>
            <Form.TextInput
              name="address.streetName"
              label="Street Name"
              description="What is the street name of the address?"
              isReadOnly={isReadOnly}
            />
          </Grid.Column>
          <Grid.Column>
            <Form.TextInput
              name="address.city"
              label="City"
              description="Nearest hub city"
              isReadOnly={isReadOnly}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns="equal">
          <Grid.Column>
            <Form.TextInput
              name="address.state"
              label="State"
              description="e.g. Government region"
              isReadOnly={isReadOnly}
            />
          </Grid.Column>
          <Grid.Column>
            <Form.TextInput
              name="address.country"
              label="Country"
              description="e.g. AUS"
              isReadOnly={isReadOnly}
              rules={{ validate: isValidCountryCode }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns="equal">
          <Grid.Column>
            <Form.TextInput
              name="address.postCode"
              label="Post Code"
              isReadOnly={isReadOnly}
            />
          </Grid.Column>
          <Grid.Column />
        </Grid.Row>
        <LocationMap
          markerTitle={
            watchName ?? (props.isBuilding ? watchBuildingId : watchSiteId)
          }
        />
      </Grid>
    </PageSection>
  );
};
