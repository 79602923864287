import {
  Schema,
  DCHModel,
  ModelId,
  ModelRootType,
  Wing,
  PointId,
  Floor,
  Room,
  Zone,
  Equipment,
  Point,
  FloorType,
  RoomType,
  ZoneType,
  EquipmentType,
  PointType,
} from "data/brick";
import {
  convertDtosToEntityProperties,
  convertEntityPropertiesToDtos,
} from "data/Mason/EntityProperties";
import {
  AnyNodeId,
  SubLevelNode,
  SubLevelNodeId,
} from "components/SitesAndBuildings/Common";
import { ModelFormFields } from "components/SitesAndBuildings/Model/Form/SiteOrBuildingModelForm";
import { compareString } from "components/shared";

export const transformSerialisedModelToFormFields = (
  fetchedModel: DCHModel
): ModelFormFields => {
  const sortByLabelOrId = (
    a: [SubLevelNodeId, SubLevelNode],
    b: [SubLevelNodeId, SubLevelNode]
  ) => compareString(a[1]?.label ?? a[1].id, b[1]?.label ?? b[1].id);
  let formValues: ModelFormFields = {
    modelDetails: {
      fieldId: fetchedModel.id,
      name: fetchedModel.label,
      locationOf: fetchedModel.location_of,
      points: fetchedModel.points,
      comment: fetchedModel.comment,
      properties: convertDtosToEntityProperties(fetchedModel.properties),
    },
    wings: Array.from(fetchedModel.wing)
      .sort(sortByLabelOrId)
      .map(([_, wing]) => ({
        fieldId: wing.id,
        name: wing.label,
        comment: wing.comment,
        locationOf: wing.location_of,
        isFedBy: wing.fed_by,
        floors: wing.parts,
        points: wing.points,
        properties: convertDtosToEntityProperties(wing.properties),
      })),
    floors: Array.from(fetchedModel.floor)
      .sort(sortByLabelOrId)
      .map(([_, floor]) => ({
        fieldId: floor.id,
        name: floor.label,
        comment: floor.comment,
        type: floor.floor_type ?? ("Floor" as FloorType),
        wing: floor.wing,
        rooms: floor.parts,
        locationOf: floor.location_of,
        isFedBy: floor.fed_by,
        points: floor.points,
        properties: convertDtosToEntityProperties(floor.properties),
      })),
    rooms: Array.from(fetchedModel.room)
      .sort(sortByLabelOrId)
      .map(([_, room]) => ({
        fieldId: room.id,
        name: room.label,
        comment: room.comment,
        type: room.room_type ?? ("Room" as RoomType),
        floor: room.floor,
        locationOf: room.location_of,
        isFedBy: room.fed_by,
        points: room.points,
        properties: convertDtosToEntityProperties(room.properties),
      })),
    zones: Array.from(fetchedModel.zone)
      .sort(sortByLabelOrId)
      .map(([_, zone]) => ({
        fieldId: zone.id,
        name: zone.label,
        comment: zone.comment,
        type: zone.zone_type ?? ("Zone" as ZoneType),
        locations: zone.locations,
        isFedBy: zone.fed_by,
        points: zone.points,
        properties: convertDtosToEntityProperties(zone.properties),
      })),
    equipment: Array.from(fetchedModel.equipment)
      .sort(sortByLabelOrId)
      .map(([_, equipment]) => ({
        fieldId: equipment.id,
        name: equipment.label,
        comment: equipment.comment,
        type: equipment.equipment_type ?? ("Equipment" as EquipmentType),
        isFedBy: equipment.fed_by,
        feeds: equipment.feeds,
        locations: [...new Set(equipment.locations)],
        //TODO - add these back in once bug DCH-4832 is fixed to allow saving when pass these to back end
        // partOf: (equipment.part_of),
        // parts: (equipment.parts),
        points: equipment.points,
        properties: convertDtosToEntityProperties(equipment.properties),
      })),
    points: Array.from(fetchedModel.point)
      .sort(sortByLabelOrId)
      .map(([_, point]) => ({
        fieldId: point.id,
        name: point.label,
        comment: point.comment,
        type: point.point_type ?? ("Point" as PointType),
        unit: point.unit,
        pointOf: point.point_of,
        streamIds: point.streamIds,
        properties: convertDtosToEntityProperties(point.properties),
      })),
  };
  return formValues;
};

export type NodeFormFields = {
  fieldId: AnyNodeId;
  name?: string;
  comment?: string;
};

export type ModelMeta = {
  modelId: ModelId;
  schema: Schema;
  modelName?: string;
};

export const transformFormFieldsToSerialisedModel = (
  formValues: ModelFormFields,
  modelMeta: ModelMeta,
  modelType: ModelRootType
): DCHModel => {
  let model: DCHModel = {
    id: modelMeta.modelId,
    root_type: modelType,
    label: formValues.modelDetails.name,
    comment: formValues.modelDetails.comment,
    schema: modelMeta.schema,
    fed_by: [],
    in_zone: [],
    location_of: formValues.modelDetails.locationOf ?? [],
    points: formValues.modelDetails.points ?? [],
    properties: convertEntityPropertiesToDtos(
      formValues.modelDetails.properties
    ),
    wing: new Map(
      formValues.wings.map((wing) => [
        wing.fieldId,
        {
          id: wing.fieldId,
          label: wing.name,
          comment: wing.comment,
          location_of: wing.locationOf,
          fed_by: wing.isFedBy,
          parts: wing.floors,
          points: (wing.points as PointId[]) ?? ([] as PointId[]),
          in_zone: [],
          properties: convertEntityPropertiesToDtos(wing.properties),
        } as Wing,
      ])
    ),
    floor: new Map(
      formValues.floors.map((floor) => [
        floor.fieldId,
        {
          id: floor.fieldId,
          label: floor.name,
          comment: floor.comment,
          floor_type: floor.type,
          wing: floor.wing,
          parts: floor.rooms,
          location_of: floor.locationOf,
          fed_by: floor.isFedBy,
          points: floor.points,
          in_zone: [],
          properties: convertEntityPropertiesToDtos(floor.properties),
        } as Floor,
      ])
    ),
    room: new Map(
      formValues.rooms.map((room) => [
        room.fieldId,
        {
          id: room.fieldId,
          label: room.name,
          comment: room.comment,
          room_type: room.type,
          floor: room.floor,
          location_of: room.locationOf,
          fed_by: room.isFedBy,
          points: (room.points as PointId[]) ?? ([] as PointId[]),
          in_zone: [],
          properties: convertEntityPropertiesToDtos(room.properties),
        } as Room,
      ])
    ),
    zone: new Map(
      formValues.zones.map((zone) => [
        zone.fieldId,
        {
          id: zone.fieldId,
          label: zone.name,
          comment: zone.comment,
          zone_type: zone.type,
          locations: zone.locations,
          fed_by: zone.isFedBy,
          points: zone.points,
          part_of: undefined,
          parts: [],
          properties: convertEntityPropertiesToDtos(zone.properties),
        } as Zone,
      ])
    ),
    equipment: new Map(
      formValues.equipment.map((equipment) => [
        equipment.fieldId,
        {
          id: equipment.fieldId,
          label: equipment.name,
          comment: equipment.comment,
          equipment_type: equipment.type,
          fed_by: equipment.isFedBy,
          feeds: equipment.feeds,
          parts: equipment.parts,
          locations: equipment.locations,
          points: equipment.points,
          part_of: undefined,
          properties: convertEntityPropertiesToDtos(equipment.properties),
        } as Equipment,
      ])
    ),
    point: new Map(
      formValues.points.map((point) => [
        point.fieldId,
        {
          id: point.fieldId,
          label: point.name,
          comment: point.comment,
          point_type: point.type,
          unit: point.unit,
          point_of: point.pointOf,
          streamIds: point.streamIds,
          properties: convertEntityPropertiesToDtos(point.properties),
        } as Point,
      ])
    ),
  };
  return model;
};
