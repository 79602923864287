import React, { useMemo, useState } from "react";
import { Button, Checkbox, Icon, Table } from "semantic-ui-react";
import { chronosApi } from "data/Chronos/chronosApi";
import { StreamInfo } from "data/Metis";
import { saveToFile } from "data/utils";
import { DchModal, UIStatus, UIStatusWrapper } from "components/shared";

export const ApplicationDownloadStreamsButton: React.FunctionComponent<{
  applicationName: string;
  applicationStreams: Array<StreamInfo>;
}> = ({ applicationName, applicationStreams }) => {
  const [selectedStreams, setSelectedStreams] = useState<StreamInfo[]>([]);
  const [downloadStatus, setDownloadStatus] = useState(new UIStatus());

  const streamTable = useMemo(() => {
    const toggleSelectedStream = (streamInfo: StreamInfo) => {
      if (!selectedStreams.includes(streamInfo)) {
        setSelectedStreams((p) => p.concat(streamInfo));
      } else {
        setSelectedStreams((p) => p.filter((x) => x !== streamInfo));
      }
    };

    return (
      <Table celled striped inverted columns={3}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={10}>Port ID</Table.HeaderCell>
            <Table.HeaderCell>Is Collection</Table.HeaderCell>
            <Table.HeaderCell>Download</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {applicationStreams.flatMap((pointInfo) => {
            return (
              <Table.Row key={`panel-${pointInfo.id}`}>
                <Table.Cell width={10}>
                  <Icon name="folder" /> {pointInfo.id}
                </Table.Cell>
                <Table.Cell collapsing>
                  {pointInfo?.isCollection ? "Yes" : "No"}
                </Table.Cell>
                <Table.Cell collapsing textAlign="center">
                  <Checkbox
                    readOnly={false}
                    value={pointInfo.id}
                    checked={selectedStreams.includes(pointInfo)}
                    onChange={() => toggleSelectedStream(pointInfo)}
                  />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  }, [selectedStreams, applicationStreams]);

  const downloadJson = () => {
    setDownloadStatus((prev) => prev.setIndeterminate(true));
    return chronosApi
      .postDownload({})({
        points: selectedStreams
          .flatMap((stream) => stream.points)
          .map(
            (point) => `${point.orgId}:${point.dataPoolId}:${point.pointId}`
          ),
      })
      .then(
        (res) => {
          setDownloadStatus((prev) => prev.setIndeterminate(false));
          saveToFile(
            JSON.stringify(res, null, 4),
            `${applicationName} data.json`,
            "application/json"
          );
        },
        () => {
          setDownloadStatus?.((prev) =>
            prev.setError(
              "There was an error downloading the point data. Please try again."
            )
          );
        }
      );
  };

  return (
    <DchModal
      content={
        <UIStatusWrapper status={downloadStatus}>{streamTable}</UIStatusWrapper>
      }
      header="Download Application Data"
      confirmText={
        <>
          <Icon name="download" />
          Download
        </>
      }
      onConfirm={() => downloadJson()}
      onClose={() => {
        setSelectedStreams([]);
      }}
      disableConfirm={selectedStreams.length === 0}
      id="application-download-modal"
      trigger={
        <Button
          basic
          inverted
          icon="download"
          content="Download Application Data"
          disabled={applicationStreams.length === 0}
        />
      }
    />
  );
};
