import {
  FieldArrayWithId,
  UseFormGetValues,
  FieldValues,
  UseFormSetValue,
} from "react-hook-form";
import { UidType } from "data/brick";
import { AnyNodeId, AnyNodeName } from "components/SitesAndBuildings/Common";
import { extractModelId } from "../../ModelUtils";
import { getCollectionName } from "../ModelFormUtils";
import { FormFieldArrayType } from "../SiteOrBuildingModelForm";

/*These watchers allow us to monitor what changes are made under a particular entity, 
and update related entities whenever changes occur*/

export type WatcherProps = {
  isReadOnly?: boolean;
  entityFieldId: UidType;
  entityIdentifier: string;
};

type RelationshipChangeProps<T extends FormFieldArrayType> = {
  entityFieldId: AnyNodeId;
  watchedField: AnyNodeId | AnyNodeId[];
  existingTargetFields?: FieldArrayWithId[];
  getValues: UseFormGetValues<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  targetNodeType: AnyNodeName;
  targetProperty: keyof T;
};

export const handleArrayToArrayChanges = <T extends FormFieldArrayType>({
  entityFieldId,
  watchedField = [],
  existingTargetFields,
  getValues,
  setValue,
  targetNodeType,
  targetProperty,
}: RelationshipChangeProps<T>) => {
  const targetCollectionName = getCollectionName(targetNodeType);
  const mapArray = (targetIdentifier: string, watchedArray: UidType[]) => {
    const targetValues: T | undefined = getValues(targetIdentifier);
    const targetArray = targetValues?.[
      targetProperty
    ] as unknown as AnyNodeId[];
    if (
      targetValues?.fieldId &&
      watchedArray?.includes(targetValues.fieldId) &&
      !targetArray?.includes(entityFieldId)
    )
      setValue(`${targetIdentifier}.${String(targetProperty)}`, [
        ...(targetArray ?? []),
        entityFieldId,
      ]);
    else if (
      targetValues?.fieldId &&
      !watchedArray?.includes(targetValues.fieldId) &&
      targetArray?.includes(entityFieldId)
    )
      setValue(
        `${targetIdentifier}.${String(targetProperty)}`,
        [...(targetArray ?? [])].filter((e) => e !== entityFieldId)
      );
  };
  if (targetNodeType === "model") {
    mapArray(
      targetCollectionName,
      (watchedField as UidType[])?.map((w) => extractModelId(w))
    );
  } else if (existingTargetFields) {
    existingTargetFields.forEach((_, index) => {
      mapArray(`${targetCollectionName}.${index}`, watchedField as UidType[]);
    });
  }
};

export const handleArrayToFieldChanges = <T extends FormFieldArrayType>({
  entityFieldId,
  watchedField = [],
  existingTargetFields,
  getValues,
  setValue,
  targetNodeType,
  targetProperty,
}: RelationshipChangeProps<T>) => {
  const targetCollectionName = getCollectionName(targetNodeType);
  if (existingTargetFields)
    existingTargetFields.forEach((_, index) => {
      const targetIdentifier = `${targetCollectionName}.${index}`;
      const targetValues: T | undefined = getValues(targetIdentifier);
      const targetField = targetValues?.[targetProperty] as unknown as string;
      if (
        targetValues?.fieldId &&
        watchedField.includes(targetValues.fieldId) &&
        targetField !== entityFieldId
      )
        setValue(
          `${targetIdentifier}.${String(targetProperty)}`,
          entityFieldId
        );
      else if (
        targetValues?.fieldId &&
        !watchedField.includes(targetValues.fieldId) &&
        targetField === entityFieldId
      )
        setValue(`${targetIdentifier}.${String(targetProperty)}`, undefined);
    });
};

export const handleFieldToArrayChanges = <T extends FormFieldArrayType>({
  entityFieldId,
  watchedField = "" as UidType,
  existingTargetFields,
  getValues,
  setValue,
  targetNodeType,
  targetProperty,
}: RelationshipChangeProps<T>) => {
  const targetCollectionName = getCollectionName(targetNodeType);
  if (existingTargetFields)
    existingTargetFields.forEach((_, index) => {
      const targetIdentifier = `${targetCollectionName}.${index}`;
      const targetValues: T | undefined = getValues(targetIdentifier);
      const targetArray = targetValues?.[
        targetProperty
      ] as unknown as AnyNodeId[];
      if (
        targetValues?.fieldId &&
        targetValues.fieldId === watchedField &&
        !targetArray.includes(entityFieldId)
      )
        setValue(`${targetIdentifier}.${String(targetProperty)}`, [
          ...(targetArray ?? []),
          entityFieldId,
        ]);
      else if (
        targetValues?.fieldId &&
        targetValues.fieldId !== watchedField &&
        targetArray.includes(entityFieldId)
      )
        setValue(
          `${targetIdentifier}.${String(targetProperty)}`,
          [...(targetArray ?? [])].filter((e) => e !== entityFieldId)
        );
    });
};
