import Z from "zxcvbn";

/******************
 STRING VALIDATIONS 
 ******************/

//Validator for SiteId and BuildingId - only allow specific characters
export const isValidId = (s?: string) => {
  const idRegex = /^[a-zA-Z0-9._-]+$/;
  return s && idRegex.test(s)
    ? undefined
    : "ID can include only letters, numbers, or one of -_.";
};

//similar to isValidId, but allows spaces as names should be more human-readable
export const isValidName = (name: string) => {
  const nameRegex = /[^a-zA-Z0-9-_")(~'.\s]+/g;
  return nameRegex.test(name)
    ? "Name can include only letters, numbers, spaces, or the characters -_\")(~'."
    : undefined;
};

/******************
 EMAIL VALIDATIONS
 ******************/

// This regex came from the Senaps team, and is what Senaps uses to validate emails. Ideally this
// would come from the BSL, but for now, let's at least be in sync.
// TODO: add this to the rules we get from the backend.
export const senapsEmailRegEx =
  /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
export const isEmail = (s: string) => {
  const validEmail = senapsEmailRegEx.test(s);
  if (validEmail) {
    return undefined;
  }
  if (s === undefined || s === "") {
    return "A valid email address is required";
  } else {
    return `"${s}" is not a valid email`;
  }
};

/********************
 PASSWORD VALIDATIONS
 ********************/
export const validatePasswordStrength = (p: string) =>
  p && Z(p).score < 3 ? "A more secure password is required" : undefined;

export const validatePasswordsMatch = (
  p: string,
  comparisonFieldValue?: string
) => (p === comparisonFieldValue ? undefined : "Passwords must match");

/**
 * VALIDATION CONSTANTS
 */
export const API_CALL_DEBOUNCE_MS = 750;

/********************
 ADDRESS VALIDATIONS
 ********************/
export const isValidStreetNumber = (value: string) => {
  const pattern = /(\d+[a-z]*)(-(\d+[a-z]))?/i;
  return !value || pattern.test(value)
    ? undefined
    : `Value '${value}' is not a valid street number.`;
};
export const isValidCountryCode = (value: string) => {
  const pattern = /[A-Z]{2,3}/;
  return !value || pattern.test(value)
    ? undefined
    : `Value '${value}' is not a valid ISO-3166 two- or three-letter code.`;
};

/**
 * URL VALIDATION
 */
export const isValidUrl = (value: string) => {
  if (!value) return undefined;

  try {
    new URL(value);
    return undefined;
  } catch {
    return `Value '${value}' is not a valid URL.`;
  }
};
