/** @jsxImportSource @emotion/react */

import * as React from "react";
import { Link } from "react-router-dom";
import {
  Divider,
  Grid,
  Input,
  Container,
  Button,
  Segment,
  List,
  Dimmer,
  Header,
  Popup,
  Message,
  Accordion,
} from "semantic-ui-react";
import tw, { styled } from "twin.macro";
import { SenapsAPI } from "data/senaps";
import {
  InfoHelper,
  regexFilter,
  SelectInput,
  UIStatus,
  WarningMessage,
} from "components/shared";
import { SearchDetails } from "./SearchDetails";

export const LabelText = styled.p`
  ${tw`font-bold mb-1`}
`;

export const DataInspector: React.FC<{
  workflowNamespace: string;
  streamIDs: Array<string>;
  streamLastMP: Record<string, number>;
  outageStreams: Record<string, Array<string>>;
  vectorLookup: Record<number, string>;
  sensorAPI: SenapsAPI;
  addToInspector: string | undefined;
}> = (props) => {
  const [selectedRegexSearches, setSelectedRegexSearches] = React.useState<
    Array<RegExp>
  >([]);
  const [availableRegexSearches, setAvailableRegexSearches] = React.useState<
    Array<RegExp>
  >([]);
  const [candidateSearch, setCandidateSearch] = React.useState<RegExp>(
    new RegExp(".")
  );
  const [invalidSearch, setInvalidSearch] = React.useState<boolean>(false);
  const [isModified, setIsModified] = React.useState<boolean>(false);
  const [openStreamList, setOpenStreamList] = React.useState<boolean>(false);

  const [candidateSearchResults, setCandidateSearchResults] = React.useState<
    Array<string>
  >([]);

  const [inputValue, setInputValue] = React.useState<string>("");
  const [renderedSearches, setRenderedSearches] = React.useState<Array<RegExp>>(
    []
  );

  const regexTruncationLength: number = 20;
  const [queryStatus, setQueryStatus] = React.useState(new UIStatus());

  React.useEffect(() => {
    if (candidateSearch !== undefined && candidateSearch.source !== "") {
      setCandidateSearchResults(
        props.streamIDs.filter((x) => x.match(candidateSearch))
      );
    } else {
      setCandidateSearchResults([]);
    }
  }, [props.streamIDs, candidateSearch]);

  React.useEffect(() => {
    if (props.addToInspector) {
      const newStr = props.addToInspector;
      setInputValue((p) => {
        const newValue = [p, newStr].join("|");
        const res = p === "" && newStr ? newStr : newValue;
        return res;
      });
      setOpenStreamList(true);
    }
  }, [props.addToInspector]);

  React.useEffect(() => {
    try {
      const pattern = RegExp(inputValue);
      setCandidateSearch(pattern);
      setInvalidSearch(false);
    } catch {
      setInvalidSearch(true);
      setCandidateSearchResults([]);
    }
  }, [inputValue]);

  React.useEffect(() => {
    setSelectedRegexSearches([]);
    setRenderedSearches([]);
    setAvailableRegexSearches([]);
    setCandidateSearch(RegExp("."));
    setInvalidSearch(false);
    setCandidateSearchResults([]);
    setInputValue("");
  }, [props.workflowNamespace]);

  const generateSearchMetrics = React.useMemo(() => {
    setQueryStatus((prevState) => prevState.setIndeterminate(true));

    const searchDetails = (pattern: RegExp, index: number) => {
      return {
        key: pattern.source,
        title: pattern.source,
        index: index,
        content: {
          content: (
            <SearchDetails
              pattern={pattern}
              sensorAPI={props.sensorAPI}
              streamIDs={regexFilter(props.streamIDs, pattern)}
              vectorLookup={props.vectorLookup}
            ></SearchDetails>
          ),
        },
      };
    };

    setQueryStatus((prevState) => prevState.setIndeterminate(false));

    return (
      // <Segment.Group raised>
      <Accordion
        fluid
        inverted
        defaultActiveIndex={[]}
        panels={renderedSearches.flatMap((pattern, ii) =>
          searchDetails(pattern, ii)
        )}
        exclusive={false}
        className="striped"
      />
      // </Segment.Group>
    );
  }, [renderedSearches, props.streamIDs, props.vectorLookup, props.sensorAPI]);

  const errorDisplay = () => (
    <WarningMessage content="Invalid Regular Expression: A regular expression search could not be completed with the input pattern." />
  );

  /**
   * Function to generate a React Semantic UI Dimmer component when waiting on user input
   *
   * @param active - Flag to set whether the dimmer is active or not.
   * @returns React Semantic UI Dimmer component to use on the parent body.
   */
  const dimmerContent = (active: boolean) => (
    <Dimmer active={active}>
      <Header as="h5" icon inverted>
        <Divider hidden />
        <div>Click "Inspect" to reload data</div>
      </Header>
    </Dimmer>
  );

  const addToSelectedSearches = () => {
    if (candidateSearch !== undefined) {
      if (
        !availableRegexSearches
          .map((x) => x.source)
          .includes(candidateSearch.source)
      ) {
        setAvailableRegexSearches(
          availableRegexSearches.concat(candidateSearch)
        );
      }
      if (
        !selectedRegexSearches
          .map((x) => x.source)
          .includes(candidateSearch.source)
      ) {
        setIsModified(true);
        setSelectedRegexSearches(selectedRegexSearches.concat(candidateSearch));
      }
      setInputValue("");
    }
    setCandidateSearch(new RegExp("."));
    setCandidateSearchResults([]);
  };

  const generatePatternResults = () => (
    <Container fluid>
      <List divided relaxed inverted>
        <List.Item key="header">
          <List.Content>
            <b>{candidateSearchResults.length} results found</b>
          </List.Content>
        </List.Item>
        {candidateSearchResults.slice(0, 5).map((x, ii) => (
          <List.Item key={ii}>
            <List.Icon name="chart line" />
            <List.Content>{x}</List.Content>
          </List.Item>
        ))}
        {candidateSearchResults.length > 5 ? (
          <List.Item key={"..."}>
            <List.Content>...</List.Content>
          </List.Item>
        ) : (
          <span />
        )}
      </List>
    </Container>
  );

  const generatePatternSearch = () => (
    <div css={tw`pb-2`}>
      <LabelText>
        Pattern Search{" "}
        <InfoHelper
          title={
            <span>
              <i>Search Pattern</i>
            </span>
          }
          body={
            <div>
              <p>
                A regular expression pattern to filter stream IDs by. This could
                be a simple string, e.g. "Voltage", or something more complex,
                e.g. "building_a.*Voltage". Filtering the IDs this way allows
                for groups of streams to be aggregated, the patterns added to
                the "Selected Patterns" dropdown below, and inspected to produce
                plots of the aggregate data trends.
              </p>
              <p>
                For additional information on regular expressions, see{" "}
                <Link
                  css={tw`text-light`}
                  to={
                    "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions"
                  }
                >
                  here
                </Link>
                .
              </p>
            </div>
          }
        />
      </LabelText>
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column width={13}>
            <Popup
              trigger={
                <Input
                  fluid
                  placeholder="Search Pattern..."
                  // css={tw`mb-2`}
                  value={inputValue}
                  onChange={(_, d) => {
                    setInputValue(d.value.trim());
                  }}
                />
              }
              on="focus"
              open={openStreamList}
              onOpen={() => setOpenStreamList(true)}
              onClose={() => setOpenStreamList(false)}
              content={generatePatternResults()}
              inverted
              position="bottom left"
              flowing
              basic
              style={{ border: "white solid 1px", borderRadius: "5px" }}
            />
          </Grid.Column>
          <Grid.Column width={3}>
            <Button
              fluid
              primary
              labelPosition="right"
              icon="arrow down"
              content="Add to selected patterns"
              disabled={invalidSearch || candidateSearchResults.length === 0}
              onClick={addToSelectedSearches}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );

  const generateSelectedPatterns = () => (
    <div>
      <LabelText>Selected Patterns</LabelText>
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column width={13}>
            <span css={tw`mb-2`}>
              <SelectInput
                isMulti={true}
                placeholder="Select Searches to Inspect..."
                onChange={(value) => {
                  setIsModified(true);
                  setSelectedRegexSearches(
                    (value as Array<string>).map((x) => RegExp(x))
                  );
                }}
                options={availableRegexSearches.map((x) => ({
                  value: x.source,
                  text: ((limit) =>
                    x.source.slice(0, limit) +
                    (x.source.length > limit ? "..." : ""))(
                    regexTruncationLength
                  ),
                }))}
                value={selectedRegexSearches.map((x) => x.source)}
              />
            </span>
          </Grid.Column>
          <Grid.Column width={3}>
            <Button
              fluid
              primary
              labelPosition="right"
              disabled={selectedRegexSearches.length === 0}
              icon="search"
              content="Inspect"
              loading={queryStatus.indeterminate}
              onClick={() => {
                setIsModified(false);
                setRenderedSearches(selectedRegexSearches);
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );

  return (
    <Container fluid>
      <Message attached="bottom" hidden={!invalidSearch} warning>
        {errorDisplay()}
      </Message>

      {generatePatternSearch()}
      <Divider />
      {generateSelectedPatterns()}

      <Dimmer.Dimmable
        as={Segment}
        blurring
        inverted
        dimmed={isModified}
        css={tw`z-0`}
      >
        {generateSearchMetrics}
        {dimmerContent(isModified)}
      </Dimmer.Dimmable>
    </Container>
  );
};
