import React from "react";
import {
  Controller,
  FieldValues,
  RegisterOptions,
  useFormContext,
} from "react-hook-form";
import {
  FormInputProps,
  MarkdownInput,
  MarkdownInputProps,
} from "components/shared";

export type FormMarkdownInputProps<TFieldValues extends FieldValues> =
  FormInputProps<TFieldValues> & MarkdownInputProps;

export const FormMarkdownInput = <TFieldValues extends FieldValues>(
  props: FormMarkdownInputProps<TFieldValues>
) => {
  let rules = props.rules as Omit<
    RegisterOptions<FieldValues>,
    "setValueAs" | "disabled" | "valueAsNumber" | "valueAsDate"
  >;
  if (props.required) rules = { ...rules, required: "Required" };

  const { control } = useFormContext();

  return (
    <Controller
      name={props.name}
      control={control}
      rules={rules}
      defaultValue={
        props.defaultValue ?? ("" as TFieldValues[keyof TFieldValues])
      }
      render={({ field: { value, onChange } }) => {
        return <MarkdownInput value={value} onChange={onChange} {...props} />;
      }}
    />
  );
};
