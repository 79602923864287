import React from "react";
import {
  AuthoritySummary,
  RoleSetResponse,
  UserGroupResponse,
} from "data/Enodia";
import { DropdownItemProps } from "semantic-ui-react";
import { UserPermissions } from "./Permissions";

export function createFilterOption<T>(
  list: Array<T>,
  callback: (a: T) => string | string[]
) {
  return list.length > 0 ? [...new Set(list.flatMap(callback))] : [];
}

export const userFilterOptions = (userList: UserPermissions[]) =>
  createFilterOption(userList, (u: UserPermissions) => u.id);

export const groupsAndSetsToOptions = function (
  x: UserGroupResponse | RoleSetResponse
): DropdownItemProps {
  return {
    value: x.id,
    text: (
      <span>
        <b>{x.name}</b> ({x.id})
      </span>
    ),
  };
};

export const authoritiesToOptions = function (
  x: AuthoritySummary
): DropdownItemProps {
  return {
    value: x.id,
    text: x.title,
  };
};
