/** @jsxImportSource @emotion/react */

import tw from "twin.macro";
import { Icon } from "semantic-ui-react";
import {
  BUILDING_ICON_NAME,
  SITE_ICON_NAME,
} from "components/SitesAndBuildings/SiteOrBuildingIcon";
import { AppDisplayInfo, ApplicationsMode, AppListView } from ".";
import { PublishStateLabel } from "../ManageApplications/PublishStateLabel";

export const AppSitesAndBuildings = ({
  app,
  appListView,
}: {
  app: AppDisplayInfo;
  appListView: AppListView;
}) => {
  return (
    <div css={appListView === AppListView.Rows && tw`flex gap-2 flex-row`}>
      {app.sites && app.sites.length > 0 && (
        <div>
          <Icon name={SITE_ICON_NAME} />
          {app.sites.length > 1
            ? `${
                app.sites[0].label ? app.sites[0].label : app.sites[0].siteId
              } + ${app.sites.length - 1} more`
            : `${
                app.sites[0].label ? app.sites[0].label : app.sites[0].siteId
              }`}
        </div>
      )}
      {app.buildings && app.buildings.length > 0 && (
        <div>
          <Icon name={BUILDING_ICON_NAME} />
          {app.buildings.length > 1
            ? `${
                app.buildings[0].label
                  ? app.buildings[0].label
                  : app.buildings[0].buildingId
              } + ${app.buildings.length - 1} more`
            : `${
                app.buildings[0].label
                  ? app.buildings[0].label
                  : app.buildings[0].buildingId
              }`}
        </div>
      )}
    </div>
  );
};

export const AppStats = ({
  app,
  mode,
  appListView,
}: {
  app: AppDisplayInfo;
  mode: ApplicationsMode;
  appListView: AppListView;
}) => {
  return (
    <div css={tw`flex gap-2`}>
      <PublishedStateIndicator
        mode={mode}
        appListView={appListView}
        isPublished={!!app.published}
      />
      {mode === ApplicationsMode.installed ? (
        <div>{app.applicationName}</div>
      ) : (
        <div>
          <Icon name="download" />
          {`${app?.installedCount ?? 0} installs`}
        </div>
      )}
      <div> | </div>
      <div>{`v${app.version}`}</div>
    </div>
  );
};

const PublishedStateIndicator = ({
  mode,
  appListView,
  isPublished,
}: {
  mode: ApplicationsMode;
  appListView: AppListView;
  isPublished: boolean;
}) => {
  return mode === ApplicationsMode.managed ? (
    <div
      css={appListView === AppListView.Rows ? tw`order-last` : tw`order-first`}
    >
      <PublishStateLabel isPublished={isPublished} />
    </div>
  ) : (
    <></>
  );
};
