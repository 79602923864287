import * as O from "fp-ts/lib/Option";
import { httpPost } from "data/httpUtil";

import { ClientEnv } from "data/utils";
import { masonEnv } from "reducers/env";
import {
  QueryInvocation,
  QueryResponse,
  DescribeResponse,
} from "./queryApiTypes";

export type QueryApi = {
  postSelectQuery: (d: QueryInvocation) => Promise<QueryResponse>;
  postDescribeQuery: (d: QueryInvocation) => Promise<DescribeResponse>;
};

const mkQueryApi = (env: ClientEnv): QueryApi => ({
  postSelectQuery: (d: QueryInvocation) =>
    httpPost(d)(O.none)(env)(null)("/query/briql").then(
      (r) => r as QueryResponse
    ),
  postDescribeQuery: (d: QueryInvocation) =>
    httpPost(d)(O.none)(env)(null)("/query/briql").then(
      (r) => r as DescribeResponse
    ),
});

export const queryApi = mkQueryApi(masonEnv);
