/** @jsxImportSource @emotion/react */
import { DEFAULT_PAGINATION_DATA_LIMIT } from "data/paginationHelper";
import React, { useRef } from "react";
import { Select, Button, Icon } from "semantic-ui-react";
import tw from "twin.macro";

export const resultsPerPageOptions = [10, 25, 50, 100];

export type PaginationState = {
  activePage: number;
  resultsPerPage: number;
};

export const defaultPaginationState: PaginationState = {
  activePage: 1,
  resultsPerPage: resultsPerPageOptions[0],
};

export type PaginationProps = React.PropsWithChildren<{
  links: Record<string, string>;
  onPaginationChange: (link: string) => void;
  setLimit: (limit: number) => void;
  limit: number;
  dataLength: number; // used to calculate if its lower than the set limit
  refetch: () => void;
}>;

/**
 * Usage: limit is tracked within this component. To avoid double calls, remove limit dependency from parent trackers.
 */
export const Pagination: React.FC<PaginationProps> = ({
  links,
  onPaginationChange,
  setLimit,
  limit,
  dataLength,
  refetch,
}) => {
  const prev = links.prev ?? undefined;
  const next = links.next ?? undefined;
  const first = links.first ?? undefined;

  const prevLimit = useRef(limit);

  // api call is only made if:
  // - youre on first and only page and your loaded data is more than the limit you set
  // - there are 2 or more page
  React.useMemo(() => {
    if (prevLimit.current !== limit) {
      if (next || prev || (!next && !prev && dataLength > limit)) {
        refetch();
        prevLimit.current = limit;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit]);

  return (
    <div css={tw`flex w-full my-4 px-4`}>
      <div css={tw`text-center flex-1`}>
        <Button.Group basic inverted>
          <Button
            disabled={!prev}
            onClick={() => {
              onPaginationChange(first);
            }}
          >
            <Icon name="angle double left" inverted />
          </Button>
          <Button
            disabled={!prev}
            onClick={() => {
              onPaginationChange(prev);
            }}
          >
            Prev
          </Button>
          <Button
            disabled={!next}
            onClick={() => {
              onPaginationChange(next);
            }}
          >
            Next
          </Button>
        </Button.Group>
      </div>
      <Select
        css={tw`float-right`}
        style={{ wordWrap: "none !important" }}
        compact
        onChange={(e, data) => setLimit(data.value as number)}
        options={resultsPerPageOptions.map((option) => {
          return { text: option, value: option };
        })}
        value={limit ?? DEFAULT_PAGINATION_DATA_LIMIT}
      />
    </div>
  );
};
