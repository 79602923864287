/** @jsxImportSource @emotion/react */
import saveAs from "file-saver";
import { Icon } from "semantic-ui-react";
import tw from "twin.macro";
import {
  getReportHeader,
  renderReport,
  ShaclValidationResultReportResponse,
  ValidationStage,
} from "data/Mason";
import { DchModal } from "components/shared";
import { BuildingId, SiteId } from "data/brick";

export const VALIDATION_REPORT_MODAL_ID = "ValidationReportModal";

export type ValidationReportProps = {
  isError?: boolean;
  validationReport?: ShaclValidationResultReportResponse;
  validationStage: ValidationStage;
  modelId: SiteId | BuildingId;
};

type ValidationReportModalProps = {
  showModal: boolean;
  onClose: () => void;
} & ValidationReportProps;

export const ValidationReportModal = (props: ValidationReportModalProps) => {
  const {
    showModal,
    isError,
    validationReport,
    onClose,
    validationStage,
    modelId,
  } = props;

  const downloadReport = () => {
    let reportBlob = new Blob([JSON.stringify(validationReport, null, 2)], {
      type: "application/javascript;charset=utf-8",
    });
    saveAs(reportBlob, `${modelId}-validation-report.txt`);
  };

  return (
    <DchModal
      id={VALIDATION_REPORT_MODAL_ID}
      header={getReportHeader(
        !!isError,
        validationReport?.maxLevel,
        validationStage,
      )}
      content={
        validationStage === "validate" && !isError ? (
          <>
            <Icon name="check" color="green" css={tw`pr-2`} />
            Validation Successful - no issues found.
          </>
        ) : (
          <>
            <div css={tw`mb-2`}>
              {isError
                ? `Your model failed to ${validationStage}. Please consult the below validation report for details.`
                : `Your model ${validationStage} has been successful, however the below validation warnings require your attention.`}
            </div>
            {validationReport && renderReport(validationReport)}
          </>
        )
      }
      open={showModal}
      hideConfirm={!validationReport}
      onConfirm={downloadReport}
      onClose={onClose}
      confirmText="Download Report as Text (.txt)"
      cancelText="Close"
    />
  );
};
