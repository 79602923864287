import React, { Dispatch, SetStateAction, useState } from "react";
import { ClassifierSetSearchState } from "./ClassifierSetSearchInput";
import { ClassifierSetListItem } from "data/Aletheia";
import { ClassifierSetMetadata } from "./ClassifierSetMetadataUtils";
import { PointClassifierPageState } from "../usePointClassifierPageState";
import { ClassifierSetActionButtons, ClassifierSetTable } from ".";
import { DCHJsonClassResponse, Unit } from "data/Mason";
import { FormMethods, FormStateValues, UIStatus } from "components/shared";
import { ClassifierSetFormFields } from "./ClassifierSetFormTable";
import { OrgId } from "data/Enodia";

/**
 * @description contains the table component and action buttons in the top segment of  the point classifier page
 */
type ClassifierSetSegmentProps = {
  classifierSetMetadata: ClassifierSetMetadata;
  setClassifierSet: Dispatch<SetStateAction<ClassifierSetMetadata>>;
  classifierSetSearch: ClassifierSetSearchState;
  setClassifierSetSearch: React.Dispatch<
    React.SetStateAction<ClassifierSetSearchState>
  >;
  canSave: boolean;
  setCanSave: React.Dispatch<React.SetStateAction<boolean>>;
  pageState: PointClassifierPageState;
  setShowResults: React.Dispatch<React.SetStateAction<boolean>>;
  classifierHighlightIndex?: number;
  schemaClassesList: DCHJsonClassResponse[];
  unitsList: Unit[];
  setUIStatus: React.Dispatch<React.SetStateAction<UIStatus>>;
  getClassifierSets: (orgId?: OrgId) => Promise<void | ClassifierSetListItem[]>;
  setCanPurgeClassifiers: Dispatch<SetStateAction<boolean>>;
};

const ClassifierSetSegment: React.FC<ClassifierSetSegmentProps> = ({
  classifierSetMetadata,
  setClassifierSet,
  classifierSetSearch,
  setClassifierSetSearch,
  canSave,
  setCanSave,
  pageState,
  setShowResults,
  classifierHighlightIndex,
  schemaClassesList,
  unitsList,
  setUIStatus,
  getClassifierSets,
  setCanPurgeClassifiers,
}) => {
  const [formMethods, setFormMethods] =
    useState<FormMethods<ClassifierSetFormFields>>();
  const [formState, setFormState] =
    useState<FormStateValues<ClassifierSetFormFields>>();

  return (
    <>
      <ClassifierSetTable
        currentClassifierSet={classifierSetMetadata}
        setClassifierSet={setClassifierSet}
        classifierHighlightIndex={classifierHighlightIndex}
        setCanSave={setCanSave}
        schemaClassesList={schemaClassesList}
        unitsList={unitsList}
        setUIStatus={setUIStatus}
        setFormMethods={setFormMethods}
        setFormState={setFormState}
      />
      <ClassifierSetActionButtons
        classifierSetMetadata={classifierSetMetadata}
        setClassifierSetMetadata={setClassifierSet}
        selectedDataPool={pageState.state.datapoolId}
        setShowResults={setShowResults}
        classifierSetSearch={classifierSetSearch}
        setUIStatus={setUIStatus}
        canSave={canSave}
        setCanSave={setCanSave}
        setClassifierSetSearch={setClassifierSetSearch}
        getClassifierSets={getClassifierSets}
        setCanPurgeClassifiers={setCanPurgeClassifiers}
        formMethods={formMethods}
        formState={formState}
        pageState={pageState}
      />
    </>
  );
};
export default ClassifierSetSegment;
