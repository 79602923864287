import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Popup } from "semantic-ui-react";
import {
  ApplicationJobstatusResponse,
  ApplicationUid,
  metisApi,
} from "data/Metis";
import { JobState, UIStatus } from "components/shared";

const stoppedStates = Object.values([
  JobState.Created,
  JobState.Error,
  JobState.Success,
  JobState.Failed,
  JobState.Blocked,
  JobState.NeverRun,
  JobState.Cancelled,
]);

type RecalculateButtonProps = {
  applicationUid: ApplicationUid;
  status: UIStatus;
  setStatus: Dispatch<SetStateAction<UIStatus>>;
  runLabel: string;
  applicationJobState: JobState;
  setJobStatus: Dispatch<SetStateAction<ApplicationJobstatusResponse>>;
  disabled: boolean;
};

const ApplicationRecalculateButton: React.FC<RecalculateButtonProps> = ({
  applicationUid,
  status,
  setStatus,
  runLabel,
  applicationJobState,
  setJobStatus,
  disabled,
}) => {
  const [buttonLabel, setButtonLabel] = useState<string>(runLabel);
  const [icon, setIcon] = useState<string>("play");

  const isStopped = stoppedStates.includes(applicationJobState);
  useEffect(() => {
    setIcon(isStopped ? "play" : "stop");
    setButtonLabel(isStopped ? runLabel : "Stop");
  }, [isStopped, runLabel]);

  const runApplication = () => {
    setJobStatus((p) => ({ ...p, status: JobState.Queued }));
    metisApi.runApplication(applicationUid).catch((e) => {
      setStatus((prevState) =>
        prevState.setError("Error running application.")
      );
    });
  };

  const stopApplication = () => {
    metisApi.stopApplication(applicationUid).then(
      () => {
        setJobStatus((p) => ({ ...p, status: JobState.Cancelled }));
      },
      (e) => {
        setStatus((prevState) =>
          prevState.setError("Error running application.")
        );
      }
    );
  };

  const disabledApplicationPopup = (button: JSX.Element) =>
    disabled ? (
      <Popup
        header="Run/stop disabled by developer"
        content="If this is suspected to be an error, please contact the application developer."
        trigger={<span>{button}</span>}
        inverted
        position="bottom center"
        on="hover"
      />
    ) : (
      button
    );

  return disabledApplicationPopup(
    <Button
      primary
      icon={icon}
      content={buttonLabel}
      disabled={disabled || status.indeterminate}
      labelPosition="left"
      loading={status.indeterminate}
      onClick={() => (isStopped ? runApplication() : stopApplication())}
    />
  );
};

export default ApplicationRecalculateButton;
