import Z from "zxcvbn";

const genPassword = (length: number) => {
  const l = new Uint32Array(length);
  // As good as we are going to get for random values, without going to external libraries.
  // I trust browsers enough, but we also need to throw in some sanity checks.
  // This can be attacked by polyfils, so we need to alert the user to this fact and tell them this is
  // only a suggested password, not one to be taken on faith.
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/crypto
  // https://developer.mozilla.org/en-US/docs/Web/API/Crypto/getRandomValues
  crypto.getRandomValues(l);

  const numToAscii = (n: number): string => {
    const printableMin = 33; // excluding spaces
    const printableMax = 126; // top of printable ascii
    const modRange = printableMax - printableMin;
    const x = n % modRange;
    return String.fromCharCode(x + printableMin);
  };

  return l.reduce((z, a) => z.concat(numToAscii(a)), "");
};

const onStrength =
  <a>(weak: () => a, strong: () => a) =>
  (s: Z.ZXCVBNScore): a =>
    s <= 2 ? weak() : strong();

export const genStrongPassword = (n: number, r: number): string | undefined => {
  if (r <= 0) {
    return undefined;
  } else {
    let p = genPassword(n);
    return onStrength(
      () => genStrongPassword(n + 1, r - 1), // bump up the length until it passes
      () => p
    )(Z(p).score);
  }
};
