/** @jsxImportSource @emotion/react */

import * as React from "react";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";

const CONTACT_EMAIL = "contact.dch@csiro.au";

interface ContactEmailLinkProps {
  label?: string | ReactJSXElement;
}

export const ContactEmailLink: React.FC<ContactEmailLinkProps> = ({
  label = CONTACT_EMAIL,
}) => <a href={`mailto:${CONTACT_EMAIL}`}>{label}</a>;
