import * as React from "react";
import { DchModal, LabelValue } from "components/shared";
import { DCHJsonClassResponse } from "data/Mason";

type IncludeSubclassesModalProps = {
  open: boolean;
  setOpen: (_: boolean) => void;
  options: Array<LabelValue<DCHJsonClassResponse>>;
  selectedOptions: string[];
  updateSelectedValues: (_: string[]) => void;
};

export const IncludeSubclassesModal: React.FC<IncludeSubclassesModalProps> = ({
  open,
  setOpen,
  options,
  selectedOptions,
  updateSelectedValues,
}) => {
  return (
    <DchModal
      header="Expand classes?"
      content={
        <p>
          Click <strong>Yes</strong> to individually add every subclass into the
          list.
        </p>
      }
      open={open}
      onClose={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      onConfirm={() => {
        const subclasses = options
          .filter(({ value }) => {
            return (
              value.parents.find((p) => selectedOptions.includes(p)) !==
              undefined
            );
          })
          .map(({ value }) => value.type);
        updateSelectedValues(subclasses);
        setOpen(false);
      }}
      cancelText="No"
      confirmText="Yes"
    />
  );
};
