/** @jsxImportSource @emotion/react */

import { useFormContext } from "react-hook-form";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { Button, Divider, Grid, Icon } from "semantic-ui-react";
import tw from "twin.macro";
import { OrgId } from "data/Enodia";
import { Path } from "Routes";

export const BuildingIds = ({
  orgId,
  buildingIds,
}: {
  orgId: OrgId;
  buildingIds: string[];
}) => {
  const navigate = useNavigate();
  const { getValues } = useFormContext();
  return (
    <>
      <Divider />
      <Grid>
        <Grid.Row>
          <Grid.Column>
            Building IDs{" "}
            <Button
              basic
              type="button"
              inverted
              style={{ marginLeft: "1em" }}
              onClick={() => {
                const siteId = getValues("siteId");
                navigate(
                  generatePath(Path.NewBuildingFromSite, {
                    orgId: orgId as string,
                    siteId: siteId,
                  })
                );
              }}
            >
              <Icon name="plus" />
              Add new building
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Grid>
              <Grid.Row columns="equal">
                {buildingIds.map((id) => (
                  <Grid.Column
                    largeScreen={4}
                    computer={4}
                    tablet={8}
                    mobile={16}
                    key={id}
                  >
                    <div>
                      <Icon name="building" color="purple" inverted />
                      <Link
                        css={tw`text-light hover:text-white hover:underline`}
                        to={generatePath(Path.ViewBuilding, {
                          orgId: orgId as string,
                          siteId: getValues("siteId"),
                          buildingId: id,
                        })}
                      >
                        {id}
                      </Link>
                    </div>
                  </Grid.Column>
                ))}
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};
