import React from "react";
import { Header } from "semantic-ui-react";

export enum PrimaryTitle {
  AppMarketplace = "Applications Marketplace",
  InstalledApps = "Applications Dashboard",
  ManageApps = "Application Developer Dashboard",
}

interface Props {
  primaryHeader?: string | JSX.Element;
  secondaryHeader?: string | JSX.Element;
  subHeader?: string | JSX.Element;
  heroText?: string | JSX.Element;
  primaryTitle?: PrimaryTitle;
  secondaryTitle?: string | JSX.Element;
}

export const PageTitle = (props: Props) => {
  return (
    <>
      {props.primaryTitle && <Header as="h1">{props.primaryTitle}</Header>}
      {props.primaryHeader && <Header as="h1">{props.primaryHeader}</Header>}
      {props.secondaryTitle && <Header as="h2">{props.secondaryTitle}</Header>}
      {props.secondaryHeader && (
        <Header as="h2">{props.secondaryHeader}</Header>
      )}
      {props.subHeader && <Header as="h3">{props.subHeader}</Header>}
      {props.heroText && <p>{props.heroText}</p>}
    </>
  );
};
