/** @jsxImportSource @emotion/react */

import React from "react";
import { SvgBuilding } from "./svg-building";

interface SvgBuildingsProps {
  scale?: number;
}

export const SvgBuildings: React.FC<SvgBuildingsProps> = ({ scale = 0.5 }) => (
  <span>
    <span>
      <SvgBuilding scale={scale} />
    </span>

    <span css={{ position: "relative", top: 5 }}>
      <SvgBuilding scale={scale} />
    </span>

    <span>
      <SvgBuilding scale={scale} />
    </span>
  </span>
);
