import { Path } from "Routes";
import React, { useContext } from "react";
import { Outlet, useLocation, matchPath, Navigate } from "react-router-dom";
import { isLoginRequired } from "data/auth";
import { AuthContext } from "App";

export const PublicRoutesLayout: React.FC<{ children?: any }> = ({
  children,
}) => {
  const authContext = useContext(AuthContext);
  const location = useLocation();

  const loginRequired = isLoginRequired(authContext);
  // helper to match current page path to destination paths
  const validRedirect = React.useCallback(
    (destination: Path) => matchPath(destination, location.pathname),
    [location.pathname]
  );

  if (
    (validRedirect(Path.Index) ||
      validRedirect(Path.Login) ||
      validRedirect(Path.Signup)) &&
    !loginRequired
  ) {
    return <Navigate to={Path.Home} replace />;
  }

  // handle all the public pages through outlet
  return children ?? <Outlet />;
};
