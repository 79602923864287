import React from "react";
import {
  Controller,
  FieldValues,
  RegisterOptions,
  useFormContext,
} from "react-hook-form";
import { SelectInput, SelectInputProps } from "components/shared";
import { FormInputProps } from "../ReactHookForm";
import { ASYNC_ERROR_SUFFIX } from "../useAsyncValidate";

export type FormSelectInputProps<TFieldValues extends FieldValues> =
  FormInputProps<TFieldValues> & SelectInputProps;

export const FormSelectInput = <TFieldValues extends FieldValues>(
  props: FormSelectInputProps<TFieldValues>
) => {
  let rules = props.rules as Omit<
    RegisterOptions<FieldValues>,
    "setValueAs" | "disabled" | "valueAsNumber" | "valueAsDate"
  >;
  if (props.required) rules = { ...rules, required: "Required" };

  const { control, getFieldState } = useFormContext();

  const asyncFieldState = getFieldState(`${props.name}${ASYNC_ERROR_SUFFIX}`);

  return (
    <Controller
      name={props.name}
      control={control}
      rules={rules}
      defaultValue={
        props.defaultValue ?? props.isMulti
          ? ([] as TFieldValues[keyof TFieldValues])
          : ("" as TFieldValues[keyof TFieldValues])
      }
      render={({ field: { onBlur, onChange, value, name }, fieldState }) => {
        const invalidFieldState =
          ((props.validateOnChange && fieldState.isDirty) ||
            fieldState.isTouched) &&
          (!!fieldState.error || !!asyncFieldState.error);

        const inputValidation = rules
          ? {
              invalid: invalidFieldState,
              errorMessage:
                fieldState.error?.message ??
                (asyncFieldState.error?.message as string),
            }
          : undefined;

        return (
          <SelectInput
            {...props}
            formProps={{
              onBlur: onBlur,
              onChange: (_: any, d: any) => {
                onChange(d.value);
                !d.value && props.onClear && props.onClear();
              },
              value: value,
              name: name,
            }}
            inputValidation={inputValidation}
            testId={name}
          />
        );
      }}
    />
  );
};
