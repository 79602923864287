import React, { ReactElement } from "react";
import { Grid } from "semantic-ui-react";

type SearchOptionProps = {
  input: ReactElement;
  rightCheckboxes: ReactElement[];
};

export const SearchOption = ({ input, rightCheckboxes }: SearchOptionProps) => {
  const hasRightCheckboxes = rightCheckboxes && rightCheckboxes.length > 0;
  return (
    <Grid>
      <Grid.Row verticalAlign="middle">
        <Grid.Column
          mobile={16}
          tablet={hasRightCheckboxes ? 12 : 16}
          computer={hasRightCheckboxes ? 12 : 16}
          largeScreen={hasRightCheckboxes ? 12 : 16}
        >
          {input}
        </Grid.Column>
        {hasRightCheckboxes && (
          <Grid.Column mobile={16} tablet={4} computer={4} largeScreen={4}>
            {rightCheckboxes}
          </Grid.Column>
        )}
      </Grid.Row>
    </Grid>
  );
};
