import React from "react";
import { createRoot } from "react-dom/client";

import "semantic-ui-less/semantic.less";
import "./index.less";
import "./container.css";

import { App } from "./App";
import * as serviceWorker from "./serviceWorker";

import { LogLevel, LoggerFactory } from "./logger";

LoggerFactory.setLevel(
  (LogLevel as any)[process.env.REACT_APP_LOG_LEVEL as string],
);

const l = LoggerFactory.create("root");
l.debug(`Log level is set to ${process.env.REACT_APP_LOG_LEVEL}`);

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
