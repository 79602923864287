/** @jsxImportSource @emotion/react */
import React, { useState, useCallback, useContext } from "react";
import { matchRoutes, useLocation } from "react-router-dom";
import { Grid, Input } from "semantic-ui-react";
import { debounce } from "throttle-debounce";
import tw from "twin.macro";
import { EnodiaOrgContext } from "App";
import { Path } from "Routes";
import { DEFAULT_INPUT_WIDTHS } from "components/shared";

type ReactProps = { children?: React.ReactNode };

type FilterInputProps = {
  handleSearch: (searchTerm: string) => void;
  placeholder?: string;
  fieldLabel?: string | React.ReactNode;
  onClear?: () => void;
  value?: string;
} & ReactProps;

export const FilterInput = function (props: FilterInputProps) {
  const { orgId } = useContext(EnodiaOrgContext);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(
    props.value ?? ""
  );
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(500, (term: string) => props.handleSearch(term)),
    []
  );

  const clearSearch = useCallback(() => {
    setSearchTerm("");
    props.handleSearch("");
    props.onClear && props.onClear();
  }, [props]);

  /**
   * We want to reset the search for pages where orgId changes, but
   * retain search on other pages.
   *
   */
  const routesToMatch = [Path.Home, Path.Permissions].map((p) => ({
    path: p,
    exact: true,
  }));
  React.useEffect(() => {
    if (matchRoutes(routesToMatch, pathname)) {
      clearSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  const handleChange = (value: string) => {
    setSearchTerm(value);
    debouncedSearch(value);
  };

  const renderFilter = () => {
    return (
      <Input
        icon={{ name: "close", link: true, onClick: clearSearch }}
        css={tw`border-0 border-b border-white border-solid flex-1 pt-2 pb-2 max-w-full w-full`}
        placeholder={props.placeholder || "Search..."}
        transparent
        value={searchTerm}
        onChange={(e) => handleChange(e.target.value)}
      />
    );
  };

  return props?.fieldLabel ? (
    <Grid>
      <Grid.Row>
        <Grid.Column {...DEFAULT_INPUT_WIDTHS.label} verticalAlign="middle">
          <strong>{props.fieldLabel}</strong>
        </Grid.Column>
        <Grid.Column {...DEFAULT_INPUT_WIDTHS.input}>
          {renderFilter()}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  ) : (
    renderFilter()
  );
};
