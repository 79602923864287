import styles from "./CurlyBrace.module.css";
import * as React from "react";

type CurlyBraceProps = {
  right?: boolean;
};

export const CurlyBrace: React.FC<CurlyBraceProps> = ({ right = true }) => {
  const getClass = (name: string) => {
    return `${styles[`${right ? "right" : "left"}-${name}`]} ${
      styles["section"]
    }`;
  };

  return (
    <div>
      <span className={getClass("brace-tl")} />
      <span className={getClass("brace-tr")} />
      <br />
      <span className={getClass("brace-bl")} />
      <span className={getClass("brace-br")} />
    </div>
  );
};
