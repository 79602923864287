/** @jsxImportSource @emotion/react */

import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import tw from "twin.macro";
import { ModelId } from "data/brick";
import { OrgId } from "data/Enodia";
import {
  BuildingGetRequest,
  ShaclValidationResultReportResponse,
  masonApi,
  SiteGetRequest,
  ModelPublishStatus,
  renderReport,
} from "data/Mason";
import { transformFormFieldsToSerialisedModel } from "data/Mason/ModelDraft/ModelDraftUtils";
import { UIStatusWrapper, UIStatus, PageMode } from "components/shared";
import Form, { FormStateValues } from "components/shared/Forms/ReactHookForm";

import { ModelActions } from "./Actions/ModelActions";
import { ModelUploadModal } from "./Actions/ModelUploadModal";
import { ModelDownloadModal } from "./Actions/ModelDownloadModal";
import { ModelFormFields } from "./Form/SiteOrBuildingModelForm";
import { QueryParams } from "./ModelUtils";
import {
  BaseSiteOrBuildingModelProps,
  SiteOrBuildingModel,
} from "./SiteOrBuildingModel";
import {
  ValidationReportModal,
  ValidationReportProps,
} from "./Actions/ValidationReportModal";

type SiteOrBuildingWrapperProps = BaseSiteOrBuildingModelProps & {
  handleModelReset: () => void;
  setHasPendingDrafts: (value: boolean) => void;
  refetchModel?: () => void;
};

export const SiteOrBuildingModelWrapper = (
  props: SiteOrBuildingWrapperProps,
) => {
  const {
    orgId,
    siteId,
    buildingId,
    modelType,
    modelMeta,
    handleModelReset,
    selectedElement,
    setHasPendingDrafts,
    refetchModel,
  } = props;
  const [pageMode, setPageMode] = useState(PageMode.view);
  const [status, setStatus] = useState(new UIStatus());

  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [validationReportProps, setValidationReportProps] =
    useState<ValidationReportProps>();

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const nodeId = queryParams.get(QueryParams.id);
  const index = queryParams.get(QueryParams.streamIndex);

  const [formState, setFormState] = useState<FormStateValues<ModelFormFields>>({
    isDirty: false,
    isValid: true,
    isSubmitSuccessful: false,
    errors: {},
    dirtyFields: {},
  });

  useEffect(() => {
    if (selectedElement.type !== "model" && !index) {
      navigate(`${location.pathname}?id=${selectedElement.id}`);
      // if there is no query params
    } else if (selectedElement.type !== "model" && !nodeId) {
      navigate(location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkValidation = useCallback(() => {
    setStatus((s) => s.setIndeterminate(true));

    const requestParams = {
      orgId: orgId,
      siteId: siteId,
      buildingId: buildingId,
    };

    (buildingId
      ? masonApi.validateBuilding(requestParams as BuildingGetRequest)
      : masonApi.validateSite(requestParams as SiteGetRequest)
    )
      .then((response) => {
        setValidationReportProps({
          isError: !response.isSuccessful,
          validationReport: response.validationReport,
          validationStage: "validate",
          modelId: (buildingId ?? siteId) as ModelId,
        });
        setStatus((s) => s.setIndeterminate(false));
      })
      .catch((error) => {
        setStatus((p) =>
          p.setError(error.message || `Unable to extract validation report`),
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingId, modelType, orgId, siteId]);

  const handleSubmit = (formValues: ModelFormFields) => {
    const model = transformFormFieldsToSerialisedModel(
      formValues,
      modelMeta,
      modelType,
    );
    setStatus((s) => s.setIndeterminate(true));

    const params = {
      orgId: orgId,
      siteId,
      buildingId,
    };

    const onError = (report: ShaclValidationResultReportResponse) => {
      setStatus((prevState) => prevState.setError(report));
    };
    (buildingId
      ? masonApi.putDraftBuildingJSON(params as BuildingGetRequest, model)
      : masonApi.putDraftSiteJSON(params as SiteGetRequest, model)
    )
      .then(() => {
        masonApi
          .publishSite(params as SiteGetRequest)
          .then(
            () => {
              setStatus((s) => s.setIndeterminate(false));
              setPageMode(PageMode.view);
              handleModelReset();
            },
            (e) => {
              onError(e);
            },
          )
          .then((_) => {
            masonApi.getPublishStatus(params as SiteGetRequest).then((res) => {
              setHasPendingDrafts(res.status === ModelPublishStatus.Draft);
            });
          });
      })
      .catch(onError);
  };

  const handleCancel = () => {
    setPageMode(PageMode.view);
    if (formState.isDirty) handleModelReset();
  };

  return (
    <UIStatusWrapper status={status} errorRenderer={renderReport}>
      <ModelUploadModal
        orgId={orgId}
        siteId={siteId!!}
        buildingId={buildingId}
        showModal={showUploadModal}
        setShowModal={setShowUploadModal}
        modelType={modelType}
        existingModel={true}
        refetchModel={refetchModel}
        setValidationReportProps={setValidationReportProps}
      />
      <ModelDownloadModal
        isOpen={showDownloadModal}
        setIsOpen={setShowDownloadModal}
        modelType={modelType}
        {...{ orgId: orgId as OrgId, siteId: siteId!!, buildingId }}
      />
      {validationReportProps && (
        <ValidationReportModal
          showModal={!!validationReportProps}
          {...validationReportProps}
          onClose={() => {
            setValidationReportProps(undefined);
            if (
              !validationReportProps?.isError &&
              validationReportProps.validationStage !== "validate"
            )
              navigate(0);
          }}
          modelId={(buildingId ?? siteId) as ModelId}
        />
      )}
      <Form
        onSubmit={handleSubmit}
        setFormState={(state) => setFormState(state)}
      >
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <ModelActions
                pageMode={pageMode}
                formState={formState}
                handleCancel={handleCancel}
                setShowUploadModal={setShowUploadModal}
                setShowDownloadModal={setShowDownloadModal}
                checkIssues={() => checkValidation()}
              />
              {
                <p css={tw`text-red-error`}>
                  {formState.isDirty && !formState.isValid
                    ? "This form has errors."
                    : ""}
                </p>
              }
            </Grid.Column>
          </Grid.Row>
          <SiteOrBuildingModel {...props} pageMode={pageMode} />
        </Grid>
      </Form>
    </UIStatusWrapper>
  );
};
