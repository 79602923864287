import React, { useEffect } from "react";
import { Page, PageTitle, UIStatusWrapper } from "components/shared";
import { PointTable } from "../PointTable/PointTable";
import { usePointTablePageState } from "../PointTable/usePointTablePageState";

const PointExplorer: React.FunctionComponent = () => {
  const pageState = usePointTablePageState();

  // call GET /points on org change
  useEffect(() => {
    if (pageState.state.orgId) {
      pageState.getPoints();
    }

    // cannot allow .state.pointSearchParams, since it allows additional fetch call when limit is changed.
    // Limit change fetch is manually handled within its <Pagination> component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageState.state.orgId,
    pageState.state.pointSearchParams.id,
    pageState.state.pointSearchParams.unit,
  ]);

  return (
    <Page>
      <PageTitle
        primaryHeader="Point Explorer"
        subHeader="Search and Inspect"
        heroText="Find and view all of your available points, and assign them to your models."
      />
      <UIStatusWrapper status={pageState.state.pointsStatus}>
        <PointTable pageState={pageState} />
      </UIStatusWrapper>
    </Page>
  );
};

export default PointExplorer;
