import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { debounce } from "throttle-debounce";
import { EnodiaOrgContext } from "App";
import { GetApplicationsSearchParams, metisApi } from "data/Metis";
import {
  Page,
  PageTitle,
  UIStatusWrapper,
  UIStatus,
  PrimaryTitle,
} from "components/shared";
import {
  AppDisplayInfo,
  ApplicationsList,
  ApplicationsMode,
  AppListView,
  appResponsetoAppDisplayInfo,
  defaultFilters,
  FilterApplicationsHeader,
  FilterOption,
  SortByOption,
} from "components/Applications/shared";

export const InstalledApplications = () => {
  const { orgId } = useContext(EnodiaOrgContext);
  const [apps, setApps] = useState<Array<AppDisplayInfo>>([]);
  const defaultAppsRef = useRef(apps);
  const [appListView, setAppListView] = useState<AppListView>(AppListView.Grid);
  const [appListQuery, setAppListQuery] =
    useState<GetApplicationsSearchParams>(defaultFilters);
  const [uiStatus, setUiStatus] = useState(new UIStatus(true));

  //when we know the orgId, add it to the search params
  useEffect(() => {
    if (
      orgId &&
      (!appListQuery.organisationId || appListQuery.organisationId !== orgId)
    ) {
      setAppListQuery((prev) => ({ ...prev, organisationId: orgId }));
    }
  }, [appListQuery.organisationId, orgId]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetch = useCallback(
    debounce(500, (queryParams: GetApplicationsSearchParams) => {
      setUiStatus((prevState) => prevState.setIndeterminate(true));
      return metisApi.getApplications(queryParams).then(
        (applications) => {
          const appsWithInstallMeta = applications.map((app) =>
            appResponsetoAppDisplayInfo(ApplicationsMode.installed, app)
          );
          setApps(appsWithInstallMeta);
          //on first fetch of default list, set ref
          if (
            defaultAppsRef.current.length === 0 &&
            appsWithInstallMeta.length > 0
          )
            defaultAppsRef.current = appsWithInstallMeta;
          setUiStatus((prevState) => prevState.setSuccessful(true));
        },
        (e) => {
          setUiStatus((prevState) =>
            prevState.setError("Error loading applications: " + e.message)
          );
        }
      );
    }),
    []
  );

  // on first load and when search params change, fetch all available applications
  useEffect(() => {
    debouncedFetch(appListQuery);
  }, [appListQuery, debouncedFetch]);

  return (
    <Page>
      <PageTitle
        primaryTitle={PrimaryTitle.InstalledApps}
        secondaryHeader={"Explore Installed Applications"}
      />
      <FilterApplicationsHeader
        appListView={appListView}
        setAppListView={setAppListView}
        appListQuery={appListQuery}
        setAppListQuery={setAppListQuery}
        filterOptions={[
          FilterOption.category,
          FilterOption.application,
          FilterOption.site,
          FilterOption.building,
        ]}
        sortByOptions={[
          SortByOption.instanceName,
          SortByOption.applicationName,
          SortByOption.createdTime,
        ]}
        initialAppList={defaultAppsRef.current}
      />
      <UIStatusWrapper status={uiStatus}>
        <ApplicationsList
          apps={apps}
          mode={ApplicationsMode.installed}
          appListView={appListView}
          uiStatus={uiStatus}
        />
      </UIStatusWrapper>
    </Page>
  );
};
