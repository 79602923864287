import { SiteMetaResponse, BuildingMetaResponse } from "data/Mason";
import { ApplicationUid } from "data/Metis";
import { VendorId } from "data/Metis/Vendor/vendorTypes";

// common and optional info displayed in the application cards - anything not rendered is not included
export type AppDisplayInfo = {
  applicationUid: ApplicationUid;
  applicationName: string;
  version: string;
  categories: string[];
  applicationRecipeUid?: ApplicationUid;
  applicationId?: ApplicationUid;
  vendorId?: VendorId;
  instanceName?: string;
  instanceUid?: ApplicationUid;
  published?: boolean;
  description?: string;
  installedCount?: number;
  lastKnownRun?: string;
  sites?: SiteMetaResponse[];
  buildings?: BuildingMetaResponse[];
};

export enum AppListView {
  Grid,
  Rows,
}

export enum ApplicationsMode {
  available = "Available",
  installed = "Installed",
  managed = "Manage",
}

export interface CommonAppProps {
  app: AppDisplayInfo;
  mode: ApplicationsMode;
}
