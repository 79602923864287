import React from "react";
import { Button, ButtonProps, Icon, SemanticICONS } from "semantic-ui-react";

type Props = {
  submit: boolean;
  label: string;
  icon?: SemanticICONS;
  iconPosition?: "left" | "right";
  onClick?: () => void;
} & Pick<ButtonProps, "size" | "disabled" | "loading">;

export const FormButton = (props: Props) => {
  const { submit, icon, label, iconPosition, ...otherProps } = props;
  const buttonProps: ButtonProps = props.submit
    ? { type: "submit", primary: true }
    : { type: "button", basic: true, inverted: true };
  return (
    <Button {...otherProps} {...buttonProps}>
      {icon && iconPosition !== "right" && <Icon name={icon} />}
      {label}
      {icon && iconPosition === "right" && <Icon name={icon} />}
    </Button>
  );
};
