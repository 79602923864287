/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import { FunctionComponent, SetStateAction, Dispatch, useState } from "react";

import { DchModal, UIStatus } from "components/shared";
import { ActionButton } from "components/Tools/PointClassifier/ActionButton";
import { ClassificationPlanId, pointClassifierApi } from "data/Aletheia";
import { mkClassificationPlanEPFormId } from "./ClassificationPlanUtils";

type ActionButtonProps = {
  classificationPlanId: ClassificationPlanId;
  refetch: () => void;
  setStatus: Dispatch<SetStateAction<UIStatus>>;
  disabled?: boolean;
  manualChangesCount: number;
  activeManualDirectiveRow: number | undefined;
  onSubmit: () => void;
};

export const ClassificationPlanActionButtons: FunctionComponent<
  ActionButtonProps
> = ({
  classificationPlanId,
  setStatus,
  disabled,
  refetch,
  manualChangesCount,
  activeManualDirectiveRow,
  onSubmit,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const onClearManual = () => {
    pointClassifierApi
      .clearClassificationPlanManualChanges(classificationPlanId)
      .then(refetch)
      .catch((e) => setStatus((p) => p.setError(e.message)));
  };

  return (
    <div css={tw`w-full flex justify-between`}>
      <DchModal
        header="Confirm Manual Changes"
        content={`There ${
          manualChangesCount > 1 ? "are" : "is"
        } ${manualChangesCount} manual change${
          manualChangesCount > 1 ? "s" : ""
        } on this page. Do you wish to apply these changes?`}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={() => onSubmit()}
        confirmText="Confirm"
      />
      <span>
        <ActionButton
          label="Clear Manual Changes"
          icon="trash"
          onClick={onClearManual}
          basic
        />
      </span>
      <span>
        <ActionButton
          form={mkClassificationPlanEPFormId(activeManualDirectiveRow)}
          type="submit"
          disabled={disabled}
          label="Apply Changes"
          onClick={() => {
            if (activeManualDirectiveRow === undefined) {
              manualChangesCount > 0 ? setModalOpen(true) : onSubmit();
            }
          }}
          noStyle
        />
      </span>
    </div>
  );
};
