/** @jsxImportSource @emotion/react */

import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Dimmer, Grid, Icon } from "semantic-ui-react";
import tw from "twin.macro";
import { EnodiaOrgContext } from "App";
import { Path } from "Routes";
import { RoleSetId, RoleSetResponse, UserGroupResponse } from "data/Enodia";
import { toOptionTypeBase } from "components/shared/Common";
import {
  FilterInput,
  PageSection,
  SelectInput,
  UIStatus,
  UIStatusWrapper,
} from "components/shared";
import { UserGroupTable } from "./UserGroupTable";
import { groupsAndSetsToOptions, userFilterOptions } from "../FilterByOptions";
import { UserPermissions } from "../Permissions";

type Props = {
  userGroups: Array<UserGroupResponse>;
  userGroupStatus: UIStatus;
  roleSets: Array<RoleSetResponse>;
  userList: Array<UserPermissions>;
  userListStatus: UIStatus;
  roleSetStatus: UIStatus;
};

export const UserGroups: React.FunctionComponent<Props> = ({
  userGroups,
  userGroupStatus,
  roleSets,
  userList,
  userListStatus,
  roleSetStatus,
}) => {
  const { orgId } = useContext(EnodiaOrgContext);
  const navigate = useNavigate();
  const [userGroupSearchTerm, setUserGroupSearchTerm] = useState("");
  const [userSearchTerm, setUserSearchTerm] = useState("");
  const [roleSetSearchTerm, setRoleSetSearchTerm] = useState("");

  const users = userFilterOptions(userList);

  const userGroupsWithUsers = userList
    .filter((u) => u.id === userSearchTerm)
    .flatMap((u) => u.userGroups)
    .map((rs) => rs.id);

  const filteredUserGroups = userGroups.filter((ug) => {
    return (
      (!userGroupSearchTerm ||
        ug.name.toLowerCase().includes(userGroupSearchTerm)) &&
      (!userSearchTerm || userGroupsWithUsers.includes(ug.id)) &&
      (!roleSetSearchTerm ||
        ug.roleSetIDs?.includes(roleSetSearchTerm as RoleSetId))
    );
  });

  return (
    <PageSection
      header={
        <div>
          <Icon name="users" color="purple" inverted />
          <span> User Groups</span>
        </div>
      }
    >
      <div
        css={tw`absolute bg-black px-2 right-6`}
        style={{
          top: "calc((1em + .25rem) * -1)", //position according to half the button's own height
        }}
      >
        <Button
          id="AddUserGroup"
          size="small"
          primary
          onClick={() => navigate(Path.AddUserGroup)}
          disabled={!orgId}
        >
          <Icon name="plus" />
          Add User Group
        </Button>
      </div>
      <UIStatusWrapper status={userGroupStatus} customCss={tw`z-0`} fitted>
        <Dimmer.Dimmable blurring dimmed={!orgId} css={tw`z-0`}>
          <Dimmer active={!orgId} css={tw`w-full`}>
            <div>Select an organisation to view user groups</div>
          </Dimmer>
          <div css={tw`w-full`} className="dch-scrollbar">
            <Grid>
              <Grid.Row columns="equal">
                <Grid.Column>
                  <FilterInput
                    fieldLabel="User Group Search"
                    placeholder="Enter User Group..."
                    handleSearch={(term: string) =>
                      setUserGroupSearchTerm(term.toLowerCase())
                    }
                  />
                </Grid.Column>
                <Grid.Column>
                  <SelectInput
                    label="Filter by user"
                    placeholder="Search for user..."
                    value={userSearchTerm}
                    options={users.map(toOptionTypeBase)}
                    onChange={(_, { value }) => {
                      setUserSearchTerm(value as string);
                    }}
                  />
                </Grid.Column>
                <Grid.Column>
                  <SelectInput
                    label="Filter by RoleSet"
                    placeholder="Search for RoleSet..."
                    value={roleSetSearchTerm}
                    options={roleSets.map(groupsAndSetsToOptions)}
                    onChange={(_, { value }) => {
                      setRoleSetSearchTerm(value as string);
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <div
                    className="dch-scrollbar"
                    style={{ maxHeight: "20rem", overflowY: "auto" }}
                  >
                    <UserGroupTable
                      userGroupList={filteredUserGroups}
                      roleSets={roleSets}
                      userList={userList}
                      userListStatus={userListStatus}
                      roleSetStatus={roleSetStatus}
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </Dimmer.Dimmable>
      </UIStatusWrapper>
    </PageSection>
  );
};
