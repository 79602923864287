import { Option } from "fp-ts/lib/Option";
import { Lens } from "monocle-ts";

export enum HttpScheme {
  HTTP = "http",
  HTTPS = "https",
  WS = "ws",
  WSS = "wss",
}

export type Headers = { [key: string]: string };

export type ClientEnv = {
  scheme: HttpScheme;
  host: Option<string>;
  port: Option<number>;
  headers: Headers;
  baseUrl: string;
  nullablePort?: boolean;
};
export type SenapsClientEnv = Omit<ClientEnv, "baseUrl">;

export const ClientEnvHeaders = <e extends Pick<ClientEnv, "headers">>() =>
  Lens.fromProp<e>()("headers");

export const saveToFile = (content: string, filename: string, type: string) => {
  const blob = new Blob([content], { type: `${type};charset=utf-8;` });
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    // Create our anchor object to link out to the CSV download
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // Generate a new URL for the link
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      // Attach link object to DOM and activate, then remove the DOM object
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export const downloadCsv = (csvData: string, filename: string) => {
  saveToFile(csvData, filename + ".csv", "text/csv");
};
