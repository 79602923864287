/** @jsxImportSource @emotion/react */
import { Grid, Icon } from "semantic-ui-react";
import tw from "twin.macro";
import { decodeResponse, errorMsg } from "data/http";

/* VALIDATION */
enum ShaclValidationLevel {
  CLEAN = "Clean",
  INFO = "Info",
  WARNING = "Warning",
  ERROR = "Error",
  CRITICAL = "Critical",
  NONE = "None",
}

export type ValidationStage = "upload" | "publish" | "validate";

const ShaclValidationSuccessLevels = [
  ShaclValidationLevel.CLEAN,
  ShaclValidationLevel.NONE,
];

export const isSuccessReport = (
  validationReport: ShaclValidationResultReportResponse,
) => ShaclValidationSuccessLevels.includes(validationReport.maxLevel);

type ShaclValidationEntryResponse = {
  level: ShaclValidationLevel;
  message: string;
  schema: string;
  rule: string;
  path?: string;
  value: string;
  nodeLabel?: string;
  node?: string;
};

export type ShaclValidationResultReportResponse = {
  maxLevel: ShaclValidationLevel;
  entries: ShaclValidationEntryResponse[];
};

export const defaultValidationReport: ShaclValidationResultReportResponse = {
  maxLevel: ShaclValidationLevel.CLEAN,
  entries: [],
};

const isShaclValidationResultReportResponse = (
  value: ShaclValidationResultReportResponse,
): value is ShaclValidationResultReportResponse => {
  return !!value.maxLevel && !!value.entries && value.entries.length > 0;
};

export type ModelShaclReportResponse = {
  isSuccessful: boolean;
  validationReport?: ShaclValidationResultReportResponse;
  errorMessage?: string;
};

const parseErrorMessage = (
  decodedResponse: object | string | null,
): string | undefined => {
  if (decodedResponse === null) return;
  if (typeof decodedResponse === "string") return decodedResponse;
  return errorMsg(decodedResponse);
};

export const getValidationResponse = async (
  response: Response,
): Promise<ModelShaclReportResponse> => {
  const isSuccessful = response.ok;
  const decodedResponse = await decodeResponse<
    ShaclValidationResultReportResponse | string | null | object
  >(response, true);
  const validationReport =
    decodedResponse as ShaclValidationResultReportResponse;
  const isShaclReport = isShaclValidationResultReportResponse(
    decodedResponse as ShaclValidationResultReportResponse,
  );

  const hasValidationReport =
    !!isShaclReport &&
    !!validationReport?.maxLevel &&
    !isSuccessReport(validationReport);

  return {
    isSuccessful,
    validationReport: hasValidationReport ? validationReport : undefined,
    errorMessage:
      !isSuccessful && !hasValidationReport
        ? (parseErrorMessage(decodedResponse) ?? "Unknown Error")
        : undefined,
  };
};

const reportColour = (level: ShaclValidationLevel) => {
  switch (level) {
    case ShaclValidationLevel.CRITICAL:
    case ShaclValidationLevel.ERROR:
      return tw`text-red-error`;
    case ShaclValidationLevel.WARNING:
      return tw`text-warning`;
    default:
      return tw`text-white`;
  }
};
const statusSymbol = (level?: ShaclValidationLevel) => {
  switch (level) {
    case ShaclValidationLevel.CRITICAL:
    case ShaclValidationLevel.ERROR:
      return "times circle";
    case ShaclValidationLevel.WARNING:
      return "warning sign";
    case ShaclValidationLevel.INFO:
      return "info circle";
    default:
      return "check circle";
  }
};

export const getReportHeader = (
  isError: boolean,
  maxLevel?: ShaclValidationLevel,
  validationStage?: ValidationStage,
) =>
  validationStage === "validate" ? (
    "Validation Report"
  ) : (
    <h3>
      <Icon name={statusSymbol(maxLevel)} />
      {isError ? "Failure: Validation Report" : "Success: Model Uploaded"}
    </h3>
  );

export const renderReport = (
  report: ShaclValidationResultReportResponse,
): React.ReactElement => {
  return (
    <div css={tw`h-full text-left`}>
      {report && report?.entries?.length > 0 && (
        <div
          key={`error-${report.maxLevel}`}
          css={[tw`px-2 text-left font-bold`, reportColour(report.maxLevel)]}
        >
          {generateValidationReportTable(report)}
        </div>
      )}
    </div>
  );
};

const generateValidationReportTable = (
  report: ShaclValidationResultReportResponse,
) => {
  return (
    <Grid celled textAlign="left" columns={2}>
      <Grid.Row>
        <Grid.Column width={3}>
          <b>Level</b>
        </Grid.Column>
        <Grid.Column
          width={13}
          css={[tw`font-bold`, reportColour(report.maxLevel)]}
        >
          {report.maxLevel}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={3}>
          <b>Results</b>
        </Grid.Column>
        <Grid.Column width={13}>
          {report.entries.length > 0 ? (
            report.entries.map((x, index) => (
              <Grid celled key={index} data-test-id="validation-report-entry">
                {Object.entries(x).map(([key, value], entriesindex) => {
                  return (
                    value && (
                      <Grid.Row css={tw`p-0`} key={entriesindex}>
                        <Grid.Column width={3}>
                          <b>{key}</b>
                        </Grid.Column>
                        <Grid.Column css={tw`break-words`} width={13}>
                          {value}
                        </Grid.Column>
                      </Grid.Row>
                    )
                  );
                })}
              </Grid>
            ))
          ) : (
            <span>No issues found</span>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
