/** @jsxImportSource @emotion/react */
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useFormContext } from "react-hook-form";
import { Row } from "@tanstack/react-table";
import { Icon, Table } from "semantic-ui-react";
import { Form } from "components/shared/Forms/ReactHookForm";
import {
  EMPTY_ENTITY_PROPERTIES_MSG,
  LabelledInputLayoutProps,
  UIStatus,
  UIStatusWrapper,
} from "components/shared";
import { useEntityClasses } from "data/EntityClasses/useEntityClasses";
import { ClassHypernym, Unit } from "data/Mason";
import { useEntityProperties } from "data/Mason/EntityProperties";
import { CommonInputProps } from "../../../SitesAndBuildings/Model/Form/EntityForms/CommonInputs";
import { useCustomFieldArray } from "../../../SitesAndBuildings/Model/Form/useCustomFieldArray";
import { ClassificationDirectiveResponse } from "data/Aletheia";
import {
  AletheiaEntityPropertyInput,
  DirectiveComparisonMode,
  PointClassifierSegment,
} from "./AletheiaEntityPropertyInput";
import {
  EntityPropertyComparisonRow,
  isEntityPropertySame,
  mergeToComparisonRows,
} from "./EntityPropertiesDirectiveUtils";

export type AletheiaEntityPropertiesProps = {
  row: Row<ClassificationDirectiveResponse>;
  onEPCellClick?: () => void;
  onEPChange: (proposedResponse: any) => void;
  setActiveManualDirectiveRow: Dispatch<SetStateAction<number | undefined>>;
  unitsList: Unit[];
} & CommonInputProps &
  LabelledInputLayoutProps;

export const AletheiaDirectiveEntityProperties = (
  props: AletheiaEntityPropertiesProps
) => {
  const [activeEditRow, setActiveEditRow] = useState<number | undefined>();
  const {
    isReadOnly,
    onEPCellClick,
    onEPChange,
    row,
    setActiveManualDirectiveRow,
    unitsList,
  } = props;
  const [uiStatus, setUIStatus] = useState(new UIStatus());

  const propertiesFields = useCustomFieldArray(`.properties`);
  const { getValues, setValue, watch, formState, reset } = useFormContext();
  const watchPropertiesList: EntityPropertyComparisonRow[] =
    watch(`.properties`);

  const entityProperties: EntityPropertyComparisonRow[] | undefined =
    getValues(`.properties`);

  const updateEPCallback = useCallback(
    (updatedEPs?: EntityPropertyComparisonRow[]) => {
      const filteredProposed = (updatedEPs ?? watchPropertiesList)
        .filter(
          (row: EntityPropertyComparisonRow) =>
            row.proposed !== undefined && row.proposed?.ep_type !== ""
        )
        .map((row: EntityPropertyComparisonRow) => row.proposed);
      onEPChange(filteredProposed);
    },
    [onEPChange, watchPropertiesList]
  );

  const {
    fetchEntityPropertiesList,
    fetchingEPDefinitions,
    entityPropertyDefinitions,
    fetchEntityPropertyDefinitions,
  } = useEntityProperties();
  const { entityClasses } = useEntityClasses();

  //onload, trigger fetch of the entity properties list if not already done
  useEffect(() => fetchEntityPropertiesList(), [fetchEntityPropertiesList]);

  //fetch the definitions for the selected entity properties if we don't already have them in context
  //definitions are stored as ep_type format (e.g. brick: netArea)
  useEffect(() => {
    // only proposed EP is dynamic, so we only dynamically update that
    if (entityProperties) {
      const unFetchedDefinitions = entityProperties
        .flatMap((p) => [p.original?.ep_type ?? "", p.proposed?.ep_type ?? ""])
        .filter(
          (def: string) =>
            def &&
            def !== "" &&
            !entityPropertyDefinitions.get(def) &&
            !fetchingEPDefinitions.includes(def)
        );
      if (unFetchedDefinitions.length > 0)
        fetchEntityPropertyDefinitions(unFetchedDefinitions);
    }
  }, [
    entityProperties,
    entityPropertyDefinitions,
    fetchEntityPropertyDefinitions,
    fetchingEPDefinitions,
  ]);

  // force a rerender on parent default value change
  useEffect(() => {
    reset({ properties: mergeToComparisonRows(row) });
  }, [reset, row, row.original.proposedPointState.entityProperties]);

  useEffect(() => {
    if (
      fetchingEPDefinitions.filter((def) =>
        entityProperties?.map((p) => p.proposed?.ep_type).includes(def)
      ).length > 0
    )
      setUIStatus((prev) => prev.setIndeterminate(true));
    else setUIStatus((prev) => prev.setIndeterminate(false));
  }, [entityProperties, fetchingEPDefinitions]);

  // update and send PUT request only on cell exitq
  useEffect(() => {
    if (formState.isDirty && formState.isValid) {
      updateEPCallback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeEditRow]);

  const addNewProperty = () => {
    propertiesFields.append({
      original: undefined,
      proposed: {
        ep_type: "",
      },
    });
    setActiveEditRow(propertiesFields.fields.length);
    setActiveManualDirectiveRow(row.index);
  };

  // can only delete the RHS, hence we declared only DirectiveComparisonMode.proposed in form name
  const deleteProperty = useCallback(
    (deleteIndex: number) => {
      const originalPropertyName = `.properties.${deleteIndex}${DirectiveComparisonMode.original}`;
      const originalValue = getValues(originalPropertyName);

      const proposedPropertyName = `.properties.${deleteIndex}${DirectiveComparisonMode.proposed}`;

      if (originalValue === undefined) {
        // if theres no existing declaration, we delete the whole row
        propertiesFields.remove(deleteIndex);
      } else {
        // cant used .replace from useFieldArray library because it causes the component to unmount and remount, hence setValue used instead
        setValue(proposedPropertyName, undefined);
      }
      // prompt a PUT request with the new array
      updateEPCallback(getValues("properties") ?? []);
    },
    [getValues, propertiesFields, setValue, updateEPCallback]
  );
  return (
    <>
      <UIStatusWrapper status={uiStatus}>
        <Table inverted striped compact="very">
          <Table.Body>
            {propertiesFields.fields.length === 0 && isReadOnly ? (
              <Table.Row>{EMPTY_ENTITY_PROPERTIES_MSG}</Table.Row>
            ) : (
              propertiesFields.fields.map((epRow, propertyIndex) => {
                // need to recast generic Record type to known type so Typescript doesn't complain
                // used for comparing and showing colour diff in original and updated values
                const ep = epRow as unknown as EntityPropertyComparisonRow;
                return (
                  <Table.Row key={`original-ep-${epRow.id}`}>
                    <Table.Cell
                      selectable
                      key={epRow?.id}
                      negative={!isEntityPropertySame(ep.original, ep.proposed)}
                    >
                      <AletheiaEntityPropertyInput
                        {...props}
                        propertyIndex={propertyIndex}
                        handleDelete={(index) => deleteProperty(index)}
                        entityClasses={entityClasses}
                        isReadOnly={true}
                        comparisonSide={DirectiveComparisonMode.original}
                        accordionIndex={row.index}
                        segmentName={PointClassifierSegment.classificationPlan}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Icon name="chevron right" />
                    </Table.Cell>
                    <Table.Cell
                      selectable
                      onClick={onEPCellClick}
                      onDoubleClick={() => {
                        setActiveEditRow(propertyIndex);
                        setActiveManualDirectiveRow(row.index);
                      }}
                      positive={
                        !isEntityPropertySame(ep.original, ep.proposed) ||
                        !ep.original
                      }
                    >
                      <AletheiaEntityPropertyInput
                        {...props}
                        hypernym={ClassHypernym.Point}
                        parentClass={"Point"}
                        propertyIndex={propertyIndex}
                        handleDelete={(index) => deleteProperty(index)}
                        entityClasses={entityClasses}
                        isReadOnly={activeEditRow !== propertyIndex}
                        comparisonSide={DirectiveComparisonMode.proposed}
                        restrictTypeChange
                        allowDeleteOnRead
                        updateEPCallback={updateEPCallback}
                        accordionIndex={row.index}
                        segmentName={PointClassifierSegment.classificationPlan}
                        unitsList={unitsList}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })
            )}
          </Table.Body>
        </Table>
      </UIStatusWrapper>
      <Form.Button
        submit={false}
        onClick={() => addNewProperty()}
        icon="add"
        label={`Add ${
          entityProperties?.length === 0 ? "a" : "another"
        } property`}
      />
    </>
  );
};
