/** @jsxImportSource @emotion/react */

import * as React from "react";
import tw from "twin.macro";
import Video from "./Video";

const introVideoUrl: string =
  "https://public-videos-933e57d5-45d6-40a3-b047-0228d5b01530.s3-ap-southeast-2.amazonaws.com/explainer-video.mp4";

const VideoFrame = tw.div``;
const Pad = tw.div`px-1`;

// The padding setup makes an effective padding of "2" between things.
export const LearningHelp = () => {
  return (
    <Pad css={tw`py-2`}>
      <div css={tw`flex flex-col text-center`}>
        <div css={tw`flex flex-row`}>
          <VideoFrame>
            <Video width="75%" poster="./images/index-video-thumbnail.png">
              <source src={introVideoUrl} />
            </Video>
          </VideoFrame>
        </div>
        Introduction to the Data Clearing House.
      </div>
    </Pad>
  );
};
