import React, { useContext, useEffect, useState } from "react";
import { Button, Icon } from "semantic-ui-react";
import { AuthorityId, OrgId } from "data/Enodia";
import {
  EMPTY_ROLESETS,
  EMPTY_USERGROUPS,
  ModalMessage,
  PageMode,
  PageSection,
  redirectMessage,
  resetMessage,
  UIStatus,
} from "components/shared";
import { toOptionTypeBase } from "components/shared/Common";
import { generatePath, useParams, useNavigate } from "react-router-dom";
import { Path } from "Routes";
import OrgContextModal from "context/OrgContextModal";
import _ from "lodash";
import { AuthorityContext, EnodiaOrgContext } from "App";
import { ApiError } from "data/http";
import {
  InvitationCreateRequest,
  PermissionParams,
  RoleSetId,
  RoleSetResponse,
  UserGroupId,
  UserGroupResponse,
} from "data/Enodia";
import { UserAssignmentRequest, enodiaApi } from "data/Enodia";
import { senapsEmailRegEx } from "data/validation";
import { UserInviteFormFields } from "./EditOrInviteUser";
import Form, {
  FormMethods,
  FormStateValues,
} from "components/shared/Forms/ReactHookForm";
import {
  authoritiesToOptions,
  groupsAndSetsToOptions,
} from "../FilterByOptions";
import { UserPermissions } from "../Permissions";

const EMPTY_AUTHORITY = "No Authority Provided";

const inviteFormInfo = {
  required: true,
  emailLabel: "Add Email Address(es)",
  emailDescription:
    "You can add multiple users by separating their email addresses with a comma",
  groupLabel: "Add User to Group(s)",
  roleLabel: "Add User to Role(s)",
  assignAuthorityLabel: "User Authority",
};

const editFormInfo = {
  required: false,
  emailLabel: "Email Address",
  emailDescription: "",
  groupLabel: "User Groups",
  roleLabel: "User Role(s)",
  assignAuthorityLabel: "User Authority",
};

type UserMultiOptions = {
  usergroup: string[];
  roleset: string[];
};

type Params = {
  orgId: OrgId;
};

export const emailValidator = (emailAddresses: string) => {
  if (!emailAddresses) return undefined;
  const emailArray = emailAddresses.split(/[ ,]+/).filter(Boolean);
  const invalidEmails = emailArray.filter((e) => !senapsEmailRegEx.test(e));
  return invalidEmails.length > 0
    ? `"${invalidEmails[0]}" is not a valid email address`
    : undefined;
};

export const InviteUserForm: React.FunctionComponent<{
  userInvite: UserInviteFormFields;
  pageMode: PageMode;
  requestedUser: UserPermissions;
  setStatus: Function;
  setUserInvite: React.Dispatch<React.SetStateAction<UserInviteFormFields>>;
}> = ({ userInvite, pageMode, requestedUser, setStatus, setUserInvite }) => {
  const navigate = useNavigate();
  const params = useParams<Params>();

  const [formState, setFormState] =
    useState<FormStateValues<UserInviteFormFields>>();
  const [formMethods, setFormMethods] =
    useState<FormMethods<UserInviteFormFields>>();

  useEffect(() => {
    if (formMethods && userInvite) formMethods?.reset(userInvite);
  }, [userInvite, formMethods]);

  const { orgId } = useContext(EnodiaOrgContext);
  const { canAssignAuthoritiesList } = useContext(AuthorityContext);

  const isReadOnly = pageMode === PageMode.view;
  const [roleSets, setRoleSets] = useState<Array<RoleSetResponse>>([]);
  const [userGroups, setUserGroups] = useState<Array<UserGroupResponse>>([]);

  const [confirmationModal, setConfirmationModal] = useState(false);
  const [initialMultiValues, setInitialMultiValues] =
    useState<UserMultiOptions>({
      usergroup: [],
      roleset: [],
    });

  const modalMessage: ModalMessage =
    pageMode === PageMode.create ? resetMessage : redirectMessage;

  // on first load, get the selection list for usergroups and rolesets
  React.useEffect(() => {
    const apiParams: PermissionParams = {
      organisation: orgId,
    };
    enodiaApi
      .getRoleSets(apiParams)
      .then((rolesets) => setRoleSets(rolesets))
      .catch((e) => setStatus((prev: UIStatus) => prev.setError(e.message)));
    enodiaApi
      .getUserGroups(apiParams)
      .then((userGroups) => setUserGroups(userGroups))
      .catch((e) => {
        setStatus((prev: UIStatus) => prev.setError(e.message));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  // on first load, grab the initial values of the multiselect fields
  useEffect(() => {
    setInitialMultiValues({
      usergroup: userInvite.userGroups,
      roleset: userInvite.roleSets,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateUserGroupOrRoleSet = (
    _: string | string[],
    fieldToTrigger: "roleSets" | "userGroups"
  ): string | undefined => {
    if (formMethods) {
      const [userGroupValues, roleSetValues] = formMethods.getValues([
        "userGroups",
        "roleSets",
      ]);
      if (
        (!userGroupValues || userGroupValues.length === 0) &&
        (!roleSetValues || roleSetValues.length === 0)
      ) {
        const errorMessage =
          "At least one role set or user group must be specified";
        formMethods?.setError(fieldToTrigger, { message: errorMessage });
        return errorMessage;
      } else formMethods?.clearErrors(fieldToTrigger);
    }
    return undefined;
  };

  const handleSubmit = (formData: UserInviteFormFields) => {
    setStatus((prev: UIStatus) => prev.setIndeterminate(true));
    switch (pageMode) {
      case PageMode.create:
        const invitation = (({ emails, userGroups, roleSets, authority }) =>
          ({
            recipients: emails.replace(/\s+/g, "").split(","),
            userGroupIds: userGroups,
            roleSetIds: roleSets,
            assignAuthority: authority as AuthorityId,
          } as InvitationCreateRequest))(formData);
        enodiaApi.postInvitation(invitation).then(
          () => {
            setStatus((prev: UIStatus) => prev.setIndeterminate(false));
            navigate(Path.Permissions);
          },
          (e: ApiError) => {
            setStatus((prev: UIStatus) =>
              prev.setError(
                e.message ||
                  "An error occurred sending this invitation. Please try again later."
              )
            );
          }
        );
        break;
      case PageMode.edit:
        if (!requestedUser) {
          setStatus((prev: UIStatus) =>
            prev.setError(
              "An error occurred sending this invitation. Please try again later."
            )
          );
          break;
        }
        //to update user's assingments, must identify which groups and rolesets are being added and/or revoked
        const addedGroups = formData.userGroups.filter(
          (ug) =>
            !requestedUser?.userGroups
              .flatMap((g) => g.id)
              .includes(ug as UserGroupId)
        );
        const revokedGroups = requestedUser.userGroups
          .filter((g) => !formData.userGroups.includes(g.id))
          .map((ug) => ug.id);
        const addedRoleSets = formData.roleSets.filter(
          (rs: string) =>
            !requestedUser?.roleSets
              .flatMap((r) => r.id)
              .includes(rs as RoleSetId)
        );
        const revokedRoleSets = requestedUser.roleSets
          .filter((r) => !formData.roleSets.includes(r.id))
          .map((rs) => rs.id);

        const userAssignmentRequest: UserAssignmentRequest = {
          userIds: [requestedUser.id],
          addToUserGroupIds: addedGroups,
          removeFromUserGroupIds: revokedGroups,
          grantRoleSetIds: addedRoleSets,
          revokeRoleSetIds: revokedRoleSets,
        };
        enodiaApi
          .postUserAssignment(userAssignmentRequest)
          .then(() => {
            setStatus((prev: UIStatus) => prev.setIndeterminate(false));
          })
          .then(() => {
            navigate(
              generatePath(Path.ViewUser, {
                user_id: requestedUser.id,
              })
            );
          })
          .catch((e: ApiError) => {
            setStatus((prev: UIStatus) =>
              prev.setError(
                e.message ||
                  "An error occurred sending this invitation. Please try again later."
              )
            );
          });
    }
  };

  const onResetFormConfirm = () => {
    formMethods?.reset();
    setConfirmationModal(false);
  };
  const onRedirectPageConfirm = () => {
    navigate(Path.Permissions);
  };

  const formInfo = pageMode === PageMode.create ? inviteFormInfo : editFormInfo;
  return (
    <Form<UserInviteFormFields>
      onSubmit={(data) => handleSubmit(data)}
      defaultValues={userInvite}
      setFormState={(state: FormStateValues<UserInviteFormFields>) =>
        setFormState(state)
      }
      setFormMethods={(formMethods: FormMethods<UserInviteFormFields>) =>
        setFormMethods(formMethods)
      }
    >
      <PageSection>
        <OrgContextModal
          open={confirmationModal}
          openCondition={
            // manual dirty check/set due to shallow comparison of arrays in multiselect values- always triggers final-form to dirty
            !_.isEqual(
              initialMultiValues.roleset,
              userInvite.roleSets.map(toOptionTypeBase)
            ) ||
            !_.isEqual(
              initialMultiValues.usergroup,
              userInvite.userGroups.map(toOptionTypeBase)
            ) ||
            !!(params.orgId && params.orgId !== orgId)
          }
          onConfirm={() => {
            pageMode === PageMode.create
              ? onResetFormConfirm()
              : onRedirectPageConfirm();
          }}
          modalMessage={modalMessage}
          setModalState={setConfirmationModal}
        />
        <Form.TextInput
          name="emails"
          label={formInfo.emailLabel}
          description={formInfo.emailDescription}
          placeholder="Enter the email addresses of the users to invite"
          required={formInfo.required}
          isReadOnly={!formInfo.required}
          rules={{ validate: emailValidator }}
        />

        <Form.SelectInput
          name="userGroups"
          label={formInfo.groupLabel}
          description="You can associate this user to a group by adding them here"
          placeholder={"Add user to group"}
          options={userGroups.map(groupsAndSetsToOptions)}
          isMulti
          rules={
            pageMode === PageMode.create
              ? {
                  validate: (s: string) =>
                    validateUserGroupOrRoleSet(s, "roleSets"),
                }
              : {}
          }
          isReadOnly={isReadOnly}
          readOnlyEmptyText={EMPTY_USERGROUPS}
        />

        <Form.SelectInput
          name="roleSets"
          label={formInfo.roleLabel}
          description="You can add this user to a role or group of roles by selecting them here"
          placeholder={"Add user to role"}
          options={roleSets.map(groupsAndSetsToOptions)}
          isMulti
          isReadOnly={isReadOnly}
          rules={
            pageMode === PageMode.create
              ? {
                  validate: (s: string) =>
                    validateUserGroupOrRoleSet(s, "userGroups"),
                }
              : {}
          }
          readOnlyEmptyText={EMPTY_ROLESETS}
        />
        <Form.SelectInput
          name="authority"
          label={formInfo.assignAuthorityLabel}
          description="Select one of following authorities to grant your user"
          placeholder={"Add authority to user"}
          options={canAssignAuthoritiesList.map(authoritiesToOptions)}
          isReadOnly={pageMode !== PageMode.create}
          readOnlyEmptyText={EMPTY_AUTHORITY}
          bypassReadOnlyValue={requestedUser.authority?.title}
          required={pageMode === PageMode.edit ? false : true} // TODO: set to true only when all users have an assigned authority
        />
      </PageSection>

      {pageMode === PageMode.view ? (
        <Button
          type="button"
          primary
          onClick={(e) => {
            e.preventDefault();
            navigate(
              generatePath(Path.EditUser, {
                user_id: requestedUser.id,
              }),
              { state: { user: requestedUser } }
            );
          }}
        >
          <Icon name="pencil" />
          Edit User Access
        </Button>
      ) : (
        <Button type="submit" primary disabled={!formState?.isValid}>
          {pageMode === PageMode.create ? "Send Invitation" : "Save"}
        </Button>
      )}
      <Button
        type="button"
        basic
        inverted
        onClick={() => {
          pageMode === PageMode.edit
            ? navigate(
                generatePath(Path.ViewUser, {
                  user_id: requestedUser.id,
                })
              )
            : navigate(Path.Permissions);
        }}
      >
        Cancel
      </Button>
    </Form>
  );
};
