import React, { useEffect, useState } from "react";
import { Container, Label, Menu, Transition } from "semantic-ui-react";
import { MetadataConfig } from "data/Metis";
import {
  ConfigType,
  Status,
  StatusColor,
} from "./ApplicationConfigurationConstants";

const DocumentConfigMenu = (props: {
  activeConfig: ConfigType;
  setActiveConfig: Function;
  isModified: boolean;
  isSaved: boolean;
  configList: ConfigType[];
  documentStatus: Record<string, Status>;
}) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    setIsVisible(props.isModified || props.isSaved);
  }, [props.isModified, props.isSaved]);

  const generateMenuItem = (x: MetadataConfig, index: number) => {
    const name = x.label || x.documentId;
    let status = props.documentStatus[x.documentId] || Status.Synced;
    return (
      <Menu.Item
        color="purple"
        name={name}
        active={props.activeConfig.metadata === x}
        onClick={() => {
          props.setActiveConfig(index);
        }}
        key={x.documentId}
      >
        {name}
        <Container>
          <Transition animation="glow" duration={1000} visible={isVisible}>
            <Label color={StatusColor[status]}>{status}</Label>
          </Transition>
          {x.type !== undefined ? (
            <Label>{x.type.toUpperCase()}</Label>
          ) : (
            <span />
          )}
        </Container>
      </Menu.Item>
    );
  };

  return (
    <Menu className="basic" inverted vertical fluid key="document_config_menu">
      {props.configList.map((x, index) => generateMenuItem(x.metadata, index))}
    </Menu>
  );
};

export default DocumentConfigMenu;
