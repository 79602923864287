/** @jsxImportSource @emotion/react */
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Accordion, Button, Icon, Label, Table } from "semantic-ui-react";
import tw from "twin.macro";
import {
  ApplicationId,
  ApplicationVersionFullResponse,
  OperatorDetailsResponse,
  metisApi,
} from "data/Metis";
import {
  LabelledInput,
  StateColours,
  TextInput,
  MarkdownInput,
  UIStatus,
  UIStatusWrapper,
  formatDate,
} from "components/shared";
import { PublishStateLabel } from "../PublishStateLabel";

export const ApplicationVersions = ({
  appVersions,
}: {
  appVersions: ApplicationVersionFullResponse[];
}) => {
  const [openVersions, setOpenVersions] = useState<string[]>([]);
  const versionsByLatest = appVersions.sort((a, b) => {
    return a.uploadTime < b.uploadTime
      ? 1
      : a.uploadTime > b.uploadTime
      ? -1
      : 0;
  });
  return (
    <Accordion inverted>
      {versionsByLatest.map((version) => {
        return (
          <AppVersion
            key={version.uid}
            version={version}
            isActive={openVersions.includes(version.uid)}
            setOpenVersions={setOpenVersions}
          />
        );
      })}
    </Accordion>
  );
};

const AppVersion = ({
  version,
  isActive,
  setOpenVersions,
}: {
  version: ApplicationVersionFullResponse;
  isActive: boolean;
  setOpenVersions: Dispatch<SetStateAction<string[]>>;
}) => {
  const [publishStatus, setPublishStatus] = useState(new UIStatus());
  const [deprecateStatus, setDeprecateStatus] = useState(new UIStatus());

  const handlePublish = () => {
    setPublishStatus((prev) => prev.setIndeterminate(true));
    metisApi
      .publishApplicationVersion(version.applicationUid, version.uid)
      .then(
        () => setPublishStatus((prev) => prev.setSuccessful(true)),
        (error) =>
          setPublishStatus((prev) =>
            prev.setError(
              error.message ??
                "An error occured while publishing this application version. Please try again later."
            )
          )
      );
  };

  const handleDeprecate = () => {
    setDeprecateStatus((prev) => prev.setIndeterminate(true));
    const versionData = ((v) => ({
      autoRunOnInstall: v.autoRunOnInstall,
      canRun: v.canRun,
      runLabel: v.runLabel,
      resourceRequirement: v.resourceRequirement,
      configurationDocuments: v.configurationDocuments,
      readablePorts: v.readablePorts,
      writablePorts: v.writablePorts,
      dashboardDateLocation: v.dashboardDateLocation,
      changeLog: v.changeLog,
      requirements: v.requirements,
      configurationInstructions: v.configurationInstructions,
    }))(version);
    metisApi
      .putManageApplicationRecipeVersion(version.applicationUid, version.uid, {
        ...versionData,
        deprecated: true,
      })
      .then(
        () => setDeprecateStatus((prev) => prev.setSuccessful(true)),
        (error) =>
          setDeprecateStatus((prev) =>
            prev.setError(
              error.message ??
                "An error occured while deprecating this application version. Please try again later."
            )
          )
      );
  };

  return (
    <>
      <Accordion.Title
        active={isActive}
        onClick={() =>
          setOpenVersions((prev) => {
            if (prev.includes(version.uid))
              return prev.filter((v) => v !== version.uid);
            return [...prev, version.uid];
          })
        }
      >
        <div css={tw`flex gap-2 w-full items-center`}>
          <Icon name="dropdown" />
          <h4 css={tw`m-0`}>{`v${version.version}`}</h4>
          <span css={tw`text-xs`}>
            {formatDate(version.uploadTime, "do MMM yyyy, HH:mm")}
          </span>
          <PublishStateLabel
            isPublished={version.published || !!publishStatus.successful}
          />
          {(version.deprecated || !!deprecateStatus.successful) && (
            <Label color={StateColours.Failure}>Deprecated</Label>
          )}
        </div>
      </Accordion.Title>
      <Accordion.Content active={isActive}>
        <div css={tw`p-4`}>
          <MarkdownInput
            label="Release Notes"
            verticalLayout
            value={version.changeLog ?? "No release notes provided."}
            isReadOnly
          />
          <TextInput
            label="Date Uploaded"
            value={formatDate(version.uploadTime, "do MMM yyyy, HH:mm")}
            verticalLayout
            isReadOnly
          />
          <TextInput
            label="Run Label"
            value={version.runLabel}
            verticalLayout
            isReadOnly
          />
          <TextInput
            label="Can Run"
            value={version.canRun ? "Yes" : " No"}
            verticalLayout
            isReadOnly
          />
          <LabelledInput
            label="Operators"
            verticalLayout
            input={
              <Operators operatorDependencies={version.operatorDependencies} />
            }
          />
          <div css={tw`flex gap-2 justify-end mt-4`}>
            <Button
              basic
              inverted
              onClick={handleDeprecate}
              disabled={version.deprecated || deprecateStatus.successful}
              loading={deprecateStatus.indeterminate}
            >
              <span css={tw`flex gap-2 items-center`}>
                <Icon name={deprecateStatus.successful ? "check" : "trash"} />
                {deprecateStatus.successful
                  ? "Deprecated"
                  : "Deprecate Version"}
              </span>
            </Button>
            <Button
              primary
              disabled={version.published || publishStatus.successful}
              onClick={handlePublish}
              loading={publishStatus.indeterminate}
            >
              <span css={tw`flex gap-2 items-center`}>
                <Icon name={publishStatus.successful ? "check" : "play"} />
                {publishStatus.successful ? "Published" : "Publish Version"}
              </span>
            </Button>
          </div>
        </div>
      </Accordion.Content>
    </>
  );
};

const Operators = ({
  operatorDependencies,
}: {
  operatorDependencies: string[];
}) => {
  const [applicationOperators, setApplicationOperators] = useState<
    Array<OperatorDetailsResponse>
  >([]);
  const [uiStatus, setUiStatus] = useState(new UIStatus());

  useEffect(() => {
    setUiStatus((prev) => prev.setIndeterminate(true));
    const allOperatorPromises = operatorDependencies.map((operatorId) =>
      metisApi.getOperator(operatorId as ApplicationId).then(
        (as) => {
          setApplicationOperators(
            (previousValue: OperatorDetailsResponse[]) => [...previousValue, as]
          );
        },
        (e) =>
          setUiStatus((prev) =>
            prev.setError(
              `Error loading application operators: ${e.errorMessage}`
            )
          )
      )
    );

    Promise.all(allOperatorPromises)
      .then(() => {
        setUiStatus((prev) => prev.setIndeterminate(false));
      })
      .catch((e) => {
        setUiStatus((prev) =>
          prev.setError(
            `Error loading application operators: ${e.errorMessage}`
          )
        );
      });
  }, [operatorDependencies]);

  return (
    <UIStatusWrapper status={uiStatus}>
      {applicationOperators.length > 0 ? (
        <Table inverted basic striped data-test-id="operators-table">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={4}>Name</Table.HeaderCell>
              <Table.HeaderCell width={5}>Description</Table.HeaderCell>
              <Table.HeaderCell width={2}>Version</Table.HeaderCell>
              <Table.HeaderCell width={2}>Is Deployed</Table.HeaderCell>
              <Table.HeaderCell width={3} />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {applicationOperators.map((operator) => (
              <OperatorRow
                key={`${operator.version}_${operator.uid}`}
                operator={operator}
              />
            ))}
          </Table.Body>
        </Table>
      ) : (
        <>No operators specified.</>
      )}
    </UIStatusWrapper>
  );
};

const OperatorRow = ({ operator }: { operator: OperatorDetailsResponse }) => {
  const [uiStatus, setUiStatus] = useState(new UIStatus());
  const [deploymentTriggered, setDeploymentTriggered] = useState(false);

  const syncDeployment = () => {
    setUiStatus((prev) => prev.setIndeterminate(true));
    metisApi.syncOperatorDeployment(operator.uid).then(
      () => {
        setDeploymentTriggered(true);
        setUiStatus((prev) => prev.setIndeterminate(false));
      },
      () =>
        setUiStatus((prev) =>
          prev.setError("An error occured sending the sync request.")
        )
    );
  };

  return (
    <Table.Row>
      <Table.Cell>{operator.name}</Table.Cell>
      <Table.Cell>{operator.description}</Table.Cell>
      <Table.Cell>{operator.version}</Table.Cell>
      <Table.Cell>{operator.deployed ? "Yes" : "No"}</Table.Cell>
      <Table.Cell textAlign="right">
        <Button
          primary
          onClick={() => syncDeployment()}
          size="small"
          fluid
          loading={uiStatus.indeterminate}
          disabled={deploymentTriggered}
        >
          <span css={tw`flex gap-2 items-center justify-center`}>
            <Icon name={deploymentTriggered ? "check" : "sync"} />
            {`${deploymentTriggered ? "Deploying" : "Sync Deployment"}`}
          </span>
        </Button>
      </Table.Cell>
    </Table.Row>
  );
};
