/** @jsxImportSource @emotion/react */

import React from "react";
import tw from "twin.macro";
import { classIcons } from "data/EntityClasses/ParentClassHelper";
import { getClassColor } from "../Model/NodeBadgeShape/StyleHelper";
import { BrickClass } from "../VizModelUtil";

const PanelHeader: React.FunctionComponent<{ node: BrickClass }> = ({
  node,
}) => {
  const isSubClass = node.class.type !== node.class.parentClass;
  return (
    <div css={tw`mt-2 mb-4 text-core-grey font-bold`}>
      <div css={tw`flex items-center`}>
        <img
          src={classIcons.get(node.class.parentClass)}
          alt="class icon"
          style={{ height: "24px", width: "21px", opacity: 0.6 }}
        />
        <span css={tw`ml-2 mr-6 text-lg truncate`}>{node.label}</span>
      </div>
      <div
        css={tw`mt-2 opacity-75`}
        style={{
          color: getClassColor(node.class.parentClass, node.class.hypernym),
        }}
      >{`${node.class.label}${
        isSubClass ? " (" + node.class.parentClass + ")" : ""
      }`}</div>
    </div>
  );
};

export default PanelHeader;
