/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import { Image } from "semantic-ui-react";
import { AppDisplayInfo, getIconByCategory } from "./";

export const AppCategory = ({ category }: { category: string }) => {
  return (
    <div
      css={tw`flex gap-2 items-center border border-solid border-white rounded-full px-2 py-0 text-sm whitespace-nowrap`}
    >
      <Image
        src={getIconByCategory([category])}
        alt="application icon"
        width="16"
        height="16"
      />
      <span>{category}</span>
    </div>
  );
};

export const AppCategories = ({ app }: { app: AppDisplayInfo }) => {
  return (
    <div css={tw`flex flex-wrap gap-2`}>
      {(app.categories ?? []).map((category) => (
        <AppCategory key={category} category={category} />
      ))}
    </div>
  );
};
