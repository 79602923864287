import { OrgId } from "data/Enodia";
import { BuildingId, SiteId } from "data/brick";
import {
  AddressMetaResponse,
  Coordinates,
  OrganisationGetRequest,
} from "../MasonTypes";

export type SiteMetaResponse = {
  orgId: OrgId;
  siteId: SiteId;
  label?: string;
  description?: string;
  addressMeta?: AddressMetaResponse;
  geolocation2d?: Coordinates;
};

export type SiteGetRequest = {
  siteId: SiteId;
} & OrganisationGetRequest;

export type SiteReferenceResponse = {
  segments: string[];
} & SiteGetRequest;

export type SitesAndBuildingsResponse = {
  id: SiteId;
  label?: string;
  buildings: { id: BuildingId; label?: string }[];
  location: Coordinates;
};

export type SitePutRequest = {
  orgId: OrgId;
  siteId: SiteId;
  label?: string;
  description?: string;
  addressMeta?: AddressMetaResponse;
  geolocation2d?: Coordinates;
};

export enum ModelPublishStatus {
  Published = "published",
  Draft = "draft",
}

export type ModelPublishStatusResponse = {
  status: ModelPublishStatus;
  outstandingDrafts: number;
};
