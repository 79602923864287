import { useLocation, useNavigate } from "react-router-dom";
import { v4 as U } from "uuid";
import {
  EquipmentType,
  FloorType,
  PointType,
  RoomType,
  UidType,
  ZoneType,
} from "data/brick";
import {
  SubLevelNodeName,
  idReplaceRegex,
} from "components/SitesAndBuildings/Common";
import { useCustomFieldArray } from "./useCustomFieldArray";

export const useFieldArrayNodes = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const wingFields = useCustomFieldArray("wings");
  const floorFields = useCustomFieldArray("floors");
  const roomFields = useCustomFieldArray("rooms");
  const zoneFields = useCustomFieldArray("zones");
  const equipmentFields = useCustomFieldArray("equipment");
  const pointFields = useCustomFieldArray("points");

  const addNewNode = (nodeType: SubLevelNodeName) => {
    const id = U().replace(idReplaceRegex, "_") as UidType;
    switch (nodeType) {
      case "wing":
        wingFields.append({ fieldId: id, name: "new-wing" });
        break;
      case "floor":
        floorFields.append({
          fieldId: id,
          name: "new-floor",
          type: "Floor" as FloorType,
        });
        break;
      case "room":
        roomFields.append({
          fieldId: id,
          name: "new-room",
          type: "Room" as RoomType,
        });
        break;
      case "zone":
        zoneFields.append({
          fieldId: id,
          name: "new-zone",
          type: "Zone" as ZoneType,
        });
        break;
      case "equipment":
        equipmentFields.append({
          fieldId: id,
          name: "new-equipment",
          type: "Equipment" as EquipmentType,
        });
        break;
      case "point":
        pointFields.append({
          fieldId: id,
          name: "new-point",
          type: "Point" as PointType,
        });
    }
    navigate(`${location.pathname}?id=${id}`);
  };

  const deleteNode = (nodeType: SubLevelNodeName, index: number) => {
    switch (nodeType) {
      case "wing":
        wingFields.remove(index);
        break;
      case "floor":
        floorFields.remove(index);
        break;
      case "room":
        roomFields.remove(index);
        break;
      case "zone":
        zoneFields.remove(index);
        break;
      case "equipment":
        equipmentFields.remove(index);
        break;
      case "point":
        pointFields.remove(index);
    }
  };

  return {
    wingFields,
    floorFields,
    roomFields,
    zoneFields,
    equipmentFields,
    pointFields,
    addNewNode,
    deleteNode,
  };
};
