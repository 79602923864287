import { compile } from "path-to-regexp";
import { DropdownItemProps } from "semantic-ui-react";
import { DataPoolId } from "data/Aletheia";
import {
  BuildingId,
  EquipmentId,
  FloorId,
  ModelId,
  ModelRootType,
  PointId,
  RoomId,
  Schema,
  DCHModel,
  SiteId,
  UidType,
  WingId,
  ZoneId,
} from "data/brick";
import { OrgId } from "data/Enodia";
import { DchClass } from "data/EntityClasses/EntityClassesContext";
import { UnknownParentClass } from "data/EntityClasses/ParentClassHelper";
import { ClassHypernym } from "data/Mason";
import { EntityPropertySummary } from "data/Mason/EntityProperties";
import { ModelMeta } from "data/Mason/ModelDraft/ModelDraftUtils";
import { NodeRef, NodeUriType } from "data/QueryApi/queryTypes";
import { AnyNodeId, AnyNodeName } from "components/SitesAndBuildings/Common";
import { ModelFormFields } from "./Form/SiteOrBuildingModelForm";
import { getDchClass } from "./Visualiser/Model/ModelConverter";

export enum QueryParams {
  id = "id",
  streamIndex = "streamIndex",
}
export type SelectedElement = { type: AnyNodeName; id: AnyNodeId };

export type ModelProps = {
  orgId: OrgId;
  siteId?: SiteId;
  buildingId?: BuildingId;
  dataPoolId?: DataPoolId;
  modelType: ModelRootType;
  modelMeta?: ModelMeta;
};

export const getEmptyModel = (type: ModelRootType, id?: string): DCHModel => ({
  id: (id ?? "") as ModelId,
  root_type: type,
  schema: Schema.default,
  wing: new Map(),
  floor: new Map(),
  room: new Map(),
  zone: new Map(),
  equipment: new Map(),
  point: new Map(),
  points: [],
  properties: [],
  fed_by: [],
  location_of: [],
  in_zone: [],
});

export const getDefaultSelectedElement = (
  modelType: ModelRootType,
  siteId: string,
  buildingId?: string
): SelectedElement => ({
  type: "model",
  id: (modelType === ModelRootType.Site ? siteId : buildingId) as ModelId,
});

export const extractModelId = (m: ModelId): UidType => {
  /* "^.*\/" match everything from the start of the model id, up to the slash before the building id
   * "([a-zA-Z0-9-_.~]+)" match the allowed characters for building ids 1 or more times, and group them for extraction
   * "#?$" match the optional trailing hash, and ensure it is at the end of the string.
   */
  const re: RegExp = /^.*\/([a-zA-Z0-9_.~-]+)#?$/;
  const b = m.match(re);
  // if we have any matches, try to extract the capture group, otherwise return the string
  return (b != null ? b[1] || m : m) as UidType;
};

export const extractNodeIdFromUri = (nodeUri: NodeUriType): string => {
  const nodeUriSplit = nodeUri?.split("#");
  if (nodeUriSplit.length === 2) {
    const rdfNodeId = nodeUriSplit[1];
    return rdfNodeId;
  } else if (nodeUriSplit.length === 1) {
    return "";
  }
  return nodeUri;
};

// recursive function to get all classes starting from the leaf class, up to the root class (hypernym)
export const getAllClassesByLeafClass = (
  entityClasses: Map<ClassHypernym, Map<string, DchClass>>,
  hypernym: ClassHypernym,
  node: DchClass
): DchClass[] => {
  if (!node) return [];
  if (node.type === hypernym || node.parents.length === 0) {
    return [node];
  }

  const nodes: DchClass[] = [node];

  for (const parent of node.parents) {
    const nextNode = getDchClass(entityClasses, node.hypernym, parent);
    if (nextNode !== undefined) {
      const nextNodes = getAllClassesByLeafClass(
        entityClasses,
        hypernym,
        nextNode
      );
      nodes.push(...nextNodes);
    }
  }

  return nodes;
};

// filter the full list of entity properties to scoped domains
export const getFilteredOptionsList = (
  type: string,
  hypernym: ClassHypernym,
  entityPropertiesList: EntityPropertySummary[],
  entityClasses: Map<ClassHypernym, Map<string, DchClass>>
): DropdownItemProps[] => {
  const firstLeafClass = getDchClass(entityClasses, hypernym, type);
  if (
    !firstLeafClass ||
    firstLeafClass.parentClass === UnknownParentClass.Unknown
  ) {
    console.warn(
      `DchClass could not be found for ${hypernym}-${type} in entityClass List`
    );
    return [];
  }
  // get all classes starting from the leaf class, up to the root class (Hypernym)
  const classesList: string[] = getAllClassesByLeafClass(
    entityClasses,
    hypernym,
    firstLeafClass
  ).map((x) => x.type.toLowerCase());

  // filter by array of classes
  const filteredOptions = entityPropertiesList
    .filter((obj) =>
      obj.domains.some((s) => classesList.includes(s.toLowerCase()))
    )
    .map((ep) => ({ value: ep.id, text: ep.name }));
  return filteredOptions;
};

export const findModelSelectedElementById = (
  model: ModelFormFields,
  id: string | null
): { selectedElement: SelectedElement; label: string } => {
  if (id) {
    if (model.wings.some((wing) => wing.fieldId === id))
      return {
        selectedElement: { type: "wing", id: id as WingId },
        label: model.wings.find((wing) => wing.fieldId === id)?.name || id,
      };
    if (model.floors.some((floor) => floor.fieldId === id))
      return {
        selectedElement: { type: "floor", id: id as FloorId },
        label: model.floors.find((floor) => floor.fieldId === id)?.name || id,
      };
    if (model.rooms.some((room) => room.fieldId === id))
      return {
        selectedElement: { type: "room", id: id as RoomId },
        label: model.rooms.find((room) => room.fieldId === id)?.name || id,
      };
    if (model.zones.some((zone) => zone.fieldId === id))
      return {
        selectedElement: { type: "zone", id: id as ZoneId },
        label: model.zones.find((zone) => zone.fieldId === id)?.name || id,
      };
    if (model.equipment.some((equipment) => equipment.fieldId === id))
      return {
        selectedElement: { type: "equipment", id: id as EquipmentId },
        label:
          model.equipment.find((equipment) => equipment.fieldId === id)?.name ||
          id,
      };
    if (model.points.some((point) => point.fieldId === id))
      return {
        selectedElement: { type: "point", id: id as PointId },
        label: model.points.find((point) => point.fieldId === id)?.name || id,
      };
  }
  return {
    selectedElement: {
      type: "model",
      id: model.modelDetails.fieldId as ModelId,
    },
    label: model.modelDetails.name || model.modelDetails.fieldId,
  };
};

const OBJ_URI_PREFIX = "dch:"; // prefix is extracted from the path to avoid path-to-regexp to interpret ":" as a variable
const OBJ_URI_ORG = "org/:orgId";
const OBJ_URI_SITE = `${OBJ_URI_ORG}/site/:siteId#`;
const OBJ_URI_BUILDING = `${OBJ_URI_ORG}/site/:siteId/building/:buildingId#`;
const OBJ_URI_DATAPOOL = `${OBJ_URI_ORG}/datapool/:dataPoolId#`;

export const getUriFromNode = (node: NodeRef): NodeUriType => {
  const orgId = node.modelRef.orgId;
  const siteId = node.modelRef.siteId;
  const buildingId = node.modelRef.buildingId;
  const dataPoolId = node.modelRef.dataPoolId;
  const nodeId = node.nodeId;
  let s = "";
  if (siteId && buildingId) {
    s = compile(OBJ_URI_BUILDING)({ orgId, siteId, buildingId });
  } else if (siteId) {
    s = compile(OBJ_URI_SITE)({
      orgId: orgId as string,
      siteId: siteId as string,
    });
  } else if (dataPoolId) {
    s = compile(OBJ_URI_DATAPOOL)({
      orgId: orgId as string,
      dataPoolId: dataPoolId,
    });
  } else if (!siteId && !buildingId) {
    s = compile(OBJ_URI_ORG)({ orgId: orgId as string });
  } else {
    throw new Error("Building id must present");
  }
  return (OBJ_URI_PREFIX + s + nodeId) as NodeUriType;
};
