import { Eq } from "fp-ts/lib/Eq";
import * as S from "fp-ts/string";
import { EntityPropertyDto } from "./Mason/EntityProperties/EntityPropertiesApi";
import { StreamId } from "./senaps";
import {
  SubLevelNode,
  SubLevelNodeId,
} from "components/SitesAndBuildings/Common";

/* Types to match what BuildingService is using, rather than the draft API on confluence */

/* This module  s the main input/output types from the Building Service API.
 * API call specific params are defined in the api module.
 * This module also defines lenses for all product types, and a validator for DCHBuilding
 */

/* Since the api is so string happy for many of the IDs, I am using newtypes to
 * have some help from the compiler that we aren't mixing up fields.
 */
export type UidType = string & { readonly __tag: unique symbol };
export type SiteId = UidType;
export type BuildingId = UidType;
export type ModelId = UidType;
export type WingId = UidType;
export type FloorId = UidType;
export type RoomId = UidType;
export type ZoneId = UidType;
export type EquipmentId = UidType;
export type PointId = UidType;
type FeedId = EquipmentId | LocationId | ZoneId;

export enum LocationType {
  Wing = "Wing",
  Floor = "Floor",
  Room = "Room",
}
export type LocationId = WingId | FloorId | RoomId;
type IsPartOf =
  | ModelId
  | WingId
  | FloorId
  | RoomId
  | ZoneId
  | EquipmentId
  | PointId;

export const eqPointId = S.Eq as Eq<PointId>;
export const eqLocationId = S.Eq as Eq<LocationId>;

export type ZoneType = string & { readonly __tag: unique symbol };
export type FloorType = string & { readonly __tag: unique symbol };
export type RoomType = string & { readonly __tag: unique symbol };

export enum Schema {
  default = "dch1.0",
  putDraftRDF = "brick1.2.1",
}

export enum ModelRootType {
  Site = "Site",
  Building = "Building",
  Node = "Node",
}

type ModelDetails = {
  id: ModelId;
  root_type: ModelRootType;
  label?: string;
  comment?: string;
  schema: Schema;
  points: Array<PointId>;
  fed_by: Array<EquipmentId>;
  in_zone: Array<ZoneId>;
  location_of: Array<EquipmentId>;
  properties: Array<EntityPropertyDto>;
};

export type DCHModel = ModelDetails & {
  wing: Map<WingId, Wing>;
  floor: Map<FloorId, Floor>;
  room: Map<RoomId, Room>;
  zone: Map<ZoneId, Zone>;
  equipment: Map<EquipmentId, Equipment>;
  point: Map<PointId, Point>;
};

export type SerialisedDCHModel = ModelDetails & {
  wing: { [key: string]: Wing };
  floor: { [key: string]: Floor };
  room: { [key: string]: Room };
  zone: { [key: string]: Zone };
  equipment: { [key: string]: Equipment };
  point: { [key: string]: Point };
};

const mapToObject = <
  TEntityId extends SubLevelNodeId,
  TEntity extends SubLevelNode
>(
  map: Map<TEntityId, TEntity>
): { [key: string]: TEntity } =>
  [...map.entries()].reduce(
    (prev, [id, entity]) => Object.assign(prev, { [id as string]: entity }),
    {}
  );
export const serialiseDCHModel = (dchModel: DCHModel): SerialisedDCHModel => ({
  ...dchModel,
  wing: mapToObject(dchModel.wing),
  floor: mapToObject(dchModel.floor),
  room: mapToObject(dchModel.room),
  zone: mapToObject(dchModel.zone),
  equipment: mapToObject(dchModel.equipment),
  point: mapToObject(dchModel.point),
});

const objectToMap = <
  TEntityId extends SubLevelNodeId,
  TEntity extends SubLevelNode
>(object: {
  [key: string]: TEntity;
}): Map<TEntityId, TEntity> =>
  new Map(Object.entries(object) as [TEntityId, TEntity][]);

export const deserialiseDCHModel = (
  serialisedModel: SerialisedDCHModel
): DCHModel => ({
  ...serialisedModel,
  wing: objectToMap<WingId, Wing>(serialisedModel.wing),
  floor: objectToMap<FloorId, Floor>(serialisedModel.floor),
  room: objectToMap<RoomId, Room>(serialisedModel.room),
  zone: objectToMap<ZoneId, Zone>(serialisedModel.zone),
  equipment: objectToMap<EquipmentId, Equipment>(serialisedModel.equipment),

  point: objectToMap<PointId, Point>(serialisedModel.point),
});

type Label = { label?: string };
type Comment = { comment?: string };
type Properties = { properties: Array<EntityPropertyDto> };
type Parts<a> = { parts: Array<a> };
type FedBy = { fed_by: Array<EquipmentId> };
type InZone = { in_zone: Array<ZoneId> };
type LocationOf = { location_of: Array<EquipmentId> };
type Points = { points: Array<PointId> };

export type Wing = { id: WingId } & Points &
  Parts<FloorId> &
  FedBy &
  InZone &
  LocationOf &
  Label &
  Comment &
  Properties;

export type Floor = {
  id: FloorId;
  floor_type: FloorType;
  wing: WingId;
} & Points &
  Label &
  Comment &
  Parts<RoomId> &
  FedBy &
  InZone &
  LocationOf &
  Properties;

export type Room = {
  id: RoomId;
  room_type: RoomType;
  floor: FloorId;
} & Label &
  Comment &
  Points &
  FedBy &
  InZone &
  LocationOf &
  Properties;

export type Zone = {
  id: ZoneId;
  zone_type: ZoneType;
  locations: Array<LocationId>;
  part_of?: ZoneId;
} & Label &
  Comment &
  Points &
  Parts<ZoneId> &
  FedBy &
  Properties;

export type EquipmentType = string & { readonly __tag: unique symbol };

export type Equipment = {
  id: EquipmentId;
  equipment_type: EquipmentType;
  feeds: Array<FeedId>;
  locations: Array<LocationId>;
  part_of?: EquipmentId;
} & Label &
  Comment &
  Points &
  Parts<EquipmentId> &
  FedBy &
  Properties;

export type PointType = string & { readonly __tag: unique symbol };

export type Point = {
  id: PointId;
  point_type: PointType;
  streamIds: Array<StreamId>;
  point_of: Array<IsPartOf>;
  unit?: string | null;
} & Label &
  Comment &
  Properties;
