import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { EquipmentId, FloorType, PointId, RoomId, WingId } from "data/brick";
import { ClassHypernym } from "data/Mason";
import { NodeFormFields } from "data/Mason/ModelDraft/ModelDraftUtils";
import {
  CommonInputProps,
  Comment,
  DeleteButton,
  IsFedBy,
  LocationOf,
  Points,
  Wing,
  Name,
  Id,
  TypeInput,
  Rooms,
} from "./CommonInputs";
import { ModelEntityCollectionProps } from "../ModelFormUtils";
import { EntityProperty } from "../../EntityProperties/EntityProperty";
import { EntityProperties } from "../../EntityProperties/EntityProperties";
import {
  useIsFedByWatcher,
  useLocationOfWatcher,
  usePointsWatcher,
} from "../RelationshipWatchers";
import {
  handleFieldToArrayChanges,
  handleArrayToFieldChanges,
} from "../RelationshipWatchers/RelationshipWatcherUtils";
import { useFieldArrayNodes } from "../useFieldArrayNodes";
import { RoomFields } from "./Room";
import { WingFields } from "./Wing";

export type FloorFields = {
  type?: FloorType;
  wing?: WingId;
  rooms?: RoomId[];
  locationOf?: EquipmentId[];
  isFedBy?: EquipmentId[];
  points?: PointId[];
  properties?: EntityProperty[];
} & NodeFormFields;

export const Floor = ({
  isReadOnly,
  index,
  deleteNode,
}: ModelEntityCollectionProps) => {
  const inputProps: CommonInputProps = {
    isReadOnly,
    formFieldName: "floors",
    objectName: "floor",
    index,
  };

  useFloorRelationshipWatcher(inputProps);

  return (
    <>
      <Id {...inputProps} />
      <Name {...inputProps} />
      <TypeInput
        {...inputProps}
        hierarchyParams={{ hypernym: ClassHypernym.Location, parent: "Floor" }}
      />
      <Wing {...inputProps} />
      <Rooms {...inputProps} />
      <LocationOf {...inputProps} />
      <IsFedBy {...inputProps} />
      <Points {...inputProps} />
      <Comment {...inputProps} />
      <EntityProperties
        {...inputProps}
        hypernym={ClassHypernym.Location}
        parentClass="Floor"
      />
      {!isReadOnly && (
        <DeleteButton onDelete={() => deleteNode(index)} objectName="floor" />
      )}
    </>
  );
};

const useFloorRelationshipWatcher = (inputProps: CommonInputProps) => {
  const { isReadOnly, formFieldName, index } = inputProps;
  const { getValues, watch, setValue } = useFormContext();
  const { wingFields, roomFields } = useFieldArrayNodes();

  const entityIdentifier = `${formFieldName}.${index}`;
  const entityFieldId = getValues(`${entityIdentifier}.fieldId`);

  const watcherProps = { isReadOnly, entityFieldId, entityIdentifier };
  useIsFedByWatcher(watcherProps);
  useLocationOfWatcher(watcherProps);
  usePointsWatcher(watcherProps);

  //WING
  const watchWing = watch(`${entityIdentifier}.wing`);
  useEffect(() => {
    if (!isReadOnly) {
      handleFieldToArrayChanges({
        entityFieldId,
        getValues,
        setValue,
        watchedField: watchWing,
        existingTargetFields: wingFields.fields,
        targetNodeType: "wing",
        targetProperty: "floors" as keyof WingFields,
      });
    }
  }, [
    entityFieldId,
    getValues,
    isReadOnly,
    setValue,
    watchWing,
    wingFields.fields,
  ]);

  //ROOMS
  const watchRooms = watch(`${entityIdentifier}.rooms`);
  useEffect(() => {
    if (!isReadOnly) {
      handleArrayToFieldChanges({
        entityFieldId,
        getValues,
        setValue,
        watchedField: watchRooms,
        existingTargetFields: roomFields.fields,
        targetNodeType: "room",
        targetProperty: "floor" as keyof RoomFields,
      });
    }
  }, [
    entityFieldId,
    getValues,
    isReadOnly,
    roomFields.fields,
    setValue,
    watchRooms,
  ]);
};
