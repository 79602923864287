import { Json } from "data/Models/Brick/BrickValidationUtils";
import { OrgId } from "data/Enodia";
import { EntityProperty } from "components/SitesAndBuildings/Model/EntityProperties/EntityProperty";
/**
 * ID declarations
 */
export type DataPoolId = string & { readonly __tag: unique symbol };
export type DataPoolUid = string & { readonly __tag: unique symbol };
export type DataPoolCompositeId = string & { readonly __tag: unique symbol };
export type ClassifierId = string & { readonly __tag: unique symbol };
export type ClassifierSetId = string & { readonly __tag: unique symbol };
export type ClassificationDirectiveId = string & {
  readonly __tag: unique symbol;
};
export type ClassificationPlanId = string & { readonly __tag: unique symbol };
export type PointId = string & { readonly __tag: unique symbol };
export type DataSourceId = string & { readonly __tag: unique symbol };
export type DataSourceUid = string & { readonly __tag: unique symbol };
export type DataSourceCompositeId = string & { readonly __tag: unique symbol };

/**
 * CLASSIFIER SET AND CLASSIFIERS
 */
export type Classifier = {
  id: ClassifierId;
  enabled: boolean;
  comment?: string;
  filterBy: ClassifierFilterOptions;
  executeRules: ClassifierRuleOptions;
  classifierSetId: ClassifierSetId;
};

export type ClassifierCreateRequest = Omit<Classifier, "classifierSetId">;

export type ClassifierSetResponse = {
  id: ClassifierSetId;
  organisationId: OrgId;
  meta: ClassifierStats;
  classifiers: Array<Classifier>;
  name: string;
  submittedBy: string;
};

export type ClassifierSetListItem = {
  id: string;
  name: string;
  organisationId: OrgId;
};

export type ClassifierSetListParams = {
  id?: string;
  organisationid?: OrgId;
  name?: string;
};

export type ClassifierSetCreateRequest = {
  name: string;
  organisationId: OrgId;
  classifiers: ClassifierCreateRequest[];
};

export type ClassifierSetUpdateRequest = {
  name: string;
  classifiers: ClassifierCreateRequest[];
};

/**
 * CLASSIFICATION PLAN AND DIRECTIVE
 */
export type ClassificationPlanCreateRequest = {
  planScope: Array<string>;
  organisationId: OrgId;
  classifierSetId: ClassifierSetId;
};

export type ClassificationDirectiveResponse = {
  id: ClassificationDirectiveId;
  planId: ClassificationPlanId;
  pointId: PointId;
  action: boolean;
  actionResult: DirectiveActionResult;
  currentPointState: MatchedPointState;
  proposedPointState: DesiredPointState;
};

export type DirectiveActionResult = {
  state: DirectiveActionState;
  actionResultMessage?: string;
  actionedTime?: string;
};

export enum DirectiveActionState {
  NOT_ACTIONED = "NOT_ACTIONED",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export type ProposedDirectivePutRequest = {
  planId: ClassificationPlanId;
  directiveId: ClassificationDirectiveId;
  action: boolean;
  proposedPointState: PutMutators;
};

type PutMutators = {
  class?: StringPutMutator;
  label?: StringPutMutator;
  entityProperties?: EntityPropertyPutMutator;
  entity?: EntityPutMutator; // omitted/ not declared for now - no current support for entity
  unit?: StringPutMutator;
};

export type StringPutMutator = {
  value: string;
};

export type EntityPropertyPutMutator = {
  value: EntityPropertyKeyValue[];
};

export type EntityPutMutator = {
  value: string[];
};

export type ClassificationPlanResponse = {
  id: ClassificationPlanId;
  organisationId: OrgId;
  planScope: string;
  classifierSetId: ClassifierSetId;
  state: ClassificationPlanState;
  submittedAt: Date;
};

export enum ClassificationPlanState {
  Submitted = "Submitted",
  Processing = "Processing",
  Ready = "Ready",
  FailedPreparation = "FailedPreparation",
  Actioning = "Actioning",
  Actioned = "Actioned",
  FailedActioning = "FailedActioning",
}

export type ClassifierFilterOptions = {
  id: StringMatcher;
  timeseriesId: StringMatcher;
  class: ClassMatcher;
  entityProperties: EntityPropertiesMatcherTemp; // todo: update to the new matcher in the next Jira task
  label: StringMatcher;
  entity: EntityMatcher;
  unit: StringMatcher;
};

export type ClassifierRuleOptions = {
  class: StringSetAction;
  entityProperties: EntityPropertiesSetAction; // todo: update to the new matcher in next Jira task
  label: StringSetAction;
  entity: EntitySetAction;
  unit: StringSetAction;
};

// replacement to EntityProperty object defined in MasonTypes.js
export type EntityPropertyKeyValue = {
  ep_type: string; // always appears
} & {
  [key: string]: string | number; // >= 0 number of key-values
};

/**
 * MATCHER/ FILTERS
 *   */
// generic matcher for all string searches
type StringMatcher = {
  enabled: boolean;
  value?: string;
};

/** @deprecated - temporary placeholder for matching entity properties */
export type EntityPropertiesMatcherTemp = {
  enabled: boolean;
  value?: EntityPropertyKeyValue[];
};

// above to be replaced with this
export type EntityPropertiesMatcher = {
  enabled: boolean;
  value?: EntityPropertiesValuesMatcher[];
};

/** @deprecated- inaccurate entity property field match?  */
type MatchFieldNames =
  | "aggregationFunction"
  | "aggregationInterval"
  | "key"
  | "value"
  | "hasUnit"
  | "latitude"
  | "longitude"
  | "scale"
  | "offset"
  | "ambientTemperatureOfMeasurementDegC";

export type EntityPropertiesValuesMatcher = {
  matchType: string;
  matchFields?: Array<{
    fieldName: MatchFieldNames;
    matchValue?: string;
  }>;
};

type ClassMatcher = StringMatcher & {
  includeChildren: boolean; //preset to false until Dan's changes are implemented
};

type EntityMatcher = {
  enabled: boolean;
  value?: string[];
};

/** SET ACTIONS */
type StringSetAction = {
  enabled: boolean;
  value?: string;
};

type EntityPropertiesSetAction = {
  enabled: boolean;
  value?: EntityPropertyKeyValue[]; // TODO: change type to { [key: string]: EntityPropertyAttribute }
};

type EntitySetAction = {
  enabled: boolean;
  value?: string[];
};

/**
 * POINT STATE / FIELD STATE / MUTATORS
 */
export type MatchedPointState = {
  pointId: StringFieldState;
  class: StringFieldState;
  entityProperties: EntityPropertyFieldState;
  label: StringFieldState;
  entity: EntityFieldState;
  unit: StringFieldState;
};

export type DesiredPointState = {
  class?: StringMutation;
  label?: StringMutation;
  entityProperties?: EntityPropertiesMutation;
  entity?: EntityMutation;
  unit?: StringMutation;
  overridenByUser?: boolean;
};

// Omit overridenByUser such that the rest of objects have a common structure
export type DesiredPointStates = Omit<DesiredPointState, "overridenByUser">;

export type StringFieldState = {
  mutationPending: boolean;
  currentValue?: string;
};

export type EntityFieldState = {
  mutationPending: boolean;
  currentValue: string[];
};

export type EntityPropertyFieldState = {
  mutationPending: boolean;
  currentValue: EntityPropertyKeyValue[];
};

type Mutation = {
  classifierId?: ClassifierId;
  totalProposedMutationsCount?: number;
  overridenByUser: boolean;
};
export type StringMutation = Mutation & {
  computedValue: string;
  userValue?: string;
};

export type EntityPropertiesMutation = Mutation & {
  computedValue: EntityPropertyKeyValue[];
  userValue?: EntityPropertyKeyValue[];
};

export type EntityMutation = Mutation & {
  computedValue: string[];
  userValue?: string[];
};

export type Mutators =
  | StringMutation
  | EntityMutation
  | EntityPropertiesMutation;

export type ClassifierStats = {
  createdAt: Date;
  modifiedAt: Date;
  submittedBy: string;
};

export enum ClassifierMode {
  filter = "filterBy",
  execute = "executeRules",
}

export enum DirectiveMode {
  current = "currentPointState",
  proposed = "proposedPointState",
}

export enum DirectiveState {
  ALL = "ALL",
  CHANGES_PENDING = "CHANGES_PENDING",
  MATCHES_NO_CHANGE = "MATCHES_NO_CHANGE",
}

export type GetDirectivesParams = {
  limit?: number;
  after?: string;
  id?: string;
  state?: DirectiveState;
};

/**
 * DATASOURCE
 *  */
export interface DataSourceParams {
  limit?: number;
  after?: string;
  id?: string;
  organisationid?: string;
  uid?: string;
  name?: string;
  transporttype?: string;
  decodertype?: string;
  decoderid?: string;

  // Index signature to allow any string key
  [key: string]: any;
}

export enum DataSourceType {
  senaps_mqtt_client = "senaps_mqtt_client",
  dch_rest_api = "dch_rest_api",
}

type DataSourceTransportConfig = {
  userName: string;
  type: DataSourceType;
  password: string;
};

type DataSourceTransportConfigUpdate = {
  userName: string;
  type: DataSourceType;
  password: string | null; // if password is set to null - there is no change in password
};

type DataSourceDecoderConfig = {
  decoderId: string;
  configContent?: Json;
};

enum TimeSeriesBackingResourceType {
  Senaps = "Senaps",
}

type TimeseriesTracking = {
  backingResourceId: string;
  backingResourceType: TimeSeriesBackingResourceType;
  excludeTimeseriesBlacklistRef: string;
};

type DataSourcePoolRef = {
  dataPoolId: DataPoolId;
  dataPoolUid: DataPoolUid;
  timeseriesTracking: TimeseriesTracking;
};

export type DataSourceListItem = {
  id: DataSourceId;
  uid: string;
  organisationId: string;
  name: string;
  type: DataSourceType;
  enabled: boolean;
  description?: string;
};

export type DataSourceResponse = {
  id: string;
  uid: DataSourceUid;
  name?: string;
  enabled: boolean;
  organisationId: OrgId;
  dataPoolRef: DataSourcePoolRef;
  description?: string;
  transportConfig: DataSourceTransportConfig;
  decoderConfig: DataSourceDecoderConfig;
};

export type DataSourceCreateRequest = {
  id: string;
  name?: string;
  description?: string;
  enabled: boolean;
  organisationId: OrgId;
  transportConfig: DataSourceTransportConfig;
  decoderConfig: DataSourceDecoderConfig;
};

export type DataSourceUpdateRequest = {
  name?: string;
  description?: string;
  enabled: boolean;
  transportConfig: DataSourceTransportConfigUpdate;
  decoderConfig: DataSourceDecoderConfig;
};

/**
 * DATA POOLS
 */
type TimeSeriesTracking = {
  backingResourceId: string;
  backingResourceType: TimeSeriesBackingResourceType;
  excludeTimeseriesBlacklistRef: string;
};
export type DataPoolResponse = {
  id: string;
  uid: string;
  organisationId: string;
  name?: string;
  description?: string;
  licence?: string;
  datasourceId?: string;
  managed: boolean;
  timeseriesImmutable: boolean;
  timeseriesTracking: TimeSeriesTracking;
  compositeId: string;
};

export type DataPoolListItem = {
  id: DataPoolId;
  organisationId: OrgId;
  uid: DataPoolUid;
  name?: string;
  compositeId: string;
};

export type DataPoolGetParams = {
  limit?: number;
  after?: string;
  id?: string;
  organisationid?: string;
  uid?: string;
  name?: string;
  datasourceid?: string;
  timeseries_immutable?: boolean;
  trackingid?: string;
};
/**
 * POINTS
 */
export type PointGetParams = {
  limit?: number;
  after?: string;
  id?: string;
  organisationid?: string;
  uid?: string;
  name?: string;
  type?: string;
  datapoolid?: string;
  unit?: string;
  timeseriesid?: string;
};

export type PointListItem = {
  id: string;
  uid: string;
  organisationId: string;
  datapoolId: string;
  name?: string;
  type: string;
  unit?: string;
};

export type PointResponse = {
  id: string;
  uid: string;
  name: string;
  organisationId: string;
  dataPoolId: string;
  ownershipReferences: string[];
  entityProperties: EntityProperty[];
  unit?: string;
};

export type PointListCsvGetParams = {
  all_fields?: boolean;
  include_header?: boolean;
};
