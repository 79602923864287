export const getCookie = (name: string) => {
    const cookieValue = document.cookie.match(
        "(^|;) ?" + name + "=([^;]*)(;|$)"
    );
    return cookieValue ? cookieValue[2] : null;
};

export const removeCookie = (name: string) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export const setCookie = (name: string, value: string) => {
    document.cookie = `${name}=${value}; path=/`;
};

export const setRawCookie = (cookie: string) => {
    document.cookie = cookie;
}