/** @jsxImportSource @emotion/react */

import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "semantic-ui-react";
import tw from "twin.macro";
import { accountApi } from "data/senaps";
import {
  isEmail,
  validatePasswordStrength,
  validatePasswordsMatch,
} from "data/validation";
import { IndexPageMode, UIStatus, UIStatusWrapper } from "components/shared";
import Form, {
  FormMethods,
  FormStateValues,
} from "components/shared/Forms/ReactHookForm";

class PasswordResetFormValues {
  email = "";
  password = "";
  confirmPassword = "";
}

export const PasswordResetForm = ({
  setPageMode,
}: {
  setPageMode: (_: IndexPageMode) => void;
}) => {
  const [status, setStatus] = useState(new UIStatus());
  const { token } = useParams<keyof { token: string }>() as { token: string };

  const [formState, setFormState] =
    useState<FormStateValues<PasswordResetFormValues>>();
  const [formMethods, setFormMethods] =
    useState<FormMethods<PasswordResetFormValues>>();

  const handleSubmit = (formValues: PasswordResetFormValues) => {
    setStatus((prev) => prev.setIndeterminate(true));
    const { email, password } = formValues;
    accountApi
      .resetPassword({
        requestid: token,
        userid: email,
        password,
      })
      .then(
        () => {
          setStatus((prev) => prev.setIndeterminate(false));
          setPageMode(IndexPageMode.login);
        },
        (e) => setStatus((prev) => prev.setError(e.error))
      );
  };

  const passwordFieldValidation = (s: string) => {
    const strengthValidation = validatePasswordStrength(s);
    if (strengthValidation) return strengthValidation;
    const passwordMatchResult = validatePasswordsMatch(
      s,
      formMethods?.getValues("confirmPassword")
    );
    if (passwordMatchResult) {
      if (!formState?.errors["confirmPassword"])
        formMethods?.setError("confirmPassword", {
          message: passwordMatchResult,
        });
    } else formMethods?.clearErrors("confirmPassword");
    return undefined;
  };

  return (
    <UIStatusWrapper status={status}>
      <Form<PasswordResetFormValues>
        onSubmit={(data) => handleSubmit(data)}
        setFormState={(state) => setFormState(state)}
        setFormMethods={(methods) => setFormMethods(methods)}
      >
        <Form.TextInput
          name="email"
          label="Email"
          placeholder="Enter email"
          type="email"
          rules={{
            required: "Email is required",
            validate: isEmail,
          }}
          verticalLayout
        />
        <Form.TextInput
          name="password"
          label="Password"
          placeholder="Enter password"
          rules={{
            required: "Password is required",
            validate: passwordFieldValidation,
          }}
          type="password"
          autoComplete="current-password"
          verticalLayout
        />
        <Form.TextInput
          name="confirmPassword"
          label="Confirm Password"
          rules={{
            required: "Password is required",
            validate: (s: string) =>
              validatePasswordsMatch(s, formMethods?.getValues("password")),
          }}
          type="password"
          verticalLayout
        />
        <Button
          type="submit"
          primary
          css={tw`w-full`}
          style={{
            padding: "1em",
            marginBlock: "1.5rem",
          }}
          disabled={!formState?.isValid}
        >
          <div css={tw`flex justify-start`}>
            <span>Reset password</span>
          </div>
        </Button>
      </Form>
    </UIStatusWrapper>
  );
};
