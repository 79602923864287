/* eslint-disable no-console */
export enum LogLevel {
  DEBUG = 4,
  INFO = 3,
  WARN = 2,
  ERROR = 1,
  OFF = 0,
}

interface Logger {
  debug: (...args: any[]) => void;
  info: (...args: any[]) => void;
  warn: (...args: any[]) => void;
  error: (...args: any[]) => void;
}

/**
 * LoggerFactory
 *
 * NOTE: Create logger wisely to avoid unnecessary log messages on user console. Any errors or
 * warnings should be presented to user rather than using logger.
 */
export class LoggerFactory {
  static level: LogLevel = LogLevel.DEBUG;

  static setLevel(l: LogLevel) {
    LoggerFactory.level = l;
  }

  /**
   * Create a new logger
   * @param module Used to provide extra information for the log messages
   * @returns {debug, info, warn, error}
   * @constructor
   */
  static create(module: string): Logger {
    const logger = {
      debug:
        (console.debug && console.debug.bind(console, `[DEBUG: ${module}]:`)) ||
        (() => {}),
      info:
        (console.info && console.info.bind(console, `[INFO: ${module}]:`)) ||
        (() => {}),
      warn:
        (console.warn && console.warn.bind(console, `[WARN: ${module}]:`)) ||
        (() => {}),
      error:
        (console.error && console.error.bind(console, `[ERROR: ${module}]:`)) ||
        (() => {}),
    };

    return new Proxy(logger, {
      get(target: any, propKey: string) {
        const originalMethod = target[propKey];
        return (...args: any[]) => {
          if (
            (LoggerFactory.level as number) >=
            (LogLevel as any)[propKey.toUpperCase()]
          ) {
            return originalMethod.apply(this, args);
          }

          return undefined;
        };
      },
    });
  }
}
