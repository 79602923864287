import { ApplicationId, ApplicationInstallRequest } from "data/Metis";
import { SiteBuildingReference } from "data/QueryApi/queryTypes";
import { modelRefToSelectVal } from "components/shared";
import { FormValues } from "./MetisInstallApplicationModal";

export enum InstallApplicationState {
  Unknown = "Unknown",
  Running = "Running",
  Failure = "Failure",
  Success = "Success",
  Error = "Error",
}

export const filterModelsListBySelectedModels = (
  models: string[],
  modelsList: SiteBuildingReference[]
) => {
  return models.length > 0
    ? modelsList.filter((modelRef) =>
        models.includes(modelRefToSelectVal(modelRef))
      )
    : [];
};

export const mkFormValuesToInstallRequest = (
  formValues: FormValues,
  modelsList: SiteBuildingReference[]
) => {
  const models = filterModelsListBySelectedModels(
    formValues.models,
    modelsList
  );
  const installRequest: ApplicationInstallRequest = {
    modelIdentifiers: models,
    applicationId: formValues.applicationVersionId as ApplicationId,
    name: formValues.name,
  };

  return installRequest;
};
