import { LabelProps, LabelledInputLayoutProps } from "components/shared";
import { InputValidationProps } from "../InputValidation";

class ControlledInputProps<T> {
  value?: T;
  onChange?: (e: any, data: any) => void;
  onBlur?: (e: any, data: any) => void;
  onKeyDown?: (e: any, data: any) => void;
}

class CommonInputProps {
  placeholder?: string;
  error?: boolean;
  required?: boolean;
  disabled?: boolean;
  readOnlyEmptyText?: string;
  autoComplete?: string;
  className?: string;
}

export type ValidationProps = {
  inputValidation?: InputValidationProps; //provide this object if we want to build in space for a validation message under the input
};

export type FormProps = {
  formProps?: { [key: string]: any }; //provide this if we're using the input within a React Hook Form
};

export type InputProps<T> = CommonInputProps &
  LabelProps &
  LabelledInputLayoutProps &
  ValidationProps &
  ControlledInputProps<T> &
  FormProps;

const getProps = <T>(
  props: InputProps<T>,
  newClass:
    | LabelProps
    | LabelledInputLayoutProps
    | CommonInputProps
    | ControlledInputProps<T>
) => {
  type AllowedKeys =
    | keyof LabelProps
    | keyof LabelledInputLayoutProps
    | keyof CommonInputProps
    | keyof ControlledInputProps<T>;
  for (let key in props) {
    const myKeys = Object.keys(newClass);
    if (myKeys.includes(key)) {
      newClass = {
        ...newClass,
        [key]: props[key as AllowedKeys],
      };
    }
  }
  return newClass;
};

export const getLabelProps = <T>(props: InputProps<T>) =>
  getProps(props, new LabelProps()) as LabelProps;

export const getLabelledInputLayoutProps = <T>(props: InputProps<T>) =>
  getProps(props, new LabelledInputLayoutProps()) as LabelledInputLayoutProps;

export const getCommonInputProps = <T>(props: InputProps<T>) =>
  getProps(props, new CommonInputProps()) as CommonInputProps;

export const getControlledInputProps = <T>(props: InputProps<T>) =>
  getProps(props, new ControlledInputProps()) as ControlledInputProps<T>;
