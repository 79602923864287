import React from "react";
import { Grid } from "semantic-ui-react";
import { max as maxDate, min as minDate } from "date-fns";
import {
  DateInput,
  ResponsiveWidths,
  formatDateAsString,
  isValidDate,
} from "components/shared";

export type DateRange = {
  start: Date;
  end: Date;
};

type DateInputProps = {
  dateRange?: DateRange;
  setDateRange: (value: React.SetStateAction<DateRange | undefined>) => void;
  columnWidths?: ResponsiveWidths;
  isReadOnly?: boolean;
  disabled?: boolean;
  minMaxProps?: { minDate: string | undefined; maxDate: string | undefined };
  mountNodeId?: string;
};
export const DateRangeInput = function (props: DateInputProps) {
  const renderFromDate = () => {
    const fromDate = props?.dateRange && props?.dateRange.start;
    return (
      <DateInput
        placeholder="Start date time"
        label="From"
        verticalLayout
        disabled={props.disabled}
        value={fromDate ? formatDateAsString(fromDate) : undefined}
        onChange={(startDate) => {
          if (startDate && isValidDate(startDate)) {
            const newStart = new Date(startDate);
            props.setDateRange((d) => {
              return {
                start: newStart,
                end: d ? maxDate([d.end, newStart]) : newStart,
              };
            });
          }
        }}
        mountNodeId={props.mountNodeId}
      />
    );
  };

  const renderToDate = () => {
    const toDate = props?.dateRange && props?.dateRange.end;
    return (
      <DateInput
        placeholder="End date time"
        label="To"
        verticalLayout
        disabled={props.disabled}
        value={toDate ? formatDateAsString(toDate) : undefined}
        onChange={(endDate: string | null) => {
          if (endDate && isValidDate(endDate)) {
            const newEnd = new Date(endDate);
            props.setDateRange((d) => {
              return {
                start: d ? minDate([newEnd, d.start]) : newEnd,
                end: newEnd,
              };
            });
          }
        }}
        mountNodeId={props.mountNodeId}
      />
    );
  };

  return (
    <React.Fragment>
      <Grid.Column {...props.columnWidths}>{renderFromDate()}</Grid.Column>
      <Grid.Column {...props.columnWidths}>{renderToDate()}</Grid.Column>
    </React.Fragment>
  );
};
