import * as React from "react";
import { LatLngExpression } from "leaflet";
import { TileLayer } from "react-leaflet";

export const DEFAULT_LAT_LONG: LatLngExpression = [-24.7761086, 134.755]; // Default to Australia
export const DEFAULT_ZOOM = 4;
export const LOCATION_ZOOM = 16;

export const CommonTileLayer = () => (
  <TileLayer
    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  />
);
