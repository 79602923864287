import React from "react";
import { EMPTY_DESCRIPTION, EMPTY_NAME } from "components/shared";
import Form from "components/shared/Forms/ReactHookForm";
import { isValidName } from "data/validation";
import { checkNameIsUnique } from "./Validation";
import { RoleSet } from "./EditOrCreateRoleSet";
import { OrgId } from "data/Enodia";
import { useFormContext } from "react-hook-form";
import {
  useAsyncValidate,
  hasAsyncError,
} from "components/shared/Forms/useAsyncValidate";

type RoleSetFormFieldsProps = {
  isReadOnly: boolean;
  orgId?: OrgId;
  roleSetId?: string;
};

export default function RoleSetFormFields({
  isReadOnly,
  orgId,
  roleSetId,
}: RoleSetFormFieldsProps) {
  const { getFieldState } = useFormContext<RoleSet>();

  const validateUniqueName = (name: string): Promise<string | undefined> =>
    new Promise((resolve) => {
      if (orgId && name !== "" && name !== roleSetId) {
        checkNameIsUnique(orgId, name, resolve);
      }
    });

  useAsyncValidate<RoleSet>("name", validateUniqueName);

  return (
    <>
      <Form.TextInput
        name="name"
        label="Name"
        placeholder="Create a name for this role..."
        required
        rules={{
          validate: {
            isValidName: (name: string | undefined) =>
              name && isValidName(name),
            hasAsyncError: () => hasAsyncError("name", getFieldState),
          },
        }}
        isReadOnly={isReadOnly}
        readOnlyEmptyText={EMPTY_NAME}
      />
      <Form.TextInput<RoleSet>
        name="description"
        label="Description"
        placeholder="Create a description for this role..."
        isReadOnly={isReadOnly}
        readOnlyEmptyText={EMPTY_DESCRIPTION}
      />
    </>
  );
}
