import React, { useContext, useState } from "react";
import { DropdownItemProps } from "semantic-ui-react";
import { OrgId, OrganisationResponse, enodiaApi } from "data/Enodia";
import { EnodiaOrgContext } from "App";
import { SelectInput, UIStatus } from "components/shared";

type OrganisationIdProps = {
  orgId?: OrgId;
  onChange?: (orgId?: OrgId) => void;
  fieldName?: string;
  isReadOnly?: boolean;
  isClearable?: boolean;
  placeholder?: string;
  description?: string | React.ReactNode;
  required?: boolean;
};

const toOptionIdAsValue = function (
  org: OrganisationResponse,
): DropdownItemProps {
  return { value: org.id ?? org.uid, text: `${org.name} (${org.id})` };
};

export const sortOrganisationList = (orgsList: OrganisationResponse[]) => {
  const sortedWithoutSandbox = orgsList
    .filter((obj) => !obj.id.includes("sandbox-"))
    .sort((a, b) => a.name.localeCompare(b.name));

  const sortedWithSandbox = orgsList
    .filter((obj) => obj.id.includes("sandbox-"))
    .sort((a, b) => a.name.localeCompare(b.name));

  const sortedList = [...sortedWithoutSandbox, ...sortedWithSandbox];

  return sortedList;
};

export const EnodiaOrgIdField: React.FunctionComponent<OrganisationIdProps> = ({
  orgId,
  onChange = () => {},
  fieldName,
  isReadOnly,
  placeholder,
  description,
  required,
  isClearable = true,
}) => {
  const { orgList, setOrgList } = useContext(EnodiaOrgContext);
  const [status, setStatus] = useState<UIStatus>(new UIStatus());

  React.useMemo(() => {
    if (!orgList) {
      setStatus((p) => p.setIndeterminate(true));

      enodiaApi
        .getOrgs()
        .then((orgsList) => {
          setOrgList(sortOrganisationList(orgsList));
        })
        .catch((e) => console.error(`Failed to fetch orgs: ${e}`))
        .finally(() => setStatus((p) => p.setIndeterminate(false)));
    }
  }, [orgList, setOrgList]);

  return (
    <SelectInput
      label={fieldName ?? "Organisation ID"}
      description={description}
      placeholder={placeholder}
      onChange={(_, { value }) => onChange(value as OrgId)}
      isReadOnly={isReadOnly}
      value={orgId}
      options={orgList ? orgList.map(toOptionIdAsValue) : []}
      isMulti={false}
      required={required}
      search={true}
      isClearable={isClearable}
      isLoading={status.indeterminate}
    />
  );
};
