import * as React from "react";
import { Link } from "react-router-dom";
import { Grid, Button, Icon, Table } from "semantic-ui-react";
import {
  DataSourceListItem,
  DataSourceParams,
  aletheiaApi,
} from "data/Aletheia";
import { getPaginatedFromURL } from "data/httpUtil";
import {
  DEFAULT_PAGINATION_DATA_LIMIT,
  PaginatedResponse,
} from "data/paginationHelper";
import { aletheiaEnv } from "reducers/env";
import { EnodiaOrgContext } from "App";
import { Path } from "Routes";
import {
  FilterInput,
  Page,
  PageTitle,
  Pagination,
  UIStatus,
  UIStatusWrapper,
} from "components/shared";
import RemoveDataSource from "./RemoveDataSource";
import { displayType } from "./DataSourceUtils";
import styles from "./DataSources.module.css";

export const renderEnabled = (enabled: boolean) => (enabled ? "Yes" : "No");

const sortByName = (dss: Array<DataSourceListItem>) =>
  dss.sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });

const DataSources: React.FunctionComponent = () => {
  const { orgId } = React.useContext(EnodiaOrgContext);

  const [filter, setFilter] = React.useState<string>();
  const [uiStatus, setUIStatus] = React.useState<UIStatus>(new UIStatus());
  const [datasources, setDatasources] = React.useState<
    Array<DataSourceListItem>
  >([]);
  const [links, setLinks] = React.useState<Record<string, string>>();
  const [limit, setLimit] = React.useState<number>(
    DEFAULT_PAGINATION_DATA_LIMIT,
  );

  const fetchDataSourcesFromLink = React.useCallback((link: string) => {
    setUIStatus((prevState) => prevState.setIndeterminate(true));
    processFetchDatasources(
      getPaginatedFromURL(link)(aletheiaEnv).then(
        (r) => r as PaginatedResponse<Array<DataSourceListItem>>,
      ),
    );
  }, []);

  const fetchDataSources = React.useCallback(
    (dataSourceParams: DataSourceParams) => {
      setUIStatus((prevState) => prevState.setIndeterminate(true));
      // we always want to be filtering by org id
      // on first load, orgId may not necessarily be loaded
      if (orgId)
        processFetchDatasources(aletheiaApi.getDatasources(dataSourceParams));
    },
    [orgId],
  );

  const processFetchDatasources = (
    promise: Promise<PaginatedResponse<Array<DataSourceListItem>>>,
  ) => {
    return promise
      .then((os) => {
        setLinks(os.links);
        return os.data;
      })
      .then((res) => {
        setDatasources(res);
        setUIStatus((prevState) => prevState.setIndeterminate(false));
      })
      .catch((e) => {
        setUIStatus((prevState) =>
          prevState.setError(e.message ?? "Error fetching data sources"),
        );
      });
  };

  const dataSourceParams = React.useMemo(
    () =>
      filter
        ? {
            organisationid: orgId,
            name: `*${filter}*`,
            limit,
          }
        : {
            organisationid: orgId,
            limit,
          },
    [orgId, filter, limit],
  );

  // on search, fetch the datasources. Only want to track name in params
  React.useEffect(() => {
    if (orgId) fetchDataSources(dataSourceParams);
  }, [orgId, fetchDataSources, dataSourceParams]);

  const data = datasources ? sortByName(datasources) : [];

  return (
    <Page>
      <PageTitle
        primaryHeader="Data sources"
        subHeader="Add, remove and manage current data sources"
      />
      <Grid columns="equal">
        <Grid.Row>
          <Grid.Column>
            <FilterInput
              placeholder="Filter data sources by keyword..."
              handleSearch={(term) => {
                setFilter(term);
              }}
            />
          </Grid.Column>

          <Grid.Column textAlign="right">
            <Button primary as={Link} to={Path.NewDataSource}>
              <Icon name="plus" />
              Add data source
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <UIStatusWrapper
        status={
          uiStatus.indeterminate || uiStatus.error
            ? uiStatus
            : uiStatus.setEmpty(data.length === 0)
        }
      >
        <Table inverted striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Enabled?</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {data.map((d) => (
              <Table.Row key={d.id}>
                <Table.Cell>{renderEnabled(d.enabled)}</Table.Cell>
                <Table.Cell>
                  <Link to={`/datasource/${orgId}/${d.id}`}>{d.name}</Link>
                </Table.Cell>

                <Table.Cell>{displayType(d.type)}</Table.Cell>

                <Table.Cell>{d.description}</Table.Cell>

                <Table.Cell textAlign="right">
                  <RemoveDataSource
                    dataSource={d}
                    onRemoved={() => fetchDataSources(dataSourceParams)}
                  />
                </Table.Cell>
              </Table.Row>
            ))}

            <Table.Row>
              <Table.Cell colSpan={5} className={styles.pagination}>
                {links && (
                  <Pagination
                    links={links}
                    onPaginationChange={(link) => {
                      fetchDataSourcesFromLink(link);
                    }}
                    setLimit={(newLimit) => setLimit(newLimit)}
                    limit={limit}
                    dataLength={datasources.length}
                    refetch={() => fetchDataSources(dataSourceParams)}
                  />
                )}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </UIStatusWrapper>
    </Page>
  );
};

export default DataSources;
