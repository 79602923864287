import React from "react";
import * as O from "fp-ts/lib/Option";
import { Button, ButtonProps } from "semantic-ui-react";
import { StreamId } from "data/senaps";
// import { downloadCsv } from "data/utils";

type ReactProps = { children?: React.ReactNode };

type DownloadButtonProps = {
  id: string;
  limit?: number;
  filename?: string;
} & ReactProps &
  ButtonProps;

export const DownloadButton = function ({
  id,
  limit = 100000000, // DCH-2065 : This follows the Senaps frontend convention, downloading from graph has a limit of 100,000,000 observations
  filename,
  children,
  disabled,
}: DownloadButtonProps) {
  const [spinner, setSpinner] = React.useState<boolean>(false);
  const oStreamId = O.fromNullable(id as StreamId);
  // const downloadCSV = (pointId: string): Promise<void> =>
  //   sensorApi.getObservationsCsv({ pointId: pointId, limit: limit }).then((res) => {
  //     downloadCsv(res, filename ? filename : pointId);
  //   });

  return (
    <Button
      primary
      onClick={() => {
        setSpinner(true);
        O.fold(
          () => setSpinner(false),
          (pointId: string) => {
            setSpinner(false);
          } // TODO: Re-enable using Chronos in DCH-4585. downloadCSV(pointId).then(() => setSpinner(false)
        )(oStreamId);
      }}
      disabled={O.isNone(oStreamId) || spinner || disabled || true}
      loading={spinner}
    >
      {children}
    </Button>
  );
};
