import {
  useFieldArray,
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
} from "react-hook-form";
import { ModelFormFields } from "./SiteOrBuildingModelForm";

type FieldArrayActions<TArray extends string, TId extends string> = {
  fields: FieldArrayWithId<ModelFormFields, TArray, TId>[];
  append: UseFieldArrayAppend<ModelFormFields, TArray>;
  remove: UseFieldArrayRemove;
};

export const useCustomFieldArray = <T extends string>(
  fieldArrayName: T
): FieldArrayActions<T, "id"> => {
  const { fields, append, remove } = useFieldArray<ModelFormFields, T>({
    name: fieldArrayName,
  });
  return { fields, append, remove };
};
