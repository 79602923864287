/** @jsxImportSource @emotion/react */

import React from "react";
import { Tooltip } from "react-tooltip";
import { List } from "semantic-ui-react";
import tw, { TwStyle } from "twin.macro";
import { EMPTY_LIST_MSG, UNSPECIFIED_STRING_MSG } from "components/shared";

export const renderReadOnlyField = (
  name: string,
  value?: string | React.ReactNode,
  customFieldCss?: TwStyle,
) => {
  const displayValueOrUnspecified = () => {
    if (!value) return UNSPECIFIED_STRING_MSG;
    return typeof value === "string" ? (
      <div
        data-tooltip-id="field-helper-tooltip"
        data-tooltip-content={value}
        css={tw`truncate`}
        data-tooltip-float={true}
      >
        {value}
      </div>
    ) : (
      value
    );
  };
  return (
    <div css={customFieldCss ?? tw`mb-4`}>
      <div css={tw`font-bold truncate`}>{name}</div>
      {displayValueOrUnspecified()}
      <Tooltip id="field-helper-tooltip" />
    </div>
  );
};

export const renderReadOnlyList = (
  listItems: Array<{ name: string; element: JSX.Element }>,
) => {
  return listItems && listItems.length > 0 ? (
    <div
      className="dch-scrollbar"
      style={{ maxHeight: "200px", overflowY: "scroll" }}
    >
      <List style={{ marginTop: 0 }}>
        {listItems?.map((item) => (
          <List.Item key={item.name} css={tw`truncate`}>
            {item.element ?? item.name}
          </List.Item>
        ))}
      </List>
    </div>
  ) : (
    <span>{EMPTY_LIST_MSG}</span>
  );
};
