import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { ApiError } from "data/http";
import { ClassHypernym, DCHJsonClassResponse, masonApi } from "data/Mason";
import { REJECTED_PROMISE } from "data/constants";
import Form from "components/shared/Forms/ReactHookForm";
import { LabelValue, SEARCH_OPTION_INPUT_WIDTHS } from "./Common";
import { SearchParamsValues } from "./SearchForm";
import { SearchOption } from "./SearchOption";
import { IncludeSubclassesCheckbox } from "./IncludeSubclassesCheckbox";

const LOCATIONS_FIELD_NAME: keyof SearchParamsValues = "locations";
const SUBCLASSES_FIELD_NAME: keyof SearchParamsValues =
  "includeLocationsSubclasses";

export const LocationsSelectInput = ({
  setError,
}: {
  setError: (_: string) => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [locationOptions, setLocationOptions] = useState<
    Array<LabelValue<DCHJsonClassResponse>>
  >([]);

  const { setValue, watch } = useFormContext();
  const currentlySelectedLocations: string[] = watch(LOCATIONS_FIELD_NAME);

  //on load, fetch locations
  useEffect(() => {
    let dchClassOptions: Array<{
      value: DCHJsonClassResponse;
      label: string;
    }> = [];
    const fetchLocations = async () => {
      const getDchHierarchy = (
        hypernym: ClassHypernym,
        onSuccess: (
          _: Array<{ value: DCHJsonClassResponse; label: string }>
        ) => void
      ) => {
        return masonApi.getSchemaTypes({ hypernym }).then(
          (res) => {
            onSuccess(
              res.map((a: DCHJsonClassResponse) => ({
                value: a,
                label: a.label,
              }))
            );
          },
          (e: ApiError) => e.message || `Could not fetch ${hypernym} types`
        );
      };
      setIsLoading(true);
      const results = await Promise.allSettled([
        getDchHierarchy(ClassHypernym.Location, (opts) => {
          dchClassOptions = dchClassOptions.concat(opts);
        }),
        getDchHierarchy(ClassHypernym.Zone, (opts) => {
          dchClassOptions = dchClassOptions.concat(opts);
        }),
      ]).finally(() => setIsLoading(false));
      setLocationOptions(
        dchClassOptions.sort((a, b) => a.label.localeCompare(b.label))
      );

      const rejected = results
        .filter((r) => r.status === REJECTED_PROMISE)
        .map((r) => (r as PromiseRejectedResult).reason.error);

      if (rejected.length > 0)
        setError(rejected.reduce((c, a) => a + "\n" + c, "").trim());
    };
    fetchLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const input = (
    <Form.SelectInput
      name={LOCATIONS_FIELD_NAME}
      label="Restrict to these types of locations"
      placeholder="Select location types ..."
      noOptionsMessage="No location types found."
      options={locationOptions.map((o) => ({
        value: o.value.type,
        text: o.label,
      }))}
      isMulti
      isLoading={isLoading}
      search
      inputWidths={SEARCH_OPTION_INPUT_WIDTHS}
    />
  );

  return (
    <SearchOption
      input={input}
      rightCheckboxes={[
        <IncludeSubclassesCheckbox
          key={SUBCLASSES_FIELD_NAME}
          fieldName={SUBCLASSES_FIELD_NAME}
          isLoading={isLoading}
          currentlySelectedClasses={currentlySelectedLocations}
          options={locationOptions}
          updateValues={(subclasses: string[]) => {
            setValue(
              LOCATIONS_FIELD_NAME,
              Array.from(new Set(currentlySelectedLocations.concat(subclasses)))
            );
          }}
        />,
      ]}
    />
  );
};
