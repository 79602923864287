import React from "react";
import { ApiKeyResponse, enodiaApi } from "data/Enodia";
import { ModalComponent } from "../ApiKeyModal";
import { API_KEY_DETAILS_FORM_ID, KeyDetailsForm } from "../KeyDetailsForm";

export const getEditModal = (
  apiKey: ApiKeyResponse,
  setDisableConfirm: (_: boolean) => void,
  onSuccess: () => void,
  onError: (_: string) => void,
  onConfirm: () => void
): ModalComponent => ({
  header: `Edit API Key: ${apiKey.name}`,
  content: (
    <KeyDetailsForm
      defaultValues={apiKey}
      onSubmit={(key) => {
        onConfirm();
        key.id &&
          enodiaApi
            .patchApiKey(key.id)({
              name: key.name,
              expires: key.expires
                ? new Date(key.expires).toISOString()
                : "never",
              enabled: key.enabled,
            })
            .then(
              () => onSuccess(),
              () => onError("Error editing API key")
            );
      }}
      setFormState={({ isValid }) => setDisableConfirm(!isValid)}
    />
  ),
  submitFormId: API_KEY_DETAILS_FORM_ID,
});
