/** @jsxImportSource @emotion/react */

import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import _ from "lodash";
import {
  Button,
  ButtonGroup,
  DropdownItemProps,
  Grid,
  Icon,
} from "semantic-ui-react";
import tw from "twin.macro";
import { GridSvgIcon } from "assets/icons/grid";
import { RowSvgIcon } from "assets/icons/rows";
import {
  GetApplicationsSearchParams,
  SortOptions,
  SortOrder,
} from "data/Metis";
import {
  FilterInput,
  orderAlphabetically,
  SelectInput,
} from "components/shared";
import {
  AppListView,
  AppDisplayInfo,
  CategoryFilter,
  defaultFilters,
  VendorFilter,
} from ".";

export enum FilterOption {
  vendor,
  category,
  application,
  site,
  building,
}

export enum SortByOption {
  name,
  vendor,
  createdTime,
  instanceName,
  applicationName,
}

const sortOptionsMap: Map<SortByOption, DropdownItemProps[]> = new Map([
  [
    SortByOption.name,
    [
      {
        text: "Name, Ascending",
        value: `${SortOptions.name}_${SortOrder.ASCENDING}`,
      },
      {
        text: "Name, Descending",
        value: `${SortOptions.name}_${SortOrder.DESCENDING}`,
      },
    ],
  ],
  [
    SortByOption.vendor,
    [
      {
        text: "Vendor, Ascending",
        value: `${SortOptions.vendorId}_${SortOrder.ASCENDING}`,
      },
      {
        text: "Vendor, Descending",
        value: `${SortOptions.vendorId}_${SortOrder.DESCENDING}`,
      },
    ],
  ],
  [
    SortByOption.createdTime,
    [
      {
        text: "Created Time, Ascending",
        value: `${SortOptions.createdTime}_${SortOrder.ASCENDING}`,
      },
      {
        text: "Created Time, Descending",
        value: `${SortOptions.createdTime}_${SortOrder.DESCENDING}`,
      },
    ],
  ],
  [
    SortByOption.instanceName,
    [
      {
        text: "Name, Ascending",
        value: `${SortOptions.instanceName}_${SortOrder.ASCENDING}`,
      },
      {
        text: "Name, Descending",
        value: `${SortOptions.instanceName}_${SortOrder.DESCENDING}`,
      },
    ],
  ],
  [
    SortByOption.applicationName,
    [
      {
        text: "Application Name, Ascending",
        value: `${SortOptions.applicationName}_${SortOrder.ASCENDING}`,
      },
      {
        text: "Application Name, Descending",
        value: `${SortOptions.applicationName}_${SortOrder.DESCENDING}`,
      },
    ],
  ],
]);

type FilterApplicationHeaderProps = {
  appListView: AppListView;
  setAppListView: (value: SetStateAction<AppListView>) => void;
  appListQuery: GetApplicationsSearchParams;
  setAppListQuery: Dispatch<SetStateAction<GetApplicationsSearchParams>>;
  filterOptions: FilterOption[];
  sortByOptions: SortByOption[];
  initialAppList?: AppDisplayInfo[];
};

export const FilterApplicationsHeader: FC<FilterApplicationHeaderProps> = ({
  appListView,
  setAppListView,
  appListQuery,
  setAppListQuery,
  filterOptions,
  sortByOptions,
  initialAppList,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [showFilters, setShowFilters] = useState<boolean>(false);

  const sortOptions = sortByOptions.flatMap(
    (option) => sortOptionsMap.get(option) ?? [],
  );

  const applicationNameOptions: DropdownItemProps[] = [];
  initialAppList?.forEach((app) => {
    if (app.applicationRecipeUid && app.applicationName) {
      if (
        !applicationNameOptions
          .map((op) => op.value)
          .includes(app.applicationRecipeUid)
      ) {
        applicationNameOptions.push({
          text: app.applicationName,
          value: app.applicationRecipeUid,
        });
      }
    }
  });
  const siteOptions: DropdownItemProps[] = [];
  initialAppList?.forEach((app) => {
    app.sites?.forEach((site) => {
      if (!siteOptions.map((op) => op.value).includes(site.siteId)) {
        siteOptions.push({
          text: site.label && site.label !== "" ? site.label : site.siteId,
          value: site.siteId,
        });
      }
    });
  });

  const buildingOptions: DropdownItemProps[] = [];
  initialAppList?.forEach((app) => {
    app.buildings?.forEach((building) => {
      if (
        !buildingOptions.map((op) => op.value).includes(building.buildingId)
      ) {
        buildingOptions.push({
          text:
            building.label && building.label !== ""
              ? building.label
              : building.buildingId,
          value: building.buildingId,
        });
      }
    });
  });

  const resetFilters = () => {
    setAppListQuery(defaultFilters);
    setSearchTerm("");
  };

  const handleQueryChange = useCallback(
    (fields: Partial<GetApplicationsSearchParams>) => {
      setAppListQuery((p) => ({ ...p, ...fields }));
    },
    [setAppListQuery],
  );

  const deleteQueryFields = useCallback(
    (fieldKeys: (keyof GetApplicationsSearchParams)[]) => {
      const query = _.omit(appListQuery, fieldKeys);
      setAppListQuery(query);
    },
    [appListQuery, setAppListQuery],
  );

  // handle search term case separately, since data transformation needed for params
  useEffect(() => {
    handleQueryChange({ name: `*${searchTerm}*` });
  }, [handleQueryChange, searchTerm]);

  return (
    <div css={tw`mb-4`} data-test-id="sortAndFilter">
      <div css={tw`flex flex-row gap-2 items-center mb-2`}>
        <div css={tw`flex flex-row  basis-1/3`}>
          <FilterInput
            handleSearch={(term) => {
              setSearchTerm(term);
            }}
            value={searchTerm}
            onClear={() => {
              deleteQueryFields(["name"]);
            }}
            placeholder="Begin typing to search..."
          />
        </div>
        <div css={tw`basis-1/3`}>
          <Button
            inverted
            basic={true}
            onClick={() => {
              setShowFilters((p) => !p);
            }}
          >
            <Icon name="filter" />
            {showFilters ? "Hide Filters" : "Show Filters"}
            <Icon
              className="icon-spaced"
              name={showFilters ? "caret down" : "caret right"}
            />
          </Button>
          {showFilters && (
            <Button
              inverted
              basic={true}
              onClick={() => {
                resetFilters();
              }}
            >
              <Icon name="undo alternate" />
              Reset Filters
            </Button>
          )}
        </div>
        <div css={tw`flex-1 flex flex-row gap-x-2 justify-end items-center`}>
          <ButtonGroup basic inverted size="mini">
            <Button
              active={appListView === AppListView.Grid}
              onClick={() => setAppListView(AppListView.Grid)}
              data-test-id="gridButton"
            >
              <GridSvgIcon
                color={appListView === AppListView.Grid ? "white" : "grey"}
                width={30}
                height={24}
                gap={2}
              />
            </Button>
            <Button
              active={appListView === AppListView.Rows}
              onClick={() => setAppListView(AppListView.Rows)}
              data-test-id="rowButton"
            >
              <RowSvgIcon
                color={appListView === AppListView.Rows ? "white" : "grey"}
                width={30}
                height={24}
                strokeWidth={3}
              />
            </Button>
          </ButtonGroup>
          <Grid css={tw`flex-1`}>
            <Grid.Row>
              <Grid.Column>
                <SelectInput
                  placeholder="Sort By"
                  options={sortOptions}
                  onChange={(_, { value }) => {
                    if (value) {
                      const splitBySortAndOrder = value.split("_");
                      handleQueryChange({
                        sortBy: splitBySortAndOrder[0],
                        sortOrder: splitBySortAndOrder[1],
                      });
                    } else {
                      handleQueryChange(defaultFilters);
                    }
                  }}
                  value={`${appListQuery.sortBy}_${appListQuery.sortOrder}`}
                  isClearable={false}
                  testId="applications-sort-select"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
      {showFilters && (
        <Grid>
          <Grid.Row columns={4}>
            {filterOptions.includes(FilterOption.vendor) && (
              <Grid.Column>
                <VendorFilter
                  value={appListQuery.vendorUid ?? ""}
                  onChange={(_, { value }) => {
                    value
                      ? handleQueryChange({ vendorUid: value })
                      : deleteQueryFields(["vendorUid"]);
                  }}
                />
              </Grid.Column>
            )}
            {filterOptions.includes(FilterOption.category) && (
              <Grid.Column>
                <CategoryFilter
                  value={appListQuery.categoryUid ?? ""}
                  onChange={(_, { value }) => {
                    value
                      ? handleQueryChange({ categoryUid: value })
                      : deleteQueryFields(["categoryUid"]);
                  }}
                />
              </Grid.Column>
            )}
            {filterOptions.includes(FilterOption.application) && (
              <Grid.Column>
                <SelectInput
                  verticalLayout
                  label="Application Name"
                  placeholder="Filter by name..."
                  options={applicationNameOptions}
                  onChange={(_, { value }) => {
                    value
                      ? handleQueryChange({ applicationRecipeUid: value })
                      : deleteQueryFields(["applicationRecipeUid"]);
                  }}
                  value={appListQuery.applicationRecipeUid ?? ""}
                  search
                  testId="nameFilter"
                />
              </Grid.Column>
            )}
            {filterOptions.includes(FilterOption.site) && (
              <Grid.Column>
                <SelectInput
                  verticalLayout
                  label="Site"
                  placeholder="Filter by site..."
                  options={siteOptions.sort(orderAlphabetically)}
                  onChange={(_, { value }) => {
                    value
                      ? handleQueryChange({ siteId: value })
                      : deleteQueryFields(["siteId"]);
                  }}
                  value={appListQuery.siteId ?? ""}
                  search
                  testId="siteIdFilter"
                />
              </Grid.Column>
            )}
            {filterOptions.includes(FilterOption.building) && (
              <Grid.Column>
                <SelectInput
                  verticalLayout
                  label="Building"
                  placeholder="Filter by building..."
                  options={buildingOptions.sort(orderAlphabetically)}
                  onChange={(_, { value }) => {
                    value
                      ? handleQueryChange({ buildingId: value })
                      : deleteQueryFields(["buildingId"]);
                  }}
                  value={appListQuery.buildingId ?? ""}
                  search
                  testId="buildingIdFilter"
                />
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      )}
    </div>
  );
};
