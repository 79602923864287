// eslint-disable-next-line react-hooks/exhaustive-deps
import { debounce } from "throttle-debounce";
import { BuildingId, SiteId } from "data/brick";
import { OrgId } from "data/Enodia";
import { masonApi } from "data/Mason";
import { API_CALL_DEBOUNCE_MS } from "data/validation";

export const checkSiteOrBuildingId = debounce(
  API_CALL_DEBOUNCE_MS,
  async (
    orgId: OrgId,
    siteId: SiteId,
    resolve: (_: string | undefined) => void,
    buildingId?: BuildingId
  ) => {
    const errorMsg = `A ${
      buildingId ? "building" : "site"
    } with this ID already exists. Please choose another ID.`;
    const error: string | undefined = buildingId
      ? await masonApi.getBuilding({ orgId, siteId, buildingId }).then(
          () => errorMsg,
          () => undefined
        )
      : await masonApi.getSite({ orgId, siteId }).then(
          () => errorMsg,
          () => undefined
        );
    resolve(error);
  }
);
