import { SiteId } from "data/brick";

/**
 * UID TYPES
 */
export type OrgUid = string & { readonly __tag: unique symbol };
export type OrgId = string & { readonly __tag: unique symbol };
export type RoleSetId = string & { readonly __tag: unique symbol };
export type InvitationId = string & { readonly __tag: unique symbol };
export type UserGroupId = string & { readonly __tag: unique symbol };
export type UserId = string & { readonly __tag: unique symbol };
export type ApiKeyId = string & { readonly __tag: unique symbol };
export type AgreementId = string & { readonly __tag: unique symbol };
export type AuthorityId = string & { readonly __tag: unique symbol };

/**
 * OAUTH
 */
export type OauthTokenResponse = {
  subject: string;
  accessToken: string;
  expiry: number;
};

/**
 * ROLESETS
 */
export type RoleSetResponse = {
  organisationId: OrgId;
  id: RoleSetId;
  name: string;
  description?: string;
  roleIds: Array<string>;
};

export type RoleSetCreateRequest = {
  organisationId: OrgId;
  name: string;
  description?: string;
  newRoles: Array<RoleRequest>;
  roleIds: Array<string>;
};

export type RoleSetPatchRequest = {
  organisationId?: OrgId;
  name?: string;
  description?: string;
  deleteAllPreviousRoles?: boolean;
  deleteRoleIds?: Array<string>;
  newRoles?: Array<RoleRequest>;
  addRoleIds?: Array<string>;
};

/**
 * INVITATIONS
 */
export type RolesAndGroupsDescription = {
  id: string;
  name: string;
  description?: string;
};
export type InvitationResponse = {
  id: InvitationId;
  recipient: string;
  roleSets: Array<RolesAndGroupsDescription>;
  userGroups: Array<RolesAndGroupsDescription>;
  expires: Date;
  assignedAuthority: AuthoritySummary;
  organisation: OrganisationSummary;
  requiredAgreements: Array<RequiredAgreement>;
};

export type InvitationCreateRequest = {
  recipients: Array<string>;
  roleSetIds?: Array<string>;
  userGroupIds?: Array<UserGroupId>;
  assignAuthority: AuthorityId;
};

/** USER GROUPS */
export type UserGroupResponse = {
  organisationId?: OrgId;
  id: UserGroupId;
  name: string;
  description?: string;
  inGroupIDs?: Array<UserGroupId>;
  roleSetIDs?: Array<RoleSetId>;
};

export type UserGroupCreateRequest = {
  organisationId?: OrgId;
  name: string;
  description?: string;
  roleSetIds: Array<RoleSetId>;
  userGroupIds: Array<UserGroupId>;
};

export type UserGroupPatchRequest = {
  organisationId?: OrgId;
  name: string;
  description: string;
  addRoleSetIds?: Array<RoleSetId>;
  deleteRoleSetIds?: Array<RoleSetId>;
  deleteAllRoleSets?: Boolean;
  addUserGroupIds?: Array<UserGroupId>;
  deleteUserGroupIds?: Array<UserGroupId>;
  deleteAllUserGroups?: Boolean;
};

/** ORGANISATION */
export type OrganisationResponse = {
  uid: string;
  id: string;
  name: string;
  description?: string;
  senapsGroupId?: string;
  globallyVisible: boolean;
};

/**
 * USER
 */
// lightweight entity returned from GET /me
export type MeUser = {
  id: UserId;
  pendingInvitations?: Array<InvitationResponse>;
  requiredAgreements?: Array<RequiredAgreement>;
};

export type MeUserParams = {
  withAgreements?: boolean;
  withInvitations?: boolean;
};

export type RequiredAgreement = {
  agreementId: AgreementId;
  title: string;
  href: string;
  forOrganisations?: OrganisationSummary[];
};

export type OrganisationSummary = {
  uid: OrgUid;
  id: OrgId;
  name: string;
};

export type UserResponse = {
  id: UserId;
  sandboxOrganisation?: OrganisationResponse;
  roleSetIds: Array<RoleSetId>;
  userGroupIds: Array<UserGroupId>;
  authorityId: AuthorityId;
};

/**
 * @deprecated - replaced with UpdatedUserAssignment
 */
export type UserAssignmentResponse = {
  organisationUid?: OrgUid;
  organisationId?: OrgId;
  hasAuthority?: Authority;
  roleSets: RoleSetResponse[];
  userGroups: UserGroupResponse[];
};

/**
 * @todo - once UserAssingmentResponse is all replaced with this, rename this type back to UserAssignmentResponse
 */
export type UpdatedUserAssignmentResponse = {
  inGroups: UserGroupResponse[];
  assignedRoleSets: RoleSetResponse[];
  assignedAuthority: Authority;
  canAssignAuthorities: AuthoritySummary[];
};

/**  @alias BulkUserAssignmentWriteDTO in Enodia backend */
export type UserAssignmentRequest = {
  userIds: Array<string>;
  grantRoleSetIds?: Array<String>;
  revokeRoleSetIds?: Array<String>;
  revokeAllRoleSets?: boolean;
  addToUserGroupIds?: Array<String>;
  removeFromUserGroupIds?: Array<String>;
  removeAllUserGroups?: boolean;
};

export type UsersResponse = {
  users: Array<UserResponse>;
  roleSets: Array<RoleSetResponse>;
  userGroups: Array<UserGroupResponse>;
  authorities: Array<AuthoritySummary>;
};

/**
 * ROLES
 */
export type RoleResponse = {
  id: string;
  organisationId?: OrgId;
  siteId?: SiteId;
  name: string;
  verbs: Array<string>;
  scopeType: ScopeType;
  scopeId?: string;
};

// dch-AddRoleDto/RoleDto
export type RoleRequest = Omit<RoleResponse, "id">;

export enum ScopeType {
  Organisation = "ORGANISATION",
  Site = "SITE",
  Building = "BUILDING",
  // User = "USER",
  // Role = "ROLE",
  // RoleSet = 'ROLE_SET',
  // UserGroup = "USER_GROUP",
  // DataSource = "DATA_SOURCE",
  // DataPool = "DATA_POOL",
  // DataGroup = "DATA_GROUP",
  // Point = "POINT",
  // ClassifierSet = "CLASSIFIER_SET",
  // ClassificationPlan = "CLASSIFICATION_PLAN"
}

/** GENERIC PERMISSION PARAMS */
export type PermissionParams = {
  offset?: number;
  limit?: number;
  organisation?: string;
  id?: string;
  name?: string;
  description?: string;
};

/**
 * API KEY
 */
export type ApiKey = ApiKeyResponse & ApiKeyCreateResponse;

export type ApiKeyResponse = {
  id: ApiKeyId;
  name: string;
  keyHead: string;
  keyTail: string;
  enabled: boolean;
  created: string;
  expires?: string;
};

export type ApiKeyCreateRequest = {
  name?: string;
  enabled?: boolean;
  expires?: string;
};

export type ApiKeyCreateResponse = {
  key: string;
  id: ApiKeyId;
  name: string;
  enabled: boolean;
  created: string;
  expires?: string;
};

/**
 * TERMS AND CONDITIONS
 */
export type Authority = {
  id: AuthorityId;
  title: string;
  description: string;
  canAssignAuthorities: string[];
};

export type AuthoritySummary = Omit<Authority, "canAssignAuthorities">;

export type AuthorityPatchRequest = {
  setAuthority: AuthorityId;
};

/**
 * AGREEMENTS
 */
export type AcceptAgreementRequest = {
  agreementId: AgreementId;
  forOrganisation?: OrgId;
};
