import * as React from "react";
/** @jsxImportSource @emotion/react */

import tw from "twin.macro";
import * as S from "fp-ts/lib/Set";
import {
  LocationId,
  LocationType,
  ModelRootType,
  PointId,
  UidType,
  eqPointId,
} from "../../data/brick";
import * as O from "fp-ts/lib/Option";
import { Link } from "react-router-dom";
import {
  masonViewBuildingPath,
  viewBuildingPath,
  viewSitePath,
} from "../../Routes";
import { Ord } from "fp-ts/lib/Ord";
import { labelOrId } from "../shared/Common";
import { Button, Modal, Checkbox, Grid } from "semantic-ui-react";
import { SemanticWIDTHS } from "semantic-ui-react/dist/commonjs/generic";
import { PointChart } from "components/Data/PointData";
import { PointDataModal } from "./PointDataModal";
import { eqResult, Result, ResultLocation } from "./result-utils";
import { OrgId } from "data/Enodia";
import { CurlyBrace } from "./CurlyBrace";
import { getCompositePointIdFromRdfId } from "data/Aletheia";

const COLUMN_WIDTH: SemanticWIDTHS[] = [2, 4, 10];

type SearchResultsProps = {
  results: Set<Result>;
};
export const SearchResults: React.FC<SearchResultsProps> = ({ results }) => {
  const renderResult = (r: Result, displayIndex: string): JSX.Element => {
    const { orgId, siteId, buildingId } = r.building;

    const viewNodePath = (h: string) =>
      buildingId !== undefined
        ? masonViewBuildingPath(
            orgId as unknown as OrgId,
            siteId,
            buildingId,
            h
          )
        : viewSitePath(orgId, siteId, h);

    const nodeLink = (path: string, label: string, type?: string) => (
      <div>
        <div>
          <Link to={path}>{label}</Link>
        </div>
        {type && <div css={tw`text-xs`}>{type}</div>}
      </div>
    );

    const nodeLinkForLocation = (l: ResultLocation) => {
      if (l.type === ModelRootType.Site) {
        return nodeLink(
          viewSitePath(orgId, siteId),
          labelOrId(siteId, O.fromNullable(r.building.siteId)),
          l.type
        );
      } else if (l.type === ModelRootType.Building && buildingId) {
        return nodeLink(
          masonViewBuildingPath(orgId as unknown as OrgId, siteId, buildingId),
          labelOrId(buildingId, O.fromNullable(r.building.label)),
          l.type
        );
      } else {
        return nodeLink(
          viewNodePath(l.location),
          labelOrId(l.location, O.fromNullable(l.label)),
          l.type
        );
      }
    };

    const locations = [r.parent2, r.parent1, r.location];
    let breadcrumbElements = [];

    const hasSiteOrBuilding = locations.find(
      (l) =>
        l &&
        (l.type === ModelRootType.Site || l.type === ModelRootType.Building)
    );

    if (!hasSiteOrBuilding) {
      breadcrumbElements.push(
        buildingId &&
          nodeLink(
            viewBuildingPath(orgId as unknown as OrgId, siteId, buildingId),
            labelOrId(buildingId, O.fromNullable(r.building.label)),
            ModelRootType.Building
          )
      );
    }

    breadcrumbElements = breadcrumbElements
      .concat(
        locations
          .map((l) => l && nodeLinkForLocation(l))
          .concat([
            nodeLinkForLocation({
              location: r.point.id as string as LocationId,
              label: r.point.label,
              type: r.point.type,
            }),
          ])
      )
      .flatMap((x) => (x ? [x] : []));

    const breadcrumb = (
      <Grid columns="equal" textAlign="center" verticalAlign="middle">
        <Grid.Row>
          {breadcrumbElements.map((b, index) => (
            <React.Fragment key={index}>
              <Grid.Column css={tw`break-all`}>{b}</Grid.Column>
              {index < breadcrumbElements.length - 1 ? (
                <Grid.Column>
                  <CurlyBrace />
                </Grid.Column>
              ) : null}
            </React.Fragment>
          ))}
        </Grid.Row>
      </Grid>
    );

    return (
      <Grid.Row
        key={`${r.point.id}${r.location.location}`}
        css={tw`even:bg-core-grey-dark`}
      >
        <Grid.Column width={COLUMN_WIDTH[0]} textAlign="center">
          {r.point.id === undefined ? (
            <em>No points found</em>
          ) : (
            <Checkbox
              defaultChecked={S.elem(eqPointId)(r.point.id, chartPoints)}
              onChange={(e, { checked }) =>
                updateChartPoints(
                  checked
                    ? S.insert(eqPointId)(r.point.id)
                    : S.remove(eqPointId)(r.point.id)(chartPoints)
                )
              }
              disabled={
                chartPoints.size >= 5 &&
                !S.elem(eqPointId)(r.point.id, chartPoints)
              }
            />
          )}
        </Grid.Column>

        <Grid.Column width={COLUMN_WIDTH[1]} css={tw`break-all`}>
          {r.point.id !== undefined && (
            <PointDataModal
              id={getCompositePointIdFromRdfId(r.point.id)}
              buttonLabel={`${displayIndex}. ${r.point.label || r.point.id}`}
            />
          )}
        </Grid.Column>

        <Grid.Column width={COLUMN_WIDTH[2]}>{breadcrumb}</Grid.Column>
      </Grid.Row>
    );
  };

  // TODO: augment this to get stable ordering of results.
  const ordResult: Ord<Result> = {
    equals: eqResult.equals,
    compare: (x, y) =>
      x.location.type === y.location.type
        ? 0
        : x.location.type === LocationType.Wing
        ? 1
        : y.location.type === LocationType.Wing
        ? -1
        : x.location.type === LocationType.Floor
        ? 1
        : -1,
  };

  // Group results by point id
  const resultsByPointId = S.toArray(ordResult)(results).reduce(
    (map, result) => {
      const r = map.get(result.point.id);
      if (r) {
        map.set(result.point.id, r.concat([result]));
      } else {
        map.set(result.point.id, [result]);
      }
      return map;
    },
    new Map<PointId, Result[]>()
  );

  const [isChartOpen, updateIsChartOpen] = React.useState(false);
  const [chartPoints, updateChartPoints] = React.useState<Set<UidType>>(
    new Set()
  );

  return (
    <div css={tw`mt-4`}>
      <Modal
        size="fullscreen"
        dimmer="blurring"
        open={isChartOpen}
        closeIcon
        onClose={() => updateIsChartOpen(false)}
      >
        <Modal.Header>{`Selected Streams (${chartPoints.size})`}</Modal.Header>
        <Modal.Content>
          <PointChart
            points={Array.from(results)
              .filter((d) => Array.from(chartPoints).includes(d.point.id))
              .map((d) => {
                const compositeId = getCompositePointIdFromRdfId(d.point.id);
                return {
                  id: compositeId,
                  name: d.point.label,
                  uid: compositeId,
                };
              })}
          />
        </Modal.Content>
      </Modal>

      <h2>
        Search Results{" "}
        {resultsByPointId.size
          ? `(${resultsByPointId.size} ${
              resultsByPointId.size > 1 ? "results" : "result"
            })`
          : ``}
      </h2>
      {resultsByPointId.size > 0 ? (
        <div>
          <div
            css={tw`mt-4 border-2 border-white border-solid`}
            className="dch-scrollbar"
            style={{ maxHeight: "500px", overflow: "scroll" }}
          >
            <div css={tw`sticky top-0 bg-core-grey-dark p-4 z-3`}>
              <Grid verticalAlign="middle">
                <Grid.Row>
                  <Grid.Column width={COLUMN_WIDTH[0]} textAlign="center">
                    {/* TODO: Button is moved up to avoid issue on smaller screen size. Once
                    pagination is complete in https://jira.csiro.au/browse/DCH-1328, we
                    might move the button back to the bottom of the screen */}
                    <Button
                      primary
                      onClick={() => {
                        updateIsChartOpen(true);
                      }}
                      disabled={
                        chartPoints.size === 0 || resultsByPointId.size === 0
                      }
                    >
                      Show chart ({chartPoints.size})
                    </Button>
                  </Grid.Column>
                  <Grid.Column width={COLUMN_WIDTH[1]}>
                    Point (data stream) and link
                  </Grid.Column>
                  <Grid.Column width={COLUMN_WIDTH[2]} textAlign="center">
                    Place in Model
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>

            <div css={tw`p-4`}>
              <Grid verticalAlign="middle">
                {Array.from(resultsByPointId.values()).flatMap(
                  (results, resultGroupIndex) =>
                    results.map((r, resultIndex) =>
                      renderResult(
                        r,
                        `${resultGroupIndex + 1}${
                          results.length === 1 ? "" : `.${resultIndex + 1}`
                        }`
                      )
                    )
                )}
              </Grid>
            </div>
          </div>
        </div>
      ) : (
        <p>No results found.</p>
      )}
    </div>
  );
};
