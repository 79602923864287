import React from "react";
import { EntityPropertyAttributeSchema } from "data/Mason/EntityProperties";
import {
  convertCamelCaseToTitleCase,
  InputType,
  toOptionTypeBase,
} from "components/shared";
import Form from "components/shared/Forms/ReactHookForm";
type Props = {
  attribute: EntityPropertyAttributeSchema;
  entityPropertyFieldName: string;
  required?: boolean;
};

export const AletheiaEntityPropertyAttribute = (props: Props) => {
  const { attribute, entityPropertyFieldName, required } = props;
  const { id, enumValues, type, maximum, minimum } = attribute;

  const numberWithinLimitsValidator = (v?: number) => {
    if (!v) return undefined;
    return minimum && v < minimum
      ? `Value must be greater than ${minimum}`
      : maximum && v > maximum
      ? `Value must be less than ${maximum}`
      : undefined;
  };

  const inputProps = {
    name: `${entityPropertyFieldName}.${id}`,
    label: convertCamelCaseToTitleCase(id),
    required: required ?? attribute.required,
    rules: {
      validate:
        type && type.length === 1 && (type[0] as InputType) === "number"
          ? numberWithinLimitsValidator
          : undefined,
    },
    verticalLayout: true,
  };

  if (enumValues && enumValues.length !== 0) {
    const options = enumValues?.map(toOptionTypeBase) ?? [];

    return <Form.SelectInput {...inputProps} options={options} />;
  }

  return (
    <Form.TextInput
      {...inputProps}
      type={type && type.length === 1 ? (type[0] as InputType) : "string"}
      validateOnChange
    />
  );
};
