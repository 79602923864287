import { DataSourceId, DataSourceType } from "data/Aletheia";

export const DEFAULT_DECODER_ID = "c3656769-d55a-4abf-bbef-4fbe2e61b643";
export const DEFAULT_TRANSPORT_CONFIG_TYPE = DataSourceType.senaps_mqtt_client;

export const displayType = (type: DataSourceType) => {
  switch (type) {
    case DataSourceType.senaps_mqtt_client:
      return "MQTT Client";

    case DataSourceType.dch_rest_api:
      return "DCH Rest Api";
  }
};

export const createCompositeId = (
  orgId: string,
  Id: DataSourceId | string
): string => {
  return `${orgId}:${Id}`;
};
