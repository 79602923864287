/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import {
  Fragment,
  useCallback,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  OnChangeFn,
  RowSelectionState,
  Updater,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Checkbox, Table } from "semantic-ui-react";
import {
  ClassifierId,
  DirectiveState,
  ClassificationDirectiveResponse,
  DesiredPointStates,
  ProposedDirectivePutRequest,
  pointClassifierApi,
  EntityPropertyKeyValue,
} from "data/Aletheia";
import {
  DirectiveOptionsKeys,
  classifierDirectiveColumns,
  defaultColumn,
} from "./ClassificationPlanTableDefs";
import { getKeyFromId, useSkipper } from "../PointClassifierUtils";
import { DCHJsonClassResponse, Unit } from "data/Mason";
import {
  castNumTypeToKeyValues,
  removeEPEmptyFields,
  toOptionsDCHJsonClasses,
  toOptionsUnits,
} from "../ClassifierSet";
import {
  convertDirectiveResponseToPutRequest,
  directiveViewOptions,
  displayGreenCondition,
  displayRedCondition,
  handleDirectiveChange,
  mkClassificationPlanEPFormId,
} from "./ClassificationPlanUtils";
import { SelectInput, UIStatus } from "components/shared";
import Form from "components/shared/Forms/ReactHookForm";
import {
  AletheiaDirectiveEntityProperties,
  EntityPropertyComparisonRow,
} from "../EntityProperties";
import { mergeToComparisonRows } from "../EntityProperties";
import { FieldValues } from "react-hook-form";

export interface DirectiveAccordionFormFields extends FieldValues {
  properties: EntityPropertyComparisonRow[];
}

type ClassificationPlanTableProps = {
  directives: Array<ClassificationDirectiveResponse>;
  handleActiveClassifier: (classifierId?: ClassifierId) => void;
  updateDirectives: (_: ClassificationDirectiveResponse) => void;
  viewOption: DirectiveState;
  setViewOption: Dispatch<SetStateAction<DirectiveState>>;
  setStatus: Dispatch<SetStateAction<UIStatus>>;
  schemaClassesList: DCHJsonClassResponse[];
  unitsList: Unit[];
  setManualChangesCount: Dispatch<SetStateAction<number>>;
  setActiveManualDirectiveRow: Dispatch<SetStateAction<number | undefined>>;
  onApplyRules: () => void;
};

/**
 * All things relating to how the table is rendered and initialised using the Tanstack/table library
 */
export const ClassificationPlanTable: React.FunctionComponent<
  ClassificationPlanTableProps
> = ({
  directives,
  handleActiveClassifier,
  updateDirectives,
  viewOption,
  setViewOption,
  setStatus,
  schemaClassesList,
  unitsList,
  setManualChangesCount,
  setActiveManualDirectiveRow,
  onApplyRules,
}) => {
  const [columns] = useState<typeof classifierDirectiveColumns>(
    classifierDirectiveColumns
  );
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [initialColumnVisibility, setInitialColumnVisibility] = useState({});
  const [editActivePosition, setEditActivePosition] = useState<
    string | undefined
  >();
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();
  const [hideCurrent, setHideCurrent] = useState(false);

  useEffect(() => {
    directives.forEach((directive, index) => {
      setRowSelection((original) => {
        original[index] = directive.action;
        return original;
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directives]);

  const putDirectiveUpdates = useCallback(
    (
      updatedDirectiveObject: ClassificationDirectiveResponse,
      onSaveError: (e: any) => void
    ): Promise<void | ClassificationDirectiveResponse> => {
      // use this information to concurrently send a PUT request
      const request: ProposedDirectivePutRequest =
        convertDirectiveResponseToPutRequest(
          updatedDirectiveObject.planId,
          updatedDirectiveObject.id,
          updatedDirectiveObject
        );

      return pointClassifierApi
        .putDirective(updatedDirectiveObject.planId)(updatedDirectiveObject.id)(
          request
        )
        .then(() => setManualChangesCount((p) => p + 1))
        .catch(onSaveError);
    },
    [setManualChangesCount]
  );

  const updateFrontendOnActionChange = (index: number, checked: boolean) => {
    const updatedDirectiveObject = directives[index];
    updatedDirectiveObject.action = checked;
    updateDirectives(updatedDirectiveObject);
    return updatedDirectiveObject;
  };

  const updateProposedChange = useCallback(
    (
      currentDirective: ClassificationDirectiveResponse,
      key: DirectiveOptionsKeys,
      value?: any
    ) => {
      setStatus((p) => p.setIndeterminate(true));
      // Skip page index reset until after next rerender
      skipAutoResetPageIndex();

      const updatedDirectiveObject = handleDirectiveChange(
        currentDirective,
        key,
        value
      );

      updateDirectives(updatedDirectiveObject);

      putDirectiveUpdates(updatedDirectiveObject, (e) => {
        setStatus((p) => p.setError(e.message));
      }).then(() => setStatus((p) => p.setIndeterminate(false)));
    },

    [putDirectiveUpdates, setStatus, skipAutoResetPageIndex, updateDirectives]
  );

  const putDirectoriesSequentially = async (
    directoriesToUpdate: ClassificationDirectiveResponse[]
  ) => {
    const errors: string[] = [];
    for (const directory of directoriesToUpdate) {
      await putDirectiveUpdates(directory, (e) => {
        errors.push(e.message);
      });
    }
    errors.length > 0 &&
      setStatus((p) =>
        p.setError(
          "An error occurred saving directive. Please try again later or contact support."
        )
      );
  };

  function onCheckActions(
    oldSelection: RowSelectionState,
    newSelection: RowSelectionState
  ) {
    setStatus((p) => p.setIndeterminate(true));
    const rowsToUpdateAction = getRowsToUpdate(oldSelection, newSelection);
    const directoriesToUpdate = rowsToUpdateAction.map(([index, checked]) =>
      updateFrontendOnActionChange(Number(index), checked)
    );
    putDirectoriesSequentially(directoriesToUpdate);
    setStatus((p) => p.setIndeterminate(false));
  }

  const onRowSelectionChange: OnChangeFn<RowSelectionState> = (
    update: Updater<RowSelectionState>
  ) => {
    let newValue = typeof update === "function" ? update(rowSelection) : update;
    onCheckActions(rowSelection, newValue);
  };

  const table = useReactTable({
    data: directives,
    columns,
    state: {
      columnVisibility,
      rowSelection,
    },
    getCoreRowModel: getCoreRowModel(),
    autoResetPageIndex,
    defaultColumn,
    enableRowSelection: true,
    onRowSelectionChange: onRowSelectionChange,
    getRowCanExpand: () => true,
    onColumnVisibilityChange: setInitialColumnVisibility,
    getExpandedRowModel: getExpandedRowModel(),
    meta: {
      updateData: () => {},
      editActivePosition,
      setActivePosition: setEditActivePosition,
      updateProposedChange: updateProposedChange,
      classOptions: schemaClassesList.map(toOptionsDCHJsonClasses),
      unitsOptions: unitsList.map(toOptionsUnits),
    },
  });

  // initialise the grouping for the table on load
  useEffect(() => {
    table.getAllLeafColumns().forEach((col) => {
      col.id.includes("proposed") ? col.pin("right") : col.pin("left");
      col.id.includes("separator") && col.pin(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // On first load, store the union of LHS and RHS headers
  useEffect(() => {
    table
      .getAllLeafColumns()
      .forEach((column) => column.toggleVisibility(true));
  }, [table]);

  // TODO: think of a more optimal way of setting this
  useEffect(() => {
    table.getRowModel().rows.forEach((row) => {
      // in theory, there is only one cell that matches "select", so we use .find()
      const idCell = row
        .getVisibleCells()
        .find((cell) => cell.id.includes("select"));
      if (idCell) row.toggleSelected((idCell.getValue() as boolean) ?? false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Toggle behaviour - Hide/Show Current State (LHS)
  useEffect(() => {
    if (hideCurrent) {
      const updatedObject: VisibilityState = Object.keys(
        columnVisibility
      ).reduce((acc: VisibilityState, key) => {
        if (key.includes("current") || key.includes("separator")) {
          acc[key] = false;
        } else {
          acc[key] = columnVisibility[key];
        }
        return acc;
      }, {});

      setColumnVisibility(updatedObject);
    } else {
      setColumnVisibility(initialColumnVisibility);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideCurrent, initialColumnVisibility]);

  const handleEPChange = useCallback(
    (
      originalRow: ClassificationDirectiveResponse,
      values: EntityPropertyKeyValue[]
    ) => {
      updateProposedChange(
        originalRow,
        DirectiveOptionsKeys.entityProperties,
        removeEPEmptyFields(castNumTypeToKeyValues(values))
      );
    },
    [updateProposedChange]
  );

  const handlePUTAndApplyOnSubmit = useCallback(
    (
      originalRow: ClassificationDirectiveResponse,
      payload: EntityPropertyComparisonRow[]
    ) => {
      const filteredProposed = payload
        .filter(
          (row: EntityPropertyComparisonRow) =>
            row.proposed !== undefined && row.proposed?.ep_type !== ""
        )
        .map(
          (row: EntityPropertyComparisonRow) =>
            row.proposed as EntityPropertyKeyValue
        );

      setStatus((p) => p.setIndeterminate(true));
      // Skip page index reset until after next rerender
      skipAutoResetPageIndex();

      const updatedDirectiveObject = handleDirectiveChange(
        originalRow,
        DirectiveOptionsKeys.entityProperties,
        removeEPEmptyFields(castNumTypeToKeyValues(filteredProposed))
      );

      putDirectiveUpdates(updatedDirectiveObject, (e) => {
        setStatus((p) => p.setError(e.message));
      })
        .then(() => onApplyRules())
        .then(() => setStatus((p) => p.setIndeterminate(false)))
        .catch((e) => setStatus((p) => p.setError(e.message)));
    },
    [onApplyRules, putDirectiveUpdates, setStatus, skipAutoResetPageIndex]
  );

  /** HANDLERS */
  const handleCellClick = (classifierId?: ClassifierId) => {
    handleActiveClassifier(classifierId);
  };

  return (
    <Fragment>
      <span css={tw`flex flex-row space-x-4`}>
        <Checkbox
          css={tw`w-1/3`}
          label="Hide Current State"
          checked={hideCurrent}
          onChange={() => {
            setHideCurrent(!hideCurrent);
          }}
        />
        <SelectInput
          options={directiveViewOptions}
          isMulti={false}
          value={viewOption as string}
          onChange={(_, { value }) => setViewOption(value as DirectiveState)}
          isClearable={false}
        />
      </span>
      <div css={tw`my-3`}>
        <Table inverted striped compact="very">
          <Table.Header>
            {table.getHeaderGroups().map(
              (headerGroup) =>
                headerGroup.depth > 0 && (
                  <Table.Row
                    className="entity-properties-headers"
                    key={headerGroup.id}
                  >
                    {headerGroup.headers.map((header) => (
                      <Table.HeaderCell
                        key={header.id}
                        colSpan={header.colSpan}
                      >
                        {header.isPlaceholder
                          ? null
                          : header.column.columnDef.header &&
                            flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </Table.HeaderCell>
                    ))}
                  </Table.Row>
                )
            )}
          </Table.Header>
          <Table.Body>
            {table.getRowModel().rows.map((row, index) => (
              <Fragment key={`${row.id}-fragment`}>
                <Table.Row key={`${row.id}-table-row`}>
                  {row.getLeftVisibleCells().map((cell) => (
                    <Table.Cell
                      key={`${cell.id}-table-cell`}
                      negative={displayRedCondition(row.original, cell.id)}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Table.Cell>
                  ))}
                  {!hideCurrent && <Table.Cell fluid="true"></Table.Cell>}
                  {row.getRightVisibleCells().map((cell) => (
                    <Table.Cell
                      selectable
                      key={`${cell.id}-table-cell`}
                      onClick={() => {
                        const classifierId =
                          row.original.proposedPointState[
                            getKeyFromId(cell.id) as keyof DesiredPointStates
                          ]?.classifierId;
                        classifierId && handleCellClick(classifierId);
                      }}
                      onDoubleClick={() => {
                        setEditActivePosition(cell.id);
                      }}
                      positive={displayGreenCondition(row.original, cell.id)}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Table.Cell>
                  ))}
                </Table.Row>
                {row.getIsExpanded() && (
                  <Table.Row>
                    <Table.Cell colSpan={row.getVisibleCells().length}>
                      <Form<DirectiveAccordionFormFields>
                        formId={mkClassificationPlanEPFormId(row.index)}
                        onSubmit={(payload) => {
                          handlePUTAndApplyOnSubmit(
                            row.original,
                            payload.properties
                          );
                        }}
                        defaultValues={{
                          properties: mergeToComparisonRows(row),
                        }}
                      >
                        <AletheiaDirectiveEntityProperties
                          verticalLayout
                          isReadOnly={true}
                          formFieldName=""
                          row={row}
                          onEPCellClick={() => {
                            handleCellClick(
                              row.original.proposedPointState.entityProperties
                                ?.classifierId
                            );
                          }}
                          onEPChange={(value: any) =>
                            handleEPChange(row.original, value)
                          }
                          setActiveManualDirectiveRow={
                            setActiveManualDirectiveRow
                          }
                          unitsList={unitsList}
                        />
                      </Form>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Fragment>
            ))}
          </Table.Body>
        </Table>
      </div>
    </Fragment>
  );
};

function getRowsToUpdate(
  oldSelection: RowSelectionState,
  newSelection: RowSelectionState
): [string, boolean][] {
  const removedFromNewSelection = Object.entries(oldSelection)
    .filter(([index]) => newSelection[index] === undefined)
    .map((entry) => {
      entry[1] = false;
      return entry;
    });
  const existsButNotSameValue = Object.entries(newSelection).filter(
    ([index, value]) => oldSelection[index] !== value
  );
  return existsButNotSameValue.concat(removedFromNewSelection);
}
