/** @jsxImportSource @emotion/react */
import { useState, useContext } from "react";
import { FieldValues } from "react-hook-form";
import { Accordion, Icon } from "semantic-ui-react";
import tw from "twin.macro";
import { EnodiaOrgContext } from "App";
import { ApplicationVersionBriefResponse } from "data/Metis";
import { SiteBuildingReference } from "data/QueryApi/queryTypes";
import {
  FormMethods,
  TextInput,
  MarkdownInput,
  Form,
  ModelsSelectInput,
} from "components/shared";
import { getLatestApplicationVersion } from "components/Applications/shared";
import { CompatibilityCheckSegment } from "../InstallApplicationModal";

interface CompatibilityCheckFormValues extends FieldValues {
  version: string;
  models: string[];
  includeAllSites: boolean;
  includeAllBuildings: boolean;
}

export const ApplicationSpecifications = ({
  appVersions,
}: {
  appVersions: ApplicationVersionBriefResponse[];
}) => {
  const { orgId } = useContext(EnodiaOrgContext);
  const [compatibilityCheckOpen, setCompatibilityCheckOpen] = useState(false);
  const [modelsList, setModelsList] = useState<SiteBuildingReference[]>([]);
  const [formMethods, setFormMethods] =
    useState<FormMethods<CompatibilityCheckFormValues>>();

  const latestVersion = getLatestApplicationVersion(appVersions);

  return (
    <>
      <TextInput
        label="Latest Version"
        value={latestVersion?.version}
        isReadOnly
        verticalLayout
      />
      <MarkdownInput
        label="Model Requirements"
        value={
          latestVersion?.requirements && latestVersion.requirements !== ""
            ? latestVersion?.requirements
            : "No requirements provided."
        }
        isReadOnly
        verticalLayout
      />
      <Accordion inverted styled data-test-id="compatibility-check">
        <Accordion.Title
          active={compatibilityCheckOpen}
          onClick={() => {
            !compatibilityCheckOpen && formMethods?.reset();
            setCompatibilityCheckOpen((prev) => !prev);
          }}
        >
          <div>
            <Icon name="dropdown" />
            Check Compatibility
          </div>
          <div css={tw`mt-2 italic`}>
            Check the compatibility of this application with your model prior to
            installation.
          </div>
        </Accordion.Title>
        <Accordion.Content active={compatibilityCheckOpen}>
          <Form<CompatibilityCheckFormValues>
            defaultValues={{
              models: [],
            }}
            onSubmit={() => {}}
            setFormMethods={(methods) => setFormMethods(methods)}
          >
            <Form.SelectInput
              name="applicationVersionId"
              label="Version"
              placeholder="Select version to install ..."
              noOptionsMessage="No versions found."
              options={appVersions.map((version) => ({
                value: version.id,
                text: version.version,
              }))}
              required
              inputWidths={{
                label: {
                  computer: 3,
                  largeScreen: 3,
                  tablet: 5,
                  mobile: 16,
                },
                input: {
                  computer: 9,
                  largeScreen: 9,
                  tablet: 8,
                  mobile: 16,
                },
              }}
            />
            <ModelsSelectInput orgId={orgId} setModelsList={setModelsList} />
            <div css={tw`mt-4`}>
              <CompatibilityCheckSegment modelsList={modelsList} />
            </div>
          </Form>
        </Accordion.Content>
      </Accordion>
    </>
  );
};
