import { defaultTryDecode } from "./http";

export type PaginatedResponse<T> = {
  data: T;
  links: Record<string, string>;
};

export const DEFAULT_PAGINATION_DATA_LIMIT = 50;

const parseLinkHeader = (header: string) => {
  const links: Record<string, string> = {};
  const parts = header.split(",");

  for (const part of parts) {
    const section = part.split(";");
    if (section.length < 2) {
      continue;
    }

    const urlMatch = section[0].match(/<(.+)>/);
    const relMatch = section[1].match(/rel="(.+)"/);

    if (urlMatch && relMatch) {
      const url = urlMatch[1];
      const rel = relMatch[1];
      links[rel] = url;
    }
  }

  return links;
};

// Helper function to parse the response and generate the PaginatedResponse
export const handlePaginationResponse = async <T>(
  response: Response
): Promise<PaginatedResponse<T>> => {
  const linkHeader = response.headers.get("Link");
  const links = linkHeader ? parseLinkHeader(linkHeader) : {};
  const data = (await defaultTryDecode(response)) as unknown as T;

  return { data, links };
};
