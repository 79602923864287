/** @jsxImportSource @emotion/react */
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Divider } from "semantic-ui-react";
import {
  ClassifierId,
  pointClassifierApi,
  ClassifierSetListItem,
} from "data/Aletheia";
import { OrgId } from "data/Enodia";
import {
  ClassHypernym,
  DCHJsonClassResponse,
  Unit,
  masonApi,
} from "data/Mason";
import { EnodiaOrgContext } from "App";
import OrgContextModal from "context/OrgContextModal";
import {
  UIStatus,
  UIStatusWrapper,
  changeOrgNoResetMessage,
  Page,
  PageTitle,
} from "components/shared";
import { DataPoolSelectInput } from "components/shared/InputFields/Fields/Domain/DataSourceGroupField";
import ClassificationPlanResults from "./ClassificationPlan/ClassificationPlanResults";
import {
  ClassifierSearchMode,
  ClassifierSetMetadata,
  ClassifierSetSearch,
  ClassifierSetSearchState,
  clearedClassifierSet,
  defaultClassifierSet,
} from "./ClassifierSet";
import { useGetStateFromPaths } from "./useGetStateFromParams";
import { usePointClassifierPageState } from "./usePointClassifierPageState";
import ClassifierSetSegment from "./ClassifierSet/ClassifierSetSegment";
import { getShareablePath } from "./PointClassifierUtils";

const UNITS_MAX_LIMIT = 2000;

export function PointClassifier() {
  const navigate = useNavigate();

  const { orgId } = useContext(EnodiaOrgContext);

  const pageState = usePointClassifierPageState();
  const prevUnsavedClassifierSet = useRef<ClassifierSetMetadata>();

  // object/DTO states
  const [classifierSet, setClassifierSet] =
    useState<ClassifierSetMetadata>(defaultClassifierSet);

  const [schemaClassesList, setSchemaClassesList] = useState<
    DCHJsonClassResponse[]
  >([]);
  const [unitsList, setUnitsList] = useState<Unit[]>([]);

  // classifier set states
  const [status, setStatus] = useState<UIStatus>(new UIStatus());
  const [classifierSetSearch, setClassifierSetSearch] =
    useState<ClassifierSetSearchState>({ mode: ClassifierSearchMode.create });
  const [classifierSetOptions, setClassifierSetOptions] = useState<
    ClassifierSetListItem[]
  >([]);
  const [canSave, setCanSave] = useState(false);

  // classification plan states
  const [classifierHighlightIndex, setClassifierHighlightIndex] =
    useState<number>();
  const [showResults, setShowResults] = useState(false); //maybe redundant?
  const [canPurgeClassifiers, setCanPurgeClassifiers] =
    useState<boolean>(false);

  useGetStateFromPaths(
    pageState,
    setClassifierSetSearch,
    setStatus,
    setClassifierSet,
    setShowResults,
    setCanPurgeClassifiers,
  );

  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    pageState.setOrgId(orgId);
    getClassifierSets(orgId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  useEffect(() => {
    if (pageState.state.orgId) {
      navigate(getShareablePath(pageState.state));
    } else {
      pageState.setOrgId(orgId);
      navigate(getShareablePath({ orgId: orgId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState.state]);

  /**
   * INITIALIZATION: ON FIRST LOAD
   */
  // get list of class options
  useEffect(() => {
    masonApi
      .getSchemaTypes({ hypernym: ClassHypernym.Point })
      .then((res) => {
        setSchemaClassesList(res);
      })
      .catch((e) => {
        setStatus((p) => p.setError(e.message));
      });
  }, []);

  // cache UoM options for dropdown selection
  useEffect(() => {
    masonApi
      .getSchemaUnits({ limit: UNITS_MAX_LIMIT })
      .then((res) => setUnitsList(res.units))
      .catch((e) => {
        setStatus((p) => p.setError(e.message));
      });
  }, []);

  /** HANDLERS */
  const handleActiveClassifier = (classifierId?: ClassifierId) => {
    // if in current set, highlight
    classifierSet?.classifiers.forEach((classifier, index) => {
      if (classifier.id === classifierId) setClassifierHighlightIndex(index);
    });
    if (classifierId === undefined) setClassifierHighlightIndex(undefined);
  };

  const getClassifierSets = (classifierSetOrg?: OrgId) => {
    if (classifierSetOrg === undefined || classifierSetOrg === null)
      return new Promise<ClassifierSetListItem[]>(() => []);
    return pointClassifierApi
      .getClassifierSets({
        organisationid: classifierSetOrg,
      })
      .then((res) => {
        setClassifierSetOptions(res);
        return res;
      })
      .catch((e) =>
        setStatus((prev) =>
          prev.setError(e.message ?? "Cannot retrieve classifier sets"),
        ),
      );
  };

  const onOrgChangeConfirm = () => {
    setClassifierHighlightIndex(undefined);
    orgId && setClassifierSet(clearedClassifierSet(orgId));
    setClassifierSetSearch({ mode: classifierSetSearch.mode });
    pageState.setState({
      orgId: orgId,
    });
    getClassifierSets(orgId);
  };

  const showClassifierSet =
    classifierSetSearch.mode === ClassifierSearchMode.create ||
    classifierSetSearch.id;

  return (
    <Page>
      <UIStatusWrapper status={status}>
        <PageTitle primaryHeader="Point Classifier" />
        <OrgContextModal
          open={modalOpen}
          openCondition={true}
          onConfirm={() => onOrgChangeConfirm()}
          modalMessage={changeOrgNoResetMessage}
          setModalState={setModalOpen}
        />
        <ClassifierSetSearch
          classifierSetSearch={classifierSetSearch}
          setClassifierSetSearch={setClassifierSetSearch}
          canSave={canSave}
          setCanSave={setCanSave}
          resetPageState={pageState.clear}
          unsavedClassifierSet={prevUnsavedClassifierSet.current}
          setClassifierSetId={pageState.setClassifierSetId}
          setClassifierSet={setClassifierSet}
          classifierSet={classifierSet}
          setPlanId={pageState.setPlanId}
          setShowResults={setShowResults}
          classifierSetOptions={classifierSetOptions}
          getClassifierSets={getClassifierSets}
          disabled={pageState.state.orgId === undefined}
          setCanPurgeClassifiers={setCanPurgeClassifiers}
          canPurgeClassifiers={canPurgeClassifiers}
        />
        <DataPoolSelectInput
          id={pageState.state.datapoolId}
          setSelectedDataPool={pageState.setSelectedDataPool}
          setShowResults={setShowResults}
          disabled={
            (classifierSetSearch.id === undefined ||
              classifierSetSearch.id === "") &&
            classifierSetSearch.mode === ClassifierSearchMode.search
          }
        />
        {showClassifierSet && (
          <ClassifierSetSegment
            classifierSetMetadata={classifierSet}
            setClassifierSet={setClassifierSet}
            classifierSetSearch={classifierSetSearch}
            setClassifierSetSearch={setClassifierSetSearch}
            canSave={canSave}
            setCanSave={setCanSave}
            pageState={pageState}
            setShowResults={setShowResults}
            classifierHighlightIndex={classifierHighlightIndex}
            schemaClassesList={schemaClassesList}
            unitsList={unitsList}
            setUIStatus={setStatus}
            getClassifierSets={getClassifierSets}
            setCanPurgeClassifiers={setCanPurgeClassifiers}
          />
        )}
      </UIStatusWrapper>
      <Divider />
      {pageState.state.datapoolId &&
        pageState.state.classificationPlanId &&
        pageState.state.classifierSetId &&
        showResults && (
          <ClassificationPlanResults
            setShowResults={setShowResults}
            selectedDataPool={pageState.state.datapoolId}
            handleActiveClassifier={handleActiveClassifier}
            classificationPlanId={pageState.state.classificationPlanId}
            schemaClassesList={schemaClassesList}
            unitsList={unitsList}
          />
        )}
    </Page>
  );
}
