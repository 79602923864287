/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import cronstrue from "cronstrue";
import { FieldValues } from "react-hook-form";
import { Divider, Grid, Icon } from "semantic-ui-react";
import tw from "twin.macro";
import { ApiError } from "data/http";
import { ScheduleRequest, metisApi } from "data/Metis";
import {
  ErrorMessage,
  TextInput,
  UIStatus,
  UIStatusWrapper,
} from "components/shared";
import Form, { FormStateValues } from "components/shared/Forms/ReactHookForm";
import ApplicationScheduleBasicFrequency from "./ApplicationScheduleBasicFrequency";
import ApplicationScheduleCronFrequency from "./ApplicationScheduleCronFrequency";

const DEFAULT_CRON_FREQUENCY = "0 */5 * * * *";

export enum FrequencyTypes {
  cron = "CRON",
  basic = "BASIC",
}

const DEFAULT_SCHEDULE: ScheduleRequest = {
  name: "",
  cron: DEFAULT_CRON_FREQUENCY,
  active: false,
};

export interface EditApplicationScheduleFormFields extends FieldValues {
  name: string;
  humanReadableFrequency: string;
  disabled: boolean;
}

const ApplicationSchedule: React.FC<{
  applicationID: string;
}> = (props) => {
  const [downloadStatus, setDownloadStatus] = useState(new UIStatus());
  const [uploadStatus, setUploadStatus] = useState(new UIStatus());
  const [parseStatus, setParseStatus] = useState(new UIStatus());
  const [isReadOnly, setIsReadOnly] = useState<boolean>(true);

  const [schedule, setSchedule] = useState<ScheduleRequest>(DEFAULT_SCHEDULE);
  const [initialSchedule, setInitialSchedule] = useState<ScheduleRequest>();

  const [selectedFrequency, setSelectedFrequency] = useState<string>(
    FrequencyTypes.cron
  );

  const [formState, setFormState] =
    useState<FormStateValues<EditApplicationScheduleFormFields>>();

  useEffect(() => {
    setDownloadStatus((p) => p.setIndeterminate(true));
    metisApi.getApplicationSchedule(props.applicationID).then((r) => {
      const updatedSchedules = r as unknown as Array<ScheduleRequest>;
      if (updatedSchedules.length > 0) {
        setInitialSchedule(updatedSchedules[0]);
        setSchedule(updatedSchedules[0]);
      } else if (updatedSchedules.length === 0) {
        setSchedule(DEFAULT_SCHEDULE);
      }
      setDownloadStatus((p) => p.setIndeterminate(false));
    });
  }, [props.applicationID]);

  const errorMessages = () => {
    const error = parseStatus.error || uploadStatus.error;
    return error ? (
      <div>
        <Divider />
        <ErrorMessage icon>
          <>
            <Icon name="times" /> {error}
          </>
        </ErrorMessage>
      </div>
    ) : (
      <></>
    );
  };

  const renderScheduleFrequencyEditor = (isReadOnly: boolean) =>
    isReadOnly ? null : (
      <div css={tw`mb-4`}>
        <ApplicationScheduleBasicFrequency
          cronString={schedule.cron}
          setCronString={(newCron: string) =>
            setSchedule({ ...schedule, cron: newCron })
          }
          disabled={selectedFrequency !== FrequencyTypes.basic}
          selectedFrequency={selectedFrequency}
          setSelectedFrequency={setSelectedFrequency}
          setStatus={setParseStatus}
          isReadOnly={isReadOnly}
        />

        <ApplicationScheduleCronFrequency
          cronString={schedule.cron}
          setCronString={(newCron: string) =>
            setSchedule({ ...schedule, cron: newCron })
          }
          disabled={selectedFrequency !== FrequencyTypes.cron}
          selectedFrequency={selectedFrequency}
          setSelectedFrequency={setSelectedFrequency}
          setStatus={setParseStatus}
          isReadOnly={isReadOnly}
        />
        {errorMessages()}
      </div>
    );

  const onSubmit = (data: EditApplicationScheduleFormFields) => {
    if (isReadOnly) {
      setIsReadOnly(false);
    } else {
      setUploadStatus((p) => p.setIndeterminate(true));
      metisApi.deleteApplicationSchedule(props.applicationID).then(
        (_) => {
          metisApi
            .setApplicationSchedule(props.applicationID)({
              name: data.name,
              cron: schedule.cron,
              active: !data.disabled,
            })
            .then(
              () => {
                setUploadStatus((p) => p.setIndeterminate(false));
                setIsReadOnly(true);
              },
              (e: ApiError) => setUploadStatus((p) => p.setError(e.message))
            );
        },
        (e: ApiError) => setUploadStatus((p) => p.setError(e.message))
      );
    }
  };

  return (
    <div data-test-id="applicationSchedule" css={tw`p-2`}>
      <UIStatusWrapper fitted status={downloadStatus}>
        {downloadStatus.indeterminate ? null : (
          <Form<EditApplicationScheduleFormFields>
            setFormState={(state) => setFormState(state)}
            defaultValues={{
              name: schedule.name,
              humanReadableFrequency: cronstrue.toString(schedule.cron),
              disabled: !schedule.active,
            }}
            onSubmit={onSubmit}
          >
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Form.CheckboxInput
                    name="disabled"
                    checkboxLabel="Disabled"
                    disabled={isReadOnly || parseStatus.error !== undefined}
                    toggle
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Form.TextInput
              name="name"
              label="Name"
              isReadOnly={isReadOnly}
              placeholder={"Name of the schedule"}
              required
              verticalLayout
            />
            <TextInput
              value={cronstrue.toString(schedule.cron)}
              label="Frequency"
              isReadOnly={true}
              verticalLayout
            />
            {renderScheduleFrequencyEditor(isReadOnly)}
            <div css={tw`flex justify-end gap-2`}>
              {isReadOnly ? null : (
                <Form.Button
                  submit={false}
                  label="Cancel"
                  onClick={() => {
                    setSchedule(initialSchedule ?? DEFAULT_SCHEDULE);
                    setIsReadOnly(true);
                  }}
                />
              )}

              {isReadOnly ? (
                <Form.Button
                  submit
                  label="Edit schedule"
                  icon="pencil"
                  onClick={() => setIsReadOnly(false)}
                />
              ) : (
                <Form.Button
                  submit
                  label="Save schedule"
                  icon="save"
                  disabled={!formState?.isDirty || !formState.isValid}
                />
              )}
            </div>
          </Form>
        )}
      </UIStatusWrapper>
    </div>
  );
};

export default ApplicationSchedule;
