/** @jsxImportSource @emotion/react */
import { useContext, useEffect, useState } from "react";
import { FieldValues } from "react-hook-form";
import { generatePath } from "react-router-dom";
import { Button, DropdownItemProps, Icon, Image } from "semantic-ui-react";
import tw from "twin.macro";
import { EnodiaOrgContext } from "App";
import { Path } from "Routes";
import {
  ApplicationRecipeDetailResponse,
  ApplicationVersionFullResponse,
  metisApi,
} from "data/Metis";
import { useApplicationCategories } from "data/Metis/useApplicationCategories";
import {
  Form,
  FormMethods,
  FormStateValues,
  PageMode,
  UIStatus,
  UIStatusWrapper,
} from "components/shared";
import {
  getIconByCategory,
  getLatestApplicationVersion,
} from "components/Applications/shared";

interface ApplicationDetailsFormFields extends FieldValues {
  name: string;
  description: string;
  vendorUid: string;
  latestVersion: string;
  author: string;
  categories: string[];
  overview: string;
}

export const ApplicationDetails = ({
  application,
  appVersions,
}: {
  application: ApplicationRecipeDetailResponse;
  appVersions: ApplicationVersionFullResponse[];
}) => {
  const { orgId } = useContext(EnodiaOrgContext);
  const [vendorOptions, setVendorOptions] = useState<DropdownItemProps[]>([]);
  const [pageMode, setPageMode] = useState(PageMode.view);
  const [uiStatus, setUiStatus] = useState(new UIStatus());

  const [formState, setFormState] =
    useState<FormStateValues<ApplicationDetailsFormFields>>();
  const [formMethods, setFormMethods] =
    useState<FormMethods<ApplicationDetailsFormFields>>();

  const { categorySelectOptions } = useApplicationCategories();
  const latestVersion = getLatestApplicationVersion(appVersions);

  useEffect(() => {
    if (orgId)
      metisApi.getVendors({ organisationId: orgId }).then((vendors) => {
        setVendorOptions(
          vendors.map((vendor) => ({ value: vendor.uid, text: vendor.name })),
        );
      });
  }, [orgId]);

  const handleSubmit = (formData: ApplicationDetailsFormFields) => {
    setUiStatus((prev) => prev.setIndeterminate(true));
    metisApi
      .putManageApplicationRecipe(application.id, {
        name: formData.name,
        description: formData.description,
        author: formData.author,
        categoryUids: formData.categories,
        overview: formData.overview,
      })
      .then(
        () => {
          setUiStatus((prev) => prev.setIndeterminate(false));
          setPageMode(PageMode.view);
        },
        (error) => {
          setUiStatus((prev) =>
            prev.setError(
              `An error occured while updating the application details. Please try again later. ${
                error.errorMessage ?? ""
              }`,
            ),
          );
        },
      );
  };

  return (
    <UIStatusWrapper status={uiStatus}>
      <div css={tw`px-2 w-full`}>
        <Form<ApplicationDetailsFormFields>
          setFormState={(state) => setFormState(state)}
          setFormMethods={(methods) => setFormMethods(methods)}
          defaultValues={{
            name: application.name,
            description: application.description,
            vendorUid: application.vendorUid,
            latestVersion: latestVersion?.version,
            author: application.author,
            categories: application.categoryUids,
            overview: application.overview,
          }}
          onSubmit={handleSubmit}
        >
          <Form.TextInput
            required
            verticalLayout
            name="name"
            label="Application Name"
            isReadOnly={pageMode === PageMode.view}
          />
          <Form.TextInput
            required
            verticalLayout
            name="description"
            label="Description"
            isReadOnly={pageMode === PageMode.view}
          />

          {pageMode === PageMode.view && (
            <Form.SelectInput
              required
              verticalLayout
              name="vendorUid"
              label="Vendor"
              options={vendorOptions}
              isReadOnly
              link={() =>
                generatePath(Path.ViewVendorDetails, {
                  vendorUid: application.vendorUid,
                })
              }
            />
          )}
          {pageMode === PageMode.view && (
            <Form.TextInput
              required
              verticalLayout
              name="latestVersion"
              label="Latest Version"
              isReadOnly
            />
          )}
          <Form.TextInput
            required
            verticalLayout
            name="author"
            label="Author"
            isReadOnly={pageMode === PageMode.view}
          />
          <Form.SelectInput
            required
            verticalLayout
            isMulti
            search
            name="categories"
            label="Categories"
            options={categorySelectOptions}
            isReadOnly={pageMode === PageMode.view}
            className="application-category"
            renderLabel={(item) => ({
              content: (
                <div
                  css={tw`flex gap-2 items-center text-sm whitespace-nowrap`}
                >
                  <Image
                    src={getIconByCategory([item.text as string])}
                    alt="application icon"
                    width="16"
                    height="16"
                  />
                  <span>{item.text}</span>
                </div>
              ),
            })}
          />
          <Form.MarkdownInput
            name="overview"
            label="Overview"
            verticalLayout
            isReadOnly={pageMode === PageMode.view}
            readOnlyEmptyText="No overview provided."
          />
          <div css={tw`mt-4 text-right`}>
            {pageMode === PageMode.view ? (
              <Button
                type="button"
                primary
                onClick={() => setPageMode(PageMode.edit)}
              >
                <Icon name="pencil" />
                Edit Application Details
              </Button>
            ) : (
              <>
                <Form.Button
                  submit={false}
                  label="Cancel"
                  onClick={() => {
                    formMethods?.reset();
                    setPageMode(PageMode.view);
                  }}
                />
                <Form.Button
                  submit
                  label="Save Application Details"
                  icon="save"
                  disabled={!formState?.isDirty}
                />
              </>
            )}
          </div>
        </Form>
      </div>
    </UIStatusWrapper>
  );
};
