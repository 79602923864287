/** @jsxImportSource @emotion/react */

import React from "react";
import { differenceInMilliseconds } from "date-fns";
import tw from "twin.macro";
import { UIStatus, UIStatusWrapper } from "components/shared";
import { Chart, ChartData, Datum } from ".";
import { DownloadPostParams, ObservationData } from "data/Chronos/chronosTypes";
import { chronosApi } from "data/Chronos/chronosApi";
import { StreamMinMaxDate } from "data/senaps";

type PointData = {
  id: string;
  uid: string;
  name?: string;
};

type PointChartProps = {
  points?: PointData[];
  mountNodeId?: string;
};

const AGG_RESULTS_LIMIT = 1500;
export const getAggPeriod = (fromDate: Date, toDate: Date) => {
  const diffInMs = Math.abs(differenceInMilliseconds(fromDate, toDate));

  return Math.ceil(diffInMs / AGG_RESULTS_LIMIT) || 1;
};

const lookupPointName = (points: PointData[], pointId: string) => {
  const point = points.find((p) => p.uid === pointId);
  return point?.id || pointId;
};

const handleNumericData = (
  pointName: string,
  pointData: ObservationData[]
) => ({
  name: pointName,
  data: pointData.map(
    (d: ObservationData) => ({ x: new Date(d.t), y: d.n } as Datum<number>)
  ),
});

const handleVectorData = (pointName: string, pointData: ObservationData[]) =>
  // DCH-4585: Assume vectors are always the same length
  Array(pointData[0].v!!.length)
    .fill(0)
    .map((_, idx) => ({
      name: `${pointName} (${idx.toString()})`,
      data: pointData.map(
        (d: ObservationData) =>
          ({ x: new Date(d.t), y: d.v!![idx] } as Datum<number>)
      ),
    }));

export const getDataForRange =
  (
    points: PointData[],
    params: DownloadPostParams,
    setUIStatus?: React.Dispatch<React.SetStateAction<UIStatus>>
  ) =>
  (start: Date) =>
  (end: Date): Promise<ChartData<number>> => {
    if (start === undefined && end === undefined) {
      return Promise.resolve([]);
    }
    return chronosApi
      .postDownload({
        ...params,
        start: start?.toISOString(),
        end: end?.toISOString(),
      })({
        points: points.map((p) => p.uid),
      })
      .then(
        (r) =>
          r === null
            ? []
            : Object.entries(r?.metadata.points)
                .map(([pointEnum, pointId]) => {
                  const pointData = r.data.filter(
                    (d: ObservationData) => d.p === pointEnum
                  );
                  const pointName = lookupPointName(points, pointId);
                  if (
                    pointData.some((d: ObservationData) => d.n !== undefined)
                  ) {
                    return handleNumericData(pointName, pointData);
                  } else if (
                    pointData.some((d: ObservationData) => d.v !== undefined)
                  ) {
                    return handleVectorData(pointName, pointData);
                  } else {
                    return [];
                  }
                })
                .flatMap((d) => d),
        (error) => {
          setUIStatus &&
            setUIStatus((prevStatus) => prevStatus.setError(error.message));
          throw new Error(error);
        }
      );
  };

/**
 * "streamIds" will trigger backend requests to get streams.
 * If streams' data are already retrieved, use "streams" instead.
 *
 * Limitation: when using this component in a semantic-ui Modal, do not use `dimmer="blurring"`
 * since it will blur the calendar popup. The calendar popup (created by DateTimeInput from https://www.npmjs.com/package/semantic-ui-calendar-react)
 * is placed inside a div tag with no classes to target custom CSS rules and is outside our control.
 *
 * @param props
 * @constructor
 */
export const PointChart: React.FunctionComponent<PointChartProps> = ({
  points = [],
  mountNodeId,
}) => {
  const now = Date.now();
  const [status, setStatus] = React.useState<UIStatus>(new UIStatus());
  const [dateRange] = React.useState<StreamMinMaxDate>({
    min: new Date(now - 1000 * 60 * 60 * 24 * 365),
    max: new Date(now),
  });
  const [pointData, setPointData] = React.useState<PointData[]>([]);

  React.useEffect(() => {
    // DCH-4585: Only update pointData if points have actually changed.
    // Can't just compare the arrays as this will compare the objects themselves
    if (!pointData.every((element, index) => element === points[index])) {
      setPointData(points);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [points]);

  const params: DownloadPostParams = {
    limit: AGG_RESULTS_LIMIT,
  };

  const memoizedChart = React.useMemo(
    () => {
      const divId = points.reduce((sid, a) => `${a?.uid}_${sid}`, "chart");

      return (
        <Chart
          id={divId}
          css={tw`h-400`}
          getDataForRange={getDataForRange(points, params, setStatus)}
          min={dateRange?.min}
          max={dateRange?.max}
          mountNodeId={mountNodeId}
        />
      );
    },
    // to avoid unnecessary reload when comparing raw arrays
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dateRange, pointData]
  );

  return (
    <UIStatusWrapper
      status={status}
      errorRenderer={(text) =>
        text
          .split("\n")
          .map((s: string, i: number) => <p key={`error-${i}`}>{s}</p>)
      }
      clearable={false}
    >
      {memoizedChart}
    </UIStatusWrapper>
  );
};
