/** @jsxImportSource @emotion/react */
import React, { useContext, useState } from "react";
import { FieldValues } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Divider, Grid } from "semantic-ui-react";
import { EnodiaOrgContext } from "App";
import { installedApplicationViewPath } from "Routes";
import { ApiError } from "data/http";
import { ApplicationVersionBriefResponse, metisApi } from "data/Metis";
import { SiteBuildingReference } from "data/QueryApi/queryTypes";
import {
  DchModal,
  ModelsSelectInput,
  UIStatus,
  UIStatusWrapper,
} from "components/shared";
import {
  Form,
  FormMethods,
  FormStateValues,
} from "components/shared/Forms/ReactHookForm";
import { SEARCH_OPTION_INPUT_WIDTHS } from "components/Search/SearchParams/Common";
import { CompatibilityCheckSegment } from "./CompatibilityCheckSegment";
import {
  filterModelsListBySelectedModels,
  mkFormValuesToInstallRequest,
} from "./InstallApplicationModalHelpers";

export interface FormValues extends FieldValues {
  models: string[];
  name?: string;
  applicationVersionId: string;
  includeAllSites: boolean;
  includeAllBuildings: boolean;
}

export type InstallApplicationModalProps = {
  appName: string;
  appVersions: ApplicationVersionBriefResponse[];
  open: boolean;
  onClose: () => void;
};

export const InstallApplicationModal = ({
  appName,
  appVersions,
  open,
  onClose,
}: InstallApplicationModalProps) => {
  const navigate = useNavigate();
  const { orgId } = useContext(EnodiaOrgContext);
  // Form state and methods
  const [formState, setFormState] = useState<FormStateValues<FormValues>>();
  const [formMethods, setFormMethods] = useState<FormMethods<FormValues>>();

  const [modelsList, setModelsList] = useState<SiteBuildingReference[]>([]);
  const [installStatus, setInstallStatus] = useState(new UIStatus());

  // Compatibility Segment
  const [isCompatible, setIsCompatible] = useState(false);

  const autoGenerateName = () => {
    const modelsFormValue =
      (formMethods && formMethods.getValues("models")) ?? [];
    let modelString = "";
    // if there are existing models selected, render them differently.
    if (modelsFormValue.length > 0) {
      const models: SiteBuildingReference[] = filterModelsListBySelectedModels(
        modelsFormValue,
        modelsList
      );

      modelString =
        models.length > 3
          ? `: models of ${orgId}`
          : `: ${models.map((x) => x.buildingId ?? x.siteId).join(", ")}`;
    }
    const autoGeneratedName = `${appName} ${modelString}`;
    formMethods?.setValue("name", autoGeneratedName);
    formMethods?.trigger();
  };

  const handleSubmit = (formValues: FormValues) => {
    setInstallStatus((prevState) => prevState.setIndeterminate(true));
    if (orgId) {
      const installRequest = mkFormValuesToInstallRequest(
        formValues,
        modelsList
      );
      metisApi.postInstallApplication(installRequest).then(
        (installedApplication) => {
          navigate(installedApplicationViewPath(installedApplication.id));
        },
        (e: ApiError) => {
          setInstallStatus((prevState) =>
            prevState.setError(
              e.message || "An error occurred when installing the application"
            )
          );
        }
      );
    }
  };

  return (
    <DchModal
      header={
        <>
          <h1>Install Application</h1>
          <h5>
            Select version and the site and/or buildings that the application
            will be used for.
          </h5>
        </>
      }
      content={
        <Form
          formId="InstallApplicationForm"
          onSubmit={(data: FormValues) => handleSubmit(data)}
          setFormState={(state) => setFormState(state)}
          setFormMethods={(methods) => setFormMethods(methods)}
          defaultValues={{
            models: [],
            applicationVersionId: appVersions[0]?.id,
          }}
        >
          <UIStatusWrapper status={installStatus}>
            <Grid>
              <Grid.Row verticalAlign="middle">
                <Grid.Column mobile={12} tablet={12} computer={12}>
                  <Form.TextInput
                    required
                    name="name"
                    label="Name"
                    inputWidths={SEARCH_OPTION_INPUT_WIDTHS}
                  />
                </Grid.Column>
                <Grid.Column mobile={4} tablet={4} computer={4}>
                  <Form.Button
                    label="Auto-Generate Name"
                    submit={false}
                    onClick={() => {
                      autoGenerateName();
                    }}
                  />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={3} computer={3}></Grid.Column>
              </Grid.Row>
              <Grid.Row verticalAlign="middle">
                <Grid.Column mobile={16} tablet={12} computer={12}>
                  <Form.SelectInput
                    name="applicationVersionId"
                    label="Version"
                    placeholder="Select version to install ..."
                    noOptionsMessage="No versions found."
                    options={appVersions.map((version) => ({
                      value: version.id,
                      text: version.version,
                    }))}
                    required
                    inputWidths={SEARCH_OPTION_INPUT_WIDTHS}
                  />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={4} computer={4}></Grid.Column>
              </Grid.Row>
            </Grid>
            <ModelsSelectInput
              orgId={orgId}
              setModelsList={setModelsList}
              setError={(e) => {
                setInstallStatus((p) => p.setError(e));
              }}
            />
            <Divider />
            <CompatibilityCheckSegment
              modelsList={modelsList}
              setIsCompatible={setIsCompatible}
            />
          </UIStatusWrapper>
        </Form>
      }
      size="large"
      open={open}
      onClose={onClose}
      confirmLoading={installStatus.indeterminate}
      submitFormId="InstallApplicationForm"
      confirmText="Install"
      disableConfirm={!orgId || !formState?.isValid || !isCompatible}
    />
  );
};
