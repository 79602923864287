import React from "react";
import { Divider, List } from "semantic-ui-react";
import tw from "twin.macro";
import { mkUrl } from "data/http";
import {
  aletheiaEnv,
  chronosEnv,
  iamEnodiaEnv,
  masonEnv,
  metisEnv,
} from "reducers/env";
import {
  DOCUMENTATION_URL,
  externalLinkTo,
  Page,
  PageTitle,
} from "components/shared";

const Documentation = () => {
  return (
    <Page>
      <PageTitle primaryHeader="Documentation" subHeader="Guide - Gitbook" />
      {externalLinkTo(DOCUMENTATION_URL, "Open User Guide")}
      <Divider />
      <PageTitle subHeader="DCH Middleware API" />
      <List>
        <List.Item css={tw`mx-4`}>
          {externalLinkTo(
            mkUrl(masonEnv, "/swagger#/", null),
            "Open Mason API Docs"
          )}
        </List.Item>
        <List.Item css={tw`mx-4`}>
          {externalLinkTo(
            mkUrl(aletheiaEnv, "/swagger#/", null),
            "Open Aletheia API Docs"
          )}
        </List.Item>
        <List.Item css={tw`mx-4`}>
          {externalLinkTo(
            mkUrl(iamEnodiaEnv, "/swagger#/", null),
            "Open Enodia API Docs"
          )}
        </List.Item>
        <List.Item css={tw`mx-4`}>
          {externalLinkTo(
            mkUrl(chronosEnv, "/swagger#/", null),
            "Open Chronos API Docs"
          )}
        </List.Item>
        <List.Item css={tw`mx-4`}>
          {externalLinkTo(
            mkUrl(metisEnv, "/swagger#/", null),
            "Open Metis API Docs"
          )}
        </List.Item>
      </List>
    </Page>
  );
};
export default Documentation;
