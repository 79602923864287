/** @jsxImportSource @emotion/react */

import React, { useState } from "react";
import { saveAs } from "file-saver";
import { Button } from "semantic-ui-react";
import tw from "twin.macro";
import { JobStatusDTO, modelGenerationApi } from "data/modelGenerationApi";
import { Page, PageTitle, UIStatus, UIStatusWrapper } from "components/shared";
import ProcessingStatusLabel, {
  isFailureStatus,
  isSuccessStatus,
  isWaitingStatus,
} from "components/shared/Components/ProcessingStatusLabel";
import Form, { FormStateValues } from "components/shared/Forms/ReactHookForm";
import { FieldValues } from "react-hook-form";

const errorMessage = (message: string) => {
  return (
    <div
      css={tw`border border-red-600 border-solid bg-red-900 p-1 rounded w-80
      my-2 h-full break-words whitespace-pre-line whitespace-pre-wrap`}
    >
      {message}
    </div>
  );
};

interface ModelFile extends FieldValues {
  file: File;
}

const ModelManagement = () => {
  const POLLING_RATE = 1500;

  const [jobState, setJobState] = useState<JobStatusDTO | null>();
  const [fileName, setFileName] = useState("");
  const [status, setStatus] = useState(new UIStatus());
  const [error, setError] = useState("");
  const [activePoll, setActivePoll] = useState(false);

  const [formState, setFormState] = useState<FormStateValues<ModelFile>>();

  const checkApi = () => {
    setStatus((p) => p.setIndeterminate(true));
    modelGenerationApi
      .getRootAPI()
      .then(() => setStatus((p) => p.setIndeterminate(false)))
      .catch((e) => {
        setStatus((prevState) => prevState.setError(e.message));
      });
  };

  const clearJob = () => {
    setFileName("");
    setError("");
    setJobState(null);
    setActivePoll(false);
  };

  // on load, check if Api works
  React.useEffect(() => {
    checkApi();
  }, []);

  //capture cases where the api continues to run after server error
  React.useEffect(() => {
    if (!jobState && error) {
      setStatus((prevState) => prevState.setError(error));
    }
  }, [error, jobState]);

  const onSubmit = async (values: ModelFile) => {
    if (values.file) {
      setFileName(values.file.name);
      const formData = new FormData();
      formData.append("zip_file", values.file);

      modelGenerationApi
        .postJob(formData)
        .then((r: JobStatusDTO) => {
          setJobState(r);
        })
        .then(() => setActivePoll(true))
        .catch((e) => setError(e.message));
    }
  };

  // download the processed model once done
  const downloadModel = React.useCallback(() => {
    jobState &&
      modelGenerationApi
        .getModelGenPdfBlob(jobState.id)
        .then((blob) => saveAs(blob as Blob, `generated-${fileName}`))
        .catch((e) => {
          setError(e.message);
        });
  }, [fileName, jobState]);

  // use as callback when polling
  const checkStatus = React.useCallback(() => {
    jobState &&
      modelGenerationApi
        .getJobStatus(jobState.id)
        .then((res) => {
          if (jobState.id === res.id && res.state !== jobState.state)
            setJobState(res);
          if (!isWaitingStatus(res.state)) {
            downloadModel();
            setActivePoll(false);
          }
        })
        .catch((e) => setError(e.message));
  }, [downloadModel, jobState]);

  React.useEffect(() => {
    if (activePoll) {
      const statusPoll = setInterval(checkStatus, POLLING_RATE);
      return () => clearInterval(statusPoll);
    }
    return;
    // only want to check if polling state changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePoll]);

  React.useEffect(() => {
    error && setActivePoll(false);
  }, [error]);

  const cancelJob = () => {
    jobState &&
      modelGenerationApi
        .cancelJob(jobState.id)
        .then(() => {
          clearJob();
        })
        .catch((e) => setError(e.message));
  };

  const renderForm = () => {
    return (
      <Form onSubmit={onSubmit} setFormState={(state) => setFormState(state)}>
        Upload a .zip file containing the following:
        <ul>
          <li>Manifest to describe the files (as manifest.json)</li>
          <li>Folder named "input_csvs": containing CSV input files</li>
          <li>
            (Optional): Folder named "input_ttls": containing existing site
            and/or building model(s) in Turtle format (with .ttl extension)
          </li>
        </ul>
        <Form.FileInput name={"file"} required accept=".zip" dragAndDrop />
        <Button primary disabled={!formState?.isValid} type="submit">
          Generate Model
        </Button>
      </Form>
    );
  };

  const renderResults = () => {
    let state = jobState?.state;
    if (state)
      switch (true) {
        case isWaitingStatus(state):
          return (
            <span>
              <Button secondary onClick={cancelJob} content="Cancel" />
            </span>
          );
        case isSuccessStatus(state):
          return (
            <div css={tw`py-1`}>
              File didn't automatically download?&nbsp;
              <Button
                primary
                icon="download"
                content="Download"
                onClick={downloadModel}
              />
            </div>
          );
        case isFailureStatus(state):
          return errorMessage(error);
      }
    return <div></div>;
  };

  return (
    <Page>
      <PageTitle
        primaryHeader="Model Management"
        subHeader="Automatically Generate DCH Compatible Models"
      />
      <UIStatusWrapper status={status}>
        {(!jobState || (jobState && !isWaitingStatus(jobState.state))) &&
          renderForm()}
        {jobState && <ProcessingStatusLabel status={jobState.state} />}
        {renderResults()}
        {!jobState && error && errorMessage(error)}
      </UIStatusWrapper>
    </Page>
  );
};
export default ModelManagement;
