import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { ApiError } from "data/http";
import { ClassHypernym, DCHJsonClassResponse, masonApi } from "data/Mason";
import Form from "components/shared/Forms/ReactHookForm";
import { LabelValue, SEARCH_OPTION_INPUT_WIDTHS } from "./Common";
import { SearchParamsValues } from "./SearchForm";
import { SearchOption } from "./SearchOption";
import { IncludeSubclassesCheckbox } from "./IncludeSubclassesCheckbox";

const EQUIPMENT_FIELD_NAME: keyof SearchParamsValues = "equipment";
const SUBCLASSES_FIELD_NAME: keyof SearchParamsValues =
  "includeEquipmentSubclasses";

export const EquipmentSelectInput = ({
  setError,
}: {
  setError: (_: string) => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [equipmentOptions, setEquipmentOptions] = useState<
    Array<LabelValue<DCHJsonClassResponse>>
  >([]);

  const { setValue, watch } = useFormContext();
  const currentlySelectedEquipment: string[] = watch(EQUIPMENT_FIELD_NAME);

  //on load, fetch equipment
  useEffect(() => {
    const fetchEquipment = async () => {
      setIsLoading(true);
      masonApi
        .getSchemaTypes({ hypernym: ClassHypernym.Equipment })
        .then(
          (res) => {
            setEquipmentOptions(
              res
                .map((a: DCHJsonClassResponse) => ({
                  value: a,
                  label: a.label,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
            );
          },
          (e: ApiError) =>
            setError(
              e.message || `Could not fetch ${ClassHypernym.Equipment} types`,
            ),
        )
        .finally(() => setIsLoading(false));
    };
    fetchEquipment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const input = (
    <Form.SelectInput
      name={EQUIPMENT_FIELD_NAME}
      label="Restrict to these types of equipment"
      placeholder="Select equipment types ..."
      noOptionsMessage="No equipment types found."
      options={equipmentOptions.map((o) => ({
        value: o.value.type,
        text: o.label,
      }))}
      isMulti
      isLoading={isLoading}
      search
      inputWidths={SEARCH_OPTION_INPUT_WIDTHS}
    />
  );

  return (
    <SearchOption
      input={input}
      rightCheckboxes={[
        <IncludeSubclassesCheckbox
          key={SUBCLASSES_FIELD_NAME}
          fieldName={SUBCLASSES_FIELD_NAME}
          isLoading={isLoading}
          currentlySelectedClasses={currentlySelectedEquipment}
          options={equipmentOptions}
          updateValues={(subclasses: string[]) => {
            setValue(
              EQUIPMENT_FIELD_NAME,
              Array.from(
                new Set(currentlySelectedEquipment.concat(subclasses)),
              ),
            );
          }}
        />,
      ]}
    />
  );
};
