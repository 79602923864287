import * as React from "react";

type HelpTextProps = {
  className?: string;
  children?: React.ReactNode;
};

export const HelpText: React.FC<HelpTextProps> = (props) => (
  <div
    className={"dch-help-text ".concat(props.className ? props.className : "")}
  >
    <div className="dch-help-text--icon">
      <span className="dch-help-text--qmark">?</span>
    </div>
    <div className="dch-help-text--content">{props.children}</div>
  </div>
);
