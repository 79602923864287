/** @jsxImportSource @emotion/react */

import React, { useEffect, useMemo, useState } from "react";
import { Popup } from "semantic-ui-react";
import tw from "twin.macro";
import { useFilePicker } from "use-file-picker";
import { saveToFile } from "data/utils";
import { Form } from "components/shared";
import { ConfigType } from "./ApplicationConfigurationConstants";

type ConfigActionProps = {
  initialTextState?: string;
  validInput: boolean;
  activeDoc: ConfigType;
  updatePlainText: (text?: string) => void;
  showRaw: boolean;
  setShowRaw: React.Dispatch<React.SetStateAction<boolean>>;
};
export const ApplicationConfigurationActionBar = ({
  initialTextState,
  validInput,
  activeDoc,
  updatePlainText,
  showRaw,
  setShowRaw,
}: ConfigActionProps) => {
  const documentId = activeDoc.metadata.documentId;
  const plaintext = activeDoc.plaintext;

  /**
   * STATES
   */
  const [dragActive, setDragActive] = useState<boolean>(false);
  const { openFilePicker, filesContent } = useFilePicker({
    multiple: false,
  });

  /**
   * USE EFFECTS
   */
  // update plain text on new file upload
  useEffect(() => {
    const fileContent = filesContent.pop();
    if (fileContent) {
      updatePlainText(fileContent.content);
    }
  }, [filesContent, updatePlainText]);

  const renderDownloadButton = () => {
    return (
      <Form.Button
        submit={false}
        label="Download to file"
        icon="download"
        onClick={() => {
          if (plaintext !== undefined) {
            const typeString = activeDoc.metadata.type?.toLowerCase() || "txt";
            const filename = documentId || "document";
            saveToFile(
              plaintext,
              `${filename}.${typeString}`,
              `text/${
                activeDoc.metadata.type === "txt"
                  ? "plaintext"
                  : activeDoc.metadata.type
              }`,
            );
          }
        }}
        css={tw`inline-block`}
      />
    );
  };

  useEffect(() => {
    const fileContent = filesContent.pop();
    if (fileContent) {
      updatePlainText(fileContent.content);
    }
  }, [filesContent, updatePlainText]);

  const renderUploadButton = () => {
    const processDrop = (event: React.DragEvent) => {
      let items = event.dataTransfer.items;
      for (const element of items) {
        // Read first compatible
        if (element.kind === "file") {
          // If we've found a file, read the text and store in textArea
          element
            .getAsFile()
            ?.text()
            .then((response) => {
              updatePlainText(response);
            });
          // Skip the rest of the files if we've already found one
          break;
        }
      }
    };

    return (
      <div
        css={tw`m-0 p-0 z-10`}
        onDrop={(e: React.DragEvent) => {
          e.preventDefault();
          setDragActive(false);
          processDrop(e);
        }}
        onDragOver={(e: React.DragEvent) => {
          e.preventDefault();
          setDragActive(true);
        }}
        onDragExit={(e: React.DragEvent) => {
          e.preventDefault();
          setDragActive(false);
        }}
      >
        <Form.Button
          submit={false}
          label={dragActive ? "Drop to upload" : "Upload from file"}
          icon="upload"
          onClick={() => {
            openFilePicker();
          }}
          css={tw`inline-block`}
        />
      </div>
    );
  };

  const renderShowRawButton = () => {
    return activeDoc.schema !== undefined ? (
      <Popup
        content="Your input is not in JSON format. Please amend error or reset form."
        popper={<div style={{ filter: "none" }}></div>}
        position="top center"
        inverted
        wide
        disabled={validInput}
        on={["hover", "click"]}
        trigger={
          <span>
            <Form.Button
              submit={false}
              icon={showRaw ? "file alternate" : "file code"}
              data-tip
              data-for="json-warning"
              data-type="light"
              label={`Toggle ${showRaw ? "Form" : "Raw"}`}
              onClick={() => validInput && setShowRaw(!showRaw)}
              disabled={!validInput}
            />
          </span>
        }
      />
    ) : null;
  };
  const renderResetButton = useMemo(
    () => (
      <Form.Button
        submit={false}
        icon="refresh"
        label="Reset"
        disabled={initialTextState === activeDoc.plaintext}
        onClick={() => {
          updatePlainText(initialTextState);
        }}
      />
    ),
    [activeDoc.plaintext, initialTextState, updatePlainText],
  );

  return (
    <div css={tw`flex flex-row`}>
      {renderDownloadButton()}
      {renderUploadButton()}
      {renderShowRawButton()}
      {renderResetButton}
    </div>
  );
};
