/** @jsxImportSource @emotion/react */

import { useCallback, useContext, useEffect, useState } from "react";
import {
  CheckboxInput,
  DchModal,
  UIStatus,
  UIStatusWrapper,
} from "components/shared";
import {
  AcceptAgreementRequest,
  AgreementId,
  OrgId,
  RequiredAgreement,
  enodiaApi,
} from "data/Enodia";
import { Link, useNavigate } from "react-router-dom";
import tw from "twin.macro";
import { AuthContext } from "App";
import { getUserPendingAgreements, setUserPendingAgreements } from "data/auth";

const PreLoginAgreementsModal: React.FC<{}> = () => {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  const [showTermsModal, setShowTermsModal] = useState<boolean>(false);
  const [modalStatus, setModalStatus] = useState<UIStatus>(new UIStatus());

  const [agreements, setAgreements] = useState<RequiredAgreement[]>();
  const [termsAgreed, setTermsAgreed] = useState<
    Map<string, AcceptAgreementRequest>
  >(new Map());

  const totalAgreements =
    agreements?.reduce(
      (total, agreement) =>
        (total =
          total +
          (agreement.forOrganisations ? agreement.forOrganisations.length : 1)),
      0
    ) ?? 0;

  /**
   * @description control the terms of service modal open condition on redirect and with valid email given from Auth0
   */
  useEffect(
    () => {
      const email = authContext.state?.userInfo?.email;
      if (authContext.state?.userInfo?.email) {
        const storedUserAgreements = getUserPendingAgreements();
        if (
          storedUserAgreements &&
          storedUserAgreements?.email === email &&
          storedUserAgreements.requiredAgreementsLength === 0
        ) {
          handlePostLogin();
        } else {
          fetchCurrentUser();
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authContext.state]
  );

  /**
   * ENODIA API CALLBACKS
   */
  const handlePostLogin = useCallback(() => {
    const email = authContext.state?.userInfo?.email;
    if (email) {
      if (agreements) {
        setUserPendingAgreements(email, totalAgreements);
      } else {
        setUserPendingAgreements(email, 0);
      }
    }
    navigate("/home");
  }, [
    agreements,
    authContext.state?.userInfo?.email,
    navigate,
    totalAgreements,
  ]);

  const fetchCurrentUser = useCallback(() => {
    enodiaApi.getMe({ withAgreements: true }).then((res) => {
      if (res.requiredAgreements) {
        if (res.requiredAgreements.length === 0) handlePostLogin();
        else {
          setAgreements(res.requiredAgreements);
          setShowTermsModal(true);
        }
      } else {
        handlePostLogin();
      }
    });
  }, [handlePostLogin]);

  /**
   * ON CLICK CHANGE HANDLERS
   */
  const handleAgreeAndSignIn = () => {
    enodiaApi
      .postAcceptAgreements(Array.from(termsAgreed.values()))
      .then(() => {
        handlePostLogin();
      })
      .catch((e) => setModalStatus((p) => p.setError(e.message)));
  };

  const handleRejectTerms = () => {
    setShowTermsModal(false);
    setTermsAgreed(new Map());
    authContext.logout();
    setModalStatus((prevState) =>
      prevState.setError(
        "Please accept the Terms of Service to sign in to Data Clearing House"
      )
    );
  };

  /**
   * ON CHECKBOX CHANGE HANDLERS
   */
  const handleOnChange = (id: AgreementId, orgId?: OrgId) => {
    const updatedCheckedState = new Map(termsAgreed);
    // key will be appended with its child, such that entry is unique
    const key = orgId ? `${id}_${orgId}` : `${id}`;
    if (updatedCheckedState.get(key)) {
      updatedCheckedState.delete(key);
    } else {
      updatedCheckedState.set(key, {
        agreementId: id,
        forOrganisation: orgId,
      });
    }
    setTermsAgreed(updatedCheckedState);
  };

  return (
    <DchModal
      header="Terms of Service"
      content={
        <UIStatusWrapper status={modalStatus}>
          <div className="design-system-2">
            <p>
              By signing in to Data Clearing House you acknowledge that you have
              read and agree to adhere with the following Terms of Service:
            </p>
            {agreements &&
              agreements.map((agreement) => {
                return (
                  <div
                    css={tw`mt-2`}
                    key={`${agreement.agreementId}-checkbox-container`}
                  >
                    {agreement.forOrganisations ? (
                      agreement.forOrganisations.map((org) => (
                        <CheckboxInput
                          dataTestId="required-checkbox"
                          key={`${agreement.agreementId}_${org.uid}_checkbox`}
                          checkboxLabel={
                            <>
                              {org.name}:{" "}
                              <Link to={agreement.href} target="_blank">
                                {agreement.title}
                              </Link>
                              <span css={tw`text-red-error whitespace-nowrap`}>
                                {" "}
                                *
                              </span>
                            </>
                          }
                          value={
                            !!termsAgreed.get(
                              `${agreement.agreementId}_${org.id}`
                            )
                          }
                          onChange={() =>
                            handleOnChange(agreement.agreementId, org.id)
                          }
                        />
                      ))
                    ) : (
                      <CheckboxInput
                        dataTestId="required-checkbox"
                        key={`${agreement.agreementId}_checkbox`}
                        checkboxLabel={
                          <>
                            <Link to={agreement.href} target="_blank">
                              {agreement.title}
                            </Link>
                            <span css={tw`text-red-error whitespace-nowrap`}>
                              {" "}
                              *
                            </span>
                          </>
                        }
                        value={!!termsAgreed.get(agreement.agreementId)}
                        onChange={() =>
                          handleOnChange(agreement.agreementId, undefined)
                        }
                      />
                    )}
                  </div>
                );
              })}
          </div>
        </UIStatusWrapper>
      }
      open={showTermsModal}
      onClose={() => handleRejectTerms()}
      onCancel={() => handleRejectTerms()}
      onConfirm={() => {
        handleAgreeAndSignIn();
      }}
      confirmText={"Agree & Sign In"}
      disableConfirm={termsAgreed.size !== totalAgreements}
    />
  );
};

export default PreLoginAgreementsModal;
