import { Graph } from "@antv/g6";
import { ModelReference, NodeUriType } from "data/QueryApi/queryTypes";
import { SelectedNode } from "../Visualisation";

export const addEventListeners = (
  graph: Graph,
  setSelectedNode: (_?: SelectedNode) => void
) => {
  graph.on("node:mouseenter", (e) => {
    const nodeItem = e.item; // Get the target item
    nodeItem && graph.setItemState(nodeItem, "hover", true); // Set the state 'hover' of the item to be true
  });

  graph.on("node:mouseleave", (e) => {
    const nodeItem = e.item; // Get the target item
    nodeItem && graph.setItemState(nodeItem, "hover", false); // Set the state 'hover' of the item to be false
  });

  graph.on("node:click", (e) => {
    const nodeItem = e.item; // get the clicked item
    const modelReference = nodeItem?.getModel()
      .modelReference as ModelReference;
    if (modelReference === undefined) {
      console.log("The clicked node did not have model reference");
      return;
    }
    //graph?.focusItem(nodeItem); //add this back in if we want to center on the clicked node
    setSelectedNode(
      nodeItem
        ? {
            id: nodeItem.getID() as NodeUriType,
            action: "update",
            modelReference,
          }
        : undefined
    );
  });

  graph.on("canvas:click", (e) => setSelectedNode(undefined));
};
