import React from "react";
import { ApplicationVersionBriefResponse } from "data/Metis";
import { MarkdownInput } from "components/shared";
import { getLatestApplicationVersion } from "components/Applications/shared";

export const ApplicationConfiguration = ({
  appVersions,
}: {
  appVersions: ApplicationVersionBriefResponse[];
}) => {
  const latestVersion = getLatestApplicationVersion(appVersions);
  return (
    <MarkdownInput
      value={
        latestVersion?.configurationInstructions &&
        latestVersion.configurationInstructions !== ""
          ? latestVersion.configurationInstructions
          : "No configuration instructions provided."
      }
      isReadOnly
    />
  );
};
