/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Divider, List } from "semantic-ui-react";
import tw from "twin.macro";
import { useEntityClasses } from "data/EntityClasses/useEntityClasses";
import { ClassHypernym } from "data/Mason";
import { useEntityProperties } from "data/Mason/EntityProperties";
import {
  EMPTY_LIST_MSG,
  LabelledInput,
  LabelledInputLayoutProps,
  UIStatus,
  UIStatusWrapper,
} from "components/shared";
import Form from "components/shared/Forms/ReactHookForm";
import { CommonInputProps } from "../Form/EntityForms/CommonInputs";
import { useCustomFieldArray } from "../Form/useCustomFieldArray";
import { EntityProperty, EntityPropertyInput } from "./EntityProperty";

export type EntityPropertiesProps = {
  hypernym?: ClassHypernym;
  parentClass?: string;
} & CommonInputProps &
  LabelledInputLayoutProps;

export const EntityProperties = (props: EntityPropertiesProps) => {
  const { formFieldName, index, verticalLayout, isReadOnly } = props;
  const indexIdentifier = index !== undefined ? `.${index}` : "";

  const propertiesFields = useCustomFieldArray(
    `${formFieldName}${indexIdentifier}.properties`
  );
  const { getValues } = useFormContext();
  const entityProperties: EntityProperty[] | undefined = getValues(
    `${formFieldName}${indexIdentifier}.properties`
  );

  const [uiStatus, setUIStatus] = useState(new UIStatus());
  const {
    fetchEntityPropertiesList,
    fetchingEPDefinitions,
    entityPropertyDefinitions,
    fetchEntityPropertyDefinitions,
  } = useEntityProperties();
  const { entityClasses } = useEntityClasses();

  //onload, trigger fetch of the entity properties list if not already done
  useEffect(() => fetchEntityPropertiesList(), [fetchEntityPropertiesList]);

  //fetch the definitions for the selected entity properties if we don't already have them in context
  //definitions are stored as schemaDefinition format (e.g. brick: netArea)
  useEffect(() => {
    if (entityProperties) {
      const unFetchedDefinitions =
        entityProperties
          ?.map((p) => p.schemaDefinition)
          .filter(
            (def) =>
              def &&
              def !== "" &&
              !entityPropertyDefinitions.get(def) &&
              !fetchingEPDefinitions.includes(def)
          ) ?? [];
      if (unFetchedDefinitions.length > 0)
        fetchEntityPropertyDefinitions(unFetchedDefinitions);
    }
  }, [
    entityProperties,
    entityPropertyDefinitions,
    fetchEntityPropertyDefinitions,
    fetchingEPDefinitions,
  ]);

  useEffect(() => {
    if (
      fetchingEPDefinitions.filter((def) =>
        entityProperties?.map((p) => p.schemaDefinition).includes(def)
      ).length > 0
    )
      setUIStatus((prev) => prev.setIndeterminate(true));
    else setUIStatus((prev) => prev.setIndeterminate(false));
  }, [entityProperties, fetchingEPDefinitions]);

  const addNewProperty = () => {
    propertiesFields.append({
      entityPropertyId: "",
      schemaDefinition: "",
    });
  };

  const deleteProperty = (deleteIndex: number) => {
    propertiesFields.remove(deleteIndex);
  };

  const input = (
    <UIStatusWrapper status={uiStatus}>
      {propertiesFields.fields.length === 0 && isReadOnly ? (
        <em>{EMPTY_LIST_MSG}</em>
      ) : (
        <List
          key={`${formFieldName}${indexIdentifier}.properties`}
          css={[
            tw`rounded bg-core-grey-dark mr-0`,
            verticalLayout
              ? { marginLeft: "0.5rem !important" }
              : { marginTop: "0 !important" },
          ]}
          style={{ padding: ".5rem 1rem" }}
          className="dch-scrollbar"
        >
          {propertiesFields.fields.map((field, propertyIndex) => {
            return (
              <List.Item key={field.id} style={{ padding: ".5rem 0" }}>
                <EntityPropertyInput
                  {...props}
                  propertyIndex={propertyIndex}
                  handleDelete={deleteProperty}
                  entityClasses={entityClasses}
                />
              </List.Item>
            );
          })}
          {!isReadOnly &&
            !entityProperties?.some(
              (ep) => !ep.entityPropertyId || ep.entityPropertyId === ""
            ) && (
              <List.Item>
                {entityProperties?.length !== 0 ? (
                  <Divider />
                ) : (
                  <div
                    css={tw`py-2 mb-4`}
                  >{`This entity currently has no properties.`}</div>
                )}
                <Form.Button
                  submit={false}
                  onClick={() => addNewProperty()}
                  icon="add"
                  label={`Add ${
                    entityProperties?.length === 0 ? "a" : "another"
                  } property`}
                />
              </List.Item>
            )}
        </List>
      )}
    </UIStatusWrapper>
  );

  return (
    <LabelledInput
      label="Entity Properties"
      input={input}
      verticalLayout={verticalLayout}
    />
  );
};
