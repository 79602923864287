import React, { useCallback, useEffect, useState } from "react";
import { debounce } from "throttle-debounce";
import { GetApplicationsSearchParams, metisApi } from "data/Metis";
import {
  Page,
  PageTitle,
  PrimaryTitle,
  UIStatus,
  UIStatusWrapper,
} from "components/shared";
import {
  AppDisplayInfo,
  AppListView,
  ApplicationsList,
  ApplicationsMode,
  appResponsetoAppDisplayInfo,
  defaultFilters,
  FilterApplicationsHeader,
  FilterOption,
  SortByOption,
} from "components/Applications/shared";

export const AvailableApplications = () => {
  const [apps, setApps] = useState<Array<AppDisplayInfo>>([]);
  const [appListView, setAppListView] = useState<AppListView>(AppListView.Grid);
  const [appListQuery, setAppListQuery] =
    useState<GetApplicationsSearchParams>(defaultFilters);
  const [uiStatus, setUiStatus] = useState(new UIStatus(true));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetch = useCallback(
    debounce(500, (queryParams: GetApplicationsSearchParams) => {
      setUiStatus((prevState) => prevState.setIndeterminate(true));
      metisApi.getApplicationRecipes(queryParams).then(
        (applications) => {
          const publishedApps = applications.filter(
            (application) => application.published
          );
          const displayAppList: AppDisplayInfo[] = publishedApps.map((app) =>
            appResponsetoAppDisplayInfo(ApplicationsMode.available, app)
          );
          setApps(displayAppList);
          setUiStatus((prevState) => prevState.setIndeterminate(false));
        },
        (e) => {
          setUiStatus((prevState) =>
            prevState.setError("Error loading applications: " + e.message)
          );
        }
      );
    }),
    []
  );

  // on first load and when search params change, fetch all available applications
  useEffect(() => {
    debouncedFetch(appListQuery);
  }, [appListQuery, debouncedFetch]);

  return (
    <Page>
      <PageTitle
        primaryTitle={PrimaryTitle.AppMarketplace}
        secondaryTitle="Browse Available Applications"
      />
      <FilterApplicationsHeader
        appListView={appListView}
        setAppListView={setAppListView}
        appListQuery={appListQuery}
        setAppListQuery={setAppListQuery}
        filterOptions={[FilterOption.vendor, FilterOption.category]}
        sortByOptions={[
          SortByOption.name,
          SortByOption.vendor,
          SortByOption.createdTime,
        ]}
      />
      <UIStatusWrapper status={uiStatus}>
        <ApplicationsList
          apps={apps}
          mode={ApplicationsMode.available}
          appListView={appListView}
          uiStatus={uiStatus}
        />
      </UIStatusWrapper>
    </Page>
  );
};
