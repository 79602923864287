import { InputWidths } from "components/shared";
import { SemanticShorthandContent } from "semantic-ui-react";

export type LabelValue<a> = {
  value: a;
  label: string;
  disabled?: boolean;
  isBuilding?: boolean;
  content?: SemanticShorthandContent;
};

export const SEARCH_OPTION_INPUT_WIDTHS: InputWidths = {
  label: {
    computer: 4,
    largeScreen: 4,
    tablet: 6,
    mobile: 16,
  },
  input: {
    computer: 12,
    largeScreen: 12,
    tablet: 10,
    mobile: 16,
  },
};
