import { ClientEnv, Headers } from "data/utils";
import { Option } from "fp-ts/lib/Option";
import * as O from "fp-ts/lib/Option";

import {
  DecodedResponse,
  UserPass,
  decodeResponse,
  mkBasic,
  mkUrl,
} from "data/http";
import { localStorageKeys } from "data/auth";
import { handlePaginationResponse } from "data/paginationHelper";
import { getValidationResponse } from "./Mason";

const authHeaders = (auth: Option<UserPass>): Headers => {
  const bearer: Headers = {
    Authorization: `Bearer ${localStorage.getItem(
      localStorageKeys.idpBearerToken,
    )}`,
  };
  const basic: Headers = O.fold(
    () => ({}),
    (a: UserPass) => ({ Authorization: mkBasic(a.user)(a.pass) }),
  )(auth);
  return { ...bearer, ...basic };
};

export const http =
  (url?: string) =>
  <T>(decodeResponseFunc?: (input: Response) => Promise<T>) =>
  (rawError?: boolean) =>
  (method: string) =>
  (request: object) =>
  <p extends object | null>(auth: Option<UserPass>) =>
  (env: ClientEnv) =>
  (params: p) =>
  (route: string): Promise<DecodedResponse<T>> =>
    window
      .fetch(url ?? mkUrl(env, route, params), {
        method,
        credentials: "same-origin",
        headers: { ...env.headers, ...authHeaders(auth) },
        redirect: "follow",
        ...request,
      })
      .then((r) => decodeResponse<T>(r, !!rawError, decodeResponseFunc));

export const httpPost = <a>(data: a) =>
  http()()()("POST")({ body: JSON.stringify(data) });
export const httpGet = http()()()("GET")({});
export const httpPatch = <a>(data: a) =>
  http()()()("PATCH")({ body: JSON.stringify(data) });
export const httpDelete = http()()()("DELETE")({});
export const httpPut = <a>(data: a) =>
  http()()()("PUT")({ body: JSON.stringify(data) });

export const httpPostFile = (formData: FormData) => {
  return http()()()("POST")({ body: formData });
};
export const httpPostRaw = <a>(data: a) => http()()()("POST")({ body: data });
export const httpPutRawWithShaclReport = <a>(data: a) =>
  http()(getValidationResponse)(true)("PUT")({ body: data });
export const httpPostRawWithShaclReport = <a>(data: a) =>
  http()(getValidationResponse)(true)("POST")({ body: data });

export const httpGetToBlob = http()((r: Response) => r.blob())()("GET")({});

export const getPaginatedFromURL = (url: string) => (env: ClientEnv) =>
  http(url)((res: Response) => handlePaginationResponse(res))()("GET")({})(
    O.none,
  )(env)(null)("");
