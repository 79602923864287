import React, { useEffect } from "react";
import { EntityPropertyAttributeSchema } from "data/Mason/EntityProperties";
import { convertCamelCaseToTitleCase } from "components/shared";
import Form from "components/shared/Forms/ReactHookForm";
import { useFormContext } from "react-hook-form";
import { Unit } from "data/Mason";
import { toOptionsUnits } from "../ClassifierSet";
type Props = {
  attribute: EntityPropertyAttributeSchema;
  entityPropertyFieldName: string;
  required?: boolean;
  unitsList: Unit[];
};

enum KeyValueKeys {
  KEY = "key",
  VALUE = "value",
  HASUNIT = "hasUnit",
}
export const EntityPropertyKeyValueAttribute = (props: Props) => {
  const { attribute, entityPropertyFieldName, required, unitsList } = props;
  const { id } = attribute;

  const { watch, trigger, setValue } = useFormContext();
  const watchHasUnit = watch(
    `${entityPropertyFieldName}.${KeyValueKeys.HASUNIT}`,
  );
  const watchValue = watch(`${entityPropertyFieldName}.${KeyValueKeys.VALUE}`);

  const hasUnitDeclaredValidator = (v?: number) => {
    if (v) return undefined;
    return "Value must be a number when hasUnit is declared";
  };

  const inputProps = {
    name: `${entityPropertyFieldName}.${id}`,
    label: convertCamelCaseToTitleCase(id),
    required: required ?? attribute.required,
    rules: {
      validate:
        id === KeyValueKeys.VALUE && isNaN(watchValue) && watchHasUnit
          ? hasUnitDeclaredValidator
          : undefined,
    },
    verticalLayout: true,
  };

  useEffect(() => {
    // reset the value on first hasunit declaration
    if (watchHasUnit && typeof watchValue === "string") {
      // carry the value over to new key if its a number i.e.`number-${entityPropertyFieldName}.${id}`
      if (!isNaN(Number(watchValue)) && watchValue.trim() !== "") {
        setValue(`${entityPropertyFieldName}.${id}`, Number(watchValue));
      }
      // reset the value if its not valid
      else {
        setValue(`${entityPropertyFieldName}.${id}`, undefined);
      }
      trigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (id === KeyValueKeys.HASUNIT) {
    return (
      <Form.SelectInput
        {...inputProps}
        options={unitsList.map(toOptionsUnits)}
      />
    );
  }

  return (
    <Form.TextInput
      key={
        watchHasUnit && id === KeyValueKeys.VALUE
          ? `number-${entityPropertyFieldName}.${id}`
          : `string-${entityPropertyFieldName}.${id}`
      }
      {...inputProps}
      type={watchHasUnit && id === KeyValueKeys.VALUE ? "number" : "string"}
      validateOnChange={true}
    />
  );
};
