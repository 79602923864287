/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { format, getMonth, getYear, parse } from "date-fns";
import range from "lodash/range";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Input } from "semantic-ui-react";
import tw from "twin.macro";
import {
  DATE_TIME_FORMAT,
  isValidDate,
  SelectInput,
  toOptionTypeBase,
} from "components/shared";
import { InputValidation } from "../InputValidation";
import {
  InputProps,
  getLabelProps,
  getLabelledInputLayoutProps,
} from "./InputUtils";
import { LabelledInput } from "./LabelledInput";

export type DateInputProps = {
  minDate?: Date;
  maxDate?: Date;
  mountNodeId?: string;
  clearable?: boolean;
  validateOnLoad?: boolean;
  onBlur?: () => void;
  onChange?: (date: string | null) => void;
} & InputProps<string>;

export const DateInput = (props: DateInputProps) => {
  const [stringValue, setStringValue] = useState(""); //maintain this separately as use can edit manually in the input field and we want to reflect the changes as they type and delay validation
  const [startDate, setStartDate] = useState<Date | null>(null);
  const labelProps = getLabelProps(props);
  const labelledInputProps = getLabelledInputLayoutProps(props);

  useEffect(() => {
    if (props.value) {
      if (isValidDate(props.value)) {
        setStartDate(parse(props.value, DATE_TIME_FORMAT, new Date()));
      }
      setStringValue(props.value);
    }
  }, [props.value]);

  const error =
    props.error || !isValidDate(stringValue) || props.inputValidation?.invalid;

  const inputValidation = !props.inputValidation
    ? undefined
    : isValidDate(stringValue)
      ? props.inputValidation
      : {
          invalid: true,
          errorMessage: `Invalid date. Please enter a valid date in the format ${DATE_TIME_FORMAT}.`,
        };

  const input = (
    <>
      <div
        data-test-id={labelProps.label ?? "dateInput"}
        className="datetimeinput-full"
      >
        <DatePicker
          renderCustomHeader={(datePickerProps) => (
            <CustomCalendarHeader
              date={datePickerProps.date}
              changeYear={datePickerProps.changeYear}
              changeMonth={datePickerProps.changeMonth}
              minDate={props.minDate}
              maxDate={props.maxDate}
            />
          )}
          minDate={props.minDate}
          maxDate={props.maxDate}
          showTimeSelect
          selected={startDate}
          placeholderText={props.placeholder ?? "Select Date"}
          onChange={(date) => {
            setStartDate(date);
            if (date) {
              props.onChange?.(format(date, DATE_TIME_FORMAT));
              setStringValue(format(date, DATE_TIME_FORMAT));
            }
          }}
          onChangeRaw={(e) => {
            setStringValue((e?.target as HTMLInputElement).value);
            props.onChange?.((e?.target as HTMLInputElement).value);
          }}
          customInput={
            <Input
              className={`datetimeinput-full ${error ? "error" : ""}`}
              icon="calendar"
              iconPosition="left"
            />
          }
          dateFormat={DATE_TIME_FORMAT}
          timeIntervals={15}
          portalId={props.mountNodeId}
          popperPlacement="top-start"
          showPopperArrow={false}
        />
      </div>
      {inputValidation && <InputValidation {...inputValidation} />}
    </>
  );
  return props.label ? (
    <LabelledInput {...labelProps} {...labelledInputProps} input={input} />
  ) : (
    input
  );
};

const CustomCalendarHeader = ({
  date,
  changeYear,
  changeMonth,
  minDate,
  maxDate,
}: {
  date: Date;
  changeYear: (year: number) => void;
  changeMonth: (month: number) => void;
  minDate?: Date;
  maxDate?: Date;
}) => {
  const years = range(
    minDate ? getYear(minDate) : 1990,
    maxDate ? getYear(maxDate) : getYear(new Date()) + 10,
    1,
  );
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    <div css={tw`flex gap-1 px-2 py-1`}>
      <div css={tw`w-[56%]`}>
        <SelectInput
          placeholder=""
          value={months[getMonth(date)]}
          onChange={(_, { value }) => changeMonth(months.indexOf(value))}
          options={months.map((month) => toOptionTypeBase(month))}
          isClearable={false}
          search
        />
      </div>
      <div css={tw`w-[44%]`}>
        <SelectInput
          placeholder=""
          value={getYear(date)}
          onChange={(_, { value }) => changeYear(Number(value))}
          options={years.map((year) => toOptionTypeBase(year))}
          isClearable={false}
          search
        />
      </div>
    </div>
  );
};
