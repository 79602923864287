import React from "react";

export const RowSvgIcon = (props: {
  color: string;
  width?: number;
  height?: number;
  strokeWidth?: number;
}) => {
  const width = props.width ?? 43;
  const height = props.height ?? 27;
  const strokeWidth = props.strokeWidth ?? 3;
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={width} height={strokeWidth} rx={3} fill={props.color} />
      <rect
        y={(height - strokeWidth) / 3}
        width={width}
        height={strokeWidth}
        rx={3}
        fill={props.color}
      />
      <rect
        y={(2 * (height - strokeWidth)) / 3}
        width={width}
        height={strokeWidth}
        rx={3}
        fill={props.color}
      />
      <rect
        y={height - strokeWidth}
        width={width}
        height={strokeWidth}
        rx={3}
        fill={props.color}
      />
    </svg>
  );
};
