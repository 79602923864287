import * as React from "react";
import { Grid } from "semantic-ui-react";
import { ModelRootType } from "data/brick";
import Form from "components/shared/Forms/ReactHookForm";
import { RdfLink } from "components/SitesAndBuildings/Common";

export const UploadModelForm = ({
  modelType,
}: {
  modelType: ModelRootType;
}) => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <p>
            Directly upload an existing {`${modelType.toLowerCase()}`} model in{" "}
            <RdfLink /> format with a .ttl file extension
          </p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Form.TextInput name="orgId" label="Organisation ID" isReadOnly />
          <Form.TextInput name="siteId" label="Site ID" isReadOnly />
          {modelType === ModelRootType.Building && (
            <Form.TextInput name="buildingId" label="Building ID" isReadOnly />
          )}
          <Form.FileInput
            name="file"
            label={`Upload ${modelType} File`}
            required
            accept=".ttl"
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
