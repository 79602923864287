import { ClientEnv } from "data/utils";
import { masonEnv } from "reducers/env";
import {
  BuildingGetRequest,
  BuildingMetaResponse,
  BuildingPutRequest,
  ShaclValidationResultReportResponse,
  MASON_BUILDINGS_ROUTE,
  MASON_BUILDING_ROUTE,
  MASON_JSON_DRAFT_ROUTE,
  MASON_ORGANISATION_ROUTE,
  MASON_RDF_DRAFT_ROUTE,
  MASON_SITE_ROUTE,
  ModelShaclReportResponse,
} from "..";
import * as O from "fp-ts/lib/Option";
import {
  httpDelete,
  httpGet,
  httpPut,
  httpPutRawWithShaclReport,
} from "data/httpUtil";
import {
  BuildingId,
  DCHModel,
  SerialisedDCHModel,
  SiteId,
  deserialiseDCHModel,
  serialiseDCHModel,
} from "data/brick";
import { OrgId } from "data/Enodia";
import { SiteGetRequest } from "../Site/SiteTypes";

export type BuildingApi = {
  getBuildings: (
    params: SiteGetRequest,
  ) => Promise<Array<BuildingMetaResponse>>;
  getBuilding: (params: BuildingGetRequest) => Promise<BuildingMetaResponse>;
  deleteBuilding: (params: BuildingGetRequest) => Promise<void>;
  putBuilding: (
    params: BuildingGetRequest,
  ) => (buildingMeta: BuildingPutRequest) => Promise<void>;
  getDraftBuildingRDF: (params: BuildingGetRequest) => Promise<string>;
  putDraftBuildingRDF: (
    params: BuildingGetRequest,
  ) => (rdf: string) => Promise<ModelShaclReportResponse>;
  getDraftBuildingJSON: (params: BuildingGetRequest) => Promise<DCHModel>;
  putDraftBuildingJSON: (
    params: BuildingGetRequest,
    model: DCHModel,
  ) => Promise<ShaclValidationResultReportResponse>;
  validateBuilding: (
    params: BuildingGetRequest,
  ) => Promise<ModelShaclReportResponse>;
};

const getBuildingBaseUrl = (params: BuildingGetRequest) =>
  `/${MASON_ORGANISATION_ROUTE}/${params.orgId}/${MASON_SITE_ROUTE}/${params.siteId}/${MASON_BUILDING_ROUTE}/${params.buildingId}`;

const mkBuildingApi = (env: ClientEnv): BuildingApi => ({
  getBuildings: (params) =>
    (true
      ? httpGet(O.none)(env)(null)(
          `/${MASON_ORGANISATION_ROUTE}/${params.orgId}/${MASON_SITE_ROUTE}/${params.siteId}/${MASON_BUILDINGS_ROUTE}`,
        )
      : Promise.resolve([stubBuildingMeta])
    ).then((r) => r as BuildingMetaResponse[]),
  getBuilding: (params) =>
    (true
      ? httpGet(O.none)(env)(null)(getBuildingBaseUrl(params))
      : Promise.resolve(stubBuildingMeta)
    ).then((r) => r as BuildingMetaResponse),
  deleteBuilding: (params) =>
    httpDelete(O.none)(env)(null)(getBuildingBaseUrl(params)).then(
      (_) => undefined,
    ),
  putBuilding: (params) => (buildingMeta) =>
    httpPut(buildingMeta)(O.none)(env)({})(getBuildingBaseUrl(params)).then(
      () => undefined,
    ),
  getDraftBuildingRDF: (params) =>
    httpGet(O.none)(env)({})(
      `${getBuildingBaseUrl(params)}/${MASON_RDF_DRAFT_ROUTE}`,
    ).then((r) => r as string),
  putDraftBuildingRDF: (params) => (rdf) =>
    httpPutRawWithShaclReport(rdf)(O.none)(env)({})(
      `${getBuildingBaseUrl(params)}/${MASON_RDF_DRAFT_ROUTE}`,
    ).then((r) => r as ModelShaclReportResponse),
  getDraftBuildingJSON: (params) =>
    httpGet(O.none)(env)({})(
      `${getBuildingBaseUrl(params)}/${MASON_JSON_DRAFT_ROUTE}`,
    ).then((r) => deserialiseDCHModel(r as SerialisedDCHModel)),
  putDraftBuildingJSON: (params, model) =>
    httpPut(serialiseDCHModel(model))(O.none)(env)({})(
      `${getBuildingBaseUrl(params)}/${MASON_JSON_DRAFT_ROUTE}`,
    ).then((r) => r as ShaclValidationResultReportResponse),
  validateBuilding: (params) =>
    httpPutRawWithShaclReport({})(O.none)(env)(params)(
      `${getBuildingBaseUrl(params)}/validate`,
    ).then((r) => r as ModelShaclReportResponse),
});

export const buildingApi = mkBuildingApi(masonEnv);

const stubBuildingMeta: BuildingMetaResponse = {
  siteId: "SiteId" as SiteId,
  orgId: "OrgId" as OrgId,
  buildingId: "BuildingId" as BuildingId,
  label: "Building-label",
  description: "description",
};
