import { OrgId, OrgUid } from "data/Enodia";
import { GetMetisSortParams } from "../metisTypes";

export type VendorId = string & { readonly __tag: unique symbol };
export type VendorUid = string & { readonly __tag: unique symbol };
export type VendorName = string & { readonly __tag: unique symbol };
export type VendorDescription = string & { readonly __tag: unique symbol };

export type CommonVendorDetails = {
  organisationId: OrgId;
  organisationUid: OrgUid;
  name: string;
  description: string;
  overview: string;
  email: string;
  website: string;
};

export type VendorDetailsResponse = {
  uid: VendorUid;
} & CommonVendorDetails;

export type VendorCreateRequest = CommonVendorDetails;

export type VendorUpdateRequest = VendorCreateRequest;

export const generateVendorSearchParamsQueryString = (
  params: GetVendorsSearchParams
) => {
  const searchParams = new URLSearchParams();
  if (params.name) searchParams.set("name", params.name);
  if (params.organisationId)
    searchParams.set("organisationId", params.organisationId);
  if (params.organisationUid)
    searchParams.set("organisationUid", params.organisationUid);
  return searchParams.toString();
};

export type GetVendorsSearchParams = {
  name?: string;
  organisationId?: string;
  organisationUid?: string;
} & GetMetisSortParams;
