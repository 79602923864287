import { DataPoolId } from "data/Aletheia";
import { SiteId, BuildingId, ModelId, PointId, PointType } from "data/brick";
import { OrgId } from "data/Enodia";
import { ClassHypernym } from "data/Mason";
import { EntityPropertyFromQueryDto } from "data/Mason/EntityProperties";
import { BrickProperty } from "data/Models/Brick/brickRelationships";
import { StreamId } from "data/senaps";

/****************************************************************/
/* BRIQL QUERYING */
/****************************************************************/
export enum QueryMode {
  select = "select",
  ask = "ask",
  describe = "describe",
}

export type Query = {
  ref?: string;
  comment?: string;
  mode?: QueryMode; // has a server side default of QueryMode.select
  variables: Array<QueryVar>;
  query?: Block;
};

export type QueryRefInvocation = {
  queryRef?: string;
};
export type QueryDefInvocation = {
  queryDef: Query;
};

export enum VarFields {
  id = "id",
  type = "type",
  hypernym = "hypernym",
  pointInfo = "pointInfo",
  streams = "streams",
  label = "label",
  entityProperties = "entityProperties",
}

export enum QueryVarType {
  node = "node",
  type = "type",
  property = "property",
  literal = "literal",
}

enum LogicMode {
  and = "and",
  or = "or",
}

export enum OrderHint {
  fetchPoints = "fetchPoints",
}

type PropertyVariable = {
  variable: string;
};

type PropertyProperty = {
  property: BrickProperty;
};

type Property = (PropertyVariable | PropertyProperty) & {
  or?: Array<Property>;
  count?: number;
  min?: number; // default = 1
  max?: number;
};

type PropertyPath = {
  fromRef: string;
  properties: Array<Property>;
  toRef?: string;
  toDef?: QueryVar;
};

type Block = {
  comment?: string;
  paths?: Array<PropertyPath>;
  nested?: Array<Block>;
  subquery?: Array<Query>;
  logic?: LogicMode;
  optional?: boolean;
  model?: ModelReference;
};

type QueryVarBase = {
  varType: QueryVarType;
  name: string;
  comment?: string;
  input?: boolean;
  output?: boolean;
  nullable?: boolean;
  constraints?: Block;
};

type NodeVar = {
  bind?: Array<VarFields>;
  fetch?: Array<VarFields>;
  orgId?: OrgId;
  siteId?: SiteId;
  buildingId?: BuildingId;
  nodeId?: string;
  brickType?: MatchType;
  brickTypes?: Array<MatchType>;

  // This option will prevent query returning solutions where a node doesn't have any of the requested point types.
  pointsOptional?: boolean;

  // if the user specifies any point types (other than "Point"), add "orderHint":["fetchPoints"].
  // if the user doesn't specify any point types, do not add this line.
  // This hint will improve performance and reduce temporary memory usage during execution.
  orderHint?: Array<OrderHint>;

  fetchPoints?: Array<MatchType> | null;
  filterOn?: Array<VarFields>;
  filterString?: string;
  nested?: Array<NodeVar>;
} & QueryVarBase;
type TypeVar = {
  brickType?: string;
  hypernym?: ClassHypernym;
  fetch?: Array<VarFields>;
} & QueryVarBase;
type PropertyVar = { property?: BrickProperty } & QueryVarBase;
type LiteralVar = { value?: string | number } & QueryVarBase;
export type QueryVar = NodeVar | TypeVar | PropertyVar | LiteralVar;

/****************************************************************/
/* TAG MATCHING */
/****************************************************************/
export enum Match {
  isa = "isa",
  equals = "equals",

  // "equalsOrEquivalent" will make it match the named class, or any class which is related via "owl:equivalentClass" (but not rdfs:subClassOf)
  equalsOrEquivalent = "equalsOrEquivalent",

  parent = "parent",
  hypernym = "hypernym",
  tags = "tags",
}

type MatchTypeType = {
  match?:
    | Match.equals
    | Match.hypernym
    | Match.isa
    | Match.parent
    | Match.equalsOrEquivalent;
  type?: string;
};

type MatchTypeTags = { match?: Match.tags; tags?: Array<BrickTag> };

export type MatchType = MatchTypeType | MatchTypeTags;

enum BrickTag {
  AHU = "AHU",
  Absorption = "Absorption",
  Acceleration = "Acceleration",
  Adjust = "Adjust",
  Air = "Air",
  Alarm = "Alarm",
  Angle = "Angle",
  Auto = "Auto",
  Automatic = "Automatic",
  Average = "Average",
  Azimuth = "Azimuth",
  Band = "Band",
  Basement = "Basement",
  Battery = "Battery",
  Blowdown = "Blowdown",
  Boiler = "Boiler",
  Booster = "Booster",
  Box = "Box",
  Building = "Building",
  Bus = "Bus",
  Button = "Button",
  Bypass = "Bypass",
  CO2 = "CO2",
  CRAC = "CRAC",
  CWS = "CWS",
  Capacity = "Capacity",
  Centrifugal = "Centrifugal",
  Change = "Change",
  Chilled = "Chilled",
  Chiller = "Chiller",
  City = "City",
  Close = "Close",
  Code = "Code",
  Coil = "Coil",
  Cold = "Cold",
  Coldest = "Coldest",
  Command = "Command",
  Communication = "Communication",
  Compressor = "Compressor",
  Computer = "Computer",
  Condensate = "Condensate",
  Condenser = "Condenser",
  Conditioning = "Conditioning",
  Conductivity = "Conductivity",
  Contact = "Contact",
  Control = "Control",
  Cool = "Cool",
  Current = "Current",
  Curtailment = "Curtailment",
  Cutout = "Cutout",
  Cycle = "Cycle",
  Daily = "Daily",
  Damper = "Damper",
  Dc = "Dc",
  Deadband = "Deadband",
  Deceleration = "Deceleration",
  Dehumidification = "Dehumidification",
  Deionised = "Deionised",
  Deionized = "Deionized",
  Delay = "Delay",
  Demand = "Demand",
  Derivative = "Derivative",
  Detected = "Detected",
  Detection = "Detection",
  Dewpoint = "Dewpoint",
  Differential = "Differential",
  Dimmer = "Dimmer",
  Direction = "Direction",
  Disable = "Disable",
  Discharge = "Discharge",
  Domestic = "Domestic",
  Drive = "Drive",
  Driver = "Driver",
  Dual = "Dual",
  Duct = "Duct",
  Duration = "Duration",
  Econcycle = "Econcycle",
  Economizer = "Economizer",
  Effective = "Effective",
  Electrical = "Electrical",
  Elevator = "Elevator",
  Emergency = "Emergency",
  Enable = "Enable",
  Energy = "Energy",
  Entering = "Entering",
  Enthalpy = "Enthalpy",
  Environment = "Environment",
  Equipment = "Equipment",
  Evaporative = "Evaporative",
  Even = "Even",
  Exchanger = "Exchanger",
  Exhaust = "Exhaust",
  FCP = "FCP",
  FCU = "FCU",
  Failure = "Failure",
  Fan = "Fan",
  Fault = "Fault",
  Fequency = "Fequency",
  Filter = "Filter",
  Fire = "Fire",
  Fixed = "Fixed",
  Floor = "Floor",
  Flow = "Flow",
  Fluid = "Fluid",
  Freeze = "Freeze",
  Freezer = "Freezer",
  Frequency = "Frequency",
  Fresh = "Fresh",
  Frost = "Frost",
  Fuel = "Fuel",
  Fume = "Fume",
  Furniture = "Furniture",
  Gain = "Gain",
  Gas = "Gas",
  Gasoline = "Gasoline",
  Generator = "Generator",
  Glycool = "Glycool",
  Grains = "Grains",
  HVAC = "HVAC",
  HWS = "HWS",
  HX = "HX",
  Hail = "Hail",
  Hand = "Hand",
  Handler = "Handler",
  Head = "Head",
  Heat = "Heat",
  Heater = "Heater",
  High = "High",
  Highest = "Highest",
  Hold = "Hold",
  Hood = "Hood",
  Hot = "Hot",
  Humidification = "Humidification",
  Humidifier = "Humidifier",
  Humidify = "Humidify",
  Humidity = "Humidity",
  Ice = "Ice",
  Illuminance = "Illuminance",
  Indicator = "Indicator",
  Integral = "Integral",
  Interface = "Interface",
  Inverter = "Inverter",
  Isolation = "Isolation",
  Laboratory = "Laboratory",
  Lag = "Lag",
  Last = "Last",
  Lead = "Lead",
  Leak = "Leak",
  Leaving = "Leaving",
  Level = "Level",
  Lighting = "Lighting",
  Limit = "Limit",
  Liquid = "Liquid",
  Load = "Load",
  Locally = "Locally",
  Location = "Location",
  Lockout = "Lockout",
  Loss = "Loss",
  Louver = "Louver",
  Low = "Low",
  Lowest = "Lowest",
  Luminaire = "Luminaire",
  Luminance = "Luminance",
  Maintenance = "Maintenance",
  Makeup = "Makeup",
  Manual = "Manual",
  Max = "Max",
  Medium = "Medium",
  Meter = "Meter",
  Min = "Min",
  Mixed = "Mixed",
  Mode = "Mode",
  Month = "Month",
  Monthly = "Monthly",
  Motion = "Motion",
  Motor = "Motor",
  Natural = "Natural",
  No = "No",
  Occupancy = "Occupancy",
  Occupied = "Occupied",
  Off = "Off",
  Oil = "Oil",
  On = "On",
  Open = "Open",
  Operating = "Operating",
  Output = "Output",
  Outside = "Outside",
  Overload = "Overload",
  Overridden = "Overridden",
  Override = "Override",
  PID = "PID",
  PIR = "PIR",
  PV = "PV",
  Panel = "Panel",
  Parameter = "Parameter",
  Peak = "Peak",
  Percent = "Percent",
  Photovoltaic = "Photovoltaic",
  Piezoelectric = "Piezoelectric",
  Pir = "Pir",
  PlugStrip = "PlugStrip",
  Point = "Point",
  Position = "Position",
  Power = "Power",
  Pre = "Pre",
  Preheat = "Preheat",
  Pressure = "Pressure",
  Proportional = "Proportional",
  Protect = "Protect",
  Pump = "Pump",
  Push = "Push",
  RTU = "RTU",
  RVAV = "RVAV",
  Radiance = "Radiance",
  Rain = "Rain",
  Rated = "Rated",
  Ratio = "Ratio",
  Reactive = "Reactive",
  Ready = "Ready",
  Real = "Real",
  Reheat = "Reheat",
  Relative = "Relative",
  Remotely = "Remotely",
  Request = "Request",
  Required = "Required",
  Reset = "Reset",
  Return = "Return",
  Roof = "Roof",
  Rooftop = "Rooftop",
  Room = "Room",
  Run = "Run",
  Safety = "Safety",
  Sash = "Sash",
  Schedule = "Schedule",
  Sensor = "Sensor",
  Server = "Server",
  Setpoint = "Setpoint",
  Shade = "Shade",
  Shed = "Shed",
  Short = "Short",
  Shutdown = "Shutdown",
  Site = "Site",
  Smoke = "Smoke",
  Solar = "Solar",
  Solid = "Solid",
  Space = "Space",
  Speed = "Speed",
  Stack = "Stack",
  Stages = "Stages",
  Standby = "Standby",
  Start = "Start",
  Static = "Static",
  Status = "Status",
  Steam = "Steam",
  Step = "Step",
  Stop = "Stop",
  Storage = "Storage",
  Suction = "Suction",
  Supply = "Supply",
  Switch = "Switch",
  System = "System",
  Tank = "Tank",
  Temperature = "Temperature",
  Temporary = "Temporary",
  Terminal = "Terminal",
  Thermal = "Thermal",
  Thermostat = "Thermostat",
  Time = "Time",
  Timer = "Timer",
  Today = "Today",
  Tolerance = "Tolerance",
  Torque = "Torque",
  Touchpanel = "Touchpanel",
  Tower = "Tower",
  Trace = "Trace",
  Turn = "Turn",
  Underfloor = "Underfloor",
  Unit = "Unit",
  Unoccupied = "Unoccupied",
  Usage = "Usage",
  VAV = "VAV",
  VFD = "VFD",
  Valve = "Valve",
  Variable = "Variable",
  Velocity = "Velocity",
  Vent = "Vent",
  Ventilation = "Ventilation",
  Voltage = "Voltage",
  Volume = "Volume",
  Warm = "Warm",
  Warmest = "Warmest",
  Water = "Water",
  Weather = "Weather",
  Wheel = "Wheel",
  Wind = "Wind",
  Wing = "Wing",
  Yearly = "Yearly",
  Zenith = "Zenith",
  Zone = "Zone",
}

/****************************************************************/
/* BRIQL NODE TYPES */
/****************************************************************/

export type NodeUriType = `dch:org/${string}/site/${string}${
  | ""
  | `building/${string}`}#${string}`;
// Example: 'dch:org/john-test/site/test_unnamed_equip/building/test_unnamed_equip#B307_MainsWaterCircuit'

export const isNodeValueRef = (a: any): a is NodeValueRef =>
  typeof a === "object" &&
  a.varType === "reference" &&
  (a.fullId != null ? typeof a.fullId === "string" : true);

export type PointInfo = {
  type: PointType;
  point: PointId;
  streams: Array<StreamId>;
};

type NullValue = {
  varType: "null";
};

type NodeValueRef = {
  varType: "reference";
  fullId: NodeUriType;
};

export type NodeValue = {
  fullId: NodeUriType;
  varType: "node";
  modelIndex: number;
  id?: string;
  label?: string;
  type?: string;
  hypernym?: ClassHypernym;
  pointInfo?: Array<PointInfo>;
  streams?: Array<StreamId>;
  entityProperty?: EntityPropertyFromQueryDto[];
  comment?: string;
  unit?: string;
};

type PropertyValue = {
  varType: "property";
  property?: string;
};

type TypeValue = {
  varType: "type";
  type?: string;
  hypernym?: string;
};

export type ResponseValue =
  | NullValue
  | NodeValueRef
  | NodeValue
  | PropertyValue
  | TypeValue;

export type RelatedNode = {
  relationship: BrickProperty;
  node: NodeRef;
  type: string;
  label?: string;
  comment?: string;
  hypernym: string;
  streamIds?: string[];
  properties: EntityPropertyFromQueryDto[];
};

/****************************************************************/
/* MODEL REFERENCE */
/****************************************************************/
export type ModelReference = {
  siteId?: SiteId;
  dataPoolId?: DataPoolId;
} & SiteBuildingReference;

export type NodeRef = {
  modelRef: ModelReference;
  nodeId: string;
};

export type SiteBuildingReference = {
  orgId: OrgId;
  siteId: SiteId;
  buildingId?: BuildingId;
  modelId?: ModelId;
};

export type BuildingListMap<T> = Map<OrgId, Map<SiteId, Array<T>>>;
