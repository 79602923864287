import React from "react";
import { Grid } from "semantic-ui-react";
import Form from "components/shared/Forms/ReactHookForm";

export const LandParcel = ({ isReadOnly }: { isReadOnly?: boolean }) => (
  <Grid columns="equal">
    <Grid.Row>
      <Grid.Column>
        <Form.TextInput
          name="address.propertyId"
          label="Property ID"
          isReadOnly={isReadOnly}
        />
      </Grid.Column>
      <Grid.Column>
        <Form.TextInput
          name="address.cadastralId"
          label="Cadastral ID"
          isReadOnly={isReadOnly}
        />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column>
        <Form.TextInput
          name="address.titleId"
          label="Title ID"
          isReadOnly={isReadOnly}
        />
      </Grid.Column>
      <Grid.Column />
    </Grid.Row>
  </Grid>
);
