import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import {
  WingFields,
  FloorFields,
  RoomFields,
  ModelDetailsFields,
} from "../EntityForms";
import { useFieldArrayNodes } from "../useFieldArrayNodes";
import {
  WatcherProps,
  handleArrayToArrayChanges,
} from "./RelationshipWatcherUtils";

export const usePointOfWatcher = (props: WatcherProps) => {
  const { isReadOnly, entityFieldId, entityIdentifier } = props;
  const { getValues, watch, setValue } = useFormContext();
  const { wingFields, floorFields, roomFields, equipmentFields } =
    useFieldArrayNodes();
  const watchPointOf = watch(`${entityIdentifier}.pointOf`);

  useEffect(() => {
    if (!isReadOnly) {
      handleArrayToArrayChanges({
        entityFieldId,
        getValues,
        setValue,
        watchedField: watchPointOf,
        existingTargetFields: wingFields.fields,
        targetNodeType: "wing",
        targetProperty: "points" as keyof WingFields,
      });
      handleArrayToArrayChanges({
        entityFieldId,
        getValues,
        setValue,
        watchedField: watchPointOf,
        existingTargetFields: floorFields.fields,
        targetNodeType: "floor",
        targetProperty: "points" as keyof FloorFields,
      });
      handleArrayToArrayChanges({
        entityFieldId,
        getValues,
        setValue,
        watchedField: watchPointOf,
        existingTargetFields: roomFields.fields,
        targetNodeType: "room",
        targetProperty: "points" as keyof RoomFields,
      });
      handleArrayToArrayChanges({
        entityFieldId,
        getValues,
        setValue,
        watchedField: watchPointOf,
        targetNodeType: "model",
        targetProperty: "points" as keyof ModelDetailsFields,
      });
    }
  }, [
    entityFieldId,
    equipmentFields.fields,
    floorFields.fields,
    getValues,
    isReadOnly,
    roomFields.fields,
    setValue,
    watchPointOf,
    wingFields.fields,
  ]);
};
