import React, { useCallback, useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { DropdownItemProps } from "semantic-ui-react";
import { BuildingId, SiteId } from "data/brick";
import { OrgId } from "data/Enodia";
import { SiteBuildingReference } from "data/QueryApi/queryTypes";
import { SiteOrBuildingIcon } from "components/SitesAndBuildings/SiteOrBuildingIcon";
import Form from "components/shared/Forms/ReactHookForm";
import {
  LabelValue,
  SEARCH_OPTION_INPUT_WIDTHS,
} from "../../../../Search/SearchParams/Common";
import { SearchParamsValues } from "../../../../Search/SearchParams/SearchForm";
import { SearchOption } from "../../../../Search/SearchParams/SearchOption";
import { masonApi } from "data/Mason";

const MODELS_FIELD_NAME: keyof SearchParamsValues = "models";
const INCLUDE_SITES_FIELD_NAME: keyof SearchParamsValues = "includeAllSites";
const INCLUDE_BUILDINGS_FIELD_NAME: keyof SearchParamsValues =
  "includeAllBuildings";

export const modelRefToSelectVal = (m: SiteBuildingReference) =>
  `${m.orgId}_${m.siteId}_${m.buildingId ?? ""}_${m.modelId ?? ""}`;

export const ModelsSelectInput = ({
  orgId,
  setModelsList,
  setError,
}: {
  orgId?: OrgId;
  setModelsList: (_: SiteBuildingReference[]) => void;
  setError?: (_: string) => void;
}) => {
  const [loadingModels, setLoadingModels] = useState(false);
  const [modelOptions, setModelOptions] = useState<
    LabelValue<SiteBuildingReference>[]
  >([]);
  const { setValue, watch } = useFormContext();

  const watchIncludeSites: boolean = watch(INCLUDE_SITES_FIELD_NAME);
  const watchIncludeBuildings: boolean = watch(INCLUDE_BUILDINGS_FIELD_NAME);

  const currentlySelectedModels: string[] = watch(MODELS_FIELD_NAME);
  const modelsRef = useRef(currentlySelectedModels);

  //fetch available models
  useEffect(() => {
    if (orgId) {
      setLoadingModels(true);
      masonApi
        .getSitesAndBuildings({ orgId })
        .then((siteBuildings) => {
          const buildingList: Array<[SiteId, BuildingId[]]> = siteBuildings.map(
            (siteWithBuildings) => [
              siteWithBuildings.id,
              siteWithBuildings.buildings.map((building) => building.id),
            ],
          );
          const modelReferenceArray = buildingList.flatMap(
            ([siteId, buildingIds]) => {
              const siteReference = {
                orgId: orgId,
                siteId: siteId,
              };
              const mappings = buildingIds.map<SiteBuildingReference>(
                (buildingId) => ({ ...siteReference, buildingId: buildingId }),
              );
              mappings.unshift(siteReference);
              return mappings;
            },
          );
          setLoadingModels(false);
          setModelsList(modelReferenceArray);
          setModelOptions(
            modelReferenceArray.map((modelReference) => ({
              value: modelReference,
              label: `${modelReference.siteId}${
                modelReference.buildingId
                  ? `: ${modelReference.buildingId}`
                  : ""
              }`,
              isBuilding: !!modelReference.buildingId,
            })),
          );
        })
        .catch((e) => setError?.(e.message ?? "Error loading models"))
        .finally(() => setLoadingModels(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  const addBuildingsForSites = useCallback(
    (sites: SiteId[]) => {
      const allBuildingsOfSites = modelOptions
        .filter(
          (model) => !!model.isBuilding && sites.includes(model.value.siteId),
        )
        .map((buildingOptions) => modelRefToSelectVal(buildingOptions.value));
      setValue(
        MODELS_FIELD_NAME,
        Array.from(
          new Set(currentlySelectedModels.concat(allBuildingsOfSites)),
        ),
        { shouldDirty: true, shouldValidate: true },
      );
    },
    [currentlySelectedModels, modelOptions, setValue],
  );

  //when toggle the include all Sites checkbox, update selected sites
  useEffect(() => {
    if (watchIncludeSites) {
      if (watchIncludeBuildings) {
        //we're adding all sites and all buildings, so ALL the model options
        setValue(
          MODELS_FIELD_NAME,
          Array.from(
            new Set(
              currentlySelectedModels.concat(
                modelOptions.map((model) => modelRefToSelectVal(model.value)),
              ),
            ),
          ),
          { shouldDirty: true, shouldValidate: true },
        );
      } else {
        //just add the sites
        const allSites = modelOptions
          .filter((model) => !model.isBuilding)
          .map((siteModel) => modelRefToSelectVal(siteModel.value));
        setValue(
          MODELS_FIELD_NAME,
          Array.from(new Set(currentlySelectedModels.concat(allSites))),
          { shouldDirty: true, shouldValidate: true },
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchIncludeSites]);

  //when toggle the include all Buildings checkbox, update selected buildings
  useEffect(() => {
    if (watchIncludeBuildings) {
      const currentlySelectedSites = modelOptions.filter(
        (model) =>
          !model.isBuilding &&
          currentlySelectedModels.includes(modelRefToSelectVal(model.value)),
      );
      addBuildingsForSites(currentlySelectedSites.map((m) => m.value.siteId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchIncludeBuildings]);

  //when manually selecting or de-selecting models, update checkboxes if needed
  useEffect(() => {
    if (!currentlySelectedModels) return;

    const handleRemovedModels = () => {
      const removedModels = modelsRef.current.filter(
        (ref) => !currentlySelectedModels.includes(ref),
      );
      const removedModelOptions = modelOptions.filter((model) =>
        removedModels.includes(modelRefToSelectVal(model.value)),
      );
      if (
        watchIncludeSites &&
        removedModelOptions.some((removed) => !removed.isBuilding)
      )
        setValue(INCLUDE_SITES_FIELD_NAME, false);
      if (
        watchIncludeBuildings &&
        removedModelOptions.some((removed) => removed.isBuilding)
      )
        setValue(INCLUDE_BUILDINGS_FIELD_NAME, false);
    };

    const handleAddedModels = () => {
      const addedModels = currentlySelectedModels.filter(
        (model) => !modelsRef.current.includes(model),
      );
      const addedSites = modelOptions
        .filter(
          (model) =>
            !model.isBuilding &&
            addedModels.includes(modelRefToSelectVal(model.value)),
        )
        .map((site) => site.value.siteId);

      if (watchIncludeBuildings && addedSites.length > 0) {
        addBuildingsForSites(addedSites);
      }
    };

    if (modelsRef.current) {
      if (watchIncludeSites || watchIncludeBuildings) {
        handleRemovedModels();
        handleAddedModels();
      }
    }
    modelsRef.current = currentlySelectedModels;
  }, [
    addBuildingsForSites,
    watchIncludeBuildings,
    watchIncludeSites,
    modelOptions,
    currentlySelectedModels,
    setValue,
  ]);

  const mapModelsToDropdownOptions = (
    modelOptions: Array<LabelValue<SiteBuildingReference>>,
  ): DropdownItemProps[] => {
    return modelOptions.map(({ value, label }) => {
      return {
        value: modelRefToSelectVal(value),
        text: label,
        content: (
          <>
            <SiteOrBuildingIcon isBuilding={!!value.buildingId} />
            {label}
          </>
        ),
        disabled: watchIncludeBuildings && !!value.buildingId,
      };
    });
  };

  const input = (
    <Form.SelectInput
      name={MODELS_FIELD_NAME}
      label="Model(s)"
      placeholder="Select models ..."
      noOptionsMessage="No models found."
      options={mapModelsToDropdownOptions(modelOptions)}
      isMulti
      isLoading={loadingModels}
      renderLabel={(item) => ({
        content: item.text,
        icon: item.icon,
      })}
      search
      required
      inputWidths={SEARCH_OPTION_INPUT_WIDTHS}
    />
  );

  const checkboxes = [
    <div key="includeAllSites">
      <Form.CheckboxInput
        name={INCLUDE_SITES_FIELD_NAME}
        disabled={!!loadingModels}
        checkboxLabel="include all sites"
        defaultValue={false}
      />
    </div>,
    <div key="includeAllBuildings">
      <Form.CheckboxInput
        name={INCLUDE_BUILDINGS_FIELD_NAME}
        disabled={!!loadingModels}
        checkboxLabel="include all  buildings of selected sites"
        defaultValue={false}
      />
    </div>,
  ];
  return <SearchOption input={input} rightCheckboxes={checkboxes} />;
};
