/** @jsxImportSource @emotion/react */

import * as React from "react";
import {
  Divider,
  Grid,
  Button,
  Icon,
  Table,
  SemanticCOLORS,
  SemanticICONS,
  SemanticWIDTHS,
  Statistic,
  Transition,
  Container,
} from "semantic-ui-react";
import tw from "twin.macro";
import { Observation } from "data/senaps";
import { StateColours, formatDate } from "components/shared";
import { min, max } from "date-fns";
import {
  DataDetails,
  dataFlowingHelper,
  dataHealthHelper,
  exportToCSV,
  gatherAverageResults,
  missingPercentHelper,
  missingSamplesHelper,
  outlierPercentHelper,
  outlierSamplesHelper,
  periodHelper,
  toFixed,
} from ".";
import { filterPeriod } from "./filterPeriod";

type PlotData = Array<{
  x: Date;
  y: number;
  name: string;
}>;

export const DataDetailsPane: React.FC<{
  streamData: Array<Observation>;
  vectorLookup: Record<number, string>;
  workflowGroupIDs: Array<string>;
  workflowSummaryID: string;
  workflowStreamIDs: Array<string>;
  selectedGroupIDs: Array<string>;
  invalidNamespace: boolean;
  workflowNamespace: string;
  streamLastMP: Record<string, number>;
  dataTrendChart: JSX.Element;
}> = (props) => {
  const [dataDetailSummary] = React.useState<DataDetails>({});
  const [visibility, setVisibility] = React.useState<boolean>(true);
  const [missingPercentData, setMissingPercentData] = React.useState<PlotData>(
    [],
  );
  const [outlierPercentData, setOutlierPercentData] = React.useState<PlotData>(
    [],
  );
  const [missingSamplesData, setMissingSamplesData] = React.useState<PlotData>(
    [],
  );
  const [outlierSamplesData, setOutlierSamplesData] = React.useState<PlotData>(
    [],
  );

  /**
   * Function to generate mean, delta, start time and end time of data given a requested time period.
   *
   * @param streamData - Array of Senaps observations to gather data from.
   * @param vectorLookup - Mapping from vector index to metrics.
   * @param streamid - Stream ID to gather data for.
   * @param metric - Metric to extract data from the vector.
   * @param aggString - String to define the time period over which to gather summaries.
   * @returns Dictionary `{mean: <mean>, <delta>: delta, <t0>: time start, <t1>: time end}` of summary statistics for the stream.
   */
  const getSummaryStatistic = (streamData: PlotData, aggString: string) => {
    const defaultResult = { mean: 0, delta: 0, t0: new Date(), t1: new Date() };
    if (streamData.length === 0) {
      return defaultResult;
    }

    const fData = filterPeriod(aggString, streamData);
    if (fData.length === 0) {
      return defaultResult;
    }

    const previousData = fData.slice(0, -1);
    const currentData = fData[fData.length - 1];

    let dT0 = new Date();
    let dT1 = new Date();
    if (fData.length >= 2) {
      dT0 = previousData[0].x;
      dT1 = currentData.x;
    }

    let dMean =
      previousData.flatMap((v) => v.y).reduce((p, c) => p + c, 0) /
      previousData.length;
    let dDelta = currentData.y - dMean;

    return { mean: dMean, delta: dDelta, t0: dT0, t1: dT1 };
  };

  /**
   * Utility function to generate a chevron to denote if a value has positively or negatively changed.
   *
   * @param value - Value to denote the observed change.
   * @param isPercent - Boolean to define if the value should be treated as a percentage.
   * @param isMaximising - Boolean to define if a growing value should be treated as a good or bad event.
   * @returns Chevron icon with the observed change, styled to denote if the change was good or bad.
   */
  const generateUpdateArrow = (
    value: number | undefined,
    isPercent: boolean = false,
    isMaximising: boolean = true,
  ) => {
    const isImproving =
      value && ((value > 0 && isMaximising) || (value < 0 && !isMaximising));
    const isUnchanging = value === undefined || value === 0;

    const iconName: SemanticICONS =
      value === undefined || value === 0
        ? ("minus circle" as SemanticICONS)
        : (`arrow circle ${value > 0 ? "up" : "down"}` as SemanticICONS);
    const iconColour: SemanticCOLORS = isUnchanging
      ? StateColours.Unknown
      : isImproving
        ? StateColours.Success
        : StateColours.Failure;

    return (
      <span>
        <Icon name={iconName} color={iconColour} />
        <span style={{ color: iconColour }}>
          {value === undefined
            ? toFixed(undefined, 0, "")
            : value > 0
              ? toFixed(value, 1, "")
              : toFixed(-value, 1, "")}
          {isPercent ? "%" : ""}
        </span>
      </span>
    );
  };

  const generateDataDetailsTableHeader = () => (
    <Table.Row>
      <Table.HeaderCell />
      <Table.HeaderCell>Period Start {periodHelper()}</Table.HeaderCell>
      <Table.HeaderCell>Data Health {dataHealthHelper()}</Table.HeaderCell>
      <Table.HeaderCell>
        Missing Samples {missingSamplesHelper()}
      </Table.HeaderCell>
      <Table.HeaderCell>
        Missing Percent {missingPercentHelper()}
      </Table.HeaderCell>
      <Table.HeaderCell>
        Outlier Samples {outlierSamplesHelper()}
      </Table.HeaderCell>
      <Table.HeaderCell>
        Outlier Percent {outlierPercentHelper()}
      </Table.HeaderCell>
    </Table.Row>
  );

  /**
   * Function to generate a table of values along with their change in value.
   *
   * @param missingSamples - Value of missingSamples to include in table.
   * @param missingSamplesDelta - Value of missingSamplesDelta to include in table.
   * @param missingPercent - Value of missingPercent to include in table.
   * @param missingPercentDelta - Value of missingPercentDelta to include in table.
   * @param outlierSamples - Value of outlierSamples to include in table.
   * @param outlierSamplesDelta - Value of outlierSamplesDelta to include in table.
   * @param outlierPercent - Value of outlierPercent to include in table.
   * @param outlierPercentDelta - Value of outlierPercentDelta to include in table.
   * @returns Table of Missing Samples, Missing Percent, Outlier Samples and Outlier Percent along with their deltas.
   */
  const generateHealthSummaryStatistics = (
    label: string,
    t0: Date,
    missingSamples: number | undefined,
    missingSamplesDelta: number | undefined,
    missingPercent: number | undefined,
    missingPercentDelta: number | undefined,
    outlierSamples: number | undefined,
    outlierSamplesDelta: number | undefined,
    outlierPercent: number | undefined,
    outlierPercentDelta: number | undefined,
  ) => {
    return (
      <Table.Row key={`HealthSummaryStatistics_${label}`}>
        <Table.Cell>{label}</Table.Cell>
        <Table.Cell textAlign="right">
          {t0 === undefined ? "" : formatDate(t0)}
        </Table.Cell>

        <Table.Cell textAlign="right">
          {toFixed(
            missingPercent === undefined ? undefined : 100.0 - missingPercent,
            1,
            "",
          )}
          %{" "}
          {generateUpdateArrow(
            missingPercentDelta === undefined
              ? undefined
              : -missingPercentDelta,
            true,
            true,
          )}
        </Table.Cell>

        <Table.Cell textAlign="right">
          {toFixed(missingSamples, 1, "")}{" "}
          {generateUpdateArrow(missingSamplesDelta, false, false)}
        </Table.Cell>

        <Table.Cell textAlign="right">
          {toFixed(missingPercent, 1, "")}%{" "}
          {generateUpdateArrow(missingPercentDelta, true, false)}
        </Table.Cell>

        <Table.Cell textAlign="right">
          {toFixed(outlierSamples, 1, "")}{" "}
          {generateUpdateArrow(outlierSamplesDelta, false, false)}
        </Table.Cell>

        <Table.Cell textAlign="right">
          {toFixed(outlierPercent, 1, "")}%{" "}
          {generateUpdateArrow(outlierPercentDelta, true, false)}
        </Table.Cell>
      </Table.Row>
    );
  };

  React.useEffect(() => {
    setMissingPercentData(
      gatherAverageResults(
        props.streamData,
        props.vectorLookup,
        props.selectedGroupIDs,
        "missing_percent",
      ),
    );
    setMissingSamplesData(
      gatherAverageResults(
        props.streamData,
        props.vectorLookup,
        props.selectedGroupIDs,
        "missing_data_samples",
      ),
    );
    setOutlierPercentData(
      gatherAverageResults(
        props.streamData,
        props.vectorLookup,
        props.selectedGroupIDs,
        "outlier_percent",
      ),
    );
    setOutlierSamplesData(
      gatherAverageResults(
        props.streamData,
        props.vectorLookup,
        props.selectedGroupIDs,
        "outlier_samples",
      ),
    );
  }, [props.streamData, props.vectorLookup, props.selectedGroupIDs]);

  /**
   * Function to generate a tab pane of a requested time period.
   *
   * @param label - String to define the tab labels which will define the periods which a user can request summary information for.
   * @returns Semantic UI Tab Pane of the requested period label.
   */
  const generatePeriodTab = (label: string) => {
    const mp = getSummaryStatistic(missingPercentData, label);
    const op = getSummaryStatistic(outlierPercentData, label);
    const ms = getSummaryStatistic(missingSamplesData, label);
    const os = getSummaryStatistic(outlierSamplesData, label);

    const t0 = min([mp.t0, op.t0, ms.t0, os.t0]);
    const t1 = max([mp.t1, op.t1, ms.t1, os.t1]);

    // Add the current panel data to the overall summary for export to CSV
    dataDetailSummary[label] = {
      periodStart: t0,
      periodEnd: t1,
      dataHealthMean: 100 - mp.mean,
      missingSamplesMean: ms.mean,
      missingPercentMean: mp.mean,
      outlierSamplesMean: os.mean,
      outlierPercentMean: op.mean,
    };

    return generateHealthSummaryStatistics(
      label,
      t0,
      ms.mean,
      ms.delta,
      mp.mean,
      mp.delta,
      os.mean,
      os.delta,
      op.mean,
      op.delta,
    );
  };

  /**
   * Function to generate data details general statistics panel, including "Last Updated", "Data Flowing", "Jobs Completed Total", "Jobs Completed Today", "Jobs Left Today".
   *
   * @returns Semantic UI Grid of the general statistics.
   */
  const generateDataDetailsSummary = React.useMemo(() => {
    const data = missingPercentData;

    const missingPercentNow =
      data.length === 0 ? undefined : data[data.length - 1].y;
    const dataFlowing =
      !(data.length === 0) &&
      missingPercentNow !== undefined &&
      missingPercentNow < 100;
    const lastUpdated =
      data.length === 0 ? new Date() : data[data.length - 1].x;

    const icons = [
      "clock",
      "cloud upload",
      "heartbeat",
      "line graph",
      "building",
    ];
    const label = [
      <span>Last Checked</span>,
      <div>
        <span>Data Flowing</span> {dataFlowingHelper()}
      </div>,
      <span>Average Data Health</span>,
      <span>Streams Checked</span>,
      <span>Buildings Checked</span>,
    ];
    const value = [
      <span>{formatDate(lastUpdated)}</span>,
      <span>{dataFlowing ? "Yes" : "No"}</span>,
      <span>
        {toFixed(
          missingPercentNow === undefined ? undefined : 100 - missingPercentNow,
          1,
          "",
        )}
        %
      </span>,
      <span>{toFixed(props.workflowStreamIDs.length, 0, "")}</span>,
      <span>{toFixed(props.workflowGroupIDs.length, 0, "")}</span>,
    ];

    return (
      <Grid
        columns={value.length as SemanticWIDTHS}
        centered
        inverted
        divided
        doubling
        stackable
      >
        {label.map((_, ii) => (
          <Grid.Column key={ii} verticalAlign="middle">
            <div>
              <Icon name={icons[ii] as SemanticICONS} size="large" inverted />
            </div>
            <Statistic color="purple" inverted size="mini">
              <Statistic.Label>{label[ii]}</Statistic.Label>
              <Statistic.Value>{value[ii]}</Statistic.Value>
            </Statistic>
          </Grid.Column>
        ))}
      </Grid>
    );
  }, [
    missingPercentData,
    props.workflowGroupIDs.length,
    props.workflowStreamIDs.length,
  ]);

  React.useEffect(() => {
    setVisibility((v) => !v);
  }, [props.selectedGroupIDs]);

  return (
    <div>
      <div css={tw`pt-2`}>
        {generateDataDetailsSummary}
        <Divider css={tw`my-0`} />
      </div>

      <Transition animation={"flash"} duration={500} visible={visibility}>
        <Container>
          <Table
            attached="top"
            inverted
            compact
            celled
            collapsing
            basic
            verticalAlign="top"
          >
            <Table.Header>{generateDataDetailsTableHeader()}</Table.Header>
            <Table.Body>
              {[
                "Most Recent",
                "Last Day",
                "Last Week",
                "Last Month",
                "Last Year",
              ].map(generatePeriodTab)}
            </Table.Body>
          </Table>
        </Container>
      </Transition>

      {props.dataTrendChart}

      <Divider />
      <Button
        primary
        icon="download"
        content="Export"
        labelPosition="right"
        onClick={() =>
          exportToCSV(
            dataDetailSummary,
            props.workflowNamespace,
            `Site,${
              props.workflowNamespace
            },Building(s),${props.selectedGroupIDs.join(",")}\n`,
          )
        }
      />
    </div>
  );
};
