import React from "react";
import { Grid } from "semantic-ui-react";
import { PointChart } from ".";
import { PointListItem, PointResponse } from "data/Aletheia";
import { DownloadButton } from "./DownloadButton";

type DataStreamProps = {
  point: PointListItem | PointResponse;
  mountNodeId?: string;
};

const PointData: React.FC<DataStreamProps> = ({ point, mountNodeId }) => {
  const renderPointChart = React.useCallback(
    () => (
      <PointChart
        points={[point] as PointResponse[]}
        mountNodeId={mountNodeId}
      />
    ),
    [point, mountNodeId]
  );

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>{renderPointChart()}</Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <DownloadButton id={point.id}>Download CSV</DownloadButton>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default PointData;
