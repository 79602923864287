import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Icon } from "semantic-ui-react";
import { debounce } from "throttle-debounce";
import { Path } from "Routes";
import { GetApplicationsSearchParams, metisApi } from "data/Metis";
import {
  Page,
  PageTitle,
  UIStatusWrapper,
  UIStatus,
  PrimaryTitle,
} from "components/shared";
import {
  AppDisplayInfo,
  AppListView,
  ApplicationsList,
  ApplicationsMode,
  appResponsetoAppDisplayInfo,
  defaultFilters,
  FilterApplicationsHeader,
  FilterOption,
  SortByOption,
} from "components/Applications/shared";

export const ManageApplications = () => {
  const [apps, setApps] = useState<Array<AppDisplayInfo>>([]);
  const [appListView, setAppListView] = useState<AppListView>(AppListView.Grid);
  const [appListQuery, setAppListQuery] =
    useState<GetApplicationsSearchParams>(defaultFilters);
  const navigate = useNavigate();
  const [uiStatus, setUiStatus] = useState(new UIStatus(true));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetch = useCallback(
    debounce(500, (queryParams: GetApplicationsSearchParams) => {
      setUiStatus((prevState) => prevState.setIndeterminate(true));
      metisApi.getManageApplicationRecipes(queryParams).then(
        (applications) => {
          const appsWithDisplayInfo: AppDisplayInfo[] = applications.map(
            (app) => appResponsetoAppDisplayInfo(ApplicationsMode.managed, app)
          );
          setApps(appsWithDisplayInfo);
          setUiStatus((prevState) => prevState.setIndeterminate(false));
        },
        (e) => {
          setUiStatus((prevState) =>
            prevState.setError("Error loading applications: " + e.message)
          );
        }
      );
    }),
    []
  );

  // on first load and when search params change, fetch all available applications
  useEffect(() => {
    debouncedFetch(appListQuery);
  }, [appListQuery, debouncedFetch]);

  return (
    <Page>
      <PageTitle
        primaryTitle={PrimaryTitle.ManageApps}
        secondaryTitle="Manage Applications"
      />
      <div style={{ marginBlock: ".5rem" }}>
        <Button
          primary
          onClick={() => {
            navigate(Path.UploadApplication, {
              state: { backTo: "Manage Applications" },
            });
          }}
        >
          <Icon name="upload" />
          Upload a New Application
        </Button>
      </div>
      <FilterApplicationsHeader
        appListView={appListView}
        setAppListView={setAppListView}
        appListQuery={appListQuery}
        setAppListQuery={setAppListQuery}
        filterOptions={[FilterOption.vendor, FilterOption.category]}
        sortByOptions={[
          SortByOption.name,
          SortByOption.vendor,
          SortByOption.createdTime,
        ]}
      />
      <UIStatusWrapper status={uiStatus}>
        <ApplicationsList
          apps={apps}
          mode={ApplicationsMode.managed}
          appListView={appListView}
          uiStatus={uiStatus}
        />
      </UIStatusWrapper>
    </Page>
  );
};
