import { SemanticGridLayout } from "components/shared/constants";

export enum SearchParam {
  id,
  unitOfMeasure,
}

export enum ColumnName {
  Id = "ID",
  UnitOfMeasure = "Unit of Measure",
}

export type VocabularyDisplayProps = {
  label: string;
  description?: string;
};

export const timeSeriesLayout: SemanticGridLayout[] = [
  {
    columnName: ColumnName.Id,
    columnWidth: 12,
  },
  {
    columnName: ColumnName.UnitOfMeasure,
    columnWidth: 4,
  },
];
