/** @jsxImportSource @emotion/react */

import * as O from "fp-ts/lib/Option";
import { generatePath } from "react-router-dom";
import tw from "twin.macro";
import { Path, viewBuildingPath } from "Routes";
import {
  WingId,
  FloorId,
  RoomId,
  EquipmentId,
  PointId,
  ZoneId,
  ModelId,
  Equipment,
  Floor,
  Point,
  Room,
  Wing,
  Zone,
} from "data/brick";
import { OrgId } from "data/Enodia";
import { SitesAndBuildingsResponse } from "data/Mason";
import { ResponsiveWidths, labelOrId } from "components/shared";

export type SiteOrBuildingParams = {
  orgId: string;
  siteId: string;
  buildingId?: string;
};

export const idReplaceRegex = /-/gi;

export type SubLevelNodeName =
  | "wing"
  | "floor"
  | "room"
  | "zone"
  | "equipment"
  | "point";
export type AnyNodeName = "model" | SubLevelNodeName;

export type SubLevelNodeId =
  | WingId
  | FloorId
  | RoomId
  | ZoneId
  | EquipmentId
  | PointId;
export type AnyNodeId = ModelId | SubLevelNodeId;

export type SubLevelNode = Wing | Floor | Room | Zone | Equipment | Point;

export const BuildingModelWidths: {
  tree: ResponsiveWidths;
  component: ResponsiveWidths;
} = {
  tree: {
    computer: 4,
    largeScreen: 5,
    tablet: 6,
    mobile: 8,
  },
  component: {
    computer: 12,
    largeScreen: 11,
    tablet: 10,
    mobile: 8,
  },
};
export type BuildingModel = {
  name: string;
  id: string;
  path?: string;
};
export type SiteModel = {
  id: string;
  name: string;
  path?: string;
  buildings: BuildingModel[];
};
export type OrgModel = {
  id: OrgId;
  name: string;
  sites: SiteModel[];
};

export const sortModelByName = (
  a: OrgModel | SiteModel | BuildingModel,
  b: OrgModel | SiteModel | BuildingModel,
): number => {
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();
  return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
};

const sortSiteById = (a: SiteModel, b: SiteModel) => {
  const nameA = a.id.toUpperCase();
  const nameB = b.id.toUpperCase();
  return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
};

export const createSingleOrgModel = (
  siteList: SitesAndBuildingsResponse[],
  orgId: OrgId,
) => {
  let orgModel: OrgModel = { name: orgId, id: orgId, sites: [] };

  siteList.forEach((site) => {
    const siteId = site.id;
    const siteName = labelOrId(siteId, O.fromNullable(site.label));
    const sitePath = generatePath(Path.ViewSite, {
      orgId: orgId,
      siteId: siteId,
    });

    let siteModel: SiteModel = {
      id: siteId,
      name: siteName,
      path: sitePath,
      buildings: [],
    };
    site.buildings.forEach((building) => {
      const buildingId = building.id;
      const buildingModel: BuildingModel = {
        name: building.label ? building.label : buildingId,
        id: buildingId,
        path: viewBuildingPath(orgId, siteId, buildingId),
      };
      siteModel.buildings.push(buildingModel);
    });
    siteModel.buildings.sort(sortModelByName);
    orgModel.sites.push(siteModel);
  });
  orgModel.sites.sort(sortSiteById);

  return orgModel;
};

export const RdfLink = () => {
  return (
    <a
      href="https://www.w3.org/TR/turtle/"
      target="_blank"
      rel="noopener noreferrer"
      css={tw`underline text-current`}
    >
      RDF
    </a>
  );
};
