import React, { useContext, useEffect, useState } from "react";
import { Divider } from "semantic-ui-react";
import { AuthoritySummary, OrgId, UserId } from "data/Enodia";
import { ApiError } from "data/http";
import {
  PermissionParams,
  RoleSetResponse,
  UserGroupResponse,
  UsersResponse,
  enodiaApi,
} from "data/Enodia";
import { EnodiaOrgContext } from "App";
import { Page, PageTitle, UIStatus } from "components/shared";
import { RoleSets, Users, UserGroups } from "./";
export type UserPermissions = {
  id: UserId;
  roleSets: RoleSetResponse[];
  userGroups: UserGroupResponse[];
  authority?: AuthoritySummary;
};

export const Permissions: React.FunctionComponent = () => {
  const { orgId } = useContext(EnodiaOrgContext);

  const [usersStatus, setUsersStatus] = useState(new UIStatus());
  const [userGroupStatus, setUserGroupStatus] = useState(new UIStatus());
  const [roleSetStatus, setRoleSetStatus] = useState(new UIStatus());

  const [enodiaUserList, setEnodiaUserList] = useState<Array<UserPermissions>>(
    [],
  );
  const [userGroups, setUserGroups] = useState<Array<UserGroupResponse>>([]);
  const [roleSets, setRoleSets] = useState<Array<RoleSetResponse>>([]);

  const permissionsParams: PermissionParams = {
    organisation: orgId,
  };
  const fetchUserList = (orgId: OrgId) => {
    enodiaApi
      .getUsersByOrg(orgId as OrgId)
      .then((permissions: UsersResponse) => {
        const distinctUsers = [
          ...new Map(permissions.users.map((u) => [u.id, u])).values(),
        ];

        const users: UserPermissions[] = distinctUsers.map((u) => {
          const filteredRolesets = permissions.roleSets.filter((rs) =>
            u.roleSetIds.includes(rs.id),
          );
          const filteredUserGroups = permissions.userGroups.filter((ug) =>
            u.userGroupIds.includes(ug.id),
          );
          const filteredAuthority = permissions.authorities.find(
            (authority) => u.authorityId === authority.id,
          );

          return {
            id: u.id,
            roleSets: filteredRolesets,
            userGroups: filteredUserGroups,
            authority: filteredAuthority,
          };
        });
        setEnodiaUserList(users);
        setUsersStatus((prev) => prev.setIndeterminate(false));
      })
      .catch((err) => {
        setUsersStatus(err.message);
      });
  };

  const fetchRoleSets = () => {
    const setRoleSetError = (e: any) =>
      setRoleSetStatus((prev) =>
        prev.setError(
          e.message ?? "An error occurred retrieving roleSet details",
        ),
      );
    setRoleSetStatus((prev) => prev.setIndeterminate(true));

    enodiaApi
      .getRoleSets(permissionsParams)
      .then((roleSets) => {
        setRoleSets(roleSets);
        setRoleSetStatus((prev) => prev.setIndeterminate(false));
      })
      .catch((e) => {
        setRoleSetError(e);
      });
  };

  const fetchUserGroups = () => {
    setUserGroupStatus((prev) => prev.setIndeterminate(true));

    enodiaApi
      .getUserGroups(permissionsParams)
      .then((userGroups) => {
        setUserGroups(userGroups);
        setUserGroupStatus((prev) => prev.setIndeterminate(false));
      })
      .catch((e: ApiError) =>
        setUserGroupStatus((prev) =>
          prev.setError(
            e.message ?? "An error occured retrieving User Groups data",
          ),
        ),
      );
  };

  useEffect(() => {
    if (orgId) {
      fetchUserList(orgId);
      fetchUserGroups();
      fetchRoleSets();
    }
    // only want to fetch the data if the orgid changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  return (
    <Page>
      <PageTitle primaryHeader="Access Permissions" />
      <Users
        userList={enodiaUserList}
        uiStatus={usersStatus}
        roleSets={roleSets}
        userGroups={userGroups}
      />
      <Divider />
      <UserGroups
        userGroups={userGroups}
        userGroupStatus={userGroupStatus}
        roleSets={roleSets}
        userList={enodiaUserList}
        userListStatus={usersStatus}
        roleSetStatus={roleSetStatus}
      />
      <Divider />
      <RoleSets
        roleSets={roleSets}
        userList={enodiaUserList}
        roleSetStatus={roleSetStatus}
        userListStatus={usersStatus}
      />
    </Page>
  );
};
