import { parseJSON } from "fp-ts/lib/Either";

export const validateJSON = (data: string): boolean => {
  let valid = true;
  parseJSON(data, () => {
    valid = false;
  });
  return valid;
};

const validateCSV = (data: string): boolean => {
  const rows = data.split("\n");
  const columns = rows.flatMap((x) => x.split(",").length);
  return columns.every((v) => v === columns[0]);
};

export const validateConfiguration = (
  inputData: string | undefined,
  dataType: string
): boolean => {
  if (inputData === undefined) {
    return false;
  }

  let dt = dataType.toLowerCase();
  try {
    if (dt === "json") {
      return validateJSON(inputData);
    } else if (dt === "csv") {
      return validateCSV(inputData);
    } else {
      return true;
    }
  } catch (e) {
    console.error(`Error validating document of type ${dataType} ${e}`);
    return false;
  }
};
