import React from "react";
import MDEditor, { commands, PreviewType } from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";

type MarkdownProps = {
  markdown?: string;
  setMarkdown?: (_?: string) => void;
  isReadOnly: boolean;
  defaultView?: PreviewType;
};

export const Markdown = ({
  markdown,
  setMarkdown,
  isReadOnly,
  defaultView = "live",
}: MarkdownProps) => {
  const placeholderMarkdown = ``;
  return (
    <div className="markdown-container" data-color-mode="dark">
      {isReadOnly ? (
        <MDEditor.Markdown source={markdown ?? placeholderMarkdown} />
      ) : (
        <MDEditor
          previewOptions={{
            rehypePlugins: [[rehypeSanitize]],
          }}
          toolbarHeight={40}
          commands={[
            commands.bold,
            commands.italic,
            commands.strikethrough,
            commands.hr,
            commands.title,
            commands.divider,
            commands.link,
            commands.quote,
            commands.code,
            commands.codeBlock,
            commands.divider,
            commands.unorderedListCommand,
            commands.orderedListCommand,
            commands.checkedListCommand,
          ]}
          extraCommands={[
            commands.codeEdit,
            commands.codeLive,
            commands.codePreview,
          ]}
          preview={defaultView}
          height={300}
          value={markdown ?? placeholderMarkdown}
          onChange={(value) => setMarkdown && setMarkdown(value ?? "")}
        />
      )}
    </div>
  );
};
