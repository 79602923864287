/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Button, Divider } from "semantic-ui-react";
import tw from "twin.macro";
import { ApiError } from "data/http";
import {
  ApplicationJobstatusResponse,
  InstalledApplicationMetadataResponse,
  InstalledApplicationResponse,
  metisApi,
  StreamInfo,
} from "data/Metis";
import { metisWSApi } from "data/Metis/metisWSApi";
import {
  DATE_TIME_FORMAT_WITH_AMPM,
  DateRange,
  formatDate,
  JobState,
  Page,
  PageTitle,
  PrimaryTitle,
  ProcessingStatusLabel,
  UIStatus,
  UIStatusWrapper,
} from "components/shared";
import {
  getIconByCategory,
  Header,
  Tabs,
} from "components/Applications/shared";
import ApplicationConfiguration from "./Configuration/ApplicationConfiguration";
import ApplicationDetails from "./Details/ApplicationDetails";
import ApplicationSchedule from "./Schedule/ApplicationSchedule";
import ApplicationRecalculateButton from "./ApplicationRecalculationButton";
import ApplicationUninstallModal from "./ApplicationUninstallModal";
import {
  ApplicationDashboard,
  ApplicationDashboardProps,
  extractDateRangeFromURL,
} from ".";

const defaultJobStatus: ApplicationJobstatusResponse = {
  status: JobState.Created,
  runtime: "",
  starttime: new Date(),
};

enum ApplicationTab {
  Dashboard = "dashboard",
  Details = "details",
  Schedule = "schedule",
  Configuration = "configuration",
}

type Params = { applicationId: string };

export const InstalledApplication: React.FC<{}> = () => {
  const location = useLocation();
  const params = useParams<keyof Params>() as Params;
  const applicationId = params.applicationId;

  // API endpoint response setstates
  const [app, setApp] = useState<InstalledApplicationResponse>();
  const [appMetadata, setAppMetadata] =
    useState<InstalledApplicationMetadataResponse>();
  const [jobStatus, setJobStatus] =
    React.useState<ApplicationJobstatusResponse>(defaultJobStatus);
  const [appStreams, setAppStreams] = React.useState<Array<StreamInfo>>([]);
  const [dateRange, setDateRange] = React.useState<DateRange | undefined>();

  // page render states
  const [status, setStatus] = useState<UIStatus>(new UIStatus());
  const [selectedTab, setSelectedTab] = useState<ApplicationTab>(
    ApplicationTab.Dashboard,
  );

  const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false);

  const loadApplication = () => {
    setStatus((prevState) => prevState.setIndeterminate(true));

    const handleErrors = (error: ApiError, detail?: string) => {
      let message = `${error.message || "Error loading application"} ${detail}`;
      setStatus((prevState) => prevState.setError(message));
    };

    metisApi
      .getApplication(applicationId)
      .then(
        (as) => {
          setApp(as);

          // DCH-3148 - Apply default dashboard window
          metisApi.getDashboardDateWindow(applicationId).then(
            (dateRange?: DateRange) => {
              const [from, to] = extractDateRangeFromURL(as.dashboardUrl);
              const defaultDateRange =
                dateRange ||
                ({
                  start: from ? new Date(from) : undefined,
                  end: to ? new Date(to) : undefined,
                } as DateRange);
              setDateRange(() => defaultDateRange);
            },
            (e: ApiError) => {
              handleErrors(e, "- could not gather application date window");
            },
          );
        },
        (e) => {
          handleErrors(e, "- could not gather application");
        },
      )
      .finally(() =>
        setStatus((prevState) => prevState.setIndeterminate(false)),
      );

    metisApi.getApplicationMetadata(applicationId).then(
      (as) => {
        as.configurationDocuments = as.configurationDocuments
          ? as.configurationDocuments
          : [];
        as.runLabel = as.runLabel ? as.runLabel : "Recalculate";
        setAppMetadata(as);
      },
      (e) => {
        handleErrors(e, "- could not gather application metadata");
      },
    );
    metisApi.getApplicationJobStatus(applicationId).then((jobStatus) => {
      setJobStatus(jobStatus);
    });
    metisApi.getApplicationStreams(applicationId).then((applicationStreams) => {
      setAppStreams(Object.values(applicationStreams));
    });

    const jobstatusPollingFallback = () => {
      return metisApi.getApplicationJobStatus(applicationId).then(
        (jobStatus) => {
          setJobStatus(jobStatus);
          return true;
        },
        () => false,
      );
    };

    // On received message, update status of application and update dashboard loading state as required
    const onMessage = function (message: ApplicationJobstatusResponse) {
      setJobStatus(message);
    };

    // Get websocket for the current application
    const websocketConnection = metisWSApi.getApplicationStatus(
      applicationId,
      onMessage,
      jobstatusPollingFallback,
    );

    setStatus((prevState) => prevState.setIndeterminate(false));

    return () => {
      websocketConnection.stopFallback();
      if (websocketConnection.websocketClient) {
        websocketConnection.websocketClient.close();
      }
    };
  };

  // fetch vendor metadata & application list on first load
  useEffect(loadApplication, [applicationId]);

  return (
    <Page hasGoBack backTo={location.state?.["[backTo"]}>
      <PageTitle primaryTitle={PrimaryTitle.InstalledApps} />
      <UIStatusWrapper status={status}>
        {app && jobStatus && appMetadata && (
          <div
            css={tw`flex flex-col gap-4 bg-core-grey-dark rounded p-4`}
            data-test-id={"applicationDetails"}
          >
            <Header
              name={app.name}
              icon={getIconByCategory(app.categories ?? [])}
              subHeader={
                <div css={tw`flex flex-col gap-2`}>
                  <div css={tw`flex flex-row gap-x-2`}>
                    <span>{app.applicationName}</span>
                    <span>|</span>
                    <span>v{app.version}</span>
                  </div>
                  <div css={tw`flex gap-x-2`}>
                    <ProcessingStatusLabel status={jobStatus.status} />
                    <span>
                      {formatDate(
                        jobStatus.starttime,
                        DATE_TIME_FORMAT_WITH_AMPM,
                      )}
                    </span>
                  </div>
                </div>
              }
              actions={
                <Button.Group vertical labeled icon>
                  <ApplicationRecalculateButton
                    applicationUid={app.id}
                    status={status}
                    setStatus={setStatus}
                    runLabel={appMetadata.runLabel ?? "Recalculate"}
                    applicationJobState={jobStatus.status}
                    setJobStatus={setJobStatus}
                    disabled={!appMetadata.canRun}
                  />
                  <Button
                    basic
                    inverted
                    icon="trash"
                    content="Uninstall"
                    onClick={() => setDeleteModalOpen(true)}
                  />
                </Button.Group>
              }
            />
            <ApplicationUninstallModal
              app={app}
              deleteModalOpen={deleteModalOpen}
              setDeleteModalOpen={setDeleteModalOpen}
            />
            <Divider />
            <Tabs<ApplicationTab>
              tabs={Object.values(ApplicationTab)}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
            <ApplicationInformation
              selectedTab={selectedTab}
              app={app}
              dateRange={dateRange}
              setDateRange={setDateRange}
              appStreams={appStreams}
              jobState={jobStatus.status}
              appMetadata={appMetadata}
            />
          </div>
        )}
      </UIStatusWrapper>
    </Page>
  );
};

const ApplicationInformation = ({
  selectedTab,
  app,
  dateRange,
  setDateRange,
  appStreams,
  jobState,
  appMetadata,
}: {
  selectedTab: ApplicationTab;
  appMetadata: InstalledApplicationMetadataResponse;
} & ApplicationDashboardProps) => {
  switch (selectedTab) {
    case ApplicationTab.Details:
      return <ApplicationDetails app={app} />;
    case ApplicationTab.Schedule:
      return <ApplicationSchedule applicationID={app.id} />;
    case ApplicationTab.Configuration:
      return (
        <ApplicationConfiguration
          applicationID={app.id}
          applicationMetadata={appMetadata}
        />
      );
    default:
      return (
        <ApplicationDashboard
          app={app}
          dateRange={dateRange}
          setDateRange={setDateRange}
          appStreams={appStreams}
          jobState={jobState}
        />
      );
  }
};
