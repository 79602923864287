import * as React from "react";
import { InfoHelper } from "components/shared";

export const metricLookup: Record<string, string> = {
  data_min: "Minimum data sample value found within the analysis period.",
  data_max: "Maximum data sample value found within the analysis period.",
  data_mean: "Mean data sample value found within the analysis period.",
  data_mode: "Most common data sample value found within the analysis period.",
  data_std: "Standard deviation of data within the analysis period.",
  data_samples: "Number of data samples found within the analysis period.",
  data_min_time_between_samples:
    "Minimum number of seconds between data same timestamps within the analysis period.",
  data_time_std_between_samples:
    "Standard deviation of time between samples within the analysis period.",
  data_time_coef_variation:
    "Standard deviation of time between samples divided by the mean of time between samples for samples within the analysis period.",
  data_max_time_between_samples:
    "Maximum number of seconds between data same timestamps within the analysis period.",
  sampling_period: "Sampling period detected for the data stream.",
  missing_data_samples:
    "Number of data samples detected as missing per the detected sampling period.",
  missing_percent:
    "Percent of data which is found to be missing (100 * missing_data_samples / data_samples)",
  time_to_detect_missing:
    "Number of seconds taken to calculate the missing data statistics.",
  outlier_samples: "Number of samples detected to be outliers.",
  outlier_samples_under_mean:
    "Number of samples which are under the mean value for the sampling period.",
  outlier_samples_over_mean:
    "Number of samples which are above the mean value for the sampling period.",
  outlier_percent:
    "Percent of data which is determined as outliers (100 * outlier_samples / data_samples)",
  time_to_detect_outliers:
    "Number of seconds taken to calculate the outlier statistics.",
};

export const metricFormatted: Record<string, string> = {
  data_min: "Data Minimum",
  data_max: "Data Maximum",
  data_mean: "Data Mean",
  data_mode: "Data Mode",
  data_std: "Data Std.",
  data_samples: "Number of Samples",
  data_min_time_between_samples: "Minimum Sampling Period (s)",
  data_time_std_between_samples: "Std. Sampling Period (s)",
  data_time_coef_variation: "Std. Sampling Period / Mean Sampling Period (s)",
  data_max_time_between_samples: "Maximum Sampling Period (s)",
  sampling_period: "Detected Sampling Period",
  missing_data_samples: "Number Missing Samples",
  missing_percent: "Missing Percent",
  time_to_detect_missing: "Time to Detect Missing (s)",
  outlier_samples: "Number Outlier Samples",
  outlier_samples_under_mean: "Number of Outlier Samples Under Mean",
  outlier_samples_over_mean: "Number of Outlier Samples Over Mean",
  outlier_percent: "Outlier Percent",
  time_to_detect_outliers: "Time to Detect Outliers (s)",
};

/**
 * Utility function to create the Missing Samples info popup.
 */
export const missingSamplesHelper = () => (
  <InfoHelper
    body={
      <p>
        The number of expected samples to be detected over a time period, minus
        the number of samples actually ingested:
      </p>
    }
    title={<i>Missing Samples</i>}
    equation={
      <>
        <u>Missing Samples</u> = <u>Expected Samples</u> - <u>Actual Samples</u>
      </>
    }
  />
);

/**
 * Utility function to create the Missing Percent info popup.
 */
export const missingPercentHelper = () => (
  <InfoHelper
    title={<i>Missing Percent</i>}
    body={
      <p>
        The percent ratio of missing data samples as compared to the total
        number of samples:
      </p>
    }
    equation={
      <>
        <u>Missing Percent</u> = <u>Missing Samples</u> / <u>Total Samples</u>
      </>
    }
  />
);

/**
 * Utility function to create the Outlier Samples info popup.
 */
export const outlierSamplesHelper = () => (
  <InfoHelper
    title={<i>Outlier Samples</i>}
    body={
      <p>
        The number of samples detected to be an outlier over a time period,
        where an outlier is defined as any sample that exceeds ±3 standard
        deviations (stds) from the mean:
      </p>
    }
    equation={
      <>
        <u>Outlier Samples</u> = Set of Samples :{" "}
        <div>sample ≥ mean + 3 * std</div>
        <div>sample ≤ mean - 3 * std </div>
      </>
    }
  />
);

/**
 * Utility function to create the Outlier Percent info popup.
 */
export const outlierPercentHelper = () => (
  <InfoHelper
    title={<i>Outlier Percent</i>}
    body={
      <p>
        The percent ratio of outlier data samples as compared to the total
        number of samples:
      </p>
    }
    equation={
      <>
        <u>Outlier Percent</u> = <u>Outlier Samples</u> / <u>Total Samples</u>
      </>
    }
  />
);

/**
 * Utility function to create the Period info popup.
 */
export const periodHelper = () => (
  <InfoHelper
    title={<i>Period</i>}
    body={
      <>
        <p>
          The period over which aggregate metrics are calculated. Period
          timestamps represent the <u>time at which the workflow was run</u>.
        </p>
        <p>
          e.g. A workflow executed at 02/01/2021 00:00 to analyse the previous 4
          hours of data would report this data at 02/01/2021 00:00 but would be
          reporting data health metrics based on 01/01/2021 20:00 - 02/01/2021
          00:00.
        </p>
      </>
    }
  />
);

/**
 * Utility function to create the Data Health info popup.
 */
export const dataHealthHelper = () => (
  <InfoHelper
    title={<i>Data Health</i>}
    body={
      <>
        <p>
          The percentage of ingested data samples compared to the number of
          samples expected.{" "}
        </p>{" "}
        <p>
          This metric is the percentage of total data minus the percentage of
          data estimated as missing:
        </p>
      </>
    }
    equation={
      <>
        <u>Data Health</u> = 100 - <u>Missing Percent</u>
      </>
    }
  />
);

export const dataFlowingHelper = () => (
  <InfoHelper
    title="Data Flowing"
    body={
      <p>
        Will be determined as "Yes" if any data has been found upon the last
        Health Check. Otherwise data flowing will be determined as "No".
      </p>
    }
  />
);
