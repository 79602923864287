/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { Button } from "semantic-ui-react";
// import { DateTimeInputProps } from "semantic-ui-calendar-react-17";
import tw, { TwComponent } from "twin.macro";
import { DateInput, isValidDate, PageSection } from "components/shared";
import { FormDateInputProps } from "components/shared/Forms/Inputs";
import Form from "components/shared/Forms/ReactHookForm";
import { Grid3Col } from "../ComponentLibrary";
import { FormFields, InputsProps } from "./Inputs";

const FormDateInputWrapper = (props: {
  formId: string;
  dateInputProps: Omit<FormDateInputProps<FormFields>, "name">;
  InputBox: TwComponent<"div">;
}) => {
  const { formId, dateInputProps, InputBox } = props;
  return (
    <div>
      <InputBox>
        <Form
          formId={formId}
          onSubmit={(data: FormFields) => console.log(data)}
        >
          <Form.DateInput name="date" {...dateInputProps} />
        </Form>
      </InputBox>
      <Button
        size="mini"
        inverted
        basic
        type="submit"
        form={formId}
        style={{ marginTop: ".5rem" }}
      >
        Submit
      </Button>
    </div>
  );
};

export const DateInputs = ({ showBoundaries }: InputsProps) => {
  const InputBox = showBoundaries
    ? tw.div`border border-dashed border-core-grey`
    : tw.div`border border-solid border-transparent`;

  const [dateStr, setDateStr] = useState("");

  const dateProps = {
    value: dateStr,
    onChange: (value: string | null) => {
      if (value && isValidDate(value)) setDateStr(value);
    },
  };

  const getFormDateInput = (
    formId: string,
    dateInputProps: Omit<FormDateInputProps<FormFields>, "name">,
  ) => {
    return (
      <FormDateInputWrapper
        InputBox={InputBox}
        dateInputProps={dateInputProps}
        formId={formId}
      />
    );
  };

  return (
    <>
      <PageSection header="Basic">
        <Grid3Col>
          <div>
            <InputBox>
              <DateInput {...dateProps} />
            </InputBox>
          </div>
          <div>
            <InputBox>
              <DateInput
                {...dateProps}
                placeholder="Should be after now"
                minDate={new Date()}
              />
            </InputBox>
          </div>
          <div>
            <InputBox>
              <DateInput
                {...dateProps}
                placeholder="Should be before now"
                maxDate={new Date()}
              />
            </InputBox>
          </div>
          <div>
            <InputBox>
              <DateInput
                {...dateProps}
                placeholder="In error state"
                error={true}
              />
            </InputBox>
          </div>
          <div>
            <InputBox>
              <DateInput
                {...dateProps}
                placeholder="In error state with message"
                inputValidation={{
                  invalid: true,
                  errorMessage: "This is wrong",
                }}
              />
            </InputBox>
          </div>
          <div></div>
          <div>
            <InputBox>
              <DateInput {...dateProps} label="Labelled" />
            </InputBox>
          </div>
          <div>
            <InputBox>
              <DateInput
                {...dateProps}
                label="Labelled"
                placeholder="Required with custom error message"
                required
                inputValidation={{
                  invalid: true,
                  errorMessage: "This is wrong",
                }}
              />
            </InputBox>
          </div>
          <div>
            <InputBox>
              <DateInput
                {...dateProps}
                value="not a date"
                label="With invalid value"
              />
            </InputBox>
          </div>
          <div>
            <InputBox>
              <DateInput
                {...dateProps}
                value="1 Jan 13:40"
                label="With invalid date"
              />
            </InputBox>
          </div>
          <div>
            <InputBox>
              <DateInput
                {...dateProps}
                label="With explicit validation message"
                inputValidation={{
                  invalid: true,
                  errorMessage: "This is wrong because I said so",
                }}
              />
            </InputBox>
          </div>
          <div>
            <InputBox>
              <DateInput
                {...dateProps}
                value="1 Jan 13:40"
                label="With implicit validation message"
                inputValidation={{
                  invalid: false,
                }}
              />
            </InputBox>
          </div>
        </Grid3Col>
      </PageSection>
      <PageSection header="In a React Hook Form">
        <Grid3Col>
          {getFormDateInput("FormDateInput_1", {})}
          {getFormDateInput("FormDateInput_2", { label: "My Date Label" })}
          {getFormDateInput("FormDateInput_3", {
            label: "With required validation",
            required: true,
          })}
          {getFormDateInput("FormDateInput_4", {
            label: "With min date validation",
            minDate: new Date(),
            placeholder: "Must be after now",
          })}
          {getFormDateInput("FormDateInput_5", {
            label: "With max date validation",
            maxDate: new Date(),
            placeholder: "Must be earlier than now",
          })}
          {getFormDateInput("FormDateInput_6", {
            label: "With required and minDate validations",
            required: true,
            minDate: new Date(),
            placeholder: "Must be after now",
          })}
        </Grid3Col>
      </PageSection>
    </>
  );
};
