import React, { useEffect, useState } from "react";
import { SelectInput, UIStatus } from "components/shared";
import { DropdownItemProps } from "semantic-ui-react";
import { SortOptions, SortOrder, metisApi } from "data/Metis";

type VendorFilterProps = {
  value: string;
  onChange: (e: any, data: any) => void;
  placeholder?: string;
  label?: string;
  verticalLayout?: boolean;
};

export const VendorFilter = (props: VendorFilterProps) => {
  const [vendorOptions, setVendorOptions] = useState<DropdownItemProps[]>([]);
  const [uiStatus, setUiStatus] = useState(new UIStatus());

  //on first load, get list of vendors
  useEffect(() => {
    setUiStatus((prev) => prev.setIndeterminate(true));
    metisApi
      .getVendors({
        sortBy: SortOptions.name,
        sortOrder: SortOrder.ASCENDING,
      })
      .then((res) => {
        setUiStatus((prev) => prev.setIndeterminate(false));
        setVendorOptions(
          res.map((vendor) => ({ value: vendor.uid, text: vendor.name })),
        );
      });
  }, []);
  return (
    <SelectInput
      verticalLayout={props.verticalLayout ?? true}
      label={props.label ?? "Vendor"}
      placeholder={props.placeholder ?? "Filter by Vendor..."}
      options={vendorOptions}
      onChange={props.onChange}
      value={props.value}
      search
      isLoading={uiStatus.indeterminate}
      testId="vendorFilter"
    />
  );
};
