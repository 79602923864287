/** @jsxImportSource @emotion/react */

import * as React from "react";
import { Button, Icon, Modal } from "semantic-ui-react";
import tw from "twin.macro";
import PointData from "components/Data/PointData/PointData";
import { UIStatus, UIStatusWrapper } from "../shared";
import { QueryParams } from "components/SitesAndBuildings/Model/ModelUtils";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { PointResponse, aletheiaApi } from "data/Aletheia";

const MODAL_ID = "DataStreamModal";

type PointDataModalProps = {
  id: string;
  buttonLabel: string | JSX.Element;
  loadOnOpen?: boolean;
  index?: number; // capture .map indexes for multiple stream displays
  isLink?: boolean; //makes it navigateable
};

export const PointDataModal: React.FC<PointDataModalProps> = ({
  id,
  buttonLabel,
  loadOnOpen = true,
  index,
  isLink,
}) => {
  const [open, setOpen] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [status, setStatus] = React.useState<UIStatus>(new UIStatus());
  const [point, setPoint] = React.useState<PointResponse>();

  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(search);
  const nodeId = queryParams.get(QueryParams.id);
  const streamIndex = queryParams.get(QueryParams.streamIndex);
  const originalPath = `${pathname}?id=${nodeId}`;

  // handle history.back() behaviour for when URL doesnt have streamIndex in it
  React.useEffect(() => {
    // isLink declared to indicate that this condition occurs to those that can .back
    if (!streamIndex && open && isLink) {
      setOpen(false);
    }
  }, [isLink, open, streamIndex]);
  // on first render, if it matches the index number of the one in the query, then its opened
  React.useEffect(() => {
    if (streamIndex && parseInt(streamIndex) === index) setOpen(true);
  }, [index, streamIndex]);

  React.useEffect(() => {
    if (!loaded && loadOnOpen && open) {
      setStatus((s) => s.setIndeterminate(true));
      aletheiaApi.getPoint(id).then(
        (s) => {
          setPoint(s);
          setStatus((s) => s.setIndeterminate(false));
          setLoaded(true);
        },
        (e) => {
          setStatus((s) =>
            s.setError(
              e.error ||
                e.message ||
                "An error occurred when loading stream detail"
            )
          );
        }
      );
    }
  }, [id, loadOnOpen, open, setLoaded, loaded]);

  React.useEffect(() => {
    setLoaded(false);
  }, [id]);

  return (
    <Modal
      id={MODAL_ID}
      size="fullscreen"
      dimmer="blurring"
      closeIcon
      onClose={() => {
        setOpen(false);
        if (nodeId) navigate(originalPath);
      }}
      onOpen={() => {
        setOpen(true);
        if (nodeId && index) navigate(`${originalPath}&streamIndex=${index}`);
      }}
      open={open}
      trigger={
        isLink ? (
          <Link
            css={tw`cursor-pointer underline pointer-events-auto block`}
            to={`${originalPath}&streamIndex=${index}`}
          >
            {buttonLabel}
          </Link>
        ) : (
          <span css={tw`font-bold cursor-pointer hover:underline`}>
            {buttonLabel}
          </span>
        )
      }
    >
      <Modal.Header>
        <Icon name={"chart bar"} />
        {id}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <UIStatusWrapper status={status}>
            {point && <PointData point={point} mountNodeId={MODAL_ID} />}
          </UIStatusWrapper>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          type="button"
          primary={false}
          basic
          inverted
          style={{ marginRight: "1rem" }}
          onClick={() => setOpen(false)}
        >
          <Icon name={"times"} />
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
