/** @jsxImportSource @emotion/react */

import tw from "twin.macro";
import React, { useContext, useEffect, useState } from "react";
import { matchPath, useLocation, Navigate, Outlet } from "react-router-dom";
import { isLoginRequired } from "data/auth";
import { AuthContext, AuthorityContext, EnodiaOrgContext } from "App";
import { Path, Paths } from "Routes";
import { LeftNavBar } from "components/shared/Page/LeftNav";
import { UIStatus, UIStatusWrapper } from "components/shared";
import { enodiaApi, InvitationResponse } from "data/Enodia";
import InvitationsModal from "components/Index/Authentication/Invitations/InvitationsModal";

const publicPages = Paths.filter((p) => p?.isPublic).map((p) =>
  p.path.toString()
);

type Props = { children?: any };
export const PrivateRoutesLayout: React.FC<Props> = ({ children }) => {
  const authContext = useContext(AuthContext);
  const { orgId } = useContext(EnodiaOrgContext);

  const { authoritiesBeenSet, setAuthoritiesBeenSet, setCanAssignAuthorities } =
    useContext(AuthorityContext);
  const location = useLocation();
  const [invitations, setInvitations] = useState<InvitationResponse[]>();
  const [invitationStatus, setInvitationStatus] = useState(new UIStatus());

  const isOnPublic =
    publicPages.find((p) => matchPath(p, location.pathname)) !== undefined;

  const loginRequired = isLoginRequired(authContext);
  const isOnAuthenticatedPage = !isOnPublic && !loginRequired;

  useEffect(() => {
    if (
      authContext.state?.authenticated &&
      authContext.state.userInfo?.email &&
      !invitationStatus.successful
    ) {
      setInvitationStatus((prevState) => prevState.setIndeterminate(true));
      enodiaApi
        .getInvitations(authContext.state.userInfo?.email)
        .then((res) => {
          setInvitations(res);
          setInvitationStatus((prevState) => prevState.setIndeterminate(false));
        })
        .catch((errMsg) => {
          setInvitationStatus((prev) => prev.setError(errMsg));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authContext.state?.authenticated, authContext.state?.userInfo]);

  useEffect(() => {
    if (
      authContext.state?.authenticated &&
      authContext.state.userInfo?.email &&
      !authoritiesBeenSet &&
      orgId
    ) {
      enodiaApi
        .getUserAssignmentByOrg(orgId)(authContext.state.userInfo?.email)
        .then((userAssignment) =>
          setCanAssignAuthorities(userAssignment.canAssignAuthorities)
        )
        .then(() => setAuthoritiesBeenSet(true))
        .catch((errMsg) => {
          console.error(errMsg);
        });
    }
  }, [
    authContext.state,
    authoritiesBeenSet,
    orgId,
    setAuthoritiesBeenSet,
    setCanAssignAuthorities,
  ]);

  return authContext.deferRender(
    !isOnAuthenticatedPage ? (
      <Navigate to={Path.Index} replace />
    ) : (
      <UIStatusWrapper status={invitationStatus}>
        <div css={tw`min-h-screen`}>
          {invitations && invitations.length > 0 && (
            <InvitationsModal
              invitations={invitations}
              setInvitations={setInvitations}
            />
          )}

          <div css={tw`flex items-start`}>
            <div
              css={tw`sticky bottom-0 self-end`}
              style={{
                width: "clamp(95px,7%,145px)",
                zIndex: 2000,
              }}
            >
              <LeftNavBar />
            </div>
            <div css={tw`sticky top-0 flex-1`}>{children ?? <Outlet />}</div>
          </div>
        </div>
      </UIStatusWrapper>
    )
  );
};
