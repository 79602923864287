/** @jsxImportSource @emotion/react */

import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import {
  Dropdown,
  Menu,
  Divider,
  Icon,
  SemanticICONS,
  SemanticCOLORS,
} from "semantic-ui-react";
import tw from "twin.macro";
import { Path } from "Routes";
import { ReactComponent as LogoCondensed } from "assets/icons/logoCondensed.svg";
import Logo from "components/shared/Page/Logo";
import { isApplicationDeveloper } from "../utils/usertype-lookup-utils";

interface MenuItem {
  name: string;
  path?: string;
  icon?: SemanticICONS;
  subMenu?: Array<MenuItem>;
  isViewOrEditItem?: boolean;
  disabled?: boolean;
  hidden?: boolean;
}

const menuItems: Array<MenuItem> = [
  { name: "Home", path: Path.Home, icon: "home" },
  { name: "Search", path: Path.Search, icon: "search" },
  {
    name: "Apps",
    subMenu: [
      { name: "Installed Apps", path: Path.Applications },
      { name: "Available Apps", path: Path.AvailableApplications },
      {
        name: "Manage Apps",
        path: Path.ManageApplications,
        hidden: !isApplicationDeveloper(),
      },
      {
        name: "View App",
        path: Path.InstalledApplication,
        isViewOrEditItem: true,
      },
    ],
    icon: "laptop",
  },
  {
    name: "Sites",
    subMenu: [
      {
        name: "View all",
        path: Path.SitesAndBuildings,
      },

      { name: "Add New Site", path: Path.NewSite },
      { name: "Add New Building", path: Path.NewBuilding },
      { name: "View Site", path: Path.ViewSite, isViewOrEditItem: true },
      { name: "Edit Site", path: Path.EditSite, isViewOrEditItem: true },
      {
        name: "View Building",
        path: Path.ViewBuilding,
        isViewOrEditItem: true,
      },
      {
        name: "Edit Building",
        path: Path.EditBuilding,
        isViewOrEditItem: true,
      },
    ],
    icon: "map outline",
  },
  {
    name: "Data",
    subMenu: [
      { name: "Data Sources", path: Path.DataSources },
      { name: "Data Health", path: Path.DataHealth, disabled: true },
      { name: "Point Explorer", path: Path.PointExplorer },
      { name: "Add Data Source", path: Path.NewDataSource },
      {
        name: "View Data Source",
        path: Path.DataSource,
        isViewOrEditItem: true,
      },
      {
        name: "Edit Data Source",
        path: Path.EditDataSource,
        isViewOrEditItem: true,
      },
    ],
    icon: "chart line",
  },
  {
    name: "Tools",
    subMenu: [
      { name: "Generate Model(s)", path: Path.ModelManagement },
      { name: "Point Classifier", path: Path.PointClassifierRoot },
    ],
    icon: "wrench",
  },
  {
    name: "Settings",
    subMenu: [
      { name: "API Keys", path: Path.ApiKeys },
      { name: "Permissions", path: Path.Permissions },
    ],
    icon: "cog",
  },
  {
    name: "Help",
    path: Path.Help,
    icon: "question",
  },
];

const CurrentPageMarker = tw.div`
inline-block
w-3
mr-2
self-stretch
`;

const PageMarker = tw.div`
inline-block
bg-transparent
mr-2
border
border-solid
opacity-0
transition-all
self-stretch
w-3
`;

const renderPageMarker = (isActive: boolean, isSubItem: boolean) =>
  isActive ? (
    <CurrentPageMarker className={"selected-gradient"} />
  ) : (
    <PageMarker
      css={!isSubItem && tw`group-hover:opacity-100`}
      className={isSubItem ? "nested-hover" : ""}
    />
  );

const renderMenuItemContent = (
  isActive: boolean,
  itemName: string,
  icon: SemanticICONS | undefined,
  isSubItem = false,
  activeSubItemName = ""
) => {
  const colour: SemanticCOLORS = isActive ? "purple" : "grey";
  const MenuItemDiv = icon ? MainMenuItemDiv : SubMenuItemDiv;
  return (
    <MenuItemContainerDiv>
      {renderPageMarker(isActive, isSubItem)}
      <MenuItemDiv>
        {icon && (
          <Icon
            className="iconStyle"
            inverted
            size="huge"
            color={colour}
            name={icon}
          />
        )}
        {isActive ? (
          <ActiveItemNameSpan>{itemName}</ActiveItemNameSpan>
        ) : (
          <ItemNameSpan>{itemName}</ItemNameSpan>
        )}
        {activeSubItemName && (
          <React.Fragment>
            <Divider fitted inverted />
            {isActive ? (
              <ActiveSubItemName>{activeSubItemName}</ActiveSubItemName>
            ) : (
              <SubItemName>{activeSubItemName}</SubItemName>
            )}
          </React.Fragment>
        )}
      </MenuItemDiv>
    </MenuItemContainerDiv>
  );
};

export const LeftNavBar: React.FC = () => {
  const location = useLocation();

  const matchActiveRoute = (path?: string) => {
    if (path) {
      return location.pathname.startsWith(path.split(":")[0]);
    } else {
      return false;
    }
  };

  const renderSubmenuItem = (subItem: MenuItem, isFinalIndex: boolean) => {
    const isActiveItem = matchActiveRoute(subItem.path);
    const toPath = subItem.path?.split(":")[0];
    if (subItem.hidden) return;
    return (
      <React.Fragment key={subItem.name}>
        <Dropdown.Item
          as={NavLink}
          to={toPath}
          disabled={subItem.disabled}
          className="nested-hover-container"
        >
          {renderMenuItemContent(isActiveItem, subItem.name, undefined, true)}
        </Dropdown.Item>
        {!isFinalIndex && <Dropdown.Divider />}
      </React.Fragment>
    );
  };

  const renderItemWithSubMenu = (item: MenuItem) => {
    const activeSubmenuItem = item?.subMenu?.find((s) => {
      return matchActiveRoute(s.path);
    });

    return (
      <Dropdown
        item
        key={item.name}
        className="group submenu-container"
        trigger={renderMenuItemContent(
          activeSubmenuItem !== undefined,
          item.name,
          item.icon,
          false,
          activeSubmenuItem?.name
        )}
        icon="caret right"
        style={{ width: "100%" }}
        disabled={item.disabled}
      >
        <Dropdown.Menu>
          {item?.subMenu
            ?.filter((subItem) => !subItem.isViewOrEditItem)
            .map((subItem, index, array) => {
              return renderSubmenuItem(
                subItem,
                index + 1 === array.filter((item) => !item.hidden).length
              );
            })}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const renderMenuItem = (menuItem: MenuItem) => {
    const isActiveItem = matchActiveRoute(menuItem.path);

    return (
      <Menu.Item
        as={NavLink}
        to={menuItem.path}
        active={isActiveItem}
        className="group"
        key={menuItem.name}
      >
        <MenuItemContentDiv>
          {renderMenuItemContent(isActiveItem, menuItem.name, menuItem.icon)}
        </MenuItemContentDiv>
      </Menu.Item>
    );
  };

  const renderMenuHeader = () => {
    return (
      <MenuHeader>
        <Link to={Path.Home}>
          <Logo css={tw`text-primary! text-white`}>
            <LogoCondensed style={{ width: "100%" }} />
          </Logo>
        </Link>
      </MenuHeader>
    );
  };

  return (
    <Menu
      vertical
      inverted
      style={{
        margin: 0,
        width: "unset",
        position: "sticky",
        bottom: 0,
      }}
    >
      <MenuHeaderDiv>
        <div style={{ flex: "1" }}>{renderMenuHeader()}</div>
        <div style={{ flex: "10" }}>
          {menuItems.map((item) =>
            item?.subMenu ? renderItemWithSubMenu(item) : renderMenuItem(item)
          )}
        </div>
      </MenuHeaderDiv>
    </Menu>
  );
};

const ItemNameSpan = tw.span`
  w-full
  text-center
`;

const ActiveItemNameSpan = tw(ItemNameSpan)`
  font-bold
  text-lighter
`;

const SubItemName = tw.span`
  italic
  font-normal
  text-xs
`;

const ActiveSubItemName = tw(SubItemName)`
  font-bold
  text-lighter
`;

const MenuHeader = tw.div`
  mx-3
  my-4
`;

const MenuHeaderDiv = tw.div`
  flex
  flex-col
  min-h-screen
  text-sm
  w-full
`;

const MenuItemContentDiv = tw.div`
  flex
  w-full
`;

const MainMenuItemDiv = tw.div`
  w-full
  text-center
  pr-3
`;

const SubMenuItemDiv = tw(MainMenuItemDiv)`
  flex
  items-center
`;

const MenuItemContainerDiv = tw.div`
  flex
  flex-row
  w-full
  h-full
`;
