/** @jsxImportSource @emotion/react */

import tw from "twin.macro";
import { UIStatus } from "components/shared";
import {
  AppDisplayInfo,
  ApplicationCard,
  ApplicationRow,
  ApplicationsMode,
  AppListView,
} from ".";

interface AppListProps {
  apps: AppDisplayInfo[];
  mode: ApplicationsMode;
  appListView: AppListView;
  uiStatus: UIStatus;
}

export const ApplicationsList = ({
  apps,
  mode,
  appListView,
  uiStatus,
}: AppListProps) => {
  return uiStatus.isTruthy() ? (
    apps.length > 0 ? (
      appListView === AppListView.Grid ? (
        <div css={tw`flex flex-wrap w-full`} className="flex-cards">
          {apps.map((app) => (
            <ApplicationCard
              key={`${app.applicationUid}-card`}
              app={app}
              mode={mode}
            />
          ))}
        </div>
      ) : (
        <div css={tw`flex flex-col gap-4 w-full`}>
          {apps.map((app) => (
            <ApplicationRow
              key={`${app.applicationUid}-row`}
              app={app}
              mode={mode}
            />
          ))}
        </div>
      )
    ) : (
      <>No applications found</>
    )
  ) : (
    <></>
  );
};
