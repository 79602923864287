/** @jsxImportSource @emotion/react */

import React from "react";
import tw, { TwStyle } from "twin.macro";
import { Dimmer, Loader } from "semantic-ui-react";
import {
  ErrorMessage,
  InfoMessage,
  SuccessMessage,
} from "../Components/Messages";
import { UIStatus } from "./UIStatus";

interface UIStatusWrapperProps {
  status: UIStatus;
  emptyDataMsg?: React.ReactNode;
  successfulMsg?: string;
  loadingDataMsg?: string;
  children: React.ReactNode;
  inverted?: boolean;
  clearable?: boolean;
  fitted?: boolean;
  loaderPostion?: "center" | "top";
  afterClear?: () => void;
  errorRenderer?: (error: any) => React.ReactElement;
  customCss?: TwStyle;
  onErrorDismiss?: () => void;
  onOpen?: () => void;
}

export const UIStatusWrapper: React.FC<UIStatusWrapperProps> = ({
  status,
  children,
  emptyDataMsg = "No data found.",
  successfulMsg = "",
  loadingDataMsg = "Loading",
  inverted = false,
  clearable = true,
  fitted = false,
  loaderPostion = "center",
  afterClear,
  errorRenderer,
  customCss,
  onErrorDismiss,
  onOpen,
}) => {
  const [s, setS] = React.useState(status);

  React.useEffect(() => {
    setS(status);
  }, [status, setS]);

  const clear = () => {
    setS(new UIStatus());
    afterClear?.();
    onErrorDismiss?.();
  };

  const dismissable = clearable || !!afterClear || !!onErrorDismiss;
  let content;

  if (s.indeterminate) {
    content = (
      <Loader content={loadingDataMsg} inverted className={loaderPostion} />
    );
  } else if (s.error) {
    content = (
      <ErrorMessage
        css={tw`max-h-90vh overflow-y-auto`}
        color={inverted ? "red" : undefined}
        dismissable={dismissable}
        customDismiss={clear}
      >
        {errorRenderer
          ? errorRenderer(s.error)
          : typeof s.error === "string"
          ? s.error
              .split("\n")
              .map((m: string) => <p key={`error-${m}`}>{m}</p>)
          : "An error occurred."}
      </ErrorMessage>
    );
  } else if (s.empty) {
    content = (
      <InfoMessage color={inverted ? "blue" : undefined}>
        {emptyDataMsg}
      </InfoMessage>
    );
  } else if (s.successful && successfulMsg !== "") {
    content = (
      <SuccessMessage
        color={inverted ? "green" : undefined}
        dismissable={dismissable}
        customDismiss={clear}
        content={successfulMsg}
      />
    );
  }

  content !== undefined && onOpen?.();

  return (
    /**
     * NOTE: Components Dimmer.Dimmable containing the attribute "blurring" will
     * affect the z-index of components containing dropdown selections or text inputs.
     * For further investigation, refer to: /node_modules/semantic_ui_less/definitions/modules/dimmer.less
     * Investigation pending on this matter.
     */
    <Dimmer.Dimmable className={fitted ? "fitted" : ""} css={customCss}>
      <Dimmer active={content !== undefined} inverted={inverted}>
        {content}
      </Dimmer>
      {children}
    </Dimmer.Dimmable>
  );
};
