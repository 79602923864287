import * as O from "fp-ts/lib/Option";
import { OauthTokenResponse } from "./Enodia";
import { Auth0Context } from "components/Index/Authentication/useAuth0Context";

type LocalStorageKeys = {
  idpBearerToken: string;
  idpBearerTokenExp: string;
  enodiaFavouriteOrg: string;
  userPendingAgreements: string;
};

const mkLocalStorageKeys = (prefix: string): LocalStorageKeys => ({
  enodiaFavouriteOrg: `${prefix}-enodia-favourite-org`,
  idpBearerToken: `${prefix}-idp-bearer-token`,
  idpBearerTokenExp: `${prefix}-idp-bearer-token-exp`,
  userPendingAgreements: `${prefix}-last-user-agreements`,
});

export const localStorageKeys = mkLocalStorageKeys(
  process.env.REACT_APP_LOCAL_STORAGE_KEYS_PREFIX ?? ""
);

const getKey = (key: keyof LocalStorageKeys) =>
  O.fromNullable(localStorage.getItem(localStorageKeys[key]));

export const getIdpBearerToken = () => getKey("idpBearerToken");
export const getEnodiaFavouriteOrg = () => getKey("enodiaFavouriteOrg");

export const setFavouriteEnodiaOrg = (orgId: string) => {
  localStorage.setItem(localStorageKeys.enodiaFavouriteOrg, orgId);
};

export const setEnodiaToken = (r: OauthTokenResponse) => {
  // Store the expiry in ms since epoch --- consistent with JS date functions
  const expiry = r.expiry * 1000;

  localStorage.setItem(localStorageKeys.idpBearerToken, r.accessToken);
  localStorage.setItem(localStorageKeys.idpBearerTokenExp, `${expiry}`);
};

export const setUserPendingAgreements = (email: string, count: number) => {
  localStorage.setItem(
    localStorageKeys.userPendingAgreements,
    JSON.stringify({
      email,
      requiredAgreementsLength: count,
    })
  );
};

export type LocalStoragePendingAgreements = {
  email: string;
  requiredAgreementsLength: number;
};

export const getUserPendingAgreements = () => {
  const agreements = localStorage.getItem(
    localStorageKeys.userPendingAgreements
  );
  return agreements
    ? (JSON.parse(agreements) as LocalStoragePendingAgreements)
    : undefined;
};

export const isLoginRequired = (authContext: Auth0Context) => {
  const agreements = getUserPendingAgreements();
  if (agreements) {
    const isTsAndCsAccepted =
      authContext.state?.userInfo?.email === agreements.email &&
      agreements.requiredAgreementsLength === 0;
    return !authContext.state?.authResult?.accessToken || !isTsAndCsAccepted;
  }
  return true;
};
