import React from "react";

interface SvgPinProps {
  title?: string;
  scale?: number;
}

export const SvgPin: React.FC<SvgPinProps> = ({ title, scale = 1 }) => (
  <svg
    height={104 * scale}
    width={68 * scale}
    version="1.1"
    viewBox="0 0 68 104"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title ? <title>{title}</title> : null}
    <defs>
      <linearGradient
        id="linearGradient-1"
        x1="35.9863575%"
        x2="64.5741877%"
        y1="0%"
        y2="100.999999%"
      >
        <stop offset="0%" stopColor="#683F71" />
        <stop offset="100%" stopColor="#C86DD7" />
      </linearGradient>
    </defs>
    <g id="Pin" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path
        id="Combined-Shape"
        d="M31.9820344,94 C31.0656749,93.8324513 15.3587299,59.6000605 11.8115757,49.1196794 C9.78106103,43.1203438 9,35.1517277 9,32.9310368 C9,25.8924966 10.4057879,9 30.789712,9 C55.3909998,9 53.985212,32.9310368 53.985212,32.9310368 C53.6883045,35.4176649 52.8781513,41.644206 50.4707423,49.1196794 C47.0357143,59.7861096 32.8983939,94.166337 31.9820344,94 Z M31.5,45 C37.8512746,45 43,39.8512746 43,33.5 C43,27.1487254 37.8512746,22 31.5,22 C25.1487254,22 20,27.1487254 20,33.5 C20,39.8512746 25.1487254,45 31.5,45 Z"
        fill="url(#linearGradient-1)"
      />
    </g>
  </svg>
);
