import React, { useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { generatePath, useParams } from "react-router-dom";
import { BuildingId, ModelRootType, SiteId } from "data/brick";
import { OrgId } from "data/Enodia";
import { masonApi } from "data/Mason";
import { isValidId } from "data/validation";
import { EnodiaOrgContext } from "App";
import { Path } from "Routes";
import {
  toOptionTypeBase,
  PageMode,
  PageSection,
  UIStatus,
} from "components/shared";
import Form from "components/shared/Forms/ReactHookForm";
import {
  hasAsyncError,
  useAsyncValidate,
} from "components/shared/Forms/useAsyncValidate";
import { SiteOrBuildingParams } from "../Common";
import { BuildingIds } from "./BuildingIdsList";
import { checkSiteOrBuildingId } from "./Validation";

type SiteOrBuildingDetailProps = {
  pageMode?: PageMode;
  modelType: ModelRootType;
  isBuildingDetails?: boolean;
  buildingIds: string[];
};

export const SiteOrBuildingDetails: React.FunctionComponent<
  SiteOrBuildingDetailProps
> = ({
  pageMode = PageMode.edit,
  modelType = ModelRootType.Site,
  isBuildingDetails = false,
  buildingIds,
}) => {
  const [availableSites, setAvailableSites] = useState<Array<SiteId>>([]);
  const [sitesUiStatus, setSitesUiStatus] = useState<UIStatus>(new UIStatus());

  const { orgId } = useContext(EnodiaOrgContext);
  const params = useParams<SiteOrBuildingParams>();
  const { getValues, reset, getFieldState } = useFormContext();

  //fetch list of available sites for buildings
  useEffect(() => {
    if (orgId && isBuildingDetails && pageMode !== PageMode.view) {
      setSitesUiStatus((prevState) => prevState.setIndeterminate(true));
      masonApi
        .getSites({ orgId })
        .then((sites) => setAvailableSites(sites.map((site) => site.siteId)))
        .catch((e) => console.error(`Failed to fetch sites: ${e}`))
        .finally(() =>
          setSitesUiStatus((prevState) => prevState.setIndeterminate(false)),
        );
    }
  }, [isBuildingDetails, orgId, pageMode]);

  //when we've changed context in terms of pageMode or modelType, reset the form
  useEffect(() => {
    if (pageMode === PageMode.create) reset({ siteId: params.siteId ?? "" });
  }, [pageMode, modelType, reset, params.siteId]);

  const validateSiteOrBuildingId = (s: string): Promise<string | undefined> =>
    new Promise((resolve) => {
      if (
        (isBuildingDetails && s !== "" && s !== params.buildingId) ||
        (!isBuildingDetails && s !== "" && s !== params.siteId)
      )
        checkSiteOrBuildingId(
          orgId as OrgId,
          isBuildingDetails ? getValues("siteId") : (s as SiteId),
          resolve,
          isBuildingDetails ? (s as BuildingId) : undefined,
        );
    });

  useAsyncValidate(
    isBuildingDetails ? "buildingId" : "siteId",
    pageMode === PageMode.create
      ? validateSiteOrBuildingId
      : () => Promise.resolve(undefined),
  );

  return (
    <PageSection header={`${modelType} Details`}>
      {isBuildingDetails ? (
        <Form.SelectInput
          name="siteId"
          label="Site ID"
          description={
            pageMode !== PageMode.create
              ? null
              : "Site defined by the organisation selected"
          }
          placeholder="Select site..."
          options={availableSites.map(toOptionTypeBase)}
          required
          isReadOnly={
            pageMode !== PageMode.create || params.siteId !== undefined
          }
          search
          link={() =>
            orgId && getValues("siteId")
              ? generatePath(Path.ViewSite, {
                  orgId: orgId as string,
                  siteId: getValues("siteId"),
                })
              : ""
          }
          isLoading={sitesUiStatus.indeterminate}
        />
      ) : (
        <Form.TextInput
          name="siteId"
          label="Site ID"
          description={
            pageMode !== PageMode.create
              ? null
              : "Site defined by the organisation selected"
          }
          required
          validateOnChange
          rules={{
            validate: {
              isValidId: isValidId,
              hasAsyncError: () => hasAsyncError("siteId", getFieldState),
            },
          }}
          isReadOnly={pageMode !== PageMode.create}
        />
      )}
      {isBuildingDetails && (
        <Form.TextInput
          name="buildingId"
          label={`Building ID`}
          description={
            pageMode !== PageMode.create
              ? null
              : "Building defined by the organisation selected"
          }
          required={pageMode === PageMode.create}
          isReadOnly={pageMode !== PageMode.create}
          validateOnChange
          rules={{
            validate: {
              isValidId: isValidId,
              hasAsyncError: () => hasAsyncError("buildingId", getFieldState),
            },
          }}
        />
      )}
      <Form.TextInput
        name="name"
        label={`${modelType} Name`}
        description={`Descriptive label for the ${modelType}`}
      />
      <Form.TextInput
        name="description"
        label="Description"
        description={`What necessary information would you like to include about the ${modelType}?`}
      />
      {pageMode !== PageMode.create && !isBuildingDetails && (
        <BuildingIds orgId={orgId as OrgId} buildingIds={buildingIds} />
      )}
    </PageSection>
  );
};
