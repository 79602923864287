/** @jsxImportSource @emotion/react */

import tw from "twin.macro";
import React, { useMemo, useState } from "react";
import { Accordion } from "semantic-ui-react";
import { BrickProperty } from "data/Models/Brick/brickRelationships";
import { SelectedNode } from "../Visualisation";
import { BrickClass, FullNodeIdentifier, VizModel } from "../VizModelUtil";

type Props = {
  node: BrickClass;
  vizModel: VizModel;
  setSelectedNode: (_?: SelectedNode) => void;
};

export const getAccordionTitle = (
  relationship: BrickProperty,
  nodeIdentifiers: FullNodeIdentifier[]
) => `${relationship} : ${nodeIdentifiers.length}`;

export const getLabel = (vizModel: VizModel, id: string) => {
  const relation = vizModel.nodes.get(id);
  return relation?.label && relation.label !== ""
    ? relation.label
    : relation?.graphLabel ?? id;
};

const Relationships: React.FunctionComponent<Props> = ({
  node,
  vizModel,
  setSelectedNode,
}) => {
  const [openRows, setOpenRows] = useState<string[]>([]);

  useMemo(() => {
    setOpenRows([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node]);

  const panels = [...node.relationships.entries()].map(
    ([relationship, nodeIdentifiers]: [
      BrickProperty,
      FullNodeIdentifier[]
    ]) => {
      return {
        key: `${node.id}_rel_${relationship}`,
        title: getAccordionTitle(relationship, nodeIdentifiers),
        content: {
          children: nodeIdentifiers.map((nodeIdentifier) => {
            const label = getLabel(vizModel, nodeIdentifier.nodeUri);
            return (
              <div
                key={`${relationship}_${nodeIdentifier.nodeUri}`}
                id={`${relationship}_${nodeIdentifier.nodeUri}`}
                css={tw`truncate underline cursor-pointer`}
                onClick={() =>
                  setSelectedNode({
                    modelReference: nodeIdentifier.modelReference,
                    id: nodeIdentifier.nodeUri,
                    action: "update",
                  })
                }
              >
                {label}
              </div>
            );
          }),
        },
        onTitleClick: () =>
          openRows.includes(relationship)
            ? setOpenRows((prev) => [...prev.filter((r) => r !== relationship)])
            : setOpenRows((prev) => [...new Set([...prev, relationship])]),
        active: openRows.includes(relationship),
      };
    }
  );

  return (
    <div css={tw`mb-4`}>
      <div css={tw`font-bold mb-2`}>Relationships</div>
      <Accordion
        id="RelationshipAccordion"
        styled
        panels={panels}
        inverted
        exclusive={false}
      />
    </div>
  );
};

export default Relationships;
