/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { DropdownItemProps, Grid, Icon, Popup } from "semantic-ui-react";
import tw from "twin.macro";
import { DchClass } from "data/EntityClasses/EntityClassesContext";
import { ClassHypernym, Unit } from "data/Mason";
import {
  BASIC_EP_ATTRIBUTES,
  EntityPropertyAttributeSchema,
  EntityPropertyDefinition,
  getEntityPropertyAttributes,
  useEntityProperties,
} from "data/Mason/EntityProperties";
import {
  LabelledInputLayoutProps,
  convertCamelCaseToTitleCase,
} from "components/shared";
import Form from "components/shared/Forms/ReactHookForm";
import { getFilteredOptionsList } from "components/SitesAndBuildings/Model/ModelUtils";
import { EntityPropertyKeyValue } from "data/Aletheia";
import { ActionButton } from "../ActionButton";
import { EntityPropertyComparisonRow } from "./EntityPropertiesDirectiveUtils";
import { AletheiaEntityPropertyAttribute } from "./AletheiaEntityPropertyAttribute";
import { CommonInputProps } from "components/SitesAndBuildings/Model/Form/EntityForms/CommonInputs";
import { EntityPropertyKeyValueAttribute } from "./EntityPropertyKeyValueAttribute";
import { getEPFieldName } from "./EntityPropertiesClassifierAccordion";

export type EntityPropertyInputProps = {
  hypernym?: ClassHypernym;
  parentClass?: string;
  entityClasses: Map<ClassHypernym, Map<string, DchClass>>;
  propertyIndex: number;
  segmentName: PointClassifierSegment;
  accordionIndex: number; // row of where the parent accordion is initialised
  handleDelete: (_: number) => void;
  comparisonSide?: DirectiveComparisonMode;
  restrictTypeChange?: boolean;
  allowDeleteOnRead?: boolean;
  updateEPCallback?: () => void;
  unitsList: Unit[];
} & CommonInputProps &
  LabelledInputLayoutProps;

export enum DirectiveComparisonMode {
  original = ".original",
  proposed = ".proposed",
}

export enum PointClassifierSegment {
  classifier = ".classifier",
  classificationPlan = ".classificationPlan",
}

export const BRICK_KEY_VALUE = "brick:keyValue";

export const AletheiaEntityPropertyInput = (
  props: EntityPropertyInputProps,
) => {
  const {
    propertyIndex,
    isReadOnly,
    verticalLayout,
    comparisonSide,
    allowDeleteOnRead,
    updateEPCallback,
    accordionIndex,
    segmentName,
    unitsList,
  } = props;
  const entityPropertyName = `${
    segmentName === PointClassifierSegment.classifier
      ? getEPFieldName(accordionIndex)
      : ".properties"
  }.${propertyIndex}${comparisonSide ?? ""}`;

  const { entityPropertyDefinitions } = useEntityProperties();
  const { watch, getValues } = useFormContext();

  const entityProperty: EntityPropertyKeyValue = getValues(entityPropertyName);
  const [schema, setSchema] = useState<EntityPropertyDefinition>();

  const [attributes, setAttributes] = useState<EntityPropertyAttributeSchema[]>(
    [],
  );
  const watchSchemaDefinition = watch(`${entityPropertyName}.ep_type`);
  const schemaIdRef = useRef("");

  const originalValueName = `.properties.${propertyIndex}${DirectiveComparisonMode.original}`;
  const watchOriginalValue = watch(originalValueName);

  //if we update the entity property type, or once the definitions are fetched, set the id, schema and attributes for the EP
  useEffect(() => {
    if (
      schemaIdRef.current !== watchSchemaDefinition &&
      entityPropertyDefinitions
    ) {
      const schemaDefinition = entityPropertyDefinitions.get(
        watchSchemaDefinition,
      );

      if (schemaDefinition) {
        schemaIdRef.current = watchSchemaDefinition;
        setSchema(schemaDefinition);
      }
      if (schemaDefinition) {
        setAttributes(
          getEntityPropertyAttributes(
            schemaDefinition.json_schema,
            schemaDefinition.schema,
          ),
        );
      }
    }
  }, [watchSchemaDefinition, entityPropertyDefinitions]);

  if (entityProperty !== undefined) {
    return isReadOnly ? (
      <ReadOnlyEntityProperty
        entityPropertyName={entityPropertyName}
        schemaName={schema?.name}
        verticalLayout={verticalLayout}
        propertyIndex={propertyIndex}
        handleDelete={props.handleDelete}
        allowDeleteOnRead={allowDeleteOnRead}
        accordionIndex={accordionIndex}
        segmentName={segmentName}
      />
    ) : (
      <EditableEntityProperty
        {...props}
        entityPropertyName={entityPropertyName}
        attributes={attributes}
        schema={schema}
        schemaIdRef={schemaIdRef.current}
        restrictTypeChange={!!watchOriginalValue}
        comparisonSide={comparisonSide}
      />
    );
  }
  if (entityProperty === undefined && !isReadOnly) {
    return (
      <EditableEntityProperty
        {...props}
        entityPropertyName={entityPropertyName}
        attributes={attributes}
        schema={schema}
        schemaIdRef={schemaIdRef.current}
        restrictTypeChange={false}
        unitsList={unitsList}
      />
    );
  }
  return (
    <ReaddEntityProperty
      entityPropertyName={entityPropertyName}
      propertyIndex={propertyIndex}
      comparisonSide={comparisonSide}
      updateEPCallback={updateEPCallback}
    />
  );
};

const EditableEntityProperty = ({
  entityPropertyName,
  attributes,
  hypernym,
  parentClass,
  handleDelete,
  entityClasses,
  propertyIndex,
  schema,
  schemaIdRef,
  restrictTypeChange,
  comparisonSide,
  unitsList,
  accordionIndex,
}: EntityPropertyInputProps & {
  entityPropertyName: string;
  attributes: EntityPropertyAttributeSchema[];
  schema?: EntityPropertyDefinition;
  schemaIdRef: string;
}) => {
  const [options, setOptions] = useState<DropdownItemProps[]>([]);
  const { watch, setValue, getValues } = useFormContext();

  const { entityPropertiesList } = useEntityProperties();
  const entityProperty: EntityPropertyKeyValue = getValues(entityPropertyName);
  const watchProperties:
    | EntityPropertyComparisonRow[]
    | EntityPropertyKeyValue[] = watch(".properties");
  const watchClassifierProperties: EntityPropertyKeyValue[] = watch(
    `.classifiers.${accordionIndex}.executeRules.entityProperties.value`,
  );

  // on first load and on type change, load the options accordingly
  useEffect(() => {
    // if its already been declared on the page, we want to prevent it from being declared again.
    let declaredTypes: (string | undefined)[];

    if (comparisonSide) {
      declaredTypes = (
        (watchProperties as EntityPropertyComparisonRow[]) ?? []
      ).map((ep: EntityPropertyComparisonRow) => ep.proposed?.ep_type);
    } else {
      //in classifier segment
      declaredTypes = (
        (watchClassifierProperties as EntityPropertyKeyValue[]) ?? []
      ).map((ep: EntityPropertyKeyValue) => ep.ep_type);
    }

    const exclusionTypes: (string | undefined)[] = declaredTypes.filter(
      (type: string | undefined) =>
        type !== undefined &&
        type !== "" &&
        type !== BRICK_KEY_VALUE &&
        type !== entityProperty?.ep_type,
    );

    if (entityPropertiesList.length > 0 && entityClasses.size > 0) {
      const filteredOptions =
        parentClass && hypernym && hypernym !== ClassHypernym.Zone //Zone not currently supported
          ? getFilteredOptionsList(
              parentClass,
              hypernym,
              entityPropertiesList,
              entityClasses,
            ).filter(
              (dropdown) => !exclusionTypes.includes(dropdown.value as string),
            )
          : entityPropertiesList.map((ep) => ({ value: ep.id, text: ep.name }));
      setOptions(filteredOptions);
    }
  }, [
    comparisonSide,
    entityClasses,
    entityPropertiesList,
    entityProperty?.ep_type,
    hypernym,
    parentClass,
    watchClassifierProperties,
    watchProperties,
  ]);

  const attributeSchemaDefinitionIdfRef = useRef(schemaIdRef);
  const watchSchemaDefinition = watch(`${entityPropertyName}.ep_type`);

  useEffect(() => {
    if (
      schemaIdRef &&
      watchSchemaDefinition === schemaIdRef &&
      !attributeSchemaDefinitionIdfRef.current
    ) {
      attributeSchemaDefinitionIdfRef.current = schemaIdRef;
    } else if (
      schemaIdRef &&
      watchSchemaDefinition === schemaIdRef &&
      ((attributeSchemaDefinitionIdfRef.current &&
        attributeSchemaDefinitionIdfRef.current !== schemaIdRef) ||
        (!entityProperty.ep_type && !attributeSchemaDefinitionIdfRef.current))
    ) {
      /**
       * react-hook-form doesn't handle undefined values
       * We set values that are common across some types to "" to avoid persistent values on ep_type change
       * These persistent values are only displayed on the form, but does not retain an actual value- hence a visual rerendering is needed via setValue
       */
      BASIC_EP_ATTRIBUTES.forEach((attribute) => {
        setValue(`${entityPropertyName}.${attribute}`, "");
      });

      const newProperty: EntityPropertyKeyValue | undefined = schema
        ? {
            ep_type: schema.id,
          }
        : undefined;
      setValue(entityPropertyName, newProperty);

      attributeSchemaDefinitionIdfRef.current = schemaIdRef;
    }
  }, [
    entityProperty,
    entityPropertyName,
    schema,
    schemaIdRef,
    setValue,
    watchSchemaDefinition,
  ]);

  /**
   * setting key with type forces a rerender on different input types
   */
  const attributeKeyName = (attribute: EntityPropertyAttributeSchema) => {
    let type = attribute.type?.[0];
    const watchHasUnit = watch(`${entityPropertyName}.hasUnit`);
    if (
      watchSchemaDefinition === BRICK_KEY_VALUE &&
      attribute.id === "value" &&
      watchHasUnit
    ) {
      type = "number";
    } else {
      type = "string";
    }

    return `${entityPropertyName}_attribute_${attribute.id}_${type}`;
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={14}>
          <Form.SelectInput
            name={`${entityPropertyName}.ep_type`}
            label={!restrictTypeChange && "Type"}
            options={options}
            search
            verticalLayout
            required
            isClearable={false}
            isReadOnly={restrictTypeChange}
          />
        </Grid.Column>
        <Grid.Column textAlign="right" width={2}>
          <Popup
            inverted
            hoverable
            trigger={
              <Icon
                name="trash"
                onClick={() => handleDelete(propertyIndex)}
                style={{ cursor: "pointer" }}
              />
            }
            content="Delete this entity property"
            position="top left"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row verticalAlign="bottom" css={{ paddingTop: "0 !important" }}>
        {attributes.map((attribute) => (
          <Grid.Column key={attributeKeyName(attribute)} width={5}>
            {watchSchemaDefinition === BRICK_KEY_VALUE ? (
              <EntityPropertyKeyValueAttribute
                attribute={attribute}
                entityPropertyFieldName={entityPropertyName}
                unitsList={unitsList}
              />
            ) : (
              <AletheiaEntityPropertyAttribute
                attribute={attribute}
                entityPropertyFieldName={entityPropertyName}
              />
            )}
          </Grid.Column>
        ))}
      </Grid.Row>
    </Grid>
  );
};

const ReadOnlyEntityProperty = ({
  entityPropertyName,
  schemaName,
  verticalLayout,
  handleDelete,
  propertyIndex,
  allowDeleteOnRead,
  accordionIndex,
  segmentName,
}: {
  entityPropertyName: string;
  schemaName?: string;
  verticalLayout?: boolean;
  handleDelete: (_: number) => void;
  propertyIndex: number;
  allowDeleteOnRead?: boolean;
  accordionIndex: number;
  segmentName: string;
}) => {
  const propCss = verticalLayout ? tw`flex flex-col` : tw`flex flex-wrap`;
  const { getValues } = useFormContext();
  const entityProperty: EntityPropertyKeyValue = getValues(entityPropertyName);
  return (
    entityProperty && (
      <div>
        <div css={tw`font-bold break-words pb-1`}>
          {schemaName ??
            convertCamelCaseToTitleCase(
              removeBrickPrefix(entityProperty.ep_type),
            )}
        </div>
        <div css={propCss}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={14}>
                {Object.keys(entityProperty)
                  .filter((key) => key !== "ep_type")
                  .map((key) => (
                    <div
                      key={`${segmentName}${accordionIndex}${entityPropertyName}-${key}-title`}
                    >
                      {convertCamelCaseToTitleCase(key)}:{" "}
                      <span> {entityProperty[key]}</span>
                    </div>
                  ))}
              </Grid.Column>
              {allowDeleteOnRead && (
                <Grid.Column textAlign="right" width={2}>
                  <Popup
                    inverted
                    hoverable
                    trigger={
                      <Icon
                        name="trash"
                        onClick={() => handleDelete(propertyIndex)}
                        style={{ cursor: "pointer" }}
                      />
                    }
                    content="Delete this entity property"
                    position="top left"
                  />
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
        </div>
      </div>
    )
  );
};

const ReaddEntityProperty = ({
  entityPropertyName,
  propertyIndex,
  comparisonSide,
  updateEPCallback,
}: {
  entityPropertyName: string;
  propertyIndex: number;
  comparisonSide?: DirectiveComparisonMode;
  updateEPCallback?: () => void;
}) => {
  // assuming this message only shows up when theres a removal, we want to clone the original value to the RHS
  const { setValue, getValues } = useFormContext();

  const originalFieldName = `.properties.${propertyIndex}${DirectiveComparisonMode.original}`;
  const originalValue = getValues(originalFieldName);

  const cloneOriginalValue = () => {
    setValue(entityPropertyName, originalValue);
    if (updateEPCallback) updateEPCallback();
  };
  return comparisonSide === DirectiveComparisonMode.original ? (
    <div></div>
  ) : (
    <div css={tw`flex flex-col`}>
      <div>
        <i>Entity property removed</i>
      </div>
      <ActionButton
        icon="add"
        secondary
        label={"Re-add"}
        onClick={() => cloneOriginalValue()}
      />
    </div>
  );
};

const removeBrickPrefix = (epType: string) => {
  return epType.replace("brick:", "");
};
