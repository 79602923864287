import { createContext, useContext } from "react";
import { CategoryResponse } from "./metisTypes";

type ApplicationCategoriesContextType = {
  categories: Map<string, CategoryResponse>;
  setCategories: (_: Map<string, CategoryResponse>) => void;
};

export const ApplicationCategoriesContext =
  createContext<ApplicationCategoriesContextType>({
    categories: new Map(),
    setCategories: () => {},
  });

export const useApplicationCategoriesContext = () =>
  useContext(ApplicationCategoriesContext);
