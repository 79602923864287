import { SemanticTEXTALIGNMENTS, SemanticWIDTHS } from "semantic-ui-react";

/** EXTERNAL URLs */
export const DOCUMENTATION_URL = "https://docs.dataclearinghouse.org";
export const TERMS_CONDITIONS_URL = `${DOCUMENTATION_URL}/v/terms-of-service/`;

/** MODAL MESSAGES */
export type ModalMessage = {
  header: string;
  content: string;
};
export const changeOrgNoResetMessage = {
  header: "Confirm Organisation Change",
  content: "Are you sure you want to change your organisation?",
};

export const resetMessage = {
  header: "Confirm Form Reset",
  content:
    "Your form is incomplete. Are you sure you want to change your organisation and reset information related to it?",
};

export const redirectMessage = {
  header: "Confirm page redirect",
  content:
    "You have changed your organisation. Are you sure you want to leave the page?",
};
export const EMPTY_USERGROUPS = "No User Groups Provided";
export const EMPTY_ROLESETS = "No Rolesets Provided";
export const EMPTY_DESCRIPTION = "No description provided";
export const EMPTY_NAME = "No name provided";
export const UNSPECIFIED_STRING_MSG = "Not specified";
export const EMPTY_LIST_MSG = "No data found";
export const EMPTY_ENTITY_PROPERTIES_MSG = "No Entity Properties found";
export const EMPTY_APPLICATIONS_MSG = "No applications provided";
export const EMPTY_OVERVIEW_MSG = "No overview provided";

export type ResponsiveWidths = {
  computer: SemanticWIDTHS;
  largeScreen: SemanticWIDTHS;
  tablet: SemanticWIDTHS;
  mobile: SemanticWIDTHS;
};

export type InputWidths = {
  label: ResponsiveWidths;
  input: ResponsiveWidths;
};

export const DEFAULT_INPUT_WIDTHS: InputWidths = {
  label: {
    computer: 4,
    largeScreen: 4,
    tablet: 8,
    mobile: 16,
  },

  input: {
    computer: 12,
    largeScreen: 12,
    tablet: 8,
    mobile: 16,
  },
};

export const FULL_SCREEN_WIDTHS: InputWidths = {
  label: {
    computer: 16,
    largeScreen: 16,
    tablet: 16,
    mobile: 16,
  },

  input: {
    computer: 16,
    largeScreen: 16,
    tablet: 16,
    mobile: 16,
  },
};

export enum StrengthCategory {
  strong = "strong",
  neutral = "neutral",
  weak = "weak",
}

export enum PageMode {
  view = "View",
  edit = "Edit",
  create = "Create new",
}

export enum IndexPageMode {
  signup,
  login,
  resetPassword,
}

export type SemanticGridLayout = {
  columnName: string;
  columnWidth: SemanticWIDTHS;
  headerAlign?: SemanticTEXTALIGNMENTS;
  contentAlign?: SemanticTEXTALIGNMENTS;
};
