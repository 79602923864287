/** @jsxImportSource @emotion/react */

import { useContext, useEffect, useState } from "react";
import * as O from "fp-ts/Option";
import "leaflet/dist/leaflet.css";
import { Grid, Divider, Dimmer, List } from "semantic-ui-react";
import tw from "twin.macro";
import { OrgId } from "data/Enodia";
import { SitesAndBuildingsResponse, masonApi } from "data/Mason";
import { EnodiaOrgContext } from "App";
import { Path } from "Routes";
import { labelOrId, quickMenuItem } from "components/shared/Common";
import { Page, PageTitle, UIStatus, UIStatusWrapper } from "components/shared";
import {
  SvgPin,
  SvgIconLabel,
  SvgBuildings,
} from "components/SitesAndBuildings/svg";
import { SitesMap } from "components/SitesAndBuildings/Metdata/Map/SitesMap";
import { createSingleOrgModel } from "./Common";
import SitesAndBuildingsList from "./SitesAndBuildingsList";

const SVG_SCALE = 0.5;

export const getSiteDisplayLabel = (s: SitesAndBuildingsResponse) =>
  labelOrId(s.id, O.fromNullable(s.label));

const SitesAndBuildings = () => {
  const { orgId } = useContext(EnodiaOrgContext);
  const [uiStatus, setUIStatus] = useState(new UIStatus());

  const [sitesAndBuildingsModel, setSitesAndBuildingsModel] = useState<
    SitesAndBuildingsResponse[]
  >([]);

  const [cache, setCache] = useState(
    new Map<OrgId, SitesAndBuildingsResponse[]>()
  );

  useEffect(() => {
    if (orgId) {
      const cachedOrgSitesAndBuildings = cache.get(orgId);
      if (!cachedOrgSitesAndBuildings) {
        setUIStatus((prevState) => prevState.setIndeterminate(true));
        masonApi
          .getSitesAndBuildings({ orgId })
          .then((res: SitesAndBuildingsResponse[]) => {
            setSitesAndBuildingsModel(res);
            setCache((prev) => prev.set(orgId, res));
            setUIStatus((prevState) => prevState.setIndeterminate(false));
          })
          .catch((e) =>
            setUIStatus((prevState) =>
              prevState.setError(
                e.message || "Unable to retrieve sites and buildings."
              )
            )
          );
      } else {
        //use data from the cache
        setSitesAndBuildingsModel(cachedOrgSitesAndBuildings);
        setUIStatus((prevState) => prevState.setIndeterminate(false));
      }
    }
  }, [cache, orgId]);

  const noOfSites = sitesAndBuildingsModel.length;

  const noOfBuildings = sitesAndBuildingsModel.reduce((a, siteAndBuilding) => {
    return a + siteAndBuilding.buildings.length;
  }, 0);

  return (
    <Page>
      <PageTitle
        primaryHeader="Sites and Buildings"
        subHeader="Create and Manage"
      />
      <Dimmer.Dimmable
        blurring
        dimmed={!orgId}
        className={"fitted"}
        css={tw`flex-col-fit z-0`}
      >
        <Dimmer active={!orgId}>
          <div>Select an organisation to view sites and building list</div>
        </Dimmer>
        <div css={tw`h-full overflow-y-hidden`}>
          <UIStatusWrapper status={uiStatus} customCss={tw`h-full z-0`}>
            <Grid celled="internally" style={{ height: "100%" }}>
              <Grid.Row style={{ maxHeight: "100%" }}>
                <Grid.Column width={12}>
                  <Grid columns="equal" verticalAlign="middle">
                    <Grid.Row>
                      <Grid.Column>
                        <SvgIconLabel
                          icon={<SvgPin scale={SVG_SCALE} />}
                          label={`${noOfSites} sites`}
                        />
                      </Grid.Column>

                      <Grid.Column>
                        <SvgIconLabel
                          icon={<SvgBuildings scale={SVG_SCALE} />}
                          label={`${noOfBuildings} buildings`}
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column>
                        <h2>Navigate Sites and Buildings</h2>
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column>
                        <SitesMap
                          sites={sitesAndBuildingsModel}
                          loading={uiStatus.indeterminate}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>

                <Grid.Column
                  width={4}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <List>
                    {quickMenuItem(Path.NewSite, "Add New Site")}
                    {quickMenuItem(Path.NewBuilding, "Add New Building")}
                  </List>
                  <Divider />
                  <div css={tw`flex flex-col flex-col-fit`}>
                    <h4>Manage Sites and Buildings</h4>
                    <SitesAndBuildingsList
                      sitesUiStatus={uiStatus}
                      orgId={orgId}
                      sitesAndBuildingsList={
                        orgId
                          ? createSingleOrgModel(sitesAndBuildingsModel, orgId)
                              .sites
                          : []
                      }
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </UIStatusWrapper>
        </div>
      </Dimmer.Dimmable>
    </Page>
  );
};

export default SitesAndBuildings;
