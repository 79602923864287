import { EnodiaOrgContext } from "App";
import { DchModal, ModalMessage } from "components/shared";
import React, { useContext } from "react";

type ModalProps = {
  open: boolean;
  openCondition: boolean;
  onConfirm: () => void;
  modalMessage: ModalMessage;
  setModalState: (_: boolean) => void;
};

const OrgContextModal = (props: ModalProps) => {
  const { orgId, setOrgId, prevOrg } = useContext(EnodiaOrgContext);

  const handleClose = () => {
    props.setModalState(false);
    setOrgId(prevOrg.current);
  };

  // captures org change after first render
  const firstUpdate = React.useRef(true);
  React.useLayoutEffect(() => {
    // skips renders if both refs are not initialised i.e. page reload
    if (firstUpdate.current || prevOrg.current === undefined) {
      firstUpdate.current = false;
      return;
    }

    if (props.openCondition && prevOrg.current !== orgId) {
      props.setModalState(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  return (
    <DchModal
      open={props.open}
      onClose={handleClose}
      onCancel={handleClose}
      onConfirm={() => {
        props.onConfirm();
        props.setModalState(false);
      }}
      header={props.modalMessage.header}
      content={props.modalMessage.content}
      confirmText="Confirm"
    />
  );
};

export default OrgContextModal;
