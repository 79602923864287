import { aletheiaEnv } from "reducers/env";
import { ClientEnv } from "data/utils";
import {
  ClassifierSetListParams,
  ClassifierSetListItem,
  ClassifierSetId,
  ClassifierSetResponse,
  ClassifierSetUpdateRequest,
  ClassifierSetCreateRequest,
  ClassificationPlanCreateRequest,
  ClassificationPlanResponse,
  ClassificationDirectiveResponse,
  ClassificationPlanId,
  ClassificationDirectiveId,
  ProposedDirectivePutRequest,
  GetDirectivesParams,
} from "./aletheiaTypes";
import * as O from "fp-ts/lib/Option";
import { httpDelete, httpGet, httpPost, httpPut } from "data/httpUtil";
import { PaginatedResponse } from "data/paginationHelper";
import { httpGetPaginatedList } from "./aletheiaApi";

/**
 *  Point Management & Classification of Points API ENDPOINTS
 * AKA. Aletheia
 * POINT CLASSIFIER SUBSET OF ENDPOINTS
 * @link https://develop.dataclearinghouse.org/api/aletheia/v1/swagger#/
 */
type PointClassifierApi = {
  getClassifierSets: (
    params: ClassifierSetListParams
  ) => Promise<Array<ClassifierSetListItem>>;
  getClassifierSetById: (
    classifierSetId: ClassifierSetId
  ) => Promise<ClassifierSetResponse>;
  postClassifierSet: (
    request: ClassifierSetCreateRequest
  ) => Promise<ClassifierSetResponse>;
  putClassifierSet: (
    classifierSetId: ClassifierSetId
  ) => (
    classifierSet: ClassifierSetUpdateRequest
  ) => Promise<ClassifierSetResponse>;
  deleteClassifierSet: (classifierSetId: ClassifierSetId) => Promise<void>;
  purgeAllClassifiers: (classifierSetId: ClassifierSetId) => Promise<void>;
  postClassificationPlan: (
    request: ClassificationPlanCreateRequest
  ) => Promise<ClassificationPlanResponse>;
  deleteClassificationPlan: (
    classificationPlanId: ClassificationPlanId
  ) => Promise<void>;
  clearClassificationPlanManualChanges: (
    classificationPlanId: ClassificationPlanId
  ) => Promise<void>;
  getDirectives: (
    classificationPlanId: ClassificationPlanId
  ) => (
    params: GetDirectivesParams
  ) => Promise<PaginatedResponse<Array<ClassificationDirectiveResponse>>>;
  putDirective: (
    classificationPlanId: ClassificationPlanId
  ) => (
    directiveId: ClassificationDirectiveId
  ) => (
    request: ProposedDirectivePutRequest
  ) => Promise<ClassificationDirectiveResponse>;
  applyClassificationPlan: (
    classificationPlanId: ClassificationPlanId
  ) => Promise<void>;
};

export const mkPointClassifierApi = (env: ClientEnv): PointClassifierApi => ({
  getClassifierSets: (params) =>
    httpGet(O.none)(env)(params)(`/classifiersets`).then(
      (r) => r as Array<ClassifierSetListItem>
    ),
  getClassifierSetById: (classifierSetId) =>
    httpGet(O.none)(env)(null)(`/classifiersets/${classifierSetId}`).then(
      (r) => r as ClassifierSetResponse
    ),
  postClassifierSet: (request) =>
    httpPost(request)(O.none)(env)(null)("/classifiersets").then(
      (r) => r as ClassifierSetResponse
    ),
  putClassifierSet: (classifierSetId) => (classifierSet) =>
    httpPut(classifierSet)(O.none)(env)(null)(
      `/classifiersets/${classifierSetId}`
    ).then((r) => r as ClassifierSetResponse),
  deleteClassifierSet: (classifierSetId) =>
    httpDelete(O.none)(env)(null)(`/classifiersets/${classifierSetId}`).then(
      (_) => undefined
    ),
  purgeAllClassifiers: (classifierSetId) =>
    httpPost(null)(O.none)(env)(null)(
      `/classifiersets/${classifierSetId}/purge`
    ).then((_) => undefined),
  postClassificationPlan: (request) =>
    httpPost(request)(O.none)(env)(null)(`/classificationplans`).then(
      (r) => r as ClassificationPlanResponse
    ),
  deleteClassificationPlan: (classificationPlanId) =>
    httpDelete(O.none)(env)(null)(
      `/classificationplans/${classificationPlanId}`
    ).then((_) => undefined),
  clearClassificationPlanManualChanges: (classificationPlanId) =>
    httpPost({})(O.none)(env)(null)(
      `/classificationplans/${classificationPlanId}/clear_manual_change`
    ).then((_) => undefined),
  getDirectives: (classificationPlanId) => (params) =>
    httpGetPaginatedList(O.none)(env)(params)(
      `/classificationplans/${classificationPlanId}/directives`
    ).then((r) => r as PaginatedResponse<ClassificationDirectiveResponse[]>),
  putDirective: (classificationPlanId) => (directiveId) => (request) =>
    httpPut(request)(O.none)(env)(null)(
      `/classificationplans/${classificationPlanId}/directives/${directiveId}`
    ).then((r) => r as ClassificationDirectiveResponse),
  applyClassificationPlan: (classificationPlanId) =>
    httpPost(null)(O.none)(env)(null)(
      `/classificationplans/${classificationPlanId}/apply`
    ).then((_) => undefined),
});

export const pointClassifierApi = mkPointClassifierApi(aletheiaEnv);
