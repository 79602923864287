/** @jsxImportSource @emotion/react */
import React from "react";
import { Table } from "semantic-ui-react";
import { SelectInput, toOptionTypeBase } from "components/shared";
import { DropdownOptionsSelector } from "./OptionsSelector";
import { ScopeTypePermissionsPropsType } from "./Common";

type OrganisationPermissionsProps = ScopeTypePermissionsPropsType;

export const OrganisationPermissions: React.FunctionComponent<
  OrganisationPermissionsProps
> = ({ role, onChange, orgId, isReadOnly, optionsStatus, verbOptions }) => {
  return (
    <>
      <Table.Cell width={3}>
        <SelectInput
          testId="OrganisationSelect"
          isReadOnly={isReadOnly}
          value={orgId}
          options={orgId ? [toOptionTypeBase(orgId)] : []}
          isMulti={false}
          required={true}
          disabled={true}
        />
      </Table.Cell>
      <DropdownOptionsSelector
        role={role}
        onChange={onChange}
        isReadOnly={isReadOnly}
        optionsStatus={optionsStatus}
        verbOptions={verbOptions}
      />
    </>
  );
};
