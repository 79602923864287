import React from "react";
import { Divider } from "semantic-ui-react";
import { MarkdownInput } from "components/shared";
import { ApplicationImages } from "components/Applications/shared";
import { AvailableApplicationDetails } from "data/Metis";

export const ApplicationOverview = ({
  app,
}: {
  app: AvailableApplicationDetails;
}) => {
  return (
    <div>
      <MarkdownInput
        value={
          app.overview && app.overview !== ""
            ? app.overview
            : "No overview provided."
        }
        isReadOnly
      />
      <Divider />
      <h3>Application Screenshots</h3>
      <ApplicationImages
        appId={app.id}
        screenshotCount={app.screenshotCount}
        isReadOnly
      />
    </div>
  );
};
