/* @jsxImportSource @emotion/react */

import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { Table } from "semantic-ui-react";
import tw from "twin.macro";
import {
  AcceptAgreementRequest,
  enodiaApi,
  InvitationResponse,
} from "data/Enodia";
import {
  DchModal,
  formatDate,
  sortOrganisationList,
  UIStatus,
  UIStatusWrapper,
} from "components/shared";
import { EnodiaOrgContext } from "App";
import {
  InvitationState,
  groupInvitationsByOrg,
  AcceptRejectButtons,
  InvitationStateById,
  RequiredAgreements,
  InvitationDescriptionRow,
  DescriptionType,
  acceptAgreementPromise,
  acceptInvitePromise,
  filterOutRejectedInvitationIds,
} from "./InvitationModalHelper";
import { REJECTED_PROMISE } from "data/constants";

type InvitationsModalProps = {
  invitations: InvitationResponse[];
  setInvitations: Dispatch<SetStateAction<InvitationResponse[] | undefined>>;
};

const InvitationsModal: React.FunctionComponent<InvitationsModalProps> = ({
  invitations,
  setInvitations,
}) => {
  const { setOrgList } = useContext(EnodiaOrgContext);

  /**
   * ON CLICK BEHAVIORS
   */
  const onModalClose = () => {
    setInvitations(undefined);
    enodiaApi.getOrgs().then((orgList) => {
      setOrgList(sortOrganisationList(orgList));
    });
  };

  const groupedInvitations = groupInvitationsByOrg(invitations);

  return (
    <DchModal
      content={
        <div
          data-test-id={`invitations-modal-content`}
          css={tw`overflow-y-scroll max-h-70vh p-4 flex flex-col gap-y-4`}
          className="dch-scrollbar"
        >
          {Object.entries(groupedInvitations).map(
            ([orgName, invitations]: [string, InvitationResponse[]]) => (
              <InvitationsByOrg
                key={orgName}
                orgName={orgName}
                invitations={invitations}
                invitationLength={(groupedInvitations[orgName] ?? []).length}
              />
            ),
          )}
        </div>
      }
      header="Invitations"
      open={!!invitations}
      onClose={onModalClose}
      onCancel={onModalClose}
      cancelText="Close"
      hideConfirm
      size="large"
    />
  );
};
export default InvitationsModal;

const InvitationsByOrg = ({
  orgName,
  invitations,
  invitationLength,
}: {
  orgName: string;
  invitations: InvitationResponse[];
  invitationLength: number;
}) => {
  let globalInvitationCounter = 0;
  const [status, setStatus] = useState(new UIStatus());
  const [selectAllState, setSelectAllState] = useState<InvitationState>();
  const [invitationState, setInvitationState] = useState<InvitationStateById>(
    invitations.reduce<InvitationStateById>((acc, invitation) => {
      acc[invitation.id] = undefined;
      return acc;
    }, {}),
  );
  const [agreementsAccepted, setAgreementsAccepted] = useState<number>(0);

  const requiredAgreementsCount = invitations.reduce((total, inv) => {
    return total + (inv.requiredAgreements ?? []).length;
  }, 0);

  const getPendingInvitationIds = () => {
    let pendingInvitationIds = [];
    for (const [key, state] of Object.entries(invitationState)) {
      if (state === undefined) {
        pendingInvitationIds.push(key);
      }
    }
    return pendingInvitationIds;
  };

  const onAcceptAll = () => {
    const handleRejectedAgreementsList = (rejected: string[]) => {
      rejected.forEach((rejected) => {
        setInvitationState((p) => ({
          ...p,
          [rejected as keyof InvitationStateById]: InvitationState.Rejected,
        }));
      });
    };

    const acceptAllAgreementsPromise = () => {
      const allAgreements = invitations.flatMap((inv) => {
        return inv.requiredAgreements.map((agreement) => ({
          invitationId: inv.id,
          agreementId: agreement.agreementId,
          orgId: inv.organisation.id,
        }));
      });

      return Promise.allSettled(
        allAgreements.map((agreement) =>
          acceptAgreementPromise(
            agreement.invitationId,
            agreement.agreementId,
            agreement.orgId,
          ),
        ),
      ).then((results) => {
        const rejected: string[] = results
          .filter((r) => r.status === REJECTED_PROMISE)
          .map((x) => (x as PromiseRejectedResult).reason as string);
        handleRejectedAgreementsList(rejected);

        return rejected;
      });
    };

    const acceptAllInvitesPromise = (invitationIds: string[]) => {
      return (
        Promise.allSettled(invitationIds.map(acceptInvitePromise))
          .then((results) => {
            // handle the expired invitations by rejecting & display rejects via label
            const rejected: string[] = results
              .filter((r) => r.status === REJECTED_PROMISE)
              .map((x) => (x as PromiseRejectedResult).reason as string);
            handleRejectedAgreementsList(rejected);

            setStatus((p) => p.setIndeterminate(false));
          })
          // rest of the accepted cases will have the behaviour of a disabled button
          .then(() => setSelectAllState(InvitationState.AcceptedAll))
          .catch((e) => {
            setStatus((p) => p.setError(e.message));
          })
      );
    };

    setStatus((p) => p.setIndeterminate(true));
    // handle agreements acceptance > invitationAcceptance
    if (
      requiredAgreementsCount === agreementsAccepted &&
      requiredAgreementsCount > 0
    ) {
      acceptAllAgreementsPromise().then((exclusionsList) =>
        acceptAllInvitesPromise(
          filterOutRejectedInvitationIds(
            getPendingInvitationIds(),
            exclusionsList,
          ),
        ),
      );
    } else {
      acceptAllInvitesPromise(getPendingInvitationIds());
    }
  };

  const onRejectAll = () => {
    Promise.allSettled(
      getPendingInvitationIds().map(enodiaApi.declineInvitation),
    )
      .then(() => {
        setStatus((p) => p.setIndeterminate(false));
        setSelectAllState(InvitationState.RejectedAll);
      })
      .catch((e) => {
        setStatus((p) => p.setError(e.message));
      });

    setStatus((p) => p.setIndeterminate(true));
  };

  return (
    <UIStatusWrapper status={status}>
      <div key={`org-header-${orgName}`} data-test-id={`org-header-${orgName}`}>
        <div css={tw`flex justify-between`}>
          <div css={tw`flex-1`}>
            <h3 css={tw`inline-block pr-2 mb-4`}> {orgName} </h3>
            <span>
              {invitationLength} pending invitation
              {invitationLength > 0 ? "s" : ""}
            </span>
          </div>
          <AcceptRejectButtons
            isAcceptDisabled={requiredAgreementsCount !== agreementsAccepted}
            invitationState={selectAllState}
            onAccept={() => onAcceptAll()}
            onReject={() => onRejectAll()}
            isSelectAll
          />
        </div>
        {invitations.map((invitation, index) => (
          <InvitationCard
            isAllSelected={!!selectAllState}
            key={`invitation-card-${invitation.id}`}
            invitation={invitation}
            invitationCounter={++globalInvitationCounter}
            invitationState={invitationState[invitation.id]}
            setInvitationState={setInvitationState}
            setAgreementsAccepted={setAgreementsAccepted}
          />
        ))}
      </div>
    </UIStatusWrapper>
  );
};

const InvitationCard = ({
  isAllSelected,
  invitation,
  invitationCounter,
  invitationState,
  setInvitationState,
  setAgreementsAccepted,
}: {
  isAllSelected: boolean;
  invitation: InvitationResponse;
  invitationCounter: number;
  invitationState: InvitationState | undefined;
  setInvitationState: Dispatch<SetStateAction<InvitationStateById>>;
  setAgreementsAccepted: Dispatch<SetStateAction<number>>;
}) => {
  const [status, setStatus] = useState(new UIStatus());
  const [termsAgreed, setTermsAgreed] = useState<
    Map<string, AcceptAgreementRequest>
  >(new Map());

  const onRejectInvitation = () => {
    enodiaApi
      .declineInvitation(invitation.id)
      .then(() => {
        setStatus((p) => p.setIndeterminate(false));
        setInvitationState((p) => ({
          ...p,
          [invitation.id]: InvitationState.Rejected,
        }));
      })
      .catch((e) => setStatus((p) => p.setError(e.message)));
  };

  const onAcceptInvitation = () => {
    const acceptAgreementPromise = () =>
      enodiaApi
        .postAcceptAgreements(Array.from(termsAgreed.values()))
        .catch((e) => setStatus((p) => p.setError(e.message)));

    const acceptInvitationPromise = () =>
      enodiaApi
        .acceptInvitation(invitation.id)
        .then(() => {
          setInvitationState((p) => ({
            ...p,
            [invitation.id]: InvitationState.Accepted,
          }));
        })
        .then(() => setStatus((p) => p.setIndeterminate(false)))
        .catch((e) => setStatus((p) => p.setError(e.message)));

    // implied that if they can click the button, then the checkbox is ticked (they acknowledge agreement)
    setStatus((p) => p.setIndeterminate(true));
    if (termsAgreed.size > 0) {
      Promise.all([acceptAgreementPromise(), acceptInvitationPromise()]).catch(
        (e) => setStatus((p) => p.setError(e.message)),
      );
    } else {
      acceptInvitationPromise();
    }
  };

  return (
    <div
      key={invitation.id}
      css={tw`rounded-md border-2 border-solid mb-4 border-white`}
      data-test-id={`invitation-card-${invitation.id}`}
    >
      <div
        css={tw`flex justify-between items-center p-2 min-h-12 bg-core-grey-dark rounded-t-md`}
      >
        <div css={tw`flex items-center`}>
          <span>
            <h4
              css={tw`inline-block pr-2`}
            >{`Invitation ${invitationCounter}`}</h4>
          </span>
          <span>expires {formatDate(invitation.expires)}</span>
        </div>
        <UIStatusWrapper status={status}>
          <AcceptRejectButtons
            isBothDisabled={!!isAllSelected}
            isAcceptDisabled={
              invitation.requiredAgreements &&
              termsAgreed.size !== invitation.requiredAgreements.length
            }
            invitationState={invitationState}
            onReject={() => onRejectInvitation()}
            onAccept={() => onAcceptInvitation()}
          />
        </UIStatusWrapper>
      </div>
      <div css={tw`px-4`}>
        {invitation.requiredAgreements &&
          invitation.requiredAgreements.length > 0 && (
            <RequiredAgreements
              authorityName={invitation.assignedAuthority.title}
              agreements={invitation.requiredAgreements}
              invitationId={invitation.id}
              setAgreementsAccepted={setAgreementsAccepted}
              termsAgreed={termsAgreed}
              setTermsAgreed={setTermsAgreed}
              orgId={invitation.organisation.id}
            />
          )}
        <Table inverted basic="very">
          <Table.Body>
            <InvitationDescriptionRow
              descriptions={invitation.userGroups ?? []}
              type={DescriptionType.UserGroup}
              disabled={
                invitation.requiredAgreements &&
                termsAgreed.size !== invitation.requiredAgreements.length
              }
            />
            <InvitationDescriptionRow
              descriptions={invitation.roleSets}
              type={DescriptionType.RoleSet}
              disabled={
                invitation.requiredAgreements &&
                termsAgreed.size !== invitation.requiredAgreements.length
              }
            />
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};
