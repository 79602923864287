/** @jsxImportSource @emotion/react */

import React, { useCallback } from "react";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { List, Icon, Divider } from "semantic-ui-react";
import tw from "twin.macro";
import { BuildingId, SiteId } from "data/brick";
import { OrgId } from "data/Enodia";
import { ClassHypernym, masonApi } from "data/Mason";
import { QueryInvocation, QueryResponse } from "data/QueryApi/queryApiTypes";
import { ModelReference, NodeUriType } from "data/QueryApi/queryTypes";
import { createSearchQueryDef } from "components/Search/Queries";
import { FilterInput, UIStatus, UIStatusWrapper } from "components/shared";
import { extractNodeIdFromUri } from "../ModelUtils";
import { SelectedNode } from "./Visualisation";
import { MODEL_NODE_NAME } from "data/QueryApi/queryApiUtils";

export type Params = {
  orgId: OrgId;
  siteId: SiteId;
  buildingId: BuildingId;
};
type SearchListItem = {
  id?: NodeUriType;
  modelReference: ModelReference;
  type?: string;
  hypernym?: ClassHypernym;
};
type Props = {
  setSearchOpen: (_: boolean) => void;
  searchOpen: boolean;
  setSearchClicked: (_: boolean) => void;
  searchClicked: boolean;
  onClear: () => void;
  setSelectedNode: (_?: SelectedNode) => void;
  selectedSearchResult?: NodeUriType;
  setSelectedSearchResult: (_?: NodeUriType) => void;
};
export const VisualisationSearchPanel: React.FunctionComponent<Props> = ({
  setSearchOpen,
  searchOpen,
  setSearchClicked,
  searchClicked,
  onClear,
  setSelectedNode,
  selectedSearchResult,
  setSelectedSearchResult,
}) => {
  const NO_CLASSIFICATION = "Unclassified";

  const { orgId, siteId, buildingId } = useParams<Params>();
  const [status, setStatus] = React.useState(new UIStatus());
  const [searchTerm, setSearchTerm] = React.useState("");
  const [searchResults, setSearchResults] = React.useState<
    Map<string, SearchListItem[]> | undefined
  >(undefined);

  const searchApi = React.useCallback(async () => {
    const model = [
      {
        orgId: orgId as OrgId,
        siteId: siteId as SiteId,
        buildingId: buildingId as BuildingId,
      },
    ];

    if (searchTerm) {
      const query: QueryInvocation = {
        models: model,
        queryDef: createSearchQueryDef(searchTerm),
      };
      setStatus((prev) => prev.setIndeterminate(true));
      masonApi
        .postSelectQuery(query)
        .then((queryResponse: QueryResponse) => {
          let tmpMap = new Map();
          const models = queryResponse.models;
          for (const nodeValue of queryResponse.solutionNodes[
            MODEL_NODE_NAME
          ]) {
            const entryModelReference = models[nodeValue.modelIndex];
            const newSearchRes = {
              type: nodeValue.type && nodeValue.type.replace(/_/g, " "),
              id: nodeValue.fullId,
              modelReference: entryModelReference,
              hypernym: nodeValue.hypernym as ClassHypernym,
            };

            // under the assumption that all the solution node have types
            // if not the case, can be addressed in the future

            if (nodeValue.type) {
              tmpMap.set(
                nodeValue.type,
                tmpMap.get(nodeValue.type)
                  ? [...tmpMap.get(nodeValue.type), newSearchRes]
                  : [newSearchRes]
              );
            } else {
              // categorise those without a type under its own key
              // presumption that all nodes are already classified, but added as a safety net
              tmpMap.set(
                NO_CLASSIFICATION,
                tmpMap.get(NO_CLASSIFICATION)
                  ? [...tmpMap.get(NO_CLASSIFICATION), newSearchRes]
                  : [newSearchRes]
              );
            }
          }
          setSearchResults(tmpMap);
          setStatus((prev) => prev.setIndeterminate(false));
          setStatus((prevState) =>
            prevState.setEmpty(
              _.isEmpty(queryResponse.solutionNodes[MODEL_NODE_NAME])
            )
          );
        })
        .catch((e) => setStatus((prevState) => prevState.setError(e.message)));
    }
  }, [buildingId, orgId, searchTerm, siteId]);

  // feed in the list using query api
  React.useMemo(() => {
    searchApi();
  }, [searchApi]);

  const handleSearch = (term: string) =>
    setSearchTerm(term.trim().toLowerCase());

  const handleClear = useCallback(() => {
    setSearchResults(undefined);
    if (searchClicked) {
      onClear();
    }
  }, [onClear, searchClicked]);

  const renderSearchList = () => {
    return (
      <UIStatusWrapper
        status={status}
        loadingDataMsg="Loading Data"
        clearable
        fitted
        emptyDataMsg="No matching results found"
      >
        <List.List>
          {searchResults &&
            [...searchResults.keys()].map((key: string) => {
              return (
                <div key={key}>
                  <b>{key.replace(/_/g, " ")}</b>
                  {searchResults.get(key)?.map((searchResultItem) => {
                    return (
                      <List.Item key={searchResultItem.id} css={tw`mb-2 pl-4`}>
                        {searchResultItem.id === selectedSearchResult ? (
                          <span css={tw`font-bold bg-primary break-words`}>
                            {selectedSearchResult &&
                              extractNodeIdFromUri(selectedSearchResult)}
                          </span>
                        ) : (
                          <span
                            css={tw`cursor-pointer hover:text-lighter hover:font-bold hover:animate-pulse break-words`}
                            onClick={() => {
                              if (searchResultItem.id) {
                                setSelectedNode({
                                  id: searchResultItem.id,
                                  modelReference:
                                    searchResultItem.modelReference,
                                  action: "replace",
                                });
                                setSearchClicked(true);
                                setSelectedSearchResult(searchResultItem.id);
                              }
                            }}
                          >
                            {searchResultItem.id &&
                              extractNodeIdFromUri(searchResultItem.id)}
                          </span>
                        )}
                      </List.Item>
                    );
                  })}
                </div>
              );
            })}
        </List.List>
      </UIStatusWrapper>
    );
  };

  const minimisedSearchIcon = () => (
    <div
      id="VisualisationSearchOpen"
      css={tw`p-2 border border-white border-solid rounded bg-black flex-wrap cursor-pointer`}
      onClick={() => {
        setSearchOpen(true);
      }}
    >
      <Icon name="search" />
      <Icon name="caret down" />
      {searchClicked && (
        <span css={tw`font-medium`}>{selectedSearchResult}</span>
      )}
    </div>
  );

  const MinimiseBar = () => (
    <div
      id="VisualisationSearchClose"
      css={tw`w-full flex flex-col cursor-pointer`}
      onClick={() => {
        setSearchOpen(false);
      }}
    >
      <div css={tw`w-full`}>
        <Icon name="search" />
        <Icon name="caret up" />
      </div>

      <Divider fitted inverted css={tw`text-white`} />
    </div>
  );

  return searchOpen ? (
    <div css={tw`h-full p-4 top-0 left-0 w-1/4 absolute`}>
      <div
        css={tw`h-full p-2 rounded bg-black border border-white border-solid relative flex flex-col`}
      >
        <div css={tw`flex flex-initial flex-col`}>
          <MinimiseBar />
          <FilterInput
            handleSearch={handleSearch}
            onClear={handleClear}
            value={searchTerm}
          />
        </div>

        <div className="dch-scrollbar" css={tw`flex-1 overflow-auto`}>
          {renderSearchList()}
        </div>
      </div>
    </div>
  ) : (
    <div css={tw` p-4 absolute top-0 left-0`}>{minimisedSearchIcon()}</div>
  );
};
