import React, { useEffect, useState } from "react";
import { FieldValues } from "react-hook-form";
import { Button } from "semantic-ui-react";
import { ModelRootType, UidType } from "data/brick";
import { ApiError } from "data/http";
import {
  DCHJsonClassResponse,
  SchemaTypeRequestParams,
  masonApi,
} from "data/Mason";
import { ModelMeta } from "data/Mason/ModelDraft/ModelDraftUtils";
import {
  UIStatus,
  UIStatusWrapper,
  capitaliseFirstCharacter,
} from "components/shared";
import Form from "components/shared/Forms/ReactHookForm";
import { AnyNodeName } from "components/SitesAndBuildings/Common";
import { renderEntityListLabel } from "../ModelFormUtils";
import { ModelFormFields } from "../SiteOrBuildingModelForm";
import { useFormValues } from "../useFormValues";

export type CommonInputProps = {
  isReadOnly?: boolean;
  objectName?: ModelRootType | AnyNodeName;
  formFieldName: keyof ModelFormFields;
  index?: number;
  currentEntityId?: UidType;
};

/***** ID *****/
export const Id = (props: CommonInputProps) => {
  const { isReadOnly, objectName, formFieldName, index } = props;
  const indexIdentifier = index !== undefined ? `.${index}` : "";
  return (
    <Form.TextInput
      name={`${formFieldName}${indexIdentifier}.fieldId`}
      label={`${capitaliseFirstCharacter(objectName as string)} ID`}
      description={
        !isReadOnly &&
        `System identifier for the ${objectName} (contact us if this needs to be modifed)`
      }
      isReadOnly
    />
  );
};

/***** NAME *****/
export const Name = (props: CommonInputProps) => {
  const { isReadOnly, objectName, formFieldName, index } = props;
  const indexIdentifier = index !== undefined ? `.${index}` : "";
  return (
    <Form.TextInput
      name={`${formFieldName}${indexIdentifier}.name`}
      label="Name"
      description={isReadOnly ? null : `Name or label for the ${objectName}`}
      isReadOnly={isReadOnly}
    />
  );
};

/***** Type *****/
export const TypeInput = (
  props: CommonInputProps & { hierarchyParams: SchemaTypeRequestParams },
) => {
  const { isReadOnly, objectName, formFieldName, index, hierarchyParams } =
    props;
  const indexIdentifier = index !== undefined ? `.${index}` : "";
  const [types, setTypes] = useState<Array<DCHJsonClassResponse>>([]);
  const [typesStatus, setTypesStatus] = useState(new UIStatus());

  useEffect(() => {
    if (types.length === 0 && !typesStatus.indeterminate) {
      setTypesStatus((p) => p.setIndeterminate(true));
      masonApi.getSchemaTypes(hierarchyParams).then(
        (l) => {
          setTypes(l);
          setTypesStatus((p) => p.setIndeterminate(false));
        },
        (e: ApiError) =>
          setTypesStatus((prevState) =>
            prevState.setError(
              e.message || `Could not fetch ${objectName} types`,
            ),
          ),
      );
    }
  }, [hierarchyParams, objectName, types.length, typesStatus.indeterminate]);

  return (
    <UIStatusWrapper status={typesStatus}>
      <Form.SelectInput
        name={`${formFieldName}${indexIdentifier}.type`}
        label="Type"
        description={
          isReadOnly ? null : `Type classification of the ${objectName}`
        }
        options={types.map((a) => ({
          value: a.type,
          text: a.label,
        }))}
        isReadOnly={isReadOnly}
        isLoading={typesStatus.indeterminate}
        required
        search
      />
    </UIStatusWrapper>
  );
};

/***** WINGS *****/
export const Wing = (props: CommonInputProps) => {
  const { isReadOnly, formFieldName, index } = props;
  const indexIdentifier = index !== undefined ? `.${index}` : "";
  const { wingOptions } = useFormValues();
  return (
    <Form.SelectInput
      name={`${formFieldName}${indexIdentifier}.wing`}
      label="Wing"
      options={wingOptions}
      isReadOnly={isReadOnly}
      link={(value) => `?id=${value}`}
    />
  );
};

/***** FLOORS *****/
export const Floor = (props: CommonInputProps) => {
  const { isReadOnly, formFieldName, index } = props;
  const indexIdentifier = index !== undefined ? `.${index}` : "";
  const { floorOptions } = useFormValues();
  return (
    <Form.SelectInput
      name={`${formFieldName}${indexIdentifier}.floor`}
      label="Floor"
      options={floorOptions}
      isReadOnly={isReadOnly}
      link={(value) => `?id=${value}`}
    />
  );
};
export const Floors = (props: CommonInputProps) => {
  const { isReadOnly, formFieldName, index } = props;
  const indexIdentifier = index !== undefined ? `.${index}` : "";
  const { floorOptions } = useFormValues();
  return (
    <Form.SelectInput
      name={`${formFieldName}${indexIdentifier}.floors`}
      label="Floors"
      isMulti
      options={floorOptions}
      isReadOnly={isReadOnly}
      renderLabel={renderEntityListLabel(!!isReadOnly)}
    />
  );
};

/***** ROOMS *****/
export const Rooms = (props: CommonInputProps) => {
  const { isReadOnly, formFieldName, index } = props;
  const indexIdentifier = index !== undefined ? `.${index}` : "";
  const { roomOptions } = useFormValues();
  return (
    <Form.SelectInput
      name={`${formFieldName}${indexIdentifier}.rooms`}
      label="Rooms"
      isMulti
      options={roomOptions}
      isReadOnly={isReadOnly}
      renderLabel={renderEntityListLabel(!!isReadOnly)}
    />
  );
};

/***** LOCATIONS *****/
export const Locations = (props: CommonInputProps, modelMeta: ModelMeta) => {
  const { isReadOnly, objectName, formFieldName, index } = props;
  const indexIdentifier = index !== undefined ? `.${index}` : "";
  const { locationOptions } = useFormValues(modelMeta);

  return (
    <Form.SelectInput
      name={`${formFieldName}${indexIdentifier}.locations`}
      label="Locations"
      description={
        isReadOnly ? null : `Where the ${objectName} is physically located`
      }
      isMulti
      options={locationOptions}
      isReadOnly={isReadOnly}
      renderLabel={renderEntityListLabel(!!isReadOnly)}
    />
  );
};

/***** PARTS *****/
export const Parts = (props: CommonInputProps) => {
  const { isReadOnly, objectName, formFieldName, index, currentEntityId } =
    props;
  const indexIdentifier = index !== undefined ? `.${index}` : "";
  const { equipmentOptions } = useFormValues();
  return (
    <Form.SelectInput
      name={`${formFieldName}${indexIdentifier}.parts`}
      label="Parts"
      description={isReadOnly ? null : `Components of the ${objectName}`}
      isMulti
      options={equipmentOptions.filter((o) => o.value !== currentEntityId)}
      isReadOnly={isReadOnly}
      renderLabel={renderEntityListLabel(!!isReadOnly)}
    />
  );
};

/***** PART OF *****/
export const PartOf = (props: CommonInputProps) => {
  const { isReadOnly, objectName, formFieldName, index, currentEntityId } =
    props;
  const indexIdentifier = index !== undefined ? `.${index}` : "";
  const { equipmentOptions } = useFormValues();
  return (
    <Form.SelectInput
      name={`${formFieldName}${indexIdentifier}.partOf`}
      label="Part Of"
      description={
        isReadOnly
          ? null
          : `${capitaliseFirstCharacter(
              objectName as string,
            )} that this is a component of`
      }
      isMulti
      options={equipmentOptions.filter((o) => o.value !== currentEntityId)}
      isReadOnly={isReadOnly}
      renderLabel={renderEntityListLabel(!!isReadOnly)}
    />
  );
};

/***** LOCATION OF *****/
export const LocationOf = (props: CommonInputProps) => {
  const { isReadOnly, objectName, formFieldName, index } = props;
  const indexIdentifier = index !== undefined ? `.${index}` : "";
  const { equipmentOptions } = useFormValues();
  return (
    <Form.SelectInput
      name={`${formFieldName}${indexIdentifier}.locationOf`}
      label="Location Of"
      description={
        isReadOnly
          ? null
          : `Equipment with which the ${objectName} is associated`
      }
      isMulti
      options={equipmentOptions}
      isReadOnly={isReadOnly}
      renderLabel={renderEntityListLabel(!!isReadOnly)}
    />
  );
};

/***** FEEDS *****/
export const Feeds = (props: CommonInputProps) => {
  const { isReadOnly, formFieldName, index, objectName, currentEntityId } =
    props;
  const indexIdentifier = index !== undefined ? `.${index}` : "";
  const { feedsOptions } = useFormValues();
  return (
    <Form.SelectInput
      name={`${formFieldName}${indexIdentifier}.feeds`}
      label="Feeds"
      description={
        !isReadOnly &&
        `Downstream equipment, locations and zones that the ${objectName} services`
      }
      isMulti
      options={feedsOptions.filter((o) => o.value !== currentEntityId)}
      isReadOnly={isReadOnly}
      renderLabel={renderEntityListLabel(!!isReadOnly)}
    />
  );
};

/***** IS FED BY *****/
export const IsFedBy = (props: CommonInputProps) => {
  const { isReadOnly, formFieldName, index, currentEntityId } = props;
  const indexIdentifier = index !== undefined ? `.${index}` : "";
  const { equipmentOptions } = useFormValues();
  return (
    <Form.SelectInput
      name={`${formFieldName}${indexIdentifier}.isFedBy`}
      label="Is Fed By"
      isMulti
      options={equipmentOptions.filter((o) => o.value !== currentEntityId)}
      isReadOnly={isReadOnly}
      renderLabel={renderEntityListLabel(!!isReadOnly)}
    />
  );
};

/***** POINTS *****/
export const Points = (props: CommonInputProps) => {
  const { isReadOnly, formFieldName, index } = props;
  const indexIdentifier = index !== undefined ? `.${index}` : "";
  const { pointOptions } = useFormValues();
  return (
    <Form.SelectInput
      name={`${formFieldName}${indexIdentifier}.points`}
      label="Points"
      isMulti
      options={pointOptions}
      isReadOnly={true} //made temporarily readonly under DCH-4598 (points not yet supported on MASON)
      readOnlyEmptyText="Not currently supported" //made temporarily readonly under DCH-4598 (points not yet supported on MASON)
      renderLabel={renderEntityListLabel(!!isReadOnly)}
    />
  );
};

/***** COMMENT *****/
const commentValidator = <TFieldValues extends FieldValues>(
  s?: TFieldValues[keyof TFieldValues],
) => {
  const regex = /^.{0,1000}$/;
  return !s || regex.test(s)
    ? undefined
    : "Comment must not be longer than 1000 characters";
};

export const Comment = (props: CommonInputProps) => {
  const { isReadOnly, objectName, formFieldName, index } = props;
  const indexIdentifier = index !== undefined ? `.${index}` : "";
  return (
    <Form.TextInput
      name={`${formFieldName}${indexIdentifier}.comment`}
      label="Comment"
      description={
        isReadOnly
          ? null
          : `Additional description relating to the ${objectName ?? "entity"}`
      }
      isReadOnly={isReadOnly}
      rules={{ validate: commentValidator }}
    />
  );
};

export const DeleteButton = ({
  onDelete,
  objectName,
}: {
  onDelete: () => void;
  objectName?: ModelRootType | AnyNodeName;
}) => (
  <Button primary onClick={() => onDelete()} style={{ marginTop: "2rem" }}>
    {`Delete ${capitaliseFirstCharacter(objectName as string)}`}
  </Button>
);
