/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { debounce } from "lodash";
import { Button } from "semantic-ui-react";
import tw, { TwComponent } from "twin.macro";
import { TextInput, PageSection } from "components/shared";
import { FormTextInputProps } from "components/shared/Forms/Inputs";
import { useAsyncValidate } from "components/shared/Forms/useAsyncValidate";
import Form from "components/shared/Forms/ReactHookForm";
import { Grid3Col } from "../ComponentLibrary";
import { FormFields, InputsProps } from "./Inputs";

const FormTextInputWrapper = (props: {
  formId: string;
  textInputProps: Omit<FormTextInputProps<FormFields>, "name">;
  InputBox: TwComponent<"div">;
}) => {
  const { formId, textInputProps, InputBox } = props;
  return (
    <div>
      <InputBox>
        <Form
          formId={formId}
          onSubmit={(data: FormFields) => console.log(data)}
        >
          <Form.TextInput name="text" {...textInputProps} />
        </Form>
      </InputBox>
      <Button
        size="mini"
        inverted
        basic
        type="submit"
        form={formId}
        style={{ marginTop: ".5rem" }}
      >
        Submit
      </Button>
    </div>
  );
};

export const TextInputs = ({ showBoundaries }: InputsProps) => {
  const InputBox = showBoundaries
    ? tw.div`border border-dashed border-core-grey`
    : tw.div`border border-solid border-transparent`;

  const [controlledErrorValue, setControlledErrorValue] =
    useState("Error value");

  const getFormTextInput = (
    formId: string,
    textInputProps: Omit<FormTextInputProps<FormFields>, "name">
  ) => {
    return (
      <FormTextInputWrapper
        InputBox={InputBox}
        formId={formId}
        textInputProps={textInputProps}
      />
    );
  };

  return (
    <>
      <PageSection header="Basic">
        <Grid3Col>
          <div>
            <InputBox>
              <TextInput placeholder="With placeholder" />
            </InputBox>
          </div>
          <div>
            <InputBox>
              <TextInput
                error={true}
                value={controlledErrorValue}
                onChange={(value) => {
                  console.log("changed to ", value);
                  setControlledErrorValue(value);
                }}
              />
            </InputBox>
          </div>
          <div>
            <InputBox>
              <TextInput
                placeholder="Error state with message"
                inputValidation={{
                  invalid: true,
                  errorMessage: "This is wrong",
                }}
              />
            </InputBox>
          </div>
          <div>
            <InputBox>
              <TextInput label="My Label" placeholder="Labelled input" />
            </InputBox>
          </div>
          <div>
            <InputBox>
              <TextInput
                required
                label="My Label"
                error={true}
                placeholder="Labelled, required indicator, error state"
              />
            </InputBox>
          </div>
          <div>
            <InputBox>
              <TextInput
                required
                label="My Label"
                placeholder="Labelled, error state with message"
                inputValidation={{
                  invalid: true,
                  errorMessage: "This is wrong",
                }}
              />
            </InputBox>
          </div>
        </Grid3Col>
      </PageSection>
      <PageSection header="In a React Hook Form">
        <Grid3Col>
          {getFormTextInput("FormTextInput_1", {
            placeholder: "In a React Hook Form",
          })}
          {getFormTextInput("FormTextInput_2", {
            error: true,
            placeholder: "In a Form with error state",
          })}
          {getFormTextInput("FormTextInput_3", {
            required: true,
            placeholder: "In a Form with required validation",
          })}
          {getFormTextInput("FormTextInput_4", {
            label: "My Form Label",
            placeholder: "Labelled, in a React Hook Form",
          })}
          {getFormTextInput("FormTextInput_5", {
            label: "My Form Label",
            error: true,
            placeholder: "Labelled, in a Form, error state",
          })}
          {getFormTextInput("FormTextInput_6", {
            label: "My Form Label",
            required: true,
            placeholder: "Labelled, in a Form, required validation",
            rules: { required: "Required" },
          })}
          {getFormTextInput("FormTextInput_7", {
            label: "BAD IMPLEMENTATION OF ASYNC VALIDATION",
            description:
              "This will trigger the async call on every form input change",
            placeholder: "Enter 'Bret' for error",
            rules: {
              validate: {
                asyncValidate: (s: string): Promise<string | undefined> =>
                  new Promise((resolve) => {
                    debouncedFetch(s, resolve);
                  }),
              },
            },
            validateOnChange: true,
          })}
          <InputBox>
            <Form onSubmit={(data) => console.log(data)}>
              <FormTextInputWithAsyncValidation />
            </Form>
          </InputBox>
          <InputBox>
            <Form onSubmit={(data) => console.log(data)}>
              <FormTextInputWithAsyncValidation required />
            </Form>
          </InputBox>
        </Grid3Col>
      </PageSection>
    </>
  );
};

const debouncedFetch = debounce(
  async (s: string, resolve: (_: string | undefined) => void) => {
    if (!s) {
      resolve(undefined);
    } else {
      const response = await fetch(
        `https://jsonplaceholder.typicode.com/users?username=${s}`
      );
      const data = await response.json();
      resolve(data.length === 0 ? undefined : "This data already exists");
    }
  },
  500
);

const asyncValidate = (s: string): Promise<string | undefined> =>
  new Promise((resolve) => {
    debouncedFetch(s, resolve);
  });

const FormTextInputWithAsyncValidation = (props: { required?: boolean }) => {
  useAsyncValidate("text", asyncValidate);
  return (
    <>
      <Form.TextInput
        name="text"
        label={`With custom async${props.required ? " and required" : ""}`}
        description="This implements async validation the way we want"
        placeholder="Enter 'Bret' for error"
        required={props.required}
        validateOnChange
        rules={{}}
      />
      <Button
        size="mini"
        inverted
        basic
        type="submit"
        style={{ marginTop: ".5rem" }}
      >
        Submit
      </Button>
    </>
  );
};
