/** @jsxImportSource @emotion/react */
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import tw from "twin.macro";
import {
  ApplicationUrl,
  InstalledApplicationResponse,
  StreamInfo,
} from "data/Metis";
import {
  DateRange,
  DateRangeInput,
  getDateString,
  inStoppedStates,
  JobState,
  ResponsiveWidths,
  UIStatus,
  UIStatusWrapper,
} from "components/shared";
import {
  ApplicationDownloadStreamsButton,
  convertTimeString,
  extractDateRangeFromURL,
  MaxHeightIFrame,
} from "./";

const dateRangeWidth: ResponsiveWidths = {
  computer: 5,
  largeScreen: 5,
  tablet: 16,
  mobile: 16,
};

export type ApplicationDashboardProps = {
  app: InstalledApplicationResponse;
  dateRange: DateRange | undefined;
  setDateRange: Dispatch<SetStateAction<DateRange | undefined>>;
  appStreams: StreamInfo[];
  jobState: JobState;
};

export const ApplicationDashboard: FC<ApplicationDashboardProps> = ({
  app,
  dateRange,
  setDateRange,
  appStreams,
  jobState,
}) => {
  const [modifiedURL, setModifiedURL] = useState<ApplicationUrl>();
  const [frameSrc, setFrameSrc] = useState<ApplicationUrl>();
  const [status, setStatus] = useState<UIStatus>(
    new UIStatus().setIndeterminate(!inStoppedStates(jobState))
  );
  const [timeNow] = useState<Date>(new Date());

  useEffect(() => {
    setStatus((p) => p.setIndeterminate(!inStoppedStates(jobState)));
  }, [jobState]);

  useEffect(() => {
    if (app && app.dashboardUrl) {
      const [from, to] = extractDateRangeFromURL(app?.dashboardUrl);
      let newURL = app.dashboardUrl;
      if (from) {
        newURL = newURL.replace(
          from,
          dateRange?.start ? getDateString(dateRange.start) : from
        ) as ApplicationUrl;
      }
      if (to) {
        newURL = newURL.replace(
          to,
          dateRange?.end ? getDateString(dateRange.end) : to
        ) as ApplicationUrl;
      }
      setModifiedURL(newURL);
    }
  }, [app, dateRange]);

  useEffect(() => {
    let [start, end] = extractDateRangeFromURL(frameSrc as string);
    const startDate = start ? convertTimeString(start, timeNow) : start;
    const endDate = end ? convertTimeString(end, timeNow) : end;
    setDateRange((d) => {
      if (!d) return d;
      return {
        start: startDate ?? d.start,
        end: endDate ?? d.end,
      } as DateRange;
    });
  }, [frameSrc, setDateRange, timeNow]);

  return (
    <div data-test-id="applicationDashboard">
      <div>
        <ApplicationDownloadStreamsButton
          applicationName={app.applicationName}
          applicationStreams={appStreams}
        />
      </div>
      <UIStatusWrapper status={status}>
        <div
          css={tw`bg-black border border-solid border-core-grey rounded p-4 mt-4`}
        >
          <Grid>
            <Grid.Row>
              <DateRangeInput
                dateRange={dateRange}
                setDateRange={setDateRange}
                columnWidths={dateRangeWidth}
              />
            </Grid.Row>
          </Grid>
          <MaxHeightIFrame
            src={modifiedURL ?? app.dashboardUrl}
            setFrameSrc={setFrameSrc}
            setDateRange={setDateRange}
          />
        </div>
      </UIStatusWrapper>
    </div>
  );
};
