/** @jsxImportSource @emotion/react */
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Button, Icon, Table } from "semantic-ui-react";
import tw from "twin.macro";
import { ApiError } from "data/http";
import { InstallationValidationReport, metisApi } from "data/Metis";
import { SiteBuildingReference } from "data/QueryApi/queryTypes";
import {
  ErrorHeaderWithContent,
  ProcessingStatusLabel,
} from "components/shared";
import {
  InstallApplicationState,
  mkFormValuesToInstallRequest,
} from "./InstallApplicationModalHelpers";
import { FormValues } from "./MetisInstallApplicationModal";

export const CompatibilityCheckSegment: React.FC<{
  modelsList: SiteBuildingReference[];
  setIsCompatible?: Dispatch<SetStateAction<boolean>>;
}> = ({ modelsList, setIsCompatible }) => {
  const { watch, getValues } = useFormContext();

  const models: string[] = watch("models");
  const applicationVersionId = watch("applicationVersionId");

  const [compatibilityState, setCompatibilityState] = useState(
    InstallApplicationState.Unknown
  );
  const [compatibilityReport, setCompatibilityReport] =
    useState<InstallationValidationReport>();
  const [compatibilityError, setCompatibilityError] = useState<
    string | undefined
  >();

  // disable button on model change and was previously successful
  useEffect(() => {
    if (
      compatibilityState === InstallApplicationState.Success ||
      models.length === 0
    ) {
      setCompatibilityState(InstallApplicationState.Unknown);
      setCompatibilityError(undefined);
      setIsCompatible?.(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [models, applicationVersionId]);

  const checkCompatibility = () => {
    setCompatibilityState(InstallApplicationState.Running);
    setCompatibilityReport(undefined);

    const validationRequest = mkFormValuesToInstallRequest(
      getValues() as FormValues,
      modelsList
    );

    /**
     * - 201 response code is returned regardless of validation state i.e. failed still returns 201
     * - error response codes are returned on error external to validation
     */
    metisApi
      .postValidateCompatibility(validationRequest)
      .then((report: InstallationValidationReport) => {
        if (report.isCompatible) {
          setCompatibilityState(InstallApplicationState.Success);
          setIsCompatible?.(true);
        } else {
          setCompatibilityState(InstallApplicationState.Failure);
          setIsCompatible?.(false);
        }
        setCompatibilityError(undefined);
        setCompatibilityReport(report);
      })
      .catch((error: ApiError) => {
        setCompatibilityState(InstallApplicationState.Error);
        setCompatibilityError(
          error.message ??
            "An error occurred while checking the compatibility of your model(s). Please try again."
        );
      });
  };

  const errorTable = () => {
    return (
      compatibilityState === InstallApplicationState.Failure &&
      compatibilityReport && (
        <div>
          <ErrorHeaderWithContent
            content={
              <Table inverted celled attached="bottom">
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Reason</Table.Cell>
                    <Table.Cell>{compatibilityReport?.reason}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Missing Data</Table.Cell>
                    <Table.Cell>
                      {compatibilityReport?.missingData
                        ?.map((data) => data)
                        .join(", ")}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Inaccessible Streams</Table.Cell>
                    <Table.Cell>
                      {compatibilityReport?.inaccessibleStream
                        ?.map((stream) => stream)
                        .join(",")}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            }
          />
        </div>
      )
    );
  };

  return (
    <>
      <div css={tw`flex flex-row items-center gap-4 mb-2`}>
        <b>Compatibility Status</b>
        <ProcessingStatusLabel status={compatibilityState} />
        <Button
          type="button"
          basic
          inverted
          disabled={!(applicationVersionId && models.length > 0)}
          onClick={() => checkCompatibility()}
          loading={compatibilityState === InstallApplicationState.Running}
        >
          <Icon name="linkify" />
          {compatibilityState === InstallApplicationState.Failure
            ? "Re-check Compatibility"
            : "Check Compatibility"}
        </Button>
      </div>

      <div>
        {compatibilityState === InstallApplicationState.Error && (
          <div>
            <Icon name="exclamation" color="red" />
            <span css={tw`text-red-500`}>{compatibilityError}</span>
          </div>
        )}
        {compatibilityState === InstallApplicationState.Success && (
          <div>
            <Icon name="check" color="green" />
            Your model is compatible with this application.
          </div>
        )}
        {compatibilityState === InstallApplicationState.Unknown && (
          <div>
            <Icon name="exclamation" color="yellow" />
            {applicationVersionId && models.length > 0
              ? "Please check compatibility of your model(s) prior to installation"
              : "Please select a version and model(s) in order to check compatibility"}
          </div>
        )}
      </div>
      {errorTable()}
    </>
  );
};
