/** @jsxImportSource @emotion/react */

import { useNavigate } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import tw from "twin.macro";
import {
  availableApplicationViewPath,
  installedApplicationViewPath,
  manageApplicationViewPath,
} from "Routes";
import {
  AppCategories,
  AppListView,
  AppSitesAndBuildings,
  AppStats,
  ApplicationsMode,
  CommonAppProps,
  getIconByCategory,
} from "./";
import { DATE_TIME_FORMAT_WITH_AMPM, formatDate } from "components/shared";

export const ApplicationRow = ({ app, mode }: CommonAppProps) => {
  const navigate = useNavigate();
  const iconSvg = getIconByCategory(app.categories ?? []);

  const handleNavigateOnClick = () => {
    switch (mode) {
      case ApplicationsMode.available:
        navigate(availableApplicationViewPath(app.applicationUid), {
          state: { backTo: "Available Applications" },
        });
        break;
      case ApplicationsMode.installed:
        navigate(installedApplicationViewPath(app.applicationUid), {
          state: { backTo: "Installed Applications" },
        });
        break;
      case ApplicationsMode.managed:
        navigate(manageApplicationViewPath(app.applicationUid), {
          state: { backTo: "Manage Applications" },
        });
        break;
    }
  };
  return (
    <div
      css={tw`rounded bg-core-grey-dark p-4 flex gap-4 hover:bg-primary hover:cursor-pointer`}
      data-test-id="applicationRow"
      onClick={() => {
        handleNavigateOnClick();
      }}
    >
      <div>
        <img src={iconSvg} alt="application icon" width="75" height="75" />
      </div>
      <div
        css={tw`flex flex-1 flex-col justify-between
        `}
      >
        <h3 css={tw`m-0`}>
          {mode === ApplicationsMode.installed
            ? app.instanceName
            : app.applicationName}
        </h3>
        {mode === ApplicationsMode.installed ? (
          <>
            <AppStats app={app} mode={mode} appListView={AppListView.Rows} />
            <AppSitesAndBuildings app={app} appListView={AppListView.Rows} />
          </>
        ) : (
          <>
            <div>{`by ${app.vendorId}`}</div>
            <p>{app.description}</p>
          </>
        )}
      </div>
      <div css={tw`flex flex-col justify-between items-end`}>
        <AppCategories app={app} />
        <div>
          {mode === ApplicationsMode.installed ? (
            app.lastKnownRun && (
              <span>
                <Icon name="refresh" />
                {formatDate(app.lastKnownRun, DATE_TIME_FORMAT_WITH_AMPM)}
              </span>
            )
          ) : (
            <AppStats app={app} mode={mode} appListView={AppListView.Rows} />
          )}
        </div>
      </div>
    </div>
  );
};
