import { Classifier, ClassifierMode } from "data/Aletheia";
import { Table } from "@tanstack/react-table";
import { DropdownItemProps } from "semantic-ui-react";
import { DCHJsonClassResponse, Unit } from "data/Mason/MasonTypes";

export const generateClassifierTableHeaderId = (
  mode: ClassifierMode,
  headerId: string
) => {
  return mode === ClassifierMode.filter
    ? `filter-by-${headerId}`
    : `apply-rules-${headerId}`;
};

export const toggleAllColumnsVisible = (table: Table<Classifier>) => {
  table.getAllLeafColumns().forEach((column) => column.toggleVisibility(true));
};

export const toOptionsDCHJsonClasses = function (
  x: DCHJsonClassResponse
): DropdownItemProps {
  return {
    value: `brick:${x.type}`,
    text: x.label,
  };
};

export const toOptionsUnits = function (x: Unit): DropdownItemProps {
  return {
    value: x.id,
    text: x.name ?? x.id,
  };
};
const hasNonEmptyValue = (obj: any) => {
  if (obj === null || obj === undefined || typeof obj !== "object") {
    return false;
  }

  if (Object.keys(obj).length === 0 && obj.constructor === Object) {
    return false;
  }

  // iterate keys in FilterOptions and RuleOptions, to find non-null value
  for (const key in obj) {
    if (
      obj[key] &&
      obj[key].value !== undefined &&
      obj[key].value !== null &&
      obj[key].value !== ""
    ) {
      return true;
    }

    // recursively find the .value key
    if (typeof obj[key] === "object") {
      if (hasNonEmptyValue(obj[key])) {
        return true;
      }
    }
  }

  return false;
};

export const hasNonEmptyValueInClassifiers = (classifiers: Classifier[]) => {
  for (const classifier of classifiers) {
    if (
      hasNonEmptyValue(classifier.filterBy) ||
      hasNonEmptyValue(classifier.executeRules)
    ) {
      return true;
    }
  }
  return false;
};
