import { ClientEnv } from "data/utils";

import * as O from "fp-ts/lib/Option";
import { iamEnodiaEnv } from "reducers/env";

import {
  ApiKeyCreateRequest,
  ApiKeyCreateResponse,
  ApiKeyResponse,
  InvitationCreateRequest,
  InvitationResponse,
  OrganisationResponse,
  PermissionParams,
  RoleResponse,
  RoleSetCreateRequest,
  RoleSetPatchRequest,
  RoleSetResponse,
  ScopeType,
  UserAssignmentRequest,
  UserGroupCreateRequest,
  UserGroupPatchRequest,
  UserGroupResponse,
  UsersResponse,
  OrgId,
  Authority,
  MeUser,
  UpdatedUserAssignmentResponse,
  AuthorityPatchRequest,
  UserId,
  AcceptAgreementRequest,
  MeUserParams,
} from ".";

import { httpDelete, httpGet, httpPatch, httpPost } from "../httpUtil";

/**
 * DCH Identity and Access Management (IAM) service
 * AKA. Enodia
 * @link https://develop.dataclearinghouse.org/api/iam/v1/swagger#/
 */

type EnodiaApi = {
  getInvitation: (invitationId: string) => Promise<InvitationResponse>;
  getInvitations: (recipient: string) => Promise<Array<InvitationResponse>>;
  postInvitation: (
    invitation: InvitationCreateRequest
  ) => Promise<InvitationResponse>;
  acceptInvitation: (invitationId: string) => Promise<void>;
  declineInvitation: (invitationId: string) => Promise<void>;
  getRoleSets: (params: PermissionParams) => Promise<Array<RoleSetResponse>>;
  getRoleSet: (roleSetId: string) => Promise<RoleSetResponse>;
  postRoleSet: (roleSet: RoleSetCreateRequest) => Promise<void>;
  patchRoleSet: (
    roleSetId: string
  ) => (roleSet: RoleSetPatchRequest) => Promise<void>;
  deleteRoleSet: (roleSetId: string) => Promise<void>;
  getRoleById: (roleId: string) => Promise<RoleResponse>;
  getUserGroups: (
    params: PermissionParams
  ) => Promise<Array<UserGroupResponse>>;
  postUserGroup: (
    userGroup: UserGroupCreateRequest
  ) => Promise<UserGroupResponse>;
  getUserGroup: (userGroupId: string) => Promise<UserGroupResponse>;
  patchUserGroup: (
    userGroupId: string
  ) => (userGroup: UserGroupPatchRequest) => Promise<void>;
  deleteUserGroup: (userGroupId: string) => Promise<void>;
  getOrgs: () => Promise<Array<OrganisationResponse>>;
  getOrgById: (orgId: OrgId) => Promise<OrganisationResponse>;
  getUserAssignmentByOrg: (
    orgId: OrgId
  ) => (userId: string) => Promise<UpdatedUserAssignmentResponse>;
  getUsersByOrg: (orgId: OrgId) => Promise<UsersResponse>;
  postUserAssignment: (assignmentReq: UserAssignmentRequest) => Promise<void>;
  getPermissionsByScopeType: (scopeType: ScopeType) => Promise<string[]>;
  getApiKeys: () => Promise<Array<ApiKeyResponse>>;
  postApiKey: (request: ApiKeyCreateRequest) => Promise<ApiKeyCreateResponse>;
  getApiKeyById: (apiKeyId: string) => Promise<ApiKeyResponse>;
  patchApiKey: (
    apiKeyId: string
  ) => (request: ApiKeyCreateRequest) => Promise<ApiKeyResponse>;
  deleteApiKey: (apiKeyId: string) => Promise<void>;
  getAuthorities: () => Promise<Authority[]>;
  patchAuthorities: (
    orgId: OrgId
  ) => (
    userId: UserId
  ) => (
    request: AuthorityPatchRequest
  ) => Promise<UpdatedUserAssignmentResponse>;
  getMe: (params: MeUserParams) => Promise<MeUser>;
  postAcceptAgreements: (request: AcceptAgreementRequest[]) => Promise<void>;
};

const mkEnodiaApi = (env: ClientEnv): EnodiaApi => ({
  getRoleSets: (params) =>
    httpGet(O.none)(env)(params)("/rolesets").then(
      (r) => r as Array<RoleSetResponse>
    ),
  getUserGroups: (params) =>
    httpGet(O.none)(env)(params)("/usergroups").then(
      (r) => r as Array<UserGroupResponse>
    ),
  getOrgs: () =>
    httpGet(O.none)(env)(null)("/organisations").then(
      (r) => r as Array<OrganisationResponse>
    ),
  getOrgById: (orgId: OrgId) =>
    httpGet(O.none)(env)(null)(`/organisations/${orgId}`).then(
      (r) => r as OrganisationResponse
    ),
  getUserAssignmentByOrg: (orgId: OrgId) => (userId: string) =>
    httpGet(O.none)(env)(null)(`/organisations/${orgId}/users/${userId}`).then(
      (r) => r as UpdatedUserAssignmentResponse
    ),
  getInvitation: (invitationId: string) =>
    httpGet(O.none)(env)(null)(`/invitations/${invitationId}`).then(
      (r) => r as unknown as InvitationResponse
    ),
  getInvitations: (recipient) =>
    httpGet(O.none)(env)({ recipient })(`/invitations`).then(
      (r) => r as unknown as Array<InvitationResponse>
    ),
  postInvitation: (invitation: InvitationCreateRequest) =>
    httpPost(invitation)(O.none)(env)(null)("/invitations").then(
      (r) => r as unknown as InvitationResponse
    ),
  acceptInvitation: (invitationId: string) =>
    httpPost(null)(O.none)(env)(null)(
      `/invitations/${invitationId}/accept`
    ).then((_) => undefined),
  declineInvitation: (invitationId: string) =>
    httpDelete(O.none)(env)(null)(`/invitations/${invitationId}`).then(
      (_) => undefined
    ),
  getUsersByOrg: (orgId: OrgId) =>
    httpGet(O.none)(env)(null)(`/organisations/${orgId}/users`).then(
      (r) => r as UsersResponse
    ),
  postUserAssignment: (assignmentReq) =>
    httpPost(assignmentReq)(O.none)(env)(O.none)("/users/assign").then(
      (_) => undefined
    ),
  postUserGroup: (userGroup) =>
    httpPost(userGroup)(O.none)(env)(null)("/usergroups").then(
      (r) => r as UserGroupResponse
    ),
  getUserGroup: (userGroupId: string) =>
    httpGet(O.none)(env)(null)(`/usergroups/${userGroupId}`).then(
      (r) => r as UserGroupResponse
    ),
  patchUserGroup: (userGroupId: string) => (userGroup) =>
    httpPatch(userGroup)(O.none)(env)(null)(`/usergroups/${userGroupId}`).then(
      (_) => undefined
    ),
  deleteUserGroup: (userGroupId: string) =>
    httpDelete(O.none)(env)(null)(`/usergroups/${userGroupId}`).then(
      (_) => undefined
    ),
  postRoleSet: (roleSet) =>
    httpPost(roleSet)(O.none)(env)(null)("/rolesets").then((_) => undefined),
  getRoleSet: (roleSetId) =>
    httpGet(O.none)(env)(null)(`/rolesets/${roleSetId}`).then(
      (r) => r as RoleSetResponse
    ),
  patchRoleSet: (roleSetId) => (roleSet) =>
    httpPatch(roleSet)(O.none)(env)(null)(`/rolesets/${roleSetId}`).then(
      (_) => undefined
    ),
  deleteRoleSet: (roleSetId) =>
    httpDelete(O.none)(env)(null)(`/rolesets/${roleSetId}`).then(
      (_) => undefined
    ),
  getRoleById: (roleId) =>
    httpGet(O.none)(env)(null)(`/roles/${roleId}`).then(
      (r) => r as RoleResponse
    ),
  getPermissionsByScopeType: (scopeType) =>
    httpGet(O.none)(env)(null)(
      `/resource-metadata/relations/${scopeType}`
    ).then((r) => r as string[]),
  getApiKeys: () =>
    httpGet(O.none)(env)(null)(`/keys`).then((r) => r as Array<ApiKeyResponse>),
  postApiKey: (request: ApiKeyCreateRequest) =>
    httpPost(request)(O.none)(env)(null)("/keys").then(
      (r) => r as ApiKeyCreateResponse
    ),
  getApiKeyById: (apiKeyId: string) =>
    httpGet(O.none)(env)(null)(`/keys/${apiKeyId}`).then(
      (r) => r as ApiKeyResponse
    ),
  patchApiKey: (apiKeyId: string) => (request: ApiKeyCreateRequest) =>
    httpPatch(request)(O.none)(env)(null)(`/keys/${apiKeyId}`).then(
      (r) => r as ApiKeyResponse
    ),
  deleteApiKey: (apiKeyId: string) =>
    httpDelete(O.none)(env)(null)(`/keys/${apiKeyId}`).then((_) => undefined),
  getAuthorities: () =>
    httpGet(O.none)(env)(null)(`/authorities`).then((r) => r as Authority[]),
  getMe: (params: MeUserParams) =>
    httpGet(O.none)(env)(params)(`/me`).then((r) => r as MeUser),
  patchAuthorities:
    (orgId: OrgId) => (userId: UserId) => (request: AuthorityPatchRequest) =>
      httpPatch(request)(O.none)(env)(null)(
        `/organisations/${orgId}/users/${userId}`
      ).then((r) => r as UpdatedUserAssignmentResponse),
  postAcceptAgreements: (agreements) =>
    httpPost(agreements)(O.none)(env)(null)("/agreements/accept").then(
      (_) => undefined
    ),
});

export const enodiaApi = mkEnodiaApi(iamEnodiaEnv);
