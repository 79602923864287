/** @jsxImportSource @emotion/react */
import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import tw from "twin.macro";
import {
  Form,
  FormStateValues,
  Page,
  PageTitle,
  UIStatus,
  UIStatusWrapper,
} from "components/shared";
import { FieldValues } from "react-hook-form";
import { EnodiaOrgContext } from "App";
import { DropdownItemProps, Icon, Image } from "semantic-ui-react";
import { metisApi, useApplicationCategories } from "data/Metis";
import { getIconByCategory } from "components/Applications/shared";
import { VendorUid } from "data/Metis/Vendor/vendorTypes";
import { ApiError } from "data/http";
import { manageApplicationViewPath } from "Routes";

interface UploadApplicationFormData extends FieldValues {
  name: string;
  description: string;
  vendorUid: VendorUid;
  author: string;
  categoryUids: string[];
  archiveFile: File;
}

export const UploadApplication = () => {
  const { orgId } = useContext(EnodiaOrgContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [vendorOptions, setVendorOptions] = useState<DropdownItemProps[]>([]);
  const [uiStatus, setUiStatus] = useState(new UIStatus());
  const { categorySelectOptions } = useApplicationCategories();
  const [formState, setFormState] =
    useState<FormStateValues<UploadApplicationFormData>>();

  useEffect(() => {
    if (orgId)
      metisApi.getVendors({ organisationId: orgId }).then(
        (vendors) => {
          setVendorOptions(
            vendors.map((vendor) => ({ value: vendor.uid, text: vendor.name })),
          );
        },
        () => {
          setUiStatus((prev) =>
            prev.setError("An error occured while fetching vendors."),
          );
        },
      );
  }, [orgId]);

  const handleSubmit = (formValues: UploadApplicationFormData) => {
    setUiStatus((prev) => prev.setIndeterminate(true));
    metisApi
      .uploadApplicationRecipe(formValues.vendor)(formValues.archiveFile)
      .then(
        (res) => {
          metisApi
            .putManageApplicationRecipe(
              res.id,
              (({ name, description, author, categoryUids }) => ({
                name,
                description,
                author,
                categoryUids,
                overview: "",
              }))(formValues),
            )
            .then(
              () => {
                setUiStatus((prev) => prev.setSuccessful(true));
                navigate(manageApplicationViewPath(res.id));
              },
              (error: ApiError) => {
                setUiStatus((prev) =>
                  prev.setError(
                    error.message ??
                      "An error occured while uploading this application. Please try again.",
                  ),
                );
              },
            );
        },
        (error: ApiError) => {
          setUiStatus((prev) =>
            prev.setError(
              error.message ??
                "An error occured while uploading this application. Please try again.",
            ),
          );
        },
      );
  };

  return (
    <Page hasGoBack backTo={location.state?.["backTo"]}>
      <PageTitle
        primaryHeader="Application Developer Dashboard"
        secondaryHeader="Upload a New Application"
      />
      <div css={tw`rounded bg-core-grey-dark p-4`}>
        <Form<UploadApplicationFormData>
          onSubmit={handleSubmit}
          setFormState={(state) => setFormState(state)}
        >
          <UIStatusWrapper status={uiStatus}>
            <Form.TextInput
              name="name"
              label="Application Name"
              required
              verticalLayout
            />
            <Form.TextInput
              verticalLayout
              name="description"
              label="Description"
            />
            <Form.SelectInput
              required
              verticalLayout
              name="vendor"
              label="Vendor"
              options={vendorOptions}
            />
            <Form.TextInput name="author" label="Author" verticalLayout />
            <Form.SelectInput
              required
              verticalLayout
              isMulti
              search
              name="categoryUids"
              label="Categories"
              options={categorySelectOptions}
              className="application-category"
              renderLabel={(item) => ({
                content: (
                  <div
                    css={tw`flex gap-2 items-center text-sm whitespace-nowrap`}
                  >
                    <Image
                      src={getIconByCategory([item.text as string])}
                      alt="application icon"
                      width="16"
                      height="16"
                    />
                    <span>{item.text}</span>
                  </div>
                ),
              })}
            />
            <Form.FileInput
              name="archiveFile"
              label="Application Archive"
              required
              verticalLayout
              accept=".zip, .tar.gz"
            />
            <div css={tw`mt-2`} className="design-system-2">
              <Icon name="exclamation circle" size="large" />
              For instructions and guidelines on creating a valid application
              archive, refer to{" "}
              <Link
                to="http://docs.dataclearinghouse.org"
                target="_blank"
                className="distinct"
              >
                our documentation
              </Link>
              .
            </div>
            <div css={tw`text-right`}>
              <Form.Button
                submit
                label="Upload Application"
                icon="upload"
                disabled={!formState?.isDirty || !formState?.isValid}
                loading={uiStatus.indeterminate}
              />
            </div>
          </UIStatusWrapper>
        </Form>
      </div>
    </Page>
  );
};
