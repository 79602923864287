/** @jsxImportSource @emotion/react */

import React from "react";
import { Button, Grid, Icon, SemanticICONS } from "semantic-ui-react";
import tw from "twin.macro";
import { PageMode } from "components/shared";
import Form, { FormStateValues } from "components/shared/Forms/ReactHookForm";
import { ModelFormFields } from "../Form/SiteOrBuildingModelForm";

type ButtonProps = {
  label: string;
  onClick?: () => void;
  icon?: SemanticICONS;
  basic?: boolean;
  disabled?: boolean;
};
const ModelAction = ({
  label,
  onClick,
  icon,
  basic,
  disabled,
}: ButtonProps) => (
  <Button
    type="button"
    primary={!basic}
    onClick={onClick}
    basic={basic}
    inverted={basic}
    style={{ marginRight: "1rem" }}
    disabled={disabled}
  >
    {icon && <Icon name={icon} />}
    {label}
  </Button>
);

type Props = {
  pageMode: PageMode;
  handleCancel: () => void;
  formState: FormStateValues<ModelFormFields>;
  setShowUploadModal: (_: boolean) => void;
  setShowDownloadModal: (_: boolean) => void;
  checkIssues: () => void;
};

export const ModelActions: React.FunctionComponent<Props> = (props) => {
  const {
    pageMode,
    handleCancel,
    formState,
    setShowUploadModal,
    setShowDownloadModal,
    checkIssues,
  } = props;

  return (
    <Grid.Row css={tw`bg-black sticky top-0 z-5`}>
      <Grid.Column>
        {pageMode === PageMode.edit && (
          <Form.Button
            submit
            label="Save Model"
            icon="save"
            disabled={!formState.isValid || !formState.isDirty}
          />
        )}
        {pageMode === PageMode.view && (
          <React.Fragment>
            <ModelAction
              label="Upload Model File"
              icon="upload"
              onClick={() => setShowUploadModal(true)}
              basic
            />
            <ModelAction
              label="Download Model File"
              icon="download"
              onClick={() => setShowDownloadModal(true)}
              basic
            />
            <ModelAction
              label="Check Issues"
              icon="sync"
              onClick={checkIssues}
              basic
            />
          </React.Fragment>
        )}
        {pageMode !== PageMode.view && (
          <ModelAction label="Cancel" onClick={handleCancel} basic />
        )}
      </Grid.Column>
    </Grid.Row>
  );
};
