/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Icon, Image, Message, MessageHeader } from "semantic-ui-react";
import {
  IndexPageMode,
  TERMS_CONDITIONS_URL,
  UIStatus,
  UIStatusWrapper,
} from "components/shared";
import { SignUpForm, PasswordResetForm } from "./Authentication";
import { AuthContext } from "App";
import LoginForm from "./Authentication/Login";

const NavBar = () => {
  return (
    <div
      css={tw`flex justify-between w-full min-h-fit bg-black`}
      style={{ paddingInline: "25px", paddingBlock: ".5rem" }}
    >
      <div />
      <div>
        <span css={tw`font-bold pl-4 text-xl`}>
          Data Clearing House - DCH v2
        </span>
      </div>
      <div>
        <Link to="https://research.csiro.au/dch/" className="navbar">
          <span>
            Home
            <Icon
              name="sign out"
              css={tw`pl-4`}
              style={{ textDecoration: "unset" }}
            />
          </span>
        </Link>
      </div>
    </div>
  );
};

const Footer = ({ pageMode }: { pageMode: IndexPageMode }) => {
  return pageMode === IndexPageMode.signup ? (
    <div css={tw`h-0`}></div>
  ) : (
    <div css={tw`max-w-fit mt-4 mb-8`} style={{ width: "90vw" }}>
      <small>
        <Icon name="copyright outline" />
        {`Copyright 
        ${new Date().getUTCFullYear()} Commonwealth Scientific and Industrial
        Research Organisation (CSIRO)`}
      </small>
      <div css={tw`text-sm`}>
        <Link to={TERMS_CONDITIONS_URL} target="_blank">
          Terms of service
        </Link>
      </div>
    </div>
  );
};

export const Index = ({
  initialPageMode,
}: {
  initialPageMode?: IndexPageMode;
}) => {
  const authContext = useContext(AuthContext);

  const [pageMode, setPageMode] = useState(
    initialPageMode ?? IndexPageMode.login,
  );
  const [status, setStatus] = useState(new UIStatus());
  const [signupTokenInvalid, setSignupTokenInvalid] = useState(false);

  useEffect(() => {
    if (authContext?.state?.error) {
      authContext.resetSessionStorage();
    }

    pageMode === IndexPageMode.signup
      ? authContext.signup()
      : initialPageMode &&
        pageMode === IndexPageMode.login &&
        authContext.login();
  });

  const handleInvalidToken = () => {
    setSignupTokenInvalid(true);
    setPageMode(IndexPageMode.login);
    setStatus((prev) =>
      prev.setError("Invalid sign up token. Please try again."),
    );
  };

  const getHeader = () => {
    switch (pageMode) {
      case IndexPageMode.signup:
        return "Sign Up";
      case IndexPageMode.resetPassword:
        return "Password Reset";
      default:
        return "Sign In";
    }
  };

  const getForm = () => {
    switch (pageMode) {
      case IndexPageMode.signup:
        return <SignUpForm handleInvalidToken={handleInvalidToken} />;
      case IndexPageMode.resetPassword:
        return <PasswordResetForm setPageMode={setPageMode} />;

      default:
        return <LoginForm />;
    }
  };

  const getLinks = () => {
    switch (pageMode) {
      case IndexPageMode.signup:
        return (
          <div>
            Already have an account? Click{" "}
            <Link to={""} onClick={() => setPageMode(IndexPageMode.login)}>
              here
            </Link>{" "}
            to sign in.
          </div>
        );
      case IndexPageMode.login:
        return (
          <>
            {initialPageMode === IndexPageMode.signup && !signupTokenInvalid ? (
              <div>
                Need to create an account? Click{" "}
                <Link to={""} onClick={() => setPageMode(IndexPageMode.signup)}>
                  here
                </Link>{" "}
                to sign up
              </div>
            ) : (
              <div>
                Don't have an account? Email{" "}
                <Link to={"mailto:contact.dch@csiro.au"}>
                  contact.dch@csiro.au
                </Link>{" "}
                to request access.
              </div>
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  return authContext.deferRender(
    pageMode === IndexPageMode.signup ? (
      <></>
    ) : (
      <Background
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/DchBackground.png)`,
        }}
        className="design-system-2"
      >
        <NavBar />
        <Content style={{ marginLeft: "5vw" }}>
          <div css={tw`h-0`}></div>
          <div>
            <Image
              alt="CSIRO logo"
              size="tiny"
              src={`${process.env.PUBLIC_URL}/images/csiro-logo.png`}
            />
            <div
              css={tw`flex flex-col mt-2 mb-8`}
              style={{ width: "min(500px,90%)" }}
            >
              <h1>{getHeader()}</h1>
              <Message color="yellow">
                <MessageHeader>DCH 2.0 is now Live!</MessageHeader>
                <p>
                  Click{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      "https://docs.dataclearinghouse.org/dch-2.0-documentation/whats-new"
                    }
                  >
                    here
                  </a>{" "}
                  to see whats changed.
                </p>
                <p>
                  Our{" "}
                  <Link to={"mailto:support@dch-support.freshdesk.com"}>
                    support team
                  </Link>{" "}
                  is ready to assist with any questions or concerns during the
                  transition period.
                </p>
              </Message>
              <UIStatusWrapper status={status}>
                <div css={tw`w-3/4`}>{getForm()}</div>
              </UIStatusWrapper>
              {getLinks()}
            </div>
          </div>
          <Footer pageMode={pageMode} />
        </Content>
      </Background>
    ),
  );
};

const Background = tw.div`bg-cover bg-no-repeat bg-center min-h-screen w-screen flex flex-col`;
const Content = tw.div`flex-1 flex flex-col justify-between`;
