/** @jsxImportSource @emotion/react */

import React, { useContext, useState } from "react";
import { generatePath, useParams, useNavigate } from "react-router-dom";
import { Button, Dimmer, Icon, Segment, Table } from "semantic-ui-react";
import tw from "twin.macro";
import { EnodiaOrgContext } from "App";
import { Path } from "Routes";
import OrgContextModal from "context/OrgContextModal";
import { BuildingId, SiteId } from "data/brick";

import { ApiError } from "data/http";
import {
  OrgId,
  RoleRequest,
  RoleResponse,
  RoleSetCreateRequest,
  ScopeType,
  enodiaApi,
} from "data/Enodia";
import {
  DchModal,
  Page,
  PageSection,
  PageTitle,
  PageMode,
  redirectMessage,
  resetMessage,
  UIStatus,
  UIStatusWrapper,
} from "components/shared";
import { RoleRow } from "./RoleRow";
import Form, {
  FormMethods,
  FormStateValues,
} from "components/shared/Forms/ReactHookForm";
import RoleSetFormFields from "./RoleSetFormFields";

type EditOrCreateRoleSetProps = {
  pageMode: PageMode;
};

type Params = { roleSetId?: string };
export type SiteBuildingId = [SiteId, BuildingId];

export class RoleSet {
  roleSetId?: string;
  name = "";
  description = "";
}

export const EditOrCreateRoleSet: React.FunctionComponent<
  EditOrCreateRoleSetProps
> = ({ pageMode }) => {
  const { orgId } = useContext(EnodiaOrgContext);
  const params = useParams<Params>();
  const navigate = useNavigate();
  const isReadOnly = pageMode === PageMode.view;

  const [formState, setFormState] = useState<FormStateValues<RoleSet>>();
  const [formMethods, setFormMethods] = useState<FormMethods<RoleSet>>();

  const [status, setStatus] = React.useState(new UIStatus());
  const [deleteStatus, setDeleteStatus] = React.useState(new UIStatus());

  const roleSetId = params.roleSetId;

  const [roles, setRoles] = React.useState<Array<RoleResponse>>([]);

  const [showDeleteModal, updateShowDeleteModal] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [verbOptions, setVerbOptions] = useState(new Map<string, string[]>());

  const modalMessage =
    pageMode === PageMode.create ? resetMessage : redirectMessage;

  React.useEffect(() => {
    if (pageMode !== PageMode.create && orgId && roleSetId && formMethods) {
      setStatus((s) => s.setIndeterminate(true));
      enodiaApi
        .getRoleSet(roleSetId)
        .then((r) => {
          setStatus((s) => s.setIndeterminate(false));
          formMethods?.reset({ name: r.name, description: r.description });
          return r.roleIds;
        })
        .then((roleIds) => {
          // get the list of roles from the list of role Ids and set Roles
          const promises = roleIds.map((id) =>
            enodiaApi.getRoleById(id).then((res) => res),
          );
          Promise.all(promises).then((res) => {
            setRoles(res);
          });
        })
        .catch((e: ApiError) => {
          setStatus((p) => p.setError(e.message || "Unable to load RoleSet"));
        });
    }
  }, [formMethods, pageMode, orgId, roleSetId, verbOptions]);

  const addRole = () =>
    orgId &&
    setRoles(
      roles.concat([
        {
          id: "",
          name: "new role", // todo: add a field to change this; ticket scoped -  DCH-4396
          verbs: [],
          scopeType: ScopeType.Organisation,
          scopeId: orgId,
        },
      ]),
    );

  const onSuccess = () => {
    setStatus((p) => p.setIndeterminate(false));
    navigate(Path.Permissions);
  };
  const onError = (e: Error) => {
    setStatus((p) =>
      p.setError(
        e.message ||
          `Unable to ${
            pageMode === PageMode.create ? "create" : "edit"
          } RoleSet.`,
      ),
    );
  };

  const createNewRoleSet = (orgId: OrgId, roleSet: RoleSet) => {
    const rolesetCreateRequest: RoleSetCreateRequest = {
      organisationId: orgId,
      name: roleSet.name,
      description: roleSet.description,
      newRoles: roles.map(({ id, ...request }) => request as RoleRequest), // we want to omit the id as its not part of RoleRequest
      roleIds: [], // todo
    };
    enodiaApi
      .postRoleSet(rolesetCreateRequest as RoleSetCreateRequest)
      .then(() => onSuccess())
      .catch((e) => onError(e));
  };

  const patchRoleSet = (rsId: string, orgId: OrgId, roleSet: RoleSet) => {
    enodiaApi
      .patchRoleSet(rsId)({
        // todo: after react hook form, create an appropriate patch object
        organisationId: orgId,
        name: roleSet.name,
        description: roleSet.description,
        newRoles: roles.map(({ id, ...request }) => request as RoleRequest),
        addRoleIds: [],
        deleteRoleIds: [],
        deleteAllPreviousRoles: true,
      })
      .then(() => onSuccess())
      .catch((e) => onError(e));
  };

  const handleSubmit = (data: RoleSet) => {
    if (orgId) {
      setStatus((p) => p.setIndeterminate(true));
      pageMode === PageMode.create
        ? createNewRoleSet(orgId, data)
        : roleSetId && patchRoleSet(roleSetId, orgId, data);
    }
  };

  const deleteRoleSet = () => {
    if (roleSetId) {
      setDeleteStatus((prevState) => prevState.setIndeterminate(true));

      return enodiaApi.deleteRoleSet(roleSetId).then(
        (_) => {
          setDeleteStatus((prevState) => prevState.setIndeterminate(false));
          navigate(Path.Permissions);
        },
        (err: ApiError) =>
          setDeleteStatus((prevState) =>
            prevState.setError(
              err.message || "An error occurred when deleting the RoleSet",
            ),
          ),
      );
    }

    return undefined;
  };

  // TODO: Support 'data source' and 'data pool' resources in roleset editor

  const onResetFormConfirm = () => {
    formMethods?.reset();
    setRoles([]);
    setModalOpen(false);
  };

  const onRedirectPageConfirm = () => {
    navigate(Path.Permissions);
  };

  function allRolesValid(roles: RoleResponse[]): boolean {
    if (roles.length === 0) return false;
    return roles.every((r) => r.verbs && r.verbs.length > 0 && !!r.scopeId);
  }

  return (
    <Page>
      {pageMode !== PageMode.create && roleSetId && (
        <DchModal
          header={`Delete RoleSet: ${formMethods?.getValues("name")}`}
          content={
            <UIStatusWrapper status={deleteStatus}>
              Really delete this RoleSet? This will also delete all roles listed
              in it.
            </UIStatusWrapper>
          }
          open={showDeleteModal}
          onConfirm={deleteRoleSet}
          onClose={() => {
            setDeleteStatus(new UIStatus());
            updateShowDeleteModal(false);
            navigate(generatePath(Path.Permissions));
          }}
          confirmText="Delete"
        />
      )}

      <PageTitle
        primaryHeader={`Access Permissions | ${
          pageMode === PageMode.create ? "Create new" : "Edit"
        } RoleSet`}
      />

      <UIStatusWrapper status={status}>
        <Form<RoleSet>
          onSubmit={(data) => handleSubmit(data)}
          setFormState={(state) => setFormState(state)}
          setFormMethods={(methods) => setFormMethods(methods)}
        >
          <PageSection>
            <OrgContextModal
              open={modalOpen}
              openCondition={roles.length > 0}
              onConfirm={() =>
                pageMode === PageMode.create
                  ? onResetFormConfirm()
                  : onRedirectPageConfirm()
              }
              modalMessage={modalMessage}
              setModalState={setModalOpen}
            />
            <RoleSetFormFields
              orgId={orgId}
              roleSetId={roleSetId}
              isReadOnly={isReadOnly}
            />
          </PageSection>
          <Dimmer.Dimmable dimmed={!orgId}>
            <Dimmer active={!orgId}>Please select an organisation.</Dimmer>

            <div css={tw`w-full mb-4`} className="dch-scrollbar">
              <Table inverted compact>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell collapsing>
                      {!isReadOnly && (
                        <Icon link name="add" onClick={addRole} />
                      )}
                    </Table.HeaderCell>
                    <Table.HeaderCell width={3}>Scope Type</Table.HeaderCell>
                    <Table.HeaderCell width={3}>Scope ID</Table.HeaderCell>
                    <Table.HeaderCell>Can do:</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {roles.length > 0 ? (
                    roles.map((role, index) => (
                      <RoleRow
                        key={index}
                        isReadOnly={isReadOnly}
                        role={role}
                        index={index}
                        updateRole={(newValue: RoleRequest) =>
                          setRoles(
                            roles.map((r) =>
                              r === role ? { ...newValue, id: "" } : r,
                            ),
                          )
                        }
                        onDelete={() => {
                          setRoles(roles.filter((r) => r !== role));
                        }}
                        verbOptions={verbOptions}
                        setVerbOptions={setVerbOptions}
                        orgId={orgId}
                      />
                    ))
                  ) : (
                    <Table.Row>
                      <Table.Cell colSpan={4}>
                        <Segment placeholder inverted>
                          <p css={tw`text-center`}>No roles found.</p>

                          {!isReadOnly && (
                            <Button type="button" primary onClick={addRole}>
                              Add role
                            </Button>
                          )}
                        </Segment>
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              </Table>
            </div>
          </Dimmer.Dimmable>
          {pageMode === PageMode.view && (
            <Button
              type="button"
              primary
              onClick={(e) => {
                e.preventDefault();
                navigate(
                  generatePath(Path.EditRoleSet, {
                    roleSetId: roleSetId as string,
                  }),
                );
              }}
            >
              <Icon name="pencil" />
              Edit RoleSet
            </Button>
          )}

          {pageMode !== PageMode.view && (
            <Button
              type="submit"
              loading={status.indeterminate}
              primary
              disabled={!formState?.isValid || !orgId || !allRolesValid(roles)}
            >
              {pageMode === PageMode.create ? "Create" : "Update"}
            </Button>
          )}

          {pageMode !== PageMode.create && (
            <Button
              type="button"
              basic
              inverted
              onClick={() => updateShowDeleteModal(true)}
            >
              <Icon name="trash" />
              Delete RoleSet
            </Button>
          )}

          <Button type="button" basic inverted onClick={() => navigate(-1)}>
            Cancel
          </Button>
        </Form>
      </UIStatusWrapper>
    </Page>
  );
};
