import { FullNodeIdentifier } from "components/SitesAndBuildings/Model/Visualiser/VizModelUtil";

/* BRICK RELATIONSHIPS */
export enum BrickProperty {
  feeds = "feeds",
  isFedBy = "isFedBy",
  hasPart = "hasPart",
  isPartOf = "isPartOf",
  hasLocation = "hasLocation",
  isLocationOf = "isLocationOf",
  hasPoint = "hasPoint",
  isPointOf = "isPointOf",
  streams = "streams",
  isMeteredBy = "isMeteredBy",
  hasSubMeter = "hasSubMeter",
  isSubMeterOf = "isSubMeterOf",
  meters = "meters",
  feedsAir = "feedsAir",
}

export type BrickRelationship = {
  relation: BrickProperty;
  inverse: BrickProperty;
};

export type Relationship = {
  parents: FullNodeIdentifier;
  child: FullNodeIdentifier;
  relation: BrickProperty;
};

export const BRICK_RELATIONSHIPS: BrickRelationship[] = [
  { relation: BrickProperty.hasPart, inverse: BrickProperty.isPartOf },
  { relation: BrickProperty.isFedBy, inverse: BrickProperty.feeds },
  { relation: BrickProperty.hasPoint, inverse: BrickProperty.isPointOf },
  { relation: BrickProperty.isLocationOf, inverse: BrickProperty.hasLocation },
  { relation: BrickProperty.isMeteredBy, inverse: BrickProperty.meters },
  { relation: BrickProperty.hasSubMeter, inverse: BrickProperty.isSubMeterOf },
];

export const getBrickRelationship = (relationship: BrickProperty) =>
  BRICK_RELATIONSHIPS.filter(
    (r) => r.relation === relationship || r.inverse === relationship
  )[0];
