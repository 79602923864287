import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

import { PrivateRoutesLayout } from "route-layout/PrivateRoutesLayout";
import { PublicRoutesLayout } from "route-layout/PublicRoutesLayout";
import { PublicRoutes, Routes as DCHRoutes } from "./Routes";

import { AuthoritySummary, OrgId, OrganisationResponse } from "./data/Enodia";
import {
  DchClass,
  EntityClassesContext,
} from "data/EntityClasses/EntityClassesContext";
import { ClassHypernym } from "data/Mason";
import {
  EntityPropertiesContext,
  EntityPropertyDefinition,
  EntityPropertySummary,
} from "data/Mason/EntityProperties";
import { ApplicationCategoriesContext, CategoryResponse } from "data/Metis";

import {
  Auth0Context,
  useAuth0Context,
  GrafanaContext,
  useGrafanaContext,
} from "components/Index/Authentication";

export type EnodiaContext = {
  orgId?: OrgId;
  setOrgId: (_: OrgId | undefined) => void;
  orgList?: OrganisationResponse[];
  setOrgList: (_: OrganisationResponse[] | undefined) => void;
  prevOrg?: any;
};
type AuthorityContextType = {
  canAssignAuthoritiesList: AuthoritySummary[];
  setCanAssignAuthorities: (_: AuthoritySummary[]) => void;
  authoritiesBeenSet: boolean;
  setAuthoritiesBeenSet: (_: boolean) => void;
};

export const EnodiaOrgContext = React.createContext<EnodiaContext>({
  setOrgId: () => {},
  setOrgList: () => [],
});

export const AuthorityContext = React.createContext<AuthorityContextType>({
  canAssignAuthoritiesList: [],
  setCanAssignAuthorities: () => [],
  authoritiesBeenSet: false,
  setAuthoritiesBeenSet: () => [],
});

export const AuthContext = React.createContext<Auth0Context>({
  signup: () => {},
  login: () => {},
  logout: () => {},
  checkSession: () => {},
  resetError: () => {},
  resetSessionStorage: () => {},
  deferRender: (element: JSX.Element) => element,
});

export const DashboardContext = React.createContext<GrafanaContext>({
  login: () => {},
  logout: () => {},
  checkSession: () => {},
  deferRender: (element: JSX.Element) => element,
  session: undefined,
});

const FIFTEEN_MINUTE_MS = 9e5;

export const App = () => {
  const [orgId, setOrgId] = React.useState<OrgId>();
  const [orgList, setOrgList] = React.useState<Array<OrganisationResponse>>();

  const prevOrg = useRef();

  const [entityPropertyDefinitions, setEntityPropertyDefinitions] = useState<
    Map<string, EntityPropertyDefinition>
  >(new Map());
  const [entityPropertiesList, setEntityPropertiesList] = useState<
    Array<EntityPropertySummary>
  >([]);
  const [canAssignAuthoritiesList, setCanAssignAuthoritiesList] = useState<
    AuthoritySummary[]
  >([]);
  const [authoritiesBeenSet, setAuthoritiesBeenSet] = useState<boolean>(false);

  const [entityClasses, setEntityClasses] = useState<
    Map<ClassHypernym, Map<string, DchClass>>
  >(new Map());

  const [applicationCategories, setApplicationCategories] = useState<
    Map<string, CategoryResponse>
  >(new Map());
  const appCategoriesProviderValue = useMemo(
    () => ({
      categories: applicationCategories,
      setCategories: setApplicationCategories,
    }),
    [applicationCategories]
  );

  const authContext = useAuth0Context();
  const dashboardContext = useGrafanaContext(authContext);

  useEffect(() => {
    authContext.checkSession(FIFTEEN_MINUTE_MS);
    // Create 15 minute timer to refresh token
    const timer = setInterval(() => {
      authContext.checkSession(FIFTEEN_MINUTE_MS);
    }, FIFTEEN_MINUTE_MS);
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const routes = createRoutesFromElements(
    <>
      <Route element={<PublicRoutesLayout />}>{PublicRoutes}</Route>
      <Route element={<PrivateRoutesLayout />}>{DCHRoutes}</Route>
    </>
  );
  const router = createBrowserRouter(routes, {
    basename: process.env.PUBLIC_URL,
  });

  return (
    <AuthContext.Provider value={authContext}>
      <DashboardContext.Provider value={dashboardContext}>
        <EnodiaOrgContext.Provider
          value={{
            orgId,
            setOrgId,
            orgList,
            setOrgList,
            prevOrg,
          }}
        >
          <EntityClassesContext.Provider
            value={{ entityClasses, setEntityClasses }}
          >
            <AuthorityContext.Provider
              value={{
                canAssignAuthoritiesList,
                setCanAssignAuthorities: setCanAssignAuthoritiesList,
                authoritiesBeenSet,
                setAuthoritiesBeenSet,
              }}
            >
              <EntityPropertiesContext.Provider
                value={{
                  entityPropertyDefinitions,
                  setEntityPropertyDefinitions,
                  entityPropertiesList,
                  setEntityPropertiesList,
                }}
              >
                <ApplicationCategoriesContext.Provider
                  value={appCategoriesProviderValue}
                >
                  <RouterProvider router={router} />
                </ApplicationCategoriesContext.Provider>
              </EntityPropertiesContext.Provider>
            </AuthorityContext.Provider>
          </EntityClassesContext.Provider>
        </EnodiaOrgContext.Provider>
      </DashboardContext.Provider>
    </AuthContext.Provider>
  );
};
