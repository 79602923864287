/** @jsxImportSource @emotion/react */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "semantic-ui-react";
import tw from "twin.macro";
import { BuildingId, ModelId, ModelRootType, SiteId } from "data/brick";
import { OrgId } from "data/Enodia";
import { RdfLink } from "components/SitesAndBuildings/Common";
import { ModelUploadModal } from "./ModelUploadModal";
import {
  ValidationReportModal,
  ValidationReportProps,
} from "./ValidationReportModal";

type CreateModelProps = {
  orgId: OrgId;
  siteId: SiteId;
  buildingId?: BuildingId;
  modelType: ModelRootType;
  createEmptyModel: (modelType: ModelRootType, id: ModelId) => void;
};

export const CreateModel: React.FunctionComponent<CreateModelProps> = (
  props,
) => {
  const { orgId, siteId, buildingId, modelType, createEmptyModel } = props;
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [validationReportProps, setValidationReportProps] =
    useState<ValidationReportProps>();
  const modelId = (
    modelType === ModelRootType.Site ? siteId : buildingId
  ) as string;
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <ModelUploadModal
        orgId={orgId as OrgId}
        siteId={siteId}
        buildingId={buildingId}
        showModal={showUploadModal}
        setShowModal={setShowUploadModal}
        modelType={modelType}
        existingModel={false}
        setValidationReportProps={setValidationReportProps}
      />
      {validationReportProps?.validationReport && (
        <ValidationReportModal
          showModal={!!validationReportProps.validationReport}
          {...validationReportProps}
          onClose={() => {
            setValidationReportProps(undefined);
            if (!validationReportProps?.isError) navigate(0);
          }}
        />
      )}
      <div css={tw`mx-4`}>
        <p>No model found. Create one using one of these methods:</p>
        <div css={tw`flex items-center`}>
          <div css={tw`flex-within-25`}>
            <Button primary fluid onClick={() => setShowUploadModal(true)}>
              Upload from File
            </Button>
          </div>
          <span css={tw`flex-1 pl-4`}>
            Directly upload an existing {modelType} model in <RdfLink /> format
            with a .ttl file extension.
          </span>
        </div>
        <div css={tw`mt-4 flex items-center`}>
          <div css={tw`flex-within-25`}>
            <Button
              primary
              fluid
              onClick={() => createEmptyModel(modelType, modelId as ModelId)}
            >
              Configure Manually
            </Button>
          </div>
          <span css={tw`flex-1 pl-4`}>
            Start with an empty {modelType} model and edit it manually.
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};
