import React from "react";
import { useFormContext } from "react-hook-form";
import {
  EquipmentId,
  EquipmentType,
  LocationId,
  PointId,
  ZoneId,
} from "data/brick";
import { NodeFormFields } from "data/Mason/ModelDraft/ModelDraftUtils";
import { ClassHypernym } from "data/Mason";
import { EntityProperty } from "../../EntityProperties/EntityProperty";
import { EntityProperties } from "../../EntityProperties/EntityProperties";
import { ModelEntityCollectionProps } from "../ModelFormUtils";
import {
  useIsFedByWatcher,
  usePointsWatcher,
  useFeedsWatcher,
  useLocationsWatcher,
} from "../RelationshipWatchers";
import {
  Comment,
  CommonInputProps,
  DeleteButton,
  Feeds,
  Id,
  IsFedBy,
  Locations,
  Name,
  Points,
  TypeInput,
} from "./CommonInputs";

export type EquipmentFields = {
  type?: EquipmentType;
  isFedBy?: EquipmentId[];
  feeds?: (LocationId | EquipmentId | ZoneId)[];
  parts?: EquipmentId[];
  partOf?: EquipmentId;
  locations?: LocationId[];
  points?: PointId[];
  properties?: EntityProperty[];
} & NodeFormFields;

export const Equipment = ({
  isReadOnly,
  index,
  modelMeta,
  deleteNode,
}: ModelEntityCollectionProps) => {
  const { getValues } = useFormContext();

  const inputProps: CommonInputProps = {
    isReadOnly,
    formFieldName: "equipment",
    objectName: "equipment",
    index,
    currentEntityId: getValues(`equipment.${index}.fieldId`),
  };

  useEquipmentRelationshipWatcher(inputProps);

  return (
    <>
      <Id {...inputProps} />
      <Name {...inputProps} />
      <TypeInput
        {...inputProps}
        hierarchyParams={{
          hypernym: ClassHypernym.Equipment,
          parent: "Equipment",
        }}
      />
      <IsFedBy {...inputProps} />
      <Feeds {...inputProps} />
      {/* TODO - add these back in once bug DCH-4832 is fixed to allow saving when pass these to back end
          <Parts {...inputProps} />
      <PartOf {...inputProps} /> */}
      <Locations {...inputProps} {...modelMeta} />
      <Points {...inputProps} />
      <Comment {...inputProps} />
      <EntityProperties
        {...inputProps}
        hypernym={ClassHypernym.Equipment}
        parentClass="Equipment"
      />
      {!isReadOnly && (
        <DeleteButton
          onDelete={() => deleteNode(index)}
          objectName="equipment"
        />
      )}
    </>
  );
};

const useEquipmentRelationshipWatcher = (inputProps: CommonInputProps) => {
  const { isReadOnly, formFieldName, index } = inputProps;
  const { getValues } = useFormContext();

  const entityIdentifier = `${formFieldName}.${index}`;
  const entityFieldId = getValues(`${entityIdentifier}.fieldId`);

  const watcherProps = { isReadOnly, entityFieldId, entityIdentifier };
  useIsFedByWatcher(watcherProps);
  useFeedsWatcher(watcherProps);
  usePointsWatcher(watcherProps);
  useLocationsWatcher(watcherProps);
};
