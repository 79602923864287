import {
  ClassificationDirectiveResponse,
  EntityPropertyKeyValue,
} from "data/Aletheia";
import { Row } from "@tanstack/react-table";

// side by side comparision of each entity property key value.
export type EntityPropertyComparisonRow = {
  original: EntityPropertyKeyValue | undefined;
  proposed: EntityPropertyKeyValue | undefined;
};

type KeyValueEP = {
  ep_type: string;
  key: string;
  value: string | number;
  hasUnit?: string;
};

/**
 * @description Determines which key name to return for initialised entity property sets
 * Since there can be more than 1 instance of keyValue, we need to get/set by its key vs. other ep_types which are unique
 */
const determineKey = (ep: EntityPropertyKeyValue) =>
  ep.ep_type === "brick:keyValue"
    ? `keyValue:${(ep as KeyValueEP).key}`
    : ep.ep_type;

/**
 * this function determines what is prioritized for the RHS of a directive row.
 * priority of overwriting : computedValue > currentValue
 * Cases:
 *  - Only preload computed if there are no manual changes saved
 *  - User values replace those computed values.
 *  - user values are only displayed if no computed values are present.
 *  - When currentValue is populated and computed value is empty - it represents that all entityproperties are to be deleted from current point
 *  - when proposed -> entityproperties === null, it means no changes are made
 */
const mergeComputedAndProposedEntityProperties = (
  row: Row<ClassificationDirectiveResponse>,
) => {
  const resultMap = new Map<string, EntityPropertyKeyValue>();
  const currArr =
    row.original.currentPointState?.entityProperties?.currentValue;

  const computedArr =
    row.original.proposedPointState.entityProperties?.computedValue ?? [];

  const userArr =
    row.original.proposedPointState.entityProperties?.userValue ?? [];

  const overridenByUser =
    row.original.proposedPointState.entityProperties?.overridenByUser;

  // when there are no rules applied to entity properties, we want to copy the values to the RHS
  if (row.original.proposedPointState.entityProperties === null) {
    currArr.forEach((ep) => resultMap.set(determineKey(ep), ep));
  }
  if (!overridenByUser)
    computedArr.forEach((ep) => resultMap.set(determineKey(ep), ep));

  userArr.forEach((ep) => resultMap.set(determineKey(ep), ep));

  return Array.from(resultMap.values());
};

/**
 * merges only the entity property before and after values from ClassificationDirectiveResponse
 * into a simplified object called EntityPropertyComparison row
 * to render table rows and for side-by-side comparison
 */
export const mergeToComparisonRows = (
  row: Row<ClassificationDirectiveResponse>,
): EntityPropertyComparisonRow[] => {
  const resMap = new Map<string, EntityPropertyComparisonRow>();

  row.original.currentPointState?.entityProperties?.currentValue?.forEach(
    (ep) => {
      resMap.set(determineKey(ep), {
        original: ep,
        proposed: undefined,
      });
    },
  );

  mergeComputedAndProposedEntityProperties(row).forEach((ep) => {
    // check if it already exists, otherwise create a new comparison row object
    const comparisonResult = resMap.get(determineKey(ep)) || {
      original: undefined,
      proposed: undefined,
    };

    resMap.set(determineKey(ep), { ...comparisonResult, proposed: ep });
  });

  return Array.from(resMap.values());
};

// under assumption that ep_type is immutable in the whole process, we only compare the declared contents of each row
export const isEntityPropertySame = (
  original: EntityPropertyKeyValue | undefined,
  proposed: EntityPropertyKeyValue | undefined,
) => {
  if (!original && proposed) return true; // addition of new proposed row
  if (original && proposed) {
    const originalKeys = Object.keys(original);
    const proposedKeys = Object.keys(proposed);

    // check if ep length same
    if (originalKeys.length !== proposedKeys.length) {
      return false;
    }
    // check if contents of ep are same
    for (let key of originalKeys) {
      if (original[key] !== proposed[key]) return false;
    }
    return true;
  }

  // no original or proposed - should not ever reach this false statement
  return false;
};
