/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import { Button, Checkbox, Icon } from "semantic-ui-react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { ClassificationDirectiveResponse, DirectiveMode } from "data/Aletheia";
import { SelectInput, TextInput } from "components/shared";
import { matchSorter } from "match-sorter";
import { ReactNode, useEffect, useState } from "react";

export const generateVisibleColumnId = (
  mode: DirectiveMode,
  headerId: string,
) => {
  return mode === DirectiveMode.current
    ? `current-${headerId}`
    : `proposed-${headerId}`;
};

export enum DirectiveOptionsKeys {
  pointId = "pointId",
  class = "class",
  entityProperties = "entityProperties",
  label = "label",
  unit = "unit",
  action = "action",
}

/** CLASSIFICATION TABLE COLUMN DEFINITION */
const columnHelper = createColumnHelper<ClassificationDirectiveResponse>();
export const classifierDirectiveColumns = [
  columnHelper.accessor((row: ClassificationDirectiveResponse) => row.action, {
    header: "",
    id: "action",
    cell: ({ row }) => (
      <div className="px-1">
        <Checkbox
          {...{
            id: row.original.id,
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      </div>
    ),
    enableHiding: false,
  }),
  columnHelper.group({
    header: "Before",
    columns: [
      columnHelper.accessor(
        (row: ClassificationDirectiveResponse) =>
          row.currentPointState.pointId.currentValue,
        {
          header: "Point ID",
          id: generateVisibleColumnId(
            DirectiveMode.current,
            DirectiveOptionsKeys.pointId,
          ),
          enableHiding: false,
        },
      ),
      columnHelper.accessor((row: ClassificationDirectiveResponse) => null, {
        header: () => null,
        cell: ({ row }) => {
          return row.getCanExpand() ? (
            <Button
              basic
              active={row.getIsExpanded()}
              inverted
              css={tw`cursor-pointer p-0`}
              onClick={() => row.toggleExpanded()}
            >
              Properties
              <Icon
                size="small"
                name={row.getIsExpanded() ? "chevron down" : "chevron right"}
              />
            </Button>
          ) : null;
        },
        id: "apply-rules-entityProperties",
        enableHiding: false,
      }),
      columnHelper.accessor(
        (row: ClassificationDirectiveResponse) =>
          row.currentPointState.entityProperties,
        {
          id: generateVisibleColumnId(
            DirectiveMode.current,
            DirectiveOptionsKeys.entityProperties,
          ),
          header: "",
          cell: () => null,
          enableHiding: false,
        },
      ),
      columnHelper.accessor(
        (row: ClassificationDirectiveResponse) =>
          row.currentPointState.label.currentValue,
        {
          header: "Label",
          id: generateVisibleColumnId(
            DirectiveMode.current,
            DirectiveOptionsKeys.label,
          ),
        },
      ),
      columnHelper.accessor(
        (row: ClassificationDirectiveResponse) =>
          row.currentPointState.class.currentValue,
        {
          id: generateVisibleColumnId(
            DirectiveMode.current,
            DirectiveOptionsKeys.class,
          ),
          header: "Class",
        },
      ),
      columnHelper.accessor(
        (row: ClassificationDirectiveResponse) =>
          row.currentPointState.unit.currentValue,
        {
          id: generateVisibleColumnId(
            DirectiveMode.current,
            DirectiveOptionsKeys.unit,
          ),
          header: "UoM",
        },
      ),
    ],
  }),
  columnHelper.accessor(() => null, {
    header: () => <Icon name="angle right" />,
    id: "separator",
  }),
  columnHelper.group({
    header: "After",
    columns: [
      columnHelper.accessor(
        (row: ClassificationDirectiveResponse) =>
          row.proposedPointState.label?.userValue ??
          row.proposedPointState.label?.computedValue,
        {
          header: "Label",
          id: generateVisibleColumnId(
            DirectiveMode.proposed,
            DirectiveOptionsKeys.label,
          ),
        },
      ),
      columnHelper.accessor(
        (row: ClassificationDirectiveResponse) =>
          row.proposedPointState.class?.userValue ??
          row.proposedPointState.class?.computedValue,
        {
          header: "Class",
          id: generateVisibleColumnId(
            DirectiveMode.proposed,
            DirectiveOptionsKeys.class,
          ),
        },
      ),
      columnHelper.accessor(
        (row: ClassificationDirectiveResponse) =>
          row.proposedPointState.unit?.userValue ??
          row.proposedPointState.unit?.computedValue,
        {
          header: "UoM",
          id: generateVisibleColumnId(
            DirectiveMode.proposed,
            DirectiveOptionsKeys.unit,
          ),
        },
      ),
    ],
  }),
];

// Render of cells logic
export const defaultColumn: Partial<
  ColumnDef<ClassificationDirectiveResponse>
> = {
  cell: function Cell({
    getValue,
    row: { original, index },
    column: { id },
    table,
  }) {
    const initialValue = getValue() as ReactNode;

    // We need to keep and update the state of the cell normally
    const [value, setValue] = useState(initialValue);
    const [classSearchQuery, setClassSearchQuery] = useState("");
    const [unitSearchQuery, setUnitSearchQuery] = useState("");

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    // we're only interested in the last part of the id, which tells us what input type to render
    const idSplit = id.split("-");
    const key = idSplit[idSplit.length - 1];

    // When the input is blurred, we'll call our table meta's updateData function
    const onBlur = () => {
      if (value) {
        table.options?.meta?.updateProposedChange(
          original,
          key as DirectiveOptionsKeys,
          value,
        );
        table.options.meta?.setActivePosition(undefined);
      }
    };
    if (id.includes("pointId"))
      return (
        <b css={[tw` font-bold`]} onClick={() => {}}>
          {initialValue as string}
        </b>
      );
    const activePosition: string | undefined =
      table.options.meta?.editActivePosition;

    if (activePosition) {
      const splitString = activePosition?.split("_");
      const row = parseInt(splitString[0]);
      const columnId = splitString?.slice(1).join("_");
      if (index === row && columnId === id) {
        // we want exact match to id, since we want only the RHS to be edited
        switch (columnId) {
          case "proposed-label":
            return (
              <TextInput
                placeholder="label"
                value={value as string}
                onChange={(_, { value }) => setValue(value)}
                onBlur={onBlur}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onBlur();
                  }
                }}
              />
            );
          case "proposed-class":
            return (
              <SelectInput
                search
                placeholder="Class"
                options={
                  classSearchQuery
                    ? matchSorter(
                        table.options.meta?.classOptions,
                        classSearchQuery,
                        { keys: ["text"] },
                      )
                    : (table.options.meta?.classOptions ?? [])
                }
                value={value as string}
                onChange={(_, { value }) => {
                  table.options.meta?.updateProposedChange(
                    original,
                    key as DirectiveOptionsKeys,
                    value,
                  );
                  table.options.meta?.setActivePosition(undefined);
                  if (!value) setClassSearchQuery("");
                }}
                onSearchChange={(_, { searchQuery }) => {
                  setClassSearchQuery(searchQuery);
                }}
              />
            );

          case "proposed-unit":
            return (
              <SelectInput
                search
                placeholder="UoM"
                options={
                  unitSearchQuery
                    ? matchSorter(
                        table.options.meta?.unitsOptions,
                        unitSearchQuery,
                        { keys: ["text"] },
                      )
                    : (table.options.meta?.unitsOptions ?? [])
                }
                value={value as string}
                onChange={(_, { value }) => {
                  table.options.meta?.updateProposedChange(
                    original,
                    key as DirectiveOptionsKeys,
                    value,
                  );
                  table.options.meta?.setActivePosition(undefined);
                  if (!value) setUnitSearchQuery("");
                }}
                onSearchChange={(_, { searchQuery }) =>
                  setUnitSearchQuery(searchQuery)
                }
              />
            );
          default:
            return <div css={tw`break-all`}>{initialValue as string}</div>;
        }
      }
    }

    return <div css={tw`break-all`}>{initialValue}</div>;
  },
};
