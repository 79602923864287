/** @jsxImportSource @emotion/react */

import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { DropdownItemProps } from "semantic-ui-react";
import tw from "twin.macro";
import {
  aletheiaApi,
  DataPoolCompositeId,
  DataPoolListItem,
} from "data/Aletheia";
import { EnodiaOrgContext } from "App";
import { SelectInput, UIStatus, UIStatusWrapper } from "components/shared";

type DataGroupProps = {
  id?: DataPoolCompositeId;
  setSelectedDataPool: (datapoolId: DataPoolCompositeId) => void;
  setShowResults: Dispatch<SetStateAction<boolean>>;
  disabled: boolean;
};

const toOptionIdOrName = function (a: DataPoolListItem): DropdownItemProps {
  return {
    value: a.compositeId,
    text: a.name ? `${a.name} (${a.id})` : `${a.id}`,
  };
};

export const DataPoolSelectInput: FunctionComponent<DataGroupProps> = ({
  id,
  setSelectedDataPool,
  setShowResults,
  disabled,
}) => {
  const { orgId } = useContext(EnodiaOrgContext);
  const [status, setStatus] = useState<UIStatus>(new UIStatus());
  const [dataPools, setDataPools] = useState<Array<DataPoolListItem>>([]);

  // on first load, preload the list of available datagroups
  // onClassifierSet Search mode, refresh the datapool list
  // todo: optimise to reduce API calls when switching between modes if the list doesnt need updating
  useEffect(() => {
    if (orgId)
      aletheiaApi
        .getDataPools({ organisationid: orgId })
        .then((pools) => setDataPools(pools))
        .catch((e) =>
          setStatus((prev) =>
            prev.setError(e.message ?? "Cannot retrieve data pools"),
          ),
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  return (
    <UIStatusWrapper status={status}>
      <div css={tw`w-3/4`}>
        <SelectInput
          testId="select_data_pool"
          disabled={disabled}
          label={"Data Pools"}
          onChange={(_, { value }) => {
            setSelectedDataPool(value as DataPoolCompositeId);
            setShowResults(false);
          }}
          value={id || ""}
          options={dataPools.map(toOptionIdOrName)}
          isMulti={false}
          required
          isLoading={status.indeterminate}
          search={true}
          placeholder="Select a data pool"
        />
      </div>
    </UIStatusWrapper>
  );
};
