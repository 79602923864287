import { Observation, SingleStreamObservation } from "data/senaps";

/**
 * Transforms Senaps observations to an array of <timestamp, value> pairs.
 *
 * @param streamData - Array of observations as read from Senaps. Expects a type of `Array<{"t": <timestamp>, "v": {"v": <value>}}>` or `Array<{<timestamp>: "v": {"v": <value>}}>`.
 * @param vectorLookup - Mapping vector index to Stream Health Monitor metrics.
 * @param streamid - String ID of the stream to gather data for.
 * @param metric - String ID of the metric to gather data of for the specified stream.
 * @returns Array of <x: Date, y: number> readings for the requested metric and stream, or an empty list if an error has occurred.
 */
export const gatherResults = (
  streamData: Array<Observation>,
  vectorLookup: Record<number, string>,
  streamid: string,
  metric: string
) => {
  try {
    if (streamData.length === 0) {
      return [];
    }

    const selected_metrics_index: number = Object.values(vectorLookup).indexOf(
      metric
    );

    if (selected_metrics_index === undefined) {
      console.error(`Could not find metric in vector lookup (${metric})`);
      return [];
    }

    // Convert timestamps to TS Dates
    var t: Array<Date> = [];
    var v: Array<number> = [];

    // Extract observations from the payload - here we have to account for how Senaps returns multiple stream results differently...
    if (streamData.every((x) => Object.keys(x).includes("t"))) {
      var sd = streamData.flatMap((x) => x as SingleStreamObservation);

      // Handle case where a single stream was requested -- here we assume that the stored stream is the same as the requested stream ID!
      t = sd.flatMap((x) => new Date(x.t));

      // Extract values from Senaps observations
      v = sd.map((x) => (x.v.v as Array<number>)[selected_metrics_index]);
    } else {
      // Handle case where multiple streams were requested
      t = streamData.flatMap((x) => Object.keys(x)).flatMap((x) => new Date(x));
      v = streamData.flatMap((m) =>
        Object.entries(m).map(([_, val]) => {
          const idx = Object.keys(val).indexOf(streamid);
          const vv = val as Record<string, { v: Array<number> }>;

          if (idx === -1) {
            return NaN;
          }
          return Object.values(vv)[idx].v[selected_metrics_index];
        })
      );
    }

    // Catch error in case of timestamps not being same length as value
    if (t.length !== v.length) {
      console.error(
        "Found different length of timestamps and values as read from Senaps."
      );
      return [];
    }

    // Return datetime, value pairs as we've collected
    return t
      .flatMap((ts, ii) => ({ x: new Date(ts), y: v[ii], name: metric }))
      .sort((a, b) => a.x.getTime() - b.x.getTime());
  } catch (e) {
    console.error(`Unable to read results (${e})`);
    return [];
  }
};

/**
 * Transforms Senaps observations to an array of <timestamp, value> pairs.
 *
 * @param streamData - Array of observations as read from Senaps. Expects a type of `Array<{"t": <timestamp>, "v": {"v": <value>}}>` or `Array<{<timestamp>: "v": {"v": <value>}}>`.
 * @param vectorLookup - Mapping vector index to Stream Health Monitor metrics.
 * @param streamids - String ID of the stream to gather data for.
 * @param metric - String ID of the metric to gather data of for the specified stream.
 * @returns Array of <x: Date, y: number> readings for the requested metric and stream, or an empty list if an error has occurred.
 */
export const gatherAverageResults = (
  streamData: Array<Observation>,
  vectorLookup: Record<number, string>,
  streamids: Array<string>,
  metric: string
) => {
  try {
    if (streamData.length === 0) {
      return [];
    }

    const selected_metrics_index: number = Object.values(vectorLookup).indexOf(
      metric
    );

    if (selected_metrics_index === undefined) {
      console.error(`Could not find metric in vector lookup (${metric})`);
      return [];
    }

    // Convert timestamps to TS Dates
    let t: Array<Date> = [];
    let v: Array<number> = [];

    // Extract observations from the payload - here we have to account for how Senaps returns multiple stream results differently...
    if (streamData.every((x) => x.hasOwnProperty("t"))) {
      // Handle case where a single stream was requested
      // OR multiple streams were requested separately and merged

      // Extract values from Senaps observations, group them by time
      let valuesByTime: { [time: string]: number[] } = {};
      for (let i = 0; i < streamData.length; i++) {
        const o = streamData[i] as SingleStreamObservation;
        const tmp = o.t;
        const values = valuesByTime.hasOwnProperty(tmp)
          ? valuesByTime[tmp]
          : [];
        valuesByTime[tmp] = values.concat(
          (o.v.v as Array<number>)[selected_metrics_index]
        );
      }

      // Get the list of unique time
      const tArray = Array.from(
        new Set(streamData.map((x) => (x as SingleStreamObservation).t))
      );

      // Take the average of all received values
      v = tArray.map(
        (t) =>
          valuesByTime[t].reduce((a, c) => a + c, 0) / valuesByTime[t].length
      );

      t = tArray.map((t) => new Date(t));
    } else {
      // Handle case where multiple streams were requested
      t = streamData.flatMap((x) => Object.keys(x)).map((x) => new Date(x));
      v = streamData.flatMap((m) =>
        Object.entries(m).map(([_, val]) => {
          let valCount = 0;
          let valAgg = 0;
          const vv = val as Record<string, { v: Array<number> }>;

          streamids.forEach((s) => {
            if (Object.keys(val).includes(s)) {
              valCount += 1;
              valAgg += Object.values(vv)[Object.keys(vv).indexOf(s)].v[
                selected_metrics_index
              ];
            }
          });
          return valAgg / valCount;
        })
      );
    }

    // Catch error in case of timestamps not being same length as value
    if (t.length !== v.length) {
      console.error(
        "Found different length of timestamps and values as read from Senaps."
      );
      return [];
    }

    // Return datetime, value pairs as we've collected
    return t
      .map((ts, ii) => ({ x: ts, y: v[ii], name: metric }))
      .sort((a, b) => a.x.getTime() - b.x.getTime());
  } catch (e) {
    console.error(`Unable to read results (${e})`);
    return [];
  }
};
