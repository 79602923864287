/** @jsxImportSource @emotion/react */

import React, { useRef } from "react";
import { Icon } from "semantic-ui-react";
import tw from "twin.macro";
import { SiteId } from "data/brick";
import { SelectedNode } from "../Visualisation";
import { BrickClass, VizModel } from "../VizModelUtil";
import NodePanel from "./NodePanel";

type Props = {
  siteId: SiteId;
  vizModel?: VizModel;
  selectedNode?: SelectedNode;
  setSelectedNode: (_?: SelectedNode) => void;
  selectedNodeStateUuid: string;
};

export const SelectedNodePanel: React.FunctionComponent<Props> = (props) => {
  const {
    vizModel,
    selectedNode,
    siteId,
    setSelectedNode,
    selectedNodeStateUuid,
  } = props;
  let node = useRef<BrickClass | undefined>();

  const renderNodeDetails = () => {
    if (vizModel && selectedNode) {
      node.current = vizModel.nodes.get(selectedNode.id);
      if (node.current)
        return (
          <NodePanel
            key={selectedNodeStateUuid}
            node={node.current}
            siteId={siteId}
            setSelectedNode={setSelectedNode}
            vizModel={vizModel}
          />
        );
      return;
    }
    return <React.Fragment></React.Fragment>;
  };

  const CloseIcon = () => (
    <div css={tw`absolute top-2 right-2 cursor-pointer`}>
      <Icon name="close" onClick={() => setSelectedNode(undefined)} />
    </div>
  );

  return (
    <div
      className="dch-scrollbar"
      css={tw`border border-white border-solid h-full px-4 py-2 rounded bg-black relative overflow-auto`}
    >
      <CloseIcon />
      {renderNodeDetails()}
    </div>
  );
};
