/** @jsxImportSource @emotion/react */

import React, { useContext, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { Icon, Dimmer, Grid, Table, Button } from "semantic-ui-react";
import tw from "twin.macro";
import { EnodiaOrgContext } from "App";
import { Path } from "Routes";
import { RoleSetResponse } from "data/Enodia";
import {
  EMPTY_DESCRIPTION,
  FilterInput,
  PageSection,
  SelectInput,
  toOptionTypeBase,
  UIStatus,
  UIStatusWrapper,
} from "components/shared";
import { userFilterOptions } from "./FilterByOptions";
import { UserPermissions } from "./Permissions";

type Props = {
  roleSets: Array<RoleSetResponse>;
  userList: Array<UserPermissions>;
  roleSetStatus: UIStatus;
  userListStatus: UIStatus;
};
export const RoleSets: React.FunctionComponent<Props> = ({
  roleSets,
  userList,
  roleSetStatus,
  userListStatus,
}) => {
  const { orgId } = useContext(EnodiaOrgContext);
  const [idSearchTerm, setIdSearchTerm] = useState("");
  const [userSearchTerm, setUserSearchTerm] = useState("");
  const navigate = useNavigate();

  const renderRoleSet = (roleSet: RoleSetResponse) => {
    const roleCount = roleSet?.roleIds?.length || 0;
    const userCount = userList.filter((u) =>
      u.roleSets?.map((rs) => rs.id).includes(roleSet.id)
    ).length;
    return (
      <Table.Row key={`roleSet-${roleSet.id}`}>
        <Table.Cell>{roleSet.name ?? roleSet.id}</Table.Cell>
        <Table.Cell>
          <UIStatusWrapper
            status={userListStatus}
            loadingDataMsg=""
          >{`${roleCount} role${roleCount === 1 ? "" : "s"}, ${userCount} user${
            userCount === 1 ? "" : "s"
          }`}</UIStatusWrapper>
        </Table.Cell>
        <Table.Cell>{roleSet.description || EMPTY_DESCRIPTION}</Table.Cell>

        <Table.Cell width={2} textAlign="center">
          <Button
            basic
            inverted
            onClick={() =>
              navigate(
                generatePath(Path.ViewRoleSet, {
                  roleSetId: roleSet.id,
                })
              )
            }
          >
            View
          </Button>
        </Table.Cell>
      </Table.Row>
    );
  };

  const renderNoResults = () => {
    return (
      <Table.Row>
        <Table.Cell>No RoleSets Found</Table.Cell>
      </Table.Row>
    );
  };

  const users = userFilterOptions(userList);

  const roleSetsWithSelectedUser = userList
    .filter((u) => u.id === userSearchTerm)
    .flatMap((u) => u.roleSets)
    .map((rs) => rs.id);

  const filteredRoleSets =
    roleSets.length > 0
      ? roleSets.filter((rs) => {
          return (
            (!idSearchTerm || rs.name.toLowerCase().includes(idSearchTerm)) &&
            (!userSearchTerm || roleSetsWithSelectedUser.includes(rs.id))
          );
        })
      : [];
  return (
    <PageSection
      header={
        <div>
          <Icon name="users" color="purple" inverted />
          <span> RoleSet Summary</span>
        </div>
      }
    >
      <div
        css={tw`absolute bg-black px-2 right-6`}
        style={{
          top: "calc((1em + .25rem) * -1)", //position according to half the button's own height
        }}
      >
        <Button
          primary
          size="small"
          onClick={() => navigate(Path.AddRoleSet)}
          disabled={!orgId}
        >
          <Icon name="plus" />
          Add RoleSet
        </Button>
      </div>
      <UIStatusWrapper status={roleSetStatus} customCss={tw`z-0`} fitted>
        <Dimmer.Dimmable blurring dimmed={!orgId} css={tw`z-0`}>
          <Dimmer active={!orgId} css={tw`w-full`}>
            <div>Select an organisation to view roles</div>
          </Dimmer>
          <div css={tw`w-full`} className="dch-scrollbar">
            <Grid>
              <Grid.Row columns="equal">
                <Grid.Column>
                  <FilterInput
                    fieldLabel="RoleSet Search"
                    placeholder="Enter RoleSet Name..."
                    handleSearch={(term: string) =>
                      setIdSearchTerm(term.toLowerCase())
                    }
                  />
                </Grid.Column>
                <Grid.Column>
                  <SelectInput
                    label="Filter by user"
                    placeholder="Search for user..."
                    value={userSearchTerm}
                    options={users.map(toOptionTypeBase)}
                    onChange={(_, { value }) => {
                      setUserSearchTerm(value as string);
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <div
                    className="dch-scrollbar"
                    style={{ maxHeight: "20rem", overflowY: "auto" }}
                  >
                    <Table
                      fixed
                      singleLine
                      inverted
                      striped
                      className="collapsed"
                    >
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell width={4}>
                            Role Sets
                          </Table.HeaderCell>
                          <Table.HeaderCell width={3}>
                            No. of Roles/Users
                          </Table.HeaderCell>
                          <Table.HeaderCell width={8}>
                            Description
                          </Table.HeaderCell>
                          <Table.HeaderCell width={2}></Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {filteredRoleSets.length > 0
                          ? filteredRoleSets.map((roleSet) =>
                              renderRoleSet(roleSet)
                            )
                          : renderNoResults()}
                      </Table.Body>
                    </Table>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </Dimmer.Dimmable>
      </UIStatusWrapper>
    </PageSection>
  );
};
