import { differenceInDays } from "date-fns";

export enum TimePeriod {
  MOSTRECENT = "Most Recent",
  LASTDAY = "Last Day",
  LASTWEEK = "Last Week",
  LASTMONTH = "Last Month",
  LASTYEAR = "Last Year",
}

/**
 * Function to filter timeseries data to within a requested time range. Valid time ranges include "Most Recent", "Last Day", "Last Week", "Last Year" and "All Time".
 *
 * @param aggString - String value of the requested time period.
 * @param data - Array of <x: Date, y: number> values to generate data for.
 * @returns Array of data which falls within the requested time period.
 */
export const filterPeriod = (
  aggString: string,
  data: Array<{ x: Date; y: any }>
) => {
  const tNow =
    data.length === 0 ? Date.now() : data[data.length - 1].x.getTime();

  const sortDates = (a: { x: Date; y: any }, b: { x: Date; y: any }) =>
    a.x.getTime() - b.x.getTime();

  let filteredData: Array<{ x: Date; y: any }> = data.sort(sortDates);

  switch (aggString) {
    case TimePeriod.MOSTRECENT:
      filteredData = filteredData.slice(-2);
      break;
    case TimePeriod.LASTDAY:
      filteredData = filteredData.filter(
        (v) => differenceInDays(tNow, v.x) <= 1
      );
      break;
    case TimePeriod.LASTWEEK:
      filteredData = filteredData.filter(
        (v) => differenceInDays(tNow, v.x) <= 7
      );
      break;
    case TimePeriod.LASTMONTH:
      filteredData = filteredData.filter(
        (v) => differenceInDays(tNow, v.x) <= 30
      );
      break;
    case TimePeriod.LASTYEAR:
      filteredData = filteredData.filter(
        (v) => differenceInDays(tNow, v.x) <= 365
      );
      break;
    default:
      throw new Error("Invalid tab selection");
  }

  return filteredData;
};
