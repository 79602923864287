import { formatDate } from "components/shared";
import { DataDetails } from ".";

/**
 * Utility function to apply `number.toFixed` which accounting for null.
 *
 * @param v - Number of which to apply toFixed.
 * @param precision - Precision to apply to the input number.
 * @param nullStr - String to return in the case in which the input number is null.
 * @returns String representation of the number at the requested precision, or the nullStr if the input number is null.
 */
export const toFixed = (
  v: number | undefined | null,
  precision: number,
  nullStr: string
) => {
  return v === undefined || v === null ? nullStr : v.toFixed(precision);
};

export const exportToCSV = (
  content: DataDetails,
  filename: string = "results",
  preheader: string = ""
) => {
  const header = [
    "",
    "Period Start",
    "Data Health",
    "Missing Samples",
    "Missing Percent",
    "Outlier Samples",
    "Outlier Percent",
  ];

  const csv = [
    preheader,
    header,
    Object.keys(content)
      .flatMap((x) =>
        [
          x,
          `"${formatDate(content[x].periodStart)}"`,
          content[x].dataHealthMean,
          content[x].missingSamplesMean,
          content[x].missingPercentMean,
          content[x].outlierSamplesMean,
          content[x].outlierPercentMean,
        ].join(",")
      )
      .join("\n"),
  ].join("\n");

  // Append timestamp onto filename
  filename = filename.endsWith(".csv") ? filename.slice(0, -4) : filename;
  filename +=
    "_" + formatDate(content[Object.keys(content)[0]].periodEnd) + ".csv";

  var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    // Create our anchor object to link out to the CSV download
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // Generate a new URL for the link
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      // Attach link object to DOM and activate, then remove the DOM object
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
