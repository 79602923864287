// eslint-disable-next-line react-hooks/exhaustive-deps
import { debounce } from "throttle-debounce";
import { API_CALL_DEBOUNCE_MS } from "data/validation";
import { OrgId, enodiaApi } from "data/Enodia";

export const checkNameIsUnique = debounce(
  API_CALL_DEBOUNCE_MS,
  async (
    orgId: OrgId,
    name: string,
    resolve: (_: string | undefined) => void
  ) => {
    const errorMsg = "A roleset exists with the same name in this organisation";
    const error: string | undefined = await enodiaApi
      .getRoleSets({ limit: 1, organisation: orgId, name: name })
      .then((response) => (response.length > 0 ? errorMsg : undefined));
    resolve(error);
  }
);
