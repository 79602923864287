/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import { DropdownItemProps, Table } from "semantic-ui-react";
import { OrgId } from "data/Enodia";
import { SelectInput } from "components/shared";
import { masonApi } from "data/Mason";
import { DropdownOptionsSelector } from "./OptionsSelector";
import { ScopeTypePermissionsPropsType } from "./Common";

type SitePermissionsProps = ScopeTypePermissionsPropsType;

export const SitePermissions: React.FunctionComponent<SitePermissionsProps> = ({
  role,
  onChange,
  orgId,
  isReadOnly,
  optionsStatus,
  verbOptions,
}) => {
  const sitesOptions = useLabeledSiteOptions({ orgId });

  return (
    <>
      <Table.Cell width={3}>
        <SelectInput
          testId="SiteSelect"
          placeholder="Select site..."
          options={sitesOptions.availableSites}
          required
          search
          onChange={(_, { value }) => {
            onChange({
              ...role,
              scopeId: value as string,
            });
          }}
          isReadOnly={isReadOnly}
          isLoading={sitesOptions.isLoading}
          value={role.scopeId}
          isMulti={false}
        />
      </Table.Cell>
      <DropdownOptionsSelector
        role={role}
        onChange={onChange}
        isReadOnly={isReadOnly}
        optionsStatus={optionsStatus}
        verbOptions={verbOptions}
      />
    </>
  );
};

type UseLabeledSitesParams = { orgId?: OrgId };

function useLabeledSiteOptions({ orgId }: UseLabeledSitesParams): {
  availableSites: Array<DropdownItemProps>;
  isLoading: boolean;
} {
  const [availableSites, setAvailableSites] = useState<
    Array<DropdownItemProps>
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    loadSites(orgId, setAvailableSites, setIsLoading);
  }, [orgId]);
  return {
    availableSites,
    isLoading,
  };
}

function loadSites(
  orgId: OrgId | undefined,
  setAvailableSites: React.Dispatch<React.SetStateAction<DropdownItemProps[]>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
) {
  if (orgId) {
    setIsLoading(true);
    masonApi
      .getSites({ orgId })
      .then((sites) => {
        const sitesDropdownList = sites.map((site) => ({
          value: site.siteId,
          text: !!site.label ? site.label : site.siteId,
        }));
        setAvailableSites(sitesDropdownList);
      })
      .then(() => setIsLoading(false))
      .catch((e) => console.error(`Failed to fetch sites: ${e}`));
  }
}
