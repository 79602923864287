import React from "react";

interface SvgBuildingProps {
  scale?: number;
}

export const SvgBuilding: React.FC<SvgBuildingProps> = ({ scale = 1 }) => (
  <svg
    height={104 * scale}
    width={74 * scale}
    version="1.1"
    viewBox="0 0 74 104"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>BuildingTrace</title>
    <defs>
      <linearGradient
        id="linearGradient-1"
        x1="65.8986845%"
        x2="34.7128028%"
        y1="100.999999%"
        y2="6.24500451e-15%"
      >
        <stop offset="0%" stopColor="#7F1F8D" />
        <stop offset="100%" stopColor="#AB369B" />
      </linearGradient>
      <linearGradient
        id="linearGradient-2"
        x1="101.999998%"
        x2="0%"
        y1="63.2983248%"
        y2="36.9624264%"
      >
        <stop offset="0%" stopColor="#7F1F8D" />
        <stop offset="100%" stopColor="#AB369B" />
      </linearGradient>
      <linearGradient
        id="linearGradient-3"
        x1="60.2716046%"
        x2="40.1234568%"
        y1="100.999999%"
        y2="6.24500451e-15%"
      >
        <stop offset="0%" stopColor="#7F1F8D" />
        <stop offset="100%" stopColor="#AB369B" />
      </linearGradient>
      <linearGradient
        id="linearGradient-4"
        x1="73.1111103%"
        x2="27.7777778%"
        y1="100.999999%"
        y2="6.24500451e-15%"
      >
        <stop offset="0%" stopColor="#7F1F8D" />
        <stop offset="100%" stopColor="#AB369B" />
      </linearGradient>
    </defs>
    <g
      id="BuildingTrace"
      fill="none"
      fillRule="evenodd"
      stroke="none"
      strokeWidth="1"
    >
      <g id="Group-4" transform="translate(12.000000, 6.000000)">
        <polygon
          id="Path"
          points="1 73 25 86 48 74 48 13 25 1 1 13"
          stroke="url(#linearGradient-1)"
          strokeWidth="2"
        />
        <polygon
          id="Path-2"
          fill="url(#linearGradient-2)"
          points="1 14 25 26 48 13 25 2"
        />
        <polygon
          id="Path-3"
          points="6 22 6 29 10 31 10 24"
          stroke="url(#linearGradient-3)"
          strokeWidth="2"
        />
        <polygon
          id="Path-3"
          points="6 37 6 44 10 46 10 39"
          stroke="url(#linearGradient-3)"
          strokeWidth="2"
        />
        <polygon
          id="Path-3"
          points="18 43 18 50 22 52 22 45"
          stroke="url(#linearGradient-3)"
          strokeWidth="2"
        />
        <polygon
          id="Path-3"
          points="6 51 6 58 10 60 10 53"
          stroke="url(#linearGradient-3)"
          strokeWidth="2"
        />
        <polygon
          id="Path-3"
          points="18 57 18 64 22 66 22 59"
          stroke="url(#linearGradient-3)"
          strokeWidth="2"
        />
        <polygon
          id="Path-3"
          points="18 28 18 35 22 37 22 30"
          stroke="url(#linearGradient-3)"
          strokeWidth="2"
        />
        <polygon
          id="Path-3"
          points="28 28 28 35 32 37 32 30"
          stroke="url(#linearGradient-3)"
          strokeWidth="2"
          transform="translate(30.000000, 32.500000) scale(-1, 1) translate(-30.000000, -32.500000) "
        />
        <polygon
          id="Path-3"
          points="39 22 39 29 43 31 43 24"
          stroke="url(#linearGradient-3)"
          strokeWidth="2"
          transform="translate(41.000000, 26.500000) scale(-1, 1) translate(-41.000000, -26.500000) "
        />
        <polygon
          id="Path-3"
          points="28 43 28 50 32 52 32 45"
          stroke="url(#linearGradient-3)"
          strokeWidth="2"
          transform="translate(30.000000, 47.500000) scale(-1, 1) translate(-30.000000, -47.500000) "
        />
        <polygon
          id="Path-3"
          points="39 37 39 44 43 46 43 39"
          stroke="url(#linearGradient-3)"
          strokeWidth="2"
          transform="translate(41.000000, 41.500000) scale(-1, 1) translate(-41.000000, -41.500000) "
        />
        <polygon
          id="Path-3"
          points="39 51 39 58 43 60 43 53"
          stroke="url(#linearGradient-3)"
          strokeWidth="2"
          transform="translate(41.000000, 55.500000) scale(-1, 1) translate(-41.000000, -55.500000) "
        />
        <polygon
          id="Path-3"
          points="28 57 28 64 32 66 32 59"
          stroke="url(#linearGradient-3)"
          strokeWidth="2"
          transform="translate(30.000000, 61.500000) scale(-1, 1) translate(-30.000000, -61.500000) "
        />
        <polyline
          id="Path-4"
          points="9 77 9 67 19 72 19 82"
          stroke="url(#linearGradient-4)"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
);
