/** @jsxImportSource @emotion/react */

import {
  ClassificationPlanId,
  ClassifierSetId,
  DataPoolCompositeId,
} from "data/Aletheia";
import { EnodiaOrgContext } from "App";
import { useContext, useState } from "react";
import { OrgId } from "data/Enodia";

export type PageState = {
  orgId?: OrgId;
  classifierSetId?: ClassifierSetId;
  datapoolId?: DataPoolCompositeId;
  classificationPlanId?: ClassificationPlanId;
};

export type PointClassifierPageState = {
  clear: () => void;
  setClassificationPlanId: (
    classificationPlanId?: ClassificationPlanId
  ) => void;
  setState: (urlParams: Readonly<Partial<PageState>>) => void;
  setPlanId: (planId?: ClassificationPlanId) => void;
  setOrgId: (orgId?: OrgId) => void;
  setSelectedDataPool: (dataPoolId?: DataPoolCompositeId) => void;
  setClassifierSetId: (setId?: ClassifierSetId) => void;
  state: PageState;
};

export function usePointClassifierPageState(): PointClassifierPageState {
  const { orgId } = useContext(EnodiaOrgContext);
  const [state, setPageState] = useState<PageState>({
    orgId: orgId as OrgId,
  });

  const setPlanId = (planId?: ClassificationPlanId) => {
    setPageState((currentState: PageState) => ({
      ...currentState,
      classificationPlanId: planId,
    }));
  };
  const setOrgId = (orgId?: OrgId) => {
    setPageState((currentState: PageState) => ({
      ...currentState,
      orgId: orgId,
    }));
  };
  const setSelectedDataPool = (dataPoolId?: DataPoolCompositeId) => {
    setPageState((currentState: PageState) => ({
      ...currentState,
      datapoolId: dataPoolId,
    }));
  };
  const setClassifierSetId = (setId?: ClassifierSetId) => {
    setPageState((currentState: PageState) => ({
      ...currentState,
      classifierSetId: setId,
    }));
  };
  const setClassificationPlanId = (
    classificationPlanId?: ClassificationPlanId
  ) => {
    setPageState((currentState: PageState) => ({
      ...currentState,
      classificationPlanId: classificationPlanId,
    }));
  };
  const setState = (newState: PageState) => {
    setPageState(newState);
  };
  const clear = () => {
    setPageState(({ orgId }) => ({ orgId }));
  };

  return {
    state,
    setPlanId,
    setOrgId,
    setSelectedDataPool,
    setClassifierSetId,
    setState,
    setClassificationPlanId,
    clear,
  };
}
