import React, { useState } from "react";
import { FieldValues } from "react-hook-form";
import { Button } from "semantic-ui-react";
import { FileInput, PageSection } from "components/shared";
import Form from "components/shared/Forms/ReactHookForm";
import { Grid3Col } from "../ComponentLibrary";

interface FormValues extends FieldValues {
  file?: FileList;
}
const SubmitButton = () => (
  <Button
    inverted
    basic
    size="tiny"
    type="submit"
    style={{ marginTop: "1rem" }}
  >
    Submit
  </Button>
);

export const FileInputs = (props: { showBoundaries: boolean }) => {
  const [file, setFile] = useState<File>();
  const onSubmit = (data: FormValues) => console.log("submitting", data);
  return (
    <>
      <PageSection header="Basic">
        <Grid3Col>
          <div>
            <FileInput
              onChange={(value) => {
                setFile(value);
                console.log("file is set to", file);
              }}
            />
          </div>
          <div>
            Only accepts .ttl
            <FileInput
              onChange={(value) => {
                setFile(value);
                console.log("file is set to", file);
              }}
              accept=".ttl"
            />
          </div>
          <div>
            With error
            <FileInput
              onChange={(value) => {
                setFile(value);
                console.log("file is set to", file);
              }}
              inputValidation={{
                invalid: true,
                errorMessage: "There's something wrong",
              }}
            />
          </div>
          <div>
            <FileInput
              onChange={(value) => {
                setFile(value);
                console.log("file is set to", file);
              }}
              label="Labelled"
            />
          </div>
        </Grid3Col>
      </PageSection>
      <PageSection header="Drag and Drop">
        <Grid3Col>
          <div>
            <FileInput
              onChange={(value) => {
                setFile(value);
                console.log("file is set to", file);
              }}
              dragAndDrop
            />
          </div>
          <div>
            Only accepts .ttl
            <FileInput
              onChange={(value) => {
                setFile(value);
                console.log("file is set to", file);
              }}
              dragAndDrop
              accept=".ttl"
            />
          </div>
        </Grid3Col>
      </PageSection>
      <PageSection header="In a Form">
        <Grid3Col>
          <div>
            <Form<FormValues> onSubmit={(data) => onSubmit(data)}>
              <Form.FileInput<FormValues> name="file" />
              <SubmitButton />
            </Form>
          </div>
          <div>
            Only accepts .ttl
            <Form<FormValues> onSubmit={(data) => onSubmit(data)}>
              <Form.FileInput<FormValues> name="file" accept=".ttl" />
              <SubmitButton />
            </Form>
          </div>
          <div>
            With error
            <Form<FormValues> onSubmit={(data) => onSubmit(data)}>
              <Form.FileInput<FormValues>
                name="file"
                inputValidation={{
                  invalid: true,
                  errorMessage: "There's something wrong",
                }}
              />
              <SubmitButton />
            </Form>
          </div>
          <Form<FormValues> onSubmit={(data) => onSubmit(data)}>
            <Form.FileInput<FormValues> name="file" label="Labelled" />
            <SubmitButton />
          </Form>
          <div>
            <Form<FormValues> onSubmit={(data) => onSubmit(data)}>
              <Form.FileInput<FormValues> name="file" dragAndDrop />
              <SubmitButton />
            </Form>
          </div>
          <div>
            Only accepts .ttl
            <Form<FormValues> onSubmit={(data) => onSubmit(data)}>
              <Form.FileInput<FormValues>
                name="file"
                dragAndDrop
                accept=".ttl"
              />
              <SubmitButton />
            </Form>
          </div>
        </Grid3Col>
      </PageSection>
    </>
  );
};
