/** @jsxImportSource @emotion/react */

import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  ClassificationDirectiveResponse,
  ClassificationPlanId,
  ClassifierId,
  DataPoolCompositeId,
  DirectiveState,
  GetDirectivesParams,
  pointClassifierApi,
} from "data/Aletheia";
import { getPaginatedFromURL } from "data/httpUtil";
import { DCHJsonClassResponse, Unit } from "data/Mason";
import { PaginatedResponse } from "data/paginationHelper";
import { aletheiaEnv } from "reducers/env";
import {
  Pagination,
  resultsPerPageOptions,
  PageTitle,
  UIStatus,
  UIStatusWrapper,
} from "components/shared";
import { ClassificationPlanActionButtons } from "./ClassificationPlanActionButtons";
import { ClassificationPlanTable } from "./ClassificationPlanTable";
import { useNavigate } from "react-router-dom";

type ResultsProps = {
  setShowResults: Dispatch<SetStateAction<boolean>>;
  selectedDataPool: DataPoolCompositeId;
  handleActiveClassifier: (classifierId?: ClassifierId) => void;
  classificationPlanId: ClassificationPlanId;
  schemaClassesList: DCHJsonClassResponse[];
  unitsList: Unit[];
};

const ClassificationPlanResults: React.FunctionComponent<ResultsProps> = (
  props
) => {
  const [status, setStatus] = useState(new UIStatus());
  const [directives, setDirectives] = useState<
    ClassificationDirectiveResponse[]
  >([]);
  const [viewOption, setViewOption] = useState<DirectiveState>(
    DirectiveState.ALL
  );
  const [manualChangesCount, setManualChangesCount] = useState<number>(0);

  // updates to whatever parent index (directive row ) touched when entity properties are being edited
  const [activeManualDirectiveRow, setActiveManualDirectiveRow] = useState<
    number | undefined
  >();

  const navigate = useNavigate();

  // pagination states
  const [links, setLinks] = useState<Record<string, string>>();
  const [classificationPlanLimit, setClassificationPlanLimit] =
    useState<number>(resultsPerPageOptions[0]);

  // assure that component is mounted and not called after component becomes unmounted
  // to address error: Can't perform a react state update on an unmounted component
  const isMountedRef = useRef(true);
  useEffect(() => {
    // Set the ref to false when the component unmounts
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const fetchDirectivesFromParams = useCallback(
    (params: GetDirectivesParams) => {
      if (props.classificationPlanId) {
        processFetchDirectives(
          pointClassifierApi.getDirectives(props.classificationPlanId)(params)
        );
      }
    },
    [props.classificationPlanId]
  );

  const fetchDirectivesFromLink = useCallback((link: string) => {
    processFetchDirectives(
      getPaginatedFromURL(link)(aletheiaEnv).then(
        (r) => r as PaginatedResponse<Array<ClassificationDirectiveResponse>>
      )
    );
  }, []);

  const processFetchDirectives = (
    promise: Promise<PaginatedResponse<Array<ClassificationDirectiveResponse>>>
  ) => {
    setStatus((p) => p.setIndeterminate(true));
    return promise
      .then((os) => {
        setLinks(os.links);
        return os.data;
      })
      .then((res) => setDirectives(res))
      .then(() => setStatus((p) => p.setIndeterminate(false)))
      .catch((e) => {
        setStatus((p) => p.setError(e.message ?? "error fetching directives"));
      });
  };

  useEffect(() => {
    fetchDirectivesFromParams({
      state: viewOption,
      limit: classificationPlanLimit,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDirectivesFromParams, viewOption]);

  const updateClassificationDirectives = (
    directive: ClassificationDirectiveResponse
  ) => {
    setDirectives((oldDirectives) =>
      oldDirectives.map((d) => {
        return d.id === directive.id ? directive : d;
      })
    );
  };

  const refetchDirectives = () => {
    fetchDirectivesFromParams({
      state: viewOption,
      limit: classificationPlanLimit,
    });
  };

  // have to check if component is still mounted on each step of the promise chain
  const onApplyRules = useCallback(() => {
    setStatus((p) => p.setIndeterminate(true));
    pointClassifierApi
      .applyClassificationPlan(props.classificationPlanId)
      .then(
        () =>
          isMountedRef.current && setStatus((p) => p.setIndeterminate(false))
      )
      .then(() => isMountedRef.current && props.setShowResults(false))
      .then(() => isMountedRef.current && setManualChangesCount(0))
      .catch(
        (e) => isMountedRef.current && setStatus((p) => p.setError(e.message))
      );
  }, [props]);

  return (
    <UIStatusWrapper status={status} afterClear={() => navigate(0)}>
      <PageTitle secondaryHeader={"Results"} />
      <ClassificationPlanTable
        directives={directives}
        handleActiveClassifier={props.handleActiveClassifier}
        updateDirectives={updateClassificationDirectives}
        viewOption={viewOption}
        setViewOption={setViewOption}
        schemaClassesList={props.schemaClassesList}
        setStatus={setStatus}
        unitsList={props.unitsList}
        setManualChangesCount={setManualChangesCount}
        setActiveManualDirectiveRow={setActiveManualDirectiveRow}
        onApplyRules={onApplyRules}
      />
      {links && (
        <Pagination
          links={links}
          onPaginationChange={(link) => {
            fetchDirectivesFromLink(link);
          }}
          setLimit={setClassificationPlanLimit}
          limit={classificationPlanLimit}
          dataLength={directives.length}
          refetch={refetchDirectives}
        />
      )}
      <ClassificationPlanActionButtons
        disabled={directives.length === 0}
        classificationPlanId={props.classificationPlanId}
        refetch={refetchDirectives}
        setStatus={setStatus}
        manualChangesCount={manualChangesCount}
        activeManualDirectiveRow={activeManualDirectiveRow}
        onSubmit={onApplyRules}
      />
    </UIStatusWrapper>
  );
};

export default ClassificationPlanResults;
