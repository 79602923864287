import {
  ClassifierSetId,
  ClassifierStats,
  Classifier,
  ClassifierCreateRequest,
  ClassifierId,
  ClassifierMode,
  ClassifierFilterOptions,
  ClassifierRuleOptions,
  EntityPropertyKeyValue,
} from "data/Aletheia";
import { v4 } from "uuid";
import { checkNonEmptyValue } from "../PointClassifierUtils";
import { OrgId } from "data/Enodia";
import { BRICK_KEY_VALUE } from "../EntityProperties";
import { ClassifierOptionsKeys } from "./ClassifierSetTableDefs";

/** CLassifierSetResponse with optional fields to address ClassifierSetCreateRequest Object */
export type ClassifierSetMetadata = {
  id?: ClassifierSetId;
  organisationId: OrgId;
  meta?: ClassifierStats;
  classifiers: Array<ClassifierCreateRequest | Classifier>;
  name: string;
  submittedBy: string;
};

/**
 * DEFAULT VALUES
 */
const defaultFilterValues: ClassifierFilterOptions = {
  id: {
    enabled: false,
  },
  timeseriesId: {
    enabled: false,
  },
  class: {
    enabled: false,
    includeChildren: false,
  },
  label: {
    enabled: false,
  },
  entityProperties: {
    enabled: false,
  },
  entity: {
    enabled: false,
  },
  unit: {
    enabled: false,
  },
};

const defaultExecuteValues: ClassifierRuleOptions = {
  class: {
    enabled: false,
  },
  entityProperties: {
    enabled: false,
  },
  label: {
    enabled: false,
  },
  entity: {
    enabled: false,
  },
  unit: {
    enabled: false,
  },
};

export const defaultClassifierSet: ClassifierSetMetadata = {
  id: "" as ClassifierSetId,
  classifiers: [],
  organisationId: "" as OrgId,
  meta: {
    createdAt: new Date(),
    modifiedAt: new Date(),
    submittedBy: "",
  },
  name: "",
  submittedBy: "",
};

export const clearedClassifierSet = (
  orgId: OrgId,
  setId?: string,
  name?: string
): ClassifierSetMetadata => ({
  id: (setId as ClassifierSetId) ?? undefined,
  organisationId: orgId,
  classifiers: [],
  meta: {
    createdAt: new Date(),
    modifiedAt: new Date(),
    submittedBy: "",
  },
  submittedBy: "",
  name: name ?? "",
});

export const createNewClassifier = (): ClassifierCreateRequest => {
  return {
    id: v4() as ClassifierId,
    enabled: true,
    filterBy: { ...defaultFilterValues },
    executeRules: { ...defaultExecuteValues },
  };
};

/**
 * HANDLERS
 */
export const handleClassifierChange = (
  classifier: ClassifierCreateRequest,
  key: string,
  value: string | number | string[],
  mode: ClassifierMode
) => {
  return mode === ClassifierMode.filter
    ? {
        ...classifier,
        filterBy: {
          ...classifier.filterBy,
          [key]: {
            enabled: !!checkNonEmptyValue(value),
            value: checkNonEmptyValue(value),
          },
        },
      }
    : {
        ...classifier,
        executeRules: {
          ...classifier.executeRules,
          [key]: {
            enabled: !!checkNonEmptyValue(value),
            value: checkNonEmptyValue(value),
          },
        },
      };
};

export const handleEPChange = (
  classifier: ClassifierCreateRequest,
  EPValues: EntityPropertyKeyValue[]
) => {
  const EPclassifier: ClassifierCreateRequest = {
    ...classifier,
    executeRules: {
      ...classifier.executeRules,
      entityProperties: {
        enabled: true,
        value: removeEPEmptyFields(castNumTypeToKeyValues(EPValues)),
      },
    },
  };

  return EPclassifier;
};

//remove all "" values
export const removeEPEmptyFields = (values: EntityPropertyKeyValue[]) => {
  return values.map((ep: EntityPropertyKeyValue) => {
    let newEp: EntityPropertyKeyValue = { ep_type: ep.ep_type };
    for (let key in ep) {
      if (ep[key] !== "" && ep[key] !== null) {
        newEp[key] = ep[key];
      }
    }
    return newEp;
  });
};

// remove all "" values from classifier row
export const removeClassifierEmptyFields = (
  classifier: ClassifierCreateRequest
) => {
  let newClassifier: ClassifierCreateRequest = classifier;

  const filterBy = classifier.filterBy;
  const executeRules = classifier.executeRules;

  for (let key in filterBy) {
    const filterByValue = filterBy[key as keyof ClassifierFilterOptions].value;
    if (filterByValue === "" && filterByValue !== null) {
      delete newClassifier.filterBy[key as keyof ClassifierFilterOptions].value;
    }
  }
  for (let key in executeRules) {
    const executeValue = executeRules[key as keyof ClassifierRuleOptions].value;
    if (executeValue === "" && executeValue !== null) {
      delete newClassifier.executeRules[key as keyof ClassifierRuleOptions]
        .value;

      // address the nested values for entity properties
    } else if (key === ClassifierOptionsKeys.entityProperties && executeValue) {
      newClassifier.executeRules.entityProperties.value = removeEPEmptyFields(
        executeValue as EntityPropertyKeyValue[]
      );
    }
  }
  return newClassifier;
};

// casts type Number to those where hasUnit is declared but the value is still in string type
export const castNumTypeToKeyValues = (values: EntityPropertyKeyValue[]) => {
  return values.map((keyValue) => {
    if (keyValue.ep_type === BRICK_KEY_VALUE && keyValue.value)
      return {
        ...keyValue,
        value: !keyValue.hasUnit ? keyValue.value : Number(keyValue.value),
      };
    return keyValue;
  });
};
