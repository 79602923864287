import React from "react";
import { FieldValues } from "react-hook-form";
import { LocationParentClass } from "data/EntityClasses/ParentClassHelper";
import { ClassHypernym } from "data/Mason";
import {
  convertQueryDtosToEntityProperties,
  getPropertyName,
  sortPropertiesByName,
} from "data/Mason/EntityProperties";
import Form from "components/shared/Forms/ReactHookForm";
import { EntityProperty } from "../../EntityProperties/EntityProperty";
import { EntityProperties } from "../../EntityProperties/EntityProperties";
import { SelectedNode } from "../Visualisation";
import { BrickClass, VizModel } from "../VizModelUtil";
import { renderReadOnlyField } from "./FieldHelpers";
import PanelHeader from "./PanelHeader";
import Relationships from "./Relationships";
import { extractNodeIdFromUri } from "../../ModelUtils";

interface EntityPropertiesFormFields extends FieldValues {
  properties: EntityProperty[];
}
const NodePanel: React.FunctionComponent<{
  node: BrickClass;
  siteId: string;
  setSelectedNode: (_?: SelectedNode) => void;
  vizModel: VizModel;
}> = ({ node, siteId, setSelectedNode, vizModel }) => {
  const entityProperties: EntityProperty[] = convertQueryDtosToEntityProperties(
    node.properties?.sort((a, b) =>
      sortPropertiesByName(
        getPropertyName(a.property),
        getPropertyName(b.property)
      )
    )
  );

  return (
    <>
      <PanelHeader node={node} />
      {node.class.parentClass === LocationParentClass.Building &&
        renderReadOnlyField("Site ID", siteId)}
      {renderReadOnlyField("Id", extractNodeIdFromUri(node.id))}
      {renderReadOnlyField("Name", node.label)}
      {renderReadOnlyField("Comment", node.comment)}
      {node.class.type !== node.class.parentClass &&
        renderReadOnlyField(
          `${node.class.hypernym ?? "Unknown"} Sub-Class`,
          node.class.label
        )}

      {node.class.hypernym === ClassHypernym.Point &&
        renderReadOnlyField("Unit", node.unit)}
      <Relationships
        node={node}
        setSelectedNode={setSelectedNode}
        vizModel={vizModel}
      />
      <Form<EntityPropertiesFormFields>
        onSubmit={() => undefined}
        defaultValues={{ properties: entityProperties }}
      >
        <EntityProperties
          verticalLayout
          isReadOnly={true}
          hypernym={node.class.hypernym}
          parentClass={node.class.parentClass}
          formFieldName=""
        />
      </Form>
    </>
  );
};

export default NodePanel;
