/** @jsxImportSource @emotion/react */

import tw from "twin.macro";
import React from "react";

interface SvgIconLabelProps {
  icon: React.ReactElement;
  label: string;
}

export const SvgIconLabel: React.FC<SvgIconLabelProps> = ({ icon, label }) => (
  <span>
    <span css={tw`align-middle mr-3`}>{icon}</span>
    <span css={tw`align-middle`}>{label}</span>
  </span>
);
