/** @jsxImportSource @emotion/react */

import React, { useRef, useState } from "react";
import tw from "twin.macro";

type Props = React.DetailedHTMLProps<
  React.VideoHTMLAttributes<HTMLVideoElement>,
  HTMLVideoElement
>;

const Video: React.FC<Props> = (props) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPlayed, setIsPlayed] = useState(false);
  const togglePlay = (e: React.MouseEvent) => {
    const video = videoRef.current;
    if (video) {
      if (video.paused) {
        video.play();
        setIsPlaying(true);
        setIsPlayed(true);
      } else {
        video.pause();
        setIsPlaying(false);
      }
      e.preventDefault();
      e.stopPropagation();
    }
  };
  return (
    <Container onClick={togglePlay}>
      {!isPlaying && <PlayButton onClick={togglePlay} />}
      <video
        {...props}
        controls={isPlayed}
        ref={videoRef}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
      />
    </Container>
  );
};

const Container = tw.div`
  flex justify-center items-center
  relative
  cursor-pointer
`;

const PlayButton: React.FC<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
> = (props) => {
  return (
    <Button {...props}>
      <svg
        viewBox="0 0 400 400"
        width="100%"
        height="100%"
        css={tw`fill-current stroke-current text-white`}
      >
        <polygon points="70,55 70,345 400,200" />
      </svg>
    </Button>
  );
};

const Button = tw.button`
  absolute
  w-20 h-12 p-2 border-0 rounded-sm
  bg-black bg-opacity-75
  cursor-pointer
`;

export default Video;
