/** @jsxImportSource @emotion/react */

import * as React from "react";
import {
  LatLng,
  Marker as LeafletMarker,
  divIcon,
  LatLngExpression,
} from "leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import { Marker, Popup } from "react-leaflet";
import { SvgPin } from "components/SitesAndBuildings/svg";

interface DraggableMarkerProps {
  pos?: LatLngExpression;
  onCoordinatesChange?: (value: LatLng) => void;
  draggable?: boolean;
  title?: string;
  popupText?: React.ReactNode;
}

const customMarkerIcon = (title?: string) =>
  divIcon({
    html: renderToStaticMarkup(
      <span style={{ top: "-400%", left: "-108%", position: "absolute" }}>
        <SvgPin title={title} scale={0.5} />
      </span>
    ),
  });

/**
 * Draggable pin to be used in the map
 * @param pos The position of the marker
 * @param onCoordinatesChange Will be triggered when user changes the marker
 * @param draggable
 * @param popupText
 * @constructor
 */
export const DraggableMarker: React.FunctionComponent<DraggableMarkerProps> = ({
  pos,
  onCoordinatesChange,
  draggable = false,
  title,
  popupText,
}) => {
  const [position, setPosition] = React.useState<LatLngExpression | undefined>(
    pos
  );
  const markerRef = React.useRef<LeafletMarker<any>>(null);

  React.useEffect(() => {
    setPosition(pos);
  }, [pos]);

  const eventHandlers = React.useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          const l = marker.getLatLng();
          setPosition(l);
          onCoordinatesChange?.(l);
        }
      },
    }),
    [onCoordinatesChange]
  );

  if (!position) {
    return null;
  } else {
    return (
      <Marker
        icon={customMarkerIcon(title)}
        draggable={draggable}
        eventHandlers={eventHandlers}
        position={position}
        ref={markerRef}
      >
        {popupText ? <Popup minWidth={90}>{popupText}</Popup> : null}
      </Marker>
    );
  }
};
