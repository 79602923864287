import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import {
  EquipmentFields,
  WingFields,
  FloorFields,
  RoomFields,
  ZoneFields,
} from "../EntityForms";
import { useFieldArrayNodes } from "../useFieldArrayNodes";
import {
  WatcherProps,
  handleArrayToArrayChanges,
} from "./RelationshipWatcherUtils";

export const useFeedsWatcher = (props: WatcherProps) => {
  const { isReadOnly, entityFieldId, entityIdentifier } = props;
  const { getValues, watch, setValue } = useFormContext();
  const { wingFields, floorFields, roomFields, zoneFields, equipmentFields } =
    useFieldArrayNodes();
  const watchFeeds = watch(`${entityIdentifier}.feeds`);

  useEffect(() => {
    if (!isReadOnly) {
      handleArrayToArrayChanges({
        entityFieldId,
        getValues,
        setValue,
        watchedField: watchFeeds,
        existingTargetFields: equipmentFields.fields,
        targetNodeType: "equipment",
        targetProperty: "isFedBy" as keyof EquipmentFields,
      });
      handleArrayToArrayChanges({
        entityFieldId,
        getValues,
        setValue,
        watchedField: watchFeeds,
        existingTargetFields: wingFields.fields,
        targetNodeType: "wing",
        targetProperty: "isFedBy" as keyof WingFields,
      });
      handleArrayToArrayChanges({
        entityFieldId,
        getValues,
        setValue,
        watchedField: watchFeeds,
        existingTargetFields: floorFields.fields,
        targetNodeType: "floor",
        targetProperty: "isFedBy" as keyof FloorFields,
      });
      handleArrayToArrayChanges({
        entityFieldId,
        getValues,
        setValue,
        watchedField: watchFeeds,
        existingTargetFields: roomFields.fields,
        targetNodeType: "room",
        targetProperty: "isFedBy" as keyof RoomFields,
      });
      handleArrayToArrayChanges({
        entityFieldId,
        getValues,
        setValue,
        watchedField: watchFeeds,
        existingTargetFields: zoneFields.fields,
        targetNodeType: "zone",
        targetProperty: "isFedBy" as keyof ZoneFields,
      });
    }
  }, [
    entityFieldId,
    equipmentFields.fields,
    floorFields.fields,
    getValues,
    isReadOnly,
    roomFields.fields,
    setValue,
    watchFeeds,
    wingFields.fields,
    zoneFields.fields,
  ]);
};
