/** @jsxImportSource @emotion/react */

import React, { useContext, useEffect, useState } from "react";
import { List } from "semantic-ui-react";
import tw from "twin.macro";
import { EnodiaOrgContext } from "App";
import { Path } from "Routes";
import { SitesAndBuildingsResponse, masonApi } from "data/Mason";
import {
  DOCUMENTATION_URL,
  externalLinkTo,
  Page,
  quickMenuItem,
  UIStatus,
} from "components/shared";
import {
  SiteModel,
  createSingleOrgModel,
} from "components/SitesAndBuildings/Common";
import SitesAndBuildingsList from "components/SitesAndBuildings/SitesAndBuildingsList";
import { LearningHelp } from "./LearningHelp";
import { OrgId } from "data/Enodia";

type CenterBordersProps = React.PropsWithChildren<{ heading: string }>;
const CenterBorders: React.FC<CenterBordersProps> = (props) => (
  <div css={tw`border border-solid border-white rounded m-2 w-1/3`}>
    <h3 css={tw`pl-4 pt-4`}>{props.heading}</h3>
    {props.children}
  </div>
);

const CustomerHome: React.FC = () => {
  const { orgId } = useContext(EnodiaOrgContext);
  const [uiStatus, setUiStatus] = useState(new UIStatus());

  const [cache, setCache] = useState(new Map<OrgId, SiteModel[]>());

  //once we have an orgId, or if the orgId changes, fetch the sites and buildings
  useEffect(() => {
    if (orgId) {
      const cachedOrgSitesAndBuildings = cache.get(orgId);
      if (!cachedOrgSitesAndBuildings) {
        setUiStatus((prev) => prev.setIndeterminate(true));
        masonApi.getSitesAndBuildings({ orgId }).then(
          (res: SitesAndBuildingsResponse[]) => {
            const orgModel = createSingleOrgModel(res, orgId);
            setCache((prev) => prev.set(orgId, orgModel.sites));
            setUiStatus((prev) => prev.setIndeterminate(false));
          },
          (e) => {
            setUiStatus((prev) =>
              prev.setError("Unable to fetch sites. Please try again later.")
            );
          }
        );
      }
    }
  }, [cache, orgId]);

  const sitesBuildingsList = () => {
    if (orgId) {
      const cachedOrgSitesAndBuildings = cache.get(orgId);
      return cachedOrgSitesAndBuildings ?? [];
    }
    return [];
  };

  return (
    <Page>
      <div css={tw`flex flex-row h-full`}>
        <div
          css={tw`border border-solid border-white rounded m-2 w-1/3 px-4 flex flex-col`}
        >
          <h3 css={tw`pt-4`}>Sites and Buildings</h3>
          <SitesAndBuildingsList
            sitesUiStatus={uiStatus}
            orgId={orgId}
            sitesAndBuildingsList={sitesBuildingsList()}
          />
        </div>
        <CenterBorders heading="Learning and Help">
          <ul css={tw`list-none p-0 m-0`}>
            <LearningHelp />
            <h3 css={tw`pl-4 pt-4`}>Documentation</h3>
            <List>
              <List.Item css={tw`mx-4`}>
                {externalLinkTo(
                  DOCUMENTATION_URL,
                  "Open User Guide",
                  tw`w-full`
                )}
              </List.Item>
            </List>
          </ul>
        </CenterBorders>
        <CenterBorders heading="Quick Menu">
          <List>
            {quickMenuItem(Path.NewSite, "Add New Site")}
            {quickMenuItem(Path.NewBuilding, "Add New Building")}
            {quickMenuItem(Path.NewDataSource, "Add New Data Source")}
          </List>
        </CenterBorders>
      </div>
    </Page>
  );
};

export default CustomerHome;
