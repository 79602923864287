import { ModelRootType } from "data/brick";
import { DchClass } from "data/EntityClasses/EntityClassesContext";
import {
  EquipmentParentClass,
  UnknownParentClass,
} from "data/EntityClasses/ParentClassHelper";
import { ClassHypernym } from "data/Mason";
import {
  BrickProperty,
  getBrickRelationship,
} from "data/Models/Brick/brickRelationships";
import { DescribeResponse } from "data/QueryApi/queryApiTypes";
import { ModelResult } from "data/QueryApi/queryApiUtils";
import { NodeValue, RelatedNode } from "data/QueryApi/queryTypes";
import { getUriFromNode } from "../../ModelUtils";
import {
  FullNodeIdentifier,
  getGraphLabel,
  VizModel,
  VizModelNode,
} from "../VizModelUtil";

/**
 * Get the class of a particular node
 */
export const getDchClass = (
  entityClasses: Map<ClassHypernym, Map<string, DchClass>>,
  hypernym?: ClassHypernym,
  parentClass?: string
): DchClass => {
  if (
    hypernym === ClassHypernym.Equipment &&
    parentClass &&
    !entityClasses.get(hypernym)?.get(parentClass)
  ) {
    //check if this is a collection that's been configured as equipment
    const collectionClass = entityClasses
      .get(ClassHypernym.Collection)
      ?.get(parentClass);
    if (collectionClass)
      return {
        ...collectionClass,
        hypernym: ClassHypernym.Equipment,
        parentClass: EquipmentParentClass.Collection,
      };
  }
  if (hypernym) {
    const entityClass =
      entityClasses.get(hypernym)?.get(parentClass ?? hypernym) ??
      entityClasses.get(hypernym)?.get(hypernym);
    if (entityClass) return entityClass;
  }

  //if we haven't been able to determine the class, return an unknown class so that it can still be rendered
  console.error("Unable to determine entity type for", parentClass);
  return {
    type: parentClass ?? "Unknown Entity Type",
    parents: [],
    label: parentClass ?? "Unknown Entity Type",
    parentClass: UnknownParentClass.Unknown,
  };
};

/**
 * Determine the classification for the root/origin node.
 * Traditionally, was only limited to sites and buildings, now search query allows node types to
 * be root nodes
 */
const chooseClass = (describeType: string) => {
  switch (describeType) {
    case "Site":
      return ModelRootType.Site;
    case "Building":
      return ModelRootType.Building;
    default:
      return ModelRootType.Node;
  }
};

export const getGraphLabelFromNodeValue = (modelNode: NodeValue): string => {
  if (!!modelNode.label) {
    return modelNode.label;
  } else if (!!modelNode.id) {
    return modelNode.id;
  } else {
    return modelNode.fullId;
  }
};

export const convertDchModelToVisualiserModel = (
  dchModel: ModelResult,
  entityClasses: Map<ClassHypernym, Map<string, DchClass>>
): VizModel => {
  const { rootModelNode } = dchModel;

  //top-level of the model
  let vizModel: VizModel = {
    id: rootModelNode.fullId,
    label: rootModelNode.label,
    graphLabel: getGraphLabelFromNodeValue(rootModelNode),
    modelClass: dchModel.modelType,
    nodes: new Map<string, VizModelNode>(),
  };

  const modelIndentifiers = dchModel.modelIdentifiers;

  const getRelationships = (parentName: string) => {
    const relationships = dchModel.relationships.filter(
      (r) => r.parents.nodeUri === parentName
    );
    const uniqueRelations = new Set(relationships.map((r) => r.relation));
    const relationshipsMapping = new Map<BrickProperty, FullNodeIdentifier[]>();
    uniqueRelations.forEach((brickRelationship) => {
      const relationshipNodes = relationships
        .filter(
          (relationshipNode) => relationshipNode.relation === brickRelationship
        )
        .map((relationshipNode) => {
          return relationshipNode.child;
        });
      relationshipsMapping.set(brickRelationship, relationshipNodes);
    });
    return relationshipsMapping;
  };

  //add root model itself as a node
  vizModel.nodes.set(vizModel.id, {
    id: vizModel.id,
    label: vizModel.label,
    graphLabel: vizModel.graphLabel,
    modelReference: modelIndentifiers[rootModelNode.modelIndex],
    class: entityClasses
      .get(ClassHypernym.Location)
      ?.get(dchModel.modelType) as DchClass,
    relationships: getRelationships(vizModel.id),
    properties: rootModelNode.entityProperty,
  });

  //add all other nodes
  dchModel.nodes.forEach((node) => {
    const graphLabel = getGraphLabelFromNodeValue(node);
    vizModel.nodes.set(node.fullId, {
      id: node.fullId,
      label: node.label,
      graphLabel,
      comment: node.comment,
      class: getDchClass(entityClasses, node.hypernym, node.type),
      relationships: getRelationships(node.fullId),
      properties: node.entityProperty,
      unit: node.unit,
      modelReference: modelIndentifiers[node.modelIndex],
    });
  });
  return vizModel;
};

export const getInverse = (relationship: BrickProperty) => {
  const brickRelationship = getBrickRelationship(relationship);
  return brickRelationship.relation === relationship
    ? brickRelationship.inverse
    : brickRelationship.relation;
};
/**
 * Grab the current describe query and convert it to a graph of its relationships
 */
export const convertDescribetoVisualiserModel = (
  describeResponse: DescribeResponse,
  entityClasses: Map<ClassHypernym, Map<string, DchClass>>
): VizModel => {
  const nodeUri = getUriFromNode(describeResponse.node);

  //top-level of the model
  let vizModel: VizModel = {
    id: nodeUri,
    label: describeResponse.label,
    graphLabel: getGraphLabel(describeResponse, nodeUri),
    modelClass: chooseClass(describeResponse.type),
    nodes: new Map<string, VizModelNode>(),
  };

  // initialise all the related nodes (excluding root node)
  describeResponse.relatedNodes.forEach((relatedNode) => {
    const nodeUri = getUriFromNode(relatedNode.node);
    const newNode: VizModelNode = {
      id: nodeUri,
      label: relatedNode.label,
      modelReference: relatedNode.node.modelRef,
      graphLabel: getGraphLabel(relatedNode, nodeUri),
      comment: relatedNode.comment,
      class: getDchClass(
        entityClasses,
        relatedNode.hypernym as ClassHypernym,
        relatedNode.type
      ),
      properties: relatedNode.properties,
      relationships: new Map([
        [
          getInverse(relatedNode.relationship),
          [{ modelReference: relatedNode.node.modelRef, nodeUri }],
        ],
      ]),
    };
    vizModel.nodes.set(nodeUri, newNode);
  });
  //add the searched result as a node
  vizModel.nodes.set(vizModel.id, {
    id: vizModel.id,
    label: vizModel.label,
    graphLabel: vizModel.graphLabel,
    modelReference: describeResponse.node.modelRef,
    class: getDchClass(
      entityClasses,
      describeResponse.hypernym as ClassHypernym,
      describeResponse.type
    ),
    relationships: convertRelatedNodeToRelationship(
      describeResponse.relatedNodes
    ),
    properties: describeResponse.properties,
    unit: describeResponse.unit,
  });
  return vizModel;
};

export const convertRelatedNodeToRelationship = (
  relatedNodes: RelatedNode[]
): Map<BrickProperty, FullNodeIdentifier[]> => {
  const relationshipMapping = new Map<BrickProperty, FullNodeIdentifier[]>();
  const uniqueBrickRelations = new Set(relatedNodes.map((r) => r.relationship));
  uniqueBrickRelations.forEach((p) => {
    const relationshipNodes = relatedNodes
      .filter((r) => r.relationship === p)
      .map((c) => {
        return {
          modelReference: c.node.modelRef,
          nodeUri: getUriFromNode(c.node),
        };
      });
    relationshipMapping.set(p, relationshipNodes);
  });
  return relationshipMapping;
};
