import { createContext, useContext } from "react";
import { ParentClass } from "./ParentClassHelper";
import { ClassHypernym, DCHJsonClassResponse } from "data/Mason";

export type DchClass = DCHJsonClassResponse & {
  parentClass: ParentClass;
  hypernym?: ClassHypernym;
};

type EntityClassesContextType = {
  entityClasses: Map<ClassHypernym, Map<string, DchClass>>;
  setEntityClasses: (_: Map<ClassHypernym, Map<string, DchClass>>) => void;
};

export const EntityClassesContext = createContext<EntityClassesContextType>({
  entityClasses: new Map(),
  setEntityClasses: () => {},
});

export const useEntityClassesContext = () => useContext(EntityClassesContext);
