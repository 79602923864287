/** @jsxImportSource @emotion/react */

import tw from "twin.macro";
import React from "react";

export type InputValidationProps = {
  invalid?: boolean;
  errorMessage?: string;
  testId?: string;
};

export const InputValidation = (validationResult?: InputValidationProps) => {
  return (
    <span
      css={tw`text-red-error block h-0 text-left mb-2`}
      data-test-id={`${validationResult?.testId}-validation`}
    >
      {validationResult?.invalid ? validationResult?.errorMessage : null}
    </span>
  );
};
