/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import { DropdownItemProps, Table } from "semantic-ui-react";
import { DropdownOptionsSelector } from "./OptionsSelector";
import { BuildingId, SiteId } from "data/brick";
import { SelectInput } from "components/shared";
import { OrgId } from "data/Enodia";
import { masonApi } from "data/Mason";
import { ScopeTypePermissionsPropsType } from "./Common";

type BuildingPermissionsProps = ScopeTypePermissionsPropsType;

export const BuildingPermissions: React.FunctionComponent<
  BuildingPermissionsProps
> = ({ role, onChange, orgId, isReadOnly, optionsStatus, verbOptions }) => {
  const [buildingMap, isLoading] = useLabeledBuildingOptions({ orgId });

  return (
    <>
      <Table.Cell width={3}>
        <SelectInput
          testId="BuildingSelect"
          placeholder="Select building..."
          options={getOptionsFromMap(buildingMap)}
          required
          search
          onChange={(_, { value }) => {
            const buildingOption = buildingMap.get(value);
            buildingOption &&
              onChange({
                ...role,
                organisationId: orgId,
                siteId: buildingOption.siteId,
                scopeId: buildingOption.buildingId,
              });
          }}
          isReadOnly={isReadOnly}
          isLoading={isLoading}
          value={getBuildingOptionId(role.siteId, role.scopeId as BuildingId)}
          isMulti={false}
        />
      </Table.Cell>
      <DropdownOptionsSelector
        role={role}
        onChange={onChange}
        isReadOnly={isReadOnly}
        optionsStatus={optionsStatus}
        verbOptions={verbOptions}
      />
    </>
  );
};

type UseLabeledBuildingsParams = { orgId?: OrgId };
type BuildingContext = {
  siteId: SiteId;
  buildingId: BuildingId;
};

function useLabeledBuildingOptions({
  orgId,
}: UseLabeledBuildingsParams): [
  availableBuildings: Map<string, BuildingContext>,
  isLoading: boolean,
] {
  const [buildingsMap, setBuildingsMap] = useState<
    Map<string, BuildingContext>
  >(new Map());
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    loadBuildings(orgId, setBuildingsMap, setIsLoading);
  }, [orgId]);
  return [buildingsMap, isLoading];
}

function loadBuildings(
  orgId: OrgId | undefined,
  setAvailableBuildings: React.Dispatch<
    React.SetStateAction<Map<string, BuildingContext>>
  >,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
) {
  if (orgId) {
    setIsLoading(true);
    masonApi
      .getSites({ orgId })
      .then((sitesReferences) => {
        const sitePromises = sitesReferences.map((siteReference) => {
          return masonApi.getBuildings(siteReference);
        });
        Promise.all(sitePromises)
          .then((res) => {
            const allBuildings = res.flat();
            const buildingMap = new Map();
            allBuildings.forEach(({ siteId, buildingId }) => {
              const key = getBuildingOptionId(siteId, buildingId);
              buildingMap.set(key, { siteId, buildingId });
            });
            setAvailableBuildings(buildingMap);
          })
          .then(() => setIsLoading(false));
      })
      .catch((e) => console.error(`Failed to fetch buildings: ${e}`));
  }
}

function getBuildingOptionId(
  siteId?: SiteId,
  buildingId?: BuildingId,
): string | undefined {
  return !!siteId && !!buildingId ? `${siteId}_${buildingId}` : undefined;
}

function getOptionsFromMap(
  buildingMap: Map<string, BuildingContext>,
): DropdownItemProps[] {
  const options = Array.from(buildingMap.values()).map(
    ({ siteId, buildingId }) => {
      return {
        value: `${siteId}_${buildingId}`,
        text: `${buildingId} (Site: ${siteId})`,
      };
    },
  );
  return options;
}
