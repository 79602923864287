/** @jsxImportSource @emotion/react */
import Form from "@rjsf/semantic-ui";
import {
  RJSFSchema,
  RJSFValidationError,
  FieldTemplateProps,
} from "@rjsf/utils";
import validator from "@rjsf/validator-ajv6";
import { Button, Grid, Item } from "semantic-ui-react";
import tw from "twin.macro";
import { JsonData, metisApi } from "data/Metis";
import { UIStatus } from "components/shared";
import {
  JSON_STRING_SPACING,
  Status,
} from "./ApplicationConfigurationConstants";
import { CustomSelectWidget } from "./CustomSelectWidget";
import { useCallback, useEffect, useMemo, useState } from "react";

const stringToForm = (str?: string): JsonData => {
  if (str === undefined) {
    return {};
  }
  try {
    return JSON.parse(str);
  } catch (e) {
    return {};
  }
};

const ONE_COLUMN_WIDTH = 16;
const TWO_COLUMN_WIDTH = 13;

function CustomFieldTemplate(props: FieldTemplateProps) {
  const { id, classNames, label, required, rawDescription, errors, children } =
    props;

  // Don't render the root element as this just contains the title and subtitle
  if (id === "root") {
    return (
      <Grid stackable>
        <Grid.Row>
          <Grid.Column>
            <Item>
              <Item.Content>
                <Item.Header>{label}</Item.Header>
                <Item.Description css={tw`italic text-core-grey`}>
                  {rawDescription}
                </Item.Description>
              </Item.Content>
            </Item>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>{children}</Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  const errorMessages =
    errors?.props?.errors?.map((error: string) =>
      error.replace("is a required property", "Required")
    ) || [];
  const endsWithDigit = new RegExp("_\\d$").test(id);
  /**
   * TODO: errorMessages sometimes displays twice, since its required on both onOf traits
   * Note: not captured in an bugfix ticket yet- awaiting update to the latest version of rjsf
   */
  const childrenColumn = (
    <Grid.Column
      width={endsWithDigit ? ONE_COLUMN_WIDTH : TWO_COLUMN_WIDTH}
      className={errorMessages.length > 0 ? "error-field" : ""}
    >
      {children}
      {errorMessages.map((error: any, index: number) => (
        <div key={`error-${index}`} css={tw`text-red-error whitespace-nowrap`}>
          {error}
        </div>
      ))}
    </Grid.Column>
  );

  if (endsWithDigit) {
    return (
      <Grid stackable>
        <Grid.Row columns="equal" className={classNames}>
          {childrenColumn}
        </Grid.Row>
      </Grid>
    );
  }

  // Render children as two columns (nested columns will take up additional columns)
  return (
    <Grid stackable>
      <Grid.Row columns="equal" className={classNames}>
        <Grid.Column width={3}>
          <Item>
            <Item.Content>
              <Item.Header>
                {label}
                {required && (
                  <span css={tw`text-red-error whitespace-nowrap`}>*</span>
                )}
              </Item.Header>
              <Item.Description css={tw`italic text-core-grey`}>
                {rawDescription}
              </Item.Description>
            </Item.Content>
          </Item>
        </Grid.Column>
        {childrenColumn}
      </Grid.Row>
    </Grid>
  );
}

export const ApplicationConfigurationJsonDocument: React.FC<{
  schema: RJSFSchema;
  plaintext?: string;
  setPlaintext?: (value: string | undefined) => void;
  setValidInput: React.Dispatch<React.SetStateAction<boolean>>;
  isClean: boolean;
  applicationId: string;
  updateInitialState: (docId: string, plaintext?: string) => void;
  documentId: string;
  setDocumentStatus: React.Dispatch<React.SetStateAction<Status>>;
}> = ({
  schema,
  plaintext,
  setPlaintext,
  setValidInput,
  isClean,
  applicationId,
  updateInitialState,
  documentId,
  setDocumentStatus,
}) => {
  // State handler to allow updating of the plaintext value, handling error parsing as well
  const [invalidFormDataAttempted, setInvalidFormDataAttempted] =
    useState(false);

  const [dirtyText, setDirtyText] = useState<{
    isDirty: boolean;
    data: string | undefined;
    hasErrors: boolean;
  }>({ isDirty: false, data: undefined, hasErrors: true });
  const [saveStatus, setSaveStatus] = useState<UIStatus>(new UIStatus());

  const formToString = (data: JsonData | undefined): string => {
    return JSON.stringify(data, null, JSON_STRING_SPACING);
  };

  useEffect(() => {
    // Once the form has changed AND the errors have been parsed, then set the plaintext state
    /** EDIT: hasErrors detection is broken at the moment as of v4 of rjsf
     * upgrading to the latest version will improve error detection
     */
    setValidInput(dirtyText.hasErrors);
    if (dirtyText.isDirty && dirtyText.data !== undefined) {
      setPlaintext && setPlaintext(dirtyText.data);
      setDirtyText({ isDirty: false, data: undefined, hasErrors: true });
    }
  }, [dirtyText, setPlaintext, setValidInput]);

  const validateAndSave = useCallback(
    ({ formData }: { formData: JsonData }) => {
      setSaveStatus((p) => p.setIndeterminate(true));
      const submitPlainText = formToString(formData);
      if (submitPlainText) {
        metisApi
          .setApplicationDocument(applicationId)(documentId)(submitPlainText)
          .then(
            (_) => {
              updateInitialState(documentId, submitPlainText);
            },
            (e) => setSaveStatus((p) => p.setError("Unable to upload document"))
          )
          .finally(() => setSaveStatus((p) => p.setIndeterminate(false)));
      }
    },
    [applicationId, documentId, updateInitialState]
  );

  const renderForm = useMemo(
    () => (
      <Form
        templates={{
          FieldTemplate: CustomFieldTemplate,
        }}
        widgets={{
          SelectWidget: CustomSelectWidget,
        }}
        schema={schema}
        formData={stringToForm(plaintext)}
        liveValidate={invalidFormDataAttempted}
        validator={validator}
        transformErrors={(errors: RJSFValidationError[]) => {
          setDirtyText((p) => ({
            ...p,
            isDirty: false,
            hasErrors: errors.length === 0,
          }));
          return errors;
        }}
        onChange={({ formData }: any) => {
          setDirtyText((p) => ({
            ...p,
            isDirty: true,
            data: formToString(formData),
          }));
        }}
        onError={() => {
          setInvalidFormDataAttempted(true);
        }}
        className="rjsf inverted"
        formContext={{
          "ui:options": {
            semantic: {
              inverted: true,
              wrapItem: true,
              horizontalButtons: true,
              errorOptions: {
                size: "small",
                pointing: "below",
              },
            },
            addable: false,
            removable: false,
            label: false,
          },
        }}
        onSubmit={validateAndSave}
        children={
          <div css={tw`mt-2`}>
            <Button
              css={tw`mr-2`}
              type="submit"
              icon="save"
              content="Save"
              primary
              disabled={isClean}
              loading={saveStatus.indeterminate}
            />
          </div>
        }
      />
    ),
    [
      invalidFormDataAttempted,
      isClean,
      plaintext,
      saveStatus.indeterminate,
      schema,
      validateAndSave,
    ]
  );

  return renderForm;
};
