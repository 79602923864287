import React from "react";
import { Label } from "semantic-ui-react";
import { StateColours } from "components/shared";

export const PublishStateLabel = ({
  isPublished,
}: {
  isPublished: boolean;
}) => {
  return (
    <Label color={isPublished ? StateColours.Success : StateColours.Unknown}>
      {isPublished ? "Published" : "Not Published"}
    </Label>
  );
};
