import {
  adaptiveControlSvg,
  airQualitySvg,
  applicationSvg,
  buildingAutomationSvg,
  buildingEnvelopeSvg,
  carbonEmissionsSvg,
  dataVisualisationSvg,
  energyManagementSvg,
  environmentalMonitoringSvg,
  hvacSvg,
  lightingControlSvg,
  maintenanceSvg,
  occupantComfortSvg,
  performanceBenchmarkingSvg,
  predictiveAnalyticsSvg,
  renewableEnergySvg,
  securitySvg,
  spaceUtilisationSvg,
  tenantEngagementSvg,
  wasteManagementSvg,
  waterManagmentSvg,
} from "assets/icons/ApplicationCategories";
import {
  ApplicationVersionBriefResponse,
  ApplicationVersionFullResponse,
  AvailableApplicationResponse,
  InstalledApplicationResponse,
  SortOptions,
  SortOrder,
} from "data/Metis";
import { ApplicationsMode, AppDisplayInfo } from "./ApplicationTypes";

/**
 * @description manage optional fields for different application modes
 * there are common fields such as uid, name and version but due to different naming of fields in different responses, we must manually declared these
 */
export const appResponsetoAppDisplayInfo = (
  mode: ApplicationsMode,
  app: AvailableApplicationResponse | InstalledApplicationResponse
): AppDisplayInfo => {
  if (mode === ApplicationsMode.installed) {
    const installedApp = app as InstalledApplicationResponse;

    return {
      applicationUid: installedApp.id,
      applicationId: installedApp.applicationId,
      applicationName: installedApp.applicationName,
      version: installedApp.version,
      instanceName: installedApp.name,
      instanceUid: installedApp.id,
      sites: installedApp.sites,
      buildings: installedApp.buildings,
      lastKnownRun: installedApp.lastKnownRun,
      categories: installedApp.categories,
      applicationRecipeUid: installedApp.applicationRecipeUid,
    };
  } else {
    const availApp = app as AvailableApplicationResponse;
    let commonFields: AppDisplayInfo = {
      applicationUid: availApp.applicationUid,
      applicationName: availApp.name,
      version: availApp.version,
      categories: availApp.categories,
      vendorId: availApp.vendorId,
      description: availApp.description,
      installedCount: availApp.installedCount,
    };

    switch (mode) {
      case ApplicationsMode.managed:
        return {
          ...commonFields,
          published: availApp.published,
        };
      default: //ApplicationsMode.available
        return commonFields;
    }
  }
};

export const getIconByCategory = (categories: string[]) => {
  if (categories.length === 0) return applicationSvg;
  switch (categories[0]) {
    case "Adaptive Control Systems":
      return adaptiveControlSvg;
    case "Air Quality Monitoring":
      return airQualitySvg;
    case "Building Automation":
      return buildingAutomationSvg;
    case "Building Envelope Performance":
      return buildingEnvelopeSvg;
    case "Carbon Emissions Tracking":
      return carbonEmissionsSvg;
    case "Data Visualisation":
      return dataVisualisationSvg;
    case "Energy Management":
      return energyManagementSvg;
    case "Environmental Monitoring":
      return environmentalMonitoringSvg;
    case "HVAC Optimization":
      return hvacSvg;
    case "Lighting Control":
      return lightingControlSvg;
    case "Maintenance and Asset Management":
      return maintenanceSvg;
    case "Occupant Comfort":
      return occupantComfortSvg;
    case "Performance Benchmarking":
      return performanceBenchmarkingSvg;
    case "Predictive Analytics":
      return predictiveAnalyticsSvg;
    case "Renewable Energy Integration":
      return renewableEnergySvg;
    case "Security and Surveillance":
      return securitySvg;
    case "Space Utilization":
      return spaceUtilisationSvg;
    case "Tenant Engagement":
      return tenantEngagementSvg;
    case "Waste Management":
      return wasteManagementSvg;
    case "Water Management":
      return waterManagmentSvg;
    default:
      return applicationSvg;
  }
};

export const defaultFilters = {
  sortBy: SortOptions.createdTime,
  sortOrder: SortOrder.DESCENDING,
};

export const sortVersionsByLatest = (
  appVersions:
    | ApplicationVersionBriefResponse[]
    | ApplicationVersionFullResponse[]
) => {
  return appVersions.sort((a, b) => {
    return a.uploadTime < b.uploadTime
      ? 1
      : a.uploadTime > b.uploadTime
      ? -1
      : 0;
  });
};
export const getLatestApplicationVersion = (
  appVersions:
    | ApplicationVersionBriefResponse[]
    | ApplicationVersionFullResponse[]
) => {
  const versionsByLatest = sortVersionsByLatest(appVersions);
  return versionsByLatest.length > 0 ? versionsByLatest[0] : undefined;
};
