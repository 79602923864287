/** @jsxImportSource @emotion/react */

import React from "react";
import { Divider } from "semantic-ui-react";
import tw from "twin.macro";
import { ContactEmailLink, TERMS_CONDITIONS_URL } from "components/shared";

const PageFooter: React.FC = () => {
  // tailwind doesnt allow string interpolation in their tw macro, hence it has to be conditionally declared
  // as a string beforehand
  const bgColour =
    process.env.REACT_APP_FOOTER_COLOUR !== undefined &&
    (process.env.REACT_APP_FOOTER_COLOUR === "blue"
      ? tw`bg-blue-400/50`
      : tw`bg-rose-600/50`);

  return (
    <div css={[tw`text-center fixed bottom-0 h-8 w-full`, bgColour]}>
      <Divider fitted inverted />
      <div css={tw`text-xs text-white flex space-x-8 justify-center p-2`}>
        <div>Ver {process.env.REACT_APP_VERSION}</div>
        <div>|</div>
        <div>Copyright © CSIRO {new Date().getUTCFullYear()}</div>
        <div>|</div>
        <ContactEmailLink label={"Contact Us"} />
        <div>|</div>
        <div>
          <a href={TERMS_CONDITIONS_URL} target="_blank" rel="noreferrer">
            Terms of Service
          </a>
        </div>
      </div>
    </div>
  );
};

export default PageFooter;
