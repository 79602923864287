import * as O from "fp-ts/lib/Option";
import { httpDelete, httpGetToBlob, httpPostRaw } from "data/httpUtil";
import { ClientEnv } from "data/utils";
import { metisEnv } from "reducers/env";
import { ApplicationId } from "./metisTypes";

// Omit the accept-type and let the browser automatially assign the type, else the boundary will not
// set for the uploaded file
const uploadHeader: ClientEnv = {
  ...metisEnv,
  headers: { Accept: "application/json" },
};

export type ScreenshotsApi = {
  getApplicationRecipeScreenshot: (
    applicationUid: ApplicationId,
    index: number
  ) => Promise<Blob>;
  postApplicationRecipeScreenshot: (
    applicationUid: ApplicationId,
    formData: FormData
  ) => Promise<void>;
  deleteApplicationRecipeScreenshot: (
    applicationId: ApplicationId,
    indexToDelete: number
  ) => Promise<void>;
};

const mkScreenshotsApi = (env: ClientEnv): ScreenshotsApi => ({
  getApplicationRecipeScreenshot: (
    applicationUid: ApplicationId,
    index: number
  ) =>
    httpGetToBlob(O.none)(env)(null)(
      `/application/recipes/${applicationUid}/screenshots/${index}`
    ).then((r) => r as Blob),
  postApplicationRecipeScreenshot: (
    applicationUid: ApplicationId,
    formData: FormData
  ) =>
    httpPostRaw(formData)(O.none)(uploadHeader)(formData)(
      `/manage/application/recipes/${applicationUid}/screenshots`
    ).then((r) => r as any),
  deleteApplicationRecipeScreenshot: (
    applicationId: ApplicationId,
    indexToDelete: number
  ) =>
    httpDelete(O.none)(env)(null)(
      `/manage/application/recipes/${applicationId}/screenshots/${indexToDelete}`
    ).then((_) => undefined),
});

export const screenshotsApi = mkScreenshotsApi(metisEnv);
