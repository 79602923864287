/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import tw, { TwComponent } from "twin.macro";
import { Button, DropdownItemProps } from "semantic-ui-react";
import { SelectInput, PageSection } from "components/shared";
import { FormSelectInputProps } from "components/shared/Forms/Inputs";
import Form from "components/shared/Forms/ReactHookForm";
import { Grid3Col } from "../ComponentLibrary";
import { FormFields } from "./Inputs";

const SELECT_OPTIONS: DropdownItemProps[] = [
  { value: "option1", text: "Option 1" },
  { value: "option2", text: "Another Option" },
  { value: "option3", text: "A Pre-Selected Option" },
  { value: "option4", text: "Yet Another Option" },
];

const FormSelectInputWrapper = ({
  formId,
  selectInputProps,
  InputBox,
}: {
  formId: string;
  selectInputProps: Omit<FormSelectInputProps<FormFields>, "name">;
  InputBox: TwComponent<"div">;
}) => {
  return (
    <div>
      <InputBox>
        <Form
          formId={formId}
          onSubmit={(data: FormFields) => console.log(data)}
        >
          <Form.SelectInput name="select" {...selectInputProps} />
        </Form>
      </InputBox>
      <Button
        size="mini"
        inverted
        basic
        type="submit"
        form={formId}
        style={{ marginTop: ".5rem" }}
      >
        Submit
      </Button>
    </div>
  );
};

export const SelectInputs = ({
  showBoundaries,
}: {
  showBoundaries: boolean;
}) => {
  const InputBox = showBoundaries
    ? tw.div`border border-dashed border-core-grey`
    : tw.div`border border-solid border-transparent`;

  const [selectValue, setSelectValue] = useState("option3");
  const [selectMultiValue, setSelectMultiValue] = useState<string[]>([]);

  const getFormSelectInput = (
    formId: string,
    selectInputProps: Omit<FormSelectInputProps<FormFields>, "name">,
  ) => {
    return (
      <FormSelectInputWrapper
        InputBox={InputBox}
        formId={formId}
        selectInputProps={selectInputProps}
      />
    );
  };

  return (
    <>
      <PageSection header="Basic">
        <Grid3Col>
          <InputBox>
            <SelectInput
              options={SELECT_OPTIONS}
              placeholder="With placeholder"
            />
          </InputBox>
          <InputBox>
            <SelectInput
              options={SELECT_OPTIONS}
              placeholder="Searchable"
              search
            />
          </InputBox>
          <InputBox>
            <SelectInput options={[]} isLoading />
          </InputBox>
          <InputBox>
            <SelectInput
              options={SELECT_OPTIONS}
              placeholder="Disabled"
              disabled
            />
          </InputBox>
          <InputBox>
            <SelectInput
              options={SELECT_OPTIONS}
              value={selectValue}
              onChange={(_, { value }) => setSelectValue(value)}
            />
          </InputBox>
          <InputBox>
            <SelectInput options={[]} noOptionsMessage="No options found" />
          </InputBox>
          <InputBox>
            <SelectInput
              options={SELECT_OPTIONS}
              label="Labelled"
              placeholder="With placeholder"
            />
          </InputBox>
          <InputBox>
            <SelectInput
              options={SELECT_OPTIONS}
              label="Labelled"
              required
              placeholder="Required"
            />
          </InputBox>
          <InputBox>
            <SelectInput
              options={SELECT_OPTIONS}
              label="Labelled"
              placeholder="In error state"
              inputValidation={{ invalid: true, errorMessage: "This is wrong" }}
            />
          </InputBox>
          <InputBox>
            <SelectInput
              options={SELECT_OPTIONS}
              label="Multi Select"
              isMulti
              value={selectMultiValue}
              onChange={(_, { value }) => setSelectMultiValue(value)}
            />
          </InputBox>
        </Grid3Col>
      </PageSection>

      <PageSection header="In a React Hook Form">
        <Grid3Col>
          {getFormSelectInput("FormSelectInput_1", {
            options: SELECT_OPTIONS,
            placeholder: "In a React Hook Form",
          })}
          <div></div>
          <div></div>
          {getFormSelectInput("FormSelectInput_4", {
            options: SELECT_OPTIONS,
            placeholder: "Multi select",
            isMulti: true,
          })}
        </Grid3Col>
      </PageSection>
    </>
  );
};
