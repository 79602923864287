import React, { useContext } from "react";
import { Button, Table } from "semantic-ui-react";
import {
  EMPTY_DESCRIPTION,
  UIStatus,
  UIStatusWrapper,
} from "components/shared";
import { generatePath, useNavigate } from "react-router-dom";
import { Path } from "Routes";
import { RoleSetResponse, UserGroupResponse } from "data/Enodia";
import { EnodiaOrgContext } from "App";
import { UserPermissions } from "../Permissions";

type Props = {
  userGroupList: Array<UserGroupResponse>;
  userList: Array<UserPermissions>;
  roleSets: Array<RoleSetResponse>;
  userListStatus: UIStatus;
  roleSetStatus: UIStatus;
};

const renderNoResults = () => {
  return (
    <Table.Row>
      <Table.Cell>No User Groups Found</Table.Cell>
    </Table.Row>
  );
};

export const roleCount = (
  roleSets: Array<RoleSetResponse>,
  group: UserGroupResponse
) => {
  //Array<number> of all the roles of current usergroup compared with roleset pool
  const filteredRoleSets =
    roleSets.length > 0
      ? roleSets
          .filter((rs) => group.roleSetIDs?.includes(rs.id))
          .map((e) => e.roleIds.length)
      : [];
  return filteredRoleSets.reduce((a, b) => a + b, 0);
};

export const userCount = (
  userList: Array<UserPermissions>,
  group: UserGroupResponse
) => {
  return userList.filter((ul) => ul.userGroups.find((id) => id.id === group.id))
    .length;
};

export const UserGroupTable: React.FC<Props> = ({
  userGroupList,
  userList,
  roleSets,
  userListStatus,
  roleSetStatus,
}) => {
  const navigate = useNavigate();
  const { orgId } = useContext(EnodiaOrgContext);
  const renderUserGroup = (group: UserGroupResponse) => {
    return (
      <Table.Row key={`userGroup-${group.id}`}>
        <Table.Cell>{group.name ?? group.id}</Table.Cell>
        <Table.Cell>
          <UIStatusWrapper
            status={UIStatus.from([roleSetStatus, userListStatus])}
            loadingDataMsg=""
          >
            {`${roleCount(roleSets, group)} role${
              roleCount(roleSets, group) === 1 ? "" : "s"
            }, ${userCount(userList, group)} user${
              userCount(userList, group) === 1 ? "" : "s"
            }`}
          </UIStatusWrapper>
        </Table.Cell>
        <Table.Cell>{group.description || EMPTY_DESCRIPTION}</Table.Cell>
        <Table.Cell width={2} textAlign="center">
          <Button
            basic
            inverted
            onClick={() =>
              navigate(
                generatePath(Path.ViewUserGroup, {
                  orgId: orgId as string,
                  userGroupId: group.id,
                })
              )
            }
          >
            View
          </Button>
        </Table.Cell>
      </Table.Row>
    );
  };

  return (
    <Table fixed singleLine inverted striped className="collapsed">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={4}>User Groups</Table.HeaderCell>
          <Table.HeaderCell width={3}>No. of Roles/Users</Table.HeaderCell>
          <Table.HeaderCell width={8}>Description</Table.HeaderCell>
          <Table.HeaderCell width={2}></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {userGroupList.length > 0
          ? userGroupList.map((user) => renderUserGroup(user))
          : renderNoResults()}
      </Table.Body>
    </Table>
  );
};
