import adaptiveControl from "./adaptive-control.svg";
import airQuality from "./air-quality.svg";
import application from "./application.svg";
import buildingAutomation from "./building-automation.svg";
import buildingEnvelope from "./building-envelope.svg";
import carbonEmissions from "./carbon-emissions.svg";
import dataVisualisation from "./data-visualisation.svg";
import energyManagement from "./energy-management.svg";
import environmentalMonitoring from "./environmental-monitoring.svg";
import hvac from "./hvac.svg";
import lightingControl from "./lighting-control.svg";
import maintenance from "./maintenance.svg";
import occupantComfort from "./occupant-comfort.svg";
import performanceBenchmarking from "./performance-benchmark.svg";
import predictiveAnalytics from "./predictive-analytics.svg";
import renewableEnergy from "./renewable-energy.svg";
import security from "./security.svg";
import spaceUtilisation from "./space-utilisation.svg";
import tenantEngagement from "./tenant-engagement.svg";
import wasteManagement from "./waste-management.svg";
import waterManagment from "./water-management.svg";

export const adaptiveControlSvg = adaptiveControl;
export const airQualitySvg = airQuality;
export const applicationSvg = application;
export const buildingAutomationSvg = buildingAutomation;
export const buildingEnvelopeSvg = buildingEnvelope;
export const carbonEmissionsSvg = carbonEmissions;
export const dataVisualisationSvg = dataVisualisation;
export const energyManagementSvg = energyManagement;
export const environmentalMonitoringSvg = environmentalMonitoring;
export const hvacSvg = hvac;
export const lightingControlSvg = lightingControl;
export const maintenanceSvg = maintenance;
export const occupantComfortSvg = occupantComfort;
export const performanceBenchmarkingSvg = performanceBenchmarking;
export const predictiveAnalyticsSvg = predictiveAnalytics;
export const renewableEnergySvg = renewableEnergy;
export const securitySvg = security;
export const spaceUtilisationSvg = spaceUtilisation;
export const tenantEngagementSvg = tenantEngagement;
export const wasteManagementSvg = wasteManagement;
export const waterManagmentSvg = waterManagment;
