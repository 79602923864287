import * as React from "react";
import { Divider, Icon, Popup } from "semantic-ui-react";
import tw from "twin.macro";

interface InfoHelperProps {
  position?: "left" | "right" | "center";
  title?: JSX.Element | String;
  body: JSX.Element | String;
  equation?: JSX.Element | String;
  disabled?: boolean;
}

/**
 * Utility function to generate a Semantic UI Statistic of a given value and label.
 *
 * @param title - Title of the helper popup to display before the divider.
 * @param body - Main content of the helper popup to display below the divider.
 * @param equation - Optional part to display as an equation describing the operation.
 * @param position - The position of the popup
 * @returns Semantic UI Popup to brief the user with information about the field.
 */
export const InfoHelper: React.FC<InfoHelperProps> = ({
  position = "center",
  title,
  body,
  equation,
}) => (
  <Popup
    inverted
    popper={{ style: { filter: "none" } }}
    trigger={<Icon name="question circle" />}
    hoverable
    content={
      <div>
        {title ? (
          <React.Fragment>
            <div>{title}</div>
            <Divider />
          </React.Fragment>
        ) : null}

        <div>{body}</div>

        {equation ? (
          <div css={tw`content-center font-mono`}>{equation}</div>
        ) : null}
      </div>
    }
    position={`bottom ${position}` as any}
    style={{ border: "1px solid white" }}
  />
);
