/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import { Icon } from "semantic-ui-react";
import tw from "twin.macro";
import {
  LabelProps,
  LabelledInput,
  LabelledInputLayoutProps,
  ValidationProps,
  getLabelProps,
  getLabelledInputLayoutProps,
} from "components/shared";
import { InputValidation } from "../InputValidation";

const zipFileTypes = ["application/zip", "application/x-zip-compressed"];
const zipExtension = ".zip";

export type FileInputProps = {
  accept?: string;
  value?: any;
  onChange?: (_?: File) => void;
  dragAndDrop?: boolean;
} & LabelProps &
  LabelledInputLayoutProps &
  ValidationProps;

const validateFileType = (file: File, accept: string) => {
  const splitFileName = file.name.split(".");
  const fileExtension = splitFileName[splitFileName.length - 1];
  return accept === `.${fileExtension}`;
};

export const FileInput = (props: FileInputProps) => {
  const { inputValidation, ...inputProps } = props;
  const labelProps = getLabelProps(inputProps);
  const labelledInputProps = getLabelledInputLayoutProps(props);
  const [dragActive, setDragActive] = React.useState(false);
  const [fileValue, setFileValue] = useState(props.value ?? "No file chosen");
  const [fileInputValidation, setFileInputValidation] =
    useState(inputValidation);

  useEffect(() => {
    setFileInputValidation(inputValidation);
  }, [inputValidation]);

  const processDrop = (event: React.DragEvent) => {
    let items = event.dataTransfer.files;
    if (
      !props.accept ||
      (props.accept === zipExtension && zipFileTypes.includes(items[0].type)) ||
      validateFileType(items[0], props.accept)
    ) {
      setFileValue(items[0].name);
      setFileInputValidation(undefined);
      props.onChange?.(items[0]);
    } else {
      setFileInputValidation({
        invalid: true,
        errorMessage: `This file type is not accepted. Please select a file of type ${props.accept}`,
      });
    }
  };

  const input = (
    <>
      <input
        id="upload"
        type="file"
        accept={props.accept}
        onChange={(e) => {
          const selectedFile = e.target.files?.[0];
          setFileValue(selectedFile?.name);
          props.onChange?.(selectedFile);
        }}
        className={fileInputValidation?.invalid ? "field-error" : undefined}
        css={props.dragAndDrop ? tw`text-black` : null}
      />
      {props.dragAndDrop ? (
        <div css={tw`mt-2 flex`}>
          <span css={tw`mr-2`}>{fileValue}</span>
          <InputValidation {...fileInputValidation} />
        </div>
      ) : (
        fileInputValidation && <InputValidation {...fileInputValidation} />
      )}
      {props.dragAndDrop && (
        <label
          id="upload"
          htmlFor="upload"
          css={[
            tw`border border-dashed border-primary rounded m-2 w-1/2 h-1/4vh p-5 flex flex-col-reverse flex-wrap justify-center`,
            dragActive && tw`bg-primary opacity-75`,
          ]}
          onDrop={(e: React.DragEvent) => {
            e.preventDefault();
            setDragActive(false);
            processDrop(e);
          }}
          onDragOver={(e: React.DragEvent) => {
            e.preventDefault();
            setDragActive(true);
          }}
          className={fileInputValidation?.invalid ? "field-error" : undefined}
        >
          <div css={tw`text-center m-auto text-white`}>
            <Icon name="upload" size="huge" />
            <br />
            or drag file to this window
          </div>
        </label>
      )}
    </>
  );

  return props.label ? (
    <LabelledInput {...labelProps} {...labelledInputProps} input={input} />
  ) : (
    input
  );
};
