import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { EquipmentFields } from "../EntityForms";
import { useFieldArrayNodes } from "../useFieldArrayNodes";
import {
  WatcherProps,
  handleArrayToArrayChanges,
} from "./RelationshipWatcherUtils";

export const useIsFedByWatcher = (props: WatcherProps) => {
  const { isReadOnly, entityFieldId, entityIdentifier } = props;
  const { getValues, watch, setValue } = useFormContext();
  const { equipmentFields } = useFieldArrayNodes();
  const watchIsFedBy = watch(`${entityIdentifier}.isFedBy`);

  useEffect(() => {
    if (!isReadOnly) {
      handleArrayToArrayChanges({
        entityFieldId,
        getValues,
        setValue,
        watchedField: watchIsFedBy,
        existingTargetFields: equipmentFields.fields,
        targetNodeType: "equipment",
        targetProperty: "feeds" as keyof EquipmentFields,
      });
    }
  }, [
    entityFieldId,
    equipmentFields.fields,
    getValues,
    isReadOnly,
    setValue,
    watchIsFedBy,
  ]);
};
