import React, { useContext, useEffect, useState } from "react";
import { EnodiaOrgContext } from "App";
import { EnodiaOrgIdField } from "components/shared/InputFields/Fields/Domain/EnodiaOrgIdField";
import { useParams } from "react-router-dom";
import * as O from "fp-ts/lib/Option";
import { getEnodiaFavouriteOrg, setFavouriteEnodiaOrg } from "data/auth";
import { OrgId, enodiaApi } from "data/Enodia";
import { UIStatus, UIStatusWrapper } from "components/shared";

const OrgContextHeader: React.FC = () => {
  const { orgId, setOrgId, orgList, prevOrg } = useContext(EnodiaOrgContext);
  const [status, setStatus] = useState<UIStatus>(new UIStatus());

  const params = useParams<{ orgId: string }>();

  useEffect(() => {
    orgId && setFavouriteEnodiaOrg(orgId as string);
  }, [orgId]);

  useEffect(() => {
    // current implementation: names with "sandbox-" will also be caught in regex
    const sandboxRegex = /^sandbox-.*/g;
    const lastUsedContext = O.getOrElse(() => "")(getEnodiaFavouriteOrg());

    if (
      lastUsedContext &&
      lastUsedContext !== "undefined" &&
      orgList?.some((x) => x.id === lastUsedContext)
    ) {
      setOrgId(lastUsedContext as OrgId);
    } else if (orgList) {
      const orgsExcludeSandbox = orgList.filter(
        (org) => !org.id.match(sandboxRegex),
      );
      if (orgsExcludeSandbox.length > 1) {
        setOrgId((orgsExcludeSandbox[0]?.id as OrgId) ?? undefined);
      } else {
        setOrgId((orgList[0]?.id as OrgId) ?? undefined);
      }
    }
  }, [orgList, setOrgId]);

  useEffect(() => {
    if (orgId && params.orgId && params.orgId !== orgId) {
      enodiaApi
        .getOrgById(params.orgId as OrgId)
        .then(() => {
          setStatus((prev) => prev.setSuccessful(true));
          setOrgId(params.orgId as OrgId);
        })
        .catch((e) => {
          setStatus((prev) =>
            prev.setError("Could not load the organisation."),
          );
          const lastUsedContext = O.getOrElse(() => "")(
            getEnodiaFavouriteOrg(),
          );
          setOrgId(lastUsedContext as OrgId);
        });
    }
    // putting all dependencies run risk of overwriting prevOrg on multiple renders,
    // thus, only track when parameters are available
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.orgId]);

  return (
    <UIStatusWrapper status={status}>
      <EnodiaOrgIdField
        isClearable={false}
        orgId={orgId}
        onChange={(org: OrgId | undefined) => {
          setOrgId(org);
          prevOrg.current = orgId;
        }}
      />
    </UIStatusWrapper>
  );
};

export default OrgContextHeader;
