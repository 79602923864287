/** @jsxImportSource @emotion/react */
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import tw from "twin.macro";
import { Button, DropdownItemProps, Icon } from "semantic-ui-react";
import { DchModal, UIStatus, SelectInput, TextInput } from "components/shared";
import { EnodiaOrgContext } from "App";
import {
  ClassificationPlanId,
  ClassifierSetId,
  ClassifierSetListItem,
  pointClassifierApi,
} from "data/Aletheia";
import { OrgId } from "data/Enodia";
import {
  ClassifierSetMetadata,
  clearedClassifierSet,
  defaultClassifierSet,
} from "./ClassifierSetMetadataUtils";

type ClassifierSetInputProps = {
  classifierSetSearch: ClassifierSetSearchState;
  setClassifierSetSearch: Dispatch<SetStateAction<ClassifierSetSearchState>>;
  canSave: boolean;
  setCanSave: Dispatch<SetStateAction<boolean>>;
  unsavedClassifierSet?: ClassifierSetMetadata;
  setClassifierSet: Dispatch<SetStateAction<ClassifierSetMetadata>>;
  setClassifierSetId: (setId: ClassifierSetId | undefined) => void;
  classifierSet: ClassifierSetMetadata;
  setPlanId: (planId: ClassificationPlanId | undefined) => void;
  setShowResults: Dispatch<SetStateAction<boolean>>;
  classifierSetOptions: ClassifierSetListItem[];
  getClassifierSets: (orgId?: OrgId) => Promise<void | ClassifierSetListItem[]>;
  resetPageState: () => void;
  disabled: boolean;
  setCanPurgeClassifiers: Dispatch<SetStateAction<boolean>>;
  canPurgeClassifiers: boolean;
};

export type ClassifierSetSearchState = {
  name?: string;
  id?: ClassifierSetId;
  mode: ClassifierSearchMode;
};

export enum ClassifierSearchMode {
  create,
  search,
}

// duplicate code in FilterByOptions.tsx, wrong context to draw its function, so its declared again here
// todo: make a common DropdownItemProp
export const listItemToOptions = function (
  x: ClassifierSetListItem,
): DropdownItemProps {
  return {
    value: x.id,
    text: `${x.name} (${x.id})`,
    content: (
      <span>
        <b>{x.name}</b> ({x.id})
      </span>
    ),
  };
};

export const ClassifierSetSearch: React.FunctionComponent<
  ClassifierSetInputProps
> = ({
  classifierSetSearch,
  setClassifierSetSearch,
  canSave,
  setCanSave,
  unsavedClassifierSet,
  setClassifierSet,
  setClassifierSetId,
  classifierSet,
  setPlanId,
  setShowResults,
  classifierSetOptions,
  getClassifierSets,
  resetPageState,
  disabled,
  setCanPurgeClassifiers,
  canPurgeClassifiers,
}) => {
  const { orgId } = useContext(EnodiaOrgContext);

  const [status, setStatus] = useState<UIStatus>(new UIStatus());
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const prevSearchState = useRef<ClassifierSetSearchState>();
  const prevUnsavedClassifierSet = useRef<ClassifierSetMetadata>();

  // Ensure options are loaded
  useEffect(() => {
    getClassifierSets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If the options are 0, automatically select search.
  useEffect(() => {
    setClassifierSetSearch({
      ...classifierSetSearch,
      mode:
        classifierSetOptions && classifierSetOptions.length > 0
          ? ClassifierSearchMode.search
          : ClassifierSearchMode.create,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classifierSetOptions]);

  // fetch the classifier set on id change
  useEffect(() => {
    if (
      classifierSetSearch.id &&
      classifierSetSearch.mode === ClassifierSearchMode.search
    ) {
      setCanPurgeClassifiers(false);
      pointClassifierApi
        .getClassifierSetById(classifierSetSearch.id)
        .then((res) => {
          // cast it through clearedClassifierSet to initialise 1 preset classifier row
          if (res.classifiers.length > 0) {
            setCanPurgeClassifiers(true);
            setClassifierSet(res);
            setClassifierSetId(res.id);
          } else {
            setClassifierSet(
              clearedClassifierSet(res.organisationId, res.id, res.name),
            );
          }
          return res;
        })
        .then((res) => {
          // restore unsaved lost values on cancellation
          if (
            res?.id === prevUnsavedClassifierSet.current?.id &&
            prevUnsavedClassifierSet.current !== undefined
          )
            setClassifierSet(prevUnsavedClassifierSet.current);
        })
        .catch((e) => {
          setStatus((p) => p.setError(e));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classifierSetSearch.id]);

  // captures org change after first render
  const firstUpdate = React.useRef(true);

  useEffect(() => {
    if (firstUpdate.current || prevSearchState.current === undefined) {
      firstUpdate.current = false;
      return;
    }
    if (prevSearchState.current && canSave) {
      if (
        (prevSearchState?.current?.id !== classifierSetSearch.id ||
          prevSearchState?.current?.mode !== classifierSetSearch.mode) &&
        !(classifierSetSearch.id && classifierSetSearch.name)
      ) {
        setConfirmationModalOpen(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classifierSetSearch.id, classifierSetSearch.mode]);

  const onSetChangeConfirmation = () => {
    // update the search state to the new confirmation
    setCanSave(false);
    setConfirmationModalOpen(false);
    classifierSetSearch.mode === ClassifierSearchMode.create &&
      setClassifierSet(defaultClassifierSet);
    setPlanId(undefined);

    // Clear the previous state reference
    prevSearchState.current = undefined;
  };

  const handleConfirmationCloseOrCancel = () => {
    if (prevSearchState.current !== undefined) {
      setClassifierSetSearch(prevSearchState.current);
    }
    if (unsavedClassifierSet) setClassifierSet(unsavedClassifierSet);
    setConfirmationModalOpen(false);
  };

  const handleDeleteClassifierSet = useCallback(
    (id: ClassifierSetId) => {
      const deleteSet = () =>
        pointClassifierApi
          .deleteClassifierSet(id)
          .then(() => {
            setClassifierSetSearch((p) => ({
              mode: p.mode,
              name: undefined,
              id: undefined,
            }));
          })
          .then(() => {
            setClassifierSet(clearedClassifierSet(orgId as OrgId, "", ""));
          })
          .then(() => {
            getClassifierSets();
          })
          .then(() => resetPageState())
          .catch((e) => {
            setStatus((p) => p.setError(e));
          });

      if (canPurgeClassifiers) {
        pointClassifierApi.purgeAllClassifiers(id).then(() => deleteSet());
      } else {
        deleteSet();
      }

      setDeleteModalOpen(false);
    },
    [
      canPurgeClassifiers,
      getClassifierSets,
      orgId,
      resetPageState,
      setClassifierSet,
      setClassifierSetSearch,
    ],
  );
  return (
    <div>
      <div css={tw`flex gap-x-2 pb-4`}>
        <React.Fragment>
          <DchModal
            content="Are you sure you want to change or create a new classifier set? All current changes will be lost. "
            header="Confirm Classifier Set Change"
            open={confirmationModalOpen}
            onClose={() => {
              handleConfirmationCloseOrCancel();
            }}
            onConfirm={onSetChangeConfirmation}
          />
          <DchModal
            content="Are you sure you want to delete your current set? This action is irreversible. "
            header={`Confirm Delete ${
              classifierSetSearch.name ?? classifierSetSearch.id
            }`}
            open={deleteModalOpen}
            onClose={() => {
              setDeleteModalOpen(false);
            }}
            onConfirm={() => {
              classifierSetSearch.id &&
                handleDeleteClassifierSet(classifierSetSearch.id);
            }}
            confirmText="Confirm"
          />
          <Button.Group>
            <Button
              inverted={true}
              color={"purple"}
              basic
              active={classifierSetSearch?.mode === ClassifierSearchMode.search}
              onClick={() => {
                setClassifierSet(clearedClassifierSet(orgId as OrgId, "", ""));
                // before switching modes, store the previous search state
                prevSearchState.current = classifierSetSearch;
                setClassifierSetSearch((p) => ({
                  ...p,
                  mode: ClassifierSearchMode.search,
                }));
              }}
              children={"Search Saved Sets"}
            />
            <Button.Or />
            <Button
              inverted={true}
              color={"purple"}
              basic
              active={classifierSetSearch?.mode === ClassifierSearchMode.create}
              onClick={() => {
                resetPageState();
                setClassifierSet(clearedClassifierSet(orgId as OrgId, "", ""));
                // before switching modes, store the previous search state
                prevSearchState.current = classifierSetSearch;
                setClassifierSetSearch({
                  mode: ClassifierSearchMode.create,
                });
              }}
              children={"Create New"}
            />
          </Button.Group>
        </React.Fragment>
      </div>
      <div css={tw`flex flex-row mb-2 gap-2`}>
        {classifierSetSearch?.mode === ClassifierSearchMode.search ? (
          <div css={tw`w-3/4`}>
            <SelectInput
              testId="select_classifier_set"
              disabled={disabled}
              label={"Search Classifier Sets"}
              placeholder={"Search existing classifier sets..."}
              value={classifierSetSearch.id || ""}
              onChange={(_, { value }) => {
                const changedSetSearch = {
                  id: value as ClassifierSetId,
                  mode: ClassifierSearchMode.search,
                };
                if (classifierSetSearch.id)
                  prevSearchState.current = classifierSetSearch;
                prevUnsavedClassifierSet.current = classifierSet;
                setClassifierSetId(value);
                setClassifierSetSearch(changedSetSearch);
                setShowResults(false);
              }}
              options={classifierSetOptions.map(listItemToOptions)}
              isLoading={status.indeterminate}
              search={true}
            />
          </div>
        ) : (
          <div css={tw`w-3/4`}>
            <TextInput
              testId="create_classifier_set"
              label={"New Classifier Set Name"}
              placeholder="Enter a new set name"
              required={true}
              onChange={(_, { value }) => {
                const changedSetSearch = {
                  name: value as string,
                  mode: ClassifierSearchMode.create,
                };
                if (value !== undefined || value !== "") setCanSave(true);
                setClassifierSetSearch(changedSetSearch);
              }}
            />
          </div>
        )}
        {classifierSetSearch.id && (
          <Button basic inverted onClick={() => setDeleteModalOpen(true)}>
            <Icon name="trash" /> Delete Set
          </Button>
        )}
      </div>
    </div>
  );
};
