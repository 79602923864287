/** @jsxImportSource @emotion/react */
import React from "react";
import tw from "twin.macro";
import { Tab } from "semantic-ui-react";
import { Page, PageTitle } from "components/shared";
import { Inputs } from "./Inputs/Inputs";
import { ReactHookForm } from "./ReactHookForm";
import { MarkdownComponent } from "./MarkdownComponent";

export const Grid3Col = tw.div`grid grid-cols-3 gap-x-4 gap-y-2`;

const ComponentLibrary = () => {
  const panes = [
    {
      menuItem: "Inputs",
      render: () => (
        <Tab.Pane>
          <Inputs />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Form",
      render: () => (
        <Tab.Pane>
          <ReactHookForm />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Markdown",
      render: () => (
        <Tab.Pane>
          <MarkdownComponent />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <Page>
      <PageTitle secondaryHeader="Component Library" />
      <Tab panes={panes} />
    </Page>
  );
};
export default ComponentLibrary;
