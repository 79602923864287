/** @jsxImportSource @emotion/react */

import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionContent,
  Grid,
  Header,
  Icon,
  Label,
  SemanticCOLORS,
} from "semantic-ui-react";
import tw from "twin.macro";
import {
  BuildingId,
  DCHModel,
  ModelId,
  ModelRootType,
  Schema,
} from "data/brick";
import { OrgId } from "data/Enodia";
import { ApiError, ApiErrorType } from "data/http";
import { BuildingGetRequest, ModelPublishStatus, masonApi } from "data/Mason";
import { SiteGetRequest } from "data/Mason/Site/SiteTypes";
import { ModelMeta } from "data/Mason/ModelDraft/ModelDraftUtils";
import { UIStatusWrapper, UIStatus, StateColours } from "components/shared";
import { CreateModel } from "./Actions/CreateModel";
import { Breadcrumb } from "./Breadcrumb";
import {
  extractModelId,
  getDefaultSelectedElement,
  getEmptyModel,
  ModelProps,
  SelectedElement,
} from "./ModelUtils";
import { SiteOrBuildingModelWrapper } from "./SiteOrBuildingModelWrapper";

type Props = {
  modelExists?: boolean;
  setModelExists: (value: boolean) => void;
} & ModelProps;

export const SiteOrBuildingModelAccordion = (props: Props) => {
  const { orgId, siteId, buildingId, modelType, modelExists, setModelExists } =
    props;
  const [modelSectionOpen, setModelSectionOpen] = useState(true);
  const [modelStatus, setModelStatus] = useState(new UIStatus());
  const [hasPendingDrafts, setHasPendingDrafts] = useState<boolean>();

  const location = useLocation();
  const navigate = useNavigate();

  const [modelMeta, setModelMeta] = useState<ModelMeta>({
    modelId: `dch:org/${orgId}/site/${siteId}${
      buildingId ? "/building/" + buildingId : ""
    }#` as ModelId,
    schema: Schema.default,
  });
  const [fetchedModel, setFetchedModel] = useState<DCHModel>();
  const [selectedElement, setSelectedElement] = useState<SelectedElement>(
    getDefaultSelectedElement(modelType, siteId!!, buildingId)
  );

  const [breadcrumbs, setBreadcrumbs] = useState<Array<Breadcrumb>>([]);
  const currentCrumb = useRef<Breadcrumb>();

  React.useEffect(() => {
    masonApi
      .getPublishStatus({
        orgId: orgId,
        siteId: siteId,
      } as SiteGetRequest)
      .then(
        (res) => {
          setHasPendingDrafts(res.status === ModelPublishStatus.Draft);
        },
        () => {
          setHasPendingDrafts(undefined);
        }
      );
  }, [orgId, siteId]);

  const fetchModelDraft = useCallback(() => {
    const getModelPromise = () => {
      const params = {
        orgId: orgId as OrgId,
        siteId: siteId,
        buildingId: buildingId as BuildingId,
      };

      const ret =
        modelType === ModelRootType.Site
          ? masonApi.getDraftSiteJSON(params as SiteGetRequest)
          : masonApi.getDraftBuildingJSON(params as BuildingGetRequest);

      return ret;
    };
    if (orgId && siteId && (buildingId || modelType === ModelRootType.Site)) {
      setModelStatus((prev) => prev.setIndeterminate(true));
      getModelPromise().then(
        (model) => {
          setFetchedModel(model);
          setModelMeta({
            modelId: model.id,
            schema: model.schema,
            modelName: model.label ?? extractModelId(model.id),
          });
          setModelExists(true);
          setModelStatus((prev) => prev.setIndeterminate(false));
        },
        (e: ApiError) => {
          if (e.type === ApiErrorType.ResourceNotFound) {
            setModelExists(false);
            setModelStatus((prev) => prev.setIndeterminate(false));
          } else {
            setModelStatus((prevState) =>
              prevState.setError(e.message || `Could not load ${modelType}`)
            );
          }
        }
      );
    }
    //below is to allow setSelectedElementByQuery to be excluded - it depends on nodeId, so would re-fetch the model whenever the selected entity is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingId, modelType, orgId, setModelExists, siteId]);

  // On first load, want to grab the model
  useEffect(() => {
    fetchModelDraft();
  }, [fetchModelDraft]);

  const onModelReset = () => {
    fetchModelDraft();
    const element = getDefaultSelectedElement(modelType, siteId!!, buildingId);
    setBreadcrumbs([]);
    currentCrumb.current = undefined;
    setSelectedElement(element);
  };

  /** @deprecated */
  const createEmptyModel = (modelType: ModelRootType, id: ModelId) => {
    setModelStatus((prev) => prev.setIndeterminate(true));
    const emptyModel = getEmptyModel(modelType, id);
    const createModel = () => {
      return modelType === ModelRootType.Site
        ? masonApi.putDraftSiteJSON(
            {
              orgId: orgId,
              siteId: siteId,
            } as SiteGetRequest,
            emptyModel
          )
        : masonApi.putDraftBuildingJSON(
            {
              orgId: orgId,
              siteId: siteId,
              buildingId: buildingId,
            } as BuildingGetRequest,
            emptyModel
          );
    };

    createModel().then(
      (_) => {
        setFetchedModel(emptyModel);
        setModelMeta({
          modelId: emptyModel.id,
          schema: emptyModel.schema,
          modelName: "",
        });
        setModelExists(true);
        setModelStatus((prev) => prev.setIndeterminate(false));
      },
      (err) =>
        setModelStatus((prev) =>
          prev.setError(
            "There was an error creating the new model. Please try again."
          )
        )
    );
  };

  const renderDraftStatusLabel = () => {
    if (hasPendingDrafts === undefined) return null;
    const colour: SemanticCOLORS = hasPendingDrafts
      ? StateColours.Waiting
      : StateColours.Success;
    const status = "Site " + (hasPendingDrafts ? "Unpublished" : "Published");
    return <Label color={colour}>{status}</Label>;
  };

  return (
    <Accordion>
      <Accordion.Title
        active={modelSectionOpen}
        onClick={() => setModelSectionOpen((prev) => !prev)}
      >
        <Header>
          <span css={tw`cursor-pointer flex items-center`}>
            <Icon
              name={"dropdown"}
              rotated={modelSectionOpen ? "clockwise" : undefined}
            />
            {modelType} Model {renderDraftStatusLabel()}
          </span>
        </Header>
      </Accordion.Title>
      <AccordionContent active={modelSectionOpen}>
        <UIStatusWrapper
          status={
            location.state?.error
              ? new UIStatus().setError(location.state.error)
              : new UIStatus()
          }
          afterClear={() => navigate(location.pathname)}
        >
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <UIStatusWrapper status={modelStatus}>
                  {modelExists === false ? (
                    <CreateModel
                      {...props}
                      siteId={siteId!!}
                      createEmptyModel={createEmptyModel}
                    />
                  ) : (
                    <SiteOrBuildingModelWrapper
                      {...props}
                      setHasPendingDrafts={setHasPendingDrafts}
                      modelMeta={modelMeta}
                      fetchedModel={fetchedModel}
                      selectedElement={selectedElement}
                      setSelectedElement={setSelectedElement}
                      handleModelReset={onModelReset}
                      breadcrumbs={breadcrumbs}
                      setBreadcrumbs={setBreadcrumbs}
                      currentCrumb={currentCrumb}
                      refetchModel={fetchModelDraft}
                    />
                  )}
                </UIStatusWrapper>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </UIStatusWrapper>
      </AccordionContent>
    </Accordion>
  );
};

export default SiteOrBuildingModelAccordion;
