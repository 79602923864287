import React from "react";
import MDEditor, { commands, PreviewType } from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";
import {
  getLabelProps,
  getLabelledInputLayoutProps,
  LabelledInput,
  LabelProps,
  LabelledInputLayoutProps,
  UNSPECIFIED_STRING_MSG,
} from "components/shared";

export type MarkdownInputProps = {
  value?: string;
  onChange?: (_?: string) => void;
  isReadOnly?: boolean;
  defaultView?: PreviewType;
  readOnlyEmptyText?: string;
} & LabelProps &
  LabelledInputLayoutProps;

export const MarkdownInput = (props: MarkdownInputProps) => {
  const labelProps = getLabelProps(props);
  const labelledInputProps = getLabelledInputLayoutProps(props);

  const placeholderMarkdown = ``;

  const input = (
    <div className="markdown-container" data-color-mode="dark">
      {props.isReadOnly ? (
        <MDEditor.Markdown
          source={
            props.value && props.value !== ""
              ? props.value
              : props.readOnlyEmptyText ?? UNSPECIFIED_STRING_MSG
          }
        />
      ) : (
        <MDEditor
          previewOptions={{
            rehypePlugins: [[rehypeSanitize]],
          }}
          toolbarHeight={40}
          commands={[
            commands.bold,
            commands.italic,
            commands.strikethrough,
            commands.hr,
            commands.title,
            commands.divider,
            commands.link,
            commands.quote,
            commands.code,
            commands.codeBlock,
            commands.divider,
            commands.unorderedListCommand,
            commands.orderedListCommand,
            commands.checkedListCommand,
          ]}
          extraCommands={[
            commands.codeEdit,
            commands.codeLive,
            commands.codePreview,
          ]}
          preview={props.defaultView}
          height={300}
          value={props.value ?? placeholderMarkdown}
          onChange={props.onChange}
        />
      )}
    </div>
  );

  return labelProps.label ? (
    <LabelledInput {...labelProps} {...labelledInputProps} input={input} />
  ) : (
    input
  );
};
