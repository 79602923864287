/* @jsxImportSource @emotion/react */

import * as React from "react";
import {
  Dropdown,
  DropdownProps,
  Icon,
  Input,
  Menu,
  Popup,
} from "semantic-ui-react";
import { getSiteDisplayLabel } from "../SitesAndBuildings/SitesAndBuildings";
import tw from "twin.macro";
import { OrgId } from "data/Enodia";
import { SitesAndBuildingsResponse } from "data/Mason/Site/SiteTypes";
import { compareString } from "components/shared/utils/string-utils";
import { SITE_ICON_NAME } from "components/SitesAndBuildings/SiteOrBuildingIcon";

export const SiteSearcher: React.FC<{
  orgId?: OrgId;
  sites: SitesAndBuildingsResponse[];
  loadingSites: boolean;
  saveSelectedSites: Function;
  multiple?: boolean;
  dropdownProps?: DropdownProps;
  includeAllSites?: boolean;
}> = ({
  orgId,
  sites,
  loadingSites,
  saveSelectedSites,
  multiple = true,
  dropdownProps = {},
  includeAllSites,
}) => {
  const [searchString, setSearchString] = React.useState<string>("");
  const [validSites, setValidSites] = React.useState<
    SitesAndBuildingsResponse[]
  >([]);
  const [selectedSites, setSelectedSites] = React.useState<
    SitesAndBuildingsResponse[]
  >([]);
  const [disableSearch, setDisableSearch] = React.useState<boolean>(false);

  const placeholder: string = dropdownProps.placeholder
    ? dropdownProps.placeholder
    : "Select sites ...";

  const noResultsMessage: string = dropdownProps.noResultsMessage
    ? dropdownProps.noResultsMessage.toString()
    : "No sites found.";

  React.useEffect(() => {
    setValidSites(
      sites
        ? sites.filter(
            (site) =>
              searchString === "" ||
              site.id.toLowerCase().includes(searchString.toLowerCase())
          )
        : []
    );
  }, [searchString, sites]);

  React.useEffect(() => {
    setDisableSearch(!!sites && sites.length === 0);
  }, [sites]);

  React.useEffect(
    () => saveSelectedSites(selectedSites),
    [selectedSites, saveSelectedSites]
  );

  React.useEffect(
    () => {
      if (includeAllSites && sites) {
        setSelectedSites(sites);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(sites), includeAllSites]
  );

  const getSiteMapping = React.useCallback(
    () =>
      sites
        ? sites.map((siteMeta: SitesAndBuildingsResponse) => ({
            text: getSiteDisplayLabel(siteMeta),
            metadata: siteMeta,
            description: orgId,
          }))
        : [],
    [orgId, sites]
  );

  const getSiteIndexMapping = React.useCallback(
    () =>
      getSiteMapping().map((d, idx) => ({
        ...d,
        value: idx,
        key: idx,
      })),
    [getSiteMapping]
  );

  const getValues = React.useMemo(() => {
    const ret = getSiteIndexMapping()
      .filter((d) => selectedSites.includes(d.metadata))
      .map((d) => d.value);

    if (multiple) {
      return ret;
    }
    if (ret.length > 0) {
      return ret[0];
    }
    return undefined;
  }, [selectedSites, getSiteIndexMapping, multiple]);

  const handleClick = (siteMeta: SitesAndBuildingsResponse) => {
    if (multiple) {
      if (selectedSites.includes(siteMeta)) {
        setSelectedSites((p) => p.filter((x) => x !== siteMeta));
      } else {
        setSelectedSites((p) => p.concat(...[siteMeta]));
      }
    } else {
      if (selectedSites.includes(siteMeta)) {
        setSelectedSites([]);
      } else {
        setSelectedSites([siteMeta]);
      }
    }
  };

  const renderDropdown = () => (
    <Dropdown
      className="icon"
      loading={loadingSites}
      disabled={loadingSites || disableSearch || includeAllSites}
      noResultsMessage={noResultsMessage}
      fluid
      selection
      multiple={multiple}
      clearable
      open={false}
      options={
        disableSearch
          ? []
          : getSiteIndexMapping().sort((a, b) => compareString(a.text, b.text))
      }
      value={disableSearch ? [] : getValues}
      onChange={(_, d) => {
        if (d.value) {
          if (multiple) {
            const l = d.value as Array<any>;
            setSelectedSites(l.map((d) => getSiteMapping()[d].metadata));
          } else {
            setSelectedSites([getSiteMapping()[d.value as any].metadata]);
          }
        } else {
          setSelectedSites([]);
        }
      }}
      renderLabel={(label) => ({
        content: label.text,
        icon: SITE_ICON_NAME,
      })}
      {...dropdownProps}
      placeholder={
        loadingSites
          ? "Loading sites ..."
          : disableSearch
          ? noResultsMessage
          : placeholder
      }
    />
  );

  const renderMenu = () =>
    disableSearch ? (
      <span>No results found.</span>
    ) : (
      <div className="dch-scrollbar" css={tw`overflow-y-scroll max-h-80 m-0`}>
        <Menu vertical inverted fluid borderless size="large">
          <Menu.Item>
            <Input
              icon="search"
              placeholder="Search sites..."
              value={searchString}
              onChange={(_, d) => setSearchString(d.value)}
            />
          </Menu.Item>
          {validSites.length === 0 ? (
            <Menu.Item key={"no-sites"}>
              <Menu.Header>{noResultsMessage}</Menu.Header>
            </Menu.Item>
          ) : (
            validSites.map((siteMeta) => (
              <Menu.Item
                key={`${orgId}:${siteMeta.id}`}
                active={selectedSites.includes(siteMeta)}
                onClick={(e, d) => handleClick(siteMeta)}
              >
                <Icon name={SITE_ICON_NAME} color="purple" />
                {siteMeta.id}
              </Menu.Item>
            ))
          )}
        </Menu>
      </div>
    );

  return (
    <div>
      <Popup
        on="click"
        basic
        flowing
        wide="very"
        disabled={disableSearch || includeAllSites}
        position="bottom left"
        trigger={renderDropdown()}
        content={renderMenu()}
        inverted
      />
    </div>
  );
};
