import { JobState } from "components/shared";
import { DataPoolId, PointId } from "data/Aletheia";
import { BuildingId, SiteId } from "data/brick";
import { OrgId, OrgUid } from "data/Enodia";
import { BuildingMetaResponse, SiteMetaResponse } from "data/Mason";
import { SiteBuildingReference } from "data/QueryApi/queryTypes";
import { StreamId } from "data/senaps";
import { VendorId, VendorUid } from "./Vendor/vendorTypes";

export type ApplicationId = string & { readonly __tag: unique symbol };
export type ApplicationUid = string & { readonly __tag: unique symbol };
export type ApplicationUrl = string & { readonly __tag: unique symbol };
export type ApplicationVersion = string & { readonly __tag: unique symbol };
export type CategoryUid = string & { readonly __tag: unique symbol };
/**
 * MARKETPLACE
 */
export type AvailableApplicationDetails = {
  id: ApplicationId;
  name: string;
  description: string;
  categories: string[];
  versions: string[];
  installations: number;
  vendorName: string;
  vendorUid: VendorUid;
  overview: string;
  screenshotCount: number;
};

type CommonApplicationResponse = {
  id: ApplicationId;
  applicationUid: ApplicationUid;
  name: string;
  author: string;
  vendorId: VendorId;
  vendorUid: VendorUid;
  description: string;
  version: string;
  published: boolean;
  deprecated: boolean;
  categories: string[];
  installedCount: number;
};

export type ApplicationRecipeDetailResponse = {
  id: ApplicationId;
  vendorId: OrgId;
  vendorUid: OrgUid;
  published: boolean;
  installations: number;
  screenshotCount: number;
} & CommonApplicationRecipeData;

export type CommonApplicationRecipeData = {
  name: string;
  description: string;
  author: string;
  categoryUids: string[];
  overview: string;
};

export type AvailableApplicationResponse = CommonApplicationResponse;

export enum SortOrder {
  ASCENDING = "ASCENDING",
  DESCENDING = "DESCENDING",
}
export enum SortOptions {
  name = "name",
  author = "author",
  description = "description",
  vendorId = "vendorId",
  createdTime = "createdTime",
  instanceName = "instanceName",
  applicationName = "applicationName",
}

export type GetMetisSortParams = {
  sortBy?: SortOptions;
  sortOrder?: SortOrder;
  offset?: number;
  limit?: number;
};

export type GetApplicationsSearchParams = {
  name?: string;
  vendorUid?: string;
  author?: string;
  categoryUid?: string;
  organisationId?: OrgId;
  organisationUid?: OrgUid;
  siteId?: SiteId;
  buildingId?: BuildingId;
  applicationRecipeUid?: string;
} & GetMetisSortParams;

/**
 * INSTALLED APPLICATIONS
 */
export type InstalledApplicationResponse = {
  name: string;
  description: string;
  applicationName: string;
  applicationId: ApplicationUid;
  id: ApplicationUid;
  ownerOrganisationUid: OrgUid;
  ownerOrganisationId: OrgId;
  sites: SiteMetaResponse[];
  buildings: BuildingMetaResponse[];
  version: string;
  dashboardUrl: ApplicationUrl;
  externalReference: string;
  lastKnownRun?: string;
  categories: string[];
  applicationRecipeUid: ApplicationUid;
};

export type ApplicationVersionBriefResponse = {
  id: ApplicationId;
  version: ApplicationVersion;
  published: boolean;
  deprecated: boolean;
  uploadTime: string;
  configurationInstructions: string;
  requirements: string;
  changeLog: string;
};

export type OperatorDetailsResponse = {
  operatorId: string;
  uid: ApplicationId;
  name: string;
  version: string;
  description: string;
  vendorUid: ApplicationId;
  deployed: boolean;
  externalId: ApplicationId;
};

export type InstallationValidationReport = {
  missingData?: Array<string>;
  inaccessibleStream?: Array<string>;
  reason?: string;
  isCompatible: boolean;
};

/**
 * INSTALLED APPLICATION DOCUMENTS
 */
export type InstalledApplicationMetadataResponse = {
  models?: Array<BuildingId>;
  configurationDocuments: Array<MetadataConfig>;
  readablePorts: Array<string>;
  writablePorts: Array<string>;
  autoRunOnInstall?: boolean;
  canRun?: boolean;
  runLabel?: string;
  version?: ApplicationVersion;
};

export type MetadataConfig = {
  documentId: string;
  label?: string;
  type?: string;
  description?: string;
};

type PointInfo = {
  dataPoolId: DataPoolId;
  orgId: OrgId;
  pointId: PointId;
};

export type StreamInfo = {
  id: string;
  label?: string;
  referenceIds: StreamId[];
  isCollection: boolean;
  points: PointInfo[];
};

/**
 *  APPLICATION JOBS
 */
export type ApplicationJobstatusResponse = {
  status: JobState;
  runtime: string;
  starttime: Date;
};

export type ApplicationJobLogResponse = {
  entries: JobLogEntry[];
};

export type JobLogEntry = {
  message: string;
  level: string;
  timestamp: Date;
};

export type ApplicationInstallRequest = {
  modelIdentifiers: SiteBuildingReference[];
  applicationId: ApplicationId;
  name?: string;
};

export type StreamManifestResponse = Map<string, StreamInfo>;

/**
 * APPLICATION SCHEDULES
 */
export type ScheduleRequest = {
  name: string;
  cron: string;
  active: boolean;
};

export type Schedule = {
  name: string;
  cron: string;
  active: boolean;
};

export type JsonData =
  | string
  | number
  | boolean
  | null
  | JsonData[]
  | { [x: string]: JsonData };

export type ApplicationVersionFullResponse = {
  uid: string;
  version: ApplicationVersion;
  applicationUid: ApplicationUid;
  briqlQueries: object;
  frontendRecipe: string;
  dataFlowGraphs: object[];
  autoRunOnInstall: boolean;
  presetSchedule: object;
  canRun: boolean;
  runLabel: string;
  resourceRequirement: string;
  configurationDocuments: object[];
  readablePorts: string[];
  writablePorts: string[];
  dashboardDateLocation: object;
  presetDocuments: object;
  operatorDependencies: string[];
  published: boolean;
  deprecated: boolean;
  uploadTime: string;
  changeLog: string;
  requirements: string;
  configurationInstructions?: string;
};

export type ApplicationVersionPutRequest = {
  autoRunOnInstall: boolean;
  canRun: boolean;
  runLabel: string;
  resourceRequirement: string;
  configurationDocuments: object[];
  readablePorts: string[];
  writablePorts: string[];
  dashboardDateLocation: object;
  deprecated: boolean;
  changeLog: string;
  requirements: string;
  configurationInstructions?: string;
};

export type ApplicationRecipeUploadResponse = {
  id: ApplicationId;
  name: string;
  description: string;
  author: string;
  version: string;
  versionId: string;
  messages: string[];
};

/**
 * CATEGORIES
 */
export type CategoryResponse = {
  uid: CategoryUid;
  name: string;
  description: string;
};
