import { useMemo, useState } from "react";
import { FetchStatus } from "data/constants";
import { getClassesWithParent } from "data/EntityClasses/ParentClassHelper";
import { DchClass, useEntityClassesContext } from "./EntityClassesContext";
import { ClassHypernym, masonApi } from "data/Mason";

export const useEntityClasses = () => {
  const { entityClasses, setEntityClasses } = useEntityClassesContext();
  const [fetchingClassesStatus, setFetchingClassesStatus] = useState(
    FetchStatus.Pending
  );
  const [fetchingHypernyms, setFetchingHypernyms] = useState<
    Array<ClassHypernym>
  >([]);
  const [errorHypernyms, setErrorHypernyms] = useState<Array<ClassHypernym>>(
    []
  );

  useMemo(() => {
    const fetchHypernymClasses = (hypernymsToFetch: Array<ClassHypernym>) => {
      setFetchingClassesStatus(FetchStatus.Fetching);
      const fetchedHypernymClasses = new Map<
        ClassHypernym,
        Map<string, DchClass>
      >();
      setFetchingHypernyms((prev) => [...prev, ...hypernymsToFetch]);

      //fetch the necessary ones one by one and add to this array
      const entityClassesPromises = hypernymsToFetch.map((hypernym) => {
        return masonApi.getSchemaTypes({ hypernym }).then(
          (res) => {
            fetchedHypernymClasses.set(
              hypernym,
              getClassesWithParent(hypernym, res)
            );
          },
          () => {
            console.error(
              "error fetching entity property definitions for",
              hypernym
            );
            if (!errorHypernyms.includes(hypernym))
              setErrorHypernyms((prev) => [...prev, hypernym]);
          }
        );
      });

      Promise.all(entityClassesPromises)
        .then(() =>
          setEntityClasses(
            new Map([...entityClasses, ...fetchedHypernymClasses])
          )
        )
        .then(() =>
          setFetchingHypernyms((prev) =>
            prev.filter((hypernym) => !hypernymsToFetch.includes(hypernym))
          )
        );
    };

    //if we don't already have the classes for a hypernym, go fetch it and store in context
    const hypernymClassesToFetch = [
      ClassHypernym.Location,
      ClassHypernym.Equipment,
      ClassHypernym.Point,
      ClassHypernym.Zone,
      ClassHypernym.Collection,
    ].filter(
      (h) =>
        !entityClasses.get(h) &&
        !fetchingHypernyms.includes(h) &&
        !errorHypernyms.includes(h)
    );
    if (
      hypernymClassesToFetch.length > 0 &&
      fetchingClassesStatus === FetchStatus.Pending
    )
      fetchHypernymClasses(hypernymClassesToFetch);
  }, [
    fetchingClassesStatus,
    errorHypernyms,
    setEntityClasses,
    entityClasses,
    fetchingHypernyms,
  ]);

  return {
    entityClasses,
  };
};
