import { metisEnv } from "reducers/env";
import { ClientEnv } from "data/utils";
import * as O from "fp-ts/lib/Option";
import {
  httpDelete,
  httpGet,
  httpPost,
  httpPostRaw,
  httpPut,
} from "data/httpUtil";
import { RJSFSchema } from "@rjsf/utils";
import { DateRange as DateRangeResponse } from "components/shared";
import {
  InstalledApplicationResponse,
  ApplicationId,
  ApplicationInstallRequest,
  InstalledApplicationMetadataResponse,
  AvailableApplicationResponse,
  StreamManifestResponse,
  ScheduleRequest,
  ApplicationJobstatusResponse,
  ApplicationJobLogResponse,
  ApplicationVersionBriefResponse,
  ApplicationVersionFullResponse,
  OperatorDetailsResponse,
  AvailableApplicationDetails,
  GetApplicationsSearchParams,
  CategoryResponse,
  InstallationValidationReport,
  ApplicationUid,
} from "./metisTypes";
import { VendorApi, vendorApi } from "./Vendor/vendorApi";
import { ManageVendorApi, manageVendorApi } from "./Vendor/manageVendorApi";
import {
  manageApplicationsApi,
  ManageApplicationsApi,
} from "./manageApplicationsApi";
import { screenshotsApi, ScreenshotsApi } from "./screenshotsApi";

/**
 * Application system API ENDPOINTS
 * AKA. Metis
 * @link https://develop.dataclearinghouse.org/api/metis/v1/swagger#/
 */
type MetisApi = {
  getApplications: (
    params: GetApplicationsSearchParams
  ) => Promise<Array<InstalledApplicationResponse>>;
  getApplication: (
    applicationId: string
  ) => Promise<InstalledApplicationResponse>;
  runApplication: (applicationId: string) => Promise<void>;
  stopApplication: (applicationId: string) => Promise<void>;
  getApplicationRecipe: (
    applicationId: string
  ) => Promise<AvailableApplicationDetails>;
  getApplicationRecipes: (
    searchParams: GetApplicationsSearchParams
  ) => Promise<Array<AvailableApplicationResponse>>;
  getApplicationRecipeVersions: (
    applicationUid: ApplicationUid
  ) => Promise<Array<ApplicationVersionBriefResponse>>;
  getApplicationVersion: (
    applicationId: ApplicationId,
    applicationVersionId: ApplicationId
  ) => Promise<ApplicationVersionFullResponse>;
  getOperator: (operatorId: ApplicationId) => Promise<OperatorDetailsResponse>;
  postInstallApplication: (
    applicationInstallRequest: ApplicationInstallRequest
  ) => Promise<InstalledApplicationResponse>;
  postValidateCompatibility: (
    installApplicationRequest: ApplicationInstallRequest
  ) => Promise<InstallationValidationReport>;
  deleteApplication: (applicationUid: ApplicationUid) => Promise<void>;
  getApplicationMetadata: (
    applicationId: string
  ) => Promise<InstalledApplicationMetadataResponse>;
  getApplicationDocument: (
    applicationId: string
  ) => (documentId: string) => Promise<string>;
  getApplicationDocumentSchema: (
    applicationId: string
  ) => (documentId: string) => Promise<RJSFSchema>;
  setApplicationDocument: (
    applicationId: string
  ) => (documentId: string) => (documentBody: string) => Promise<void>;
  getApplicationSchedule: (applicationId: string) => Promise<string>;
  getApplicationStreams: (
    applicationId: string
  ) => Promise<StreamManifestResponse>;
  getApplicationJobStatus: (
    applicationId: string
  ) => Promise<ApplicationJobstatusResponse>;
  getApplicationJobLog: (
    applicationId: string
  ) => Promise<ApplicationJobLogResponse>;
  deleteApplicationSchedule: (applicationId: string) => Promise<void>;
  setApplicationSchedule: (
    applicationId: string
  ) => (schedule: ScheduleRequest) => Promise<void>;
  getDashboardDateWindow: (
    applicationId: string
  ) => Promise<DateRangeResponse | undefined>;
  getCategories: () => Promise<CategoryResponse[]>;
} & VendorApi &
  ManageVendorApi &
  ManageApplicationsApi &
  ScreenshotsApi;

export const mkMetisApi = (env: ClientEnv): MetisApi => ({
  getApplications: (queryParams: GetApplicationsSearchParams) =>
    httpGet(O.none)(env)(queryParams)("/applications").then(
      (r) => r as Array<InstalledApplicationResponse>
    ),
  getApplication: (applicationId: string) =>
    httpGet(O.none)(env)(null)(`/applications/${applicationId}`).then(
      (r) => r as InstalledApplicationResponse
    ),
  runApplication: (applicationId: string) =>
    httpPost(null)(O.none)(env)(null)(
      `/applications/${applicationId}/start`
    ).then((_) => undefined),
  stopApplication: (applicationId: string) =>
    httpPost(null)(O.none)(env)(null)(
      `/applications/${applicationId}/stop`
    ).then((_) => undefined),
  getApplicationRecipes: (queryParams: GetApplicationsSearchParams) =>
    httpGet(O.none)(env)(queryParams)("/application/recipes").then(
      (r) => r as Array<AvailableApplicationResponse>
    ),
  getApplicationRecipe: (applicationId: string) =>
    httpGet(O.none)(env)(null)(`/application/recipes/${applicationId}`).then(
      (r) => r as AvailableApplicationDetails
    ),
  getApplicationRecipeVersions: (applicationUid: ApplicationUid) =>
    httpGet(O.none)(env)(null)(
      `/application/recipes/${applicationUid}/versions`
    ).then((r) => r as Array<ApplicationVersionBriefResponse>),
  getApplicationVersion: (
    applicationId: ApplicationId,
    applicationVersionId: ApplicationId
  ) =>
    httpGet(O.none)(env)(null)(
      `/manage/application/recipes/${applicationId}/versions/${applicationVersionId}`
    ).then((r) => r as ApplicationVersionFullResponse),
  getOperator: (operatorId: ApplicationId) =>
    httpGet(O.none)(env)(null)(`/manage/operators/${operatorId}`).then(
      (r) => r as OperatorDetailsResponse
    ),
  postInstallApplication: (
    applicationInstallRequest: ApplicationInstallRequest
  ) =>
    httpPost(applicationInstallRequest)(O.none)(env)(null)(
      "/applications"
    ).then((response) => response as InstalledApplicationResponse),
  postValidateCompatibility: (
    applicationInstallRequest: ApplicationInstallRequest
  ) =>
    httpPost(applicationInstallRequest)(O.none)(env)(null)(
      "/applications/validate-compatibility"
    ).then((response) => response as InstallationValidationReport),
  deleteApplication: (applicationUid: ApplicationUid) =>
    httpDelete(O.none)(env)(null)(`/applications/${applicationUid}`).then(
      (_) => undefined
    ),
  getApplicationMetadata: (applicationId: string) =>
    httpGet(O.none)(env)(null)(`/applications/${applicationId}/metadata`).then(
      (r) => {
        return r as InstalledApplicationMetadataResponse;
      }
    ),
  getApplicationDocument: (applicationId: string) => (documentId: string) =>
    httpGet(O.none)(env)(null)(
      `/applications/${applicationId}/document/${documentId}`
    ).then((r) => {
      return r as string;
    }),
  getApplicationDocumentSchema:
    (applicationId: string) => (documentId: string) =>
      httpGet(O.none)(env)(null)(
        `/applications/${applicationId}/document/${documentId}/schema`
      ).then((r) => {
        return r as RJSFSchema;
      }),
  setApplicationDocument:
    (applicationId: string) => (documentId: string) => (documentBody: string) =>
      httpPostRaw(documentBody)(O.none)(env)(null)(
        `/applications/${applicationId}/document/${documentId}`
      ).then((_) => undefined),
  getApplicationSchedule: (applicationId: string) =>
    httpGet(O.none)(env)(null)(`/applications/${applicationId}/schedule`).then(
      (r) => r as string
    ),
  deleteApplicationSchedule: (applicationId: string) =>
    httpDelete(O.none)(env)(null)(
      `/applications/${applicationId}/schedule`
    ).then((_) => undefined),
  setApplicationSchedule:
    (applicationId: string) => (schedule: ScheduleRequest) =>
      httpPut(schedule)(O.none)(env)(null)(
        `/applications/${applicationId}/schedule`
      ).then((_) => undefined),
  getDashboardDateWindow: (applicationId: string) =>
    httpGet(O.none)(env)(null)(
      `/applications/${applicationId}/dashboard/date-window`
    ).then((r) => {
      // DCH-3148: We have to remove the [UTC] suffix from the date string so it's valid ISO8601
      let dateWindow = r as unknown as
        | { start: string; end: string }
        | undefined;
      if (dateWindow !== undefined) {
        return {
          start: new Date(dateWindow.start.replace("[UTC]", "")),
          end: new Date(dateWindow.end.replace("[UTC]", "")),
        } as DateRangeResponse;
      }
      return dateWindow;
    }),
  getApplicationStreams: (applicationId: string) =>
    httpGet(O.none)(env)(null)(`/applications/${applicationId}/streams`).then(
      (r) => r as unknown as StreamManifestResponse
    ),
  getApplicationJobStatus: (applicationId: string) =>
    httpGet(O.none)(env)(null)(`/applications/${applicationId}/jobstatus`).then(
      (r) => r as unknown as ApplicationJobstatusResponse
    ),
  getApplicationJobLog: (applicationId: string) =>
    httpGet(O.none)(env)(null)(`/applications/${applicationId}/joblog`).then(
      (r) => r as unknown as ApplicationJobLogResponse
    ),
  getCategories: () =>
    httpGet(O.none)(env)(null)("/categories").then(
      (r) => r as Array<CategoryResponse>
    ),
  ...vendorApi,
  ...manageVendorApi,
  ...manageApplicationsApi,
  ...screenshotsApi,
});

export const metisApi = mkMetisApi(metisEnv);
