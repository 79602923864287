/** @jsxImportSource @emotion/react */

import { useCallback, useState } from "react";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import { SearchResult } from "leaflet-geosearch/src/providers/provider";
import { useFormContext } from "react-hook-form";
import { DropdownOnSearchChangeData, Grid } from "semantic-ui-react";
import { debounce } from "throttle-debounce";
import { API_CALL_DEBOUNCE_MS } from "data/validation";
import { SelectInput, UIStatus, toOptionTypeBase } from "components/shared";
import Form from "components/shared/Forms/ReactHookForm";

const mapProvider = new OpenStreetMapProvider({
  params: {
    countrycodes: "au",
  },
});

export const Location = () => {
  const [searchResults, setSearchResults] = useState<SearchResult[]>();
  const [searchValue, setSearchValue] = useState<string>();
  const [uiStatus, setUiStatus] = useState<UIStatus>(new UIStatus());
  const { setValue } = useFormContext();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(API_CALL_DEBOUNCE_MS, (str: string | undefined) => {
      if (str) {
        setUiStatus((prevState) => prevState.setIndeterminate(true));
        mapProvider
          .search({ query: str })
          .then((results) => {
            setSearchResults(results);
            setUiStatus((prevState) => prevState.setIndeterminate(false));
          })
          .catch((e) =>
            setUiStatus((prevState) =>
              prevState.setError(e.message || `Unable to search for: '${str}'`),
            ),
          );
      } else {
        setUiStatus((prevState) => prevState.setIndeterminate(false));
      }
    }),
    [],
  );

  return (
    <Grid.Row verticalAlign="middle">
      <Grid.Column width={8}>
        <SelectInput
          label="Location"
          placeholder="Search for a location"
          noResultsMessage="No locations found."
          options={searchResults?.map((r) => toOptionTypeBase(r.label)) ?? []}
          value={searchValue}
          isLoading={uiStatus.indeterminate}
          onChange={(_, { value }) => {
            setSearchValue(value as string);
            if (!value) {
              setValue("location.latitude", undefined);
              setValue("location.longitude", undefined);
              setSearchResults([]);
            } else if (searchResults) {
              const result = searchResults.find((r) => r.label === value);
              if (result) {
                setValue("location.latitude", result.y, { shouldDirty: true });
                setValue("location.longitude", result.x, { shouldDirty: true });
              }
            }
          }}
          search={(options) => options} //allow typing but don't restrict results - fetch on typing takes care of the matching
          onSearchChange={(_, { searchQuery }: DropdownOnSearchChangeData) => {
            setUiStatus((prevState) => prevState.setIndeterminate(true));
            debouncedSearch(searchQuery);
          }}
        />
      </Grid.Column>

      <Grid.Column width={4}>
        <Form.TextInput
          name="location.longitude"
          label="Longitude"
          type="number"
        />
      </Grid.Column>

      <Grid.Column width={4}>
        <Form.TextInput
          name="location.latitude"
          label="Latitude"
          type="number"
        />
      </Grid.Column>
    </Grid.Row>
  );
};
