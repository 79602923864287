import { Option, none } from "fp-ts/lib/Option";
import { ClientEnv, ClientEnvHeaders, SenapsClientEnv } from "../utils";
import { Eq, eqString } from "fp-ts/lib/Eq";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import { httpPost, httpGet } from "../http";
import { pipe } from "fp-ts/lib/function";
import { senapsEnv } from "../../reducers/senapsEnv";
import { Json } from "data/Models/Brick/BrickValidationUtils";

/* Many of the optional values in the types likely
 * aren't actually optional, but the senaps swagger
 * spec isn't marking them as mandatory, so I'm not
 * making assumptions.
 * https://senaps.eratos.com/api-docs/sensorcloud-spec.json
 */

const toClientEnv =
  (baseUrl: string) =>
  (senapsEnv: SenapsClientEnv): ClientEnv => ({ baseUrl, ...senapsEnv });

const mkSensorEnv = toClientEnv("/api/sensor/v2");
const mkAccountEnv = toClientEnv("/api/accounts");
const mkAnalysisEnv = (e: SenapsClientEnv): ClientEnv => {
  const e_ = ClientEnvHeaders<SenapsClientEnv>().modify((h) =>
    Object.assign({}, h, { Accept: "application/json, text/plain" }),
  )(e);
  return toClientEnv("/api/analysis")(e_);
};

export type StreamId = string & { readonly __tag: unique symbol };
export type OrganisationId = string & { readonly __tag: unique symbol };
export type GroupId = string & { readonly __tag: unique symbol };
export type LocationId = string & { readonly __tag: unique symbol };
export type ProcedureId = string & { readonly __tag: unique symbol };
export type ISO8601 = string & { readonly __tag: unique symbol };
export type ISO8601Period = string & { readonly __tag: unique symbol };
export type Timezone = string & { readonly __tag: unique symbol };
export type HumanReadableHash = string & { readonly __tag: unique symbol };
export type WorkflowId = string & { readonly __tag: unique symbol };

export const eqStreamId: Eq<StreamId> = eqString as Eq<StreamId>;

export enum ResultType {
  scalarvalue = "scalarvalue",
  geolocationvalue = "geolocationvalue",
}

export type Href = { href: string };

export type Self = { self?: Href };

export type Next = { next?: Array<Href> };

export type ObservationsLink = { observations: Href };

export type ReportingType = string;

export type SamplePeriod = string;

export type Summary = { t?: string; v?: { v?: any } & object };

export type ResultsSummary = {
  count?: number;
  first?: Summary;
  last?: Summary;
};

export type Links = { _links?: Self };

export type StreamMetadataEmbedded = {
  unitOfMeasure?: Array<Links>;
  observedProperty?: Array<Links>;
  interpolationType?: Array<Links>;
};

export type StreamMetadata = {
  cummulative?: boolean;
  type?: string;
  _embedded?: StreamMetadataEmbedded;
};

export type StreamEmbedded = { metadata?: Array<StreamMetadata> };

export type Stream = {
  id: StreamId;
  _links: Self & ObservationsLink;
  resulttype: ResultType;
  reportingPeriod?: ReportingType;
  samplePeriod?: SamplePeriod;
  resultsSummary: ResultsSummary;
  usermetadata?: {};
  _embedded: StreamEmbedded;
};

export enum Type {
  ScalarStreamMetaData = ".ScalarStreamMetaData",
}

export type Vocabulary = string;

export enum InterpolationType {
  Continuous = "http://www.opengis.net/def/waterml/2.0/interpolationType/Continuous",
  Discontinuous = "http://www.opengis.net/def/waterml/2.0/interpolationType/Discontinuous",
  InstantTotal = "http://www.opengis.net/def/waterml/2.0/interpolationType/InstantTotal",
  AveragePrec = "http://www.opengis.net/def/waterml/2.0/interpolationType/AveragePrec",
  MaxPrec = "http://www.opengis.net/def/waterml/2.0/interpolationType/MaxPrec",
  MinPrec = "http://www.opengis.net/def/waterml/2.0/interpolationType/MinPrec",
  TotalPrec = "http://www.opengis.net/def/waterml/2.0/interpolationType/TotalPrec",
  ConstPrec = "http://www.opengis.net/def/waterml/2.0/interpolationType/ConstPrec",
  AverageSucc = "http://www.opengis.net/def/waterml/2.0/interpolationType/AverageSucc",
  TotalSucc = "http://www.opengis.net/def/waterml/2.0/interpolationType/TotalSucc",
  MinSucc = "http://www.opengis.net/def/waterml/2.0/interpolationType/MinSucc",
  MaxSucc = "http://www.opengis.net/def/waterml/2.0/interpolationType/MaxSucc",
  ConstSucc = "http://www.opengis.net/def/waterml/2.0/interpolationType/ConstSucc",
}

export type ScalarStreamMetadata = {
  type: Type;
  observedProperty: Vocabulary;
  unitOfMeasure: Vocabulary;
  interpolationType: InterpolationType;
  cummulative: Option<boolean>;
  accumulationInterval: Option<ISO8601Period>;
  accumulationAnchor: Option<ISO8601>;
  timezone: Option<Timezone>;
};

export type Streams = { streams: Array<Stream> };

export type StreamCollection = {
  _links?: Self & Next;
  _embedded?: Streams;
  count?: number;
};

export type StreamCollectionCount = { _links?: Self; count?: number };

export type WKTPoint = string;
export type Metres = number;
export type Property = string;

export type GetStreamsParams = {
  id?: string;
  limit?: number;
  skip?: number;
  resulttype?: ResultType;
  expand?: boolean;
  recursive?: boolean;
  groupids?: GroupId;
  organisationid?: OrganisationId;
  locationid?: LocationId;
  near?: WKTPoint;
  radius?: Metres;
  "streamMetadata.observedProperty"?: string;
  "streamMetadata.unitOfMeasure"?: string;
  properties?: Array<Property>;
  usermetadatafield?: string;
  usermetadatavalues?: Array<string>;
};

export type GetStreamsCountParams = {
  id?: string;
  resulttype?: ResultType;
  expand?: boolean;
  recursive?: boolean;
  groupids?: GroupId;
  organisationid?: OrganisationId;
  locationid?: LocationId;
  near?: WKTPoint;
  radius?: Metres;
  "streamMetadata.observedProperty"?: string;
  "streamMetadata.unitOfMeasure"?: string;
  properties?: Array<Property>;
  usermetadatafield?: string;
  usermetadatavalues?: Array<string>;
};

export type GetStreamIdParams = { recursive?: boolean };

export type GetObservationsParams = {
  // must include one of streamid, groupid, or platformid
  streamid: StreamId; // single id, or a comma seperated list. Wildcards * and ? are permitted.
  groupid?: string;
  platformid?: string;
  start?: string; // ISO8601 string, time range start
  end?: string; // ISO8601 string, time range end
  time?: string; // ISO8601 string, specific time
  si?: boolean; // start time is inclusive
  ei?: boolean; // end time is inclusive
  limit?: number;
  sort?: "descending";
  skipstreams?: number;
  limitstreams?: number;
};

export type GetAggregationParams = {
  streamid: StreamId;
  start?: string; // ISO8601 string
  end?: string; // ISO8601 string
  limit?: number;
  aggperiod: number;
};

export type AggregationResult = {
  t: string;
  v: { avg: number; min: number; max: number };
};
export type Aggregation = { results: Array<AggregationResult> };

export type Observation = SingleStreamObservation | MultiStreamObservation;

export type SingleStreamObservation = {
  t: string;
  v: { v: number | Array<number> };
};

export type MultiStreamObservation = Record<
  string,
  Record<string, { v: number | Array<number> }>
>;

export type Observations = { results: Array<Observation> } & StreamCollection;

type DataParserConfig = { parserid: string; configblob?: Json };

type PostMqttClientConfig = {
  parser: DataParserConfig;
  username: string;
  password: string | null;
  type: "mqtt_client";
};

type GetMqttClientConfig = {
  readonly password: null;
} & PostMqttClientConfig;

export type GetMqttClient = {
  type: "mqtt_client";
  config: GetMqttClientConfig;
};

export type PostMqttClient = {
  type: "mqtt_client";
  config: PostMqttClientConfig;
};

export type MqttClientBroker = {
  type: "mqtt_broker";
  config: {
    host: string;
    parser: string;
    username: string;
    password: string | null;
    port: number;
    protocol: string;
    subscribetopic: string;
    type: "mqtt_broker";
  };
};

// senaps name
export type DataParserConfigWebDTO = {
  parserid: string;
  configblob: Json;
};

// senaps name
export type MqttClientConfigWebDTO = {
  username: string;
  password: string | null;
  type: "mqtt_client";
  parser: DataParserConfigWebDTO;
};

// senaps name
export type MqttBrokerConfigWebDTO = {
  username: string;
  password: string | null;
  protocol: "mqtt" | "mqtts";
  host: string;
  port: number;
  subscribetopic: "string";
  type: "mqtt_broker";
  parser: DataParserConfigWebDTO;
};

export type Organisation = { id?: string; name?: string; _links?: Self };

export type Permission = { type: string };

export type RoleId = string;

export type Role = {
  id?: RoleId;
  _links?: Self;
  permissions?: Array<Permission>;
  type?: string;
  implicit?: boolean;
  addressfilters?: Array<string>;
};

export type RoleCollection = {
  _links?: Self;
  _embedded?: { roles?: Array<Role> };
  count?: number;
};
export type InvitationId = string;

/** @deprecated - replaced by type of same name */
export type UserId = string;

export type User = {
  id?: string;
  hidden?: boolean;
  _links?: Self;
  _embedded?: { roles?: Array<Role> };
};

export type Group = { id?: string; name?: string; description?: string };

export type GetWorkflowsParams = {
  skip?: number;
  limit?: number;
  name?: string;
  id?: WorkflowId;
  description?: string;
  organisation?: string;
  groupids?: string;
};

export type WorkflowCollectionItem = {
  organisationid?: string;
  groupids?: Array<string>;
  name: string;
  description?: string;
  id: WorkflowId;
  _links?: Href;
};

export type WorkflowCollection = {
  limit?: number;
  count?: number;
  skip?: number;
  totalcount?: number;
  _links?: {
    next?: Href;
    previous?: Href;
    last?: Href;
    self?: Href;
    first?: Href;
  };
  _embedded?: {
    workflows?: Array<WorkflowCollectionItem>;
  };
};

export type RunAs = {
  roles: Array<string>;
};

export type GraphNode = GraphNodeSingle | GraphNodeCollection;

export type GraphNodeSingle = {
  id?: string;
  label?: string;
  documentid?: string;
};

export type GraphNodeCollection = {
  id?: string;
  label?: string;
  _embedded?: {
    collection?: Array<{
      documentid?: string;
      valuetruncated?: boolean;
      organisationid?: string;
      groupids?: [string];
      type?: string;
      _links?: {
        self?: Href;
        value?: Href;
      };
    }>;
  };
};

export const getGraphNodeDocumentId = (g: GraphNode): string | void => {
  if ("documentid" in g) {
    return g.documentid;
  } else if ("_embedded" in g)
    return pipe(
      g._embedded?.collection || [],
      A.head,
      O.map((o) => o.documentid),
      O.toUndefined,
    );
};

export type NodeReference = {
  node?: string;
};

export type GraphConnection = {
  source?: NodeReference;
  target?: NodeReference;
};

export type WorkflowGraph = {
  _embedded?: {
    nodes?: Array<GraphNode>;
    connections?: Array<GraphConnection>;
  };
};

export type Workflow = {
  runas?: RunAs;
  logstruncatedto?: string;
  organisationid?: string;
  groupids?: Array<string>;
  name: string;
  description?: string;
  id: WorkflowId;
  _links?: {
    cancel?: Href;
    self?: Href;
  };
  _embedded: {
    graph?: WorkflowGraph;
  };
};

export type SenapsAPI = {
  getStreams: (_: GetStreamsParams) => Promise<StreamCollection>;
  getStreamsCount: (_: GetStreamsCountParams) => Promise<StreamCollectionCount>;
  getStreamId: (_: StreamId) => (_: GetStreamIdParams) => Promise<Stream>;
  getAggregation: (_: GetAggregationParams) => Promise<Aggregation>;
  getObservations: (_: GetObservationsParams) => Promise<Observations>;
  getObservationsCsv: (_: GetObservationsParams) => Promise<string>;
  getVocabularyProxy: (_: string) => Promise<Json>;
};

// TODO add validators on the JSON return
export const mkSensorAPI = (senapsEnv: SenapsClientEnv): SenapsAPI => {
  const env = mkSensorEnv(senapsEnv);
  return {
    getStreams: (p) =>
      httpGet<GetStreamsParams>(none)(env)(p)("/streams").then(
        (j) => j as unknown as StreamCollection,
      ),
    getStreamsCount: (p) =>
      httpGet<GetStreamsCountParams>(none)(env)(p)("/streams/count").then(
        (j) => j as unknown as StreamCollectionCount,
      ),
    getStreamId: (i) => (p) =>
      httpGet<GetStreamIdParams>(none)(env)(p)(`/streams/${i}`).then(
        (j) => j as unknown as Stream,
      ),
    getAggregation: (p) =>
      httpGet<GetAggregationParams>(none)(env)(p)("/aggregation").then(
        (os) => os as unknown as Aggregation,
      ),
    getObservations: (p) =>
      httpGet<GetObservationsParams>(none)(env)(p)("/observations").then(
        (os) => os as unknown as Observations,
      ),
    getObservationsCsv: (p): Promise<string> =>
      httpGet<GetObservationsParams>(none)(env)(
        Object.assign({}, p, { media: "csv" }),
      )("/observations").then((r) => r as string),
    getVocabularyProxy: (uri) =>
      httpGet(none)(env)(none)(
        `/vocabularyProxy?id=${encodeURIComponent(uri)}`,
      ) as Promise<Json>,
  };
};

export const sensorApi = mkSensorAPI(senapsEnv);

export type GetJobParams = {
  skip?: number;
  limit?: number;
  scheduleid?: string;
  workflowid?: string;
};

export type GetJobsRes = {
  limit?: number;
  count?: number;
  skip?: number;
  totalcount?: number;
  _links?: {
    next?: Href;
    previous?: Href;
    last?: Href;
    self?: Href;
    first?: Href;
  };
  _embedded?: { jobs?: Array<JobCollectionItem> };
};

export type JobCollectionItem = {
  organisationid?: string;
  groupids?: Array<string>;
  progress?: number;
  starttime?: string;
  id?: string;
  endtime?: string;
  complete?: boolean;
  workflowid?: string;
  errorcount?: number;
  scheduleid?: string;
  status?: string;
  timestamp?: string;
  elapsedtime?: string;
  _links?: {
    schedule?: Href;
    blockingjobs?: Href;
    upstreamjobs?: Href;
    workflow?: Href;
    downstreamjobs?: Href;
    self?: Href;
    blockedbyjobs?: Href;
  };
};

export type GetSchedulesParams = {
  skip?: number;
  limit?: number;
  workflowid?: string;
};
export type GetSchedulesRes = {
  limit?: number;
  count?: number;
  skip?: number;
  totalcount?: number;
  _links?: {
    next?: Href;
    previous?: Href;
    last?: Href;
    self?: Href;
    first?: Href;
  };
  _embedded?: { schedules?: Array<ScheduleCollectionItem> };
};
export type ScheduleCollectionItem = {
  cron?: string;
  organisationid?: string;
  groupids?: Array<string>;
  name?: string;
  active?: boolean;
  id?: string;
  _links?: { self?: Href };
};

export type AnalysisAPI = {
  getDocumentValue: (_: string) => Promise<Json>;
  getJobs: (_: GetJobParams) => Promise<GetJobsRes>;
  getSchedules: (_: GetSchedulesParams) => Promise<GetSchedulesRes>;
  getWorkflows: (_: GetWorkflowsParams) => Promise<WorkflowCollection>;
  getWorkflowsId: (_: WorkflowId) => Promise<Workflow>;
  mkSenapsScheduleURL: (_: string) => string;
};

const mkAnalysisAPI = (senapsEnv: SenapsClientEnv): AnalysisAPI => {
  const env = mkAnalysisEnv(senapsEnv);
  return {
    getDocumentValue: (docId) =>
      httpGet(none)(env)(null)(`/documentnodes/${docId}/value`).then(
        (r) => r as Json,
      ),
    getJobs: (params) =>
      httpGet(none)(env)(params)("/jobs").then((r) => r as GetJobsRes),
    getSchedules: (params) =>
      httpGet(none)(env)(params)("/schedules").then(
        (r) => r as GetSchedulesRes,
      ),
    getWorkflows: (params) =>
      httpGet(none)(env)(params)("/workflows").then(
        (r) => r as unknown as WorkflowCollection,
      ),
    getWorkflowsId: (id) =>
      httpGet(none)(env)(null)(`/workflows/${id}`).then(
        (r) => r as unknown as Workflow,
      ),
    mkSenapsScheduleURL: (scheduleid) =>
      `${senapsEnv.scheme}://${O.getOrElse(() => "senaps.eratos.com")(
        senapsEnv.host,
      )}${O.fold(
        () => "",
        (p) => `:${p}`,
      )(senapsEnv.port)}/dashboard/#/app/schedule/detail/${scheduleid}`,
  };
};

export const analysisApi = mkAnalysisAPI(senapsEnv);

type PostSignupParams = { userid: string; password: string };
type ResetPasswordParams = {
  requestid: string;
  userid: string;
  password: string;
};

export type AccountAPI = {
  postSignup: (_: PostSignupParams) => Promise<Json>;
  getReset: (email: string) => Promise<void>;
  resetPassword: (_: ResetPasswordParams) => Promise<void>;
};

const mkAccountAPI = (senapsEnv: SenapsClientEnv): AccountAPI => {
  const env = mkAccountEnv(senapsEnv);
  return {
    postSignup: (p) =>
      httpPost(p)(none)(env)(null)("/signup").then((res) => res as Json),

    getReset: (email: string) =>
      httpGet(none)(env)(null)(
        `/request/${email}?app_id=dataclearinghouse.org`,
      ).then((_) => undefined),

    resetPassword: (p) =>
      httpPost(p)(none)(env)(null)("/reset").then((_) => undefined),
  };
};
/** 
 * @deprecated - still need this temporarily 
 * Reason - (as mentioned by Mac)
 *  Senaps currently fulfills the role of "authorization server" in the OAuth2 authentication process. If a user has no Senaps identity, they cannot authenticate in DCH.
    IIRC, users still currently need a Senaps identity to access time-series data.
*/
export const accountApi = mkAccountAPI(senapsEnv);
