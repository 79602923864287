import React from "react";
import { FieldValues } from "react-hook-form";
import { Button, Divider } from "semantic-ui-react";
import Form from "components/shared/Forms/ReactHookForm";

interface FormValues extends FieldValues {
  text: string;
  date: string;
  checkbox: boolean;
  select: string;
  file: File;
}

export const ReactHookForm = () => {
  return (
    <Form onSubmit={(data: FormValues) => console.log(data)}>
      <Form.TextInput name="text" label="Text Input" />
      <Form.DateInput name="date" label="Date Input" />
      <Form.CheckboxInput name="checkbox" label="Checkbox Input" />
      <Form.SelectInput
        name="select"
        label="Select Input"
        options={[
          { value: "option1", text: "Option 1" },
          { value: "option2", text: "Option 2" },
        ]}
      />
      <Form.FileInput name="file" label="File Input" />
      <Divider />
      <Button basic inverted type="submit">
        Submit
      </Button>
    </Form>
  );
};
