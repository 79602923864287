/** @jsxImportSource @emotion/react */
import React from "react";
import { Table } from "semantic-ui-react";
import { RoleRequest } from "data/Enodia";
import { SelectInput, UIStatus, toOptionTypeBase } from "components/shared";

export enum VerbType {
  Admin = "Admin",
  Create = "Create",
  Delete = "Delete",
  Execute = "Execute",
  Read = "Read",
  Update = "Update",
}

export const IMPLICIT_VERBS: { [key: string]: Set<VerbType> } = {
  [VerbType.Admin]: new Set(
    Object.values(VerbType).filter((v) => v !== VerbType.Admin),
  ),
  [VerbType.Create]: new Set([VerbType.Read, VerbType.Update]),
  [VerbType.Update]: new Set([VerbType.Read]),
  [VerbType.Delete]: new Set([VerbType.Read]),
};

type DropdownOptionsSelectorProps = {
  role: RoleRequest;
  onChange: (_: RoleRequest) => void;
  isReadOnly: boolean;
  optionsStatus: UIStatus;
  verbOptions: Map<string, string[]>;
};

export const DropdownOptionsSelector: React.FunctionComponent<
  DropdownOptionsSelectorProps
> = ({ role, onChange, isReadOnly, optionsStatus, verbOptions }) => {
  return (
    <Table.Cell>
      <SelectInput
        testId="PermissionsVerbSelect"
        placeholder="Select ..."
        isLoading={optionsStatus.indeterminate}
        value={role.verbs as string[]}
        search
        isReadOnly={isReadOnly}
        isMulti
        onChange={(_, { value }) => {
          onChange({
            ...role,
            verbs: value as string[],
          });
        }}
        options={verbOptions.get(role.scopeType)?.map(toOptionTypeBase) ?? []}
      />
    </Table.Cell>
  );
};
