/** @jsxImportSource @emotion/react */

import tw from "twin.macro";
import { Button, Icon } from "semantic-ui-react";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "App";
import { UIStatus, UIStatusWrapper } from "components/shared";
import PreLoginAgreementsModal from "./PreLoginAgreementsModal";

const FIVE_SECOND_MS = 5_000;

const LoginForm = () => {
  const authContext = useContext(AuthContext);

  const [status, setStatus] = useState(new UIStatus());
  useEffect(
    () => {
      if (authContext.state?.error) {
        setStatus((prevState) =>
          prevState.setError(authContext.state?.error || "Error logging in")
        );
      } else {
        setStatus(new UIStatus());
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authContext.state?.error]
  );

  const handleClearError = () => {
    authContext.resetSessionStorage();
    authContext.resetError();
  };

  const handleErrorStatus = () => {
    const timer = setInterval(() => {
      handleClearError();
    }, FIVE_SECOND_MS);
    return () => clearInterval(timer);
  };

  return (
    <UIStatusWrapper
      status={status}
      onErrorDismiss={handleClearError}
      onOpen={handleErrorStatus}
    >
      <PreLoginAgreementsModal />
      <Button
        type="submit"
        primary
        css={tw`w-full`}
        style={{
          padding: "1em",
          marginBlock: "1.5rem",
        }}
        loading={status.indeterminate}
        onClick={() => {
          setStatus((prevState) => prevState.setIndeterminate(true));
          authContext.login();
        }}
      >
        <div css={tw`flex justify-between`}>
          <span>Sign in</span>
          <Icon name="long arrow alternate right" />
        </div>
      </Button>
    </UIStatusWrapper>
  );
};

export default LoginForm;
