import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { EquipmentId, FloorId, LocationId, PointId } from "data/brick";
import { NodeFormFields } from "data/Mason/ModelDraft/ModelDraftUtils";
import { EntityProperties } from "../../EntityProperties/EntityProperties";
import { EntityProperty } from "../../EntityProperties/EntityProperty";
import { ModelEntityCollectionProps } from "../ModelFormUtils";
import {
  useIsFedByWatcher,
  useLocationOfWatcher,
  usePointsWatcher,
} from "../RelationshipWatchers";
import { handleArrayToFieldChanges } from "../RelationshipWatchers/RelationshipWatcherUtils";
import { useFieldArrayNodes } from "../useFieldArrayNodes";
import {
  Comment,
  CommonInputProps,
  DeleteButton,
  Floors,
  Id,
  IsFedBy,
  LocationOf,
  Name,
  Points,
} from "./CommonInputs";
import { FloorFields } from "./Floor";
import { ClassHypernym } from "data/Mason";

export type WingFields = {
  locationOf?: LocationId[];
  isFedBy?: EquipmentId[];
  floors?: FloorId[];
  points?: PointId[];
  properties?: EntityProperty[];
} & NodeFormFields;

export const Wing = ({
  isReadOnly,
  index,
  deleteNode,
}: ModelEntityCollectionProps) => {
  const inputProps: CommonInputProps = {
    isReadOnly,
    formFieldName: "wings",
    objectName: "wing",
    index,
  };

  useWingRelationshipWatcher(inputProps);

  return (
    <>
      <Id {...inputProps} />
      <Name {...inputProps} />
      <LocationOf {...inputProps} />
      <IsFedBy {...inputProps} />
      <Floors {...inputProps} />
      <Points {...inputProps} />
      <Comment {...inputProps} />
      <EntityProperties
        {...inputProps}
        hypernym={ClassHypernym.Location}
        parentClass="Wing"
      />
      {!isReadOnly && (
        <DeleteButton onDelete={() => deleteNode(index)} objectName="wing" />
      )}
    </>
  );
};

const useWingRelationshipWatcher = (inputProps: CommonInputProps) => {
  const { isReadOnly, formFieldName, index } = inputProps;
  const { getValues, watch, setValue } = useFormContext();
  const { floorFields } = useFieldArrayNodes();

  const entityIdentifier = `${formFieldName}.${index}`;
  const entityFieldId = getValues(`${entityIdentifier}.fieldId`);

  const watcherProps = { isReadOnly, entityFieldId, entityIdentifier };
  useLocationOfWatcher(watcherProps);
  useIsFedByWatcher(watcherProps);
  usePointsWatcher(watcherProps);

  //FLOORS
  const watchFloors = watch(`${entityIdentifier}.floors`);
  useEffect(() => {
    if (!isReadOnly) {
      handleArrayToFieldChanges({
        entityFieldId,
        getValues,
        setValue,
        watchedField: watchFloors,
        existingTargetFields: floorFields.fields,
        targetNodeType: "floor",
        targetProperty: "wing" as keyof FloorFields,
      });
    }
  }, [
    entityFieldId,
    floorFields.fields,
    getValues,
    isReadOnly,
    setValue,
    watchFloors,
  ]);
};
