/** @jsxImportSource @emotion/react */
import React from "react";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import {
  addSeconds,
  differenceInSeconds,
  formatDistance,
  subSeconds,
  max as maxDate,
  min as minDate,
  isEqual,
} from "date-fns";
import { constant, Endomorphism } from "fp-ts/lib/function";
import { Button, Grid, SemanticWIDTHS } from "semantic-ui-react";
import { debounce } from "throttle-debounce";
import tw, { styled } from "twin.macro";
import {
  DateRangeInput,
  UIStatus,
  UIStatusWrapper,
  DATE_TIME_FORMAT,
} from "components/shared";
import styles from "./Chart.module.css";

const am4chartsDchTheme = (target: unknown) => {
  if (target instanceof am4core.Scrollbar) {
    target.background.fill = am4core.color("white");
  }
  if (target instanceof am4core.ColorSet) {
    target.list = [am4core.color("#AC4DBB")];
  }
};

am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_dark);
am4core.useTheme(am4chartsDchTheme);

export type Datum<t> = {
  x: Date;
  y: t;
};

export type ChartData<a> = Array<{ data: Array<Datum<a>>; name?: string }>;
const isChartDataEmpty = (d: ChartData<any>) => {
  return d.filter((i) => i.data.length > 0).length === 0;
};

function setSeries<a>(data: ChartData<a>) {
  return (
    chart: am4charts.XYChart,
    hasScrollbarX: boolean,
    hasScrollbarY: boolean,
  ) => {
    // Add no data found label
    if (isChartDataEmpty(data)) {
      const label = chart.createChild(am4core.Label);
      label.text = "No data found";
      label.fontSize = 20;
      label.isMeasured = false;
      label.x = am4core.percent(50);
      label.y = am4core.percent(50);

      label.horizontalCenter = "middle";
      chart.cursor.behavior = "none";
    } else {
      chart.cursor.behavior = "zoomX";
    }

    // Update chart data
    chart.data = data;
    chart.series.clear();

    let scrollbarX = new am4charts.XYChartScrollbar();
    let scrollbarY = new am4charts.XYChartScrollbar();

    data?.forEach((d) => {
      const series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.dateX = "x";
      series.dataFields.valueY = "y";
      if (data.length > 1) {
        series.tooltipText = "{name}: {valueY}";
      } else {
        series.tooltipText = "{valueY}";
      }
      series.data = d.data;
      if (d.name != null) {
        series.name = d.name;
      }

      if (hasScrollbarX) {
        scrollbarX.series.push(series);
      }

      if (hasScrollbarY) {
        scrollbarY.series.push(series);
      }
    });

    if (hasScrollbarX) {
      chart.scrollbarX = scrollbarX;
    }

    if (hasScrollbarY) {
      chart.scrollbarY = scrollbarY;
    }
  };
}

export type ChartProps<a> = {
  id: string;
  className?: string;
  data?: (f: (now: Date) => Endomorphism<Array<Datum<a>>>) => ChartData<a>;
  header?: string;
  getDataForRange?: (start: Date) => (end: Date) => Promise<ChartData<a>>;
  hasDataLimit?: boolean;
  min?: Date;
  max?: Date;
  showExportMenu?: boolean;
  filename?: string;
  mountNodeId?: string;
};

const Bold = tw.span`font-bold`;

const ChartWrapper = styled.div`
  ${tw`border border-solid p-4`};
`;

export const DATE_RANGE_CONTROL_WIDTHS = (() => {
  const prevOrNextButton = {
    widescreen: 4 as SemanticWIDTHS,
    largeScreen: 4 as SemanticWIDTHS,
    computer: 16 as SemanticWIDTHS,
    tablet: 16 as SemanticWIDTHS,
    mobile: 16 as SemanticWIDTHS,
  };

  const fromOrToDateTime = {
    widescreen: 4 as SemanticWIDTHS,
    largeScreen: 4 as SemanticWIDTHS,
    computer: 16 as SemanticWIDTHS,
    tablet: 16 as SemanticWIDTHS,
    mobile: 16 as SemanticWIDTHS,
  };

  return {
    prevButton: prevOrNextButton,
    fromDateTime: fromOrToDateTime,
    toDateTime: fromOrToDateTime,
    nextButton: prevOrNextButton,
  };
})();

type DateRange = {
  start: Date;
  end: Date;
};
function addZoomChangeListener(
  chart: am4charts.XYChart,
  dateAxis: am4charts.DateAxis,
  onChange: () => void,
  setDateRange: React.Dispatch<React.SetStateAction<DateRange | undefined>>,
) {
  let update = debounce(1000, (ev) => {
    const axis = ev.target;
    const fromDate = new Date(axis.minZoomed);
    const toDate = new Date(axis.maxZoomed);

    setDateRange((d) => {
      if (d && isEqual(d.start, fromDate) && isEqual(d.end, toDate)) {
        return d;
      } else {
        return {
          start: fromDate,
          end: toDate,
        } as DateRange;
      }
    });
  });

  const onRangeChange = (ev: any) => {
    if (!chart.isReady()) {
      return;
    }
    onChange();
    update(ev);
  };

  try {
    dateAxis.events.on("startchanged", onRangeChange);
    dateAxis.events.on("endchanged", onRangeChange);
  } catch (e) {}
}

// Auto-disposing charts
// https://www.amcharts.com/docs/v4/tutorials/chart-was-not-disposed/#Auto_disposing_charts
am4core.options.autoDispose = true;

export function Chart<a>({
  id,
  min,
  max,
  getDataForRange,

  // true if the response of getDataForRange might not be the full
  // date in the period
  hasDataLimit,
  showExportMenu,
  filename,
  data,
  header,
  className,
  mountNodeId,
}: ChartProps<a>): JSX.Element {
  const chart = React.useRef<am4charts.XYChart | null>(null);
  const idRef = React.useRef<string>(id);
  const [uiStatus, setUiStatus] = React.useState<UIStatus>(new UIStatus());
  const [dateRange, setDateRange] = React.useState<DateRange | undefined>();

  // flag to avoid unnecessary reload when setting the date range to the data range received from
  // backend
  const [skipNextDateRangeReload, setSkipNextDateRangeReload] =
    React.useState<boolean>(false);

  const [originalDateRange, setOriginalDateRange] = React.useState<
    DateRange | undefined
  >();

  React.useEffect(() => {
    if (min && max) {
      setOriginalDateRange({ start: min, end: max });
      setDateRange((p) => {
        if (p && min && max && isEqual(p.start, min) && isEqual(p.end, max)) {
          return p;
        }

        return {
          start: min,
          end: max,
        } as DateRange;
      });
    }
  }, [min, max]);

  React.useEffect(
    () => {
      if (skipNextDateRangeReload) {
        setSkipNextDateRangeReload(false);
        return;
      }

      const amChart = am4core.create(idRef.current, am4charts.XYChart);
      chart.current = amChart;

      // Add an export menu for image and data exports
      if (showExportMenu) {
        chart.current.exporting.menu = new am4core.ExportMenu();
        if (filename) {
          chart.current.exporting.filePrefix = filename;
        }
        chart.current.exporting.adapter.add("data", function () {
          let d: { data: Array<any> } = { data: [] };
          if (chart.current !== null) {
            chart.current.series.each(function (series) {
              for (let ii = 0; ii < series.data.length; ii++) {
                series.data[ii].name = series.name;
                d.data.push(series.data[ii]);
              }
            });
          }
          return d;
        });
      }

      let dateAxis = chart.current.xAxes.push(new am4charts.DateAxis());
      dateAxis.title.text = "Date";
      dateAxis.groupData = false;
      dateAxis.dateFormats.setKey("day", "d MMM");
      dateAxis.dateFormats.setKey("hour", "H:mm");
      dateAxis.tooltipDateFormat = DATE_TIME_FORMAT;

      let yAxes = new am4charts.ValueAxis();
      chart.current.yAxes.push(yAxes);

      // disable autoscaling of the y axis
      chart.current.events.on("ready", () => {
        yAxes.min = yAxes.minZoomed;
        yAxes.max = yAxes.maxZoomed;
      });

      chart.current.cursor = new am4charts.XYCursor();
      chart.current.legend = new am4charts.Legend();
      chart.current.zoomOutButton.disabled = true;

      if (getDataForRange && dateRange) {
        setUiStatus((prevState) => prevState.setIndeterminate(true));
        getDataForRange(dateRange.start)(dateRange.end).then(
          (d) => {
            setUiStatus((prevState) => prevState.setIndeterminate(false));
            if (chart.current) {
              if (hasDataLimit) {
                const dates = d.flatMap(({ data }) => data.map(({ x }) => x));
                const from = minDate(dates);
                const to = maxDate(dates);

                if (
                  !isEqual(dateRange.start, from) ||
                  !isEqual(dateRange.end, to)
                ) {
                  setSkipNextDateRangeReload(true);
                  setDateRange({
                    start: from,
                    end: to,
                  });
                }
              }

              setSeries(d)(chart.current, false, true);
              addZoomChangeListener(
                chart.current,
                chart.current.xAxes.getIndex(0) as am4charts.DateAxis,
                () => {
                  setUiStatus((prevState) => prevState.setIndeterminate(true));
                },
                setDateRange,
              );
            }
          },
          (e) => {
            setUiStatus((prevState) =>
              prevState.setError(
                e.error || e.message || "Could not load chart data",
              ),
            );
          },
        );
      } else if (data) {
        const chartData = data(constant((l: Array<Datum<a>>) => l));
        setSeries(chartData)(chart.current, true, true);
      }
    },

    // excluding "skipNextDataRangeReload" to skip the reload on setting date range with the
    // actual date range received
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, showExportMenu, filename, hasDataLimit, getDataForRange, dateRange],
  );

  const dateDistance =
    (dateRange?.end &&
      dateRange?.start &&
      formatDistance(dateRange.end, dateRange.start)) ||
    "";

  const minMaxProps = (() => {
    return {
      minDate: min,
      maxDate: max,
    };
  })();

  const applyMin = (d: Date) => (min ? maxDate([d, min]) : d);

  const applyMax = (d: Date) => (max ? minDate([d, max]) : d);

  const isZoomed =
    originalDateRange &&
    (dateRange?.start !== originalDateRange.start ||
      dateRange?.end !== originalDateRange.end);
  const resetZoom = () => {
    if (isZoomed) setDateRange(originalDateRange);
  };

  return (
    <ChartWrapper>
      <Grid>
        {header != null && (
          <Grid.Row>
            <Grid.Column>
              <Bold>{header}</Bold>{" "}
            </Grid.Column>
          </Grid.Row>
        )}
        {getDataForRange ? (
          <Grid.Row
            verticalAlign="bottom"
            centered
            className={styles["control-row"]}
          >
            <Grid.Column {...DATE_RANGE_CONTROL_WIDTHS.prevButton}>
              {!dateRange || (min && isEqual(dateRange.start, min)) ? null : (
                <Button
                  fluid
                  content={dateDistance}
                  icon="left arrow"
                  labelPosition="left"
                  onClick={() => {
                    setDateRange(
                      (d) =>
                        d && {
                          start: applyMin(
                            subSeconds(
                              d.start,
                              Math.abs(differenceInSeconds(d.end, d.start)),
                            ),
                          ),
                          end: d.start,
                        },
                    );
                  }}
                  disabled={uiStatus.indeterminate}
                />
              )}
            </Grid.Column>

            <DateRangeInput
              dateRange={dateRange}
              setDateRange={setDateRange}
              columnWidths={DATE_RANGE_CONTROL_WIDTHS.toDateTime}
              disabled={uiStatus.indeterminate}
              {...minMaxProps}
              mountNodeId={mountNodeId}
            />

            <Grid.Column {...DATE_RANGE_CONTROL_WIDTHS.nextButton}>
              {!dateRange || (max && isEqual(dateRange.end, max)) ? null : (
                <Button
                  fluid
                  content={dateDistance}
                  icon="right arrow"
                  labelPosition="right"
                  onClick={() => {
                    setDateRange(
                      (d) =>
                        d && {
                          start: d.end,
                          end: applyMax(
                            addSeconds(
                              d.end,
                              Math.abs(differenceInSeconds(d.end, d.start)),
                            ),
                          ),
                        },
                    );
                  }}
                  disabled={uiStatus.indeterminate}
                />
              )}
            </Grid.Column>
          </Grid.Row>
        ) : undefined}

        <Grid.Row>
          <Grid.Column>
            <UIStatusWrapper
              status={uiStatus}
              loadingDataMsg="Loading chart data"
              clearable
            >
              <div id={idRef.current} className={className} />
              {isZoomed && (
                <div
                  style={{ position: "absolute", top: "1rem", right: "6rem" }}
                >
                  <Button
                    circular
                    basic
                    inverted
                    icon="zoom-out"
                    onClick={() => resetZoom()}
                  />
                </div>
              )}
            </UIStatusWrapper>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </ChartWrapper>
  );
}
