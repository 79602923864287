import React from "react";
import { FieldValues, SubmitHandler } from "react-hook-form";
import { ApiKeyResponse } from "data/Enodia";
import { isValidName } from "data/validation";
import Form, { FormStateValues } from "components/shared/Forms/ReactHookForm";

export const API_KEY_DETAILS_FORM_ID = "ApiKeyDetailsForm";

export interface ApiKeyFormValues extends ApiKeyResponse, FieldValues {
  name: string;
  enabled: boolean;
  expires?: string;
}

type KeyDetailsProps = {
  defaultValues?: ApiKeyResponse;
  onSubmit: SubmitHandler<ApiKeyFormValues>;
  setFormState?: (_: FormStateValues<ApiKeyFormValues>) => void;
};

export const KeyDetailsForm = (props: KeyDetailsProps) => {
  return (
    <Form {...props} formId={API_KEY_DETAILS_FORM_ID}>
      <Form.TextInput
        name="name"
        label="Name"
        required
        rules={{ validate: isValidName }}
      />
      <Form.DateInput
        name="expires"
        label="Expiry"
        description="(optional)"
        minDate={new Date()}
        mountNodeId="ApiKeyModal"
      />
      <Form.CheckboxInput
        name="enabled"
        label="Enabled?"
        toggle
        defaultValue={true}
      />
    </Form>
  );
};
