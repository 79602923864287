/** @jsxImportSource @emotion/react */

import { UIStatus } from "components/shared";
import { pointClassifierApi, aletheiaApi } from "data/Aletheia";
import React, { useEffect } from "react";
import {
  ClassifierSearchMode,
  ClassifierSetMetadata,
  ClassifierSetSearchState,
} from "./ClassifierSet";
import {
  PageState,
  PointClassifierPageState,
} from "./usePointClassifierPageState";
import { useParams } from "react-router-dom";

export const useGetStateFromPaths = (
  pageContext: PointClassifierPageState,
  setClassifierSetSearch: React.Dispatch<
    React.SetStateAction<ClassifierSetSearchState>
  >,
  setStatus: React.Dispatch<React.SetStateAction<UIStatus>>,
  setClassifierSet: React.Dispatch<React.SetStateAction<ClassifierSetMetadata>>,
  setShowResults: (showResults: boolean) => void,
  setCanPurgeClassifiers: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const urlPaths = useParams<PageState>();
  useEffect(() => {
    setStatus((prev) => prev.setIndeterminate(true));
    setupContextFromPath(
      urlPaths,
      pageContext,
      setClassifierSetSearch,
      setStatus,
      setClassifierSet,
      setShowResults,
      setCanPurgeClassifiers
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

function setupContextFromPath(
  urlParams: Readonly<Partial<PageState>>,
  pageContext: PointClassifierPageState,
  setClassifierSetSearch: React.Dispatch<
    React.SetStateAction<ClassifierSetSearchState>
  >,
  setStatus: React.Dispatch<React.SetStateAction<UIStatus>>,
  setClassifierSet: React.Dispatch<React.SetStateAction<ClassifierSetMetadata>>,
  setShowResults: (showResults: boolean) => void,
  setCanPurgeClassifiers: React.Dispatch<React.SetStateAction<boolean>>
) {
  if (!isSetStateRequired(urlParams, pageContext.state)) {
    setStatus((prev) => prev.setIndeterminate(false));
    return;
  }
  Promise.all([
    validateClassifierSetId(
      urlParams,
      setClassifierSetSearch,
      setClassifierSet,
      setCanPurgeClassifiers
    ),
    validateDataPoolId(urlParams),
    validateClassificationPlanId(urlParams, setShowResults),
  ])
    .then(() => {
      pageContext.setState(urlParams);
      setStatus((prev) => prev.setIndeterminate(false));
    })
    .catch((e) => {
      pageContext.setState(e.verifiedState);
      setStatus((prev) => prev.setError(e.errorMessage));
    });
}

function isSetStateRequired(
  urlParams: Readonly<Partial<PageState>>,
  pageState: PageState
) {
  const { orgId, ...relevantPathParams } = urlParams;
  const notInState = [];
  for (const [key, value] of Object.entries(relevantPathParams)) {
    const pathValue = pageState[key as keyof PageState];
    if (value && value !== pathValue) notInState.push([key, value]);
  }
  return notInState.length > 0;
}

function validateClassifierSetId(
  urlParams: Readonly<Partial<PageState>>,
  setClassifierSetSearch: React.Dispatch<
    React.SetStateAction<ClassifierSetSearchState>
  >,
  setClassifierSet: React.Dispatch<React.SetStateAction<ClassifierSetMetadata>>,
  setCanPurgeClassifiers: React.Dispatch<React.SetStateAction<boolean>>
) {
  return new Promise((resolve, reject) => {
    const { classifierSetId, orgId } = urlParams;
    if (classifierSetId) {
      pointClassifierApi
        .getClassifierSetById(classifierSetId)
        .then((res) => {
          if (res.organisationId === orgId) {
            setCanPurgeClassifiers(res.classifiers.length > 0);
            setClassifierSet(res);
            setClassifierSetSearch({
              name: res.name,
              id: res.id,
              mode: ClassifierSearchMode.search,
            });
            resolve(true);
          } else {
            reject({
              verifiedState: {},
              errorMessage:
                "Requested organisation did not match the classifier set.",
            });
          }
        })
        .catch((e) => {
          reject({
            verifiedState: { orgId },
            errorMessage: "Could not load the classifier set.",
          });
        });
    } else {
      resolve(true);
    }
  });
}

function validateDataPoolId(urlParams: Readonly<Partial<PageState>>) {
  return new Promise((resolve, reject) => {
    const { datapoolId, classificationPlanId, ...rest } = urlParams;
    if (datapoolId) {
      aletheiaApi
        .getDataPoolById(datapoolId)
        .then((res) => {
          if (res.organisationId === rest.orgId) {
            resolve(true);
          } else {
            reject({
              verifiedState: { rest },
              errorMessage:
                "Requested organisation did not match the datapool.",
            });
          }
        })
        .catch((e) => {
          reject({
            verifiedState: rest,
            errorMessage: "Could not load the data pool.",
          });
        });
    } else {
      resolve(true);
    }
  });
}

function validateClassificationPlanId(
  urlParams: Readonly<Partial<PageState>>,
  setShowResults: (showResults: boolean) => void
) {
  return new Promise((resolve, reject) => {
    const { classificationPlanId, ...rest } = urlParams;
    if (classificationPlanId) {
      pointClassifierApi
        .getDirectives(classificationPlanId)({ limit: 1 })
        .then(() => {
          setShowResults(true);
          resolve(true);
        })
        .catch((e) => {
          reject({
            verifiedState: rest,
            errorMessage: "Could not load the classification plan.",
          });
        });
    } else {
      resolve(true);
    }
  });
}
