import React from "react";
import { Controller, FieldValues, useFormContext } from "react-hook-form";
import {
  DateInput,
  DateInputProps,
  isValidDate,
  maxDateRule,
  minDateRule,
  requiredDateRule,
} from "components/shared";
import { FormInputProps } from "../ReactHookForm";

export type FormDateInputProps<TFieldValues extends FieldValues> =
  FormInputProps<TFieldValues> & DateInputProps;

export const FormDateInput = <TFieldValues extends FieldValues>(
  props: FormDateInputProps<TFieldValues>,
) => {
  const dateValidations = (dateValue: string) => {
    if (!isValidDate(dateValue)) return "Invalid Date";
    if (props.required) {
      const requiredResult = requiredDateRule(dateValue);
      if (requiredResult) return requiredResult;
    }
    if (props.minDate) {
      const minDateResult = minDateRule(dateValue, props.minDate);
      if (minDateResult) return minDateResult;
    }
    if (props.maxDate) {
      const maxDateResult = maxDateRule(dateValue, props.maxDate);
      if (maxDateResult) return maxDateResult;
    }
    return undefined;
  };
  const rules =
    props.required || props.minDate || props.maxDate
      ? {
          validate: dateValidations,
        }
      : undefined;

  const { control } = useFormContext();

  return (
    <Controller
      name={props.name}
      control={control}
      rules={rules}
      render={({ field: { value, onChange, onBlur }, fieldState }) => {
        return (
          <DateInput
            {...props}
            value={value}
            onChange={onChange}
            onBlur={() => onBlur()}
            inputValidation={
              rules
                ? {
                    invalid: fieldState.invalid,
                    errorMessage: fieldState.error?.message,
                  }
                : undefined
            }
          />
        );
      }}
    />
  );
};
