import { BuildingId, ModelRootType, SiteId } from "data/brick";
import { OrgId } from "data/Enodia";
import { masonApi } from "data/Mason";
import {
  BRICK_RELATIONSHIPS,
  BrickRelationship,
  Relationship,
} from "data/Models/Brick/brickRelationships";
import {
  DescribeResponse,
  QueryInvocation,
  QueryResponse,
} from "./queryApiTypes";
import {
  Match,
  ModelReference,
  NodeUriType,
  NodeValue,
  QueryMode,
  QueryVar,
  QueryVarType,
  ResponseValue,
  VarFields,
  isNodeValueRef,
} from "./queryTypes";
import { extractNodeIdFromUri } from "components/SitesAndBuildings/Model/ModelUtils";

export const MODEL_NODE_NAME = "node";

export type ModelResult = {
  rootModelNode: NodeValue;
  modelType: ModelRootType;
  modelIdentifiers: ModelReference[];
  relationships: Relationship[];
  nodes: NodeValue[];
};

const fetchFields: VarFields[] = [
  VarFields.id,
  VarFields.label,
  VarFields.type,
  VarFields.hypernym,
  VarFields.pointInfo, //points directly related to building
  VarFields.entityProperties,
];

const getModelNode = (modelType: ModelRootType): QueryVar => ({
  comment: "A Model",
  varType: QueryVarType.node,
  name: MODEL_NODE_NAME,
  output: true,
  fetch: fetchFields,
  brickTypes: [{ match: Match.isa, type: modelType }],
});

const getModelReferenceMap = (
  allNodes: NodeValue[],
  modelReferences: ModelReference[]
): Map<string, ModelReference> => {
  const modelReferenceMap = new Map<string, ModelReference>();
  allNodes.forEach((node) =>
    modelReferenceMap.set(node.fullId, modelReferences[node.modelIndex])
  );
  return modelReferenceMap;
};

const getRelatedNodes = () => {
  let queryVariables: QueryVar[] = [];
  BRICK_RELATIONSHIPS.forEach((relationship) => {
    queryVariables.push({
      varType: QueryVarType.node,
      comment: `The node's 1st degree ${relationship.relation}`,
      name: `${relationship.relation}`,
      output: true,
      fetch: fetchFields,
      nullable: true,
      constraints: {
        paths: [
          {
            fromRef: MODEL_NODE_NAME,
            properties: [{ property: relationship.relation }],
            toRef: `${relationship.relation}`,
          },
        ],
      },
    });
  });
  return queryVariables;
};

const getRelationshipWithInverse = (
  parent: ResponseValue,
  child: ResponseValue,
  relationship: BrickRelationship,
  solutionMap: Map<string, ModelReference>
): Relationship[] => {
  if (
    isNodeValueRef(parent) &&
    isNodeValueRef(child) &&
    parent.fullId &&
    child.fullId
  )
    return [
      {
        parents: {
          modelReference: solutionMap.get(parent.fullId)!!,
          nodeUri: parent.fullId,
        },
        child: {
          modelReference: solutionMap.get(child.fullId)!!,
          nodeUri: child.fullId,
        },
        relation: relationship.relation,
      },
      {
        parents: {
          modelReference: solutionMap.get(child.fullId)!!,
          nodeUri: child.fullId,
        },
        child: {
          modelReference: solutionMap.get(parent.fullId)!!,
          nodeUri: parent.fullId,
        },
        relation: relationship.inverse,
      },
    ];
  return [];
};

const getRelationships = (
  queryResponse: QueryResponse,
  modelReferenceMap: Map<string, ModelReference>
) => {
  const solutionTable = queryResponse.solutionTable ?? [];
  const relationships: Relationship[] = [];
  solutionTable.forEach((solution) => {
    BRICK_RELATIONSHIPS.forEach((relationship) => {
      const solutionEntry = solution[`${relationship.relation}`];
      const nodeEntry = solution[MODEL_NODE_NAME];
      if (solutionEntry && solutionEntry.varType !== "null") {
        const solutionRelationships = getRelationshipWithInverse(
          nodeEntry, //parent
          solutionEntry, //child
          relationship,
          modelReferenceMap
        );
        solutionRelationships.forEach((solutionRelationship) => {
          if (
            !relationships.some(
              (r) =>
                r.parents.nodeUri === solutionRelationship.parents.nodeUri &&
                r.child.nodeUri === solutionRelationship.child.nodeUri &&
                r.relation === solutionRelationship.relation
            )
          ) {
            relationships.push(solutionRelationship);
          }
        });
      }
    });
  });
  return relationships;
};

export const convertQueryResponseToModelResult = (
  queryResponse: QueryResponse,
  modelType: ModelRootType
): ModelResult => {
  let nodes: NodeValue[] = [];
  BRICK_RELATIONSHIPS.forEach((relationship) => {
    queryResponse.solutionNodes[`${relationship.relation}`].forEach(
      (solutionNode) => {
        //don't include duplicates
        if (!nodes.map((node) => node.fullId).includes(solutionNode.fullId))
          nodes = nodes.concat(solutionNode);
      }
    );
  });
  const rootModelNode = queryResponse.solutionNodes[MODEL_NODE_NAME][0];
  const modelReferenceMap = getModelReferenceMap(
    nodes.concat(rootModelNode ?? []),
    queryResponse.models
  );
  return {
    modelIdentifiers: queryResponse.models,
    rootModelNode,
    nodes,
    relationships: getRelationships(queryResponse, modelReferenceMap),
    modelType,
  };
};

const buildSelectQuery = (
  modelType: ModelRootType,
  orgId: OrgId,
  siteId: SiteId,
  buildingId?: BuildingId
): QueryInvocation => {
  return {
    models: [
      {
        orgId: orgId,
        siteId: siteId,
        buildingId: buildingId,
      },
    ],
    queryDef: {
      comment: `Select a model node and it's associated parts to 2 degree(s).`,
      mode: QueryMode.select,
      variables: [getModelNode(modelType), ...getRelatedNodes()],
    },
  };
};

export const selectQuery = (
  modelType: ModelRootType,
  orgId: OrgId,
  siteId: SiteId,
  buildingId?: BuildingId
): Promise<ModelResult> => {
  const selectQuery = buildSelectQuery(modelType, orgId, siteId, buildingId);
  return masonApi
    .postSelectQuery(selectQuery)
    .then((res: QueryResponse) =>
      convertQueryResponseToModelResult(res, modelType)
    );
};

const buildDescribeQuery = (
  modelReference: ModelReference,
  nodeId: string
): QueryInvocation => {
  return {
    describe: {
      modelRef: modelReference,
      nodeId: nodeId,
    },
  };
};

export const describeQuery = (
  modelReference: ModelReference,
  nodeUri: NodeUriType
): Promise<DescribeResponse> => {
  const rdfNodeId = extractNodeIdFromUri(nodeUri as NodeUriType);
  const describeQuery = buildDescribeQuery(modelReference, rdfNodeId);

  return masonApi.postDescribeQuery(describeQuery).then((res) => res);
};
