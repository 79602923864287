/** @jsxImportSource @emotion/react */

import * as React from "react";
import { Button } from "semantic-ui-react";
import tw from "twin.macro";
import { SiteId, BuildingId, ModelRootType } from "data/brick";
import { OrgId } from "data/Enodia";
import { BuildingGetRequest, masonApi, SiteGetRequest } from "data/Mason";
import { saveToFile } from "data/utils";
import { DchModal } from "components/shared";

type Props = {
  modelType: ModelRootType;
  orgId: OrgId;
  siteId: SiteId;
  buildingId?: BuildingId;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ModelDownloadModal: React.FC<Props> = (props) => {
  const { modelType, orgId, siteId, buildingId, isOpen, setIsOpen } = props;

  const params = {
    orgId: orgId as OrgId,
    siteId: siteId,
    buildingId: buildingId,
  };

  const downloadJson = () =>
    modelType === ModelRootType.Building
      ? masonApi
          .getDraftBuildingJSON(params as BuildingGetRequest)
          .then((r) =>
            saveToFile(
              JSON.stringify(r, null, 4),
              `${siteId}.${buildingId}.json`,
              "application/json"
            )
          )
      : masonApi
          .getDraftSiteJSON(params as SiteGetRequest)
          .then((r) =>
            saveToFile(
              JSON.stringify(r, null, 4),
              `${siteId}.json`,
              "application/json"
            )
          );

  const downloadRdf = () =>
    modelType === ModelRootType.Building
      ? masonApi
          .getDraftBuildingRDF(params as BuildingGetRequest)
          .then((r) =>
            saveToFile(r, `${siteId}.${buildingId}.ttl`, "text/plain")
          )
      : masonApi
          .getDraftSiteRDF(params as SiteGetRequest)
          .then((r) => saveToFile(r, `${siteId}.ttl`, "text/plain"));

  return (
    <DchModal
      open={isOpen}
      onClose={() => setIsOpen(false)}
      header={`Download your ${modelType} model`}
      content={
        <div css={tw`mb-4`}>
          <p>Please select the file format for download:</p>
          <Button primary onClick={downloadJson}>
            Download as JSON (.json)
          </Button>
          <Button primary onClick={downloadRdf}>
            Download as RDF (.ttl)
          </Button>
        </div>
      }
      hideConfirm={true}
    />
  );
};
