import { v4 as Uuid } from "uuid";
import { ModelRootType } from "data/brick";
import { DchClass } from "data/EntityClasses/EntityClassesContext";
import { LocationParentClass } from "data/EntityClasses/ParentClassHelper";
import { ClassHypernym } from "data/Mason";
import { EntityPropertyFromQueryDto } from "data/Mason/EntityProperties";
import {
  BrickProperty,
  getBrickRelationship,
} from "data/Models/Brick/brickRelationships";
import { DescribeResponse } from "data/QueryApi/queryApiTypes";
import {
  ModelReference,
  NodeUriType,
  RelatedNode,
} from "data/QueryApi/queryTypes";
import { idReplaceRegex } from "components/SitesAndBuildings/Common";
import { getDchClass } from "./Model/ModelConverter";
import { getUriFromNode } from "../ModelUtils";
import { SelectedNode } from "./Visualisation";

export type BrickEntity = {
  id: NodeUriType;
  label?: string;
  comment?: string;
  properties?: Array<EntityPropertyFromQueryDto>;
  relationships: Map<BrickProperty, FullNodeIdentifier[]>;
  unit?: string;
};

export type FullNodeIdentifier = {
  nodeUri: NodeUriType;
  modelReference: ModelReference;
};

export type BrickClass = {
  class: DchClass;
} & BrickEntity;

export type VizModelNode = BrickClass & {
  graphLabel: string;
  modelReference: ModelReference;
};

export type VizModel = {
  id: NodeUriType;
  label?: string; // the actual label from backend
  graphLabel: string; // label used for the graph
  modelClass: ModelRootType;
  nodes: Map<string, VizModelNode>;
};

export const generateNewUuid = (): string =>
  Uuid().replace(idReplaceRegex, "_");

export const getNewRelationships = (
  relatedNodes: RelatedNode[],
  existingRelationships: Map<BrickProperty, FullNodeIdentifier[]>
) => {
  return relatedNodes.filter(
    (relatedNode) =>
      !(relatedNode.type === LocationParentClass.Building) &&
      (!existingRelationships.has(relatedNode.relationship) ||
        relatedNode.node.nodeId === undefined ||
        !existingRelationships
          .get(relatedNode.relationship)
          ?.map((nodeIdentifier) => nodeIdentifier.nodeUri)
          .includes(getUriFromNode(relatedNode.node)))
  );
};

const updateNodeUnit = (
  res: DescribeResponse,
  currentNode: VizModelNode,
  vizModel?: VizModel
) => {
  if (res.unit !== currentNode.unit) {
    currentNode.unit = res.unit;
    const vizNode = vizModel?.nodes.get(currentNode.id);
    if (vizNode) vizNode.unit = res.unit;
  }
};

export const getGraphLabel = (
  node: RelatedNode | DescribeResponse,
  defaultLabel: string
): string => {
  if (node.label) return node.label;
  else if (node.type === ModelRootType.Site) return node.node.modelRef.siteId;
  else if (
    node.type === ModelRootType.Building &&
    node.node.modelRef.buildingId
  )
    return node.node.modelRef.buildingId;
  else if (!!node.node.nodeId) return node.node.nodeId;
  else return defaultLabel;
};

export const updateExistingVizModel = (
  res: DescribeResponse,
  selectedNode: SelectedNode,
  entityClasses: Map<ClassHypernym, Map<string, DchClass>>,
  vizModel?: VizModel
) => {
  //take the describe response and return the vizModel updated with any new nodes and/or relationships
  let hasChanges = false;
  const currentNode = vizModel?.nodes.get(selectedNode.id);
  if (currentNode) {
    updateNodeUnit(res, currentNode, vizModel);

    const currentNodeRelationships = currentNode.relationships;
    // check if there are any new relationships not already defined
    const newRelationships = getNewRelationships(
      res.relatedNodes,
      currentNodeRelationships
    );

    if (newRelationships.length > 0) {
      hasChanges = true;
      const allNodes = vizModel?.nodes;

      // update the vizModel with the new data
      newRelationships.forEach((relationship) => {
        const brickRelationship = getBrickRelationship(
          relationship.relationship
        );
        const inverse =
          brickRelationship.relation === relationship.relationship
            ? brickRelationship.inverse
            : brickRelationship.relation;

        const nodeUri = getUriFromNode(relationship.node);

        // add any new nodes that we've found, including their inverse relationship to the current node
        if (nodeUri && allNodes && !allNodes.get(nodeUri)) {
          // we have a new node to add
          const newNode: VizModelNode = {
            id: nodeUri,
            label: relationship.label,
            graphLabel: getGraphLabel(relationship, nodeUri),
            comment: relationship.comment,
            class: getDchClass(
              entityClasses,
              relationship.hypernym as ClassHypernym,
              relationship.type
            ),
            properties: relationship.properties,
            modelReference: relationship.node.modelRef,
            relationships: new Map([
              [
                inverse,
                [
                  {
                    modelReference: currentNode.modelReference,
                    nodeUri: currentNode.id,
                  },
                ],
              ],
            ]),
          };
          allNodes.set(nodeUri, newNode);
        } else {
          // the node exists, but this particular relationship for it doesn't
          vizModel?.nodes.get(nodeUri)?.relationships.set(inverse, [
            {
              modelReference: currentNode.modelReference,
              nodeUri: currentNode.id,
            },
          ]);
        }
        // update relationships for the selected node
        const relatedIds =
          currentNodeRelationships.get(relationship.relationship) ?? [];
        relatedIds.push({
          modelReference: relationship.node.modelRef,
          nodeUri: nodeUri,
        });
        vizModel?.nodes
          .get(selectedNode.id)
          ?.relationships.set(relationship.relationship, relatedIds);
      });
    }
  }
  return hasChanges;
};
