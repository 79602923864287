/** @jsxImportSource @emotion/react */

import React, { useCallback, useEffect } from "react";
import { styled } from "twin.macro";
import { DashboardContext } from "App";
import { ApplicationUrl } from "data/Metis/metisTypes";
import { UIStatus, UIStatusWrapper } from "components/shared";

type ApplicationIFrameProps = {
  src: ApplicationUrl;
  setFrameSrc?: Function;
  setDateRange?: Function;
};

export const MaxHeightIFrame = (props: ApplicationIFrameProps) => {
  const dashboardContext = React.useContext(DashboardContext);

  useEffect(
    () => {
      dashboardContext.logout();
      dashboardContext.checkSession();
      return () => {
        dashboardContext.logout();
      };
    },
    // eslint-disable-next-line
    []
  );

  const [status, setStatus] = React.useState(
    new UIStatus().setIndeterminate(true)
  );

  window.addEventListener("message", (event) => {
    try {
      const payload = JSON.parse(event.data) as { from: string; to: string };

      if (props.setDateRange && payload.from && payload.to) {
        props.setDateRange(() => {
          return {
            start: new Date(payload.from),
            end: new Date(payload.to),
          };
        });
      }
    } catch (e) {
      console.debug(
        `Failed to parse payload or message was not from Grafana`,
        e
      );
    }
  });

  const turnOffSpinner = useCallback(() => {
    if (status.indeterminate) {
      setStatus((prevState) => prevState.setIndeterminate(false));
    }
  }, [status]);

  const loadIframe = () => (
    <Iframe
      onLoad={(e) => {
        // Grafana will repopulate from and to fields in URL with available data time range.
        // Report this back to the parent page, so it can be interpreted
        if (props.setFrameSrc) {
          props.setFrameSrc(e.currentTarget.src);
        }
        turnOffSpinner();
      }}
      src={props.src}
      className="resp-iframe"
      scrolling="no"
    />
  );

  const Iframe = React.memo(styled.iframe`
    border: 0px;
    height: 100%;
  `);
  return (
    <UIStatusWrapper status={status}>
      <div className="resp-container">
        {dashboardContext.deferRender(loadIframe())}
      </div>
    </UIStatusWrapper>
  );
};
