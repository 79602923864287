import React from "react";
import { ApiKeyResponse, enodiaApi } from "data/Enodia";
import { ModalComponent } from "../ApiKeyModal";

export const getDeleteModal = (
  apiKey: ApiKeyResponse,
  onSuccess: () => void,
  onError: (_: string) => void,
  onConfirm: () => void
): ModalComponent => ({
  header: `Delete API Key: ${apiKey.name}`,
  content: (
    <span>
      Are you sure you want to delete API key <strong>{apiKey?.name}</strong>?
    </span>
  ),
  onConfirm: () => {
    onConfirm();
    apiKey.id &&
      enodiaApi.deleteApiKey(apiKey.id).then(
        () => onSuccess(),
        () => onError("Error deleting API key")
      );
  },
  customConfirmText: "Delete",
});
