import * as E from "fp-ts/lib/Either";
import { constant } from "fp-ts/lib/function";
import * as N from "fp-ts/lib/NonEmptyArray";
import { pipe } from "fp-ts/lib/pipeable";
import { BrickClass, DchJsonClass, mkDchJsonClass } from "./BrickApi";

const mkBrickClass =
  (n: string) =>
  (h: string) =>
  (p: string) =>
  (l: string): BrickClass => ({ name: n, hypernym: h, parent: p, label: l });

/*
 * Validators
 */

export type Json =
  | null
  | boolean
  | number
  | string
  | Array<Json>
  | { [key: string]: Json };

type Validator<a> = E.Either<N.NonEmptyArray<string>, a>;

export enum ValidationLevel {
  clean = "clean",
  info = "info",
  warning = "warning",
  error = "error",
  critical = "critical",
}

type ValidationResult = {
  level: ValidationLevel;
  ruleset: string;
  rule: string;
  message: string;
  node?: string;
  value?: string;
  nodeLabel?: string;
};

const mkValidationResult =
  (l: ValidationLevel) =>
  (s: string) =>
  (r: string) =>
  (m: string) =>
  (n?: string) =>
  (v?: string) => ({
    level: l,
    ruleset: s,
    rule: r,
    message: m,
    node: n,
    value: v,
  });

// Like the functions from Either, but with Validation semantics.
const validation = E.getValidation(N.getSemigroup<string>());

const throwError = <a>(e: string): Validator<a> => E.either.throwError(N.of(e));
const ap =
  <A>(fa: Validator<A>) =>
  <B>(fab: Validator<(a: A) => B>): Validator<B> =>
    validation.ap(fab, fa);

const validateStructureUndefined =
  <a>(f: (_: Json) => Validator<a>) =>
  (j: Json): Validator<a | undefined> =>
    typeof j === "undefined" || (typeof j === "object" && j == null)
      ? validation.of(undefined)
      : f(j);

const validateStructureObjectField = <a>(
  j: Json,
  k: string,
  v: (_: Json) => Validator<a>
): Validator<a> =>
  typeof j === "object" && !Array.isArray(j) && j != null
    ? v(j[k])
    : throwError(`Not an object: ${j}`);

export const validateStructureArray =
  <a>(f: (_: Json) => Validator<a>) =>
  (j: Json): Validator<Array<a>> =>
    Array.isArray(j)
      ? j.reduce(
          (z, a) =>
            validation.chain(z, (l) =>
              validation.map(f(a), (a) => l.concat(a))
            ),
          validation.of<Array<a>>([])
        )
      : throwError(`Not an array: ${j}`);

const validateStructureString = (j: Json): Validator<string> =>
  typeof j == "string" ? validation.of(j) : throwError(`Not a string: ${j}`);

const validateStructureEnum =
  <T extends string>(e: { [key: string]: T }) =>
  (j: Json): Validator<T> =>
    typeof j === "string"
      ? Object.values(e).reduceRight(
          (z, a) =>
            validation.alt(
              z,
              constant(
                a === j
                  ? validation.of(a)
                  : throwError(
                      `validateStructureEnum no match "${j}" ${JSON.stringify(
                        e
                      )}`
                    )
              )
            ),
          throwError("validateStructureEnum empty") as Validator<T>
        )
      : throwError(`Not an enum string: ${j}`);

export const validateStructureBrickClass = (j: Json): Validator<BrickClass> =>
  pipe(
    validation.of<typeof mkBrickClass>(mkBrickClass),
    ap(validateStructureObjectField(j, "name", validateStructureString)),
    ap(validateStructureObjectField(j, "hypernym", validateStructureString)),
    ap(validateStructureObjectField(j, "parent", validateStructureString)),
    ap(validateStructureObjectField(j, "label", validateStructureString))
  );

export const validateStructureDchJsonClass = (
  j: Json
): Validator<DchJsonClass> =>
  pipe(
    validation.of<typeof mkDchJsonClass>(mkDchJsonClass),
    ap(validateStructureObjectField(j, "type", validateStructureString)),
    ap(
      validateStructureObjectField(
        j,
        "parents",
        validateStructureArray(validateStructureString)
      )
    ),
    ap(validateStructureObjectField(j, "label", validateStructureString))
  );

const validateStructureValidationResult = (
  j: Json
): Validator<ValidationResult> =>
  pipe(
    validation.of<typeof mkValidationResult>(mkValidationResult),
    ap(
      validateStructureObjectField(
        j,
        "level",
        validateStructureEnum(ValidationLevel)
      )
    ),
    ap(validateStructureObjectField(j, "ruleset", validateStructureString)),
    ap(validateStructureObjectField(j, "rule", validateStructureString)),
    ap(validateStructureObjectField(j, "message", validateStructureString)),
    ap(
      validateStructureObjectField(
        j,
        "node",
        validateStructureUndefined(validateStructureString)
      )
    ),
    ap(
      validateStructureObjectField(
        j,
        "value",
        validateStructureUndefined(validateStructureString)
      )
    )
  );

export type ValidationReport = {
  level: ValidationLevel;
  results: Array<ValidationResult>;
  rulesets: Array<string>;
};

const mkValidationReport =
  (l: ValidationLevel) =>
  (r: Array<ValidationResult>) =>
  (s: Array<string>) => ({ level: l, results: r, rulesets: s });

export const validateStructureValidationReport = (
  j: Json
): Validator<ValidationReport> =>
  pipe(
    validation.of<typeof mkValidationReport>(mkValidationReport),
    ap(
      validateStructureObjectField(
        j,
        "level",
        validateStructureEnum(ValidationLevel)
      )
    ),
    ap(
      validateStructureObjectField(
        j,
        "results",
        validateStructureArray(validateStructureValidationResult)
      )
    ),
    ap(
      validateStructureObjectField(
        j,
        "rulesets",
        validateStructureArray(validateStructureString)
      )
    )
  );
