/** @jsxImportSource @emotion/react */
import { Image } from "semantic-ui-react";
import tw from "twin.macro";
import {
  CategoryResponse,
  InstalledApplicationResponse,
  useApplicationCategories,
} from "data/Metis";
import { LabelledInput, SelectInput, TextInput } from "components/shared";
import { getIconByCategory } from "components/Applications/shared";
import { ModelsList } from "./ModelList";

const ApplicationDetails = ({ app }: { app: InstalledApplicationResponse }) => {
  const { categories, categorySelectOptions } = useApplicationCategories();
  const categoryUids = Array.from(categories)
    .filter(([_, category]: [string, CategoryResponse]) =>
      app.categories.includes(category.name)
    )
    .map(([categoryUid, _]) => categoryUid);

  return (
    <div css={tw`px-4`}>
      <TextInput
        label="Application Instance"
        value={app.applicationName}
        isReadOnly
        verticalLayout
      />
      <SelectInput
        verticalLayout
        isMulti
        label="Categories"
        options={categorySelectOptions}
        value={categoryUids}
        isReadOnly
        className="application-category"
        renderLabel={(item) => ({
          content: (
            <div css={tw`flex gap-2 items-center text-sm whitespace-nowrap`}>
              <Image
                src={getIconByCategory([item.text as string])}
                alt="application icon"
                width="16"
                height="16"
              />
              <span>{item.text}</span>
            </div>
          ),
        })}
      />
      <LabelledInput
        label="Models"
        verticalLayout
        input={<ModelsList sites={app.sites} buildings={app.buildings} />}
      />
    </div>
  );
};
export default ApplicationDetails;
