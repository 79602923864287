import React from "react";
import { Path } from "Routes";
import { PageState } from "./usePointClassifierPageState";

export function useSkipper() {
  const shouldSkipRef = React.useRef(true);
  const shouldSkip = shouldSkipRef.current;

  // Wrap a function with this to skip a pagination reset temporarily
  const skip = React.useCallback(() => {
    shouldSkipRef.current = false;
  }, []);

  React.useEffect(() => {
    shouldSkipRef.current = true;
  });

  return [shouldSkip, skip] as const;
}

export const getKeyFromId = (id: string) => {
  // we're only interested in the last part of the id, which tells us what input type to render
  const idSplit = id.split("-");
  return idSplit[idSplit.length - 1];
};

/**
 * @returns original value if there is a value string, number or string[], otherwise return undefined
 */
export const checkNonEmptyValue = (value: string | number | string[]) => {
  if (value === "" || (Array.isArray(value) && value.length === 0)) {
    return undefined;
  } else {
    return value;
  }
};

export function getShareablePath(pointClassifierContext: PageState) {
  const path: string[] = [Path.PointClassifierRoot];
  const properties = [
    pointClassifierContext.orgId,
    pointClassifierContext.classifierSetId,
    pointClassifierContext.datapoolId,
    pointClassifierContext.classificationPlanId,
  ];
  for (const i in properties) {
    if (!!properties[i]) {
      path.push(`/${properties[i]}`);
    } else {
      break;
    }
  }
  return path.join("");
}
