import React from "react";
import { FetchStatus } from "data/constants";
import { useApplicationCategories } from "data/Metis/useApplicationCategories";
import { SelectInput } from "components/shared";

type CategoryFilterProps = {
  value: string;
  onChange: (e: any, data: any) => void;
};

export const CategoryFilter = (props: CategoryFilterProps) => {
  const { fetchingStatus, categorySelectOptions } = useApplicationCategories();

  return (
    <SelectInput
      verticalLayout
      label="Category"
      placeholder="Filter by category..."
      options={categorySelectOptions}
      onChange={props.onChange}
      value={props.value}
      search
      isLoading={fetchingStatus === FetchStatus.Fetching}
      testId="categoryFilter"
    />
  );
};
