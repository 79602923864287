/** @jsxImportSource @emotion/react */
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Row } from "@tanstack/react-table";
import { Table, TableHeaderCell } from "semantic-ui-react";
import { Classifier, EntityPropertyKeyValue } from "data/Aletheia";
import Form from "components/shared/Forms/ReactHookForm";
import { FieldValues, useFormContext } from "react-hook-form";
import { ClassHypernym, Unit } from "data/Mason";
import {
  LabelledInput,
  LabelledInputLayoutProps,
  UIStatus,
} from "components/shared";
import { useCustomFieldArray } from "components/SitesAndBuildings/Model/Form/useCustomFieldArray";
import { useEntityProperties } from "data/Mason/EntityProperties";
import { useEntityClasses } from "data/EntityClasses/useEntityClasses";
import {
  AletheiaEntityPropertyInput,
  PointClassifierSegment,
} from "./AletheiaEntityPropertyInput";
import { CommonInputProps } from "components/SitesAndBuildings/Model/Form/EntityForms/CommonInputs";
import tw from "twin.macro";

type EntityPropertiesRowProps = {
  row: Row<Classifier>;
  setUIStatus: Dispatch<SetStateAction<UIStatus>>;
  setCanSave: Dispatch<SetStateAction<boolean>>;
  unitsList: Array<Unit>;
} & CommonInputProps &
  LabelledInputLayoutProps;

export interface ClassifierAccordionFormFields extends FieldValues {
  properties: EntityPropertyKeyValue[];
}

export const getEPFieldName = (index: number) =>
  `classifiers.${index}.executeRules.entityProperties.value`;

const EntityPropertiesClassifierAccordion = (
  props: EntityPropertiesRowProps
) => {
  const { row, setUIStatus, setCanSave, unitsList } = props;
  const { getValues, formState } = useFormContext();

  const [activeEditRow, setActiveEditRow] = useState<undefined | number>();
  const EPFieldName = getEPFieldName(row.index);
  const propertiesFields = useCustomFieldArray(EPFieldName);

  const entityProperties: EntityPropertyKeyValue[] = getValues(EPFieldName);

  const {
    fetchEntityPropertiesList,
    fetchingEPDefinitions,
    entityPropertyDefinitions,
    fetchEntityPropertyDefinitions,
  } = useEntityProperties();
  const { entityClasses } = useEntityClasses();

  useEffect(() => {
    if (formState.isDirty && formState.isValid) setCanSave(true);
  }, [formState, setCanSave]);

  //onload, trigger fetch of the entity properties list if not already done
  useEffect(() => fetchEntityPropertiesList(), [fetchEntityPropertiesList]);

  //fetch the definitions for the selected entity properties if we don't already have them in context
  //definitions are stored as ep_type format (e.g. brick: netArea)
  useEffect(() => {
    if (entityProperties) {
      const unFetchedDefinitions = entityProperties
        .map((p: EntityPropertyKeyValue) => p.ep_type)
        .filter(
          (def: string) =>
            def &&
            def !== "" &&
            !entityPropertyDefinitions.get(def) &&
            !fetchingEPDefinitions.includes(def)
        );
      if (unFetchedDefinitions.length > 0)
        fetchEntityPropertyDefinitions(unFetchedDefinitions);
    }
  }, [
    entityProperties,
    entityPropertyDefinitions,
    fetchEntityPropertyDefinitions,
    fetchingEPDefinitions,
  ]);

  useEffect(() => {
    if (
      fetchingEPDefinitions.filter((def) =>
        entityProperties?.map((p) => p.ep_type).includes(def)
      ).length > 0
    )
      setUIStatus((prev) => prev.setIndeterminate(true));
    else setUIStatus((prev) => prev.setIndeterminate(false));
  }, [entityProperties, fetchingEPDefinitions, setUIStatus]);

  const deleteProperty = (deleteIndex: number) => {
    propertiesFields.remove(deleteIndex);
    setActiveEditRow((p) => {
      if (p && p !== deleteIndex) return p - 1;
      return;
    });
  };

  const addProperty = () => {
    propertiesFields.append({
      ep_type: "",
    });
    setActiveEditRow(propertiesFields.fields.length);
  };

  //TODO: remove Form wrapper- only here interim as EP Component requires it to be wrapped in it
  const input = (
    <div css={tw`flex`}>
      <div css={tw`flex-1`}></div>
      <Table inverted css={tw`flex-1`}>
        <Table.Header>
          <Table.Row>
            <TableHeaderCell> Apply Rules </TableHeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {propertiesFields.fields.length > 0 &&
            propertiesFields.fields.map((field, propertyIndex) => {
              return (
                <Table.Row key={`apply-rules-ep-${field.id}`}>
                  <Table.Cell
                    onDoubleClick={() => {
                      setActiveEditRow(propertyIndex);
                    }}
                  >
                    <AletheiaEntityPropertyInput
                      {...props}
                      key={field.id}
                      hypernym={ClassHypernym.Point}
                      parentClass={ClassHypernym.Point}
                      propertyIndex={propertyIndex}
                      handleDelete={(index) => deleteProperty(index)}
                      entityClasses={entityClasses}
                      restrictTypeChange
                      allowDeleteOnRead
                      verticalLayout={true}
                      isReadOnly={activeEditRow !== propertyIndex}
                      accordionIndex={row.index}
                      segmentName={PointClassifierSegment.classifier}
                      unitsList={unitsList}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          <Table.Row>
            <Table.Cell>
              <Form.Button
                submit={false}
                onClick={() => addProperty()}
                icon="add"
                label={`Add ${
                  entityProperties?.length === 0 ? "a" : "another"
                } property`}
              />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );

  return <LabelledInput input={input} verticalLayout={true} />;
};

export default EntityPropertiesClassifierAccordion;
