import React, { useEffect, useState } from "react";
import CronParser from "cron-parser";
import { Link } from "react-router-dom";
import { Checkbox, Grid, Input } from "semantic-ui-react";
import tw from "twin.macro";
import { DEFAULT_INPUT_WIDTHS, InfoHelper, UIStatus } from "components/shared";
import { FrequencyTypes } from "./ApplicationSchedule";

const FIVE_MIN_MILLISECONDS = 300000;

const ApplicationScheduleCronFrequency: React.FC<{
  cronString: string;
  setCronString: Function;
  disabled: boolean;
  selectedFrequency: string;
  setSelectedFrequency: Function;
  setStatus: Function;
  isReadOnly: boolean;
}> = ({
  cronString,
  setCronString,
  disabled,
  selectedFrequency,
  setSelectedFrequency,
  setStatus,
  isReadOnly,
}) => {
  const [fieldString, setFieldString] = useState<string>(cronString);
  const [cronStatus, setCronStatus] = useState<UIStatus>(new UIStatus());

  useEffect(() => setFieldString(cronString), [cronString]);

  useEffect(
    () => {
      if (selectedFrequency !== FrequencyTypes.cron) {
        return;
      }
      try {
        const expr = CronParser.parseExpression(fieldString);
        if (
          expr.next().getTime() - expr.prev().getTime() <
          FIVE_MIN_MILLISECONDS
        ) {
          setCronStatus((p) =>
            p.setError("Frequency cannot be less than five minutes")
          );
          setStatus((p: UIStatus) =>
            p.setError("Frequency cannot be less than five minutes")
          );
          return;
        }
        if (fieldString !== cronString) {
          setCronString(fieldString);
        }
        setCronStatus((p) => p.setEmpty(true));
        setStatus((p: UIStatus) => p.setEmpty(true));
      } catch (err) {
        setCronStatus((p) => p.setError("Invalid cron expression"));
        setStatus((p: UIStatus) => p.setError("Invalid cron expression"));
      }
    },
    // eslint-disable-next-line
    [fieldString, selectedFrequency]
  );

  const renderInfoHelper = () => (
    <InfoHelper
      title={
        <span>
          <i>Cron Expression</i>
        </span>
      }
      body={
        <div>
          <p>
            A concise string description of a schedule's frequency. e.g. "0 0
            */2 * *" represents 12:00AM every two days.
          </p>
          <div>
            More information can be found{" "}
            <Link
              to="https://docs.oracle.com/cd/E12058_01/doc/doc.1014/e12030/cron_expressions.htm"
              css={tw`text-light cursor-pointer hover:text-white hover:underline`}
            >
              here
            </Link>
          </div>
        </div>
      }
    />
  );

  return (
    <Grid>
      <Grid.Column {...DEFAULT_INPUT_WIDTHS.label} verticalAlign="middle">
        <Checkbox
          css={tw`ml-10`}
          radio
          readOnly={isReadOnly}
          value={FrequencyTypes.cron}
          checked={selectedFrequency === FrequencyTypes.cron}
          onChange={() => {
            setSelectedFrequency(FrequencyTypes.cron);
          }}
          label={
            <label>
              <span css={tw`pr-2 pb-2`}>Cron Expression</span>
              <span>{renderInfoHelper()}</span>
            </label>
          }
        />
      </Grid.Column>
      <Grid.Column {...DEFAULT_INPUT_WIDTHS.input}>
        <Input
          fluid
          disabled={disabled}
          value={fieldString}
          error={cronStatus.error !== undefined}
          onChange={(e, d) => {
            setFieldString(d.value);
          }}
        />
      </Grid.Column>
    </Grid>
  );
};

export default ApplicationScheduleCronFrequency;
